import {getTimeUnitInMS, TIME_UNIT_VALUE_MAP} from "../Helper.js";

class StatisticCache {

    private adverts:Array<object>;
    private bookings:Array<object>;
    private timestamp:number;
    private readonly lifetime = getTimeUnitInMS(30, TIME_UNIT_VALUE_MAP.MINUTE);

    constructor() {
        this.adverts = null;
        this.bookings = null;
    }

    public getAdverts() {
        if (this.adverts === null || Date.now() >= this.timestamp + this.lifetime) {
            return null;
        }
        return this.adverts;
    }

    public getBookings() {
        if (this.bookings === null || Date.now() >= this.timestamp + this.lifetime) {
            return null;
        }
        return this.bookings;
    }

    public setCache(cache) {
        if (cache) {
            this.adverts = cache.adverts ? cache.adverts : null;
            this.bookings = cache.bookings ? cache.bookings : null;
        }
        else {
            this.adverts = null;
            this.bookings = null;
        }
        this.timestamp = Date.now();
    }

    public isValid() {
        return Date.now() < this.timestamp + this.lifetime
    }

    public reset() {
        this.timestamp = 0;
        this.adverts = null;
        this.bookings = null;
    }

}
export default StatisticCache;