import PropTypes from "prop-types";
import {DragListDirection} from "../utils/Types.ts";
import {useRef} from "react";
import {uniqueId} from "lodash";
import "../../css/components/drag-sort-list.css";

function DragSortList({direction=DragListDirection.horizontal,
                          items,
                          itemWrapper,
                          onOrderChange }) {
    const dragIndex = useRef(-1);
    const dropIndex = useRef(-1);

    const onDragStart = function(e, index) {
        e.target.classList.add("dragged");
        dragIndex.current = index;
    }

    const onDrop = function(e) {
        e.preventDefault();
        e.target.classList.remove("dragged");
        if (dropIndex.current > -1 && dragIndex.current !== dropIndex.current) {
            const orderedItems = [...items];
            orderedItems.splice(dragIndex.current, 1);
            let insertAt = dropIndex.current;
            /*if (dropIndex.current > dragIndex.current) {
                insertAt -= 1;
            }*/
            orderedItems.splice(insertAt, 0, items[dragIndex.current]);
            onOrderChange(orderedItems);
        }
        dragIndex.current = -1;
    }

    const onDragOver = function(e, index) {
        e.preventDefault();
        if (dragIndex.current > -1 && dragIndex.current !== index) {
            e.target.classList.add("dragTarget");
            dropIndex.current = index;
        }
    }

    const onDragLeave = function(e) {
        e.target.classList.remove("dragTarget");
        dropIndex.current = -1;
    }

    return <div className={`dragSortList ${direction}`}>
        {items.map((item, index) => {
            return <div
                draggable={true}
                className="dragSortItem"
                key={uniqueId(`item-${index}`)}
                onDragStart={(e) => {
                    onDragStart(e, index);
                }}
                onDragOver={(e) => {
                    onDragOver(e, index);
                }}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                {itemWrapper(item, index)}
            </div>
        })}
    </div>

}
DragSortList.propTypes = {
    direction: PropTypes.oneOf(Object.values(DragListDirection)),
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    itemWrapper: PropTypes.func.isRequired,
    onOrderChange: PropTypes.func.isRequired,
}
export default DragSortList;