import React from "react";
import PropTypes from "prop-types";
import {
    getLanguageEntry,
    START_WIDGET_LIST,
    WIDGET_ICON_MAP,
    WIDGET_MODE_MAP, WIDGET_SYNC_LIST
} from "../../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Mode, WidgetType} from "../../../utils/Types.ts";
import Widget from "../../../components/Widget";
import {Modal} from "react-bootstrap";
import FetchButton from "../../../components/buttons/FetchButton";
import {setupWidgets} from "../../../utils/RESTInterface";
import _uniqueId from "lodash/uniqueId";
import MessageModal from "../../../components/modals/MessageModal";

const DEFAULT_LANDLORD_WIDGET_SETUP = [
    [WidgetType.create_advert, WidgetType.personalize_dashboard],
    [WidgetType.tips_tricks, WidgetType.faq]];
const DEFAULT_RENTER_WIDGET_SETUP = [
    [WidgetType.search, WidgetType.personalize_dashboard],
    [WidgetType.booking_info, WidgetType.faq]];
const DEFAULT_EMPTY_WIDGET_SETUP = [
    [WidgetType.empty, WidgetType.empty],
    [WidgetType.empty, WidgetType.empty]];
const LAYOUT_MAP = [[2, 1], [1, 2], [2, 2]]

class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            edit_dashboard: false,
            show_layout_menu: false,
            setup: DEFAULT_EMPTY_WIDGET_SETUP,
            layout: 0,
            saving: false,
            show_selector: false,
            widget_selector_target: null
        }
        this.layout_button = React.createRef();
        this.widget_selector_modal = React.createRef();
        this.message_modal = React.createRef();
        this.widget_map = {};
        for (const syncWidget of WIDGET_SYNC_LIST) {
            this.widget_map[syncWidget] = React.createRef();
        }
    }

    componentDidMount() {
        this.setState(this.getActualDashboardState());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.mode !== this.props.mode) {
            this.setState(this.getActualDashboardState(), () => {
                for (const widget of Object.values(this.widget_map)) {
                    if (widget.current) {
                        widget.current.updateWidgetData();
                    }
                }
            });
        }
    }

    render() {
        return (
            <div id="my-dashboard">
                <h1 className="desktop-tool-title">
                    {getLanguageEntry("jackboard>dashboard>title")}
                </h1>
                <div id="dashboard">
                    <div id="dashboard-toolbar">
                        {
                            !this.state.edit_dashboard &&
                            <button className="outline-button accent" disabled={this.state.saving}
                                    onClick={() => {this.setState({edit_dashboard: true})}}>
                                <FontAwesomeIcon icon={["fal", "pencil"]} />
                                <span>{getLanguageEntry("jackboard>dashboard>toolbar_buttons>edit_dashboard")}</span>
                            </button>
                        }
                        {
                            this.state.edit_dashboard &&
                            <button id="layout-selector" className={"outline-button accent" + (this.state.show_layout_menu ? " selected" : "")}
                                    onClick={() => {this.setState({show_layout_menu: !this.state.show_layout_menu})}}
                                    onBlur={(e) => {this.handleLayoutBlur(e)}}
                                    ref={this.layout_button} disabled={this.state.saving}>
                                <FontAwesomeIcon icon={["fal", "th-large"]}/>
                                <span>{getLanguageEntry("jackboard>dashboard>toolbar_buttons>change_layout")}</span>
                                {
                                    this.state.show_layout_menu &&
                                    <div id="layout-type-container">
                                        <div className={"layout-type" + (this.state.layout === 0 ? " active" : "")}
                                                onClick={(e) => { this.setLayout(e, 0) }}>
                                            <div className="layout-type-cell tl"></div>
                                            <div className="layout-type-cell tr"></div>
                                            <div className="layout-type-cell w"></div>
                                        </div>
                                        <div className={"layout-type" + (this.state.layout === 1 ? " active" : "")}
                                                onClick={(e) => { this.setLayout(e, 1) }}>
                                            <div className="layout-type-cell w"></div>
                                            <div className="layout-type-cell bl"></div>
                                            <div className="layout-type-cell br"></div>
                                        </div>
                                        <div className={"layout-type" + (this.state.layout === 2 ? " active" : "")}
                                                onClick={(e) => { this.setLayout(e, 2) }}>
                                            <div className="layout-type-cell tl"></div>
                                            <div className="layout-type-cell tr"></div>
                                            <div className="layout-type-cell bl"></div>
                                            <div className="layout-type-cell br"></div>
                                        </div>
                                    </div>
                                }
                            </button>
                        }
                        {
                            this.state.edit_dashboard &&
                            <FetchButton className="outline-button accent" onClick={() => { this.startSaveWidgets(); }}
                                         loading={this.state.saving} loadingText={"general>saving"}>
                                <FontAwesomeIcon icon={["fal", "save"]}/>
                                <span>{getLanguageEntry("general>save")}</span>
                            </FetchButton>
                        }
                        {
                            this.state.edit_dashboard &&
                            <button className="outline-button accent" disabled={this.state.saving}
                                    onClick={() => {this.abortWidgetEditing()}}>
                                <FontAwesomeIcon icon={["fal", "times"]} />
                                <span>{getLanguageEntry("general>abort")}</span>
                            </button>
                        }
                    </div>
                    <div id="dashboard-body">
                        {
                            this.createWidgetContainers().map((c, _) => c)
                        }
                    </div>
                </div>
                <Modal show={this.state.show_selector} onHide={() => { this.hideWidgetSelector() }}
                       dialogClassName="max-content" id="widget-selector-modal" className={this.props.mode}
                       centered size="lg">
                    <Modal.Header>
                        <h4>{getLanguageEntry("jackboard>dashboard>widget_selector_modal>header")}</h4>
                        <button onClick={() => { this.hideWidgetSelector() }}>
                            <FontAwesomeIcon icon={["fal", "close"]}/>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="description-container">
                            {getLanguageEntry("jackboard>dashboard>widget_selector_modal>description")}
                        </div>
                        <div id="widget-selector-grid">
                            {
                                WIDGET_MODE_MAP[this.props.mode].filter(w => !START_WIDGET_LIST.includes(w))
                                    .map((w, _) => {
                                        return <button className="widget-button outline-button accent" key={_uniqueId("button-" + w)}
                                                       disabled={this.state.setup.flat().includes(w)}
                                                       onClick={() => {this.addWidget(w)}}>
                                            <FontAwesomeIcon icon={["fal", WIDGET_ICON_MAP[w]]} />
                                            <span>{getLanguageEntry("jackboard>dashboard>widgets>" + w + ">name")}</span>
                                        </button>
                                    })
                            }
                        </div>
                    </Modal.Body>
                </Modal>
                <MessageModal ref={this.message_modal} onUpdateUserData={this.props.onUpdateUserData}
                              userData={this.props.userData} />
            </div>
        )
    }

    getActualDashboardState() {
        return {
            setup: this.props.userData && this.props.userData.widgets && this.props.userData.widgets[this.props.mode] ?
                JSON.parse(JSON.stringify(this.props.userData.widgets[this.props.mode].setup)) :
                JSON.parse(JSON.stringify(this.props.mode === Mode.renter ?
                    DEFAULT_RENTER_WIDGET_SETUP : DEFAULT_LANDLORD_WIDGET_SETUP)),
            layout: this.props.userData && this.props.userData.widgets &&
            this.props.userData.widgets[this.props.mode] ? this.props.userData.widgets[this.props.mode].layout : 0
        };
    }

    createWidgetContainers() {
        let containers = [];
        for (let r=0; r<this.state.setup.length; r++) {
            let row = this.state.setup[r];
            for (let c=0; c<row.length; c++) {
                let widgetID = row[c];
                let classList = ["roomjack-container"];
                if (LAYOUT_MAP[this.state.layout][r] === 1) {
                    classList.push("w");
                }
                else {
                    if (r === 0) {
                        classList.push(c === 0 ? "tl" : "tr");
                    }
                    else {
                        classList.push(c === 0 ? "bl" : "br");
                    }
                }
                if (this.state.edit_dashboard || row[c] === WidgetType.empty) {
                    classList.push("editable");
                    if (this.state.edit_dashboard) {
                        classList.push("edit");
                    }
                }

                if (row[c] === WidgetType.personalize_dashboard &&
                    !this.state.edit_dashboard) {
                    classList.push("borderless")
                }

                containers.push(
                    <div className={classList.join(" ")}
                         key={"widget-container-" + r + c}>
                        {
                            (row[c] === WidgetType.empty ||
                            (row[c] === WidgetType.personalize_dashboard && this.state.edit_dashboard)) &&
                            <button className="widget-adder" onClick={() => {this.showWidgetSelector(r, c)}}>
                                <FontAwesomeIcon icon={["fal", "plus-circle"]} />
                                <span>{getLanguageEntry("jackboard>dashboard>add_widget_label")}</span>
                            </button>
                        }
                        {
                            row[c] !== WidgetType.empty && row[c] !== WidgetType.personalize_dashboard &&
                            this.state.edit_dashboard &&
                            <button className="outline-button accent" disabled={this.state.saving}
                                    style={{width: "32px", height: "32px", padding: "0",
                                        position: "absolute", top: "10px", right: "10px"}}
                                    onClick={() => {this.removeWidget(r, c)}}>
                                <FontAwesomeIcon icon={["fal", "trash-can"]} />
                            </button>
                        }
                        {
                            row[c] !== WidgetType.empty &&
                            (!this.state.edit_dashboard || row[c] !== WidgetType.personalize_dashboard) &&
                            <Widget type={row[c]} mode={this.props.mode} ref={this.widget_map[widgetID]}
                                    userData={this.props.userData} edit_mode={this.state.edit_dashboard}
                                    onSendMessage={row[c] === WidgetType.booking_info || row[c] === WidgetType.booker_info ?
                                        (receiver) => {this.callMessageModal(receiver)} : null}
                                    navHook={this.props.navHook} />
                        }
                    </div>
                )
            }
        }
        return containers;
    }

    setLayout(e, layout) {
        e.stopPropagation();
        if (this.state.layout !== layout && !this.state.saving) {
            let actualSetup = this.state.setup.flat();
            let newLayout = LAYOUT_MAP[layout];
            let newSetup = [];
            let index = 0;
            for (let x=0; x<newLayout.length; x++) {
                newSetup.push([]);
                for (let y=0; y<newLayout[x]; y++) {
                    if (x === 0 && actualSetup[index] === WidgetType.personalize_dashboard) {
                        index += 1;
                    }
                    newSetup[x].push(index < actualSetup.length ? actualSetup[index++] : "empty");
                }
            }
            this.setState({
                layout: layout,
                setup: newSetup
            });
        }
    }

    handleLayoutBlur(e) {
        if (this.layout_button.current && !this.layout_button.current.contains(e.relatedTarget) &&
            this.state.show_layout_menu) {
            this.setState({show_layout_menu: false});
        }
    }

    startSaveWidgets() {
        let actualWidgetSetting = this.props.userData.widgets ?
            this.props.userData.widgets[this.props.mode] : null;
        if (!actualWidgetSetting || actualWidgetSetting.layout !== this.state.layout ||
            JSON.stringify(actualWidgetSetting.setup) !== JSON.stringify(this.state.setup)) {
            this.setState({saving: true});
            let that = this;
            let mode = this.props.mode;
            setupWidgets({
                layout: this.state.layout,
                setup: this.state.setup
            }, mode, (response) => {
                if (response.widgets) {
                    let user = JSON.parse(JSON.stringify(this.props.userData));
                    user.widgets = response.widgets;
                    that.props.onUpdateUserData?.(user, () => {
                        let state = that.getActualDashboardState();
                        state.saving = false;
                        state.edit_dashboard = false;
                        that.setState(state);
                    });
                    if (response.data) {
                        for (const widgetType of Object.keys(response.data)) {
                            that.widget_map[widgetType].current.updateWidgetData();
                        }
                    }
                }
                else {
                    that.setState({
                        saving: false,
                        edit_dashboard: false
                    });
                }
            });
        }
        else {
            this.setState({edit_dashboard: false});
        }
    }

    abortWidgetEditing() {
        let state = this.getActualDashboardState();
        state.edit_dashboard = false;
        state.show_layout_menu = false;
        this.setState(state);
    }

    removeWidget(row, column) {
        let actualSetup = this.state.setup;
        if (actualSetup.length > row && actualSetup[row].length > column) {
            actualSetup[row][column] = WidgetType.empty;
        }
        this.setState({setup: actualSetup});
    }

    showWidgetSelector(row, column) {
        this.setState({
            edit_dashboard: true,
            show_selector: true,
            widget_selector_target: row + "x" + column
        })
    }

    hideWidgetSelector() {
        this.setState({
            show_selector: false,
            widget_selector_target: null
        })
    }

    addWidget(widgetType) {
        let split = this.state.widget_selector_target.split("x");
        let row = parseInt(split[0]);
        let col = parseInt(split[1]);
        let actualSetup = this.state.setup;
        actualSetup[row][col] = widgetType;
        this.setState({
            setup: actualSetup,
            show_selector: false
        });
    }

    callMessageModal(receiver) {
        if (this.message_modal.current) {
            this.message_modal.current.show(receiver);
        }
    }

}

Dashboard.propTypes = {
    mode: PropTypes.oneOf(Object.values(Mode)).isRequired,
    userData: PropTypes.object.isRequired,
    onUpdateUserData: PropTypes.func.isRequired,
    navHook: PropTypes.func.isRequired
}
Dashboard.defaultProps = {}
export default Dashboard;