import React from "react";
import PropTypes from "prop-types";
import {getDayFromBegin, getLanguageEntry, getTimeUnitInMS, PRODUCTION_START} from "../../../../utils/Helper";
import {calculateLengthOfStayData} from "../../../../utils/StatisticHelper";
import {Bar} from "react-chartjs-2";

class LengthOfStay extends React.Component {

    render() {
        let chartData = this.determineStatisticData();
        return(
            <div id="length-of-stay-overview" className="statistic-container">
                <div className="statistic-title">
                    {getLanguageEntry("jackboard>stats>submenus>length_of_stay>title")}
                </div>
                <div className="content-container">
                    <div className="statistic-data-container">
                        <div className="roomjack-container">
                            <Bar data={chartData.data} options={chartData.options} plugins={chartData.plugins} />
                        </div>
                    </div>
                    <div className="statistic-overview-container">
                        <div id="average-stay-length" className="statistic-display">
                            <div className="statistic-display-headline">
                                {getLanguageEntry("jackboard>stats>overview_labels>average_stay_length")}
                            </div>
                            <div className="statistic-display-value">
                                <div className="statistic-value">
                                    {chartData.average_length.toFixed(1)}
                                </div>
                                <div className="statistic-unit">
                                    {getLanguageEntry("general>day" + (chartData.average_length === 1 ? "" : "s"))}
                                </div>
                            </div>
                        </div>
                        <div id="min-stay-length" className="statistic-display">
                            <div className="statistic-display-headline">
                                {getLanguageEntry("jackboard>stats>overview_labels>min_stay_length")}
                            </div>
                            <div className="statistic-display-value">
                                <div className="statistic-value">
                                    {chartData.min_length}
                                </div>
                                <div className="statistic-unit">
                                    {getLanguageEntry("general>day" + (chartData.min_length === 1 ? "" : "s"))}
                                </div>
                            </div>
                        </div>
                        <div id="max-stay-length" className="statistic-display">
                            <div className="statistic-display-headline">
                                {getLanguageEntry("jackboard>stats>overview_labels>max_stay_length")}
                            </div>
                            <div className="statistic-display-value">
                                <div className="statistic-value">
                                    {chartData.max_length}
                                </div>
                                <div className="statistic-unit">
                                    {getLanguageEntry("general>day" + (chartData.max_length === 1 ? "" : "s"))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    determineStatisticData() {
        let start = PRODUCTION_START.getTime();
        let end = getDayFromBegin().getTime();
        if (this.props.interval !== -1) {
            start = end - getTimeUnitInMS(this.props.interval);
        }
        let lengthOfStayData = calculateLengthOfStayData(this.props.bookings, this.props.adverts.map((a, i) => a.id), start, end);

        let labels = [];
        let dataSets = [
            { data: [], backgroundColor: '#5C2727', categoryPercentage: 0.25, order: 2 },
            { data: [], backgroundColor: '#E9B400', categoryPercentage: 0.25, order: 1 },
            { data: [], backgroundColor: '#D86E36', categoryPercentage: 0.25, order: 0 }

        ];
        for (const dp of Object.keys(lengthOfStayData.dataPoints)) {
            let split = dp.split('.');
            let month = parseInt(split[0]) - 1;
            let year = split[1].substring(2);
            labels.push(getLanguageEntry("general>month_names>" + month) + ' ' + year);
            dataSets[0].data.push(lengthOfStayData.dataPoints[dp].max_length);
            dataSets[1].data.push(lengthOfStayData.dataPoints[dp].average_length);
            dataSets[2].data.push(lengthOfStayData.dataPoints[dp].min_length);
        }
        let options = {
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 2,
            plugins: {
                legend: {
                    display: false
                }
            },
            scales: {
                x: {
                    stacked: true
                }
            }
        }
        let dataObject = {
            labels: labels,
            datasets: dataSets,
        };
        let plugin = {
            id: 'bar-offset',
            afterDraw(chart, args, options) {
                let midData = chart.getDatasetMeta(1).data;
                chart.data.datasets.forEach((dataset, i) => {
                    chart.getDatasetMeta(i).data.forEach(function (datapoint, index) {
                        let middle = midData[index].x;
                        datapoint.x = middle + (i - 1) * datapoint.width * .3;
                    });
                });
            }
        }
        return {
            ...lengthOfStayData,
            data: dataObject,
            options: options,
            plugins: [plugin]
        }
    }

}

LengthOfStay.propTypes = {
    adverts: PropTypes.array,
    bookings: PropTypes.array,
    interval: PropTypes.number,
    breakpoint: PropTypes.number
}
export default LengthOfStay;