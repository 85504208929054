import React from 'react';

import {RENT_RESPONSIBLE} from "../../../resources/contents/ContentPageContent";
import {getLanguageEntry} from "../../utils/Helper";

class RentResponsibleContentPage extends React.Component {

    render() {
        return(
            <div id="content-rent-responsible" className="content-page">
                <div className="container">
                    <h1 className="content-title">{getLanguageEntry("content_pages>rent_responsible>title")}</h1>
                    {
                        RENT_RESPONSIBLE.features.map((item, i) => {
                            return <div className={"roomjack-feature" + (i % 2 === 1 ? " inverted" : "")}>
                                <div className="feature-image-container">
                                    <img src={item.url} alt={item.alt}/>
                                </div>
                                <div
                                    className="feature-content-container">{getLanguageEntry("content_pages>rent_responsible>content")[i]}
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        )
    }
}
export default RentResponsibleContentPage;