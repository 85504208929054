import React from "react";
import {WidgetType, Mode, AccessType} from "../utils/Types.ts";
import PropTypes from "prop-types";
import {
    copyTextToClipboard, createAdvertLocationDropdown,
    DEFAULT_LANGUAGE,
    FAQ_HELP, filterDataToQuery,
    generateGoogleNavigationLink,
    getConversationPreviewText,
    getDayFromBegin, getFormattedAccessText,
    getFormattedAddress,
    getFormattedDate,
    getLanguageEntry,
    getNextPage,
    getTimestampLabel,
    getTimeUnitInMS, getTodoRelevantBookings,
    globalLanguage,
    priceToString,
    TIPS_TRICKS,
    WIDGET_ICON_MAP,
    WIDGET_SYNC_LIST
} from "../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "../../css/components/widget.css";
import {syncWidgets} from "../utils/RESTInterface";
import {WIDGET_CACHE} from "../utils/CacheHandler.ts";
import {Carousel} from "react-bootstrap";
import parse from "html-react-parser";
import NavigatorButton from "./buttons/NavigatorButton";
import FilterSelectorBar from "./FilterSelectorBar";
import {
    determineClickBookingData,
    determineOccupancyChartData,
    getIncome, getRatingInformationOfAdverts,
    getRatingInformationOfUser
} from "../utils/StatisticHelper";
import Avatar from "./Avatar";
import {Doughnut} from "react-chartjs-2";
import {ReviewAverageContainer} from "./ReviewDisplays";
import SecretInputField from "./input/SecretInputField";
import {Link} from "react-router-dom";
import Calendar from "./Calendar";
import Dropdown from "./input/Dropdown";

const LANG_PATH = "jackboard>dashboard>widgets>";

class Widget extends React.Component {

    incomeFilterItems = [
        {
            value: -1,
            label: "components>interval_filter>all"
        },
        {
            value: 30,
            label: "components>interval_filter>last_month"
        },
        {
            value: 365,
            label: "components>interval_filter>last_year"
        }
    ]

    occupancyFilterItems = [
        {
            value: 1,
            label: "jackboard>dashboard>widgets>occupancy_rate>footer>interval_buttons>0"
        },
        {
            value: 7,
            label: "jackboard>dashboard>widgets>occupancy_rate>footer>interval_buttons>1"
        },
        {
            value: 30,
            label: "jackboard>dashboard>widgets>occupancy_rate>footer>interval_buttons>2"
        }
    ]

    satisfactionFilterItems = [
        {
            value: -1,
            label: "components>interval_filter>all"
        },
        {
            value: 30,
            label: "components>interval_filter>last_month"
        },
        {
            value: 180,
            label: "components>interval_filter>last_half_year"
        }
    ]

    constructor(props) {
        super(props);
        let interval = -1;
        if (props.type === WidgetType.occupancy_rate) {
            interval = 1;
        }
        this.state = {
            loading: false,
            data: null,
            interval: interval,
            page: 0,
            width: 0,
            height: 0
        }
        this.widget_container = React.createRef();
        this.search_location = React.createRef();
        this.search_calender = React.createRef();
    }

    componentDidMount() {
        this.updateWidgetData();
        if (this.props.type === WidgetType.personalize_dashboard &&
            this.widget_container.current &&
            this.widget_container.current.offsetWidth > 0 &&
            this.widget_container.current.offsetHeight > 0 &&
            (this.state.width !== this.widget_container.current.offsetWidth ||
                this.state.height !== this.widget_container.current.offsetHeight)) {
            this.setState({
                width: this.widget_container.current.offsetWidth,
                height: this.widget_container.current.offsetHeight
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.mode !== prevProps.mode) {
            let interval = -1;
            if (this.props.type === WidgetType.occupancy_rate) {
                interval = 1;
            }
            this.setState({
                loading: false,
                data: null,
                interval: interval,
                page: 0,
                width: 0,
                height: 0
            })
        }
        else if (this.props.type === WidgetType.personalize_dashboard &&
            this.widget_container.current &&
            this.widget_container.current.offsetWidth > 0 &&
            this.widget_container.current.offsetHeight > 0 &&
            (this.state.width !== this.widget_container.current.offsetWidth ||
                this.state.height !== this.widget_container.current.offsetHeight)) {
            this.setState({
                width: this.widget_container.current.offsetWidth,
                height: this.widget_container.current.offsetHeight
            });
        }
    }

    render() {
        let header = this.buildWidgetHeader();
        let footer = this.buildWidgetFooter();
        return (
            <div className={"widget" + (footer ? "" : " no-footer") + (header ? "" : " no-header")}
                 ref={this.widget_container}>
                { header }
                { this.buildWidgetContent() }
                { footer }
            </div>
        )
    }

    updateWidgetData() {
        if (WIDGET_SYNC_LIST.includes(this.props.type)) {
            let cacheID = this.props.type;
            let cache = WIDGET_CACHE.getCacheObject(cacheID);
            if (cache) {
                this.setState({data: cache.getData()});
            }
            else {
                this.syncWidget();
            }
        }
    }

    syncWidget() {
        this.setState({loading: true});
        let that = this;
        syncWidgets([this.props.type], this.props.mode, () => {
            let cacheID = that.props.type;
            let cache = WIDGET_CACHE.getCacheObject(cacheID);
            let state = {loading: false};
            if (cache) {
                state.data = cache.getData();
            }
            state.page = 0;
            that.setState(state);
        })
    }

    buildWidgetHeader() {
        if (!WIDGET_ICON_MAP[this.props.type]) {
            return null;
        }
        return <div className="widget-header">
            {
                WIDGET_ICON_MAP[this.props.type] &&
                <FontAwesomeIcon icon={["fal", WIDGET_ICON_MAP[this.props.type]]} />
            }
            {
                WIDGET_ICON_MAP[this.props.type] &&
                <span>{getLanguageEntry(LANG_PATH + this.props.type + ">header")}</span>
            }
        </div>
    }

    buildWidgetContent() {
        if (this.props.edit_mode) {
            return <div className="widget-body edit">
                <div className="description-container">
                    {getLanguageEntry("jackboard>dashboard>widget_edit_mode")}
                </div>
            </div>
        }
        if (WIDGET_SYNC_LIST.includes(this.props.type) && (!this.state.data || this.state.loading)) {
            return <div className="widget-body loading">
                <FontAwesomeIcon icon={["fad", "spinner-third"]} spin={true}/>
            </div>
        }
        let classList = ["widget-body"]; let noData = false;
        let start; let end; let booking;
        switch (this.props.type) {
            case WidgetType.message:
                if (!this.state.data || this.state.data.length === 0) {
                    noData = true;
                    classList.push("center");
                }
                return <div className={classList.join(" ")}>
                    {
                        this.state.data && this.state.data.length > 0 &&
                        <div className="widget-grid">
                            {
                                this.state.data.map((m, _) => this.createMessagePreview(m))
                            }
                        </div>
                    }
                    {
                        noData &&
                        <div className="description-container">
                            {getLanguageEntry(LANG_PATH + "message>body_placeholder")}
                        </div>
                    }
                </div>;
            case WidgetType.inquiry:
                if (!this.state.data || this.state.data.length === 0) {
                    noData = true;
                    classList.push("center");
                }
                return <div className={classList.join(" ")}>
                    {
                        !noData &&
                        <div className="widget-grid">
                            {
                                this.state.data.map((m, _) => this.createInquiryPreview(m))
                            }
                        </div>
                    }
                    {
                        noData &&
                        <div className="description-container">
                            {getLanguageEntry(LANG_PATH + "inquiry>body_placeholder")}
                        </div>
                    }
                </div>;
            case WidgetType.todo_list:
                classList.push("todo-list");
                return <div className={classList.join(" ")}>
                    {this.createTodoTiles()}
                </div>
            case WidgetType.occupancy_rate:
                classList.push("center")
                start = getDayFromBegin().getTime();
                end = start + getTimeUnitInMS(this.state.interval);
                let chartData = determineOccupancyChartData(start, end, this.state.data.adverts, this.state.data.bookings);
                return <div className={classList.join(" ")}>
                    <Doughnut data={chartData.data} options={chartData.options}
                              width={180} height={180}/>
                    <div className="doughnut-center-label">
                        <div>{chartData.percent}%</div>
                        <div>{getLanguageEntry("jackboard>stats>submenus>occupancy_rate>occupied_label")}</div>
                    </div>
                </div>;
            case WidgetType.customer_satisfaction:
                classList.push("customer-satisfaction");
                classList.push("center");
                end = getDayFromBegin();
                start = this.state.interval !== -1 ?
                    new Date(end.getTime() - getTimeUnitInMS(this.state.interval)) : null;
                let userReviews = this.props.userData.reviews ? Object.values(this.props.userData.reviews) : [];
                let userRatingObject = getRatingInformationOfUser(userReviews, start, end, Mode.landlord);
                let advertRatingObject = getRatingInformationOfAdverts(this.state.data, start, end, false);
                return <div className={classList.join(" ")}>
                    <div className="statistic-display">
                        <div className="statistic-display-headline">
                            {getLanguageEntry(LANG_PATH + "customer_satisfaction>landlord_satisfaction")}
                        </div>
                        <ReviewAverageContainer ratingObject={userRatingObject}/>
                    </div>
                    <div className="statistic-display">
                        <div className="statistic-display-headline">
                            {getLanguageEntry(LANG_PATH + "customer_satisfaction>adverts_satisfaction")}
                        </div>
                        <ReviewAverageContainer ratingObject={advertRatingObject}/>
                    </div>
                </div>
            case WidgetType.income:
                end = getDayFromBegin();
                start = this.state.interval !== -1 ?
                    new Date(end.getTime() - getTimeUnitInMS(this.state.interval)) : null;
                return <div className="widget-body center">
                    <div className="statistic-display">
                        <div className="statistic-display-headline">
                            {getLanguageEntry("jackboard>stats>submenus>general_statistics>income_header")}
                        </div>
                        <div className="statistic-display-value">
                            {priceToString(getIncome(this.state.data.bookings, null, start, end), true, 2)}
                        </div>
                    </div>
                </div>
            case WidgetType.click_booking:
                classList.push("click-booking");
                classList.push("center");
                let statisticData = determineClickBookingData(this.state.interval, this.state.data.bookings, this.state.data.adverts);
                return <div className={classList.join(" ")}>
                    <div className="statistic-display">
                        <div className="statistic-display-headline">
                            {getLanguageEntry("jackboard>stats>submenus>click_booking_ratio>click_header")}
                        </div>
                        <div className="statistic-display-value">{statisticData.clicks}</div>
                    </div>
                    <div className="statistic-display">
                        <div className="statistic-display-headline">
                            {getLanguageEntry("jackboard>stats>submenus>general_statistics>completed_bookings_header")}
                        </div>
                        <div className="statistic-display-value">{statisticData.bookings}</div>
                    </div>
                </div>;
            case WidgetType.search:
                classList.push("center");
                return <div className={classList.join(" ")}>
                    <div className="search-box">
                        {createAdvertLocationDropdown("location", this.search_location,
                            "landing_pages>renter>search_bar>location_placeholder", null, "none")}
                        <Calendar ref={this.search_calender} />
                    </div>
                </div>;
            case WidgetType.booker_info:
                classList.push("center");
                classList.push("booker-info");
                if (this.state.data && this.state.data.bookings &&
                    this.state.data.bookings.length > 0) {
                    booking = this.state.data.bookings[this.state.page];
                }
                return <div className={classList.join(" ")}>
                    {
                        !booking &&
                        <div className="description-container">
                            {getLanguageEntry(LANG_PATH + "booker_info>no_data_placeholder")}
                        </div>
                    }
                    {
                        booking && this.state.data.bookings.length > 1 &&
                        <button className="previous-booking-info" onClick={() => {
                            this.setState({page: getNextPage(this.state.page, -1, this.state.data.bookings.length)})
                        }}>
                            <FontAwesomeIcon icon={["fal", "chevron-left"]}/>
                        </button>
                    }
                    {
                        this.createBookerInfoTable(booking)
                    }
                    {
                        booking && this.state.data.bookings.length > 1 &&
                        <button className="next-booking-info" onClick={() => {
                            this.setState({page: getNextPage(this.state.page, 1, this.state.data.bookings.length)})
                        }}>
                            <FontAwesomeIcon icon={["fal", "chevron-right"]}/>
                        </button>
                    }
                </div>;
            case WidgetType.booking_info:
                classList.push("center");
                classList.push("booking-info");
                if (this.state.data && this.state.data.bookings &&
                    this.state.data.bookings.length > 0) {
                    booking = this.state.data.bookings[this.state.page];
                }
                return <div className={classList.join(" ")}>
                    {
                        !booking &&
                        <div className="description-container">
                            {getLanguageEntry(LANG_PATH + "booking_info>no_data_placeholder")}
                        </div>
                    }
                    {
                        booking && this.state.data.bookings.length > 1 &&
                        <button className="previous-booking-info" onClick={() => {
                            this.setState({page: getNextPage(this.state.page, -1, this.state.data.bookings.length)})
                        }}>
                            <FontAwesomeIcon icon={["fal", "chevron-left"]}/>
                        </button>
                    }
                    {
                        this.createBookingInfoTable(booking)
                    }
                    {
                        booking && this.state.data.bookings.length > 1 &&
                        <button className="next-booking-info" onClick={() => {
                            this.setState({page: getNextPage(this.state.page, 1, this.state.data.bookings.length)})
                        }}>
                            <FontAwesomeIcon icon={["fal", "chevron-right"]}/>
                        </button>
                    }
                </div>;
            case WidgetType.create_advert:
                classList.push("center");
                classList.push("create-advert");
                return <div className={classList.join(" ")}>
                    <img src="https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear_honey.png" alt="Create your first advert"/>
                    <div className="roomjack-container-header">
                        {getLanguageEntry(LANG_PATH + "create_advert>header")}
                    </div>
                    <div className="description-container">
                        {getLanguageEntry(LANG_PATH + "create_advert>description")}
                    </div>
                </div>;
            case WidgetType.personalize_dashboard:
                classList.push("personalize-dashboard");
                let width = this.state.width;
                let height = this.state.height * 0.6;
                return <div className={classList.join(" ")}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox={"0 0 " + width + " " + height}>
                        <path className="line" d={"M" + (width / 2) + " " + (height * 0.9) + " C" + (width / 2) + " " +
                            (height * .2) + " " + (width - 92) + " " + (height) + " " +
                            (width - 92) + " 25"}/>
                        <path d={"M" + (width - 110) + " 40 C" + (width - 95) + " 10 " +
                            (width - 90) + " 10 " + (width - 75) + " 40"}/>
                    </svg>
                    <div className="roomjack-container-header">
                        {getLanguageEntry(LANG_PATH + "personalize_dashboard>body")}
                    </div>
                </div>;
            case WidgetType.tips_tricks:
                return <div className={classList.join(" ")}>
                    <Carousel slide={true} interval={4000} indicators={false} controls={true}
                              prevIcon={<FontAwesomeIcon icon={["fal", "chevron-circle-left"]}/>}
                              nextIcon={<FontAwesomeIcon icon={["fal", "chevron-circle-right"]}/>}>
                        {TIPS_TRICKS.filter(t => t.category === this.props.mode).map((item, _) => {
                            return <Carousel.Item key={item.id}>
                                <Carousel.Caption>
                                    <div className="roomjack-headline">
                                        {item.header[globalLanguage] ?? item.header[DEFAULT_LANGUAGE]}
                                    </div>
                                    {this.buildTipsContent(item)}
                                </Carousel.Caption>
                            </Carousel.Item>
                        })}
                    </Carousel>
                </div>;
            case WidgetType.faq:
                return <div className={classList.join(" ")}>
                    <Carousel slide={true} interval={4000} indicators={false} controls={true}
                              prevIcon={<FontAwesomeIcon icon={["fal", "chevron-circle-left"]}/>}
                              nextIcon={<FontAwesomeIcon icon={["fal", "chevron-circle-right"]}/>}>
                        {FAQ_HELP.filter(t => t.category === this.props.mode).map((item, _) => {
                            return <Carousel.Item key={item.id}>
                                <Carousel.Caption>
                                    <div className="roomjack-headline">
                                        {item.header[globalLanguage] ?? item.header[DEFAULT_LANGUAGE]}
                                    </div>
                                    {this.buildFAQContent(item)}
                                </Carousel.Caption>
                            </Carousel.Item>
                        })}
                    </Carousel>
                </div>;
            default:
                return <div className={classList.join(" ")}>

                </div>;
        }
    }

    buildWidgetFooter() {
        switch (this.props.type) {
            case WidgetType.message:
                if (!this.state.data || this.state.loading ||
                    this.props.edit_mode) {
                    return null;
                }
                return <div className="widget-footer">
                    <NavigatorButton to="/desktop/messages" className="accent-icon-button">
                        <FontAwesomeIcon icon={["fal", "comments"]}/>
                        <span>{getLanguageEntry(LANG_PATH + "message>footer>check_all_messages")}</span>
                    </NavigatorButton>
                </div>;
            case WidgetType.todo_list:
                if (!this.state.data || this.state.loading ||
                    this.props.edit_mode) {
                    return null;
                }
                return <div className="widget-footer horizontal">
                    <Dropdown
                        items={[
                        { value: 0, label: "jackboard>todos>filters>today" },
                        { value: 1, label: "jackboard>todos>filters>tomorrow" },
                        { value: 2, label: "jackboard>todos>filters>this_week" },
                        { value: 3, label: "jackboard>todos>filters>next_four_weeks" }]}
                        name="interval"
                        initialValue={0}
                        onChange={(e, value) => {
                            console.log(value);
                            this.setState({interval: value});
                        }} />
                    <NavigatorButton to="/desktop/todos" className="accent-icon-button">
                        <FontAwesomeIcon icon={["fal", "clipboard-list"]}/>
                        <span>{getLanguageEntry(LANG_PATH + "todo_list>footer>check_all_todos")}</span>
                    </NavigatorButton>
                </div>;
            case WidgetType.occupancy_rate:
                if (!this.state.data || this.state.loading ||
                    this.props.edit_mode) {
                    return null;
                }
                return <div className="widget-footer horizontal">
                    <FilterSelectorBar items={this.occupancyFilterItems} defaultValue={this.state.interval}
                                       name="occupancy-interval" onChange={(v) => { this.setState({interval: v}) }} />
                    <NavigatorButton to="/desktop/statistics/occupancy-rate" className="accent-icon-button">
                        <FontAwesomeIcon icon={["fal", "analytics"]}/>
                        <span>{getLanguageEntry(LANG_PATH + "occupancy_rate>footer>check_statistics")}</span>
                    </NavigatorButton>
                </div>;
            case WidgetType.customer_satisfaction:
                if (!this.state.data || this.state.loading ||
                    this.props.edit_mode) {
                    return null;
                }
                return <div className="widget-footer horizontal">
                    <FilterSelectorBar items={this.satisfactionFilterItems} defaultValue={this.state.interval}
                                       name="satisfaction-interval" onChange={(v) => { this.setState({interval: v}) }} />
                    <NavigatorButton to="/desktop/reviews" className="accent-icon-button">
                        <FontAwesomeIcon icon={["fal", "analytics"]}/>
                        <span>{getLanguageEntry(LANG_PATH + "customer_satisfaction>footer>check_reviews")}</span>
                    </NavigatorButton>
                </div>;
            case WidgetType.income:
                if (!this.state.data || this.state.loading ||
                    this.props.edit_mode) {
                    return null;
                }
                return <div className="widget-footer horizontal">
                    <FilterSelectorBar items={this.incomeFilterItems} defaultValue={this.state.interval}
                                       name="income-interval" onChange={(v) => { this.setState({interval: v}) }} />
                    <NavigatorButton to="/desktop/statistics/income" className="accent-icon-button">
                        <FontAwesomeIcon icon={["fal", "analytics"]}/>
                        <span>{getLanguageEntry(LANG_PATH + "income>footer>check_statistics")}</span>
                    </NavigatorButton>
                </div>;
            case WidgetType.click_booking:
                if (!this.state.data || this.state.loading ||
                    this.props.edit_mode) {
                    return null;
                }
                return <div className="widget-footer">
                    <NavigatorButton to="/desktop/statistics/click-booking" className="accent-icon-button">
                        <FontAwesomeIcon icon={["fal", "analytics"]}/>
                        <span>{getLanguageEntry(LANG_PATH + "click_booking>footer>check_statistics")}</span>
                    </NavigatorButton>
                </div>;
            case WidgetType.search:
                return <div className="widget-footer">
                    <NavigatorButton to="/search" className="accent-icon-button"
                                     getDynamicLink={() => { return this.getSearchPath() }}>
                        <FontAwesomeIcon icon={["fal", "magnifying-glass"]}/>
                        <span>{getLanguageEntry(LANG_PATH + "search>footer>search")}</span>
                    </NavigatorButton>
                </div>;
            case WidgetType.booker_info:
                if (!this.state.data || !this.state.data.bookings ||
                    this.state.loading || this.props.edit_mode ||
                    this.state.data.bookings.length === 0) {
                    return null;
                }
                return <div className="widget-footer">
                    <button className="accent-icon-button" onClick={() => {this.goToBooking()}}>
                        <FontAwesomeIcon icon={["fal", "info-circle"]}/>
                        <span>{getLanguageEntry(LANG_PATH + "booker_info>footer>booking_details")}</span>
                    </button>
                </div>;
            case WidgetType.booking_info:
                if (!this.state.data || !this.state.data.bookings ||
                    this.state.data.bookings.length === 0) {
                    return null;
                }
                return <div className="widget-footer">
                    <button className="accent-icon-button" onClick={() => {this.goToBooking()}}>
                        <FontAwesomeIcon icon={["fal", "info-circle"]}/>
                        <span>{getLanguageEntry(LANG_PATH + "booking_info>footer>booking_details")}</span>
                    </button>
                </div>;
            case WidgetType.create_advert:
                return <div className="widget-footer">
                    <NavigatorButton to="/desktop/adverts/editor/general" className="accent-icon-button">
                        <FontAwesomeIcon icon={["fal", "house"]}/>
                        <span>{getLanguageEntry(LANG_PATH + "create_advert>footer>create_now")}</span>
                    </NavigatorButton>
                </div>;
            default:
                return;
        }
    }

    buildFAQContent(faqItem) {
        let content = faqItem.content[globalLanguage] ?? faqItem.content[DEFAULT_LANGUAGE];
        let hasMore = false;
        if (content.length > 300) {
            content = this.truncateText(content);
            hasMore = true;
        }
        let moreText = getLanguageEntry("general>more");
        moreText = moreText.charAt(0).toUpperCase() + moreText.substring(1);
        return <div className="description-container">
            <span>{parse(content)}</span>
            {
                hasMore &&
                <a href={"/faq-help?category=" + faqItem.category + "&topic=" + faqItem.id} target="_blank" rel="noreferrer nofollow">
                    {" " + moreText}...
                </a>
            }
        </div>
    }

    buildTipsContent(tipItem) {
        let content = tipItem.content[globalLanguage] ?? tipItem.content[DEFAULT_LANGUAGE];
        let hasMore = false;
        if (content.length > 500) {
            content = this.truncateText(content);
            hasMore = true;
        }
        let moreText = getLanguageEntry("general>more");
        moreText = moreText.charAt(0).toUpperCase() + moreText.substring(1);
        return <div className="description-container">
            <span>{parse(content)}</span>
            {
                hasMore &&
                <a href={"/tips-tricks?category=" + tipItem.category + "&topic=" + tipItem.id} target="_blank" rel="noreferrer nofollow">
                    {" " + moreText}...
                </a>
            }
        </div>
    }

    truncateText(content) {
        content = content.substring(0, 300);
        if (content.lastIndexOf('.') > -1) {
            content = content.substring(0, content.lastIndexOf('.') + 1);
        }
        else {
            content = content.substring(0, content.lastIndexOf(' '));
        }
        return content;
    }

    createMessagePreview(message) {
        return <NavigatorButton className={"msg-preview-container" + (message.unread_msg_count > 0 ? " new" : "")} key={message.message.id}
                                to={"/desktop/messages?conversation=" + message.conversation_id + "&message=" + message.message.id}>
            <Avatar data={message.conversation_partner.avatar} size={30} />
            <div className="message-preview-data">
                <div className="name-container">
                    {message.conversation_partner.first_name + " " + message.conversation_partner.last_name}
                </div>
                <div className="message-info-container">
                    <div className="message-container">
                        {getConversationPreviewText(message.message, 40, false)}
                    </div>
                    <div className="timestamp-container">
                        {getTimestampLabel(message.message.timestamp)}
                    </div>
                </div>
                {
                    message.unread_msg_count > 0 &&
                    <div className="unread-counter">
                        {message.unread_msg_count > 3 ? "3+" : message.unread_msg_count}
                    </div>
                }
            </div>
        </NavigatorButton>
    }

    createInquiryPreview(inquiry) {
        let otherUser = inquiry.booker;
        if (!otherUser.id) {
            otherUser = {};
        }
        let name = otherUser.first_name + " " + otherUser.last_name;
        return <NavigatorButton className={"msg-preview-container new"} key={inquiry.message.id}
        to={"/desktop/messages?conversation=" + inquiry.conversation_id + "&message=" + inquiry.message.id}>
            <Avatar data={otherUser.avatar} size={30} />
            <div className="message-info-container">
                <div className="message-container">
                    {parse(getLanguageEntry(LANG_PATH + "inquiry>body>open_inquiry")
                        .replace("#", name))}
                </div>
                <div className="timestamp-container">
                    {getTimestampLabel(inquiry.message.timestamp)}
                </div>
            </div>
        </NavigatorButton>
    }

    createBookingInfoTable(booking) {
        if (!booking || !booking.advert || !booking.advert.owner) {
            return null;
        }
        let address = getFormattedAddress(booking.advert.address, true, true, true, false);
        let startDate = new Date(booking.booking.start);
        let endDate = new Date(booking.booking.end);
        let bookingSpan =
            getFormattedDate(startDate, false, false, false, true, false ,false) + ' - ' +
            getFormattedDate(endDate, false, false, false, true, false ,false);
        let keyStorage = booking.advert.access ? booking.advert.access
            .filter(a => a.type === AccessType.key_storage)[0] : null;
        let navLink = generateGoogleNavigationLink(booking.advert.address);
        return <div className="booking-overview-table">
            <table>
                <tbody>
                <tr>
                    <td>
                        <FontAwesomeIcon icon={["fal", "map-location-dot"]} fixedWidth={true}/>
                        <span>{getLanguageEntry("general>address")}</span>
                    </td>
                    <td>
                        <span>{address}</span><br />
                        {
                            navLink && navLink.startsWith("https://www.google.com/") &&
                            <a href={navLink} target="_blank" rel="noreferrer nofollow">
                                <FontAwesomeIcon icon={["fal", "diamond-turn-right"]}/>
                                <span>{getLanguageEntry(LANG_PATH + "booking_info>navigate")}</span>
                            </a>
                        }
                    </td>
                </tr>
                <tr>
                    <td>
                        <FontAwesomeIcon icon={["fal", "calendar"]} fixedWidth={true}/>
                        <span>{getLanguageEntry(LANG_PATH + "booking_info>booking_span")}</span>
                    </td>
                    <td>{bookingSpan}</td>
                </tr>
                {
                    booking.advert.access &&
                    <tr>
                        <td>
                            <FontAwesomeIcon icon={["fal", "key"]} fixedWidth={true}/>
                            <span>{getLanguageEntry(LANG_PATH + "booking_info>housing_access")}</span>
                        </td>
                        <td>
                            {getFormattedAccessText(booking.advert.access)}
                        </td>
                    </tr>
                }
                {
                    keyStorage &&
                    <tr>
                        <td>
                            <FontAwesomeIcon icon={["fal", "binary-lock"]} fixedWidth={true}/>
                            <span>
                                {getLanguageEntry("jackboard>adverts>advert_creator>access>key_storage_code")}
                            </span>
                        </td>
                        <td>
                            <SecretInputField disabled={true} initialValue={keyStorage.code} name="key_code" />
                        </td>
                    </tr>
                }
                {
                    booking.advert.wifi_ssid &&
                    <tr>
                        <td>
                            <FontAwesomeIcon icon={["fal", "router"]} fixedWidth={true}/>
                            <span>
                                {getLanguageEntry("jackboard>adverts>advert_creator>access>wifi_ssid")}
                            </span>
                        </td>
                        <td>{booking.advert.wifi_ssid}</td>
                    </tr>
                }
                {
                    booking.advert.wifi_code &&
                    <tr>
                        <td>
                            <FontAwesomeIcon icon={["fal", "wifi"]} fixedWidth={true}/>
                            <span>
                                {getLanguageEntry("jackboard>adverts>advert_creator>access>wifi_code")}
                            </span>
                        </td>
                        <td>
                            <div className="horizontal-form-group">
                                <input type="text" readOnly={true} value={booking.advert.wifi_code}/>
                                <button className="simple-icon-button outline-button black"
                                        onClick={() => {copyTextToClipboard(booking.advert.wifi_code)}}>
                                    <FontAwesomeIcon icon={["fal", "copy"]}/>
                                </button>
                            </div>
                        </td>
                    </tr>
                }
                <tr>
                    <td>
                        <FontAwesomeIcon icon={["fal", "user"]} fixedWidth={true}/>
                        <span>
                            {getLanguageEntry(LANG_PATH + "booking_info>your_landlord")}
                        </span>
                    </td>
                    <td>
                        <Link to={"/user/" + booking.advert.owner.id}>
                            {booking.advert.owner.first_name + " " + booking.advert.owner.last_name}
                        </Link>
                    </td>
                </tr>
                <tr>
                    <td>
                        <FontAwesomeIcon icon={["fal", "contact-card"]} fixedWidth={true}/>
                        <span>
                            {getLanguageEntry(LANG_PATH + "booking_info>contact")}
                        </span>
                    </td>
                    <td>
                        <button className="link-button"
                                onClick={() => {this.props.onSendMessage?.(booking.advert.owner)}}>
                            {getLanguageEntry("jackboard>dashboard>widgets>booker_info>contact_button_label")
                                .replace('@', booking.advert.owner.first_name)}
                        </button>
                    </td>
                </tr>
                {
                    booking.advert.owner.phone &&
                    <tr>
                        <td>
                            <FontAwesomeIcon icon={["fal", "phone"]} fixedWidth={true}/>
                            <span>
                                {getLanguageEntry("general>phone")}
                            </span>
                        </td>
                        <td>
                            <a href={"tel:" + booking.advert.owner.phone}>
                                {booking.advert.owner.phone}
                            </a>
                        </td>
                    </tr>
                }
                </tbody>
            </table>
        </div>;
    }

    createBookerInfoTable(booking) {
        if (!booking || !booking.booking || !booking.booking.booker) {
            return null;
        }
        let address = getFormattedAddress(booking.advert.address, true, true, true, false);
        let startDate = new Date(booking.booking.start);
        let endDate = new Date(booking.booking.end);
        let bookingSpan =
            getFormattedDate(startDate, false, false, false, true, false ,false) + ' - ' +
            getFormattedDate(endDate, false, false, false, true, false ,false);
        return <div className="booking-overview-table">
            <table>
                <tbody>
                    <tr>
                        <td>
                            <FontAwesomeIcon icon={["fal", "map-location-dot"]} fixedWidth={true}/>
                            <span>{getLanguageEntry("general>address")}</span>
                        </td>
                        <td>{address}</td>
                    </tr>
                    <tr>
                        <td>
                            <FontAwesomeIcon icon={["fal", "calendar"]} fixedWidth={true}/>
                            <span>{getLanguageEntry(LANG_PATH + "booking_info>booking_span")}</span>
                        </td>
                        <td>{bookingSpan}</td>
                    </tr>
                    <tr>
                        <td>
                            <FontAwesomeIcon icon={["fal", "user"]} fixedWidth={true}/>
                            <span>
                                {getLanguageEntry(LANG_PATH + "booker_info>your_guest")}
                            </span>
                        </td>
                        <td>
                            <Link to={"/user/" + booking.booking.booker.id}>
                                {booking.booking.booker.first_name + " " + booking.booking.booker.last_name}
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <FontAwesomeIcon icon={["fal", "contact-card"]} fixedWidth={true}/>
                            <span>
                                {getLanguageEntry(LANG_PATH + "booking_info>contact")}
                            </span>
                        </td>
                        <td>
                            <button className="link-button"
                                    onClick={() => {this.props.onSendMessage?.(booking.booking.booker)}}>
                                {getLanguageEntry("jackboard>dashboard>widgets>booker_info>contact_button_label")
                                    .replace('@', booking.booking.booker.first_name)}
                            </button>
                        </td>
                    </tr>
                    {
                        booking.booking.booker.phone &&
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={["fal", "phone"]} fixedWidth={true}/>
                                <span>
                                    {getLanguageEntry("general>phone")}
                                </span>
                            </td>
                            <td>
                                <a href={"tel:" + booking.booking.booker.phone}>
                                    {booking.booking.booker.phone}
                                </a>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    }

    createTodoTiles() {
        const { checkIns, checkOuts } = getTodoRelevantBookings(this.state.data.result_list, this.state.interval);
        const data = { check_ins: checkIns.length, check_outs: checkOuts.length, key_handovers: 0 };
        for (const booking of checkIns) {
            const advert = this.state.data.advert_map[booking.advert_id];
            if (advert && advert.access && advert.access.filter(a => a.type === AccessType.personal_handover).length > 0) {
                data.key_handovers += 1;
            }
        }
        let todoLangPath = "jackboard>todos>widget_labels>";
        return <>
            <div className={"todo-counter" + (data.check_ins > 0 ? " not-zero" : "")}>
                <div id="check-in-counter" className="counter">
                    {data.check_ins}
                </div>
                <div className="horizontal-form-group">
                    <FontAwesomeIcon icon={["fal", "house-return"]} flip="horizontal"/>
                </div>
                <div className="label">
                    {getLanguageEntry(todoLangPath + "check_ins")}
                </div>
            </div>
            <div className={"todo-counter" + (data.check_outs > 0 ? " not-zero" : "")}>
                <div id="key-handover-counter" className="counter">
                    {data.check_outs}
                </div>
                <div className="horizontal-form-group">
                    <FontAwesomeIcon icon={["fal", "house-leave"]}/>
                </div>
                <div className="label">
                    {getLanguageEntry(todoLangPath + "check_outs")}
                </div>
            </div>
            <div className={"todo-counter" + (data.key_handovers > 0 ? " not-zero" : "")}>
                <div id="key-handover-counter" className="counter">
                    {data.key_handovers}
                </div>
                <div className="horizontal-form-group">
                    <FontAwesomeIcon icon={["fal", "key"]}/>
                </div>
                <div className="label">
                    {parse(getLanguageEntry(todoLangPath + "key_handovers"))}
                </div>
            </div>
            {/*<div className={"todo-counter" + (data.cleaning > 0 ? " not-zero" : "")}>
                <div id="cleaning-counter" className="counter">
                    {this.state.data.cleaning}
                </div>
                <div className="horizontal-form-group">
                    <FontAwesomeIcon icon={["fal", "vacuum"]}/>
                </div>
                <div className="label">
                    {getLanguageEntry(todoLangPath + "cleanings")}
                </div>
            </div>*/}
        </>
    }

    goToBooking() {
        if (this.state.loading || !this.state.data || this.props.edit_mode ||
            !this.state.data.bookings || this.state.data.bookings.length === 0) {
            return;
        }
        let booking = this.state.data.bookings[this.state.page];
        let path = "desktop/bookings/" + (this.props.mode === Mode.renter ? "active" : "gantt") +
            "?id=" + booking.id;
        this.props.navHook?.(path);
    }

    getSearchPath() {
        let link = "/search";
        let query = "";
        if (this.search_location.current && this.search_calender.current) {
            let location = this.search_location.current.value;
            let start = this.search_calender.current.getSelectedStart();
            let end = this.search_calender.current.getSelectedEnd();
            let filter = {};
            if (location) {
                filter.location = location;
            }
            if (start) {
                filter.start = start;
            }
            if (end) {
                filter.end = end;
            }
            query = filterDataToQuery(filter);
        }
        return link + query;
    }

}
Widget.propTypes = {
    navHook: PropTypes.func.isRequired,
    type: PropTypes.oneOf(Object.values(WidgetType)).isRequired,
    mode: PropTypes.oneOf(Object.values(Mode)).isRequired,
    userData: PropTypes.any.isRequired,
    edit_mode: PropTypes.bool,
    onSendMessage: PropTypes.func
}
Widget.defaultProps = {
    edit_mode: false
}
export default Widget;