import React from "react";
import {Modal} from "react-bootstrap";
import {getLanguageEntry, MAX_MESSAGE_LENGTH, USER_REVIEW_CRITERIA_MAP} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FetchButton from "../buttons/FetchButton";
import PropTypes from "prop-types";
import {AdvertReviewCriteria, Mode} from "../../utils/Types.ts";
import TextArea from "../input/TextArea";
import RatingInput from "../input/RatingInput";
import {postHousingReview, postUserReview} from "../../utils/RESTInterface";
import _uniqueId from "lodash/uniqueId";

const LANG_PATH = "processes>reviews>"

class ComboReviewModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            loading: false,
            mode: null,
            booking: null,
            process_result: null,
            evaluation_criteria: null
        }
        this.review_form = React.createRef();
    }

    render() {
        if (!this.state.booking || !this.props.userData || !this.state.mode) {
            return <></>;
        }
        let user = this.props.userData.id === this.state.booking.booker ?
            this.state.booking.owner : this.state.booking.booker;
        let reviewTypes = this.state.mode === "advert" ?
            Object.values(AdvertReviewCriteria) :
            USER_REVIEW_CRITERIA_MAP.general.concat(USER_REVIEW_CRITERIA_MAP[this.state.mode]);
        let ratingMapLangPath = "components>reviews>" + (this.state.mode === "advert" ? "advert" : "user") +
            "_review>ratings>";
        let reviewLangPath = LANG_PATH + this.state.mode + "_review>"
        let title = getLanguageEntry(reviewLangPath + "header");
        let description = getLanguageEntry(reviewLangPath + "description")
                .replace("@", user.first_name) + " " +
                    getLanguageEntry(LANG_PATH + "fair_review");
        return (
            <Modal className={"review-modal user " + this.props.mode} show={this.state.show} size="lg" backdrop="static"
                   keyboard={false} onHide={() => { this.hide() }} dialogClassName="max-content" centered>
                <Modal.Header>
                    <h4>{title}</h4>
                    <button onClick={() => { this.hide() }} disabled={this.state.loading}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>
                {
                    !this.state.process_result &&
                    <Modal.Body>
                        <div className="description-container">{description}</div>
                        <form ref={this.review_form} id="review_form"
                              onSubmit={(e) => { this.sendReview(e) } }>
                            <div className="review-detail-input-container">
                                <table>
                                    <tbody>
                                        {
                                            reviewTypes.map((r, _) => {
                                                return <tr key={_uniqueId("review-" + r)}>
                                                    <td className="roomjack-headline">
                                                        {getLanguageEntry(ratingMapLangPath + r)}
                                                    </td>
                                                    <td>
                                                        <RatingInput name={r}
                                                                     initialValue={this.state.evaluation_criteria && this.state.evaluation_criteria[r] ?
                                                                         this.state.evaluation_criteria[r] : null} />
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <TextArea rows={5} name="description" maxLength={MAX_MESSAGE_LENGTH} />
                        </form>
                    </Modal.Body>
                }
                {
                    this.state.process_result &&
                    <Modal.Body>
                        <div className="process-modal-result-container">
                            <div className="process-result-image">
                                <img src={this.state.process_result.errorCode ?
                                    "https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear.png" :
                                    "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/hardfacts/thumbs_up.jpg"}
                                     alt={"User review " + (this.state.process_result.errorCode ? "failure" : "success")} />
                            </div>
                            <div className="process-result-message-box">
                                <div className="header">
                                    {getLanguageEntry(LANG_PATH + (this.state.process_result.errorCode ?
                                        "failure" : "thanking"))}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                }
                {
                    this.state.booking && !this.state.process_result &&
                    <Modal.Footer>
                        <FetchButton className="outline-button accent" loading={this.state.loading}
                                     loadingText={LANG_PATH + "buttons>transmit_review"}
                                     form="review_form" type="submit">
                            {getLanguageEntry(LANG_PATH + "buttons>send_review")}
                        </FetchButton>
                    </Modal.Footer>
                }
            </Modal>
        )
    }

    show(booking) {
        this.setState({
            show: true,
            mode: booking.owner === this.props.userData.id ? "renter" :
                booking.booker === this.props.userData.id ? (booking.housing_review ? "landlord" : "advert") : null,
            booking: booking,
            process_result: null,
            evaluation_criteria: null
        });
    }

    hide() {
        this.setState({
            show: false,
            mode: null,
            booking: null,
            process_result: null,
            evaluation_criteria: null
        })
    }

    sendReview(e) {
        e.preventDefault();
        if (this.review_form.current) {
            let formData = new FormData(this.review_form.current);
            let reviewData = {};
            if (formData.get("description") && formData.get("description").length > 0) {
                reviewData.description = formData.get("description").substring(0, MAX_MESSAGE_LENGTH);
            }
            for (const [k, v] of formData.entries()) {
                if (k === "description") {
                    continue;
                }
                let value = parseInt("" + v);
                if (isNaN(value)) {
                    return;
                }
                if (this.state.mode === "advert") {
                    reviewData[k] = value;
                }
                else {
                    if (!reviewData.evaluation_criteria) {
                        reviewData.evaluation_criteria = {};
                    }
                    reviewData.evaluation_criteria[k] = value;
                }
            }
            this.setState({
                loading: true,
                evaluation_criteria: reviewData.evaluation_criteria
            });
            let that = this;
            if (this.state.mode === "advert") {
                let housingID = this.state.booking.advert_id;
                if (this.state.booking.room_id) {
                    housingID += "SP" + this.state.booking.room_id;
                }
                postHousingReview(this.state.booking.id, housingID, reviewData, (response) => {
                    if (response.notifications) {
                        let user = JSON.parse(JSON.stringify(that.props.userData));
                        user.notification_data = response.notifications;
                        that.props.onUpdateUserData?.(user);
                    }
                    that.setState({
                        loading: false,
                        process_result: response
                    });
                });
            }
            else {
                reviewData.type = this.state.mode;
                let targetUserID = this.props.userData.id === this.state.booking.booker ?
                    this.state.booking.owner.id : this.state.booking.booker.id;
                postUserReview(this.state.booking.id, targetUserID, reviewData, (response) => {
                    if (response.notifications) {
                        let user = JSON.parse(JSON.stringify(that.props.userData));
                        user.notification_data = response.notifications;
                        that.props.onUpdateUserData?.(user);
                    }
                    that.setState({
                        loading: false,
                        process_result: response
                    });
                });
            }
        }
    }
}
ComboReviewModal.propTypes = {
    userData: PropTypes.any,
    mode: PropTypes.oneOf(Object.values(Mode)).isRequired,
    onUpdateUserData: PropTypes.func.isRequired
}
export default ComboReviewModal;