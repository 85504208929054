import React from "react";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import {getLanguageEntry} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FetchButton from "../buttons/FetchButton";
import {setProToolStatus} from "../../utils/RESTInterface";
import parse from "html-react-parser";
import CustomToggle from "../input/CustomToggle";

class ProToolModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            loading: false,
            advert: null,
            response: null
        }
        this.form = React.createRef();
    }

    render() {
        let descriptionPath;
        if (this.state.advert) {
            descriptionPath = "jackboard>adverts>advert_creator>pro_tool>description";
        }
        return (<Modal className={"pro-tool-modal landlord"} show={this.state.show} size="lg" backdrop="static"
                       keyboard={false} onHide={() => { this.hide() }} dialogClassName="max-content" centered>
            <Modal.Header>
                <h4>{getLanguageEntry("processes>pro_tool_modal>title")}</h4>
                <button onClick={() => { this.hide() }} disabled={this.state.loading}>
                    <FontAwesomeIcon icon={["fal", "close"]}/>
                </button>
            </Modal.Header>

            <Modal.Body style={{display: "grid", gap: "20px" }}>
                {
                    this.state.advert && !this.state.response &&
                    <div className="description-container">
                        {
                            parse(getLanguageEntry(descriptionPath))
                        }
                    </div>
                }
                {
                    this.state.advert && !this.state.response &&
                    <form ref={this.form} onSubmit={(e) => {this.changeProToolStatus(e)}}
                          style={{display: "grid", gap: "20px", justifyItems: "center"}}>
                        <div className="horizontal-form-group">
                            <label className="roomjack-headline" htmlFor="pro-tool-toogle"
                                   style={{fontSize: "12px"}}>
                                {getLanguageEntry("processes>pro_tool_modal>toggle_label")}
                            </label>
                            <CustomToggle required={true} name={"pro_tool"} leftValue={false} rightValue={true}
                                          id="pro-tool-toogle" defaultValue={!!this.state.advert.pro_tool}
                                          disabled={this.state.loading} />
                        </div>
                        <FetchButton loading={this.state.loading} loadingText="general>saving"
                                     className="outline-button accent" type="submit">
                            {getLanguageEntry("general>save")}
                        </FetchButton>
                    </form>
                }
                {
                    this.state.advert && this.state.response &&
                    <div className="process-modal-result-container">
                        <div className="process-result-image">
                            <img src={this.state.response.errorCode ?
                                "https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear.png" :
                                "https://roomjack.blob.core.windows.net/roomjack/content-images/High-Five.gif"}
                                 alt={"Advert pro status change " + (this.state.response.errorCode ? "failure" : "success")} />
                        </div>
                        <div className="process-result-message-box">
                            <div className="header">
                                {getLanguageEntry("processes>pro_tool_modal>" +
                                    (this.state.response.errorCode ? "failure" : "success"))}
                            </div>
                            {
                                this.state.response.errorCode &&
                                <div className="description-container">
                                    <span>{getLanguageEntry("general>please_contact_support")}</span>
                                    <button className="link-button" onClick={() => {this.props.onContactSupport()}}>
                                        {getLanguageEntry("general>support")}
                                    </button>.
                                </div>
                            }
                        </div>
                    </div>
                }
            </Modal.Body>
        </Modal>)
    }

    show(advert) {
        this.setState({
            show: true,
            loading: false,
            advert: advert,
            response: null
        })
    }

    hide() {
        this.setState({
            show: false
        })
    }

    changeProToolStatus(e) {
        e.preventDefault();
        let form = new FormData(this.form.current);
        let that = this;
        this.setState({loading: true});
        let state = form.get("pro_tool") === "true";
        setProToolStatus(this.state.advert.id, state, (response) => {
            that.setState({
                loading: false,
                response: response
            });
            if (response.data) {
                that.props.onAdvertChanged?.();
            }
        })
    }

}
ProToolModal.propTypes = {
    onContactSupport: PropTypes.func.isRequired,
    onAdvertChanged: PropTypes.func.isRequired
}
export default ProToolModal;