import { useRef, useEffect } from "react";
import PropTypes from "prop-types";

function Autocomplete(props) {

    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const options = {
        fields: props.fields,
        types: props.types
    };
    useEffect(() => {
        if (props.componentRestrictions !== undefined) {
            options.componentRestrictions = props.componentRestrictions
        }
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            inputRef.current,
            options
        );
        autoCompleteRef.current.addListener("place_changed", async function() {
            const place = await autoCompleteRef.current.getPlace();
            if (place) {
                let address = place.address_components;
                let streetNoElem = address.filter(ac => ac.types[0] === 'street_number')[0];
                let streetElem = address.filter(ac => ac.types[0] === 'route')[0];
                let line1 = '';
                if (streetElem !== undefined) {
                    line1 += streetElem.long_name;
                }
                if (streetNoElem !== undefined) {
                    if (line1.length > 0) {
                        line1 += ' ';
                    }
                    line1 += streetNoElem.long_name;
                }
                if (line1.length > 0) {
                    inputRef.current.value = line1;
                }
                props.onPlaceSelected(line1, place);
            }
        })
    });
    return (
        <input id={props.id} className={props.className} ref={inputRef} name={props.name}
               onKeyUp={props.onKeyUp} defaultValue={props.initialValue}
               onChange={(e) => props.onChange?.(e)}/>
    );

}

Autocomplete.propTypes = {
    onPlaceSelected: PropTypes.func.isRequired,
    id: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.string),
    types: PropTypes.arrayOf(PropTypes.string),
    componentRestrictions: PropTypes.object,
    onChange: PropTypes.func,
    initialValue: PropTypes.string,
    onKeyUp: PropTypes.func
}
Autocomplete.defaultProps = {
    fields: [ 'geometry', 'address_component' ],
    types: [ "address" ]
}
export default Autocomplete;