import {languageData} from "../../resources/language";
import CustomLink from "../components/CustomLink";
import md5 from "md5";
import React from "react";
import {COUNTRIES, PHONE_PREFIXES} from "../../resources/countries";
import Dropdown from "../components/input/Dropdown";
import _uniqueId from "lodash/uniqueId";
import MultiDropdown from "../components/input/MultiDropdown";
import packageJson from "../../../package.json";
import {
    CHARACTERISTIC_ICON_MAP,
    EQUIPMENT_ICON_MAP, PREDEFINED_FEE_TYPES, PREDEFINED_LONG_TERM_FEE_TYPES, PREDEFINED_RUNNING_COSTS
} from "../../resources/housings";
import {
    getAdvertRatingTypeAverage,
    getGeneralAdvertRatingAverage,
    getGeneralUserRatingAverage
} from "./StatisticHelper";
import {
    BookingType,
    Mode,
    Page,
    ReportReason,
    UserReviewCriteria,
    AdvertType,
    WidgetType,
    PaymentType, UnitType, RuntimeMode,
} from "./Types.ts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";
import axios from "axios";
import {Link} from "react-router-dom";
import {RUNTIME_MODE} from "../../resources/version-dependent-variables";
import OpenAI from "openai";

export let clientCountry = null;
export let globalLanguage = 'de';
export let DEFAULT_LANGUAGE = 'de';
export let ADVERT_LOCATIONS = [];
export let SORTING_WEIGHTS = [];
export let BUSINESS_MODELS = {};
export let BOOSTERS = [];
export let REVOCATION_PERIOD_DURATION = 1209600000;
export let FAQ_HELP = [];
export let TIPS_TRICKS = [];
export let BOOKING_BLOCK_COLORS = [];
export let LATEST_APP_VERSION = packageJson.version;
export let OPENAI_API_KEY;
export let OpenAi;

export let RJ_REVIEW_MODAL_TRIGGERED = false;

//region global constant variables
//region Attribute names
export const SESSION_ID = "session_id";
export const DEVICE_ID = "device-id";
export const LANGUAGE = 'language';
export const EMAIL = "email";
export const USERNAME = "username";
export const PASSWORD = "password";
export const FIRST_NAME = "first_name";
export const LAST_NAME = "last_name";
export const MODE = "mode";
export const PREFERRED_MENU_MODE = "preferred_menu_mode";
export const ADDRESS = "address";
export const LINE_1 = "line_1";
export const LINE_2 = "line_2";
export const POSTAL_CODE = "postal_code";
export const CITY = "city";
export const COUNTRY = "country";
export const PHONE = "phone";
export const TAX_ID = "tax_id";
export const VAT_ID = "vat_id";
export const TAX_COUNTRY = "tax_country";
export const OPERATING_COUNTRIES = "operating_countries";
export const ABOUT_ME = "about_me";
export const BIRTHDAY = "birthday";
export const COMPANY = "company";
export const COMPANY_REG_NO = "company_reg_no";
export const START = "start";
export const END = "end";
export const SEARCH = "search";
export const RADIUS = "radius";
export const HOUSING_TYPES = "housing_types";
export const BOOKING_TYPE  = "booking_type";
export const DIRECT_BOOKING = "direct_booking";
export const VIA_INQUIRY = "via_inquiry";
export const MIN_PRICE = "min_price";
export const MAX_PRICE = "max_price";
export const MIN_SURFACE = "min_surface";
export const MAX_SURFACE = "max_surface";
export const BARRIER_FREE = "barrier_free";
export const FREE_CANCELLABLE = "free_cancellable";
export const EQUIPMENT = 'equipment';
export const CHARACTERISTICS = 'characteristics';
//endregion
export const SUPPORT_MAIL_ADDRESS = "support@roomjack.at";
export const COLOR_LOGO = "https://roomjack.blob.core.windows.net/roomjack/email-images/roomjack_color.svg";
export const WHITE_LOGO = "https://roomjack.blob.core.windows.net/roomjack/email-images/roomjack_white.svg";
export const REASON = "reason";
export const AZURE_STORAGE_PATH = "https://roomjack.blob.core.windows.net/";

export const SELECTABLE_REPORT_REASONS = [
    ReportReason.contact, ReportReason.bug_report,
    ReportReason.help, ReportReason.misc
];
export const PRESET_REPORT_REASONS = [
    ReportReason.advert_report, ReportReason.user_report, ReportReason.email_change
];
export const USER_REVIEW_CRITERIA_MAP = {
    general: [UserReviewCriteria.general_rating, UserReviewCriteria.communication_rating],
    landlord: [UserReviewCriteria.reliability_rating],
    renter: [UserReviewCriteria.house_rules_followed_rating]
}

export const WIDGET_MODE_MAP = {
    landlord: [
        WidgetType.message, WidgetType.inquiry, WidgetType.todo_list,
        WidgetType.occupancy_rate, WidgetType.customer_satisfaction, WidgetType.income,
        WidgetType.click_booking, WidgetType.personalize_dashboard, WidgetType.tips_tricks,
        WidgetType.faq, WidgetType.create_advert, WidgetType.booker_info],
    renter: [
        WidgetType.message, WidgetType.booking_info,
        WidgetType.personalize_dashboard, WidgetType.tips_tricks, WidgetType.faq,
        WidgetType.search]
};
export const WIDGET_ICON_MAP = {
    message: "comment-dots", inquiry: "calendar-days",
    todo_list: "clipboard-list-check", occupancy_rate: "bed-front",
    customer_satisfaction: "star", income: "euro",
    click_booking: "arrow-pointer", booking_info: "circle-info",
    tips_tricks: "lightbulb-on", faq: "circle-question",
    search: "magnifying-glass-location", booker_info: "circle-info"
}
export const START_WIDGET_LIST = [WidgetType.create_advert, WidgetType.personalize_dashboard];
export const WIDGET_SYNC_LIST = [WidgetType.message, WidgetType.inquiry, WidgetType.todo_list,
    WidgetType.occupancy_rate, WidgetType.customer_satisfaction, WidgetType.income,
    WidgetType.click_booking, WidgetType.booking_info, WidgetType.booker_info];

export const LeapYearDays = [31,29,31,30,31,30,31,31,30,31,30,31];
export const NormalYearDays = [31,28,31,30,31,30,31,31,30,31,30,31];
export const TimeUnitMap = { day: { week: 7, month: 30, year: 365 },
    week: { month: 4, year: 52 },
    month: { year: 12} }

export const DEFAULT_MAP_LOCATION = { lat: 48.208174, lng: 16.373819 }
export const EARTH_RADIUS = 6371;
export const PRICE_LIMIT = 999999;
export const PRICE_LIMIT_CENTS = PRICE_LIMIT * 100;
export const AVATAR_SIZE_LIMIT = 16000000;
export const SUPPORTED_IMAGE_TYPES = ["image/png", "image/jpeg", "image/bmp"];
export const WITHDRAWAL_PERIOD = 1209600000;
export const PRICE_RANGE_MINIMUM = 0;
export const PRICE_RANGE_MAXIMUM = 500;
export const SURFACE_RANGE_MINIMUM = 5;
export const SURFACE_RANGE_MAXIMUM = 500;
export const RADIUS_VALUES = ['none', '1', '2', '5', '10', '25', '50', '75', '100'];

export const TIME_UNIT_VALUE_MAP = {
    DAY: 4, HOUR: 3, MINUTE: 2, SECOND: 1
}

export const MAX_HOUSE_RULE_LENGTH = 30;
export const MAX_COMPANY_NAME_LENGTH = 50;
export const MAX_TITLE_LENGTH = 75;
export const MAX_DESCRIPTION_LENGTH = 2000;
export const MAX_CHARS_ABOUT_ME = 500;
export const MAX_MESSAGE_LENGTH = 1000;
export const MAX_ACCESS_DESCRIPTION_LENGTH = 1000;
export const MAX_MISC_ACCESS_TEXT_LENGTH = 200;
export const MAX_CUSTOM_CHARACTERISTIC_LENGTH = 20;
export const MAX_CUSTOM_EQUIPMENT_LENGTH = 20;
export const MIN_BOOKING_LENGTH = 1;
export const MAX_BOOKING_LENGTH = 364;

export const INITIAL_CHARGE_OFFSET_DAYS = 7;
export const EMOJI_REGEX = /:#@\w+@#:/g;

export const PRODUCTION_START = new Date(2022, 0, 1, 0, 0, 0, 0);
export const SUPPORTED_COUNTRIES = ["de"];
export const SUPPORTED_PAYMENT_GATEWAYS = [PaymentType.paypal, PaymentType.klarna];
export const PAYMENT_GATEWAY_LOGO_MAP = {
    paypal: "https://www.paypalobjects.com/webstatic/de_DE/i/de-pp-logo-100px.png",
    klarna: "https://docs.klarna.com/assets/media/d57b84c8-a93f-4a15-940e-aff9bd6ac208/Klarna_MarketingBadge_Pink_RGB.svg"
}
//endregion

export function isDev() {
    return RUNTIME_MODE === RuntimeMode.development;
}

export function isURLValid(url) {
    try {
        axios({
            method: "HEAD",
            url: url,
        }).then(response => {
            console.log(response);
        });
        return true;
    } catch (err) {
        return false;
    }
}

//region language handling
export function setLanguage(lang) {
    if (languageData[lang]) {
        globalLanguage = lang;
        return true;
    }
    return false;
}

export function setCountry(country) {
    clientCountry = country;
}

export function getLanguageEntry(entryName, replaceChars=null, replaceText=null) {
    if (!entryName || entryName.split('>').length === 0) {
        return '';
    }
    let split = entryName.split('>');
    let languageEntry = languageData[globalLanguage];
    for (let i=0; i<split.length; i++) {
        let index = parseInt(split[i]);
        if (isNaN(index)) {
            languageEntry = languageEntry[split[i]];
        }
        else {
            languageEntry = languageEntry[index];
        }
        if (languageEntry === undefined) {
            return entryName;
        }
    }
    if (replaceChars !== null && replaceText !== null) {
        if (typeof replaceChars === 'string' && typeof replaceText === 'string') {
            languageEntry = languageEntry.replaceAll(replaceChars, replaceText);
        }
        if (Array.isArray(replaceText) && Array.isArray(replaceChars) &&
            replaceText.length === replaceChars.length) {
            for (let i=0; i<replaceText.length; i++) {
                languageEntry = languageEntry.replaceAll(replaceChars[i], replaceText[i]);
            }
        }
    }
    return languageEntry;
}

export function getLanguageNameList(languageList) {
    let list = [];
    if (languageList) {
        for (const lang of (languageList)) {
            let langName = getLanguageEntry("languages>" + lang);
            if (langName !== lang) {
                list.push(langName);
            }
        }
    }
    return list;
}

export function buildTextLinkContainer(containerClass, textPath, linkArray) {
    return <div className={containerClass}>
        {
            linkArray === undefined || linkArray.length === 0 ?
                getLanguageEntry(textPath) :
                createLinkTextBlock(getLanguageEntry(textPath), linkArray)
        }
    </div>
}

export function createLinkTextBlock(text, linkArray) {
    let split = text.split("#link#");
    return split.reduce((acc, curr, index) => {
        let isLastItem = index === split.length - 1;
        return [...acc, curr, isLastItem ? null :
            <CustomLink textPath={linkArray[index].text_path}
                        key={"link-" + md5("" + Date.now()) + "-" + index}
                        href={linkArray[index].url}
                        rel={linkArray[index].rel ? linkArray[index].rel : ''}
                        target={linkArray[index].target ? linkArray[index].target : ''} />];
    }, []);
}

export function buildTextLinkButtonContainer(containerClass, textPath, buttonArray) {
    return <div className={containerClass}>
        {
            buttonArray === undefined ?
                getLanguageEntry(textPath) :
                createLinkButtonTextBlock(getLanguageEntry(textPath), buttonArray)
        }
    </div>
}

export function createLinkButtonTextBlock(text, buttonArray) {
    let split = text.split("#link-button#");
    return split.reduce((acc, curr, index) => {
        let isLastItem = index === split.length - 1;
        return [...acc, curr, isLastItem ? null :
            <button className="link-button" key={"link-button-" + md5("" + Date.now()) + "-" + index}
                    onClick={() => { buttonArray[index].onClick() } }>
                {getLanguageEntry(buttonArray[index].text_path)}
            </button>];
    }, []);
}

export function activateBreakLines(text) {
    let split = text.split("<br>");
    return split.reduce((acc, curr, index) => {
        let isLastItem = index === split.length - 1;
        return [...acc, curr, isLastItem ? null :
            <br key={_uniqueId("break-line")}/>];
    }, []);
}

export function getCountryDropdownItems(addNullOption=false, onlyCountries) {
    let options = addNullOption ? [{value: 'none', label: getLanguageEntry("general>not_specified")}] : [];
    COUNTRIES.forEach(function(country) {
        if (!onlyCountries || onlyCountries.includes(country.alpha2Code.toLowerCase())) {
            options.push({ value: country.alpha2Code, label: country.translations[globalLanguage] ?
                    country.translations[globalLanguage] : country.name});
        }
    });
    return options;
}

export function getPhoneCountryPrefixItems() {
    let items = [];
    PHONE_PREFIXES.forEach((prefixObject) => {
        let country = COUNTRIES.filter(c => {
            return c.alpha2Code.toLowerCase() === prefixObject.country.toLowerCase()
        })[0];
        if (country && country.translations[globalLanguage]) {
            items.push({
                value: prefixObject.value,
                label: getLanguageEntry(country.translations[globalLanguage]) + " (" + prefixObject.value + ")",
                selected_label: country.alpha2Code.toUpperCase() + " (" + prefixObject.value + ")"
            });
        }
    });
    items.sort((a, b) => a.label.localeCompare(b.label));
    return items;
}

export function getModeDropdownItems() {
    return [
        {value: Mode.renter, label: "general>modes>renter"},
        {value: Mode.landlord, label: "general>modes>landlord"}];
}

export function getAdvertLocationItems() {
    let locationList = [{ value: 'none', label: 'landing_pages>renter>search_bar>flexible_search' }];
    if (ADVERT_LOCATIONS) {
        for (const location of ADVERT_LOCATIONS) {
            locationList.push({ value: location, label: location });
        }
    }
    return locationList;
}

export function getHousingTypeItems() {
    let itemList = [];
    for (const housingType of Object.values(AdvertType)) {
        itemList.push({ value: housingType, label: "advert_attributes>advert_types>" + housingType });
    }
    return itemList;
}

export function getAdvertDropdownItems(adverts) {
    let itemList = [];
    for (const advert of adverts) {
        itemList.push({ value: advert.id, label: advert.title ?? "-" });
    }
    return itemList;
}

export function getLanguageDropdownItems() {
    let itemList = [];
    for (const lang of Object.keys(languageData.de.languages)) {
        itemList.push({ value: lang, label: "languages>" + lang });
    }
    return itemList;
}

export function getDayDropdownItems(dayArray) {
    let itemList = [];
    for (const day of dayArray) {
        itemList.push({ value: day, label: getCountLabel(day, "day") });
    }
    return itemList;
}

export function getVATDropdownItems(taxArray) {
    let itemList = [];
    for (const tax of taxArray) {
        itemList.push({
            value: { included: false, percent: tax },
            label: `${getLanguageEntry("general>not_included_short")} ${tax}% ${getLanguageEntry("components>invoicing>value_added_tax_short")}`
        });
        itemList.push({
            value: { included: true, percent: tax },
            label: `${getLanguageEntry("general>included_short")} ${tax}% ${getLanguageEntry("components>invoicing>value_added_tax_short")}`
        });
    }
    return itemList;
}

export function getRatingText(reviewCount) {
    return reviewCount + " " + getLanguageEntry("general>rating" + (reviewCount !== 1 ? "s" : ""));
}

export function createFeatureContainer(index, imageURL, imageAlt, copyright, langPath, linkArray) {
    return <div className={"roomjack-feature" + (imageURL ? (index % 2 === 1 ? " inverted" : "") : " full-width")}>
        {
            imageURL &&
            <div className="feature-image-container">
                <img src={imageURL} alt={imageAlt}/>
                {
                    copyright &&
                    <span className="image-copyright-label"><FontAwesomeIcon icon={["fal", "copyright"]} fixedWidth={true}/> Copyright: {copyright}</span>
                }
            </div>
        }
        <div className="feature-content-container">
            <h3 className="roomjack-headline accent">
                {getLanguageEntry(langPath + index + ">header")}
            </h3>
            {buildTextLinkContainer("feature-text-content", langPath + index + ">content", linkArray)}
        </div>
    </div>
}

export function createNewsBox(news, index) {
    return <div key={"news-" + index}>
        <Link className="news-box" to={news.url}>
            <div className="news-image-container" style={{backgroundImage: news.image_url}}>
                <span role="img" aria-label={getLanguageEntry(news.image_alt)}> </span>
                {
                    news.copyright &&
                    <span className="image-copyright-label">
                            <FontAwesomeIcon icon={["fal", "copyright"]} fixedWidth/> Copyright: {news.copyright}</span>
                }
            </div>
            <div className="news-date-label">{getFormattedDate(news.date, true)}</div>
            <div className="news-title-label">{getLanguageEntry(news.title)}</div>
            <div className="news-description">{getLanguageEntry(news.description)}</div>
            <div className="news-read-more">{getLanguageEntry("landing_pages>read_more")}</div>
        </Link>
    </div>
}
//endregion

//region time methods
export function getFormattedDate(date, shortMonthName=false, includeTime=false, includeDayName=false,
                          includeDay=true, shortDayName=false, useMonthName=true,
                          displaySeconds=true) {
    if (globalLanguage === DEFAULT_LANGUAGE) {
        let dateString;
        if (useMonthName) {
            let monthName = shortMonthName ? getLanguageEntry("general>month_names_short")[date.getMonth()] :
                getLanguageEntry("general>month_names")[date.getMonth()];
            dateString = monthName + ' ' + date.getFullYear();
        }
        else {
            dateString = '' + (date.getMonth() + 1);
            if (dateString.length === 1) {
                dateString = '0' + dateString;
            }
            dateString += '.' + date.getFullYear();
        }

        if (includeDay) {
            dateString = (date.getDate() > 9 || useMonthName ? date.getDate() : '0' + date.getDate()) +
                (useMonthName ? '. ' : '.') + dateString;
            if (includeDayName) {
                let dayNameArray = shortDayName ? getLanguageEntry("general>day_names_short") :
                    getLanguageEntry("general>day_names");
                dateString = dayNameArray[date.getDay()] + ', ' + dateString;
            }
        }
        if (includeTime) {
            let hours = '' + date.getHours();
            if (hours.length === 1) hours = '0' + hours;
            let minutes = '' + date.getMinutes();
            if (minutes.length === 1) minutes = '0' + minutes;
            dateString += ', ' + hours + ':' + minutes;
            if (displaySeconds) {
                let seconds = '' + date.getSeconds();
                if (seconds.length === 1) seconds = '0' + seconds;
                dateString += ':' + seconds;
            }
        }
        return dateString;
    }

    let dateString = date.getDate() + '-' + ((date.getMonth() + 1) % 12) + '-' + date.getFullYear()
    if (includeDayName) {
        dateString = getLanguageEntry("general>day_names")[date.getDay()] + ', ' + dateString;
    }

    if (includeTime) {
        let meridiem = date.getHours() < 12 ? 'AM' : 'PM';
        let hours = '' + (date.getHours() === 0 ? 12 : date.getHours() > 12 ? date.getHours() - 12 : date.getHours());
        if (hours.length === 1) hours = '0' + hours;
        let minutes = '' + date.getMinutes();
        if (minutes.length === 1) minutes = '0' + minutes;
        dateString += ', ' + hours + ':' + minutes;
        if (displaySeconds) {
            let seconds = '' + date.getSeconds();
            if (seconds.length === 1) seconds = '0' + seconds;
            dateString += ':' + seconds + ' ' + meridiem;
        }
    }
    return dateString;
}

export function millisecondsToTime(inputMS, showDays=true, showHours=true,
                            showMinutes=true, showSeconds=true,
                            roundUpDays=false) {
    let millis = inputMS % 1000;
    inputMS = (inputMS - millis) / 1000;
    let seconds = inputMS % 60;
    inputMS = (inputMS - seconds) / 60;
    let minutes = inputMS % 60;
    inputMS = (inputMS - minutes) / 60;
    let hours = inputMS % 24;
    let days = (inputMS - hours) / 24;
    if (roundUpDays && (hours + minutes + seconds + millis) > 0) {
        days += 1;
    }
    let text = '';
    if (showDays) {
        text += days + ' ' + (days === 1 ? getLanguageEntry("general>day") : getLanguageEntry("general>days"));
    }
    if (showHours && hours > 0) {
        if (text.length > 0) text += ' ';
        text += hours + ' ' + getLanguageEntry("general>hours");
    }
    if (showMinutes && minutes > 0) {
        if (text.length > 0) text += ' ';
        text += minutes + ' ' + getLanguageEntry("general>minutes");
    }
    if (showSeconds && seconds > 0) {
        if (text.length > 0) text += ' ';
        text += seconds + ' ' + getLanguageEntry("general>seconds");
    }
    if (text.length === 0) {
        text += millis + ' ms';
    }
    return text;
}

export function millisecondsToClockTime(inputMS, showSeconds=false) {
    let millis = inputMS % 1000;
    inputMS = (inputMS - millis) / 1000;
    let seconds = inputMS % 60;
    inputMS = (inputMS - seconds) / 60;
    let minutes = inputMS % 60;
    inputMS = (inputMS - minutes) / 60;
    let hours = inputMS % 24;
    let text = (hours < 10 ? "0" : "") + hours + ":" +
                (minutes < 10 ? "0" : "") + minutes;
    if (showSeconds && seconds > 0) {
        text += ":" + (seconds < 10 ? "0" : "") + seconds
    }
    return text;
}

export function convertMilliseconds(inputMS, timeUnit="day", rounding="round") {
    // initialize divider with seconds
    let divider = 1000;
    if (timeUnit !== 'second') {
        switch (timeUnit) {
            case 'minute':
                divider *= 60;
                break;
            case 'hour':
                divider *= 60 * 60;
                break;
            case 'day':
                divider *= 60 * 60 * 24;
                break;
            case 'week':
                divider *= 60 * 60 * 24 * 7;
                break;
            case 'month':
                divider *= 60 * 60 * 24 * 30;
                break;
            case 'year':
                divider *= 60 * 60 * 24 * 365;
                break;
            default:
                divider *= 1;
        }
    }
    switch (rounding) {
        case 'floor':
            return Math.floor(inputMS / divider);
        case 'ceil':
            return Math.ceil(inputMS / divider);
        default:
            return Math.round(inputMS / divider);
    }
}

export function isLeapYear(year) {
    year = parseInt(year);
    if (year % 4 !== 0) {
        return false;
    } else if (year % 400 === 0) {
        return true;
    } else return year % 100 !== 0;
}

/**
 * Determines the number of days in a certain year for a certain month
 * @param year The target year.
 * @param month The target month (1-12)
 * @returns {number}
 */
export function getDayCountOfMonth(year, month) {
    if (year === null || year === undefined ||
        month === null || month === undefined) {
        return -1;
    }
    let days = isLeapYear(year) ? LeapYearDays : NormalYearDays;
    return days[month-1];
}

/**
 *
 * @param value
 * @param unit
 * @returns {number}
 */
export function getTimeUnitInMS(value=1, unit=TIME_UNIT_VALUE_MAP.DAY) {
    let multiplier = 1000;
    if (unit > 1) {
        multiplier *= 60;
    }
    if (unit > 2) {
        multiplier *= 60;
    }
    if (unit > 3) {
        multiplier *= 24;
    }
    return value * multiplier;
}

export function convertMSToDays(milliseconds) {
    return Math.floor(milliseconds / 1000 / 24 / 3600);
}

export function getPreviousDay(date) {
    let nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() - 1);
    return nextDay;
}

export function getNextDay(date) {
    let nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay;
}

export function compareDates(dateA, dateB) {
    dateA = getDayFromBegin(dateA);
    dateB = getDayFromBegin(dateB);
    let diff = dateA.getTime() - dateB.getTime();
    return diff === 0 ? 0 : diff / Math.abs(diff);
}

export function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function convertDateToStandardDateString(date) {
    let year = date.toLocaleString("default", { year: "numeric" });
    let month = date.toLocaleString("default", { month: "2-digit" });
    let day = date.toLocaleString("default", { day: "2-digit" });
    return year + '-' + month + '-' + day;
}

export function getShortDate(date) {
    let result = '';
    result += date.getFullYear();
    result = result.substring(2);
    result += (date.getMonth() + 1);
    result += (date.getDate());
    return result;
}

/**
 * Calculates the difference in days between date2 as start date end date1 as end date.
 * @param date1 The end date.
 * @param date2 The start date.
 * @returns {number} The difference in days.
 */
export function getNightsBetweenDates(date1, date2) {
    // to avoid miscalculations reset both dates to the start of the day,
    // since we only want the number of nights between them
    let date1Reset = getDayFromBegin(new Date(date1));
    let date2Reset = getDayFromBegin(new Date(date2));
    return Math.ceil((date1Reset - date2Reset) / (1000 * 60 * 60 * 24));
}

export function getDayCountBetweenDates(date1, date2) {
    return getNightsBetweenDates(date1, date2) + 1;
}

export function getMonthsBetweenDates(date1, date2) {
    let startDate = date1 < date2 ? new Date(date1) : new Date(date2);
    let endDate = date1 < date2 ? new Date(date2) : new Date(date1);
    let startYear = startDate.getFullYear();
    let startMonth = startDate.getMonth();

    let endYear = endDate.getFullYear();
    let endMonth = endDate.getMonth();
    return Math.round(endYear - startYear) * 12 + (endMonth - startMonth);
}

export function resetDayToBegin(date) {
    date.setHours(0, 0, 0, 0);
}

export function getMonthBegin(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getDayFromBegin(date=null) {
    let reset = date === null ? new Date() : new Date(date);
    resetDayToBegin(reset);
    return reset;
}

export function getDateBefore(date, daysEarlier) {
    return new Date(date.getTime() - daysEarlier * 24 * 60 * 60 * 1000);
}

export function isDateInTimeInterval(dateMS, startMS, endMS) {
    return dateMS >= startMS && dateMS <= endMS;
}

export function isValidTimeInterval(interval, allowOpenEnd = false) {
    return Number.isInteger(interval.start) && interval.start > 0 &&
    (allowOpenEnd || (Number.isInteger(interval.end) && interval.end > interval.start));
}


export function areTimeIntervalsOverlapping(i1, i2, allowOpenEnd = false) {
    if (!isValidTimeInterval(i1, allowOpenEnd) || !isValidTimeInterval(i2, allowOpenEnd)) {
        return false;
    }

    if (i2.start < i1.start) {
        return !i2.end || i2.end > i1.start;
    }
    return !i1.end || i2.start <= i1.end;
}

export function areDatesOnSameDay(first, second) {
    return first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate();
}

export function areDatesInSameWeek(first, second) {
    if (first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth()) {
        if (first.getDate() === second.getDate()) {
            return true;
        }
        if (Math.abs(getNightsBetweenDates(first, second)) >= 7) {
            return false;
        }
        return !((first.getDate() < second.getDate() && first.getDay() === 0) ||
            (second.getDate() < first.getDate() && second.getDay() === 0));
    }
    return false;
}

export function areDatesInSameMonth(first, second) {
    return first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth();
}

export function areDatesInSameYear(first, second) {
    return first.getFullYear() === second.getFullYear();
}

export function equalizeTimeOfDates(sourceDate, targetDate) {
    targetDate.setHours(sourceDate.getHours());
    targetDate.setMinutes(sourceDate.getMinutes());
    targetDate.setSeconds(sourceDate.getSeconds());
    targetDate.setMilliseconds(sourceDate.getMilliseconds());
}

export function isDateToday(date) {
    let today = getDayFromBegin();
    return date.getTime() >= today.getTime() && date.getTime() < today.getTime() + getTimeUnitInMS();
}

export function wasDateYesterday(date) {
    let today = getDayFromBegin();
    return date.getTime() < today.getTime() && date.getTime() >= today.getTime() - getTimeUnitInMS();
}

/**
 * This method converts a javascript calendar day of week value (Sunday(0) to saturday(6))
 * to the normalized day of week value with Monday(1) to Sunday(7)
 * @param day The int value of the day of week from javascript calendar (where sunday is 0 saturday is 6)
 * @return The normalized day of week integer value with monday (1) - to sunday (7)
 */
export function normalizeDayOfWeek(day) {
    if (day < 0 || day > 7) {
        return Math.max(1, Math.min(7, day));
    }
    return day === 0 ? 7 : day;
}

/**
 * This method creates a timestamp label in dependency of how old the timestamp is:
 * 1. If the timestamp is today, it returns the clock time
 * 2. If the timestamp was yesterday, it returns yesterday
 * 3. Otherwise it returns the date in locale dd.mm.yyyy format
 */
export function getTimestampLabel(timestamp) {
    let date = new Date(timestamp);
    let tsText;
    if (isDateToday(date)) {
        tsText = getDateClockTime(timestamp)
    }
    else if (wasDateYesterday(date)) {
        tsText = getLanguageEntry("general>yesterday");
    }
    else {
        tsText = getFormattedDate(date, false, false, false, true, false, false, false);
    }
    return tsText;
}

export function getDateClockTime(timestamp) {
    let date = new Date(timestamp);
    let hours = date.getHours() < 10 ? '0' + date.getHours() : '' + date.getHours();
    let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : '' + date.getMinutes();
    return hours + ':' + minutes;
}
//endregion

//region browser query handling methods
export function queryParamsToFilterData(params) {
    // create basic search data
    let start = new Date(getDayFromBegin().getTime() + getTimeUnitInMS(2));
    let end = new Date(start.getTime() + getTimeUnitInMS(1));
    let filterData = { start: start, end: end};

    // search from and to
    if (params.has(START)) {
        let startDate = getDayFromBegin(new Date(params.get('start')));
        if (startDate instanceof Date && startDate.getTime() >= start.getTime()) {
            filterData.start = startDate;
        }
    }

    if (params.has(END)) {
        let endDate = getDayFromBegin(new Date(params.get('end')));
        if (endDate instanceof Date && endDate.getTime() > filterData.start.getTime()) {
            filterData.end = endDate;
        }
    }

    // location
    let searchTarget = params.get("location");
    if (searchTarget !== null && ADVERT_LOCATIONS.length > 0) {
        let locationSplit = searchTarget.toLowerCase().replaceAll('%20', ' ')
            .replaceAll('+', ' ').split(' ');
        let targetLocation = null;
        let splitMatchCounter = 0;
        for (const location of ADVERT_LOCATIONS) {
            if (locationSplit.length === 1 && location.toLowerCase() === locationSplit[0]) {
                targetLocation = location;
            }
            if (locationSplit.length > 1) {
                let matchCounter = 0;
                for (const split of locationSplit) {
                    if (location.toLowerCase().includes(split)) {
                        matchCounter += 1;
                    }
                }
                if (matchCounter > splitMatchCounter) {
                    splitMatchCounter = matchCounter;
                    targetLocation = location;
                }
            }
        }
        if (targetLocation !== null) {
            filterData.location = targetLocation;
        }
    }

    // radius
    if (params.has(RADIUS) && params.get(RADIUS) !== 'none' &&
        RADIUS_VALUES.includes(params.get(RADIUS))) {
        filterData.radius = parseIntegerInput(params.get(RADIUS).number);
    }

    // housing types
    if (params.has(HOUSING_TYPES)) {
        let housingTypes = params.get(HOUSING_TYPES).split(';');
        let validHousingTypes = [];
        for (const housingType of housingTypes) {
            if (AdvertType[housingType]) {
                validHousingTypes.push(housingType);
            }
        }
        if (validHousingTypes.length > 0) {
            filterData.housing_types = validHousingTypes;
        }
    }

    // booking type
    if (params.has(BOOKING_TYPE) &&
        (params.get(BOOKING_TYPE) === BookingType.direct || params.get(BOOKING_TYPE) === BookingType.inquiry)) {
        filterData.booking_type = params.get(BOOKING_TYPE);
    }

    // price range
    if (params.has(MIN_PRICE)) {
        let parseResult = parseFloatInput(params.get(MIN_PRICE));
        if (parseResult.valid && parseResult.number >= PRICE_RANGE_MINIMUM &&
            parseResult.number <= PRICE_RANGE_MAXIMUM) {
            filterData.min_price = parseResult.number;
        }
    }

    if (params.has(MAX_PRICE)) {
        let parseResult = parseFloatInput(params.get(MAX_PRICE));
        if (parseResult.valid && parseResult.number >= PRICE_RANGE_MINIMUM &&
            parseResult.number <= PRICE_RANGE_MAXIMUM) {
            filterData.max_price = parseResult.number;
        }
    }

    // surface range
    if (params.has(MIN_SURFACE)) {
        let parseResult = parseFloatInput(params.get(MIN_SURFACE));
        if (parseResult.valid && parseResult.number >= SURFACE_RANGE_MINIMUM &&
            parseResult.number <= SURFACE_RANGE_MAXIMUM) {
            filterData.min_surface = parseResult.number;
        }
    }

    if (params.has(MAX_SURFACE)) {
        let parseResult = parseFloatInput(params.get(MAX_SURFACE));
        if (parseResult.valid && parseResult.number >= SURFACE_RANGE_MINIMUM &&
            parseResult.number <= SURFACE_RANGE_MAXIMUM) {
            filterData.max_surface = parseResult.number;
        }
    }

    // region barrier free
    if (params.has(BARRIER_FREE) && params.get(BARRIER_FREE) === 'true') {
        filterData.barrier_free = true;
    }

    // region free cancellation
    if (params.has(FREE_CANCELLABLE) && params.get(FREE_CANCELLABLE) === 'true') {
        filterData.free_cancellable = true;
    }

    // equipment
    if (params.has(EQUIPMENT)) {
        let equipmentList = params.get(EQUIPMENT).split(';');
        let validEquipment = [];
        for (const equipment of equipmentList) {
            if (EQUIPMENT_ICON_MAP[equipment]) {
                validEquipment.push(equipment);
            }
        }
        if (validEquipment.length > 0) {
            filterData.equipment = validEquipment;
        }
    }

    // characteristics
    if (params.has(CHARACTERISTICS)) {
        let characteristicList = params.get(CHARACTERISTICS).split(';');
        let validCharacteristics = [];
        for (const characteristic of characteristicList) {
            if (CHARACTERISTIC_ICON_MAP[characteristic]) {
                validCharacteristics.push(characteristic);
            }
        }
        if (validCharacteristics.length > 0) {
            filterData.characteristics = validCharacteristics;
        }
    }

    return filterData;
}

export function filterDataToQuery(filterData) {
    let query = '';
    if (filterData.location !== undefined && filterData.location !== null) {

        query += "location=" + filterData.location.replaceAll(' ', '%20').toLowerCase();
    }

    // search from and to
    if (filterData.start !== undefined) {
        if (query.length > 0) {
            query += '&';
        }
        let startDate = filterData.start;
        query += START + '=' + convertDateToStandardDateString(startDate);
    }
    if (filterData.end !== undefined) {
        if (query.length > 0) {
            query += '&';
        }
        let endDate = filterData.end;
        query += END + '=' + convertDateToStandardDateString(endDate);
    }

    // radius
    if (filterData.radius !== undefined) {
        if (query.length > 0) {
            query += '&';
        }
        query += RADIUS + '=' + filterData.radius;
    }

    // housing types
    if (filterData.housing_types !== undefined) {
        if (filterData.housing_types.length !== Object.keys(languageData.advert_type_list).length) {
            let housingTypeQueryString = '';
            for (const housingType of filterData.housing_types) {
                if (housingTypeQueryString.length > 0) {
                    housingTypeQueryString += ';';
                }
                housingTypeQueryString += housingType;
            }
            if (housingTypeQueryString.length > 0) {
                if (query.length > 0) {
                    query += '&';
                }
                query += HOUSING_TYPES + '=' + housingTypeQueryString;
            }
        }
    }

    // booking type
    if (filterData.booking_type !== undefined) {
        if (query.length > 0) {
            query += '&';
        }
        query += BOOKING_TYPE + '=' + filterData.booking_type;
    }

    // price range
    if (filterData.min_price !== undefined) {
        if (query.length > 0) {
            query += '&';
        }
        query += MIN_PRICE + '=' + filterData.min_price;
    }
    if (filterData.max_price !== undefined) {
        if (query.length > 0) {
            query += '&';
        }
        query += MAX_PRICE + '=' + filterData.max_price;
    }

    // surface range
    if (filterData.min_surface !== undefined) {
        if (query.length > 0) {
            query += '&';
        }
        query += MIN_SURFACE + '=' + filterData.min_surface;
    }
    if (filterData.max_surface !== undefined) {
        if (query.length > 0) {
            query += '&';
        }
        query += MAX_SURFACE + '=' + filterData.max_surface;
    }

    // barrier free
    if (filterData.barrier_free !== undefined) {
        if (query.length > 0) {
            query += '&';
        }
        query += BARRIER_FREE + '=' + filterData.barrier_free;
    }

    // free cancellation
    if (filterData.free_cancellable !== undefined) {
        if (query.length > 0) {
            query += '&';
        }
        query += FREE_CANCELLABLE + '=' + filterData.free_cancellable;
    }

    //equipment
    if (filterData.equipment !== undefined) {
        let equipmentQueryString = '';
        for (const equipment of filterData.equipment) {
            if (equipmentQueryString.length > 0) {
                equipmentQueryString += ';';
            }
            equipmentQueryString += equipment;
        }
        if (equipmentQueryString.length > 0) {
            if (query.length > 0) {
                query += '&';
            }
            query += EQUIPMENT + '=' + equipmentQueryString;
        }
    }

    // characteristics
    if (filterData.characteristics !== undefined) {
        let characteristicsQueryString = '';
        for (const characteristic of filterData.characteristics) {
            if (characteristicsQueryString.length > 0) {
                characteristicsQueryString += ';';
            }
            characteristicsQueryString += characteristic;
        }
        if (characteristicsQueryString.length > 0) {
            if (query.length > 0) {
                query += '&';
            }
            query += CHARACTERISTICS + '=' + characteristicsQueryString;
        }
    }
    return query.length > 0 ? '?' + query : '';
}
//endregion

//region general helper methods
export function normalizeNumber(number, min, max) {
    if (min > max) {
        const newMin = max;
        max = min;
        min = newMin;
    }
    return Math.max(min, Math.min(max, number));
}

export function setRoomJackReviewModalTriggered(triggered) {
    RJ_REVIEW_MODAL_TRIGGERED = triggered;
}

export function fillInvoiceNumber(invoiceNumber) {
    return '0'.repeat( 6 - ('' + invoiceNumber).length) + invoiceNumber;
}

export function prepareDisplayedMessage(input, breakLine, maxLength=-1, allowMultiRow=true) {
    if (!allowMultiRow) {
        input = input.replaceAll("\n", " ");
    }
    if (maxLength > 0) {
        input = truncateText(input, maxLength);
    }
    let lines = input.split("\n").map(i => i.split("<br>")).flat();
    let parsedMessageItems = [];
    for (let i=0; i<lines.length; i++) {
        parsedMessageItems.push({object: <span key={_uniqueId("message-line-" + i)}>{lines[i]}</span>});
        if (i < lines.length - 1) {
            parsedMessageItems.push({object: breakLine ? <br key={_uniqueId("linebreak-" + i)} /> : " "});
        }
    }
    return parsedMessageItems;
}

export function megaByteToByte(megabyte) {
    return megabyte * 1048576;
}

export function componentToHex(c) {
    let hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

export function RGBToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function hexToRGB(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex);
    if (result) {
        if (result[4] === undefined) {
            return {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            }
        }
        else if (result.length === 5) {
            return {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16),
                a: parseInt(result[4], 16) / 255.0
            }
        }
    }
    return { r: 0, g: 0, b: 0 };
}

export function hexToRGBString(hex) {
    let resultColor = hexToRGB(hex);
    if (resultColor.a !== undefined) {
        return 'rgba(' + resultColor.r + ',' + resultColor.g + ',' + resultColor.b + ',' + resultColor.a + ')';
    }
    return 'rgb(' + resultColor.r + ',' + resultColor.g + ',' + resultColor.b + ')';
}

export function getNextPage(actualPage, direction, pageCount) {
    if (direction < 0) {
        let page = actualPage > 0 ? actualPage : pageCount;
        return page - 1;
    }
    return (actualPage + 1) % pageCount;
}

export function priceToString(priceLong, currencyBefore=false, fractionDigits=2, currency='€') {
    let price;
    if (fractionDigits === 0) {
        price = Math.round(priceLong / 100).toFixed(fractionDigits).replace('.', ',');
    }
    else {
        price = (Math.round(priceLong) / 100).toFixed(fractionDigits).replace('.', ',');
    }
    let decimalPrice = price.substring(0, price.indexOf(','));
    let split = decimalPrice.split('').reverse().join('').match(/.{1,3}/g);
    if (split !== null) {
        decimalPrice = split.join('.').split('').reverse().join('');
    }
    price = decimalPrice + price.substring(price.indexOf(','));
    return currencyBefore ? currency + ' ' + price : price + currency;
}

export function centsToPriceWithComma(cents) {
    return (cents / 100).toFixed(2).replace(".", ",");
}

export function getPageType() {
    let url = window.location.pathname;
    if (url.startsWith("/search")) {
        return Page.search;
    }
    if (url.startsWith("/profile")) {
        return Page.profile;
    }
    if (url.startsWith("/desktop")) {
        return Page.desktop;
    }
    switch (url) {
        case "/":
        case "/vermieten":
            return Page.main;
        case '/content/b2b':
        case '/content/roomjack-is-green':
        case '/content/linz':
        case '/content/rent-responsible':
            return Page.content;
        default:
            return Page.not_found;
    }
}

export function groupArrayBy(array, itemCount) {
    let result = [];
    for (const item of array) {
        if (result.length === 0 || result[result.length - 1].length === itemCount) {
            result.push([]);
        }
        result[result.length - 1].push(item);
    }
    return result;
}

export function isValidPassword(password) {
    if (password.length >= 8 && password.length <= 20) {
        return containsDigit(password) && containsUpperCase(password) && containsLowerCase(password);
    }
    return false;
}

export function isLetter(c) {
    return c.toLowerCase() !== c.toUpperCase();
}

export function isDigit(c) {
    return c >= '0' && c <= '9';
}

export function containsDigit(str) {
    for (let i=0; i<str.length; i++) {
        if (isDigit(str.charAt(i))) {
            return true;
        }
    }
    return false;
}

export function containsUpperCase(str) {
    for (let i=0; i<str.length; i++) {
        if (isLetter(str.charAt(i)) && str.charAt(i) === str.charAt(i).toUpperCase()) {
            return true;
        }
    }
    return false;
}

export function containsLowerCase(str) {
    for (let i=0; i<str.length; i++) {
        if (isLetter(str.charAt(i)) && str.charAt(i) === str.charAt(i).toLowerCase()) {
            return true;
        }
    }
    return false;
}

export function determineFlagIcon(iso) {
    let flagName;
    switch (iso) {
        case 'en':
            flagName = 'gb';
            break;
        case 'el':
            flagName = 'gr';
            break;
        case 'da':
            flagName = 'dk';
            break;
        case 'cs':
            flagName = 'cz';
            break;
        case 'bs':
            flagName = 'ba';
            break;
        case 'sl':
            flagName = 'si';
            break;
        case 'sv':
            flagName = 'se';
            break;
        case 'vi':
            flagName = 'vn';
            break;
        case 'zh':
            flagName = 'cn';
            break;
        case 'ar':
            flagName = 'ae';
            break;
        case 'ja':
            flagName = 'jp';
            break;
        case 'ko':
            flagName = 'kr';
            break;
        case 'hi':
            flagName = 'in';
            break;
        default:
            flagName = iso;
    }
    return "http://purecatamphetamine.github.io/country-flag-icons/3x2/" + flagName.toUpperCase() + ".svg";
}

export function readImageFile(file, onload) {
    let fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => { onload?.(fileReader.result); };
}

export function getFileNameFromBase64(base64) {
    if (base64.startsWith("data:")) {
        let startIndex = base64.indexOf("name=") + 5;
        let endIndex = base64.indexOf(";base64,");
        return base64.substring(startIndex, endIndex);
    }
    return base64;
}

export async function fileListToBase64(fileList) {
    // here will be array of promisified functions
    const promises = []

    // loop through fileList with for loop
    for (let i = 0; i < fileList.length; i++) {
        promises.push(getBase64(fileList[i]))
    }

    // array with base64 strings
    return await Promise.all(promises)
}

export function getBase64(file) {
    const reader = new FileReader()
    return new Promise(resolve => {
        reader.onload = ev => {
            const base64String = ev.target.result;
            let prefixIndex = base64String.indexOf(";base64,");
            const fileName = file.name;
            const base64StringWithFileName = `data:${file.type};name=${fileName};base64,${prefixIndex === -1 ? base64String : base64String.slice(prefixIndex + ";base64,".length)}`;
            resolve(base64StringWithFileName);
        }
        reader.readAsDataURL(file)
    })
}

export function getMousePositionOnElement(e, element) {
    let x = e.clientX - element.getBoundingClientRect().left;
    let y = e.clientY - element.getBoundingClientRect().top;
    return { x: x, y: y };
}

export function copyTextToClipboard(text) {
    navigator.clipboard.writeText(text).then(function () {
        alert(getLanguageEntry("expose>alerts>copy_to_clipboard_success"));
    }, function (err) {
        alert(getLanguageEntry("expose>alerts>copy_to_clipboard_failure"));
    });
}

export function truncateText(text, maxLength) {
    if (text.length > maxLength) {
        return text.substring(0, maxLength - 3) + "...";
    }
    return text;
}

export function generateIBANLabel(country, last4) {
    return country + '&#8226;&#8226;' +
        '&nbsp;&#8226;&#8226;&#8226;&#8226;' +
        '&nbsp;&#8226;&#8226;&#8226;&#8226;' +
        '&nbsp;&#8226;&#8226;&#8226;&#8226;' +
        '&nbsp;&#8226;&#8226;' + last4.substring(0, 2) + '&nbsp;' + last4.substring(2);
}

export function generateCreditCardLabel(last4) {
    return '&#8226;&#8226;&#8226;&#8226;&nbsp;' + last4;
}

export function generateCreditCardExpiryLabel(month, year) {
    let result = '' + month;
    if (result.length === 1) result = '0' + result;
    result += '/' + year;
    return result;
}

export function getCountLabel(count, langEntry) {
    return count + ' ' + getLanguageEntry("general>" + langEntry + (count === 1 ? "" : "s"));
}

export function resetScroll() {
    window.scrollTo(0, 0);
}
//endregion

//region advert helper methods
export function getTodoRelevantBookings(bookings, interval) {
    if (!bookings) {
        return {
            checkIns: [],
            checkOuts: []
        }
    }
    let today = new Date();
    resetDayToBegin(today);
    let startMS;
    let length;
    switch (interval) {
        case 1:
            startMS = getNextDay(today).getTime()
            length = 1;
            break;
        case 2:
            startMS = today.getTime();
            length = 7;
            break;
        case 3:
            startMS = today.getTime();
            length = 30;
            break;
        default:
            startMS = today.getTime();
            length = 1;
            break;
    }
    let endMS = startMS + getTimeUnitInMS(length);
    return {
        checkIns: bookings.filter(b => b.start >= startMS && b.start < endMS),
        checkOuts: bookings.filter(b => b.end >= startMS && b.end < endMS)
    }
}

export function dateStringToObject(dateString) {
    let date = new Date(dateString);
    if (date.toString() === "Invalid Date") {
        return null;
    }
    return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
    }
}

export function parseIntegerInput(str) {
    let number = parseInt(str);
    let valid = !isNaN(number);
    return { valid: valid, number: number };
}

export function parsePriceInput(str) {
    let costs = parseFloat(str.replace(',', '.'));
    let valid = !isNaN(costs) && costs > 0;
    return { valid: valid, costs: Math.min(costs, PRICE_LIMIT), costs_cent: Math.min(Math.trunc(costs * 100), PRICE_LIMIT_CENTS)};
}

export function parseFloatInput(str) {
    let number = parseFloat(str.replace(',', '.'));
    let valid = !isNaN(number);
    return { valid: valid, number: number };
}

export function getFormattedAddress(address, addLine1=true, addLine2=true, addCity=true,
                             addPostalCode=true, addCountry=true) {
    let result = '';
    if (addLine1 && address.line_1 !== undefined) {
        result += address.line_1;
    }
    if (addLine2 && address.line_2 !== undefined) {
        result += ' (' + address.line_2 + ')';
    }
    if (addCity && address.city !== undefined) {
        if (result.length > 0) {
            result += ', ';
        }
        result += address.city;
        if (addPostalCode && address.postal_code !== undefined) {
            result += ' ' + address.postal_code;
        }
    }
    if (addCountry && address.country !== undefined) {
        let country = COUNTRIES.filter(c => c.alpha2Code.toLowerCase() === address.country.toLowerCase())[0];
        if (country !== undefined) {
            if (result.length > 0) {
                result += ', ';
            }
            result += (country.translations.hasOwnProperty(globalLanguage) ?
                country.translations[globalLanguage] : country.name);
        }
    }
    return result
}

export function determineBookableHousings(advert, group=true) {
    let result = [];
    if (advert.bookable) {
        result.push(advert);
    }
    if (advert.rooms) {
        result.push(...advert.rooms.filter(r => r.bookable));
    }
    return result;
}

export function determineSearchTargets(inputList) {
    let gold_advert_1 = null;
    let gold_advert_2 = null;
    // get the adverts with gold and gold+ booster
    let goldBoosterAdverts = inputList
        .filter(a => (a.gold_boosters !== undefined && a.gold_boosters[0].expiry_date > Date.now()) ||
            (a.gold_plus_boosters !== undefined && a.gold_plus_boosters[0].expiry_date > Date.now()));
    // try to pick a random advert from the gold list first, if there are some gold adverts
    if (goldBoosterAdverts.length > 0) {
        // pick a random advert
        gold_advert_1 = goldBoosterAdverts[Math.floor(Math.random() * goldBoosterAdverts.length)];
        gold_advert_1.gold = 1;
        // remove it from the gold-advert- and the input-list
        goldBoosterAdverts = goldBoosterAdverts.filter(a => a.id !== gold_advert_1.id);
        inputList = inputList.filter(a => a.id !== gold_advert_1.id);

        // try to pick a random advert from the gold plus list, if there are any
        if (goldBoosterAdverts.length > 0) {
            // pick a random advert
            gold_advert_2 = goldBoosterAdverts[Math.floor(Math.random() * goldBoosterAdverts.length)];
            gold_advert_2.gold = 2;
            // remove it from the input-list
            inputList = inputList.filter(a => a.id !== gold_advert_2.id);
        }
    }

    let maxClicks = 0;
    let maxReviews = 0;
    let maxSharing = 0;
    let maxFavouriteCount = 0;
    let resultList = [];
    for (let i=0; i<inputList.length; i++) {
        let advert = inputList[i];
        let displayAdvert;
        // if the main advert is a search target, add a copy to the list
        // then remove all other search_target flags from it
        if (advert.search_target !== undefined && advert.search_target) {
            displayAdvert = JSON.parse(JSON.stringify(advert));
            if (displayAdvert.rooms !== undefined) {
                for (let room of displayAdvert.rooms) {
                    delete room.search_target;
                }
            }
            if (displayAdvert.clicks !== undefined) {
                maxClicks = Math.max(displayAdvert.clicks.length, maxClicks);
            }
            if (displayAdvert.reviews !== undefined) {
                maxReviews = Math.max(getReviewsOfHousing(displayAdvert, displayAdvert).length,
                    maxReviews);
            }
            if (displayAdvert.sharing !== undefined) {
                maxSharing = Math.max(displayAdvert.sharing, maxSharing);
            }
            if (displayAdvert.favourite_count !== undefined) {
                maxFavouriteCount = Math.max(displayAdvert.favourite_count, maxFavouriteCount);
            }
            resultList.push(displayAdvert);
        }

        // check if the advert has rooms as search target
        if (advert.rooms) {
            for (let room of advert.rooms) {
                if (room.search_target !== undefined && room.search_target) {
                    displayAdvert = JSON.parse(JSON.stringify(advert));
                    delete displayAdvert.search_target;
                    for (let roomCopy of displayAdvert.rooms) {
                        if (roomCopy.id !== room.id) {
                            delete roomCopy.search_target;
                        }
                    }
                    if (room.clicks !== undefined) {
                        maxClicks = Math.max(room.clicks.length, maxClicks);
                    }
                    if (displayAdvert.reviews !== undefined) {
                        maxReviews = Math.max(getReviewsOfHousing(displayAdvert, room).length, maxReviews);
                    }
                    if (room.sharing !== undefined) {
                        maxSharing = Math.max(room.sharing, maxSharing);
                    }
                    if (room.favourite_count !== undefined) {
                        maxFavouriteCount = Math.max(room.favourite_count, maxFavouriteCount);
                    }
                    resultList.push(displayAdvert);
                }
            }
        }
    }

    // calculate weights
    for (const advert of resultList) {
        calculateHousingWeight(advert, maxClicks, maxReviews, maxSharing, maxFavouriteCount);
    }


    // sort the list first by search preference booster and then by the calculated weights
    resultList.sort(function(a, b) {
        let aBooster = a.search_preference_boosters !== undefined &&
        a.search_preference_boosters[0].expiry_date > Date.now() ? -1 : 1;
        let bBooster = b.search_preference_boosters !== undefined &&
        b.search_preference_boosters[0].expiry_date > Date.now() ? -1 : 1;
        if (aBooster - bBooster !== 0) {
            return aBooster - bBooster;
        }
        return b.weight - a.weight;
    });
    for (let i=0; i<resultList.length; i++) {
        resultList[i].search_index = i;
    }

    // finally set the two gold adverts in the begin of the result list if found
    if (gold_advert_2 !== null) {
        resultList = [gold_advert_2].concat(resultList);
    }
    if (gold_advert_1 !== null) {
        resultList = [gold_advert_1].concat(resultList);
    }

    return resultList;
}

export function getReviewsOfHousing(advert, housing) {
    if (advert.reviews === undefined) {
        return [];
    }
    let reviewList = Object.values(advert.reviews).slice(0);
    if (housing.id.startsWith('room_')) {
        return reviewList.filter(r => r.room_id === housing.id);
    }
    else {
        return reviewList.filter(r => r.advert_id === housing.id);
    }
}

export function determineHousingRatingLabel(advert, target) {
    let reviews = getReviewsOfHousing(advert, target === undefined ? advert : target);
    let ratingValue = getGeneralAdvertRatingAverage(reviews);
    let ratingText = '-';
    if (ratingValue > 0) {
        ratingText = ratingValue.toFixed(1);
    }
    return ratingText;
}

export function calculateHousingWeight(advert, maxClicks, maxReviews, maxSharing, maxFavouriteCount) {
    let target = determineTargetHousing(advert);
    let clicks = target.clicks !== undefined ? target.clicks.length : 0;
    let sharing = target.sharing !== undefined ? target.sharing : 0;
    let favouriteCount = target.favourite_count !== undefined ? target.favourite_count : 0;
    let advertReviews = advert.reviews !== undefined ? Object.values(advert.reviews) : [];
    let landlordReviews = advert.owner.reviews !== undefined ? Object.values(advert.owner.reviews) : [];
    advertReviews = getReviewsOfHousing(advertReviews, target);

    // get ratings
    let pricePerformanceAverage = getAdvertRatingTypeAverage('price_performance_rating', advertReviews);
    let landlordRating = getGeneralUserRatingAverage(landlordReviews, -1, -1, 'landlord');
    let clickRating = clicks / Math.max(1, maxClicks);
    let ratingRating = advertReviews.length / Math.max(1, maxReviews);
    let sharingRating = sharing / Math.max(1, maxSharing);
    let favouriteCountRating = favouriteCount / Math.max(1, maxFavouriteCount);
    let directBookingRating = advert.booking_type === 'direct' ? 1 : 0;
    let locationRating = getAdvertRatingTypeAverage('location_rating', advertReviews);
    let cleanlinessRating = getAdvertRatingTypeAverage('cleanliness_rating', advertReviews);
    let equipmentRating = getAdvertRatingTypeAverage('equipment_rating', advertReviews);
    let wifiRating = getAdvertRatingTypeAverage('wifi_rating', advertReviews);
    let checkInRating = getAdvertRatingTypeAverage('check_in_rating', advertReviews);
    let qualityRating = (cleanlinessRating + equipmentRating + wifiRating + checkInRating) / 3;

    // calculate weights
    let pricePerformanceWeight = pricePerformanceAverage / 5 * SORTING_WEIGHTS.price_performance;
    let landlordRatingWeight = landlordRating / 5 * SORTING_WEIGHTS.landlord_rating;
    let clicksWeight = clickRating * SORTING_WEIGHTS.clicks;
    let ratingWeight = ratingRating * SORTING_WEIGHTS.review_count;
    let sharingWeight = sharingRating * SORTING_WEIGHTS.share_count;
    let favouriteCountWeight = favouriteCountRating * SORTING_WEIGHTS.favourite_count;
    let bookingTypeWeight = directBookingRating * SORTING_WEIGHTS.booking_type;
    let locationWeight = locationRating / 5 * SORTING_WEIGHTS.location;
    let qualityWeight = qualityRating / 5 * SORTING_WEIGHTS.quality;

    advert.weight = pricePerformanceWeight + landlordRatingWeight + clicksWeight + ratingWeight + sharingWeight +
        favouriteCountWeight + bookingTypeWeight + locationWeight + qualityWeight;
}

export function collectHousingListAttributes(advert, target=null) {
    if (!target) {
        target = advert;
    }
    if (!target) {
        return { images: [], equipment: [] }
    }
    let images = [];
    let equipment = new Set();
    // collect data from the target, if there is one
    if (target.images !== undefined && target.images.length > 0) {
        images = images.concat(target.images);
    }
    if (target.equipment && target.equipment.length > 0) {
        target.equipment.forEach(function (e) {
            equipment.add(e)
        });
    }
    // if the main advert is not the target
    if (target.id !== advert.id) {
        if (advert.images && advert.images.length > 0) {
            images = images.concat(advert.images);
        }
        if (advert.equipment && advert.equipment.length > 0) {
            advert.equipment.forEach(e => equipment.add(e));
        }
    }
    // otherwise try to collect the data also from the children
    if (advert.rooms) {
        for (const room of advert.rooms) {
            if (target.id === room.id) {
                continue;
            }
            if (room.equipment) {
                room.equipment.forEach(e => equipment.add(e));
            }
            if (!room.bookable && room.images !== undefined) {
                images = images.concat(room.images);
            }
        }
    }
    return { images: images, equipment: Array.from(equipment) }
}

export function determineTargetHousing(advert) {
    let target = advert.search_target ? advert : undefined;
    if (target === undefined && advert.rooms !== undefined) {
        target = advert.rooms.filter(r => r.search_target)[0];
    }
    return target;
}

export function findTargetHousing(advert, housingID) {
    let split = housingID.split("SP");
    let id = split[split.length - 1];
    if (advert.id === id) {
        return advert;
    }
    if (id.startsWith("room")) {
        if (advert.rooms) {
            let room = advert.rooms.filter(r => r.id === id)[0];
            if (room) {
                return room;
            }
        }
        return null;
    }
    return null;
}

export function determineAdvertNameLabel(advert) {
    if (advert.type !== undefined && languageData[globalLanguage].advert_attributes.advert_types[advert.type] !== undefined) {
        return getLanguageEntry("advert_attributes>advert_types>" + advert.type);
    }
    return '';
}

export function buildHousingID(advert) {
    let id = advert.id;
    if (advert.rooms) {
        let targetRoom = advert.rooms.filter(r => r.search_target !== undefined && r.search_target)[0];
        if (targetRoom) {
            id = advert.id + "SP" + targetRoom.id;
        }
    }
    return id;
}

export function findSurfaceLimits(targetList) {
    let min = undefined;
    let max = undefined;
    for (const target of targetList) {
        if (!target.search_target || target.surface_size === undefined) {
            continue;
        }
        if (min === undefined || target.surface_size < min) {
            min = target.surface_size;
        }
        if (max === undefined || target.surface_size > max) {
            max = target.surface_size;
        }
    }
    let limit = { min: min, max: max};
    limit.biggest = function() {
        return this.min !== undefined ?
            (this.max === undefined ? this.min : Math.max(this.min, this.max)) :
            this.max;
    }
    limit.smallest = function() {
        return this.min !== undefined ?
            (this.max === undefined ? this.min : Math.min(this.min, this.max)) :
            this.max;
    }
    return limit;
}

export function isAdvertAvailable(advert, onlyCompleteAndPublished=true) {
    return (!onlyCompleteAndPublished || (advert.published && advert.complete)) &&
        (!advert.delete || !advert.delete.active) &&
        (!advert.blocking || !advert.blocking.active);
}

export function generateGoogleNavigationLink(address) {
    if (address === null || address === undefined ||
        address.line_1 === undefined || address.city === undefined) {
        return "";
    }
    let googleDirLink = 'https://www.google.com/maps/dir/?api=1&destination=';

    googleDirLink += address.line_1.replaceAll(' ', '+') + '%2C';
    if (address.postal_code !== undefined) {
        googleDirLink += address.postal_code.replaceAll(' ', '+') + '%2C';
    }
    googleDirLink += address.city.replaceAll(' ', '+');
    return googleDirLink;
}

export function getFormattedAccessText(access) {
    let accessString = '';
    for (const accessType of access) {
        if (accessString.length > 0) {
            accessString += ', ';
        }
        if (!languageData.de.jackboard.adverts.advert_creator
            .access.access_types[accessType.type]) {
            accessString += accessType.code;
        }
        else {
            accessString += getLanguageEntry("jackboard>adverts>advert_creator>access>access_types>" + [accessType.type]);
        }
    }
    return accessString;
}
//endregion

//region chat helper methods
export function countUnreadMessages(conversation, userID) {
    conversation.unread_msg_count = conversation.messages
        .filter(m =>  m.sender_id !== userID && !m.read).length;
}

export function sortConversationsByTimestamp(conversationList, userID) {
    conversationList.sort(function(a, b) {
        if (a.pinned !== undefined && a.pinned.includes(userID)) {
            if (b.pinned !== undefined && b.pinned.includes(userID)) {
                return b.messages[0].timestamp - a.messages[0].timestamp;
            }
            return -1;
        }
        else if (b.pinned !== undefined && b.pinned.includes(userID)) {
            return 1;
        }
        if (!a.messages || a.messages.length === 0) {
            return 0;
        }
        if (!b.messages || b.messages.length === 0) {
            return 0;
        }
        return b.messages[0].timestamp - a.messages[0].timestamp;
    });
}

export function getConversationPreviewText(message, maxLength=-1, allowMultiRow=true) {
    let previewText;
    let langPath = "jackboard>messages>";
    if (message) {
        if (message.image) {
            previewText = <span>
                <FontAwesomeIcon icon={["fal", "image"]}/>
                {getLanguageEntry(langPath + "attachments>image_file")}
            </span>;
        }
        else if (message.inquiry) {
            previewText = <span>
                <FontAwesomeIcon icon={["fal", "calendar-circle-user"]}/>
                {getLanguageEntry(langPath + "attachments>booking_request")}
            </span>;
        }
        else if (message.booking) {
            previewText = <span>
                <FontAwesomeIcon icon={["fal", "calendar-circle-plus"]}/>
                {getLanguageEntry(langPath + "attachments>booking_invitation")}
            </span>;
        }
        else if (message.invoice_data) {
            previewText = <span>
                <FontAwesomeIcon icon={["fal", "file-invoice"]}/>
                {getLanguageEntry(langPath + "attachments>invoice_data")}
            </span>;
        }
        else if (message.message) {
            previewText = <div>
                    {prepareDisplayedMessage(message.message, false, maxLength, allowMultiRow).map((i, _) => i.object)}
                </div>;
        }
    }
    return previewText;
}
//endregion

//region invoicing and fee calculations
export function calculateFee(feeObject) {
    if (feeObject.price === undefined || typeof feeObject.price !== 'number') {
        return 0;
    }
    if (!feeObject.tax) {
        return feeObject.price;
    }
    return feeObject.price + calculateTax(feeObject.tax, feeObject.price);
}

export function calculateRunningCost(bookingLength, runningCost) {
    if (runningCost.price === undefined || typeof runningCost.price !== 'number') {
        return 0;
    }
    let finalPrice = runningCost.price;
    if (runningCost.tax != null) {
        finalPrice += calculateTax(runningCost.tax, runningCost.price);
    }
    let fullMonths = Math.floor(bookingLength / 30);
    let remainingDays = bookingLength - (fullMonths * 30);
    let lastMonthPrice = Math.round(finalPrice * remainingDays / 30);
    return fullMonths * finalPrice + lastMonthPrice;
}

export function calculateTax(tax, price) {
    if (isTaxObjectIncomplete(tax) || tax.included) {
        return 0;
    }
    return tax.percent / 100 * price;
}

export function calculateIncludedTax(tax, price) {
    return price / (100 + tax.percent) * tax.percent
}

export function calculate30DaysMonthSum(dayCount, price) {
    let fullMonths = Math.floor(dayCount / 30);
    let remainingDays = dayCount - (fullMonths * 30);
    let lastMonthPrice = Math.round(price * remainingDays / 30);
    return fullMonths * price + lastMonthPrice;
}

export function calculate30DayMonthlyPrice(dayCount, price) {
    return Math.round(price / dayCount * 30);
}

export function calculateInvoice(start, end, bookingOffsetDays, pricing) {
    let nights = getNightsBetweenDates(end, start);
    let invoiceObject = {};
    let rent = pricing.rent;
    if (pricing.tax) {
        rent += calculateTax(pricing.tax, rent);
    }

    //region GENERAL
    let total = 0;
    if (nights >= 30) {
        total = calculate30DaysMonthSum(nights, rent);
    }
    else {
        if (pricing.current_price_periods !== null &&
            pricing.current_price_periods !== undefined) {
            // get the start and end date of the booking
            let startDay = getDayFromBegin(start);
            let endDay = getDayFromBegin(end);
            // for each day until end reached
            while (startDay < endDay) {
                // if there is an overlapping price period without repeat
                // get the oldest one and add the price
                let overlappingPricePeriods =
                    filterOverlappingPricePeriods(pricing.current_price_periods, startDay);
                if (overlappingPricePeriods !== null && overlappingPricePeriods !== undefined &&
                    overlappingPricePeriods.length > 0) {
                    overlappingPricePeriods.sort((a, b) => b.creation_ts - a.creation_ts);
                    if (overlappingPricePeriods[0].price) {
                        let currentPrice = overlappingPricePeriods[0].price;
                        if (pricing.tax) {
                            currentPrice += calculateTax(pricing.tax, currentPrice);
                        }
                        total += currentPrice;
                        startDay = getNextDay(startDay);
                        continue;
                    }
                }
                // otherwise add the standard daily price
                total += rent;
                startDay = getNextDay(startDay);
            }
        }
        else {
            total = nights * rent;
        }
    }
    invoiceObject.base_fee = total;
    let totalWithDiscount = total;
    //endregion

    // region DISCOUNTS
    let weeklyDiscount = 0;
    let monthlyDiscount = 0;
    let earlyBookingDiscount = 0;
    if (pricing.monthly_discount && nights >= 30) {
        monthlyDiscount = Math.round(calculateDiscount(pricing.monthly_discount, total));
        totalWithDiscount -= monthlyDiscount;
    }
    else if (pricing.weekly_discount && nights >= 7) {
        weeklyDiscount = Math.round(calculateDiscount(pricing.weekly_discount, total));
        totalWithDiscount -= weeklyDiscount;
    }
    if (pricing.early_booker_discount &&
        bookingOffsetDays >= pricing.early_booker_discount.weeks * TimeUnitMap.day.week) {
        earlyBookingDiscount = Math.round(calculateDiscount(pricing.early_booker_discount.discount, total));
        totalWithDiscount -= earlyBookingDiscount;
    }

    let subtotal = totalWithDiscount;
    // endregion

    //region ADDITIONAL FEES
    let additionalFeeSum = 0;
    if (pricing.additional_fees !== undefined) {
        invoiceObject.additional_fees = {};
        for (const additionalFee of pricing.additional_fees) {
            let fee = calculateFee(additionalFee);
            if (fee > 0) {
                additionalFeeSum += fee;
                invoiceObject.additional_fees[additionalFee.name] = fee;
            }
        }
    }
    //endregion

    //region RUNNING COSTS
    let runningCostSum = 0;
    if (nights > 29 && pricing.running_costs !== undefined) {
        invoiceObject.running_costs = {};
        for (const runningCost of pricing.running_costs) {
            let runningCostAmount = calculateRunningCost(nights, runningCost);
            invoiceObject.running_costs[runningCost.name] = runningCostAmount;
            runningCostSum += runningCostAmount;
        }
    }
    //endregion

    total += additionalFeeSum + runningCostSum;
    totalWithDiscount += additionalFeeSum + runningCostSum;

    let chargeCount = Math.ceil(nights / 30);
    let charges = [];
    let dueDate = Math.max(Date.now(), start - getTimeUnitInMS(INITIAL_CHARGE_OFFSET_DAYS));
    let finalDailyPrice = totalWithDiscount / nights;
    let chargeSum = 0;
    for (let i=0; i<chargeCount; i++) {
        if (i < chargeCount - 1) {
            charges.push({
                id: "charge_" + i,
                amount: Math.round(finalDailyPrice * 30),
                due_dates: [dueDate]
            });
            // to avoid rounding errors, sum up all charges created
            chargeSum += Math.round(finalDailyPrice * 30);
        }
        else {
            // for the last charge calculate total minus the sum of all recent charges
            charges.push({
                id: "charge_" + i,
                amount: totalWithDiscount - chargeSum,
                due_dates: [dueDate]
            });
        }
        if (i === 0) {
            dueDate = start.getTime();
        }
        dueDate += getTimeUnitInMS(30);
    }
    invoiceObject.rent = rent;
    invoiceObject.rent_per_unit = pricing.long_term ?
        calculate30DayMonthlyPrice(nights, totalWithDiscount) : totalWithDiscount / nights;
    invoiceObject.day_count = nights;
    invoiceObject.additional_fee_sum = additionalFeeSum;
    invoiceObject.running_cost_sum = runningCostSum
    invoiceObject.total = total;
    invoiceObject.subtotal = subtotal;
    invoiceObject.total_with_discount = totalWithDiscount;
    invoiceObject.weekly_discount = weeklyDiscount;
    invoiceObject.monthly_discount = monthlyDiscount;
    invoiceObject.early_booker_discount = earlyBookingDiscount;
    invoiceObject.discounts = weeklyDiscount + monthlyDiscount + earlyBookingDiscount;
    invoiceObject.discount = invoiceObject.base_fee * (invoiceObject.discounts / 100);
    invoiceObject.charges = charges;
    return invoiceObject;
}

export function calculateInvoiceFromObject(booking) {
    // get data
    let start = new Date(booking.start);
    let end = new Date(booking.end);
    let bookingOffsetDays = convertMilliseconds(booking.start - booking.booking_ts, 'day', 'floor');
    let pricing = booking.invoice_data.pricing;

    return calculateInvoice(start, end, bookingOffsetDays, pricing);
}

export function calculateRealUnitRent(start, end, bookingOffsetDays, pricing) {
    return calculateInvoice(start, end, bookingOffsetDays, pricing).rent_per_unit;
}

export function isFeeObjectIncomplete(fee) {
    if (fee === undefined || fee === null) {
        return false;
    }
    return fee.name === undefined || fee.name.length === 0 ||
        fee.price === undefined || fee.price < 1 || fee.price > PRICE_LIMIT_CENTS ||
        (fee.tax && isTaxObjectIncomplete(fee.tax));
}

export function isRunningCostIncomplete(runningCost) {
    if (runningCost === undefined || runningCost === null) {
        return false;
    }
    return runningCost.name === undefined || runningCost.name.length === 0 ||
        runningCost.price === undefined || runningCost.price < 1 || runningCost.price > PRICE_LIMIT_CENTS ||
        (runningCost.tax && isTaxObjectIncomplete(runningCost.tax));
}

export function isTaxObjectIncomplete(tax) {
    return !tax.percent || tax.percent < 0 || tax.percent > 100;
}

export function calculateLandlordWin(bookingLength, amount, proTool=false) {
    let win = amount;
    /*// subtract stripe fee
    win -= Math.round(amount * 0.014 + 25);*/
    let percent = 0;
    if (BUSINESS_MODELS.profit_limits !== undefined) {
        for (let i=0; i<BUSINESS_MODELS.profit_limits.length; i++) {
            if (BUSINESS_MODELS.profit_limits[i].days > bookingLength) {
                percent = BUSINESS_MODELS.profit_limits[Math.max(0, i - 1)].percent;
                break;
            }
        }
        if (percent === 0) {
            percent = BUSINESS_MODELS.profit_limits[BUSINESS_MODELS.profit_limits.length - 1].percent;
        }
    }
    percent += proTool ? 1 : 0;
    // subtract roomjack fee (incl. 20% value added tax)
    return win - Math.round(amount * (percent / 100) * 1.2);
}

export function calculateDiscount(discount, price) {
    if (!discount.unit || !discount.value) {
        return 0;
    }
    if (discount.unit === UnitType.currency) {
        return discount.value;
    }
    return price * (discount.value / 100);
}

export function filterOverlappingPeriods(periods, dayMS) {
    return periods.filter(function(p) {
        return isDateInTimeInterval(dayMS, p.start, p.end);
    });
}

export function filterOverlappingPricePeriods(periods, day) {
    let currentSinglePricePeriods = periods.filter(p => {
        let periodEnd = null;
        if (p.end !== null && p.end !== undefined && p.end > 0) {
            periodEnd = new Date(p.end);
        }
        let periodStart = new Date(p.start);
        if (periodEnd === null) {
            periodEnd = getNextDay(periodStart);
        }
        return p.repeat === '0' && periodStart.getTime() <= day.getTime() &&
            periodEnd.getTime() >= day.getTime()
    });
    if (currentSinglePricePeriods.length > 0) {
        currentSinglePricePeriods.sort((a, b) => b.creation_ts - a.creation_ts);
        return currentSinglePricePeriods;
    }
    let currentPricePeriods = periods.filter(p => {
        let periodStart = new Date(p.start);
        let periodEnd = p.end !== undefined && p.end !== 0 ? new Date(p.end) : null;
        if (periodEnd === null) {
            return p.repeat.includes('' + normalizeDayOfWeek(day.getDay())) &&
                periodStart.getTime() <= day.getTime();
        }
        else if (periodEnd > periodStart) {
            return p.repeat.includes('' + normalizeDayOfWeek(day.getDay())) &&
                periodStart.getTime() <= day.getTime() &&
                periodEnd.getTime() >= day.getTime();
        }
        else {
            return false;
        }
    });
    if (currentPricePeriods.length > 0) {
        currentPricePeriods.sort((a, b) => b.creation_ts - a.creation_ts);
        return currentPricePeriods;
    }
    return [];
}
//endregion

//region element creators
export function createModeDropdown(name, ref, initialValue=null, onChange=null, required=false) {
    return <Dropdown items={getModeDropdownItems()} name={name} ref={ref} initialValue={initialValue}
                     onChange={onChange} required={required}/>
}

export function createAdvertLocationDropdown(name, ref, placeholder, onChange, initialValue, icon) {
    return <Dropdown name={name} ref={ref} items={getAdvertLocationItems()} placeholder={getLanguageEntry(placeholder)}
                     onChange={onChange} initialValue={initialValue} icon={icon} />
}

export function createCountryDropdown(name, ref, onChange, addNullOption=false, initialValue=null,
                                      required=false, onlyCountries, invalid=false) {
    return <Dropdown items={getCountryDropdownItems(addNullOption, onlyCountries)} name={name} ref={ref}
                     initialValue={initialValue} onChange={onChange} required={required} invalid={invalid}/>
}

export function createPhoneCountryPrefixDropdown(name, ref, onChange, initialValue=null,
                                                 required=false, invalid=false) {
    if (!initialValue && clientCountry) {
        let prefixObject = PHONE_PREFIXES.filter(p => p.country.toLowerCase() === clientCountry)[0];
        if (prefixObject) {
            initialValue = prefixObject.value;
        }
    }
    return <Dropdown items={getPhoneCountryPrefixItems()} name={name} ref={ref} placeholder={"general>calling_code"}
                     initialValue={initialValue} onChange={onChange} required={required} invalid={invalid}/>
}

export function createMultiCountryDropdown(name, ref, onChange, initialValue=null) {
    return <MultiDropdown items={getCountryDropdownItems()} name={name} ref={ref} initialValue={initialValue} onChange={onChange}/>
}

export function createMultiAdvertDropdown(adverts, name, ref, onChange, initialValue=null) {
    return <MultiDropdown items={getAdvertDropdownItems(adverts)} name={name} ref={ref} initialValue={initialValue} onChange={onChange}/>
}

export function createMultiLanguageDropdown(name, ref, onChange, initialValue) {
    return <MultiDropdown items={getLanguageDropdownItems()} name={name} ref={ref} initialValue={initialValue} onChange={onChange}/>
}

export function createDayDropdown(dayArray, name, ref, onChange, initialValue, placeholder, required=false, invalid=false) {
    return <Dropdown name={name} ref={ref} items={getDayDropdownItems(dayArray)} onChange={onChange} placeholder={placeholder}
                     initialValue={initialValue} required={required} invalid={invalid}/>
}

export function createVATDropdown(taxArray, name, ref, onChange, initialValue, placeholder, required=false, invalid=false) {
    return <Dropdown name={name} ref={ref} items={getVATDropdownItems(taxArray)} onChange={onChange} placeholder={placeholder}
                     initialValue={initialValue} required={required} invalid={invalid} allowDeselect={true}
                     comparator={(i1, i2) => i1 && i2 && i1.percent === i2.percent && i1.included === i2.included}/>
}

export function generateCancellationConditionHTML(bookingStart, condition, total) {
    let dateMS = getDayFromBegin(new Date(bookingStart)).getTime();
    dateMS -= getTimeUnitInMS(condition.days);
    if (condition.clock_time !== undefined) {
        dateMS += condition.clock_time;
    }
    if (!total) {
        return <div className="cancellation-condition description-container" key={_uniqueId("cancel-free")}>
            <FontAwesomeIcon icon={["fal", "info-circle"]}/>
            {parse(getLanguageEntry("processes>booking>cancellation_condition_free")
                .replace('#', getFormattedDate(new Date(dateMS), false, true,
                    true, true, true, false, false)))}
        </div>;
    }
    else {
        let fee = priceToString(total * (condition.percent / 100));
        return <div className="cancellation-condition description-container" key={_uniqueId("cancel-days-" + condition.days)}>
            <FontAwesomeIcon icon={["fal", "info-circle"]}/>
            {parse(getLanguageEntry("processes>booking>cancellation_condition_charged_from")
                .replace('€', fee)
                .replace('#', condition.percent)
                .replace('@', getFormattedDate(new Date(dateMS), false, true,
                    true, true, true, false, false)))}
        </div>;
    }
}

export function createCancellationConditionList(conditions, invoice, start, showFree=true) {
    let list = [];
    if (!invoice) {
        return list;
    }
    if (!conditions || conditions.length === 0) {
        list.push(
            <div className="cancellation-condition description-container" key={_uniqueId("cancel-free")}>
                {getLanguageEntry("processes>booking>free_cancellable")}
            </div>);
    }
    else {
        let conditionsCopy = JSON.parse(JSON.stringify(conditions));
        // sort the conditions descending by days
        conditionsCopy.sort(function(a, b) { return b.days - a.days; });
        if (showFree && takesCancellationConditionHold(start, conditionsCopy[0])) {
            list.push(generateCancellationConditionHTML(start, conditionsCopy[0]));
        }
        for (let i=0; i<conditions.length; i++) {
            if (takesCancellationConditionHold(start, conditionsCopy[i])) {
                list.push(generateCancellationConditionHTML(start, conditionsCopy[i], invoice.total_with_discount));
            }
        }
        if (list.length === 0) {
            let condition = conditionsCopy[conditionsCopy.length - 1];
            let fee = priceToString(invoice.total_with_discount * (condition.percent / 100));
            list.push(
                <div className="cancellation-condition description-container" key={_uniqueId("no-refund-on-cancel")}>
                    {parse(getLanguageEntry("processes>booking>cancellation_condition_charged")
                        .replace('€', fee)
                        .replace('#', condition.percent))}
                </div>
            )
        }
    }
    return list;
}

export function isAdvertStillFreeCancellable(start, conditions) {
    // if no conditions, return true
    if (!conditions || conditions.length === 0) {
        return true;
    }
    // get the condition with the greatest day
    let maxDay = Math.max(...conditions.map(item => item.days));
    return start - getTimeUnitInMS(maxDay) > Date.now();
}

export function takesCancellationConditionHold(start, condition) {
    return start - getTimeUnitInMS(condition.days) > Date.now();
}

export function determineActiveCancellationCondition(bookingStart, conditions, cancellationTS) {
    if (!(conditions instanceof Array)) {
        return null;
    }
    if (cancellationTS === undefined || cancellationTS === null) {
        cancellationTS = Date.now();
    }
    let activeCondition = null;
    for (const condition of conditions.sort((a, b) => b.days - a.days)) {
        let conditionLimit = bookingStart - getTimeUnitInMS(condition.days);
        if (condition.clock_time !== undefined) {
            conditionLimit += condition.clock_time;
        }
        if (cancellationTS >= conditionLimit) {
            activeCondition = condition;
        }
    }
    return activeCondition;
}

export function createBookingOverviewTableRows(pricing, invoice, startDate, endDate, discount) {
    let rows = [];
    let roomjackDiscount = 0;
    if (pricing.long_term) {
        let months = Math.floor(invoice.day_count / 30);
        let days = invoice.day_count % 30;
        let text = getCountLabel(months, "month");
        if (days > 0) {
            text += ' ' + getCountLabel(days, "day");
        }
        text += ' ' + getLanguageEntry("components>invoice_table>at") + ' ' +
            priceToString(invoice.rent) + ' ' +
            getLanguageEntry("processes>booking>price_per_month_short");
        let basisFeeAmount = months * invoice.rent +
            days * invoice.rent / 30;
        rows.push(
            <tr key={_uniqueId("base-price")}>
                <td>{text}</td>
                <td>{priceToString(basisFeeAmount)}</td>
            </tr>
        )
        if (pricing.running_costs !== undefined) {
            for (let i=0; i<pricing.running_costs.length; i++) {
                let runningCost = pricing.running_costs[i];
                let runningCostName =
                    PREDEFINED_RUNNING_COSTS[runningCost.name] ?
                    getLanguageEntry("jackboard>adverts>advert_creator>pricing>running_costs>types")[runningCost.name] :
                    runningCost.name;
                rows.push(
                    <tr key={_uniqueId("running-cost-" + i)}>
                        <td>{runningCostName}</td>
                        <td>{priceToString(invoice.running_costs[runningCost.name])}</td>
                    </tr>);
            }
        }
        if (invoice.additional_fees !== undefined) {
            for (const fee of Object.keys(invoice.additional_fees)) {
                let feeName =
                    PREDEFINED_LONG_TERM_FEE_TYPES[fee] ?
                    getLanguageEntry(`jackboard>adverts>advert_creator>pricing>additional_fees>types>${fee}`): fee;
                rows.push(
                    <tr key={_uniqueId("fee-" + fee)}>
                        <td>{feeName}</td>
                        <td>{priceToString(invoice.additional_fees[fee])}</td>
                    </tr>);
            }
        }
        if (discount) {
            let months = Math.floor(invoice.day_count / 30);
            let discountAdditionalText;
            if (discount.apply_to_charges && discount.apply_to_charges > 1) {
                discountAdditionalText = getLanguageEntry("processes>booking>roomjack_discount_multi_time")
                    .replace("x", "" + Math.min(discount.apply_to_charges, months));
            }
            else {
                discountAdditionalText = getLanguageEntry("processes>booking>roomjack_discount_one_time");
            }
            let discountText = getLanguageEntry("processes>booking>roomjack_discount_text") +
                " (" + discount.code + ", " + discountAdditionalText + ")";
            roomjackDiscount = Math.min(calculateDiscount(discount.discount, invoice.total_with_discount), invoice.total_with_discount);
            if (discount.apply_to_charges > 1) {
                roomjackDiscount *= discount.apply_to_charges;
            }
            rows.push(
                <tr key={_uniqueId("roomjack-discount")}>
                    <td>{discountText}</td>
                    <td>{priceToString(roomjackDiscount)}</td>
                </tr>
            )
        }
    }
    else {
        rows.push(
            <tr key={_uniqueId("base-price")}>
                <td>
                    {
                        priceToString(invoice.base_fee / invoice.day_count, true) + ' x ' +
                        getCountLabel(invoice.day_count, "day")
                    }
                </td>
                <td>
                    {
                        priceToString(invoice.base_fee)
                    }
                </td>
            </tr>
        )

        if (invoice.discounts > 0) {
            let discountText = getLanguageEntry("processes>booking>discount_text");
            rows.push(
                <tr key={_uniqueId("discount")}>
                    <td>{discountText}</td>
                    <td>{priceToString(invoice.discounts)}</td>
                </tr>
            )
        }

        // additional fees
        if (invoice.additional_fees !== undefined) {
            for (const fee of Object.keys(invoice.additional_fees)) {
                let feeName =
                    PREDEFINED_FEE_TYPES[fee] ?
                    getLanguageEntry("processes>booking>fee_types")[fee] : fee;
                rows.push(
                    <tr key={_uniqueId("fee-" + fee)}>
                        <td>{feeName}</td>
                        <td>{priceToString(invoice.additional_fees[fee])}</td>
                    </tr>);
            }
        }

        // roomjack discount
        if (discount) {
            let discountText = getLanguageEntry("processes>booking>roomjack_discount_text") +
                " (" + discount.code + ")";
            roomjackDiscount = Math.min(calculateDiscount(discount.discount, invoice.total_with_discount), invoice.total_with_discount);
            rows.push(
                <tr key={_uniqueId("roomjack-discount")}>
                    <td>{discountText}</td>
                    <td>{priceToString(roomjackDiscount)}</td>
                </tr>
            )
        }
    }
    rows.push(
        <tr className="sum" key={_uniqueId("booking-sum")}>
            <td>{getLanguageEntry("processes>booking>total_fees")}</td>
            <td>{priceToString(invoice.total_with_discount - roomjackDiscount)}</td>
        </tr>
    )
    return rows;
}

export function getPaymentGatewayLogo(paymentType) {
    switch (paymentType) {
        case PaymentType.paypal:
            return <img style={{height: "38px"}} src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-150px.png" alt="PayPal-Logo." />;
        case PaymentType.klarna:
            return <img style={{height: "38px"}} src="https://docs.klarna.com/assets/media/d57b84c8-a93f-4a15-940e-aff9bd6ac208/Klarna_MarketingBadge_Pink_RGB.svg" alt="Klarna-Logo." />;
        default:
            return null;
    }
}

export function createLandlordCheckbox(advert, onTermsAccepted, type) {
    let checkboxText;
    if (type === "terms" && advert.terms_of_service) {
        checkboxText = getLanguageEntry("processes>booking>landlord_terms");
        let content = "<a href=" + advert.terms_of_service + " target='_blank' rel='noreferrer nofollow'>" +
            getLanguageEntry("processes>booking>terms_of_service") + "</a>";
        checkboxText = checkboxText.replace("#", content);
    }
    if (type === "revocation" && advert.revocation) {
        checkboxText = getLanguageEntry("processes>booking>landlord_terms");
        let content = "<a href=" + advert.revocation + " target='_blank' rel='noreferrer nofollow'>" +
            getLanguageEntry("processes>booking>revocation") + "</a>";
        checkboxText = checkboxText.replace("#", content);
    }
    if (type === "privacy_policy" && advert.privacy_policy) {
        checkboxText = getLanguageEntry("processes>booking>landlord_terms");
        let content = "<a href=" + advert.privacy_policy + " target='_blank' rel='noreferrer nofollow'>" +
            getLanguageEntry("processes>booking>privacy_policy") + "</a>";
        checkboxText = checkboxText.replace("#", content);
    }
    if (checkboxText) {
        return <div className="form-group">
            <input type="checkbox" onChange={(e) => {onTermsAccepted?.(e.target.checked)}}
                   id={`landlord-${type}`}/>
            <label htmlFor={`landlord-${type}`} className="description-container">
                {parse(checkboxText)}
            </label>
        </div>
    }
    return null;
}
//endregion

//region server response data unpacking and extracting
/**
 * Extracts the predefined server variables from the server response body.
 * @param data The server response body
 */
export function extractPredefinedProperties(data) {
    if (data.business_models) {
        BUSINESS_MODELS = data.business_models;
    }
    if (data.boosters !== undefined) {
        BOOSTERS = data.boosters;
    }
    if (data.sorting_weights !== undefined) {
        SORTING_WEIGHTS = data.sorting_weights;
    }
    REVOCATION_PERIOD_DURATION = data.revocation_period === undefined ? 1209600000 : data.revocation_period;
    if (data.dynamic_page_content !== undefined) {
        for (const key of Object.keys(data.dynamic_page_content)) {
            languageData[key].dynamic_page_content = data.dynamic_page_content[key];
        }
    }
    if (data.locations !== undefined) {
        ADVERT_LOCATIONS = data.locations;
    }
    if (data.faq_help !== undefined) {
        FAQ_HELP = data.faq_help;
    }
    if (data.tips_and_tricks !== undefined) {
        TIPS_TRICKS = data.tips_and_tricks;
    }
    if (data.booking_block_colors !== undefined) {
        BOOKING_BLOCK_COLORS = data.booking_block_colors;
    }
    if (data.website_version) {
        LATEST_APP_VERSION = data.website_version;
    }
    if (RUNTIME_MODE === RuntimeMode.development &&
        data.openAI_apiKey) {
        OPENAI_API_KEY = data.openAI_apiKey;
    }
}

/**
 * Extracts the sessionID from the server response body.
 * @param data The server response body
 * @param state The state object, where the sessionID should be set.
 */
export function extractSessionID(data, state) {
    if (!state) {
        state = {};
    }
    if (data.sessionID !== undefined) {
        state.session_id = data.sessionID;
    }
    return state;
}

/**
 * Extracts the errorCode from the server response body.
 * @param response The server response body
 * @returns { number | null | object } The error-code, if there is one, otherwise null.
 */
export function extractErrorCode(response) {
    if (response.errorCode !== undefined) {
        if (response.errorCode instanceof Number) {
            return parseInt(response.errorCode);
        }
        return response.errorCode;
    } else {
        return null;
    }

}

/**
 * Unpacks a mongo-db like ID object of form { _id: { $oid: id } }
 * @param object The ID Object
 * @returns The unpacked ID as a string.
 */
export function unpackID(object) {
    if ((typeof object !== 'object' || object['_id'] === undefined)
        && typeof object !== 'string' ) return;
    if (typeof object === 'object' && typeof object['_id'] === 'string')
        return object['_id'];
    return object['_id']['$oid'];
}

/**
 * Unpacks a mongo-db like long-number object of form { $numberLong: long }
 * @param object The long-number object
 * @returns The unpacked long number.
 */
export function unpackLongNumber(object) {
    if (!isNaN(object)) return object;
    return parseInt(object['$numberLong']);
}

/**
 * Unpacks recursive a user object from the database.
 * @param userData The user data object
 * @param showOnlyPublic If set to true, it ignores certain (not public) attributes.
 * @returns The userData object unpacked.
 */
export function unpackUser(userData, showOnlyPublic=false) {
    if (userData.id === undefined) {
        userData.id = unpackID(userData);
    }
    if (userData.reg_ts !== undefined) {
        userData.reg_ts = unpackLongNumber(userData.reg_ts);
        userData.reg_date = new Date(userData.reg_ts);
    }

    if (userData.rp_acceptance !== undefined) {
        userData.rp_acceptance.date = unpackLongNumber(userData.rp_acceptance.date);
    }

    if (userData.reviews !== undefined) {
        for (const userID of (Object.keys(userData.reviews))) {
            let review = userData.reviews[userID];
            review.timestamp = unpackLongNumber(review.timestamp);
            userData.reviews[userID] = review;
        }
    }

    if (showOnlyPublic) {
        if (userData.address !== undefined &&
            (userData.privacy_settings === undefined || userData.privacy_settings.show_address)) {
            delete userData.address.line_1;
            delete userData.address.line_2;
            delete userData.address.postal_code;
        }
        else {
            delete userData.address;
        }
    }

    return userData;
}

export function unpackNotificationData(data) {
    if (data.conversations) {
        for (let conversation of data.conversations) {
            unpackConversation(conversation);
            conversation.notification_ts = conversation.messages[0].timestamp;
            conversation.notification_type = "message";
        }
    }
    if (data.bookings) {
        for (let booking of data.bookings) {
            unpackBooking(booking);
            booking.notification_ts = booking.cancellation_ts !== undefined ?
                booking.cancellation_ts : booking.booking_ts;
            booking.notification_type = "booking";
        }
    }
    if (data.inquiries) {
        for (let inquiry of data.inquiries) {
            unpackBooking(inquiry);
            inquiry.notification_ts = inquiry.booking_ts;
            inquiry.notification_type = "inquiry";
        }
    }
    if (data.user_reviews) {
        for (let review of data.user_reviews) {
            review.notification_ts = unpackLongNumber(review.timestamp);
            review.notification_type = "review";
        }
    }
    if (data.advert_reviews) {
        for (let review of data.advert_reviews) {
            review.notification_ts = unpackLongNumber(review.timestamp);
            review.notification_type = "review";
        }
    }
    if (data.pending_reviews) {
        for (let review of data.pending_reviews) {
            unpackBooking(review);
            review.notification_ts = review.end;
            review.notification_type = "pending_review";
        }
    }
}

export function unpackConversation(conversationData) {
    conversationData.id = unpackID(conversationData);
    if (conversationData.messages !== undefined) {
        conversationData.messages.forEach(m => unpackMessage(m));
    }
    if (conversationData.user !== undefined) {
        unpackUser(conversationData.user, true);
    }
}

export function unpackBooking(bookingData, showOnlyPublicData=true) {
    if (bookingData.id === undefined) {
        bookingData.id = unpackID(bookingData);
    }
    if (bookingData.start !== undefined && bookingData.start instanceof Object) {
        bookingData.start = unpackLongNumber(bookingData.start);
    }
    if (bookingData.end !== undefined && bookingData.end instanceof Object) {
        bookingData.end = unpackLongNumber(bookingData.end);
    }
    if (bookingData.booking_ts !== undefined && bookingData.booking_ts instanceof Object) {
        bookingData.booking_ts = unpackLongNumber(bookingData.booking_ts);
    }
    if (bookingData.cancellation_ts !== undefined && bookingData.cancellation_ts instanceof Object) {
        bookingData.cancellation_ts = unpackLongNumber(bookingData.cancellation_ts);
    }
    if (bookingData.reservation_duration !== undefined && bookingData.reservation_duration instanceof Object) {
        bookingData.reservation_duration = unpackLongNumber(bookingData.reservation_duration);
    }
    if (bookingData.owner !== undefined && bookingData.owner._id !== undefined) {
        unpackUser(bookingData.owner, showOnlyPublicData);
    }
    if (bookingData.booker !== undefined && bookingData.booker._id !== undefined) {
        unpackUser(bookingData.booker, showOnlyPublicData);
    }
    if (bookingData.advert !== undefined) {
        unpackAdvert(bookingData.advert);
    }
    if (bookingData.message_id !== undefined) {
        bookingData.message_id = unpackLongNumber(bookingData.message_id);
    }
    if (bookingData.message !== undefined && bookingData.message.timestamp !== undefined) {
        bookingData.message.timestamp = unpackLongNumber(bookingData.message.timestamp);
    }
    if (bookingData.charge_data !== undefined) {
        if (bookingData.charge_data.total !== undefined) {
            bookingData.charge_data.total = unpackLongNumber(bookingData.charge_data.total);
        }
        if (bookingData.charge_data.charges !== undefined) {
            for (let charge of bookingData.charge_data.charges) {
                if (charge.due_dates !== undefined) {
                    for (let i=0; i<charge.due_dates.length; i++) {
                        charge.due_dates[i] = unpackLongNumber(charge.due_dates[i]);
                    }
                }
                if (charge.application_fee) {
                    charge.application_fee = unpackLongNumber(charge.application_fee);
                }
                if (charge.cancellation_fee) {
                    charge.cancellation_fee = unpackLongNumber(charge.cancellation_fee);
                }
                if (charge.stripe_fee) {
                    charge.stripe_fee = unpackLongNumber(charge.stripe_fee);
                }
                if (charge.amount) {
                    charge.amount = unpackLongNumber(charge.amount);
                }
                if (charge.payment_ts) {
                    charge.payment_ts = unpackLongNumber(charge.payment_ts);
                }
                if (charge.cancellation_ts) {
                    charge.cancellation_ts = unpackLongNumber(charge.cancellation_ts);
                }
                if (charge.refund_amount) {
                    charge.refund_amount = unpackLongNumber(charge.refund_amount);
                }
                if (charge.refund_ts) {
                    charge.refund_ts = unpackLongNumber(charge.refund_ts);
                }
                if (charge.payout_amount) {
                    charge.payout_amount = unpackLongNumber(charge.payout_amount);
                }
                if (charge.payout_ts) {
                    charge.payout_ts = unpackLongNumber(charge.payout_ts);
                }
                if (charge.discount) {
                    charge.discount = unpackLongNumber(charge.discount);
                }
                if (charge.payout_date) {
                    charge.payout_date = unpackLongNumber(charge.payout_date);
                }
            }
        }
    }
    if (bookingData.invoice_data !== undefined && bookingData.invoice_data.pricing !== undefined) {
        unpackPricingObject(bookingData.invoice_data.pricing);
    }
}

export function unpackAdvert(advertData) {
    advertData.id = unpackID(advertData);
    if (advertData.creation_ts !== undefined && advertData.creation_ts instanceof Object) {
        advertData.creation_ts = unpackLongNumber(advertData.creation_ts);
    }

    if (advertData.last_edit !== undefined && advertData.last_edit instanceof Object) {
        advertData.last_edit = unpackLongNumber(advertData.last_edit);
    }

    if (advertData.pricing !== undefined) {
        unpackPricingObject(advertData.pricing);
    }

    if (advertData.long_term_pricing !== undefined) {
        unpackPricingObject(advertData.long_term_pricing);
    }

    if (advertData.clicks) {
        advertData.clicks = unpackLongNumber(advertData.clicks);
    }

    if (advertData.sharing) {
        advertData.sharing = unpackLongNumber(advertData.sharing);
    }

    if (advertData.favourite_count) {
        advertData.favourite_count = unpackLongNumber(advertData.favourite_count);
    }

    if (advertData.first_publishing !== undefined && advertData.first_publishing instanceof Object) {
        advertData.first_publishing = unpackLongNumber(advertData.first_publishing);
    }

    if (advertData.booking_free_periods !== undefined) {
        for (const bfp of Object.values(advertData.booking_free_periods)) {
            bfp.start = unpackLongNumber(bfp.start);
            bfp.end = unpackLongNumber(bfp.end);
            if (bfp.creation_ts) {
                bfp.creation_ts = unpackLongNumber(bfp.creation_ts);
            }
        }
    }

    if (advertData.external_booking_blocks !== undefined) {
        for (const bfp of advertData.external_booking_blocks) {
            bfp.start = unpackLongNumber(bfp.start);
            bfp.end = unpackLongNumber(bfp.end);
            if (bfp.creation_ts) {
                bfp.creation_ts = unpackLongNumber(bfp.creation_ts);
            }
        }
    }

    if (advertData.rooms !== undefined) {
        for (const room of advertData.rooms) {
            if (room.clicks !== undefined) {
                //room.clicks = unpackLongNumber(room.clicks);
                for (let i=0; i<room.clicks.length; i++) {
                    room.clicks[i] = unpackLongNumber(room.clicks[i]);
                }
            }
            if (room.sharing) {
                room.sharing = unpackLongNumber(room.sharing);
            }
            if (room.favourite_count) {
                room.favourite_count = unpackLongNumber(room.favourite_count);
            }
            if (room.booking_free_periods !== undefined) {
                for (const bfp of Object.values(room.booking_free_periods)) {
                    bfp.start = unpackLongNumber(bfp.start);
                    bfp.end = unpackLongNumber(bfp.end);
                    if (bfp.creation_ts) {
                        bfp.creation_ts = unpackLongNumber(bfp.creation_ts);
                    }
                }
            }
            if (room.external_booking_blocks !== undefined) {
                for (const bfp of room.external_booking_blocks) {
                    bfp.start = unpackLongNumber(bfp.start);
                    bfp.end = unpackLongNumber(bfp.end);
                    if (bfp.creation_ts) {
                        bfp.creation_ts = unpackLongNumber(bfp.creation_ts);
                    }
                }
            }
            if (room.pricing !== undefined) {
                unpackPricingObject(room.pricing);
            }

            if (room.long_term_pricing !== undefined) {
                unpackPricingObject(room.long_term_pricing);
            }
        }
    }

    ["search_preference_boosters", "gold_boosters", "gold_plus_boosters"].forEach(boosterList => {
        if (advertData[boosterList] !== undefined) {
            for (const booster of advertData[boosterList]) {
                if (booster.duration !== undefined) {
                    booster.duration = unpackLongNumber(booster.duration);
                }
                if (booster.expiry_date !== undefined) {
                    booster.expiry_date = unpackLongNumber(booster.expiry_date);
                }
                if (booster.purchase_date !== undefined) {
                    booster.purchase_date = unpackLongNumber(booster.purchase_date);
                }
                if (booster.start !== undefined) {
                    booster.start = unpackLongNumber(booster.start);
                }
            }
        }
    });

    if (advertData.color_accent !== undefined) {
        if (advertData.color_accent.duration !== undefined) {
            advertData.color_accent.duration = unpackLongNumber(advertData.color_accent.duration);
        }
        if (advertData.color_accent.expiry_date !== undefined) {
            advertData.color_accent.expiry_date = unpackLongNumber(advertData.color_accent.expiry_date);
        }
        if (advertData.color_accent.purchase_date !== undefined) {
            advertData.color_accent.purchase_date = unpackLongNumber(advertData.color_accent.purchase_date);
        }
        if (advertData.color_accent.start !== undefined) {
            advertData.color_accent.start = unpackLongNumber(advertData.color_accent.start);
        }
    }

    if (advertData.owner !== undefined && advertData.owner instanceof Object) {
        unpackUser(advertData.owner, true);
    }

    if (advertData.reviews !== undefined) {
        for (const userID of (Object.keys(advertData.reviews))) {
            let review = advertData.reviews[userID];
            review.timestamp = unpackLongNumber(review.timestamp);
            advertData.reviews[userID] = review;
        }
    }

    if (advertData.cancellation_conditions !== undefined) {
        for (const cc of advertData.cancellation_conditions) {
            if (cc.clock_time !== undefined) {
                cc.clock_time = unpackLongNumber(cc.clock_time);
            }
        }
    }

    if (advertData.current_price_templates !== undefined) {
        for (const cpt of advertData.current_price_templates) {
            if (cpt.price !== undefined) {
                cpt.price = unpackLongNumber(cpt.price);
            }
        }
    }
}

export function unpackMessage(message) {
    message.timestamp = unpackLongNumber(message.timestamp);
    if (message.inquiry !== undefined && message.inquiry instanceof Object) {
        if (message.inquiry.data !== undefined) {
            unpackBooking(message.inquiry.data);
        }
        unpackBooking(message.inquiry);
    }
    if (message.booking !== undefined && message.booking instanceof Object) {
        unpackBooking(message.booking);
        if (message.booking.data !== undefined) {
            unpackBooking(message.booking.data);
        }
    }
    if (message.invoice_data !== undefined && message.invoice_data instanceof Object) {
        unpackBooking(message.invoice_data, false);
    }
}

export function unpackWidgetData(data) {
    if (data.message) {
        for (const messageData of data.message) {
            if (messageData.message) {
                unpackMessage(messageData.message);
            }
        }
    }
    if (data.inquiry) {
        for (let inquiry of data.inquiry) {
            unpackBooking(inquiry);
        }
    }
    if (data.occupancy_rate) {
        for (let booking of data.occupancy_rate.bookings) {
            unpackBooking(booking);
        }
        for (let advert of data.occupancy_rate.adverts) {
            unpackAdvert(advert);
        }
    }
    if (data.customer_satisfaction) {
        for (let advert of data.customer_satisfaction) {
            unpackAdvert(advert);
        }
    }
    if (data.click_booking) {
        for (let booking of data.click_booking.bookings) {
            unpackBooking(booking);
        }
        for (let advert of data.click_booking.adverts) {
            unpackAdvert(advert);
        }
    }
    if (data.income) {
        for (let booking of data.income.bookings) {
            unpackBooking(booking);
        }
    }
    if (data.booking_info && data.booking_info.bookings) {
        for (const item of data.booking_info.bookings) {
            if (item.booking) {
                unpackBooking(item.booking);
            }
            if (item.advert) {
                unpackAdvert(item.advert);
            }
        }
    }
    if (data.booker_info && data.booker_info.bookings) {
        for (const item of data.booker_info.bookings) {
            if (item.booking) {
                unpackBooking(item.booking);
            }
            if (item.advert !== undefined) {
                unpackAdvert(item.advert);
            }
        }
    }
    if (data.todo_list && data.todo_list.result_list) {
        for (const booking of data.todo_list.result_list) {
            unpackBooking(booking);
        }
        for (const advert of Object.values(data.todo_list.advert_map)) {
            unpackAdvert(advert);
        }
    }
}

export function unpackBookingDataSet(dataSet) {
    if (dataSet) {
        if (dataSet.booking_list) {
            for (const booking of dataSet.booking_list) {
                unpackBooking(booking);
            }
        }
        /*if (dataSet.inquiry_list !== undefined && dataSet.inquiry_list !== null) {
            for (const inquiry of dataSet.inquiry_list) {
                unpackBooking(inquiry);
            }
        }*/
        if (dataSet.adverts) {
            for (const advert of Object.values(dataSet.adverts)) {
                unpackAdvert(advert);
            }
        }
    }
}

export function unpackPricingObject(pricingObject) {
    if (pricingObject === undefined || pricingObject === null) {
        return;
    }
    if (pricingObject.rent) {
        pricingObject.rent = unpackLongNumber(pricingObject.rent);
    }
    if (pricingObject.deposit) {
        pricingObject.rent = unpackLongNumber(pricingObject.rent);
    }
    if (pricingObject.weekly_discount && pricingObject.weekly_discount.value) {
        pricingObject.weekly_discount.value = unpackLongNumber(pricingObject.weekly_discount.value);
    }
    if (pricingObject.monthly_discount && pricingObject.monthly_discount.value) {
        pricingObject.monthly_discount.value = unpackLongNumber(pricingObject.monthly_discount.value);
    }
    if (pricingObject.early_booker_discount && pricingObject.early_booker_discount.discount &&
        pricingObject.early_booker_discount.discount.value) {
        pricingObject.early_booker_discount.discount.value =
            unpackLongNumber(pricingObject.early_booker_discount.discount.value);
    }
    if (pricingObject.additional_fees) {
        for (const fee of pricingObject.additional_fees) {
            if (fee.price) {
                fee.price = unpackLongNumber(fee.price);
            }
        }
    }
    if (pricingObject.running_costs) {
        for (const rc of pricingObject.running_costs) {
            if (rc.price) {
                rc.price = unpackLongNumber(rc.price);
            }
        }
    }
    if (pricingObject.taxes) {
        for (const tax of pricingObject.taxes) {
            if (tax.value) {
                tax.value = unpackLongNumber(tax.value);
            }
        }
    }
    if (pricingObject.current_price_periods) {
        for (const cpp of pricingObject.current_price_periods) {
            if (cpp.price) {
                cpp.price = unpackLongNumber(cpp.price);
            }
            if (cpp.creation_ts) {
                cpp.creation_ts = unpackLongNumber(cpp.creation_ts);
            }
            if (cpp.start) {
                cpp.start = unpackLongNumber(cpp.start);
            }
            if (cpp.end) {
                cpp.end = unpackLongNumber(cpp.end);
            }
        }
    }
}

export function unpackAPIKeyData(data) {
    if (data === null || data === undefined) {
        return;
    }
    if (data._id) {
        data.id = unpackID(data);
    }
    if (data.creation_ts) {
        data.creation_ts = unpackLongNumber(data.creation_ts);
    }
    if (data.expiry_date)
    {
        data.expiry_date = unpackLongNumber(data.expiry_date);
    }
}

export function unpackDiscount(discount) {
    discount.discount.value = unpackLongNumber(discount.discount.value);
    discount.id = unpackID(discount);
}
//endregion

//region AI stuff
export function generateOpenAIRequestText(advert, additionalDescription) {
    const minLength = normalizeNumber(advert.min_booking_length ?? MIN_BOOKING_LENGTH, MIN_BOOKING_LENGTH, MAX_BOOKING_LENGTH);
    const maxLength = normalizeNumber(advert.max_booking_length ?? MAX_BOOKING_LENGTH, MIN_BOOKING_LENGTH, MAX_BOOKING_LENGTH);
    let context = "";
    if (advert.address && (advert.address.city || advert.address.country)) {
        context += "Location of the housing: ";
        if (advert.address.city) {
            context += advert.address.city + (advert.address.country ? ", " : "");
        }
        if (advert.address.country) {
            context += advert.address.country;
        }
        context += "\n";
    }
    context += `Length of rental is ${minLength} day(s) ${maxLength !== minLength ? ` ${maxLength} day(s).` : "."}\n`;
    if (advert.characteristics) {
        context += `Characteristics of the housing are: ${advert.characteristics.map(c =>
            c.replace("_jack_", "_check_").replaceAll("_", " ")).join(", ")}\n`;
    }
    if (advert.type) {
        context += `Type of the housing is ${advert.type}\n`;
    }
    let requestText = '';
    if (context.length > 0 || additionalDescription > 0) {
        if (context.length > 0) {
            requestText += `Here is the information-context about the accommodation collected by our platform roomjack:\n${context}\n`;
        }
        if(additionalDescription.length > 0) {
            requestText += `The owner of the housing provided the following description of the housing, please try to include it completely in the description and make it better:\n${additionalDescription}`;
        }
    }
    return requestText;
}
//endregion

//region OPEN AI STUFF
export async function makeOpenAIRequest(body) {
    if (!isDev() || !OPENAI_API_KEY) {
        return;
    }
    if (!OpenAi) {
        OpenAi = new OpenAI({
            apiKey: OPENAI_API_KEY,
            dangerouslyAllowBrowser: true
        });
    }
    return OpenAi.chat.completions.create(body);
}
//endregion