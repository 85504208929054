import {
    DEVICE_ID,
    SESSION_ID,
    globalLanguage,
    extractErrorCode,
    unpackAdvert,
    unpackBooking,
    unpackUser,
    unpackBookingDataSet,
    PRICE_LIMIT,
    unpackConversation,
    countUnreadMessages,
    sortConversationsByTimestamp,
    unpackMessage,
    unpackWidgetData, extractPredefinedProperties, unpackNotificationData, unpackDiscount
} from "./Helper";
import {
    ADVERT_CACHE,
    FAVORITE_CACHE,
    HOUSING_CACHE, BOOKING_INVOICES_CACHE, BOOKING_CACHE, PAYOUT_CACHE,
    RECOMMENDATION_CACHE,
    STATISTIC_CACHE, UNPAID_RENTS_CACHE,
    USER_CACHE, TODO_CACHE, CONVERSATION_CACHE, WIDGET_CACHE
} from "./CacheHandler.ts";
import CacheObject from "./cache/objects/CacheObject.ts";
import HousingCacheObject from "./cache/objects/HousingCacheObject.ts";
import {HousingCounterType} from "./Types.ts";
import axios from "axios";
import {ROOMJACK_API_URL} from "../../resources/version-dependent-variables";

//region Request method types
export const RequestType = {
    HEAD: "HEAD",
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE"
}
//endregion

//region main paths
const USER_SERVICE_PATH = "users";
const ACCOUNT_SERVICE_PATH = "account";
const LOGIN_SERVICE_PATH = "login";
const LOGOUT_SERVICE_PATH = "logout";
const ADVERT_SERVICE_PATH = "adverts";
const CONVERSATION_SERVICE_PATH = "conversations";
const BOOKING_SERVICE_PATH = "bookings";
const PAYMENT_SERVICE_PATH = "payment";
const REPORT_SERVICE_PATH = "report";
const REVIEW_SERVICE_PATH = "reviews";
//endregion

//region subpaths
const THIRD_PARTY_PATH = "/third_party";
const CALENDAR_SYNC_PATH = "/calendar_sync";
const CHANNEL_MANAGER_PATH = "/channel_manager";
//endregion

export const ROOMJACK_SERVER_ROUTE_MAP = {

    //region log paths
    LOGIN: {
        url: ROOMJACK_API_URL + LOGIN_SERVICE_PATH,
        method: RequestType.POST
    },
    LOGOUT: {
        url: ROOMJACK_API_URL + LOGOUT_SERVICE_PATH,
        method: RequestType.POST
    },
    //endregion

    //region user-service paths
    GET_USER: {
        url: ROOMJACK_API_URL + USER_SERVICE_PATH + "/get",
        method: RequestType.POST
    },
    UPDATE_USER: {
        url: ROOMJACK_API_URL + USER_SERVICE_PATH,
        method: RequestType.PUT
    },
    SYNC_NOTIFICATIONS: {
        url: ROOMJACK_API_URL + USER_SERVICE_PATH + "/sync_notifications",
        method: RequestType.POST
    },
    SETUP_WIDGETS: {
        url: ROOMJACK_API_URL + USER_SERVICE_PATH + "/setup_widgets",
        method: RequestType.POST
    },
    SYNC_WIDGETS: {
        url: ROOMJACK_API_URL + USER_SERVICE_PATH + "/sync_widgets",
        method: RequestType.POST
    },
    LIST_PAYMENT_METHODS: {
        url: ROOMJACK_API_URL + USER_SERVICE_PATH + "/list_payment_methods",
        method: RequestType.POST
    },
    POST_USER_REVIEW: {
        url: ROOMJACK_API_URL + USER_SERVICE_PATH + "/post_review",
        method: RequestType.POST
    },
    GET_PAYOUTS: {
        url: ROOMJACK_API_URL + USER_SERVICE_PATH + "/get_payouts",
        method: RequestType.POST
    },
    GET_BOOKING_INVOICES: {
        url: ROOMJACK_API_URL + USER_SERVICE_PATH + "/get_booking_invoices",
        method: RequestType.POST
    },
    GET_UNPAID_RENTS: {
        url: ROOMJACK_API_URL + USER_SERVICE_PATH + "/get_unpaid_rents",
        method: RequestType.POST
    },
    REVIEW_AUTHORS: {
        url: ROOMJACK_API_URL + USER_SERVICE_PATH + "/review_authors",
        method: RequestType.POST
    },
    MY_REVIEWS: {
        url: ROOMJACK_API_URL + USER_SERVICE_PATH + "/my_reviews",
        method: RequestType.POST
    },
    UPDATE_USER_REVIEW: {
        url: ROOMJACK_API_URL + USER_SERVICE_PATH + "/update_review",
        method: RequestType.POST
    },
    //endregion

    //region account-service paths
    EMAIL_VALID: {
        url: ROOMJACK_API_URL + ACCOUNT_SERVICE_PATH + "/email_valid",
        method: RequestType.POST
    },
    CREATE_ACCOUNT: {
        url: ROOMJACK_API_URL + ACCOUNT_SERVICE_PATH + "/create",
        method: RequestType.POST
    },
    START_RECOVERY: {
        url: ROOMJACK_API_URL + ACCOUNT_SERVICE_PATH + "/start_recovery",
        method: RequestType.POST
    },
    RECOVER: {
        url: ROOMJACK_API_URL + ACCOUNT_SERVICE_PATH + "/recover",
        method: RequestType.POST
    },
    GET_ACCOUNT_LINK: {
        url: ROOMJACK_API_URL + ACCOUNT_SERVICE_PATH + "/account_link",
        method: RequestType.POST
    },
    GET_THIRD_PARTY: {
        url: ROOMJACK_API_URL + ACCOUNT_SERVICE_PATH + THIRD_PARTY_PATH + "/get",
        method: RequestType.POST
    },
    ADD_THIRD_PARTY: {
        url: ROOMJACK_API_URL + ACCOUNT_SERVICE_PATH + THIRD_PARTY_PATH + "/add",
        method: RequestType.POST
    },
    DELETE_THIRD_PARTY: {
        url: ROOMJACK_API_URL + ACCOUNT_SERVICE_PATH + THIRD_PARTY_PATH + "/delete",
        method: RequestType.POST
    },
    GET_CHANNEL_MANAGER: {
        url: ROOMJACK_API_URL + ACCOUNT_SERVICE_PATH + CHANNEL_MANAGER_PATH + "/get",
        method: RequestType.POST
    },
    ADD_CHANNEL_MANAGER: {
        url: ROOMJACK_API_URL + ACCOUNT_SERVICE_PATH + CHANNEL_MANAGER_PATH + "/add",
        method: RequestType.POST
    },
    UPDATE_CHANNEL_MANAGER_ACCOUNT: {
        url: ROOMJACK_API_URL + ACCOUNT_SERVICE_PATH + CHANNEL_MANAGER_PATH + "/update_account",
        method: RequestType.POST
    },
    DELETE_CHANNEL_MANAGER: {
        url: ROOMJACK_API_URL + ACCOUNT_SERVICE_PATH + CHANNEL_MANAGER_PATH + "/delete",
        method: RequestType.POST
    },
    START_STATUS_CHANGE: {
        url: ROOMJACK_API_URL + ACCOUNT_SERVICE_PATH + "/start_status_change",
        method: RequestType.POST
    },
    DEACTIVATE_ACCOUNT: {
        url: ROOMJACK_API_URL + ACCOUNT_SERVICE_PATH + "/deactivate_account",
        method: RequestType.POST
    },
    REACTIVATE_ACCOUNT: {
        url: ROOMJACK_API_URL + ACCOUNT_SERVICE_PATH + "/reactivate_account",
        method: RequestType.POST
    },
    //endregion

    //region advert-service paths
    //region owner oriented methods
    POST_ADVERT: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/post",
        method: RequestType.POST
    },
    GET_ADVERTS: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/my_adverts",
        method: RequestType.POST
    },
    ADD_CURRENT_PRICE_TEMPLATE: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/add_current_price_template",
        method: RequestType.POST
    },
    DELETE_CURRENT_PRICE_TEMPLATE: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/delete_current_price_template",
        method: RequestType.POST
    },
    ADD_CURRENT_PRICE_PERIOD: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/add_current_price_period",
        method: RequestType.POST
    },
    DELETE_CURRENT_PRICE_PERIOD: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/delete_current_price_period",
        method: RequestType.POST
    },
    ADD_BOOKING_FREE_PERIOD: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/add_booking_free_period",
        method: RequestType.POST
    },
    DELETE_BOOKING_FREE_PERIOD: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/delete_booking_free_period",
        method: RequestType.POST
    },
    SET_PUBLICATION_STATUS: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/set_publication_status",
        method: RequestType.POST
    },
    SET_PRO_TOOL_STATUS: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/set_pro_tool_status",
        method: RequestType.POST
    },
    START_DELETE_ADVERT: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/start_delete",
        method: RequestType.POST
    },
    DELETE_ADVERT: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/delete",
        method: RequestType.POST
    },
    DUPLICATE_ADVERT: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/duplicate",
        method: RequestType.POST
    },
    BUY_BOOSTER: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/buy_booster",
        method: RequestType.POST
    },
    ADVERT_BOOKINGS: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/bookings",
        method: RequestType.POST
    },
    STATISTICS: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/statistics",
        method: RequestType.POST
    },
    TODOS: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/todos",
        method: RequestType.POST
    },
    UPDATE_ADVERT_REVIEW: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/update_review",
        method: RequestType.POST
    },
    ADD_CALENDAR_SYNC: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + CALENDAR_SYNC_PATH + "/add",
        method: RequestType.POST
    },
    DELETE_CALENDAR_SYNC: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + CALENDAR_SYNC_PATH + "/delete",
        method: RequestType.POST
    },
    //endregion
    //region public advert methods
    GET_ADVERT: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/get",
        method: RequestType.POST
    },
    INCREMENT_CLICK_COUNT: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/increment_click_count",
        method: RequestType.POST
    },
    INCREMENT_SHARINGS: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/increment_sharings",
        method: RequestType.POST
    },
    SEARCH: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/search",
        method: RequestType.POST
    },
    POST_ADVERT_REVIEW: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/post_review",
        method: RequestType.POST
    },
    ADD_FAVORITE: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/add_favorite",
        method: RequestType.POST
    },
    DELETE_FAVORITE: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/delete_favorite",
        method: RequestType.POST
    },
    GET_FAVORITES: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/favorites",
        method: RequestType.POST
    },
    GET_RECOMMENDATIONS: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/recommendations",
        method: RequestType.POST
    },
    GET_USER_ADVERTS: {
        url: ROOMJACK_API_URL + ADVERT_SERVICE_PATH + "/get_user_adverts",
        method: RequestType.POST
    },
    //endregion
    //endregion

    //region conversation-service paths
    GET_MESSAGES: {
        url: ROOMJACK_API_URL + CONVERSATION_SERVICE_PATH + "/get",
        method: RequestType.POST
    },
    SEND_MESSAGE: {
        url: ROOMJACK_API_URL + CONVERSATION_SERVICE_PATH + "/send",
        method: RequestType.POST
    },
    SYNC_CONVERSATION: {
        url: ROOMJACK_API_URL + CONVERSATION_SERVICE_PATH + "/sync",
        method: RequestType.POST
    },
    DELETE_CONVERSATION: {
        url: ROOMJACK_API_URL + CONVERSATION_SERVICE_PATH + "/delete",
        method: RequestType.POST
    },
    PIN_CONVERSATION: {
        url: ROOMJACK_API_URL + CONVERSATION_SERVICE_PATH + "/pin",
        method: RequestType.POST
    },
    SET_CONVERSATION_READ: {
        url: ROOMJACK_API_URL + CONVERSATION_SERVICE_PATH + "/set_read",
        method: RequestType.POST
    },
    //endregion

    //region booking-service paths
    AVAILABLE_FOR_BOOKING: {
        url: ROOMJACK_API_URL + BOOKING_SERVICE_PATH + "/available",
        method: RequestType.POST
    },
    CANCEL_BOOKING: {
        url: ROOMJACK_API_URL + BOOKING_SERVICE_PATH + "/cancel",
        method: RequestType.POST
    },
    MY_BOOKINGS: {
        url: ROOMJACK_API_URL + BOOKING_SERVICE_PATH + "/get",
        method: RequestType.POST
    },
    TRANSACTION_DETAILS: {
        url: ROOMJACK_API_URL + BOOKING_SERVICE_PATH + "/transaction_details",
        method: RequestType.POST
    },
    DOWNLOAD_RECEIPT: {
        url: ROOMJACK_API_URL + BOOKING_SERVICE_PATH + "/download_receipt",
        method: RequestType.POST
    },
    UPDATE_BOOKING: {
        url: ROOMJACK_API_URL + BOOKING_SERVICE_PATH + "/update",
        method: RequestType.POST
    },
    CREATE_TEMPORARY_BOOKING: {
        url: ROOMJACK_API_URL + BOOKING_SERVICE_PATH + "/create_temporary",
        method: RequestType.POST
    },

    ACCEPT_INQUIRY: {
        url: ROOMJACK_API_URL + BOOKING_SERVICE_PATH + "/accept_inquiry",
        method: RequestType.POST
    },
    DECLINE_INQUIRY: {
        url: ROOMJACK_API_URL + BOOKING_SERVICE_PATH + "/decline_inquiry",
        method: RequestType.POST
    },
    CREATE_SHORT_TERM_BOOKING_PROCESS: {
        url: ROOMJACK_API_URL + BOOKING_SERVICE_PATH + "/create_short_term_booking_process",
        method: RequestType.POST
    },
    SEND_GUEST_INVOICE: {
        url: ROOMJACK_API_URL + BOOKING_SERVICE_PATH + "/send_guest_invoice",
        method: RequestType.POST
    },
    DOWNLOAD_GUEST_INVOICE: {
        url: ROOMJACK_API_URL + BOOKING_SERVICE_PATH + "/download_guest_invoice",
        method: RequestType.POST
    },
    START_PAYMENT_METHOD_CHANGE: {
        url: ROOMJACK_API_URL + BOOKING_SERVICE_PATH + "/start_payment_method_change",
        method: RequestType.POST
    },
    CHANGE_PAYMENT_METHOD: {
        url: ROOMJACK_API_URL + BOOKING_SERVICE_PATH + "/change_payment_method",
        method: RequestType.POST
    },
    CHECK_DISCOUNT_CODE: {
        url: ROOMJACK_API_URL + BOOKING_SERVICE_PATH + "/check_discount_code",
        method: RequestType.POST
    },
    //endregion

    //region payment-service paths
    CREATE_SETUP_INTENT: {
        url: ROOMJACK_API_URL + PAYMENT_SERVICE_PATH + "/create_setup_intent",
        method: RequestType.POST
    },

    CREATE_PAYMENT_INTENT: {
        url: ROOMJACK_API_URL + PAYMENT_SERVICE_PATH + "/create_payment_intent",
        method: RequestType.POST
    },

    CREATE_CHECKOUT_SESSION: {
        url: ROOMJACK_API_URL + PAYMENT_SERVICE_PATH + "/create_checkout_session",
        method: RequestType.POST
    },

    GET_SESSION_PAYMENT_INTENT: {
        url: ROOMJACK_API_URL + PAYMENT_SERVICE_PATH + "/get_session_payment_intent",
        method: RequestType.POST
    },
    //endregion

    //region review service path
    POST_REVIEW: {
        url: ROOMJACK_API_URL + REVIEW_SERVICE_PATH,
        method: RequestType.POST
    },
    //endregion

    //region report service path
    POST_REPORT: {
        url: ROOMJACK_API_URL + REPORT_SERVICE_PATH,
        method: RequestType.POST
    }
    //endregion
}
//endregion

//region BASIC API Call methods
/**
 * Use this method for making calls to our server.
 * @param routeObject An object from the const RoomJackServerRouteMap
 * @param body The request body.
 * @param callback The callback on fetch success.
 * @param onUploadProgress The callback method for tracking the upload process.
 */
export function makeRoomJackAPICall(routeObject, body, callback, onUploadProgress=null) {
    makeAxiosCall(routeObject.url, routeObject.method, {
        'Content-Type': 'application/json',
        'device-id': localStorage.getItem(DEVICE_ID)
    }, body, callback, onUploadProgress);
}

/**
 * The method makes a REST-API call with the library axios.
 * @param url The request url.
 * @param method The request method.
 * @param headers The request headers.
 * @param body The request body.
 * @param callback The callback on fetch success.
 * @param onUploadProgress The callback method for tracking the upload process.
 */
export function makeAxiosCall(url, method, headers=null, body=null,
                                 callback=null, onUploadProgress=null) {
    axios({
        method: method,
        url: url,
        data: JSON.stringify(body),
        headers: headers,
        onUploadProgress: onUploadProgress
        }).then(response => {
            callback?.(response.data);
    });
}
//endregion

//region ACCOUNT REST-METHODS
/**
 * This method is triggered on application load. It tries to reopen a session or starts a public session.
 * @param callback The method to do things after the server response.
 */
export function loginPublic(callback) {
    let body = {};
    if (localStorage.getItem(SESSION_ID) !== null) {
        body.sessionID = localStorage.getItem(SESSION_ID);
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.LOGIN, body, callback);
}

/**
 * The method triggers a login process on the server.
 * @param email The email of the user
 * @param password The md5 hashed password of the user.
 * @param callback The method to do things after the server response.
 */
export function login(email, password, callback) {
    if (email.length === 0 || password.length === 0) {
        return;
    }
    let body = {
        email: email,
        password: password
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.LOGIN, body, callback);
}

/**
 * The method triggers a logout on the server
 * @param callback The method to do things after the server response.
 */
export function logout(callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.LOGOUT, { sessionID: localStorage.getItem(SESSION_ID)}, callback);
}

/**
 * The method triggers an account reactivation on the server.
 * @param email The email of the account to be reactivated.
 * @param password The md5 hashed password for the account to be reactivated.
 * @param md5Code The md5 hashed confirmation code for the reactivation.
 * @param callback The method to do things after the server response.
 */
export function reactivateAccount(email, password, md5Code, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    if (email === undefined || email.length === 0 ||
        password === undefined || password.length === 0 ||
        md5Code === undefined || md5Code.length === 0) {
        console.error("Please provide the email, password and confirmation code for the reactivation");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        email: email.toLowerCase(),
        password: password,
        confirmation_code: md5Code
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.REACTIVATE_ACCOUNT, body, callback);
}

/**
 * The method starts an account recovery on the server.
 * @param email The email of the account to be recovered.
 * @param callback The method to do things after the server response.
 */
export function startRecovery(email, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        email: email,
        appLang: globalLanguage };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.START_RECOVERY, body, callback);
}

/**
 * The method executes the recovery code check on the server.
 * @param md5RecoveryCode The md5 hashed code.
 * @param callback The method to do things after the server response.
 */
export function recover(md5RecoveryCode, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { recovery_code: md5RecoveryCode, sessionID: localStorage.getItem(SESSION_ID) };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.RECOVER, body, callback);
}

/**
 * The method executes an email-check on the server for the registration.
 * If it is valid and not registered, a verification mail will be sent to it.
 * @param email The email of the new user to be checked.
 * @param name The name of the new user.
 * @param callback The method to do things after the server response.
 */
export function checkEmailValidity(email, name, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        email: email,
        name: name,
        appLang: globalLanguage };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.EMAIL_VALID, body, callback);
}

/**
 * The method executes an account creation on the server.
 * @param registrationObject The collected registration data.
 * @param callback The method to do things after the server response.
 */
export function createAccount(registrationObject, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    registrationObject.sessionID = localStorage.getItem(SESSION_ID);
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.CREATE_ACCOUNT, registrationObject, callback);
}

/**
 * The method request the user's stripe account status and lets the server create an account link for a stripe session.
 * @param callback The method to do things after the server response.
 */
export function getAccountLink(callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID) };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.GET_ACCOUNT_LINK, body, function(response) {
        callback?.(response);
    });
}

/**
 * The method requests a confirmation code from the server for the vertain operation.
 * @param operation The operation (pause oder delete).
 * @param callback The method to do things after the server response.
 */
export function startAccountStatusChange(operation, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    if (operation !== 'pause' && operation !== 'delete') {
        console.error("Please provide the operation type (pause or delete).");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        operation: operation,
        status: true
    };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.START_STATUS_CHANGE, body, callback);
}

/**
 * The method triggers an account pausing or deletion on the server.
 * @param operation The operation (pause or delete).
 * @param reason The reason of the status change.
 * @param md5Code The md5 hashed confirmation code.
 * @param callback The method to do things after the server response.
 */
export function deactivateAccount(operation, reason, md5Code, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    if (operation === undefined || (operation !== 'pause' && operation !== 'delete') ||
        reason === undefined || reason.length === 0 ||
        md5Code === undefined || md5Code.length === 0) {
        console.error("Please provide the reason and the confirmation code");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        reason: reason,
        confirmation_code: md5Code,
        operation: operation
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.DEACTIVATE_ACCOUNT, body, callback);
}

/**
 * The method requests the connection status of an existing channel-manager account connection.
 * @param type The type of the channel-manager. Must be one of SUPPORTED_CHANNEL_MANAGERS.
 * @param body The data for the connection depending on the channel manager type.
 * @param callback The method to do things after the server response.
 */
export function getChannelManager(type, body, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    body.type = type;
    body.sessionID = localStorage.getItem(SESSION_ID);
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.GET_CHANNEL_MANAGER, body, callback);
}

/**
 * The method tries to connect a roomjack account to a channel-manager software.
 * @param type The type of the channel-manager. Must be one of SUPPORTED_CHANNEL_MANAGERS.
 * @param body The data for the connection depending on the channel manager type.
 * @param callback The method to do things after the server response.
 */
export function addChannelManager(type, body, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    body.type = type;
    body.sessionID = localStorage.getItem(SESSION_ID);
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.ADD_CHANNEL_MANAGER, body, callback);
}

/**
 * The method for updating an existing account connection.
 * @param type The type of the channel-manager. Must be one of SUPPORTED_CHANNEL_MANAGERS.
 * @param body The data for the update depending on the channel manager type.
 * @param callback The method to do things after the server response.
 */
export function updateChannelManagerAccount(type, body, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    body.type = type;
    body.sessionID = localStorage.getItem(SESSION_ID);
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.UPDATE_CHANNEL_MANAGER_ACCOUNT, body, callback);
}

/**
 * The method to uncouple a channel-manager account from a roomjack advert.
 * @param type The type of the channel-manager. Must be one of SUPPORTED_CHANNEL_MANAGERS.
 * @param body The data for the update depending on the channel manager type.
 * @param callback The method to do things after the server response.
 */
export function deleteChannelManager(type, body, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    body.type = type;
    body.sessionID = localStorage.getItem(SESSION_ID);
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.DELETE_CHANNEL_MANAGER, body, callback);
}

/*

/!**
 * The method to request the API-keys connected to the user.
 * @param callback The method to do things after the server response.
 *!/
function getThirdPartySoftware(callback) {
    if (sessionID === null || sessionID === undefined || sessionID.length === 0) {
        console.error("No session available.");
        return;
    }
    if (user === null || user === undefined) {
        console.error("You are not logged in.");
        return
    }
    let url = ROOMJACK_API_URL + getThirdPartySoftwareService;
    let body = { sessionID: sessionID };
    startRequest(url, RequestType.POST, body, function(response) {
        if (response.data !== undefined) {
            for (const apiKey of response.data) {
                unpackAPIKeyData(apiKey);
            }
        }
        callback?.(response);
    });
}

/!**
 * The method to request the API-keys connected to the user.
 * @param publicKey The public key of the API-key to be connected to the users account.
 * @param callback The method to do things after the server response.
 *!/
function addThirdPartySoftware(publicKey, callback) {
    if (sessionID === null || sessionID === undefined || sessionID.length === 0) {
        console.error("No session available.");
        return;
    }
    if (user === null || user === undefined) {
        console.error("You are not logged in.");
        return
    }
    if (publicKey === null || publicKey === undefined ||
        !publicKey.startsWith("rj-ak-live_")) {
        console.error("The public key must be provided");
    }
    let url = ROOMJACK_API_URL + addThirdPartySoftwareService;
    let body = { sessionID: sessionID, public_key: publicKey };
    startRequest(url, RequestType.POST, body, function(response) {
        if (response.data !== undefined) {
            for (const apiKey of response.data) {
                unpackAPIKeyData(apiKey);
            }
        }
        callback?.(response);
    });
}

/!**
 * The method to request the API-keys connected to the user.
 * @param publicKey The public key of the API-key to be disconnected from the users account.
 * @param callback The method to do things after the server response.
 *!/
function deleteThirdPartySoftware(publicKey, callback) {
    if (sessionID === null || sessionID === undefined || sessionID.length === 0) {
        console.error("No session available.");
        return;
    }
    if (user === null || user === undefined) {
        console.error("You are not logged in.");
        return
    }
    if (publicKey === null || publicKey === undefined ||
        !publicKey.startsWith("rj-ak-live_")) {
        console.error("The public key must be provided");
    }
    let url = ROOMJACK_API_URL + deleteThirdPartySoftwareService;
    let body = { sessionID: sessionID, public_key: publicKey };
    startRequest(url, RequestType.POST, body, function(response) {
        if (response.data !== undefined) {
            for (const apiKey of response.data) {
                unpackAPIKeyData(apiKey);
            }
        }
        callback?.(response);
    });
} */
//endregion

//region USER REST-METHODS
/**
 * The method updates the user.
 * @param userID The ID of the user to be updated.
 * @param update The update object containing all updates.
 *               For deleting an attribute, set the attribute name as key with value null.
 * @param callback The method to do things after the server response.
 */
export function updateUser(userID, update, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    if (update === null ||  Object.keys(update).length === 0) {
        console.warn("Update was empty");
        return;
    }
    update.sessionID = localStorage.getItem(SESSION_ID);
    update.userID = userID;
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.UPDATE_USER, update, callback);
}

/**
* The method to request data about the authors of a list of reviews.
* @param userList The author id-list.
* @param callback The method to do things after the server response.
*/
export function requestReviewAuthors(userList, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID), users: userList };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.REVIEW_AUTHORS, body, (response) => {
        if (response.users) {
            for (const user of response.users) {
                unpackUser(user, true);
                USER_CACHE.addCacheObject(user.id, new CacheObject(user));
            }
        }
        callback?.(response);
    });
}

/**
 * The method requests information about a user
 * @param id The ID of the user.
 * @param callback The method to do things after the server response.
 */
export function getUser(id, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { id: id, sessionID: localStorage.getItem(SESSION_ID) };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.GET_USER, body, (response) => {
        if (response.data) {
            unpackUser(response.data);
            USER_CACHE.addCacheObject(response.data.id, new CacheObject(response.data));
        }
        callback?.(response);
    });
}

/**
* The method to get the list of stored payment methods of the user
* @param callback A callback-method to do things after the server responded
*/
export function listPaymentMethods(callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID) };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.LIST_PAYMENT_METHODS, body, callback);
}

/**
 * The method updates the user's widget settings.
 * @param widgetData The data containing information about the widgets.
 * @param mode The mode the widgets are for.
 * @param callback The method to do things after the server-response
*/
export function setupWidgets(widgetData, mode, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        widgets: widgetData,
        menu_mode: mode
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.SETUP_WIDGETS, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode !== null) {
            console.log(errorCode + ': ' + response.message);
        }
        else if (response.data) {
            unpackWidgetData(response.data);
            for (const [type, data] of Object.entries(response.data)) {
                WIDGET_CACHE.addCacheObject(type, new CacheObject(data));
            }
        }
        callback?.(response);
    });
}

/**
 * The method synchronises the widget data for the user's actual mode.
 * @param widgetTypes The widget types, for which the data should be loaded
 * @param mode The current mode.
 * @param callback The callback-method to do things after the server responded
*/
export function syncWidgets(widgetTypes, mode, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        widgets: widgetTypes,
        menu_mode: mode
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.SYNC_WIDGETS, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode !== null || !response.success) {
            console.log(errorCode + ': ' + response.message);
        }
        else if (response.data) {
            unpackWidgetData(response.data);
            for (const [type, data] of Object.entries(response.data)) {
                WIDGET_CACHE.addCacheObject(type, new CacheObject(data));
            }
        }
        callback?.();
    });
}

//region financial tool methods
/**
 * The method requests the payouts of the user.
 * @param callback The method to do things after the server response.
 */
export function getPayouts(callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID) };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.GET_PAYOUTS, body, (response) => {
        if (response.data) {
            PAYOUT_CACHE.setCache(response.data);
        }
        else {
            PAYOUT_CACHE.setCache(null);
        }
        callback?.();
    });
}

/**
 * The method to request the booking invoices of the user in a certain time interval.
 * @param interval The time interval.
 * @param callback The method to do things after the server responded.
 */
export function getBookingInvoices(interval, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        ...interval,
        sessionID: localStorage.getItem(SESSION_ID)
    };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.GET_BOOKING_INVOICES, body, (response) => {
        if (response.data) {
            for (const booking of response.data) {
                unpackBooking(booking);
            }
        }
        BOOKING_INVOICES_CACHE.setCache(`${interval.start}-${interval.end}`, response.data ?? []);
        callback?.();
    });
}

/**
 * The method to request the unpaid rents of incoming bookings of the user.
 * @param callback The method to do things after the server responded.
 */
export function getUnpaidRents(callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID) };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.GET_UNPAID_RENTS, body, (response) => {
        if (response.data) {
            for (const booking of response.data) {
                unpackBooking(booking);
            }
            UNPAID_RENTS_CACHE.setCache(response.data);
        }
        else {
            BOOKING_INVOICES_CACHE.setCache(null);
        }
    });
    callback?.();
}
//endregion

/**
 * The method to synchronize the notifications of the actual user.
 */
export function syncNotifications(callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID) }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.SYNC_NOTIFICATIONS, body, (response) => {
        extractPredefinedProperties(response);
        callback?.(response);
    });
}

/**
 * The method to update a review of the user. It should be only used for the read status.
 * @param authorID The ID of the author.
 * @param status The read status
 */
export function updateUserReview(authorID, status) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        author_id: authorID,
        read: status
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.UPDATE_USER_REVIEW, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode !== undefined) {
            console.log(errorCode + ': ' + response.message);
        }
    });
}

/**
 * The method posts a review from the user to another user.
 * @param bookingID The ID of the booking about which the rating was made.
 * @param targetUserID The ID of the user for whom the rating was made.
 * @param reviewObject The review itself
 * @param callback The callback-method to do things after the server responded
 */
export function postUserReview(bookingID, targetUserID, reviewObject, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        booking_id: bookingID,
        userID: targetUserID,
        review: reviewObject
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.POST_USER_REVIEW, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode) {
            console.log(errorCode + ": " + response.message)
        }
        if (response.notifications) {
            unpackNotificationData(response.notifications);
        }
        callback?.(response);
    });
}
//endregion


//region ADVERT REST-METHODS
//region owner oriented methods

/**
 * This method is for the user himself, to request the list of his adverts from the server.
 * @param callback The method to do things after the server response.
 */
export function getAdverts(callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID) };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.GET_ADVERTS, body, (response) => {
        if (response.data) {
            for (const advert of response.data) {
                unpackAdvert(advert);
                ADVERT_CACHE.addCacheObject(advert.id, new CacheObject(advert));
            }
        }
        callback?.(response);
    });
}

/**
 * This method is for requesting the statistics of a landlord from the server.
 * @param callback The method to do things after the server response.
 */
export function getStatisticsData(callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID) };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.STATISTICS, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode) {
            console.warn(errorCode + ': ' + response.message);
            STATISTIC_CACHE.setCache(null);
        }
        else {
            if (response.bookings) {
                for (const booking of response.bookings) {
                    unpackBooking(booking);
                }
            }
            if (response.adverts) {
                for (const advert of response.adverts) {
                    unpackAdvert(advert);
                }
            }
            STATISTIC_CACHE.setCache(response);
        }
        callback?.(response);
    });
}

/**
 * The Rest-method to request bookings for the adverts of the user.
 * @param callback The callback method, to do things after the server responded.
 */
export function requestAdvertBookings(callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID) };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.ADVERT_BOOKINGS, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode || !response.success) {
            console.log(errorCode + ': ' + response.message);
        }
        else {
            unpackBookingDataSet(response.data);
            if (response.data.booking_list) {
                BOOKING_CACHE.addCacheObjects(response.data.booking_list.map(b => new CacheObject(b)));
            }
            if (response.data.advert_map) {
                ADVERT_CACHE.addCacheObjects(Object.values(response.data.adverts).map(a => new CacheObject(a)));
            }
        }
        callback?.(response.data);
    });
}

/**
 * The method to add or edit a calendar-sync-entry to/of a housing.
 * @param advertID The ID of the advert (mandatory).
 * @param roomID The ID of the room (optional).
 * @param name The name of the entry, which will be displayed in gantt (mandatory).
 * @param url The URL of the entry, which will be called (mandatory).
 * @param entryID The ID of the entry to be edited (if exists)
 * @param callback The method to do things after the server response.
 */
export function addCalendarSyncMapEntry(advertID, roomID, name, url, entryID, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID), advert_id: advertID, name: name, url: url };
    if (roomID) {
        body.room_id = roomID;
    }
    if (entryID) {
        body.id = entryID;
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.ADD_CALENDAR_SYNC, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode) {
            console.log(errorCode + ': ' + response.message);
        }
        else if (response.data) {
            unpackAdvert(response.data);
            ADVERT_CACHE.addCacheObject(response.data.id, new CacheObject(response.data));
        }
        callback?.(response);
    });
}

/**
* The method to add a calendar-sync-entry to a housing.
* @param advertID The ID of the advert (mandatory).
* @param roomID The ID of the room (optional).
* @param id The id of the entry to be deleted (mandatory).
* @param callback The method to do things after the server response.
*/
export function deleteCalendarSyncMapEntry(advertID, roomID, id, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID), advert_id: advertID, id: id };
    if (roomID) {
        body.room_id = roomID;
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.DELETE_CALENDAR_SYNC, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode) {
            console.log(errorCode + ': ' + response.message);
        }
        else if (response.data) {
            unpackAdvert(response.data);
            ADVERT_CACHE.addCacheObject(response.data.id, new CacheObject(response.data));
        }
        callback?.(response);
    });
}

/**
* The method to add booking free period for an advert
* @param advertID The ID of the advert (mandatory).
* @param roomID The ID of the room (optional).
* @param bookingFreePeriod The period object
* @param callback The method to do things after the server response.
*/
export function addBookingFreePeriod(advertID, roomID, bookingFreePeriod, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        advert_id: advertID,
        booking_free_period: bookingFreePeriod
    };
    if (roomID) {
        body.room_id = roomID;
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.ADD_BOOKING_FREE_PERIOD, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode) {
            console.log(errorCode + ': ' + response.message);
        }
        else if (response.data) {
            unpackAdvert(response.data);
            ADVERT_CACHE.addCacheObject(response.data.id, new CacheObject(response.data));
        }
        callback?.(response);
    });
}

/**
* The method to add booking free period for an advert
* @param advertID The ID of the advert (mandatory).
* @param roomID The ID of the room (optional).
* @param bookingFreePeriodID The ID of the existing period object
* @param callback The method to do things after the server response.
*/
export function deleteBookingFreePeriod(advertID, roomID, bookingFreePeriodID, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        advert_id: advertID,
        booking_free_period: bookingFreePeriodID
    };
    if (roomID) {
        body.room_id = roomID;
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.DELETE_BOOKING_FREE_PERIOD, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode) {
            console.log(errorCode + ': ' + response.message);
        }
        else if (response.data) {
            unpackAdvert(response.data);
            ADVERT_CACHE.addCacheObject(response.data.id, new CacheObject(response.data));
        }
        callback?.(response);
    });
}

/**
* The method to create or edit a current price template.
* @param advertID The id of the target advert.
* @param id The ID of the template, if already exists.
* @param description The description for the template.
* @param price The price of the template
* @param callback The callback method, to do things after the server response.
*/
export function addCurrentPriceTemplate(advertID, id, description, price, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    description = description.substring(0, 20);
    price = Math.max(1, Math.min(price, PRICE_LIMIT));
    let body = {
        advert_id: advertID,
        sessionID: localStorage.getItem(SESSION_ID),
        description: description,
        price: price }
    ;
    if (id) {
        body.id = id;
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.ADD_CURRENT_PRICE_TEMPLATE, body, (response) => {
        if (response.errorCode) {
            console.warn(response.errorCode + ": " + response.message);
        }
        else if (response.data) {
            unpackAdvert(response.data);
            ADVERT_CACHE.addCacheObject(response.data.id, new CacheObject(response.data));
        }
        callback?.(response);
    });
}

/**
* The method to delete a current price template.
* @param advertID The id of the target advert.
* @param id The ID of the template to be deleted.
* @param callback The callback method, to do things after the server response.
*/
export function deleteCurrentPriceTemplate(advertID, id, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        advert_id: advertID,
        sessionID: localStorage.getItem(SESSION_ID),
        id: id };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.DELETE_CURRENT_PRICE_TEMPLATE, body, (response) => {
        if (response.errorCode) {
            console.warn(response.errorCode + ": " + response.message);
        }
        else if (response.data) {
            unpackAdvert(response.data);
            ADVERT_CACHE.addCacheObject(response.data.id, new CacheObject(response.data));
        }
        callback?.(response);
    });
}

/**
* The method to create or edit a current price period for a housing.
* @param housingID The ID of the housing (mandatory).
* @param periodID The ID of the current price period if exists.
* @param start The start timestamp in ms.
* @param end The end timestamp in ms (optional)
* @param templateID The ID of the current price template.
* @param price The price in cents, if no templateID defined.
* @param repeat The number of day of the week on which the period should be repeated (1-7).
* @param callback The callback method, to do things after the server response.
*/
export function addCurrentPricePeriod(housingID, periodID, start, end, templateID, price, repeat, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        housing_id: housingID,
        start: start
    };
    if (templateID !== null && templateID !== undefined) {
        body.template_id = templateID;
    }
    if (price !== null && price !== undefined) {
        body.price = price;
    }
    if (periodID !== undefined && periodID !== null) {
        body.id = periodID;
    }
    if (end !== undefined && end !== null) {
        body.end = end;
    }
    if (repeat !== undefined && repeat !== null) {
        body.repeat = repeat;
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.ADD_CURRENT_PRICE_PERIOD, body, (response) => {
        if (response.errorCode) {
            console.warn(response.errorCode + ": " + response.message);
        }
        else if (response.data) {
            unpackAdvert(response.data);
            ADVERT_CACHE.addCacheObject(response.data.id, new CacheObject(response.data));
        }
        callback?.(response);
    });
}

/**
* The method to delete a current price period for a housing.
* @param housingID The ID of the advert (mandatory).
* @param periodID The ID of the current price period to be deleted.
* @param callback The callback method, to do things after the server response.
*/
export function deleteCurrentPricePeriod(housingID, periodID, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        housing_id: housingID,
        id: periodID
    };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.DELETE_CURRENT_PRICE_PERIOD, body, (response) => {
        if (response.errorCode) {
            console.warn(response.errorCode + ": " + response.message);
        }
        else if (response.data) {
            unpackAdvert(response.data);
            ADVERT_CACHE.addCacheObject(response.data.id, new CacheObject(response.data));
        }
        callback?.(response);
    });
}

/**
* The method to start a deletion process for an advert. This method sends a deletion request to the server.
* If the advert can be deleted, the server sends an e-mail with a confirmation code to the owner.
* @param advertID The ID of the advert to be deleted.
* @param callback The callback method, to do things after the server response.
*/
export function startAdvertDeletion(advertID, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        advert_id: advertID,
        appLang: globalLanguage
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.START_DELETE_ADVERT, body, callback);
}

/**
* The method to delete an advert of the user.
* @param advertID The ID of the advert to be deleted.
* @param callback The callback method, to do things after the server response.
*/
export function deleteAdvert(advertID, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID), advert_id: advertID };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.DELETE_ADVERT, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode !== null) {
            console.log(errorCode + ': ' + response.message);
        }
        if (response.data) {
            unpackAdvert(response.data);
            ADVERT_CACHE.addCacheObject(response.data.id, new CacheObject(response.data));
        }
        else if (response.user) {
            ADVERT_CACHE.removeCachedObject(advertID);
        }
        callback?.(response);
    });
}

/**
* The method to toggle the publication status of an advert.
* @param advertID The ID of the target advert
* @param published Boolean. If true, the server tries to publish the advert. Otherwise it will be set to unpublished.
* @param callback The callback method, to do things after the server response.
*/
export function setPublicationStatus(advertID, published, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { published: published, sessionID: localStorage.getItem(SESSION_ID), advert_id: advertID };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.SET_PUBLICATION_STATUS, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode !== null) {
            console.log(errorCode + ': ' + response.message);
            console.log(response.requirements);
        }
        else if (response.data) {
            unpackAdvert(response.data);
            ADVERT_CACHE.addCacheObject(response.data.id, new CacheObject(response.data));
        }
        callback?.(response);
    });
}

/**
* The method to duplicate an advert.
* @param advertID The ID of the target advert.
* @param title The title of the new advert.
* All valid attributes in this map will replace the original attributes in the the duplicated advert.
* @param callback The method to do things after the server-response.
*/
export function duplicateAdvert(advertID, title, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID), advert_id: advertID, title: title };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.DUPLICATE_ADVERT, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode !== null) {
            console.log(errorCode + ': ' + response.message);
        }
        if (response.data) {
            unpackAdvert(response.data);
            ADVERT_CACHE.addCacheObject(response.data.id, new CacheObject(response.data));
        }
        callback?.(response);
    });
}

/**
 * This method triggers a quick pro-tool status switch.
 * @param advertID The ID of the target advert.
 * @param status The next pro-tool status
 * @param callback The method to do things after the server-response.
 */
export function setProToolStatus(advertID, status, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { pro_tool: status, sessionID: localStorage.getItem(SESSION_ID), advert_id: advertID }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.SET_PRO_TOOL_STATUS, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode !== null) {
            console.log(errorCode + ': ' + response.message);
        }
        if (response.data) {
            unpackAdvert(response.data);
            ADVERT_CACHE.addCacheObject(response.data.id, new CacheObject(response.data));
        }
        callback?.(response);
    });
}

/**
* The method to create a payment intent for boosting an advert.
* @param advertID The ID of the advert should be boosted.
* @param boosterType The type of the booster { color_accent, search_preference, gold, gold_plus }
* @param days The number of days, the booster should last.
* @param setupIntentID The client secret, if there is already a payment intent created.
* @param callback The method to do things after the server-response.
*/
export function buyBooster(advertID, boosterType, days, setupIntentID, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    if (advertID === null || advertID === undefined) {
        console.error("The advertID must be passed.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        advert_id: advertID,
        type: boosterType,
        setup_intent_id: setupIntentID
    };
    if (days) {
        body.days = days;
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.BUY_BOOSTER, body, callback);
}

/**
 * Th method to update a review of the user. It should be only used for the read status.
 * @param advertID The ID of the advert containing the target review.
 * @param authorID The ID of the author.
 * @param status The read status
 */
export function updateAdvertReview(advertID, authorID, status) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        advert_id: advertID,
        author_id: authorID,
        read: status
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.UPDATE_ADVERT_REVIEW, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode !== undefined) {
            console.log(errorCode + ': ' + response.message);
        }
    });
}

/**
 * The method to create or edit an advert.
 * @param advertObject The data object.
 * @param sendNotification A boolean to send a notification about publishing per mail.
 * @param callback The callback method, to do things after the server response.
 * @param onUploadProgress The callback event for displaying the upload progress.
 */
export function postAdvert(advertObject, sendNotification, callback, onUploadProgress) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    advertObject.sessionID = localStorage.getItem(SESSION_ID);
    advertObject.send_notification = sendNotification;
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.POST_ADVERT, advertObject, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode !== null) {
            console.log(errorCode + ': ' + response.message);
        }
        else {
            unpackAdvert(response.data);
            ADVERT_CACHE.addCacheObject(response.data.id, new CacheObject(response.data));
        }
        callback?.(response);
    }, onUploadProgress);
}
//endregion

//region public advert methods
/**
 * This method is for all users and should be called in the housing-search.
 * @param data An object containing the filters.
 * @param callback The callback-function, that should be called after the server responded.
 */
export function searchAdverts(data, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    data.sessionID = localStorage.getItem(SESSION_ID);
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.SEARCH, data, callback);
}

/**
 * The method to add a housing as favorite
 * @param id The ID of the housing
 * @param callback The callback-function, that should be called after the server responded.
 */
export function addFavoriteHousing(id, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID), housing_id: id }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.ADD_FAVORITE, body, callback);
}

/**
* The method to delete a housing as favorite
* @param id The ID of the housing.
* @param callback The callback-function, that should be called after the server responded.
*/
export function deleteFavoriteHousing(id, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID), housing_id: id };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.DELETE_FAVORITE, body, (response) => {
        FAVORITE_CACHE.remove(id);
        callback?.(response);
    });
}

/**
* The method to get the favorized housings of the user.
* @param callback The callback-function, that should be called after the server responded.
*/
export function getFavoriteHousings(callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID) };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.GET_FAVORITES, body, (response) => {
        if (response.data) {
            for (const advert of response.data) {
                unpackAdvert(advert);
                ADVERT_CACHE.addCacheObject(advert.id, new CacheObject(advert));
            }
            FAVORITE_CACHE.setCache(response.data);
        }
        else {
            FAVORITE_CACHE.setCache(null);
        }
        callback?.(response);
    });
}

/**
 * The method to get the a specific housing.
 * @param housingID The ID of the housing.
 * @param callback The callback-function, that should be called after the server responded.
 */
export function getHousing(housingID, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID), housing_id: housingID};
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.GET_ADVERT, body, (response) => {
        if (response.data) {
            unpackAdvert(response.data);
            HOUSING_CACHE.addCacheObject(housingID, new HousingCacheObject(response.data));
        }
        callback?.(response);
    });
}

/**
 * This method increments the clicks on a specific
 * @param housingID The ID of the target housing, that was clicked
 * @param counterType The type of the counter to be increment (must be one of possible HOUSING_COUNTER_TYPES)
 */
export function incrementHousingCounter(housingID, counterType) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let serverRoute = counterType === HousingCounterType.sharing ?
        ROOMJACK_SERVER_ROUTE_MAP.INCREMENT_SHARINGS :
        ROOMJACK_SERVER_ROUTE_MAP.INCREMENT_CLICK_COUNT;
    let body = { sessionID: localStorage.getItem(SESSION_ID), housing_id: housingID };
    makeRoomJackAPICall(serverRoute, body, null);
}

/**
 * This method requests the adverts of a specific user.
 * @param userID The ID of the user.
 * @param exclude Optional. Can be a single advertID or a list of them to be excluded.
 * @param callback The callback to do things after the server response.
 */
export function getAdvertsOfUser(userID, exclude, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID), userID: userID };
    if (exclude !== undefined) {
        body.exclude = exclude;
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.GET_USER_ADVERTS, body, (response) => {
        if (response.data) {
            for (const advert of response.data) {
                unpackAdvert(advert);
                ADVERT_CACHE.addCacheObject(advert.id, new CacheObject(advert));
            }
        }
        callback?.(response);
    });
}

/**
 * This method requests some recommended adverts from the server for the main landing page.
 * @param callback The callback to do things after the server response.
 */
export function getStartPageRecommendations(callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID) };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.GET_RECOMMENDATIONS, body, (response) => {
        if (response.result_list) {
            for (const advert of response.result_list) {
                unpackAdvert(advert);
                ADVERT_CACHE.addCacheObject(advert.id, new CacheObject(advert));
            }
            RECOMMENDATION_CACHE.setCache(response.result_list);
        }
        else {
            RECOMMENDATION_CACHE.setCache(null);
        }
        callback?.(response);
    });
}

/**
* The methods collects all bookings, that starts or ends in the next given days from today.
* @param callback The callback-method to do things after the server responded
*/
export function getTODOList(callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID), days: 30 };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.TODOS, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode !== null) {
            console.error(errorCode + ': ' + response.message);
        }
        else if (response.data) {
            if (!response.data.result_list) {
                response.data.result_list = [];
            }
            if (!response.data.advert_map) {
                response.data.advert_map = {};
            }
            for (const booking of response.data.result_list) {
                unpackBooking(booking);
            }
            TODO_CACHE.setCache(response.data.result_list);
            for (const advert of Object.values(response.data.advert_map)) {
                unpackAdvert(advert);
                ADVERT_CACHE.addCacheObject(advert.id, new CacheObject(advert));
            }
        }
        callback?.(response.data);
    });
}

/**
 * The method to add, edit or delete a review.
 * @param bookingID The booking ID
 * @param housingID The ID of the target advert.
 * @param reviewObject This should contain the review object for create or edit:
 *        value: integer from 1 to 5 (mandatory), title and description: strings (both optional).
 *        For delete this can be null.
 * @param callback The callback-function, that should be called after the server responded.
 */
export function postHousingReview(bookingID, housingID, reviewObject, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        booking_id: bookingID,
        housing_id: housingID,
        review: reviewObject
    };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.POST_ADVERT_REVIEW, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode) {
            console.log(errorCode + ": " + response.message);
        }
        if (response.notifications) {
            unpackNotificationData(response.notifications);
        }
        callback?.(response);
    });
}

//endregion
//endregion

//region CONVERSATION REST-METHODS

/**
 * The method to send a message to somebody.
 * @param sender  - The sender user object.
 * @param receiver  - The receiver user object.
 * @param messageText - The message.
 * @param conversationID  - The ID of the conversation (optional).
 * @param imageData - An image as bas64-string (optional)
 * @param lastMsgID - The ID of the last known message on the client (optional, only relevant if chat active)
 * @param callback - The callback to do things after the server response.
 */
export function sendMessage(sender, receiver, messageText, conversationID, imageData, lastMsgID, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        sender_id: sender.id,
        message: messageText
    };
    if (conversationID !== undefined && conversationID !== null && conversationID.length > 0) {
        body.conversation_id = conversationID;
    }
    else if (receiver && receiver.id) {
        body.receiver_id = receiver.id;
    }
    else {
        console.error("Either the ID of the conversation or the ID of the message receiver must be passed.")
        return;
    }
    if (lastMsgID !== null && lastMsgID !== undefined) {
        body.last_msg_id = lastMsgID;
    }

    if (imageData !== undefined) {
        body.image = imageData;
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.SEND_MESSAGE, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode !== null || !response.success) {
            console.log(errorCode + ': ' + response.message);
        }
        else {
            if (response.conversation) {
                unpackConversation(response.conversation);
            }
            if (response.conversation_id) {
                let cache = CONVERSATION_CACHE.getCache();
                if (cache) {
                    let conversation = cache.filter(c => c.id === response.conversation_id)[0];
                    if (conversation) {
                        let messages = response.messages;
                        messages.forEach(function(m) {
                            unpackMessage(m)
                        });
                        if (!conversation.messages) {
                            conversation.messages = [];
                        }
                        conversation.messages = messages.concat(conversation.messages);
                        countUnreadMessages(conversation, sender.id);
                    }
                    sortConversationsByTimestamp(cache, sender.id);
                    CONVERSATION_CACHE.setCache(cache);
                }
            }
        }
        callback?.(response);
    });
}

/**
 * The method to get the preview-infos about all conversations of the user or for sync.
 * @param actualConversationMap The map of conversations loaded conversation (ids) and their last message ids the user actually has.
 * @param userID The ID of the user
 * @param callback A callback-method to do things after the server responded
 */
export function syncConversations(actualConversationMap, userID, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID) };
    if (actualConversationMap) {
        body.conversations = actualConversationMap;
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.SYNC_CONVERSATION, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode !== null || !response.success) {
            console.log(errorCode + ': ' + response.message);
        }
        if (response.conversations) {
            for (const conversation of response.conversations) {
                countUnreadMessages(conversation, userID);
                unpackConversation(conversation);
            }

            let conversations;
            let cache = CONVERSATION_CACHE.getCache();
            if (cache) {
                conversations = [...cache];
                for (const conversation of response.conversations) {
                    let cachedConversation = cache.filter(c => c.id === conversation.id)[0];
                    if (!cachedConversation) {
                        conversations.push(conversation);
                    }
                    else if (conversation.messages) {
                        if (!cachedConversation.messages) {
                            cachedConversation.messages = [];
                        }
                        cachedConversation.messages = conversation.messages.concat(cachedConversation.messages);
                    }
                }
            }
            else {
                conversations = response.conversations;
            }

            sortConversationsByTimestamp(conversations, userID);
            CONVERSATION_CACHE.setCache(conversations);
        }
        callback?.();
    });
}

/**
* The method to set a conversation as pinned for the user
* @param conversationID The ID of the conversation
* @param userID The ID of the user
* @param callback A callback-method to do things after the server responded
*/
export function deleteConversation(conversationID, userID, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        conversation_id: conversationID
    };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.DELETE_CONVERSATION, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode !== null || !response.success) {
            console.log(errorCode + ': ' + response.message);
        }
        else {
            let cache = CONVERSATION_CACHE.getCache();
            if (cache) {
                cache = cache.filter(c => c.id !== conversationID);
                sortConversationsByTimestamp(cache, userID)
                CONVERSATION_CACHE.setCache(cache);
            }
        }
        callback?.(response);
    });
}

/**
* The method to set a conversation as pinned for the user
* @param conversationID The ID of the conversation
* @param userID
* @param callback A callback-method to do things after the server responded
*/
export function pinConversation(conversationID, userID, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        conversation_id: conversationID
    };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.PIN_CONVERSATION, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode !== null || !response.success) {
            console.log(errorCode + ': ' + response.message);
        }
        else {
            let cache = CONVERSATION_CACHE.getCache();
            if (cache) {
                let conversation = cache.filter(c => c.id === conversationID)[0];
                if (conversation) {
                    conversation.pinned = response.pinned;
                }
                sortConversationsByTimestamp(cache, userID)
                CONVERSATION_CACHE.setCache(cache);
            }
        }
        callback?.();
    });
}

/**
 * With this method messages of a conversation can be loaded
 * @param conversationID The ID of the conversation
 * @param lastMsgID The timestamp of the last loaded messages (should be also depending on the search dir)
 * @param dir The loading direction (-1: get messages that are older as the last message, 1: opposite)
 * @param userID The ID of the user.
 * @param callback A callback-method to do things after the server responded
 */
export function getMessages(conversationID, lastMsgID=null, dir=1, userID, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        conversation_id: conversationID,
        last_msg_id: lastMsgID,
        msg_load_dir: dir
    };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.GET_MESSAGES, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode !== null || !response.success) {
            console.log(errorCode + ': ' + response.message);
        }
        else {
            let messages = response.messages;
            if (messages && messages.length > 0) {
                messages.forEach(function(m) {
                    unpackMessage(m);
                });
                let cache = CONVERSATION_CACHE.getCache();
                if (cache) {
                    let conversation = cache.filter(c => c.id === conversationID)[0];
                    if (conversation) {
                        if (!lastMsgID || !conversation.messages) {
                            conversation.messages = messages;
                        }
                        else {
                            if (dir > 0) {
                                conversation.messages = messages.concat(conversation.messages);
                            } else {
                                conversation.messages = conversation.messages.concat(messages);
                            }
                        }
                        if (response.exists_more) {
                            conversation.exists_more = response.exists_more;
                        }
                        countUnreadMessages(conversation, userID);
                    }
                    sortConversationsByTimestamp(cache, userID)
                    CONVERSATION_CACHE.setCache(cache);
                }
            }
        }
        callback?.();
    });
}

/**
 * With this method the actual messages of a conversation can be marked as read.
 * @param conversationID The ID of the target conversation.
 * @param lastMsgID The ID of the last (youngest) message
 * @param userID
 * @param callback The method to do things after the server response.
 */
export function setConversationRead(conversationID, lastMsgID, userID, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        conversation_id: conversationID,
        last_msg_id: lastMsgID
    };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.SET_CONVERSATION_READ, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode !== null) {
            console.log(errorCode + ': ' + response.message);
        }
        else {
            let cache = CONVERSATION_CACHE.getCache();
            if (cache) {
                let conversation = cache.filter(c => c.id === conversationID)[0];
                if (conversation) {
                    let lastMsg = conversation.messages.filter(m => m.id === lastMsgID)[0];
                    if (lastMsg) {
                        conversation.messages.forEach(m => {
                            if (m.timestamp <= lastMsg.timestamp) {
                                m.read = true;
                            }
                        });
                    }
                    countUnreadMessages(conversation, userID);
                }
                sortConversationsByTimestamp(cache, userID);
                CONVERSATION_CACHE.setCache(cache);
            }
        }
        callback?.();
    });
}
//endregion

//region BOOKING REST-METHODS
/**
 * This method sends a request to the server to proof if a housing is available in the requested time interval.
 * @param advertID The ID of the target advert.
 * @param roomID (OPTIONAL) The ID of the room, if the advert is a house or apartment.
 * @param startMS The start of the time interval in milliseconds.
 * @param endMS The end of the time interval in milliseconds.
 * @param callback The callback method, to do things after the server-response.
 */
export function proofHousingAvailability(advertID, roomID, startMS, endMS, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID), advert_id: advertID, start: startMS, end: endMS  };
    if (roomID !== null) {
        body.room_id = roomID;
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.AVAILABLE_FOR_BOOKING, body, callback);
}

/**
 * The method to request the own bookings of the user.
 * @param callback The callback method, to do things after the server-response.
 */
export function requestMyBookings(callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID) };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.MY_BOOKINGS, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode) {
            console.log(errorCode + ': ' + response.message);
        }
        else {
            unpackBookingDataSet(response);
            if (response.booking_list) {
                BOOKING_CACHE.addCacheObjects(response.booking_list.map(b => new CacheObject(b)));
            }
        }
        callback?.(response);
    });
}

/**
 * The method to request transaction details of a booking depending on the user role.
 * @param bookingID The ID of the booking.
 * @param callback The callback method, to do things after the server-response.
 */
export function getBookingTransactionDetails(bookingID, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID), booking_id: bookingID };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.TRANSACTION_DETAILS, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode) {
            console.warn(errorCode + ' - ' + response.message);
        }
        else {
            let booking = BOOKING_CACHE.getCacheObjectData(bookingID);
            if (booking) {
                booking.transaction_details_loaded = true;
                booking.charge_data = response.charge_data;
            }
        }
        callback?.(response);
    });
}

/**
 * The method to get the receipt of the booking as pdf.
 * @param bookingID The ID of the target booking.
 * @param charge The target charge.
 * @param callback The callback method, to do things after the server-response.
 */
export function downloadReceipt(bookingID, charge, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID), booking_id: bookingID, charge_id: charge.id };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.DOWNLOAD_RECEIPT, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode || !response.success) {
            console.warn(errorCode + ': ' + response.message);
        }
        else {
            charge.receipt_url = response.data;
        }
        callback?.();
    });
}

/**
 * The method triggers the server for sending an invoice to the guest.
 * @param bookingID The ID of the booking.
 * @param chargeID The ID of the target charge of the booking.
 * @param invoiceNo The invoice number entered by the landlord.
 * @param message The message of the landlord to the guests (optional).
 * @param callback The callback method, to do things after the server-response.
 */
export function sendGuestInvoice(bookingID, chargeID, invoiceNo, message, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        landlord_invoice_no: invoiceNo,
        sessionID: localStorage.getItem(SESSION_ID),
        booking_id: bookingID,
        charge_id: chargeID
    };
    if (message) {
        body.message = message;
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.SEND_GUEST_INVOICE, body, callback);
}

/**
 * Starts a payment process and creates a temporary booking on the server in the same call.
 * This one should be only used for direct bookings.
 * @param advertID The target advert ID
 * @param roomID The target room ID. This is an optional parameter and can be set to null.
 * @param startMS The start of the booking time interval in milliseconds.
 * @param endMS The end of the booking time interval in milliseconds.
 * @param setupIntentID The ID of the setup intent created on the booking checkout
 * @param discountID The ID of a redeemed discount (optional).
 * @param callback The callback method to do things after the server response.
 */
export function createShortTermBookingProcess(advertID, roomID, startMS, endMS, setupIntentID, discountID, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        advert_id: advertID,
        start: startMS,
        end: endMS,
        setup_intent_id: setupIntentID
    };
    if (roomID) {
        body.room_id = roomID;
    }
    if (discountID) {
        body.discount = discountID;
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.CREATE_SHORT_TERM_BOOKING_PROCESS, body, callback);
}

/**
 * Creates a booking inquiry. This one should be only used for inquiries.
 * @param advertID The target advert ID
 * @param roomID The target room ID. This is an optional parameter and can be set to null.
 * @param startMS The start of the booking time interval in milliseconds.
 * @param endMS The end of the booking time interval in milliseconds.
 * @param setupIntentID The ID of the setup intent created on the booking checkout
 * @param discountID The ID of a redeemed discount (optional).
 * @param message The message of the booker to the landlord
 * @param callback The callback method to do things after the server response.
 */
export function createInquiry(advertID, roomID, startMS, endMS, setupIntentID,
                              discountID, message, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        advert_id: advertID,
        start: startMS,
        end: endMS,
        setup_intent_id: setupIntentID,
        message: message,
    };
    if (roomID) {
        body.room_id = roomID;
    }
    if (discountID) {
        body.discount = discountID;
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.CREATE_TEMPORARY_BOOKING, body, callback);
}

/**
 * The method to accept an inquiry.
 * @param bookingID The ID of the booking inquiry.
 * @param lastMsgID The ID of the last known message
 * @param userID The ID of the user.
 * @param callback The callback method to do things after the server response.
 */
export function acceptInquiry(bookingID, lastMsgID, userID, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        booking_id: bookingID,
    };
    if (lastMsgID) {
        body.last_msg_id = lastMsgID;
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.ACCEPT_INQUIRY, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode !== null || !response.success) {
            console.log(errorCode + ': ' + response.message);
        }
        else {
            if (response.messages) {
                for (const message of response.messages) {
                    unpackMessage(message);
                }
                let cache = CONVERSATION_CACHE.getCache();
                if (cache) {
                    let conversation = cache.filter(c => c.id === response.conversation_id)[0];
                    if (conversation) {
                        conversation.messages = response.messages.concat(conversation.messages);
                        let inquiryMessage = conversation.messages.filter(m => m.inquiry && m.inquiry.id === bookingID)[0];
                        if (inquiryMessage !== undefined) {
                            if (inquiryMessage.inquiry) {
                                inquiryMessage.inquiry.accepted = true;
                                inquiryMessage.inquiry.data.accepted = true;
                            }
                        }
                    }
                    countUnreadMessages(conversation, userID);
                    sortConversationsByTimestamp(cache, userID);
                    CONVERSATION_CACHE.setCache(cache);
                }
            }
        }
        callback?.(response);
    });
}

/**
 * The method to decline an inquiry.
 * @param bookingID The ID of the booking inquiry
 * @param lastMsgID The ID of the last known message
 * @param userID The ID of the user.
 * @param callback The method to do things after the server-response.
 */
export function declineInquiry(bookingID, lastMsgID, userID, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        booking_id: bookingID
    };
    if (lastMsgID) {
        body.last_msg_id = lastMsgID;
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.DECLINE_INQUIRY, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode !== null || !response.success) {
            console.log(errorCode + ': ' + response.message);
        }
        else {
            if (response.messages) {
                for (const message of response.messages) {
                    unpackMessage(message);
                }
                let cache = CONVERSATION_CACHE.getCache();
                if (cache) {
                    let conversation = cache.filter(c => c.id === response.conversation_id)[0];
                    if (conversation) {
                        conversation.messages = response.messages.concat(conversation.messages);
                        let inquiryMessage = conversation.messages.filter(m => m.inquiry && m.inquiry.id === bookingID)[0];

                        if (inquiryMessage !== undefined) {
                            if (inquiryMessage.inquiry) {
                                inquiryMessage.inquiry.accepted = false;
                                delete inquiryMessage.inquiry.data;
                            }
                        }
                    }
                    countUnreadMessages(conversation, userID);
                    sortConversationsByTimestamp(cache, userID);
                    CONVERSATION_CACHE.setCache(cache);
                }
            }
        }
        callback?.(response);
    });
}

/**
* The method to cancel a booking
* @param targetID The ID of the booking to be cancelled.
* @param reasonObject An object containing the reason and description for the cancellation.
* @param callback The method to do things after the server-response.
*/
export function cancelBooking(targetID, reasonObject, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID), booking_id: targetID, cancellation_reason: reasonObject };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.CANCEL_BOOKING, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode !== null) {
            console.log(errorCode + ': ' + response.message);
        }
        else if (response.booking) {
            unpackBooking(response.booking);
            BOOKING_CACHE.addCacheObject(response.booking.id, new CacheObject(response.booking));
        }
        callback?.(response);
    });
}

/**
 * This method triggers a payment method change process for a booking
 * @param bookingID The ID of the booking.
 * @param callback The method to do things after the server-response.
 */
export function startPaymentMethodChange(bookingID, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID), booking_id: bookingID };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.START_PAYMENT_METHOD_CHANGE, body, callback);
}

/**
 * This method executes a payment method change process for a booking
 * @param bookingID The ID of the booking.
 * @param setupIntentID The ID of the setup intent.
 * @param callback The method to do things after the server-response.
 */
export function changePaymentMethodForBooking(bookingID, setupIntentID, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        booking_id: bookingID,
        setup_intent_id: setupIntentID };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.CHANGE_PAYMENT_METHOD, body, (response) => {
        if (response.booking) {
            unpackBooking(response.booking);
            BOOKING_CACHE.addCacheObject(response.booking.id, new CacheObject(response.booking));
        }
        callback?.(response);
    });
}

/**
* The method to update a booking. Will be used for the landlord's task-list and in the notification center
* @param bookingID The ID of the booking
* @param update The update data
* @param callback The callback method, to do things after the server-response
*/
export function updateBooking(bookingID, update, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        booking_id: bookingID,
        update: update
    };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.UPDATE_BOOKING, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode !== null || !response.success) {
            console.log(errorCode + ': ' + response.message);
        }
        if (response.booking) {
            unpackBooking(response.booking);
            let cache = TODO_CACHE.getCache();
            if (!cache) {
                cache = [response.booking];
            }
            else {
                let index = cache.findIndex(b => b.id === bookingID);
                if (index < 0) {
                    cache.push(response.booking);
                }
                else {
                    cache.splice(index, 1, response.booking);
                }
            }
            TODO_CACHE.setCache(cache);
        }
        callback?.();
    });
}

/**
 *
 * @param code The discount code.
 * @param callback The callback method to do things after the server response.
 */
export function checkDiscountCode(code, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        code: code
    };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.CHECK_DISCOUNT_CODE, body, (response) => {
        if (response.discount) {
            unpackDiscount(response.discount);
        }
        callback?.(response);
    });
}
//endregion

//region PAYMENT REST-METHODS

/**
 * This method creates a setup intent for the user.
 * It will be used to create and store payment methods in the profile editor.
 * @param paymentType The type of the payment
 * @param accountOwnerID The ID of the connect account user, for which the intent should be created.
 * If not set, the intent will be created on the platform
 * @param metadata Optional metadata to be passed to the setup intent.
 * @param callback The callback method, to do things after the server response.
*/
export function createSetupIntent(paymentType, metadata, accountOwnerID, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        payment_type: paymentType
    };
    if (metadata) {
        body.metadata = metadata;
    }
    if (accountOwnerID !== null && accountOwnerID !== undefined) {
        body.owner_id = accountOwnerID;
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.CREATE_SETUP_INTENT, body, callback);
}

/**
 * The method creates a platform payment intent to be confirmed directly on the client.
 * If it was successful, the server responds with a client secret.
 * @param paymentType The type of the payment
 * @param amount The amount of the payment in cents.
 * @param metadata Optional metadata to be passed to the setup intent.
 * @param callback The callback method, to do things after the server response.
 */
export function createPaymentIntent(paymentType, amount, metadata, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        payment_type: paymentType,
        amount: amount
    };
    if (metadata) {
        body.metadata = metadata;
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.CREATE_PAYMENT_INTENT, body, callback);
}

/**
 * The method to create a checkout session for payments such as paypal.
 * @param paymentMethod The payment type, must be paypal, sofort or klarna
 * @param amount The amount of the payment in cents (only in case of platform payments)
 * @param description Required if the amount is specified. Will be set as description for the checkout session line item.
 * @param metadata Optional metadata to be passed to the checkout session payment intent data (only in case of platform payments).
 * @param accountOwnerID The ID of the connect account user, for which the intent should be created.
 * If not set, the session will be created for a platform payment
 * @param successURL The redirection URL if the session was successful.
 * @param cancelURL The redirection URL if the session was cancelled.
 * @param callback The callback method, to do things after the server response.
 */
export function createCheckoutSession(paymentMethod, amount, description, metadata, accountOwnerID, successURL, cancelURL, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        payment_method: paymentMethod,
        success_url: successURL,
        cancel_url: cancelURL
    }
    if (accountOwnerID) {
        body.owner_id = accountOwnerID;
    }
    if (amount) {
        body.amount = amount;
    }
    if (description) {
        body.description = description;
    }
    if (metadata) {
        body.metadata = metadata;
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.CREATE_CHECKOUT_SESSION, body, callback);
}

/**
 * This method requests the payment intent client secret for a checkout session.
 * @param sessionID The ID of the checkout session.
 * @param callback The callback method, to do things after the server response.
 */
export function getSessionPaymentIntent(sessionID, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        id: sessionID
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.GET_SESSION_PAYMENT_INTENT, body, callback);
}

export function downloadGuestInvoice(invoiceDataID, conversationID, messageID, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = {
        sessionID: localStorage.getItem(SESSION_ID),
        invoice_data: invoiceDataID
    };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.DOWNLOAD_GUEST_INVOICE, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode !== null) {
            console.error(response);
        }
        else {
            unpackBooking(response.invoice_data, false);
            let cache = CONVERSATION_CACHE.getCache();
            if (cache) {
                let conversation = cache.filter(c => c.id === conversationID)[0];
                if (conversation) {
                    let message = conversation.messages ?
                        conversation.messages.filter(m => m.id === messageID)[0] : null;
                    if (message) {
                        message.invoice_data = response.invoice_data;
                        message.invoice_data.invoice_data_id = invoiceDataID;
                        CONVERSATION_CACHE.setCache(cache);
                    }
                }
            }
        }
        callback?.(response);
    });
}
//endregion

//region REPORT REST-METHODS
/**
 * The method to report a user or advert to the support team.
 * @param targetID The ID of the user or advert to be reported (in case of advert_report or user_report)
 * @param reportObject The report object containing following data:
 *  - The reason of the report (one of REPORT_REASONS)
 *  - The statement message (1-1000 chars)
 *  - mandatory on public session => The email, first- and last-name of the user
 *  - optional on public session => A phone number
 * @param callback The method to do things after the server response.
 */
export function sendReport(targetID, reportObject, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID), data: reportObject };
    if (targetID !== null && targetID !== undefined) {
        body.target_id = targetID;
    }
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.POST_REPORT, body, callback);
}
//endregion

//region REVIEW REST-METHODS
export function postReview(reviewData, callback) {
    if (localStorage.getItem(SESSION_ID) === null) {
        console.error("No session available.");
        return;
    }
    let body = { sessionID: localStorage.getItem(SESSION_ID), review: reviewData };
    makeRoomJackAPICall(ROOMJACK_SERVER_ROUTE_MAP.POST_REVIEW, body, (response) => {
        let errorCode = extractErrorCode(response);
        if (errorCode !== null || !response.success) {
            console.log(errorCode + ': ' + response.message);
        }
        callback?.(response);
    });
}
//endregion

