import React from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getLanguageEntry} from "../../utils/Helper";

function FetchButton(props) {


    return (
        <button className={"fetch-button " + props.className} id={props.id} type={props.type} form={props.form}
                onClick={props.onClick} disabled={props.loading || props.disabled} style={props.style}
                tabIndex={props.tabIndex}>
            { !props.loading && props.children }
            {
                props.loading &&
                <div className="loading">
                    <FontAwesomeIcon icon={["fad", "spinner-third"]} spin />
                    {
                        props.loadingText &&
                        <span>{getLanguageEntry(props.loadingText)}</span>
                    }
                </div>
            }
        </button>
    )

}

FetchButton.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
    loadingText: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    form: PropTypes.any,
    tabIndex: PropTypes.number
}
FetchButton.defaultProps = {
    loading: false,
    type: "button",
    disabled: false
}
export default FetchButton;