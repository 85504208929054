import React from "react";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import {getLanguageEntry} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FetchButton from "../buttons/FetchButton";
import {deleteAdvert, startAdvertDeletion} from "../../utils/RESTInterface";
import parse from "html-react-parser";
import md5 from "md5";

class AdvertDeletionModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            loading: false,
            deleting: false,
            advert: null,
            code_result: null,
            deletion_result: null,
            code: null
        }
    }

    render() {
        return (<Modal className={"advert-deletion-modal landlord"} show={this.state.show} size="lg" backdrop="static"
                   keyboard={false} onHide={() => { this.hide() }} dialogClassName="max-content" centered>
                <Modal.Header>
                    <h4>{getLanguageEntry("processes>advert_deletion>title")}</h4>
                    <button onClick={() => { this.hide() }} disabled={this.state.loading || this.state.deleting}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>

                <Modal.Body style={{display: "grid", gap: "20px" }}>
                    {
                        this.state.advert && !this.state.deletion_result &&
                        (!this.state.code_result || !this.state.code_result.errorCode) &&
                        <div className="description-container">
                            {
                                getLanguageEntry("processes>advert_deletion>description")
                            }
                        </div>
                    }
                    {
                        this.state.advert && !this.state.deletion_result && this.state.code_result &&
                        !this.state.code_result.errorCode &&
                        <div className="horizontal-form-group" style={{justifySelf: "center"}}>
                            <label htmlFor="" className="description-container">
                                {
                                    getLanguageEntry("processes>advert_deletion>delete_code")
                                }
                            </label>
                            <input type="text" onKeyUp={(e) => {
                                e.preventDefault();
                                this.setState({code: e.target.value});
                            }}
                                   placeholder={getLanguageEntry("processes>advert_deletion>delete_code_placeholder")} />
                        </div>
                    }
                    {
                        this.state.advert && this.state.deletion_result &&
                        <div className="process-modal-result-container">
                            <div className="process-result-image">
                                <img src={this.state.deletion_result.errorCode ?
                                    "https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear.png" :
                                    "https://roomjack.blob.core.windows.net/roomjack/content-images/High-Five.gif"}
                                     alt={"Advert deletion " + (this.state.deletion_result.errorCode ? "failure" : "success")} />
                            </div>
                            <div className="process-result-message-box">
                                <div className="header">
                                    {getLanguageEntry(this.state.deletion_result.errorCode ?
                                        "processes>advert_deletion>failure" : "processes>advert_deletion>success")}
                                </div>
                                {
                                    this.state.deletion_result.errorCode && this.getCodeResultError()}
                            </div>
                        </div>
                    }
                    {
                        this.state.advert && !this.state.deletion_result && this.state.code_result &&
                        this.state.code_result.errorCode &&
                        <div className="process-modal-result-container">
                            <div className="process-result-image">
                                <img src={"https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear.png"}
                                     alt={"Advert deletion not possible"} />
                            </div>
                            <div className="process-result-message-box">
                                <div className="header">
                                    {getLanguageEntry("processes>advert_deletion>failure")}
                                </div>
                                {this.getCodeResultError()}
                            </div>
                        </div>
                    }
                </Modal.Body>
                {
                    this.state.advert &&
                    <Modal.Footer>
                        {
                            !this.state.deletion_result && !this.state.code_result &&
                            <FetchButton onClick={() => {this.requestSecurityCode()}} loading={this.state.loading}
                                         loadingText="general>requesting_code" className="outline-button accent">
                                {getLanguageEntry("general>request_code")}
                            </FetchButton>
                        }
                        {
                            !this.state.deletion_result && this.state.code_result &&
                            this.state.code && md5(this.state.code) === this.state.code_result.confirmation_code &&
                            <FetchButton onClick={() => {this.startDeleteAdvert()}} loading={this.state.deleting}
                                         loadingText="processes>advert_deletion>deleting_advert"
                                         className="outline-button accent">
                                {getLanguageEntry("processes>advert_deletion>delete_advert_irrevocable")}
                            </FetchButton>
                        }
                    </Modal.Footer>
                }
            </Modal>)
    }

    show(advert) {
        this.setState({
            show: true,
            loading: false,
            deleting: false,
            advert: advert,
            code_result: null,
            deletion_result: null,
            code: null
        })
    }

    hide() {
        this.setState({
            show: false
        })
    }

    requestSecurityCode() {
        let that = this;
        this.setState({loading: true});
        startAdvertDeletion(this.state.advert.id, (response) => {
            that.setState({
                loading: false,
                code_result: response
            })
        })
    }

    getCodeResultError() {
        if (!this.state.code_result && !this.state.code_result.errorCode &&
            !this.state.deletion_result && !this.state.deletion_result.errorCode) {
            return;
        }
        let target = this.state.deletion_result ?? this.state.code_result;
        switch (target.errorCode) {
            case 'advert_not_found':
                return <div className="description-container">
                    {getLanguageEntry("processes>advert_deletion>advert_not_found")}
                </div>;
            case 'advert_del_err_1':
                return <div className="description-container">
                    {parse(getLanguageEntry("processes>advert_deletion>advert_not_deletable"))}
                </div>;
            default:
                return <div className="description-container">
                    <span>{getLanguageEntry("general>please_contact_support")}</span>
                    <button className="link-button" onClick={() => {this.props.onContactSupport()}}>
                        {getLanguageEntry("general>support")}
                    </button>.
                </div>
        }
    }

    startDeleteAdvert() {
        let that = this;
        this.setState({
            deleting: true,
            deletion_result: null,
            code: null
        });
        deleteAdvert(this.state.advert.id, (response) => {
            that.setState({
                deleting: false,
                code_result: null,
                deletion_result: response
            });
            if (response.data || response.user) {
                that.props.onAdvertChanged?.(response);
            }
        });
    }

}

AdvertDeletionModal.propTypes = {
    onContactSupport: PropTypes.func.isRequired,
    onAdvertChanged: PropTypes.func.isRequired
}
AdvertDeletionModal.defaultProps = {}
export default AdvertDeletionModal;