import React from "react";
import PropTypes from "prop-types";
import {getLanguageEntry} from "../../../../utils/Helper";
import { determineClickBookingData} from "../../../../utils/StatisticHelper";

class ClickBookingRatio extends React.Component {

    render() {
        let statisticData = determineClickBookingData(this.props.interval, this.props.bookings, this.props.adverts);
        return(
            <div className="statistic-container">
                <div className="statistic-title">
                    {getLanguageEntry("jackboard>stats>submenus>general_statistics>title")}
                </div>
                <div className="content-container">
                    <div id="clicks" className="statistic-display">
                        <div className="statistic-display-headline">
                            {getLanguageEntry("jackboard>stats>submenus>click_booking_ratio>click_header")}
                        </div>
                        <div className="statistic-display-value">{statisticData.clicks}</div>
                    </div>
                    <div id="completed-bookings" className="statistic-display">
                        <div className="statistic-display-headline">
                            {getLanguageEntry("jackboard>stats>submenus>general_statistics>completed_bookings_header")}
                        </div>
                        <div className="statistic-display-value">{statisticData.bookings}</div>
                    </div>
                </div>
            </div>
        )
    }

}

ClickBookingRatio.propTypes = {
    adverts: PropTypes.array,
    bookings: PropTypes.array,
    interval: PropTypes.number
}
export default ClickBookingRatio;