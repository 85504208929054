import {useState} from "react";
import {Modal} from "react-bootstrap";
import {
    generateOpenAIRequestText,
    getLanguageEntry, makeOpenAIRequest,
    MAX_DESCRIPTION_LENGTH,
    MAX_TITLE_LENGTH
} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import FetchButton from "../buttons/FetchButton";
import TextArea from "../input/TextArea";
import {RUNTIME_MODE} from "../../../resources/version-dependent-variables";
import {RuntimeMode} from "../../utils/Types.ts";

const LANG_PATH = "jackboard>adverts>advert_creator>description>";

export default function AIDescriptionGenerator({description, onCancel, onAccept, advert}) {
    const [loading, setLoading] = useState(false);
    const [descriptionInput, setDescriptionInput] = useState(description ?? "");
    const [generatedTitle, setGeneratedTitle] = useState(null);
    const [generatedDescription, setGeneratedDescription] = useState(null);

    const useJacKI = async function() {
        if (RUNTIME_MODE !== RuntimeMode.development) {
            return;
        }
        setLoading(true);
        let request = generateOpenAIRequestText(advert, descriptionInput);
        const body = {
            model: "gpt-3.5-turbo",
            messages: [
                {role: "system", content: `Your task now is to support the landlord and generate a catchy title (max length ${MAX_TITLE_LENGTH} chars) and a good description (max length ${MAX_DESCRIPTION_LENGTH} chars) for a housing advert in german language. Try to fill for both title and description the max possible length. Please return your result as a JSON-Object as follows: {title: generatedTitle, description: generatedDescription}.`},
                {role: "user", content: request}
            ],
            max_tokens: 2000,
        }
        const chatCompletion = await makeOpenAIRequest(body);
        setLoading(false);
        if (chatCompletion && chatCompletion.choices && chatCompletion.choices.length > 0 &&
            chatCompletion.choices[0].message && chatCompletion.choices[0].message.content) {
            const result = JSON.parse(chatCompletion.choices[0].message.content);
            if (result && result.title) {
                setGeneratedTitle(result.title);
            }
            if (result && result.description) {
                setGeneratedDescription(result.description);
            }
        }
        console.log(chatCompletion);
    }

    return <Modal show={true} backdrop="static" size="xl"
                  keyboard={false} className="open-ai-description-generator" centered>
        <Modal.Header>
            <h4>{getLanguageEntry(`${LANG_PATH}description_generator_title`)}</h4>
            <button onClick={onCancel}>
                <FontAwesomeIcon icon={["fal", "close"]}/>
            </button>
        </Modal.Header>
        <Modal.Body>
            <div className="description-container">
                {getLanguageEntry(`${LANG_PATH}description_generator_description`)}
            </div>
            <div className="description-container" style={{fontStyle: "italic", margin: "15px 0"}}>
                {getLanguageEntry(`${LANG_PATH}description_generator_hint`)}
            </div>
            <TextArea onChange={setDescriptionInput} maxLength={1000} label={`${LANG_PATH}your_description`} />
            <div className="roomjack-headline" style={{margin: "15px 0"}}>
                {getLanguageEntry(`${LANG_PATH}ai_generated_title`)}
            </div>
            <TextArea initialValue={generatedTitle} readOnly={true} label={`${LANG_PATH}ai_generated_title`} />
            <div className="roomjack-headline" style={{margin: "15px 0"}}>
                {getLanguageEntry(`${LANG_PATH}ai_generated_description`)}
            </div>
            <TextArea initialValue={generatedDescription} readOnly={true} label={`${LANG_PATH}ai_generated_description`} />
        </Modal.Body>
        <Modal.Footer>
            {
                generatedTitle && generatedDescription && !loading &&
                <button className="outline-button accent"
                        onClick={() => {onAccept(generatedTitle, generatedDescription)}}>
                    <span>
                      {getLanguageEntry(`${LANG_PATH}accept_suggestion`)}
                    </span>
                </button>
            }
            <FetchButton className="outline-button black"
                         loading={loading} onClick={useJacKI}>
                <FontAwesomeIcon icon={["fal", "robot"]} />
                <span>{getLanguageEntry('components>ai_assistant>ask_jacki')}</span>
            </FetchButton>
            <button className="outline-button gray" onClick={onCancel}>
                <FontAwesomeIcon icon={["fal", "times"]}/>
                <span>
                  {getLanguageEntry("general>abort")}
                </span>
            </button>
        </Modal.Footer>
    </Modal>

}
AIDescriptionGenerator.propTypes = {
    description: PropTypes.string,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    advert: PropTypes.any.isRequired,
}
