import React from "react";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import {getLanguageEntry} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FetchButton from "../buttons/FetchButton";
import {duplicateAdvert} from "../../utils/RESTInterface";

class AdvertDuplicationModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            loading: false,
            advert: null,
            response: null
        }
        this.form = React.createRef();
    }

    render() {
        return (<Modal className={"advert-duplication-modal landlord"} show={this.state.show} size="lg" backdrop="static"
                       keyboard={false} onHide={() => { this.hide() }} dialogClassName="max-content" centered>
            <Modal.Header>
                <h4>{getLanguageEntry("processes>advert_duplication>title")}</h4>
                <button onClick={() => { this.hide() }} disabled={this.state.loading}>
                    <FontAwesomeIcon icon={["fal", "close"]}/>
                </button>
            </Modal.Header>

            <Modal.Body style={{display: "grid", gap: "20px" }}>
                {
                    this.state.advert && !this.state.response &&
                    <div className="description-container">
                        {
                            getLanguageEntry("processes>advert_duplication>description")
                        }
                    </div>
                }
                {
                    this.state.advert && !this.state.response &&
                    <form ref={this.form} onSubmit={(e) => {this.triggerDuplication(e)}}
                          style={{display: "grid", gap: "20px", justifyItems: "center"}}>
                        <div className="horizontal-form-group">
                            <label htmlFor="new-title" className="description-container">
                                {getLanguageEntry("processes>advert_duplication>duplicate_title_label")}
                            </label>
                            <input type="text" required={true} name="title" id="new-title"/>
                        </div>
                        <FetchButton loading={this.state.loading} type="submit" className="outline-button accent"
                                     loadingText="processes>advert_duplication>duplicating_advert">
                            {getLanguageEntry("processes>advert_duplication>title")}
                        </FetchButton>
                    </form>
                }
                {
                    this.state.advert && this.state.response &&
                    <div className="process-modal-result-container">
                        <div className="process-result-image">
                            <img src={this.state.response.errorCode ?
                                "https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear.png" :
                                "https://roomjack.blob.core.windows.net/roomjack/content-images/High-Five.gif"}
                                 alt={"Advert duplication " + (this.state.response.errorCode ? "failure" : "success")} />
                        </div>
                        <div className="process-result-message-box">
                            <div className="header">
                                {getLanguageEntry("processes>advert_duplication>" +
                                    (this.state.response.errorCode ? "failure" : "success"))}
                            </div>
                            {
                                this.state.response.errorCode &&
                                <div className="description-container">
                                    <span>{getLanguageEntry("general>please_contact_support")}</span>
                                    <button className="link-button" onClick={() => {this.props.onContactSupport()}}>
                                        {getLanguageEntry("general>support")}
                                    </button>.
                                </div>
                            }
                        </div>
                    </div>
                }
            </Modal.Body>
        </Modal>)
    }

    show(advert) {
        this.setState({
            show: true,
            loading: false,
            advert: advert,
            response: null,
        })
    }

    hide() {
        this.setState({
            show: false
        })
    }

    triggerDuplication(e) {
        e.preventDefault();
        let title = new FormData(this.form.current).get("title")
        this.setState({loading: true});
        let that = this;
        duplicateAdvert(this.state.advert.id, title, (response) => {
            that.setState({
                loading: false,
                response: response
            });
            if (response.data) {
                that.props.onAdvertDuplicated?.(response.data);
            }
        })
    }

}
AdvertDuplicationModal.propTypes = {
    onContactSupport: PropTypes.func.isRequired,
    onAdvertDuplicated: PropTypes.func.isRequired
}
export default AdvertDuplicationModal;