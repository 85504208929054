import React from "react";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import {getLanguageEntry} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FilterSelectorBar from "../FilterSelectorBar";
import {Mode} from "../../utils/Types.ts";

class ItemSelectorModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            on_save: null,
            category: "all",
            regex: "",
            selection_map: {}
        }
        this.allItems = new Set([].concat.apply([], Object.values(props.itemMap)));
        this.allItemList = Array.from(this.allItems).sort((a, b) => {
            return getLanguageEntry(this.props.itemLangPath + a).localeCompare(getLanguageEntry(this.props.itemLangPath + b))
        });
        this.customItems = new Set();
    }

    render() {
        return <Modal className={"items-selector-modal " + this.props.mode + (this.props.className ? " " + this.props.className : "")}
                      show={this.state.show} onHide={() => {this.hide()}}
                      dialogClassName="max-content" size="lg" centered>
            <Modal.Header>
                <h4>{getLanguageEntry(this.props.title)}</h4>
                <button onClick={() => { this.hide() }}>
                    <FontAwesomeIcon icon={["fal", "close"]}/>
                </button>
            </Modal.Header>
            <Modal.Body>
                {
                    this.props.categories &&
                    <FilterSelectorBar items={this.props.categories} defaultValue={this.state.category}
                                       onChange={(v) => {this.setState({category: v})} }/>
                }
                {
                    this.props.description &&
                    <div className="description-container">
                        {getLanguageEntry(this.props.description)}
                    </div>
                }
                {
                    this.props.searchPlaceholder &&
                    <input type="text" onKeyUp={(e) => {this.onSearchInputChange(e)}}
                           defaultValue={this.state.regex} placeholder={getLanguageEntry(this.props.searchPlaceholder)}/>
                }
                <div className="checkbox-grid">
                    {
                        this.getItems().map((i, _) => {
                            let label = this.allItems.has(i) ?
                                getLanguageEntry(this.props.itemLangPath + i) : i;
                            if (this.matchesRegex(i, label)) {
                                return <div className="form-group" key={i}>
                                    <input type="checkbox" id={i} name={i}
                                           checked={this.state.selection_map[i]}
                                           onChange={(e) => {this.onSelectionChange(e, i)}}/>
                                    <label htmlFor={i}>{label}</label>
                                </div>
                            }
                            return null;
                        })
                    }
                </div>
                <form onSubmit={(e) => {this.addCustomItem(e)}}
                      className="horizontal-form-group" style={{justifyContent: "center", marginTop: "auto"}}>
                    <input type="text" maxLength={this.props.customItemLength} required={true} style={{width: "300px"}}
                           name="custom_item"
                           placeholder={getLanguageEntry(this.props.customItemPlaceholder).replace("{x}", "" + this.props.customItemLength)}/>
                    <button type="submit" className="simple-icon-button border">
                        <FontAwesomeIcon icon={["fal", "plus"]} />
                    </button>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className="outline-button accent" onClick={() => {
                    this.state.on_save?.(Object.keys(this.state.selection_map).filter(i => this.state.selection_map[i]));
                    this.hide();
                }}>
                    {getLanguageEntry("general>done")}
                    <FontAwesomeIcon icon={["fas", "check"]}/>
                </button>
            </Modal.Footer>
        </Modal>
    }

    show(items, category, onSave) {
        // setup predefined items
        let selectionMap = {};
        for (const item of this.allItems.keys()) {
            selectionMap[item] = items && items.includes(item);
        }
        // setup custom items
        this.customItems.clear();
        if (items) {
            for (const item of items) {
                if (!selectionMap[item]) {
                    selectionMap[item] = true;
                    this.customItems.add(item);
                }
            }
        }
        this.setState({
            show: true,
            category: category,
            on_save: onSave,
            selection_map: selectionMap,
            regex: ""
        });
    }

    hide() {
        this.setState({
            show: false,
            on_save: null
        })
    }

    onSelectionChange(e, i) {
        let map = {...this.state.selection_map};
        map[i] = e.target.checked;
        this.setState({selection_map: map});
    }

    onSearchInputChange(e) {
        e.preventDefault();
        this.setState({regex: e.target.value.toLowerCase()})
    }

    matchesRegex(item, label) {
        return item.toLowerCase().includes(this.state.regex) || label.toLowerCase().includes(this.state.regex)
    }

    addCustomItem(e) {
        e.preventDefault();
        let formData = new FormData(e.target);
        let customItem = formData.get("custom_item");
        let selectionMap = {...this.state.selection_map};
        selectionMap[customItem] = true;
        this.customItems.add(customItem);
        e.target.reset();
        this.setState({
            selection_map: selectionMap,
            category: "all"
        });
    }

    getItems() {
        if (this.state.category === "all") {
            return this.allItemList.concat(Array.from(this.customItems));
        }
        return this.props.itemMap[this.state.category];
    }

}
ItemSelectorModal.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    categories: PropTypes.array,
    itemLangPath: PropTypes.string.isRequired,
    customItemLength: PropTypes.number.isRequired,
    customItemPlaceholder: PropTypes.string.isRequired,
    itemMap: PropTypes.object.isRequired
}
ItemSelectorModal.defaultProps = {
    mode: Mode.landlord
}
export default ItemSelectorModal;