import React from "react";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Mode} from "../../utils/Types.ts";
import {getLanguageEntry} from "../../utils/Helper";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import {usePDF} from "react-to-pdf";

function PrintOverviewModal({document, onlyPreview=false, filename, mode, onClose}) {
    const { toPDF, targetRef } = usePDF({
        filename,
    });

    return <Modal className={`print-overview-modal ${mode}`} show={true}
                  fullscreen={true} onHide={onClose} centered>
        <Modal.Header>
            <h4>{filename ?? ""}</h4>
            <button onClick={onClose}>
                <FontAwesomeIcon icon={["fal", "close"]}/>
            </button>
        </Modal.Header>
        <Modal.Body>
            <div ref={targetRef}>{document}</div>
        </Modal.Body>
        {
            !onlyPreview &&
            <Modal.Footer>
                {
                    filename &&
                    <button className="outline-button black" onClick={toPDF}>
                        <FontAwesomeIcon icon={["fal", "file-pdf"]} />
                        <span>PDF-Download</span>
                    </button>
                }
                <ReactToPrint content={() => targetRef} documentTitle={filename}>
                    <PrintContextConsumer>
                        {({handlePrint}) => (
                            <button className="outline-button accent" onClick={handlePrint}>
                                <FontAwesomeIcon icon={["fal", "print"]}/>
                                {getLanguageEntry("general>print")}
                            </button>
                        )}
                    </PrintContextConsumer>
                </ReactToPrint>
            </Modal.Footer>
        }
    </Modal>
}

PrintOverviewModal.propTypes = {
    title: PropTypes.string,
    document: PropTypes.element.isRequired,
    onlyPreview: PropTypes.bool,
    filename: PropTypes.string,
    mode: PropTypes.oneOf(Object.values(Mode)).isRequired,
    onClose: PropTypes.func.isRequired,
}
export default PrintOverviewModal;