import React from "react";
import {
    determineAdvertNameLabel,
    getFormattedDate,
    getLanguageEntry, getTodoRelevantBookings
} from "../../../utils/Helper";
import Loading from "../../../components/Loading";
import FilterSelectorBar from "../../../components/FilterSelectorBar";
import {ADVERT_CACHE, TODO_CACHE} from "../../../utils/CacheHandler.ts";
import {getTODOList, updateBooking} from "../../../utils/RESTInterface";
import AlertModal from "../../../components/modals/AlertModal";
import {AccessType, Mode} from "../../../utils/Types.ts";
import PropTypes from "prop-types";
import PrintOverviewModal from "../../../components/modals/PrintOverviewModal";
import ToDoList from "../../../components/printables/ToDoList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const LANG_PATH = "jackboard>todos>";

class ToDos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            result_list: [],
            adverts: null,
            filter: 0,
            document: null
        }
        this.alert_modal = React.createRef();
    }

    componentDidMount() {
        let cache = TODO_CACHE.getCache();
        if (cache) {
            let adverts = {};
            for (const booking of cache) {
                if (!adverts[booking.advert_id]) {
                    adverts[booking.advert_id] = ADVERT_CACHE.getCacheObject(booking.advert_id).getData();
                }
            }
            this.setState({
                result_list: cache,
                adverts: adverts
            });
        }
        else {
            let that = this;
            this.setState({loading: true});
            getTODOList((response) => {
                let resultList = response ? response.result_list : [];
                let adverts = response ? response.advert_map : {};
                that.setState({
                    loading: false,
                    result_list: resultList,
                    adverts: adverts
                });
            })
        }

    }

    render() {
        let data = this.createTODOTableData();
        return (
            <div id="my-todos">
                {
                    this.state.loading &&
                    <Loading imageWidth="250px" />
                }
                {
                    !this.state.loading &&
                    <h1 className="desktop-tool-title">
                        {getLanguageEntry(LANG_PATH + "title")}
                    </h1>
                }
                {
                    !this.state.loading &&
                    <div className="horizontal-form-group">
                        <FilterSelectorBar items={this.getFilter()} defaultValue={this.state.filter}
                                           onChange={(v) => {this.setState({filter: v})}}  />
                        <button className="outline-button accent" onClick={() => {this.printToDos(data)}}>
                            <FontAwesomeIcon icon={["fal", "print"]}/>
                            <span>{getLanguageEntry(LANG_PATH + "print_todo_list")}</span>
                        </button>
                    </div>
                }
                {
                    !this.state.loading && data.check_ins.length === 0 && data.check_outs.length === 0 &&
                    <div className="roomjack-container no-data-container">
                        <img src="https://roomjack.blob.core.windows.net/roomjack/content-images/roomjack_faultier_nachrichten_2.png"
                             alt="No bookings" style={{objectFit: "contain"}}/>
                        <div className="description-container">
                            {getLanguageEntry(LANG_PATH + "placeholder" +
                                (!this.state.result_list || this.state.result_list.length === 0 ? "" : "_interval"))}
                        </div>
                    </div>
                }
                {
                    !this.state.loading && this.state.result_list && this.state.result_list.length > 0 &&
                    (data.check_ins.length > 0 || data.check_outs.length > 0) &&
                    <div className="roomjack-container">
                        <div className="roomjack-container-header">
                            {getLanguageEntry(LANG_PATH + "widget_labels>check_ins")}
                        </div>
                        {
                            data.check_ins.length === 0 &&
                            <div className="description-container">
                                {getLanguageEntry(LANG_PATH + "table_placeholders>jack_in_placeholder")}
                            </div>
                        }
                        {
                            data.check_ins.length > 0 &&
                            <div className="todo-data-container">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>
                                            {getLanguageEntry(LANG_PATH + "table_headers>booker_name")}
                                        </th>
                                        <th>
                                            {getLanguageEntry(LANG_PATH + "table_headers>housing_id")}
                                        </th>
                                        <th>
                                            {getLanguageEntry(LANG_PATH + "table_headers>date")}
                                        </th>
                                        <th>
                                            {getLanguageEntry(LANG_PATH + "table_headers>jack_in_time")}
                                        </th>
                                        <th>
                                            {getLanguageEntry(LANG_PATH + "table_headers>key_handover")}
                                        </th>
                                        <th>
                                            {getLanguageEntry(LANG_PATH + "table_headers>process_status")}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        data.check_ins.map((row, _) => {
                                            return <tr key={row.booking_id} className={row.checked ? "done" : ""}>
                                                <td className="description-container">{row.booker}</td>
                                                <td className="description-container">{row.housing_id}</td>
                                                <td className="description-container">{row.date}</td>
                                                <td className="description-container">{row.check_in_time}</td>
                                                <td className="description-container">{row.access}</td>
                                                <td>
                                                    <div className="form-group">
                                                        <input type="checkbox" id={row.booking_id}
                                                               onClick={(e) => {
                                                                   e.preventDefault();
                                                                   this.callAlertForChange({check_in: e.target.checked}, row.booking_id);
                                                               }} readOnly={true} checked={row.checked}/>
                                                        <label htmlFor={row.booking_id}>
                                                            {getLanguageEntry(LANG_PATH + "table_placeholders>completed")}
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                }

                {
                    !this.state.loading && this.state.result_list && this.state.result_list.length > 0 &&
                    (data.check_ins.length > 0 || data.check_outs.length > 0) &&
                    !this.state.loading && this.state.result_list && this.state.result_list.length > 0 &&
                    <div className="roomjack-container">
                        <div className="roomjack-container-header">
                            {getLanguageEntry(LANG_PATH + "widget_labels>check_outs")}
                        </div>
                        {
                            data.check_outs.length === 0 &&
                            <div className="description-container">
                                {getLanguageEntry(LANG_PATH + "table_placeholders>jack_in_placeholder")}
                            </div>
                        }
                        {
                            data.check_outs.length > 0 &&
                            <div className="todo-data-container">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>
                                            {getLanguageEntry(LANG_PATH + "table_headers>booker_name")}
                                        </th>
                                        <th>
                                            {getLanguageEntry(LANG_PATH + "table_headers>housing_id")}
                                        </th>
                                        <th>
                                            {getLanguageEntry(LANG_PATH + "table_headers>date")}
                                        </th>
                                        <th>
                                            {getLanguageEntry(LANG_PATH + "table_headers>jack_out_time")}
                                        </th>
                                        <th>
                                            {getLanguageEntry(LANG_PATH + "table_headers>key_back")}
                                        </th>
                                        <th>
                                            {getLanguageEntry(LANG_PATH + "table_headers>cleaning")}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        data.check_outs.map((row, _) => {
                                            return <tr key={row.booking_id} className={row.checked ? "done" : ""}>
                                                <td className="description-container">{row.booker}</td>
                                                <td className="description-container">{row.housing_id}</td>
                                                <td className="description-container">{row.date}</td>
                                                <td className="description-container">{row.check_out_time}</td>
                                                <td className="description-container">
                                                    <div className="form-group">
                                                        <input type="checkbox" id={"co_" + row.booking_id}
                                                               onClick={(e) => {
                                                                   e.preventDefault();
                                                                   this.callAlertForChange({check_out: e.target.checked}, row.booking_id);
                                                               }} readOnly={true} checked={row.checked}/>
                                                        <label htmlFor={"co_" + row.booking_id}>
                                                            {getLanguageEntry(LANG_PATH + "table_placeholders>completed")}
                                                        </label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form-group">
                                                        <input type="checkbox" id={"cl_" + row.booking_id}
                                                               onClick={(e) => {
                                                                   e.preventDefault();
                                                                   this.callAlertForChange({cleaning: e.target.checked}, row.booking_id);
                                                               }} readOnly={true} checked={row.cleaning_checked}/>
                                                        <label htmlFor={"cl_" + row.booking_id}>
                                                            {getLanguageEntry(LANG_PATH + "table_placeholders>completed")}
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                }
                <AlertModal mode={this.props.mode} ref={this.alert_modal} />
                {
                    this.state.document &&
                    <PrintOverviewModal
                        mode={this.props.mode}
                        onClose={() => {
                            this.setState({document: null});
                        }}
                        document={this.state.document.document}
                        filename={this.state.document.filename} />
                }
            </div>
        )
    }

    getFilter() {
        return [
            { value: 0, label: LANG_PATH + "filters>today" },
            { value: 1, label: LANG_PATH + "filters>tomorrow" },
            { value: 2, label: LANG_PATH + "filters>this_week" },
            { value: 3, label: LANG_PATH + "filters>next_four_weeks" }
        ];
    }

    createTODOTableData() {
        let data = { check_ins: [], check_outs: [] };
        const { checkIns, checkOuts } = getTodoRelevantBookings(this.state.result_list, this.state.filter);

        for (const booking of checkIns) {
            let advert = this.state.adverts[booking.advert_id];
            let housingType = this.getHousingType(booking, advert);
            let checked = booking.check_in !== undefined && booking.check_in !== null && booking.check_in;
            let access = '';
            for (const accessObject of advert.access) {
                if (access.length > 0) {
                    access += ', ';
                }
                if (accessObject.type !== AccessType.miscellaneous) {
                    access +=
                        getLanguageEntry("jackboard>adverts>advert_creator>access>access_types>" + accessObject.type)
                }
                else if (accessObject.code !== undefined) {
                    access += accessObject.code;
                }
            }
            let checkIn = {
                booker: booking.booker.last_name + ', ' + booking.booker.first_name,
                housing_id: housingType + ', ' + booking.address.line_1 + ', ' + booking.address.city,
                check_in_time: getLanguageEntry("general>as_of").toLowerCase() + " " +
                                (!advert.check_in_time ? "06:00" : advert.check_in_time),
                checked: checked,
                booking_id: booking.id,
                access: access,
                date: getFormattedDate(new Date(booking.start), true, false, false)
            };
            data.check_ins.push(checkIn);
        }

        for (const booking of checkOuts) {
            let advert = this.state.adverts[booking.advert_id];
            let housingType = this.getHousingType(booking, advert);
            let checkOut = {
                booker: booking.booker.last_name + ', ' + booking.booker.first_name,
                housing_id: housingType + ', ' + booking.address.line_1 + ', ' + booking.address.city,
                check_out_time: getLanguageEntry("general>to").toLowerCase() + " " +
                    (!advert.check_out_time ? "18:00" : advert.check_out_time),
                checked: booking.check_out,
                cleaning_checked: booking.cleaning,
                booking_id: booking.id,
                date: getFormattedDate(new Date(booking.end), true, false, false)
            };
            data.check_outs.push(checkOut);
        }

        return data;
    }

    getHousingType(booking, advert) {
        let housingType = booking.room_id ?
            getLanguageEntry("advert_attributes>advert_types>apartment") :
            determineAdvertNameLabel(booking);
        if (advert.floor_no !== undefined) {
            housingType += ', ' + advert.floor_no;
        }
        if (advert.door_no !== undefined) {
            housingType += ', ' + advert.door_no;
        }
        return housingType;
    }

    callAlertForChange(update, bookingID) {
        if (this.alert_modal.current) {
            this.alert_modal.current.show("general>attention",
                <div className="description-container">
                    {getLanguageEntry(LANG_PATH + "alert_modal_content>todo_" + (Object.values(update)[0] ? "check" : "uncheck"))}
                </div>,
                () => {
                    this.alert_modal.current.setLoading(true, "general>saving");
                    let that = this;
                    updateBooking(bookingID, update, () => {
                       that.alert_modal.current.hide();
                       let cache = TODO_CACHE.getCache();
                       if (cache) {
                           that.setState({result_list: cache})
                       }
                    });
                }, ["fal", "check"], getLanguageEntry("general>yes") + "!");
        }
    }

    printToDos(data) {
        this.setState({
            document: {
                document: <ToDoList data={data} />,
                filename: `todos-${this.getFilter().filter(f => f.value === this.state.filter)[0].label.toLowerCase()}`
            }
        });
    }

}

ToDos.propTypes = {
    mode: PropTypes.oneOf(Object.values(Mode))
}
export default ToDos;