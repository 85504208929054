import React from "react";
import {
    createCountryDropdown,
    getLanguageEntry,
    SUPPORTED_COUNTRIES
} from "../../../../../utils/Helper";
import Autocomplete from "../../../../../components/input/Autocomplete";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import AdvertEditorBlock from "../../../../../components/advert/AdvertEditorBlock";
import {AdvertEditorTab} from "../../../../../utils/Types.ts";

const LANG_PATH = "jackboard>adverts>advert_creator>";

class LocationTab extends React.Component {

    constructor(props) {
        super(props);
        this.country_selector = React.createRef();
        this.line_1 = React.createRef();
        this.line_2 = React.createRef();
        this.city = React.createRef();
        this.postal_code = React.createRef();
        this.state = {
            location: this.props.advert && this.props.advert.address ?
                this.props.advert.address.location : null
        }
        this.address = React.createRef();
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((!prevProps.advert || !prevProps.advert.id) && this.props.advert && this.props.advert.id &&
            this.props.advert.address && this.props.advert.address.location) {
            this.setState({location: this.props.advert.address.location})
        }
    }

    extractData() {
        let address = Object.fromEntries(new FormData(this.address.current).entries());
        if (this.state.location) {
            address.location = {...this.state.location}
        }
        return {address};
    }

    render() {
        return(
            <div className={"editor-tab location" + (this.props.disabled ? " disabled" : "")}>
                <AdvertEditorBlock title={"general>address"} description={LANG_PATH + "address>box_description"}
                                   info={LANG_PATH + "address>infobox_description"}
                                   invalid={Object.keys(this.props.requirements).length > 0}>
                    <form ref={this.address}>
                        <div className="form-group v">
                            <label htmlFor="line_1">
                                {getLanguageEntry("general>street") + ", " +
                                    getLanguageEntry("general>street_no")}
                            </label>
                            <Autocomplete initialValue={this.props.advert && this.props.advert.address ? this.props.advert.address.line_1 : ""}
                                          name="line_1" componentRestrictions={{country: SUPPORTED_COUNTRIES}}
                                          onKeyUp={(e) => {this.onAddressChange(e)}}
                                          className={this.props.requirements.line_1 ? "invalid" : ""}
                                          onPlaceSelected={(address_line, place) => { this.onPlaceSelected(address_line, place); }}/>
                        </div>
                        <div className="form-group v">
                            <label htmlFor="postal_code">
                                {getLanguageEntry("general>postal_code")}
                            </label>
                            <input type="text" name="postal_code"
                                   defaultValue={this.props.advert && this.props.advert.address ? this.props.advert.address.postal_code : ""}
                                   onKeyUp={(e) => {this.onAddressChange(e)}}
                                   className={this.props.requirements.postal_code ? "invalid" : ""}
                                   ref={this.postal_code} />
                        </div>
                        <div className="form-group v">
                            <label htmlFor="city">
                                {getLanguageEntry("general>city") + "/" + getLanguageEntry("general>location")}
                            </label>
                            <input type="text" name="city"
                                   defaultValue={this.props.advert && this.props.advert.address ? this.props.advert.address.city : ""}
                                   onKeyUp={(e) => {this.onAddressChange(e)}}
                                   className={this.props.requirements.city ? "invalid" : ""} ref={this.city}/>
                        </div>
                        <div className="form-group v">
                            <label htmlFor="country">
                                {getLanguageEntry("general>country")}
                            </label>
                            {createCountryDropdown("country", this.country_selector, (e) => {this.onAddressChange(e)},
                                false, this.props.advert && this.props.advert.address ? this.props.advert.address.country : null, false,
                                SUPPORTED_COUNTRIES, this.props.requirements.country)}
                        </div>
                        <div className="form-group v">
                            <label htmlFor="line_2">
                                {getLanguageEntry("general>address_addition")}
                            </label>
                            <input type="text" name="line_2"
                                   defaultValue={this.props.advert && this.props.advert.address ? this.props.advert.address.line_2 : ""}
                                   ref={this.line_2} />
                        </div>
                    </form>
                    <div className="description-container">
                        <FontAwesomeIcon icon={["fal", this.state.location ? "check-circle" : "exclamation-triangle"]}
                                         style={{color: this.state.location ? "forestgreen" : "orange"}}/>
                        {getLanguageEntry(LANG_PATH + "address>google_location_" +
                            (this.state.location ? "stored" : "missing") )}
                    </div>
                    {
                        !this.state.location &&
                        <div className="description-container">
                            <FontAwesomeIcon icon={["fal", "info-circle"]}/>
                            {getLanguageEntry(LANG_PATH + "address>google_location_description")}
                        </div>
                    }
                </AdvertEditorBlock>
            </div>
        )
    }

    onPlaceSelected(address_line, place) {
        this.setState({location: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            }}, () => {
            this.actualizeRequirements();
            let addressComponents = place.address_components;
            this.line_1 = address_line;
            //region postal code
            let postalCodeElem = addressComponents.filter(ac => ac.types[0] === 'postal_code')[0];
            if (postalCodeElem !== undefined) {
                this.postal_code.current.value = postalCodeElem.long_name;
            }
            //endregion
            //region city
            let locationElem = addressComponents.filter(ac => ac.types[0] === 'locality')[0];
            if (locationElem !== undefined) {
                this.city.current.value = locationElem.long_name;
            }
            //endregion
            //region country
            let countryElem = addressComponents.filter(ac => ac.types[0] === 'country')[0];
            if (countryElem !== undefined) {
                this.country_selector.current.value = countryElem.short_name;
            }
            //endregion
        });
    }
    
    onAddressChange(e) {
        e.preventDefault();
        if (this.state.location) {
            this.setState({location: null}, () => {this.actualizeRequirements()});
        }
    }

    actualizeRequirements() {
        if (this.props.checkRequirements) {
            this.props.updateRequirements?.(this.extractData());
        }
    }

    getTabTpe() {
        return AdvertEditorTab.location;
    }
}
LocationTab.propTypes = {
    advert: PropTypes.any.isRequired,
    userData: PropTypes.any.isRequired,
    updateRequirements: PropTypes.func.isRequired,
    checkRequirements: PropTypes.bool.isRequired,
    requirements: PropTypes.any.isRequired
}
export default LocationTab;