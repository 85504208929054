import React from "react";
import PropTypes from "prop-types";
import FetchButton from "../buttons/FetchButton";
import {getFormattedDate, getLanguageEntry, getTimeUnitInMS, priceToString} from "../../utils/Helper";
import {getBookingTransactionDetails} from "../../utils/RESTInterface";
import Dropdown from "../input/Dropdown";
import {TransactionStatusType} from "../../utils/Types.ts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _uniqueId from "lodash/uniqueId";


class BookingTransactionDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selected_charge: 0
        }
    }

    render() {
        if (!this.props.booking) {
            return <div></div>;
        }
        return(
            <div>
                {
                    !this.props.booking.transaction_details_loaded &&
                    <FetchButton className="outline-button accent" loading={this.state.loading}
                                 loadingText={"components>booking_detail_table>loading_transaction_details"}
                                 onClick={() => {this.getTransactionDetails()}}>
                        {getLanguageEntry("components>booking_detail_table>load_transaction_details")}
                    </FetchButton>
                }
                {
                    this.props.booking.transaction_details_loaded && this.props.booking.charge_data &&
                    <div className="transaction-table-container" style={{display: "grid", gap: "10px"}}>
                        {
                            this.props.booking.charge_data.charges.length > 1 &&
                            this.createChargeSelector()
                        }
                        {
                            this.createChargeTable()
                        }
                    </div>
                }
                {
                    this.props.booking.transaction_details_loaded && !this.props.booking.charge_data &&
                    <div className="description-container">
                        TODO: error message
                    </div>
                }
            </div>
        )
    }

    getTransactionDetails() {
        if (!this.state.loading) {
            if (!this.props.booking.transaction_details_loaded) {
                let that = this;
                this.setState({loading: true});

                getBookingTransactionDetails(this.props.booking.id, (response) => {
                    that.setState({loading: false}, () => {
                        that.props.onDetailsLoaded?.();
                    });
                })
            }
        }
    }

    createChargeSelector() {
        let items = this.props.booking.charge_data.charges.map((_, i) => {
            return { 
                value: i,
                label: () => {
                    return getLanguageEntry("components>booking_detail_table>nth_charge")
                    .replace("#", "" + (i+1))
                }
            };
        });

        return <Dropdown items={items} name="charge_selector" onChange={(e, v) => {
            this.props.onChargeSelected?.(v);
            this.setState({selected_charge: v})
        }} initialValue={this.state.selected_charge}/>
    }

    createChargeTable() {
        let charge = this.props.booking.charge_data.charges[this.state.selected_charge];
        // region charge history
        let statusRows = [];
        for (let i=charge.due_dates.length - 1; i>=0; i--) {
            let transactionType = getLanguageEntry("components>booking_detail_table>transaction_types>debit");
            let status;
            let icon;
            // if this is not the last due date, this transaction failed
            if (i > 0) {
                status = TransactionStatusType.failed;
            }
            else {
                if (!charge.payment_intent_id && !charge.payment_intent_status) {
                    if (this.props.booking.cancelled) {
                        status = TransactionStatusType.cancelled;
                        icon = <FontAwesomeIcon icon={["fal", "times-circle"]}/>;
                    }
                    else {
                        status = TransactionStatusType.pending;
                        icon = <FontAwesomeIcon icon={["fal", "clock-rotate-left"]} flip="horizontal"/>;
                    }
                }
                else {
                    switch (charge.payment_intent_status) {
                        case TransactionStatusType.succeeded:
                            status = TransactionStatusType.succeeded;
                            icon = <FontAwesomeIcon icon={["fal", "check-circle"]}/>;
                            break;
                        case TransactionStatusType.processing:
                            status = TransactionStatusType.processing;
                            icon = <FontAwesomeIcon icon={["fal", "hourglass"]}/>;
                            break;
                        default:
                            status = TransactionStatusType.failed;
                            icon = <FontAwesomeIcon icon={["fal", "circle-exclamation"]}/>;
                            break;
                    }
                }
            }
            let dueDate = i === 0 && charge.payment_ts !== undefined ? charge.payment_ts : charge.due_dates[i];
            let amount = charge.amount;
            if (this.props.booking.booker === this.props.userData.id && charge.discount && charge.discount > 0) {
                amount -= charge.discount;
            }
            statusRows.push(
                <tr key={_uniqueId(charge.id + "-" + i)}>
                    <td>{transactionType}</td>
                    <td>{getFormattedDate(new Date(dueDate), false, status !== TransactionStatusType.processing, false, true, false, false)}</td>
                    <td>{priceToString(amount)}</td>
                    <td className={status}>
                        {getLanguageEntry("components>booking_detail_table>transaction_states>" + status)}
                        {icon}
                    </td>
                </tr>
            );
        }
        //endregion
        //region refund
        let refundRows = [];
        if (charge.cancelled) {
            refundRows.push(
                <tr key={_uniqueId("cancel-" + charge.id)}>
                    <td>{getLanguageEntry("components>booking_detail_table>transaction_types>cancellation")}</td>
                    <td>{getFormattedDate(new Date(this.props.booking.cancellation_ts), false, true, false, true, false, false)}</td>
                    <td></td>
                    <td className="succeeded">
                        {getLanguageEntry("components>booking_detail_table>transaction_states>succeeded")}
                        <FontAwesomeIcon icon={["fal", "check-circle"]} />
                    </td>
                </tr>
            );
            let transactionType = getLanguageEntry("components>booking_detail_table>transaction_types>refund");
            let status;
            let icon;
            switch (charge.refund_status) {
                case TransactionStatusType.succeeded:
                    status = TransactionStatusType.succeeded;
                    icon = <FontAwesomeIcon icon={["fal", "check-circle"]} />
                    break;
                case TransactionStatusType.processing:
                    status = TransactionStatusType.processing;
                    icon = <FontAwesomeIcon icon={["fal", "hourglass"]} />
                    break;
                default:
                    status = TransactionStatusType.failed;
                    icon = <FontAwesomeIcon icon={["fal", "circle-exclamation"]} />
                    break;
            }
            refundRows.push(
                <tr key={_uniqueId("refund-" + charge.id)}>
                    <td>{transactionType}</td>
                    <td>{getFormattedDate(new Date(charge.refund_ts), false, true, false, true, false, false)}</td>
                    <td>{priceToString(charge.refund_amount)}</td>
                    <td className={status}>
                        {getLanguageEntry("components>booking_detail_table>transaction_states>" + status)}
                        {icon}
                    </td>
                </tr>
            );
        }
        //endregion
        //region payout history
        let payoutRows = [];
        if (this.props.booking.owner === this.props.userData.id) {
            let transactionType = getLanguageEntry("components>booking_detail_table>transaction_types>payout");
            let status;
            let icon;
            if (!charge.payout_id) {
                if (this.props.booking.cancelled) {
                    status = TransactionStatusType.cancelled;
                    icon = <FontAwesomeIcon icon={["fal", "times-circle"]}/>;
                }
                else {
                    status = TransactionStatusType.pending;
                    icon = <FontAwesomeIcon icon={["fal", "clock-rotate-left"]} flip="horizontal"/>;
                }
            }
            else {
                switch (charge.payout_status) {
                    case TransactionStatusType.paid:
                        status = TransactionStatusType.succeeded;
                        icon = <FontAwesomeIcon icon={["fal", "check-circle"]} />
                        break;
                    case TransactionStatusType.processing:
                        status = TransactionStatusType.processing;
                        icon = <FontAwesomeIcon icon={["fal", "hourglass"]} />
                        break;
                    default:
                        status = TransactionStatusType.failed;
                        icon = <FontAwesomeIcon icon={["fal", "circle-exclamation"]} />
                        break;
                }
            }
            let expectedPayoutDate = Math.max(this.props.booking.start, charge.due_dates[0]);
            let payoutTime = charge.payout_ts !== undefined ?
                getFormattedDate(new Date(charge.payout_ts), false, true, false, true, false, false) :
                (this.props.booking.cancelled ? "-" :
                    getLanguageEntry("components>booking_detail_table>expected_payout") +
                    getFormattedDate(new Date(expectedPayoutDate + getTimeUnitInMS(2)), false, false, false, true, false, false));
            let payoutAmount = charge.payout_amount === undefined ?
                getLanguageEntry("components>booking_detail_table>calculated_on_payout") :
                priceToString(charge.payout_amount);
            payoutRows.push(
                <tr key={_uniqueId("payout-" + charge.id)}>
                    <td>{transactionType}</td>
                    <td>{payoutTime}</td>
                    <td>{payoutAmount}</td>
                    <td className={status}>
                        {getLanguageEntry("components>booking_detail_table>transaction_states>" + status)}
                        {icon}
                    </td>
                </tr>
            );
        }
        //endregion
        return <table className="transaction-table">
            <tbody>
                <tr>
                    <th>{getLanguageEntry("components>booking_detail_table>transaction_table_header>transaction_type")}</th>
                    <th>{getLanguageEntry("components>booking_detail_table>transaction_table_header>transaction_due")}</th>
                    <th>{getLanguageEntry("components>booking_detail_table>transaction_table_header>transaction_sum")}</th>
                    <th>{getLanguageEntry("components>booking_detail_table>transaction_table_header>transaction_status")}</th>
                </tr>
                {
                    statusRows.map((row, _) => row)
                }
                {
                    refundRows.map((row, _) => row)
                }
                {
                    payoutRows.map((row, _) => row)
                }
            </tbody>
        </table>
    }

    getSelectedCharge() {
        return this.props.booking.charge_data.charges[this.state.selected_charge];
    }

}

BookingTransactionDetails.propTypes = {
    booking: PropTypes.object,
    userData: PropTypes.object.isRequired,
    onChargeSelected: PropTypes.func,
    onDetailsLoaded: PropTypes.func.isRequired
}
export default BookingTransactionDetails;