import React from 'react';

import {getLanguageEntry} from "../../utils/Helper";

class PartnersContentPage extends React.Component {

    render() {
        return <div id="partners" className="content-page">
            <div className="container">
                <h1>{getLanguageEntry("content_pages>partners>title")}</h1>
                <div className="partner-container">
                    <h2>{getLanguageEntry("content_pages>partners>design")}:</h2>
                    <a href="https://cic.at/" target="_blank" rel="noreferrer nofollow">
                        <img src="https://www.cic.at/resources/themes/macic/img/logo_CIC_black.svg" alt="CIC-Logo"/>
                    </a>
                    <div>Corporate Image Consulting GmbH</div>
                </div>
                <div className="partner-container">
                    <h2>{getLanguageEntry("content_pages>partners>development")}:</h2>
                    <a href="https://www.cyberforum.de" target="_blank" rel="noreferrer nofollow">
                        <img src="https://www.cyberlab-karlsruhe.de/typo3conf/ext/wr_cyberlab/Resources/Public/static/media/icons/Logos/CyberLab%20farbig_Desktop.svg" alt="CyberForum Logo"/>
                    </a>
                    <div>Cyberforum e.V.</div>
                </div>
            </div>
        </div>
    }
}

export default PartnersContentPage;