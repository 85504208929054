import React from "react";
import PropTypes from "prop-types";
import GanttHousingRow from "./GanttHousingRow";
import {
    determineAdvertNameLabel,
    getLanguageEntry
} from "../../utils/Helper";

class GanttAdvertTable extends React.Component {

    render() {
        return(
            <table>
                <tbody>
                    <GanttHousingRow {...this.props} title={this.props.advert.title} rowType={"advert"}
                                     description={determineAdvertNameLabel(this.props.advert)}
                                     onBookingDetail={this.props.onBookingDetail} onAlert={this.props.onAlert}
                                     onCurrentPrice={this.props.onCurrentPrice}
                                     onSync={!this.props.advert.bookable ? undefined : this.props.onSync}
                                     onBlock={!this.props.advert.bookable ? undefined : this.props.onBlock}/>
                    {
                        this.props.rooms &&
                        Object.values(this.props.rooms).map((r, _) => this.createRoomRow(r))
                    }
                    {
                        this.props.roomGroups &&
                        this.createRoomGroupRows(this.props.roomGroups).map((rg, _) => rg)
                    }
                </tbody>
            </table>
        )
    }

    createRoomRow(roomObject) {
        let title = roomObject.room.name ? roomObject.room.name : getLanguageEntry("advert_attributes>room_types>" + roomObject.type);
        return <GanttHousingRow {...this.props} room={roomObject.room}
                                onSync={this.props.onSync} onBlock={this.props.onBlock}
                                onBookingDetail={this.props.onBookingDetail} onCurrentPrice={this.props.onCurrentPrice}
                                title={title} rowType={"room"} key={roomObject.room.id}/>
    }

    createRoomGroupRows(roomGroupObject) {
        let rows = [];
        for (const groupID of Object.keys(roomGroupObject)) {
            let roomGroup = roomGroupObject[groupID];
            let show = this.props.roomGroupStateMap[groupID];
            let title = roomGroup.title + " (" + roomGroup.rooms.length + ")"
            rows.push(<GanttHousingRow {...this.props} room={roomGroup.roomGroup} title={title} rowType={"group"}
                                       onBookingDetail={this.props.onBookingDetail} onBlock={this.props.onBlock}
                                       onCurrentPrice={this.props.onCurrentPrice}
                                       key={groupID} collapsed={!show}
                                       onCollapse={this.props.ganttType === "booking" ? () => {
                                           this.props.onSetRoomGroupState?.(this.props.advert.id, groupID, !show);
                                       } : undefined} />)
            if (this.props.ganttType === "booking" && show) {
                for (const room of roomGroup.rooms) {
                    rows.push(<GanttHousingRow {...this.props} room={room} key={room.id} title={roomGroup.title}
                                               onBookingDetail={this.props.onBookingDetail} onBlock={this.props.onBlock}
                                               rowType={"room"} onSync={this.props.onSync}/>);
                }
            }
        }
        return rows;
    }

}

GanttAdvertTable.propTypes = {
    // required fields
    advert: PropTypes.any.isRequired,
    actualMonth: PropTypes.instanceOf(Date).isRequired,
    ganttType: PropTypes.oneOf(["booking", "current-price"]).isRequired,
    userData: PropTypes.any.isRequired,
    // optional fields
    bookings: PropTypes.array,
    rooms: PropTypes.any,
    roomGroups: PropTypes.any,
    roomGroupStateMap: PropTypes.any,
    //delegate methods
    onSync: PropTypes.func,
    onBlock: PropTypes.func,
    onCurrentPrice: PropTypes.func,
    onBookingDetail: PropTypes.func,
    onSetRoomGroupState: PropTypes.func,
    onAlert: PropTypes.func
}
export default GanttAdvertTable;