class CacheList {

    private cache:Array<any>;
    private timestamp:number;
    private readonly lifetime;

    constructor(lifetime) {
        this.cache = null;
        this.lifetime = lifetime;
    }
    
    public getCache():any {
        if (this.cache === null || Date.now() >= this.timestamp + this.lifetime) {
            return null;
        }
        return this.cache;
    }

    public setCache(cache):void {
        this.cache = cache;
        this.timestamp = Date.now();
    }

    public remove(dataID):void {
        if (this.cache) {
            this.cache = this.cache.filter(d => d.id !== dataID);
        }
    }

    public reset():void {
        this.timestamp = 0;
        this.cache = null;
    }

    public isValid():boolean {
        return Date.now() < this.timestamp + this.lifetime;
    }

}
export default CacheList;