import React from "react";
import PropTypes from "prop-types";
import {getLanguageEntry, getNightsBetweenDates} from "../../../utils/Helper";
import {Link} from "react-router-dom";
import _uniqueId from "lodash/uniqueId";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BookingOverviewType, Mode, ReportReason} from "../../../utils/Types.ts";
import {Navigate, Route, Routes} from "react-router";
import ProtectedRoutes from "../../../utils/ProtectedRoutes";
import BookingBrowser from "../tools/bookings/BookingBrowser";
import {BOOKING_CACHE} from "../../../utils/CacheHandler.ts";
import MessageModal from "../../../components/modals/MessageModal";
import {requestAdvertBookings, requestMyBookings} from "../../../utils/RESTInterface";
import Loading from "../../../components/Loading";
import BookingDetailModal from "../../../components/modals/BookingDetailModal";
import GuestInvoiceCreator from "../../../components/modals/GuestInvoiceCreator";
import CalendarSyncModal from "../../../components/modals/CalendarSyncModal";
import BookingFreePeriodModal from "../../../components/modals/BookingFreePeriodModal";
import AlertModal from "../../../components/modals/AlertModal";
import CancellationModal from "../../../components/modals/CancellationModal";
import ReportModal from "../../../components/modals/ReportModal";
import PaymentMethodChangeModal from "../../../components/modals/PaymentMethodChangeModal";
import GanttChart from "../../../components/gantt/GanttChart";
import PrintOverviewModal from "../../../components/modals/PrintOverviewModal";

export const STANDARD_BLOCK_COLOR = {
    value: "cMark_standard",
    label: "Standard",
    color: "#cc3333"
}

class Bookings extends React.Component {

    menus = [
        {
            mainPath: "/desktop/bookings",
            subPath: "/desktop/bookings/gantt",
            lang: "jackboard>bookings>submenus>gantt",
            icon: <FontAwesomeIcon icon={["fal", "chart-gantt"]} />,
            mode: Mode.landlord
        },
        {
            mainPath: "/desktop/bookings",
            subPath: "/desktop/bookings/active",
            lang: "jackboard>bookings>submenus>active_bookings",
            icon: <FontAwesomeIcon icon={["fal", "circle-check"]} />
        },
        {
            mainPath: "/desktop/bookings",
            subPath: "/desktop/bookings/upcoming",
            lang: "jackboard>bookings>submenus>upcoming_bookings",
            icon: <FontAwesomeIcon icon={["fal", "clock-rotate-left"]} flip="horizontal" />
        },
        {
            mainPath: "/desktop/bookings",
            subPath: "/desktop/bookings/past",
            lang: "jackboard>bookings>submenus>past_bookings",
            icon: <FontAwesomeIcon icon={["fal", "clock-rotate-left"]} />
        },
        {
            mainPath: "/desktop/bookings",
            subPath: "/desktop/bookings/cancelled",
            lang: "jackboard>bookings>submenus>cancelled_bookings",
            icon: <FontAwesomeIcon icon={["fal", "times-circle"]} />
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            bookings: [],
            adverts: [],
            document: null,
        }
        this.message_modal = React.createRef();
        this.alert_modal = React.createRef();
        this.report_modal = React.createRef();
        this.detail_modal = React.createRef();
        this.invoice_creator = React.createRef();
        this.calendar_sync_modal = React.createRef();
        this.bfp_modal = React.createRef();
        this.cancellation_modal = React.createRef();
        this.payment_change_modal = React.createRef();
        this.print_overview = React.createRef();
    }

    componentDidMount() {
        this.updateBookings();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.mode !== prevProps.mode) {
            this.updateBookings();
        }
    }

    render() {
        let url = window.location.pathname;
        return (
            <div id="my-bookings">
                {
                    this.state.loading &&
                    <Loading imageWidth="250px"/>
                }
                {
                    !this.state.loading &&
                    <h1 className="desktop-tool-title">
                        {getLanguageEntry("jackboard>bookings>title")}
                    </h1>
                }
                {
                    !this.state.loading &&
                    <div className="desktop-submenu-grid">
                        {
                            this.menus.filter(m => !m.mode || m.mode === this.props.mode).map((item, i) => {
                                return <Link to={item.subPath} key={_uniqueId(item.subPath)}
                                             className={"desktop-submenu-tile selectable" + (url.includes(item.subPath) ? " selected" : "")}>
                                    <div className="tile-icon-container">{item.icon}</div>
                                    <div className="tile-text-container">{getLanguageEntry(item.lang)}</div>
                                </Link>
                            })
                        }
                    </div>
                }
                {
                    !this.state.loading &&
                    <Routes>
                        <Route exact path={"/overview"} element={<div />} />
                        <Route element={<ProtectedRoutes redirection="desktop/bookings/overview"
                                                         authObject={{loading: false, isAuthorized: this.props.mode === Mode.landlord}}/>}>
                            <Route exact path={"/gantt"} element={
                                <GanttChart onBookingDetail={(b) => this.callDetailModal(b)}
                                            onSync={(adv, rID, housing) => { this.callCalendarSyncModal(adv, rID, housing); }}
                                            onBlock={(...args) => { this.callBookingBlockModal(...args); }}
                                            onAlert={(...args) => this.callAlertModal(...args)}
                                            bookings={this.state.bookings} adverts={this.state.adverts}
                                            userData={this.props.userData} ganttType={"booking"} />} />
                        </Route>
                        <Route exact path={"/active"} element={<BookingBrowser type={BookingOverviewType.active}
                                                                               onSendMessage={(receiver) => {this.callMessageModal(receiver)}}
                                                                               onChangePayment={(b) => this.callPaymentChangeModal(b)}
                                                                               onCancelBooking={(b) => this.callCancellationModal(b)}
                                                                               onBookingDetail={(b, c) => this.callDetailModal(b, c)}
                                                                               bookings={this.state.bookings}
                                                                               adverts={this.state.adverts}
                                                                               {...this.props}/>}/>
                        <Route exact path={"/upcoming"} key={_uniqueId("upcoming")}
                               element={<BookingBrowser type={BookingOverviewType.upcoming}
                                                        onSendMessage={(receiver) => {this.callMessageModal(receiver)}}
                                                        onChangePayment={this.props.mode === Mode.renter ? (b) => this.callPaymentChangeModal(b) : undefined}
                                                        onCancelBooking={(b) => this.callCancellationModal(b)}
                                                        onBookingDetail={(b, c) => this.callDetailModal(b, c)}
                                                        bookings={this.state.bookings}
                                                        adverts={this.state.adverts}
                                                        {...this.props}/>} />
                        {
                            [
                                BookingOverviewType.past,
                                BookingOverviewType.cancelled
                            ].map((t, i) => {
                                return <Route exact path={"/" + t} key={_uniqueId(t)}
                                              element={<BookingBrowser type={t}
                                                                       onSendMessage={(receiver) => {this.callMessageModal(receiver)}}
                                                                       onCancelBooking={(b) => this.callCancellationModal(b)}
                                                                       onBookingDetail={(b, c) => this.callDetailModal(b, c)}
                                                                       bookings={this.state.bookings}
                                                                       adverts={this.state.adverts}
                                                                       {...this.props}/>} />
                            })
                        }
                        <Route exact path={"/*"} element={<Navigate to="/desktop/bookings/overview" />} />
                    </Routes>
                }
                <MessageModal ref={this.message_modal} userData={this.props.userData}
                              onUpdateUserData={this.props.onUpdateUserData}/>
                <AlertModal ref={this.alert_modal} />
                <ReportModal ref={this.report_modal} userData={this.props.userData} />
                <CancellationModal ref={this.cancellation_modal} mode={this.props.mode} userData={this.props.userData}
                                   onContactSupport={() => {this.callReportModal(ReportReason.bug_report)}}
                                   onBookingUpdated={(booking) => {
                                       let bookings = this.state.bookings.slice(0);
                                       bookings.splice(bookings.indexOf(b => b.id === booking.id), 1, booking);
                                       this.setState({bookings: bookings});
                                   }}/>
                <PaymentMethodChangeModal ref={this.payment_change_modal} userData={this.props.userData}
                                          onContactSupport={() => {this.callReportModal(ReportReason.bug_report)}}
                                          onBookingUpdated={(booking) => {
                                              let bookings = this.state.bookings.slice(0);
                                              bookings.splice(bookings.indexOf(b => b.id === booking.id), 1, booking);
                                              this.setState({bookings: bookings});
                                          }}/>
                {
                    this.props.mode === Mode.landlord &&
                    <BookingDetailModal ref={this.detail_modal} userData={this.props.userData} mode={this.props.mode}
                                        onSendMessage={(receiver) => {this.callMessageModal(receiver)}}
                                        onCreateInvoice={(booking, charge) => this.callInvoiceCreator(booking, charge)}
                                        onViewInvoice={(doc, filename) => this.callPrintOverview(doc, filename)}/>
                }
                {
                    this.props.mode === Mode.landlord &&
                    <GuestInvoiceCreator ref={this.invoice_creator} userData={this.props.userData}
                                         onUpdateUserData={this.props.onUpdateUserData}
                                         onPreviewInvoice={(doc) => {this.callPrintOverview(doc)}} />
                }
                {
                    this.props.mode === Mode.landlord &&
                    <CalendarSyncModal ref={this.calendar_sync_modal} onAdvertUpdate={(a) => {this.onAdvertUpdate(a)}}/>
                }
                {
                    this.props.mode === Mode.landlord &&
                    <BookingFreePeriodModal ref={this.bfp_modal} userData={this.props.userData}
                                            onAdvertUpdate={(a) => {this.onAdvertUpdate(a)}}
                                            onUpdateUserSession={this.props.onUpdateUserSession}/>
                }
                {
                    this.state.document &&
                    <PrintOverviewModal
                        mode={this.props.mode}
                        onClose={() => {
                         this.setState({document: null});
                        }}
                        document={this.state.document.document}
                        filename={this.state.document.filename}
                        onlyPreview={!this.state.document.filename} />
                }
            </div>
        )
    }

    updateBookings() {
        if (this.props.mode === Mode.renter) {
            let bookingIDs = this.props.userData.bookings;
            if (bookingIDs) {
                let cache = BOOKING_CACHE.getCacheObjects(bookingIDs);
                if (cache.length === bookingIDs.length) {
                    this.setState({bookings: cache});
                }
                else {
                    let that = this;
                    this.setState({loading: true});
                    requestMyBookings((response) => {
                        that.setState({
                            loading: false,
                            bookings: response.booking_list ? response.booking_list : []
                        });
                    })
                }
            }
            else {
                this.setState({bookings: []});
            }
        }
        else {
            let that = this;
            this.setState({loading: true});
            requestAdvertBookings((response) => {
                that.setState({
                    loading: false,
                    adverts: response.adverts ? response.adverts : [],
                    bookings: response.booking_list ? response.booking_list : [],
                    selected_adverts: response.adverts ? response.adverts.map((a, _) => a.id) : [],
                });
            })
        }
    }

    callMessageModal(receiver) {
        if (this.message_modal.current) {
            this.message_modal.current.show(receiver);
        }
    }

    callAlertModal(...args) {
        if (this.alert_modal.current) {
            this.alert_modal.current.show(...args);
        }
    }

    callReportModal(reason) {
        if (this.report_modal.current) {
            this.report_modal.current.show(null, reason);
        }
    }

    callCancellationModal(booking) {
        if (booking.discount || booking.booker !== this.props.userData.id ||
            getNightsBetweenDates(booking.end, booking.start) > 29) {
            return;
        }
        if (this.cancellation_modal.current) {
            this.cancellation_modal.current.show(booking);
        }
    }

    callPaymentChangeModal(booking) {
        if (this.payment_change_modal.current) {
            this.payment_change_modal.current.show(booking);
        }
    }

    callDetailModal(bookings, onDetailsLoaded) {
        if (!Array.isArray(bookings)) {
            bookings = [bookings]
        }
        if (this.detail_modal.current) {
            this.detail_modal.current.show(bookings, onDetailsLoaded);
        }
    }

    callInvoiceCreator(booking, charge) {
        if (this.invoice_creator.current) {
            this.invoice_creator.current.show(booking, charge);
        }
    }

    callCalendarSyncModal(advert, roomID, housing) {
        if (this.calendar_sync_modal.current) {
            this.calendar_sync_modal.current.show(advert, roomID, housing);
        }
    }

    callBookingBlockModal(advert, roomID, block, date) {
        if (this.bfp_modal.current) {
            this.bfp_modal.current.show(advert, roomID, block, date);
        }
    }

    callPrintOverview(document, filename) {
        this.setState({
            document: {
                document,
                filename,
            }
        });
    }

    onAdvertUpdate(advert) {
        let index = -1;
        for (let a=0; a<this.state.adverts.length; a++) {
            if (this.state.adverts[a].id === advert.id) {
                index = a;
                break;
            }
        }
        if (index >= 0) {
            let adverts = this.state.adverts.slice(0);
            adverts.splice(index, 1, advert);
            this.setState({adverts: adverts});
        }
    }

}
Bookings.propTypes = {
    mode: PropTypes.oneOf(Object.values(Mode)).isRequired,
    userData: PropTypes.object.isRequired,
    onUpdateUserSession: PropTypes.func.isRequired,
    onUpdateUserData: PropTypes.func.isRequired
}
export default Bookings;