import React from 'react';

import {B2B} from "../../../resources/contents/ContentPageContent";
import {getLanguageEntry} from "../../utils/Helper";
import ReportModal from "../../components/modals/ReportModal";
import PropTypes from "prop-types";

class B2BContentPage extends React.Component {
    constructor(props) {
        super(props);
        this.report_modal = React.createRef();
    }

    render() {
        return(
            <div id="content-b2b" className="content-page">
                <div className="container">
                    <h1 className="content-title">{getLanguageEntry("content_pages>b2b>title")}</h1>
                    <div>
                        {
                            getLanguageEntry("content_pages>b2b>content").map((item,i)=>{
                                return  <p id={"b2b-content-description-paragraph" + i}>{item}</p>
                            })
                        }
                        <div className="image-pull-in">
                            <div className="image-pull-in-row">
                                <img className="big"
                                     src={B2B.features[0].url} alt={B2B.features[0].alt}/>
                                <img className="small"
                                     src={B2B.features[1].url} alt={B2B.features[1].alt}/>
                            </div>
                            <div className="image-pull-in-row">
                                <img className="small"
                                     src={B2B.features[2].url} alt={B2B.features[2].alt}/>
                                <img className="big"
                                     src={B2B.features[3].url} alt={B2B.features[3].alt}/>
                            </div>
                        </div>
                        {
                            !this.props.userData &&
                            <button className="accent-icon-button" id="request-b2b-offer"
                                    onClick={()=>{this.props.onRegister?.()}}>
                                <span>{getLanguageEntry("content_pages>b2b>register_as_b2b_button")}</span>
                            </button>
                        }
                    </div>
                </div>
                <ReportModal ref={this.report_modal} userData={this.props.userData}/>
            </div>
        )
    }
}

B2BContentPage.propTypes = {
    userData: PropTypes.object,
    onRegister: PropTypes.func.isRequired
}

export default B2BContentPage;