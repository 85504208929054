import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {initStripe} from "./js/utils/StripeHelper.ts";
import ScrollReset from "./js/utils/ScrollReset";

export const SITE_INIT = Date.now();
const root = ReactDOM.createRoot(document.getElementById('root'));
initStripe();

root.render(
  <>
      <BrowserRouter>
          <ScrollReset />
          <App />
      </BrowserRouter>
  </>
);
