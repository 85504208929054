import React from "react";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import {
    determineActiveCancellationCondition, getFormattedDate,
    getLanguageEntry, MAX_MESSAGE_LENGTH,
    priceToString
} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";
import FetchButton from "../buttons/FetchButton";
import {Mode} from "../../utils/Types.ts";
import Dropdown from "../input/Dropdown";
import TextArea from "../input/TextArea";
import {cancelBooking} from "../../utils/RESTInterface";

class CancellationModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            booking: null,
            cancellation_result: null,
            cancelling: false,
            reason: null
        }
        this.form = React.createRef();
    }

    render() {
        return (
            <Modal className={"cancellation-modal " + this.props.mode} show={this.state.show} size="lg" backdrop="static"
                   keyboard={false} onHide={() => { this.hide() }} dialogClassName="max-content" centered>
                <Modal.Header>
                    <h4>{getLanguageEntry("processes>booking_cancellation>title")}</h4>
                    <button onClick={() => { this.hide() }} disabled={this.state.cancelling}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>

                <Modal.Body>
                    {
                        this.state.booking && !this.state.cancellation_result &&
                        <div style={{display: "grid", gap: "20px"}}>
                            {this.getCancellationWarning()}
                            <form ref={this.form} onSubmit={(e) => {this.cancelBooking(e)}}
                                  style={{display: "grid", gap: "20px"}}>
                                <Dropdown name="reason" items={this.getReasonItems()} required={true}
                                          onChange={(e, v) => { this.setState({reason: v})}}/>
                                <TextArea name="comment" required={this.state.reason === "miscellaneous"}
                                          label={"processes>booking_cancellation>reason_comment"}
                                          maxLength={MAX_MESSAGE_LENGTH} />
                                <FetchButton className="outline-button accent" type="submit" loading={this.state.cancelling}
                                             loadingText={"processes>booking_cancellation>cancelling"}
                                             style={{width: "max-content", justifySelf: "end"}}>
                                    {
                                        getLanguageEntry("processes>booking_cancellation>title")
                                    }
                                </FetchButton>
                            </form>
                        </div>
                    }
                    {
                        this.state.booking && this.state.cancellation_result &&
                        <div className="process-modal-result-container">
                            <div className="process-result-image">
                                <img src={this.state.cancellation_result.success ?
                                    "https://roomjack.blob.core.windows.net/roomjack/content-images/roomjack_faultier_nachrichten_10.png" :
                                    "https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear.png"}
                                     alt={"Cancellation " +
                                         (this.state.cancellation_result.success ? "success" : "failed")} />
                            </div>
                            <div className="process-result-message-box">
                                <div className="header">
                                    {
                                        getLanguageEntry("processes>booking_cancellation>" +
                                            (this.state.cancellation_result.success ? "success_title_" : "failed_") +
                                            this.props.mode)
                                    }
                                </div>
                                <div className="description-container">
                                    {
                                        this.state.cancellation_result.success &&
                                        getLanguageEntry("processes>booking_cancellation>success_description_" + this.props.mode)
                                    }
                                    {
                                        !this.state.cancellation_result.success &&
                                        <div>
                                            <span>{getLanguageEntry("general>contact_support_for_link")}</span>
                                            <button className="link-button" onClick={() => {this.props.onContactSupport?.()}}>
                                                {getLanguageEntry("general>here")}
                                            </button>.
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        )
    }

    show(booking) {
        this.setState({
            show: true,
            booking: booking,
            cancellation_result: null,
            cancelling: false,
            reason: null
        });
    }

    hide() {
        this.setState({
            show: false,
            booking: null,
            cancellation_result: null,
            cancelling: false
        })
    }

    getCancellationWarning() {
        let start = getFormattedDate(new Date(this.state.booking.start), true, false,
            false, true, false, false);
        let end = getFormattedDate(new Date(this.state.booking.end), true, false,
            false, true, false, false);
        let cancellationWarningContent;
        if (this.state.booking.booker === this.props.userData.id) {
            let activeCondition = determineActiveCancellationCondition(this.state.booking.start,
                this.state.booking.invoice_data.cancellation_conditions);

            if (this.state.booking.end <= Date.now()) {
                return;
            }
            cancellationWarningContent = getLanguageEntry("processes>booking_cancellation>warning")
                .replace('@>', start)
                .replace('<@', end)
                .replace('#advertTitle#', this.state.booking.title);
            if (activeCondition != null) {
                if (activeCondition.percent >= 100) {
                    cancellationWarningContent += ' ' + getLanguageEntry("processes>booking_cancellation>no_refund");
                }
                else {
                    let amount = this.state.booking.charge_data.charges[0].amount;
                    let refundAmount = amount * (activeCondition.percent / 100);
                    cancellationWarningContent += ' ' + getLanguageEntry("processes>booking_cancellation>costs_incurring")
                        .replace('€', priceToString(refundAmount))
                        .replace('%', activeCondition.percent + '%');
                }
            }
            else {
                cancellationWarningContent += ' ' + getLanguageEntry("processes>booking_cancellation>refund_all");
            }
            cancellationWarningContent += getLanguageEntry("processes>booking_cancellation>reason_label");
            return <div className="description-container">{parse(cancellationWarningContent)}</div>
        }
        if (this.state.booking.owner === this.props.userData.id) {
            cancellationWarningContent = getLanguageEntry("processes>booking_cancellation>warning_landlord")
                .replace('@>', start)
                .replace('<@', end)
                .replace('#advertTitle#', this.state.booking.title)
                .replace('#guestName#', this.state.booking.booker.first_name + ' ' +
                    this.state.booking.booker.last_name);
            return <div className="description-container">{parse(cancellationWarningContent)}</div>
        }
    }

    getReasonItems() {
        let items = [];
        for (const [value, label] of Object.entries(getLanguageEntry("dynamic_page_content>cancellation_reasons>" + this.props.mode))) {
            items.push({value: value, label: label});
        }
        return items;
    }

    cancelBooking(e) {
        e.preventDefault();
        if (this.state.booking && this.form.current) {
            this.setState({ cancelling: true });
            let that = this;
            let reasonObject = {};
            new FormData(this.form.current)
                .forEach((value, key) => reasonObject[key] = value);
            cancelBooking(this.state.booking.id, reasonObject, (response) => {
                that.setState({
                    cancelling: false,
                    cancellation_result: response
                });
                if (response.booking) {
                    that.props.onBookingUpdated(response.booking);
                }
            });
        }
    }

}

CancellationModal.propTypes = {
    mode: PropTypes.oneOf(Object.values(Mode)).isRequired,
    userData: PropTypes.any.isRequired,
    onBookingUpdated: PropTypes.func.isRequired,
    onContactSupport: PropTypes.func.isRequired
}
CancellationModal.defaultProps = {}
export default CancellationModal;