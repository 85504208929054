import {Mode, Page} from "../utils/Types.ts";
import PropTypes from "prop-types";
import React from "react";
import {getPageType} from "../utils/Helper";

class ModeSwitchAnimation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            animation: false
        }
        this.timeout = null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (getPageType() !== Page.main && prevProps.mode !== this.props.mode &&
            this.props.user && prevProps.user) {
            this.setState({animation: true});
            this.timeout = setTimeout(() => {
                this.setState({animation: false});
            }, 2000);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    render() {
        return <div className="mode-switch-animation">
            {
                this.state.animation &&
                <div className={"thin-bar " + this.props.mode} />
            }
            {
                this.state.animation &&
                <div className={"medium-bar " + this.props.mode} />
            }
            {
                this.state.animation &&
                <div className={"thick-bar " + this.props.mode} />
            }
        </div>
    }

}
ModeSwitchAnimation.propTypes = {
    mode: PropTypes.oneOf(Object.values(Mode)).isRequired,
    user: PropTypes.bool.isRequired
}
export default ModeSwitchAnimation;