import React from "react";
import PropTypes from "prop-types";
import {Slider, ThemeProvider} from "@mui/material";
import {rj_theme} from "../../utils/MaterialUIColorTheme";

function CustomSlider(props) {

    const handleChange = (e, v) => {
        props.onChange?.(v);
    }

    return (
        <ThemeProvider theme={rj_theme}>
            <Slider min={props.min} step={5} max={props.max} marks={true}
                    value={props.value} onChange={(e, v) => { handleChange(e, v) }}/>
        </ThemeProvider>
    )

}
CustomSlider.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
}
export default CustomSlider;