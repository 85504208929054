import React from 'react';
import {Carousel} from "react-bootstrap";
import {
    buildTextLinkContainer, createNewsBox,
    getLanguageEntry
} from "../../utils/Helper";
import {
    LANDLORD_CAROUSEL,
    LANDLORD_FEATURES,
    LANDLORD_FUNCTIONS,
    NEWS
} from "../../../resources/contents/LandingPageContent"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import {AccountType} from "../../utils/Types.ts";
import NavigatorButton from "../../components/buttons/NavigatorButton";
import CarouselGrid from "../../components/CarouselGrid";

class LandlordLandingPage extends React.Component {

    render() {
        return(
            <div id="landlord-landing-page" className="landing-page">
                <div id="llp-carousel-header" className="landing-page-carousel-header">
                    <div id="llp-header">
                        <div className="header-static-image-container">
                            <div className="container">
                                <img className="header-image-container" alt="students&business-man"
                                     src="https://roomjack.blob.core.windows.net/roomjack/landlord-landing-page/carousel/winkender mann.png" />
                            </div>
                        </div>
                        <Carousel slide={true} interval={4000} indicators={false} controls={false}>
                            {LANDLORD_CAROUSEL.map((item, index) => {
                                return <Carousel.Item key={"renter-carousel-item-" + index}>
                                    <img src={item.image_url} alt={"background-" + index} />
                                    <Carousel.Caption>
                                        <div className="container">
                                            <div className="carousel-header-info-container">
                                                <div>{parse(getLanguageEntry(item.slogan))}</div>
                                            </div>
                                        </div>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            })}
                        </Carousel>
                    </div>
                </div>
                <div id="llp-roomjack-features">
                    <div id="llp-start-container" className="container">
                        {
                            !this.props.userData &&
                            <div className="registration-container" >
                                <div>
                                    <div className="prefix">
                                        {getLanguageEntry("landing_pages>landlord>you_are")}
                                    </div>
                                    <div className="label">
                                        {getLanguageEntry("landing_pages>landlord>private_header")}
                                    </div>
                                </div>
                                <img src="https://roomjack.blob.core.windows.net/roomjack/landlord-landing-page/features/privatkunde.png" alt="Privatkunden"/>
                                <button className="accent-icon-button" onClick={() => {
                                    this.props.onRegister(AccountType.private)
                                }}>
                                    <FontAwesomeIcon icon={["fal", "user"]} />
                                    <span>{getLanguageEntry("landing_pages>landlord>private_button")}&nbsp;</span>
                                    <span>{getLanguageEntry("landing_pages>landlord>register")}</span>
                                </button>
                            </div>
                        }
                        {
                            !this.props.userData &&
                            <div className="registration-container b2b">
                                <div>
                                    <div className="prefix">
                                        {getLanguageEntry("landing_pages>landlord>you_are")}
                                    </div>
                                    <div className="label">
                                        {getLanguageEntry("landing_pages>landlord>b2b_header")}
                                    </div>
                                </div>
                                <img src="https://roomjack.blob.core.windows.net/roomjack/landlord-landing-page/features/geschaeftskunde.png" alt="Geschäfstkunden"/>
                                <button className="accent-icon-button" onClick={() => {
                                    this.props.onRegister(AccountType.b2b)
                                }}>
                                    <FontAwesomeIcon icon={["fal", "user-tie"]} />
                                    <span>{getLanguageEntry("landing_pages>landlord>b2b_button")}&nbsp;</span>
                                    <span>{getLanguageEntry("landing_pages>landlord>register")}</span>
                                </button>
                            </div>
                        }
                        {
                            this.props.userData &&
                            <div className="create-advert-container">
                                <div>
                                    <div className="prefix">{getLanguageEntry("general>now")}</div>
                                    <div className="label">{getLanguageEntry("landing_pages>landlord>create_advert_label")}</div>
                                    <NavigatorButton className="accent-icon-button" to="/desktop/adverts/editor/general">
                                        <FontAwesomeIcon icon={["fal", "building"]} />
                                        <span>{getLanguageEntry("general>start_now")}</span>
                                    </NavigatorButton>
                                </div>
                                <img src="https://roomjack.blob.core.windows.net/roomjack/landlord-landing-page/features/jetzt_inserieren.png" alt="Jetzt inserieren"/>
                            </div>
                        }
                    </div>
                    <div className="container">
                        <h1>{getLanguageEntry("landing_pages>landlord>features>title")}</h1>
                        {
                            LANDLORD_FEATURES.map((item, index) => {
                                return <div className={"roomjack-feature" + (index % 2 === 0 ? " inverted" : "")}
                                            key={"roomjack-feature-" + index}>
                                    <div className="feature-image-container">
                                        <img src={item.image_url} alt={getLanguageEntry(item.image_alt)} />
                                    </div>
                                    <div className="feature-content-container">
                                        {
                                            item.header &&
                                            <div className="roomjack-headline accent">{getLanguageEntry(item.header)}</div>
                                        }
                                        { buildTextLinkContainer("feature-text-content", item.content, item.links) }
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
                <div id="llp-become-b2b">
                    <div className="container">
                        <div className="landing-page-rental-info-container">
                            <div>
                                <div className="header">{getLanguageEntry("landing_pages>landlord>become_b2b>headline")}</div>
                                <div>{getLanguageEntry("landing_pages>landlord>become_b2b>description")}</div>
                                <Link className="outline-button" to="/content/b2b">
                                    <span>{getLanguageEntry("landing_pages>landlord>become_b2b>button")}</span>
                                </Link>
                            </div>
                        </div>
                        <div className="landing-page-rental-info-image-container">
                            <img
                                src="https://roomjack.blob.core.windows.net/roomjack/landlord-landing-page/carousel/verantwortungsvoll_vermieten.png"
                                alt={getLanguageEntry("landing_page>landlord>become_b2b>image_alt")} />
                        </div>
                    </div>
                </div>
                <div id="llp-roomjack-functions">
                    <div className="container">
                        <h2>{getLanguageEntry("landing_pages>landlord>functions>title")}</h2>
                        <CarouselGrid items={this.getLandlordFunctionItems()} id="landlord-function-carousel"
                                      breakpoints={{
                                          750: {
                                              slidesPerView: 2,
                                              slidesPerGroup: 2
                                          },
                                          950: {
                                              slidesPerView: 3,
                                              slidesPerGroup: 3
                                          }
                                      }} spaceBetween={20} />
                    </div>
                </div>
                <div className="roomjack-is-green teaser-container">
                    <div className="teaser-background-image"></div>
                    <div className="container">
                        <div className="left-side">
                            <div className="teaser-header-container">
                                <div>{getLanguageEntry("landing_pages>shared_content>roomjack_is_green>header_1")}</div>
                                <div>{getLanguageEntry("landing_pages>shared_content>roomjack_is_green>header_2")}</div>
                            </div>
                            <div className="teaser-description-container">
                                {getLanguageEntry("landing_pages>shared_content>roomjack_is_green>description")}
                            </div>
                            <a href="/content/roomjack-is-green" className="outline-button">
                                <span>{getLanguageEntry("landing_pages>shared_content>roomjack_is_green>button_text")}</span>
                            </a>
                        </div>
                        <div className="right-side">
                            <img src="https://roomjack.blob.core.windows.net/roomjack/content-images/nachhaltiger_dude.png"
                             alt={getLanguageEntry("landing_pages>shared_content>roomjack_is_green>image_alt")} />
                        </div>
                    </div>
                </div>
                <div id="landlord-news-room" className="news-room">
                    <div className="container">
                        <h2 className="area-title">
                            {getLanguageEntry("landing_pages>shared_content>news>title")}
                        </h2>
                        <CarouselGrid items={this.getLandlordNewsItems()} id="landlord-news-carousel" breakpoints={{
                            780: {
                                slidesPerView: 1,
                                slidesPerGroup: 1
                            },
                            950: {
                                slidesPerView: 2,
                                slidesPerGroup: 2
                            },
                            1100: {
                                slidesPerView: 3,
                                slidesPerGroup: 3
                            },
                            /*950: {
                                slidesPerView: 4,
                                slidesPerGroup: 4
                            }*/
                        }}/>
                    </div>
                </div>
                <div className="landing-page-rental-info">
                    <div className="container">
                        <div className="landing-page-rental-info-container">
                            <div>
                                <div className="header small">{getLanguageEntry("landing_pages>landlord>become_landlord>headline")}</div>
                                <div className="header">roomjack!</div>
                                {
                                    !this.props.userData &&
                                    <button className="outline-button" onClick={() => {this.props.onRegister()}}>
                                        {getLanguageEntry("landing_pages>landlord>become_landlord>register_now")}
                                    </button>
                                }
                                {
                                    this.props.userData &&
                                    <Link className="outline-button" to="/desktop/adverts/editor/general">
                                        <span>{getLanguageEntry("landing_pages>landlord>create_advert_label")}</span>
                                    </Link>
                                }
                            </div>
                        </div>
                        <div className="landing-page-rental-info-image-container">
                            <img
                                src="https://roomjack.blob.core.windows.net/roomjack/landlord-landing-page/carousel/alles_im_ueberblick.png"
                                alt={getLanguageEntry("landing_page>renter>become_landlord>image_alt")} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getLandlordNewsItems() {
        return NEWS.landlord.concat(NEWS.shared).map((news, i) => {
            return createNewsBox(news, i)
        });
    }

    getLandlordFunctionItems() {
        return LANDLORD_FUNCTIONS.map((item, _) => {
            return <div className="roomjack-function-wrapper">
                <div className="roomjack-function">
                    <div className="roomjack-function-icon">
                        <FontAwesomeIcon icon={item.icon} />
                    </div>
                    <h6>{getLanguageEntry(item.title)}</h6>
                    <div className="roomjack-function-text">{getLanguageEntry(item.content)}</div>
                </div>
            </div>
        });
    }

}
LandlordLandingPage.propTypes = {
    userData: PropTypes.object,
    onRegister: PropTypes.func.isRequired
}
export default LandlordLandingPage;