import React from "react";
import PropTypes from "prop-types";
import "../../../css/components/input/custom-toggle.css";

class CustomToggle extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.defaultValue !== undefined ? this.props.defaultValue : this.props.rightValue
        }
    }

    render() {
        return (
            <div className="custom-toggle">
                <button className={"toggle-container " + this.getSide()}
                        onClick={(e) => { this.switch(e) }} disabled={this.props.disabled}>
                    <div className={"toggle"}></div>
                </button>
                <input value={this.state.value} name={this.props.name} readOnly={true}
                       required={this.props.required} />
            </div>
        )
    }

    switch(e) {
        e.preventDefault();
        this.setState({
            value: this.state.value === this.props.leftValue ? this.props.rightValue : this.props.leftValue
        }, () => {
            this.props.onChange?.(this.state.value);
        });
    }

    setValue(value) {
        if (value !== this.props.leftValue && value !== this.props.rightValue) {
            return;
        }
        this.setState({value: value});
    }

    getValue() {
        return this.state.value;
    }

    getSide() {
        return this.state.value === this.props.leftValue ? "left" : "right";
    }

}
CustomToggle.propTypes = {
    leftValue: PropTypes.any.isRequired,
    rightValue: PropTypes.any.isRequired,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    name: PropTypes.string
}
CustomToggle.defaultProps = {
    disabled: false,
    required: false
}
export default CustomToggle;