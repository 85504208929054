import { createTheme } from '@mui/material/styles';

export const rj_theme = createTheme({
    palette: {
        primary: {
            main: '#D86E36'
        },
    },
});

export const rj_landlord_theme = createTheme({
    palette: {
        primary: {
            main: '#CB8D37'
        },
    },
});
