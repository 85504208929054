import {getLanguageEntry, getRatingText} from "../utils/Helper";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import _uniqueId from "lodash/uniqueId";
import "../../css/components/review-displays.css"

export function ReviewDetailContainer(props) {

    return (
        <div className="rating-detail-container">
            <table className="progress-bar-table">
                <tbody>
                {props.criteria.map((c, i) => {
                    return <tr key={_uniqueId(c)}>
                        <td>{getLanguageEntry(props.langPath + c)}</td>
                        <td>
                            <div className="statistic-bar-bg">
                                <div className="statistic-bar-fg" style={{width: (props.ratingObject[c] / 5 * 100) + "%"}} />
                            </div>
                        </td>
                        <td>{props.ratingObject[c].toFixed(1)}</td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>
    )

}
ReviewDetailContainer.propTypes = {
    criteria: PropTypes.arrayOf(PropTypes.string).isRequired,
    ratingObject: PropTypes.object.isRequired,
    langPath: PropTypes.string.isRequired
}

export function ReviewAverageContainer(props) {

    return(
        <div className="rating-average-container">
            <div className="rating-value">
                <FontAwesomeIcon icon={["fas", "star"]} fixedWidth={true} />
                <span>{props.ratingObject.rating_value.toFixed(1)}</span>
            </div>
            <div className="rating-count">
                ({getRatingText(props.ratingObject.rating_count)})
            </div>
        </div>
    )

}
ReviewAverageContainer.propTypes = {
    ratingObject: PropTypes.object.isRequired
}

export function ReviewDistributionContainer(props) {

    return (
        <div className="rating-distribution-container">
            <table className="progress-bar-table">
                <tbody>
                    {
                        Array.from(Array(5)).map((_, i) => {
                            let x = 4 - i;
                            let distPercent = 0;
                            if (props.ratingObject.rating_count > 0) {
                                distPercent = (props.ratingObject.distribution[x] / props.ratingObject.rating_count) * 100;
                            }
                            return <tr key={_uniqueId(x + "-stars")}>
                                <td>{x + 1}<FontAwesomeIcon icon={["fas", "star"]}/></td>
                                <td>
                                    <div className="statistic-bar-bg">
                                        <div className="statistic-bar-fg" style={{width: distPercent + "%"}}></div>
                                    </div>
                                </td>
                                <td>{getRatingText(props.ratingObject.distribution[x])}</td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    )

}
ReviewDistributionContainer.propTypes = {
    ratingObject: PropTypes.object.isRequired
}