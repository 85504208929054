import React from "react";
import PropTypes from "prop-types";
import {BookingOverviewType, Mode} from "../../../../utils/Types.ts";
import {
    createMultiAdvertDropdown,
    getDayFromBegin,
    getLanguageEntry,
    getTimeUnitInMS,
    PRODUCTION_START
} from "../../../../utils/Helper";
import FilterSelectorBar from "../../../../components/FilterSelectorBar";
import BookingOverviewContainer from "../../../../components/booking/BookingOverviewContainer";
import NavigatorButton from "../../../../components/buttons/NavigatorButton";

class BookingBrowser extends React.Component {

    filterItems = [
        {
            value: -1,
            label: "components>interval_filter>all_bookings",
            ignore: [BookingOverviewType.active]
        },
        {
            value: -30,
            label: "components>interval_filter>last_month",
            ignore: [BookingOverviewType.active, BookingOverviewType.upcoming]
        },
        {
            value: -90,
            label: "components>interval_filter>last_quarter",
            ignore: [BookingOverviewType.active, BookingOverviewType.upcoming]
        },
        {
            value: -180,
            label: "components>interval_filter>last_half_year",
            ignore: [BookingOverviewType.active, BookingOverviewType.upcoming, BookingOverviewType.cancelled]
        },
        {
            value: -365,
            label: "components>interval_filter>last_year",
            ignore: [BookingOverviewType.active, BookingOverviewType.upcoming, BookingOverviewType.cancelled]
        },
        {
            value: 30,
            label: "components>interval_filter>future_month",
            ignore: [BookingOverviewType.active, BookingOverviewType.past]
        },
        {
            value: 90,
            label: "components>interval_filter>future_quarter",
            ignore: [BookingOverviewType.active, BookingOverviewType.past]
        },
        {
            value: 180,
            label: "components>interval_filter>future_half_year",
            ignore: [BookingOverviewType.active, BookingOverviewType.past, BookingOverviewType.cancelled]
        },
        {
            value: 365,
            label: "components>interval_filter>future_year",
            ignore: [BookingOverviewType.active, BookingOverviewType.past, BookingOverviewType.cancelled]
        },
    ]

    constructor(props) {
        super(props);
        let params = new URLSearchParams(window.location.search);
        this.state = {
            interval: -1,
            selected_adverts: [],
            initial_open: params.get("id") ?? null
        }
    }

    render() {
        let filterItems = this.getFilter();
        let filteredBookings = this.filterBookings();
        if (this.props.mode === Mode.landlord && this.props.adverts) {
            for (const booking of filteredBookings.filter(b => b.room_id)) {
                const advert = this.props.adverts.filter(a => a.id === booking.advert_id)[0];
                if (advert && advert.rooms) {
                    const room = advert.rooms.filter(r => r.id === booking.room_id)[0];
                    if (room && room.name) {
                        booking.room_name = room.name;
                    }
                }
            }
        }
        return(
            <div className="booking-tool">
                {
                    filterItems.length > 0 &&
                    <FilterSelectorBar items={filterItems} onChange={(v) => {this.setState({interval: v})}}
                                       defaultValue={-1}/>
                }
                {
                    this.props.adverts.length > 0 && this.props.mode === Mode.landlord &&
                    createMultiAdvertDropdown(this.props.adverts, "adverts", null,
                        (e, v) => { this.setState({selected_adverts: v}) }, this.props.adverts.map(a => a.id))
                }
                {
                    filteredBookings.length === 0 &&
                    <div className="roomjack-container no-data-container">
                        <img src="https://roomjack.blob.core.windows.net/roomjack/b2b-landing-page/b2b_img_2.png"
                             alt="No bookings" style={{objectFit: "contain"}}/>
                        <div className="description-container">
                            {getLanguageEntry("jackboard>bookings>placeholders>" +
                                (this.props.bookings.length === 0 ? this.props.type : "no_bookings_filter"))}
                        </div>
                        {
                            this.props.mode === Mode.renter && (!this.props.bookings || this.props.bookings.length === 0) &&
                            <NavigatorButton to="/search" className="outline-button accent">
                                {getLanguageEntry("jackboard>bookings>labels>to_search")}
                            </NavigatorButton>
                        }
                    </div>
                }
                {
                    filteredBookings.length > 0 &&
                    <div id="booking-list-container">
                        {
                            filteredBookings.map((b, i) => {
                                return <BookingOverviewContainer booking={b} key={b.id} userData={this.props.userData}
                                                                 open={b.id === this.state.initial_open}
                                                                 onSendMessage={this.props.onSendMessage}
                                                                 onChangePaymentMethod={() => {this.props.onChangePayment?.(b)}}
                                                                 onCancelBooking={() => { this.props.onCancelBooking(b)}}
                                                                 onBookingDetail={this.props.onBookingDetail}/>
                            })
                        }
                    </div>
                }
            </div>
        )
    }

    getFilter() {
        return this.props.type === BookingOverviewType.active ? [] :
            this.filterItems.filter(i => !i.ignore.includes(this.props.type));
    }

    filterBookings() {
        let result = [];
        let now = Date.now();
        let today = getDayFromBegin().getTime();
        let start = this.props.type === BookingOverviewType.upcoming ? today : PRODUCTION_START.getTime();
        let end = this.props.type === BookingOverviewType.past ? today : null;
        if (this.props.bookings) {
            switch (this.props.type) {
                case BookingOverviewType.upcoming:
                    if (this.state.interval > 0) {
                        end = start + getTimeUnitInMS(this.state.interval);
                    }
                    result = this.props.bookings.filter(b => !b.cancelled && b.start > now &&
                        b.start >= start && (!end || b.start <= end));
                    break;
                case BookingOverviewType.cancelled:
                    if (this.state.interval !== -1) {
                        if (this.state.interval > 0) {
                            end = start + getTimeUnitInMS(this.state.interval);
                        }
                        else {
                            end = today;
                            start = today + getTimeUnitInMS(this.state.interval)
                        }
                    }
                    result = this.props.bookings.filter(b => b.cancelled &&
                        b.cancellation_ts >= start && (!end || b.cancellation_ts <= end));
                    break;
                case BookingOverviewType.past:
                    if (this.state.interval !== -1) {
                        start = end + getTimeUnitInMS(this.state.interval);
                    }
                    result = this.props.bookings.filter(b => !b.cancelled && b.end <= now &&
                        b.end >= start && b.end <= end);
                    break;
                default:
                    result = this.props.bookings.filter(b => !b.cancelled && b.start < now && b.end > now);
                    break;
            }
        }
        return result;
    }

}

BookingBrowser.propTypes = {
    mode: PropTypes.oneOf(Object.values(Mode)).isRequired,
    type: PropTypes.oneOf(Object.values(BookingOverviewType)).isRequired,
    userData: PropTypes.object.isRequired,
    onSendMessage: PropTypes.func.isRequired,
    onCancelBooking: PropTypes.func.isRequired,
    onChangePayment: PropTypes.func,
    adverts: PropTypes.array,
    bookings: PropTypes.array,
    onBookingDetail: PropTypes.func
}
export default BookingBrowser;