import React from "react";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import {
    calculateDiscount,
    calculateInvoice,
    convertMilliseconds,
    createBookingOverviewTableRows,
    createCancellationConditionList, createLandlordCheckbox,
    getFormattedDate,
    getLanguageEntry,
    getNightsBetweenDates,
    isAdvertStillFreeCancellable,
    MAX_MESSAGE_LENGTH, priceToString,
} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "../../../css/components/modals/booking-modal.css";
import {BookingType, PaymentTarget, Mode} from "../../utils/Types.ts";
import PaymentHandler from "../input/PaymentHandler";
import HousingPreview, {PREVIEW_TYPES} from "../HousingPreview";
import FetchButton from "../buttons/FetchButton";
import TextArea from "../input/TextArea";
import NavigatorButton from "../buttons/NavigatorButton";
import {checkDiscountCode, createInquiry, createShortTermBookingProcess} from "../../utils/RESTInterface";
import parse from "html-react-parser";

class BookingModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            start_date: null,
            end_date: null,
            advert: null,
            housing: null,
            invoice: null,
            pricing: null,
            booking_type: null,
            booking_in_process: null,
            process_result: null,
            checking_discount_code: false,
            discount_code_result: null,
            roomjack_terms_accepted: false,
            landlord_terms_accepted: false,
            landlord_revocation_accepted: false,
            landlord_privacy_accepted: false,
            withdrawal_revocation_accepted: false,
        }
        this.payment_handler = React.createRef();
        this.inquiry_form = React.createRef();
        this.discount_form = React.createRef();
    }

    render() {
        if (!this.state.advert) {
            return <></>
        }
        let discount = this.state.discount_code_result && this.state.discount_code_result.discount ?
            this.state.discount_code_result.discount : null;
        let ownerName = `${this.state.advert.owner.first_name} ${this.state.advert.owner.last_name}`;
        return (
            <Modal className="booking-modal" show={this.state.show} size="xl" backdrop="static" keyboard={false}
                   onHide={() => { this.hide() }} dialogClassName="max-content" centered>
                <Modal.Header>
                    <h4>Jack it out</h4>
                    <button onClick={() => { this.hide() }} disabled={this.state.booking_in_process}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>

                <Modal.Body>
                    {
                        !this.state.process_result &&
                        <div className="container-fluid">
                            <div className="basic-booking-information">
                                <div className="booking-data roomjack-container">
                                    <div className="roomjack-container-header">
                                        {getLanguageEntry("processes>booking>check_data_title")}
                                    </div>
                                    <table>
                                        <tbody>
                                        <tr>
                                            {
                                                this.state.start_date && this.state.end_date &&
                                                <td>
                                                    <div className="roomjack-headline">
                                                        {getLanguageEntry("processes>booking>booking_period")}
                                                    </div>
                                                    <div className="description-container">
                                                        {
                                                            getFormattedDate(this.state.start_date, true, false, false, true, true) +
                                                            " - " +
                                                            getFormattedDate(this.state.end_date, true, false, false, true, true)
                                                        }
                                                    </div>
                                                </td>
                                            }
                                        </tr>
                                        {
                                            this.state.advert &&
                                            <tr>
                                                <td>
                                                    <div className="roomjack-headline">Jack-in</div>
                                                    <div className="description-container">
                                                        {this.state.advert.check_in_time ?? "06:00"}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="roomjack-headline">Jack-out</div>
                                                    <div className="description-container">
                                                        {this.state.advert.check_out_time ?? "18:00"}
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                        <tr>
                                            {
                                                this.state.advert &&
                                                <td>
                                                    <div className="roomjack-headline">
                                                        {getLanguageEntry("general>landlord")}
                                                    </div>
                                                    <div className="description-container">
                                                        {ownerName}
                                                    </div>
                                                </td>
                                            }
                                        </tr>
                                        </tbody>
                                    </table>
                                    {
                                        this.state.advert &&
                                        <div className="description-container">
                                            <FontAwesomeIcon icon={["fal", "info-circle"]}/>
                                            <span>
                                        {getLanguageEntry("processes>booking>address_info")}
                                    </span>
                                        </div>
                                    }
                                </div>
                                {
                                    this.state.advert &&
                                    <div className="booking-payment roomjack-container">
                                        <div className="roomjack-container-header">
                                            {getLanguageEntry("components>payment_handler>header")}
                                        </div>
                                        <PaymentHandler target={PaymentTarget.connect} ref={this.payment_handler}
                                                        onPaymentConfirmed={(r) => {this.onPaymentConfirmed(r)}}
                                                        accountOwnerID={this.state.advert.owner.id}
                                                        onConfirmationStarted={() => {
                                                            this.setState({booking_in_process: "components>payment_handler>verifying_payment_method"})
                                                        }} />
                                    </div>
                                }
                                {
                                    this.state.invoice && this.state.invoice.charges &&
                                    <div className="charge-overview roomjack-container">
                                        <div className="roomjack-container-header">
                                            {getLanguageEntry("processes>booking>charges_title")}
                                        </div>
                                        <table className="charge-listing description-container">
                                            <tbody>
                                                <tr>
                                                    <th>{getLanguageEntry("general>date")}</th>
                                                    <th>{getLanguageEntry("general>sum")}</th>
                                                </tr>
                                                {
                                                    this.state.invoice.charges.map((c, i) => {
                                                        console.log(c);
                                                        return this.createChargeRow(c, i, discount);
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        {
                                            this.state.nights > 29 &&
                                            <div className="description-container">
                                                <FontAwesomeIcon icon={["fal", "info-circle"]} />{parse(getLanguageEntry("processes>booking>charges_description"))}
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    this.state.advert && this.state.pricing && !this.state.pricing.long_term &&
                                    <div className="booking-cancellation-conditions roomjack-container">
                                        <div className="roomjack-container-header">
                                            {getLanguageEntry("processes>booking>cancellation_condition_title")}
                                        </div>
                                        <div>
                                            {createCancellationConditionList(
                                                this.state.advert.cancellation_conditions,
                                                this.state.invoice, this.state.start_date.getTime(), false)}
                                        </div>
                                        <div className="cancellation-condition-payment-fee-hint description-container">
                                            <FontAwesomeIcon icon={["fal", "info-circle"]}/>
                                            <span>
                                                {getLanguageEntry("processes>booking>cancellation_condition_payment_fees")}
                                            </span>
                                            <a href="https://stripe.com/de/pricing" rel="noreferrer nofollow" target="blank">https://stripe.com/de/pricing</a>.
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="slide-container">
                                {
                                    this.state.advert &&
                                    <div className="booking-checkout-overview roomjack-container">
                                        <HousingPreview advert={this.state.advert} type={PREVIEW_TYPES.overview}
                                                        className="border-bottom" />
                                        <div className="roomjack-container-header">
                                            {getLanguageEntry("processes>booking>overview_listing_title")}
                                        </div>
                                        <div className="booking-overview-listing">
                                            <table>
                                                <tbody>
                                                {createBookingOverviewTableRows(this.state.pricing, this.state.invoice,
                                                this.state.start_date, this.state.end_date, discount).map((x, _) => x)}
                                                </tbody>
                                            </table>
                                        </div>
                                        {
                                            this.state.advert &&
                                            <div className="discount-container">
                                                <form className="horizontal-form-group" ref={this.discount_form}
                                                      onSubmit={(e) => {this.checkDiscountCode(e)}}>
                                                    <label htmlFor="discount-code" className="description-container">
                                                        {getLanguageEntry("processes>booking>discount_code")}
                                                    </label>
                                                    <input id="discount-code" type="text" name="discount_code"
                                                           onKeyUp={() => {
                                                               if (this.state.discount_code_result) {
                                                                   this.setState({discount_code_result: null})
                                                               }
                                                           }}
                                                           disabled={this.state.discount_code_result &&
                                                               this.state.discount_code_result.discount}
                                                           required={true}/>
                                                    {
                                                        (!this.state.discount_code_result || this.state.discount_code_result.errorCode) &&
                                                        <FetchButton className="outline-button accent" type="submit"
                                                                     loading={this.state.checking_discount_code}
                                                                     loadingText={"processes>booking>checking_discount_code"}
                                                                     disabled={!!this.state.booking_in_process}>
                                                            {getLanguageEntry("processes>booking>redeem")}
                                                        </FetchButton>
                                                    }
                                                    {
                                                        this.state.discount_code_result &&
                                                        this.state.discount_code_result.discount &&
                                                        <button className="outline-button accent"
                                                                onClick={(e) => {
                                                                    this.setState({discount_code_result: null})
                                                                }}
                                                                disabled={this.state.booking_in_process}>
                                                            {getLanguageEntry("processes>booking>remove")}
                                                        </button>
                                                    }
                                                </form>
                                                {this.getDiscountCodeErrorMessage()}
                                            </div>
                                        }
                                        {
                                            this.state.advert && this.state.booking_type === BookingType.inquiry &&
                                            <form ref={this.inquiry_form} style={{display: "grid", gap: "20px"}}
                                                  id="inquiry-form"
                                                  onSubmit={(e) => {this.startBookingProcess(e)}}>
                                                <TextArea name="message" required={true} maxLength={MAX_MESSAGE_LENGTH}
                                                          label={getLanguageEntry("processes>messaging>your_message_to")
                                                              .replace("@", this.state.advert.owner.first_name)}/>
                                            </form>
                                        }
                                        {
                                            this.state.advert && this.state.nights < 30 &&
                                            isAdvertStillFreeCancellable(this.state.start, this.state.advert.cancellation_conditions) &&
                                            createCancellationConditionList(this.state.advert.cancellation_conditions,
                                                this.state.invoice, this.state.start)[0]
                                        }
                                        {
                                            this.state.advert && this.state.nights < 30 &&
                                            !isAdvertStillFreeCancellable(this.state.start, this.state.advert.cancellation_conditions) &&
                                            <div className="cancellation-condition description-container">
                                                {getLanguageEntry("processes>booking>charged_cancellation")}
                                            </div>
                                        }
                                        {
                                            this.getLandlordTermsCheckbox()
                                        }
                                        {
                                            this.state.advert &&
                                            <div className="form-group">
                                                <input type="checkbox" onChange={(e) =>
                                                {this.setState({roomjack_terms_accepted: e.target.checked})}}
                                                       id="roomjack-terms"/>
                                                <label htmlFor="roomjack-terms" className="description-container">
                                                    {parse(getLanguageEntry("processes>booking>roomjack_terms"))}
                                                </label>
                                            </div>
                                        }
                                        {
                                            this.getLandlordRevocationCheckbox()
                                        }
                                        {
                                            this.getLandlordPrivacyCheckbox()
                                        }
                                        {
                                            this.state.advert &&
                                            <div className="form-group">
                                                <input type="checkbox" onChange={(e) =>
                                                {this.setState({withdrawal_revocation_accepted: e.target.checked})}}
                                                       id="withdrawal"/>
                                                <label htmlFor="withdrawal" className="description-container">
                                                    {parse(getLanguageEntry("processes>booking>withdrawal_revocation"))}
                                                </label>
                                            </div>
                                        }
                                        {
                                            this.state.booking_type === BookingType.direct &&
                                            <div className="description-container">
                                                <FontAwesomeIcon icon={["fal", "info-circle"]} />
                                                <span>{getLanguageEntry("processes>booking>booking_notice")
                                                    .replace("{name}", ownerName)}</span>
                                            </div>
                                        }
                                        {
                                            this.state.booking_type === BookingType.inquiry &&
                                            <div className="description-container">
                                                <FontAwesomeIcon icon={["fal", "info-circle"]} />
                                                <span>{getLanguageEntry("processes>booking>inquiry_notice")
                                                    .replace("{name}", ownerName)}</span>
                                            </div>
                                        }
                                        {
                                            this.state.advert && this.state.booking_type === BookingType.direct &&
                                            <FetchButton className="start-booking-checkout accent-icon-button"
                                                         loading={this.state.booking_in_process !== null}
                                                         loadingText={this.state.booking_in_process}
                                                         disabled={this.state.checking_discount_code || 
                                                             !this.state.roomjack_terms_accepted ||
                                                             !this.state.landlord_terms_accepted ||
                                                             !this.state.landlord_revocation_accepted ||
                                                             !this.state.landlord_privacy_accepted ||
                                                             !this.state.withdrawal_revocation_accepted}
                                                         onClick={(e) => { this.startBookingProcess(e) }}>
                                                <span>
                                                    {getLanguageEntry("processes>booking>book_with_charge")}
                                                </span>
                                            </FetchButton>
                                        }
                                        {
                                            this.state.advert && this.state.booking_type === BookingType.inquiry &&
                                            <FetchButton className="start-booking-checkout accent-icon-button"
                                                         loading={this.state.booking_in_process !== null}
                                                         type="submit" loadingText={this.state.booking_in_process}
                                                         form="inquiry-form"
                                                         disabled={this.state.checking_discount_code ||
                                                             !this.state.roomjack_terms_accepted ||
                                                             !this.state.landlord_terms_accepted ||
                                                             !this.state.landlord_revocation_accepted ||
                                                             !this.state.landlord_privacy_accepted ||
                                                             !this.state.withdrawal_revocation_accepted}>
                                                {
                                                    getLanguageEntry("processes>booking>send_inquiry")
                                                }
                                            </FetchButton>
                                        }
                                    </div>
                                }
                                <div className="payment-security-notice roomjack-container">
                                    <FontAwesomeIcon icon={["fad", "shield-quartered"]}/>
                                    <div>{getLanguageEntry("processes>booking>security_notice")}</div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.process_result &&
                        <div className="process-modal-result-container">
                            <div className="process-result-image">
                                <img src={this.state.process_result.success ?
                                    "https://roomjack.blob.core.windows.net/roomjack/animations/highfive.gif" :
                                    "https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear.png"}
                                     alt={this.state.booking_type.charAt(0).toUpperCase() +
                                          this.state.booking_type.substring(1) +
                                         (this.state.process_result.success ? " success" : " failed")} />
                            </div>
                            <div className="process-result-message-box">
                                <div className="header">
                                    {
                                        this.state.booking_type === BookingType.direct &&
                                        getLanguageEntry("processes>booking>" +
                                            (this.state.process_result.success ? "booking_success" : "booking_failed"))
                                    }
                                    {
                                        this.state.booking_type === BookingType.inquiry &&
                                        getLanguageEntry("processes>booking>" +
                                            (this.state.process_result.success ? "inquiry_success" : "booking_failed"))
                                    }
                                </div>
                                {
                                    this.state.booking_type === BookingType.direct &&
                                    this.state.process_result.errorCode &&
                                    this.getErrorDescription()
                                }
                                {
                                    this.state.process_result.success && this.createRedirectionLink()
                                }
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        )
    }

    show(advert, housing, startDate, endDate) {
        let offset = convertMilliseconds(startDate.getTime() - Date.now(), 'day', 'floor');
        let nights = getNightsBetweenDates(endDate, startDate);
        let relevantPricing = nights >= 30 ? housing.long_term_pricing : housing.pricing;
        console.log(relevantPricing);
        let invoice = calculateInvoice(startDate, endDate, offset, relevantPricing);
        this.setState({
            show: true,
            start_date: startDate,
            end_date: endDate,
            advert: advert,
            housing: housing,
            nights: nights,
            invoice: invoice,
            pricing: relevantPricing,
            booking_type: advert.booking_type === BookingType.direct && (nights < 30 || advert.owner.b2b) ?
                BookingType.direct : BookingType.inquiry,
            booking_in_process: null,
            process_result: null,
            checking_discount_code: false,
            discount_code_result: null,
            roomjack_terms_accepted: false,
            landlord_terms_accepted: advert && !advert.terms_of_service,
            landlord_revocation_accepted: advert && !advert.revocation,
            landlord_privacy_accepted: advert && !advert.privacy_policy,
            withdrawal_revocation_accepted: false
        })
    }

    hide() {
        let bookingSucceeded = this.state.booking_type === BookingType.direct &&
            this.state.process_result && this.state.process_result.success;
        this.setState({
            show: false,
            start_date: null,
            end_date: null,
            advert: null,
            housing: null,
            invoice: null,
            pricing: null,
            booking_type: null,
            booking_in_process: null,
            process_result: null,
            checking_discount_code: false,
            discount_code_result: null
        }, () => { this.props.onHide?.(bookingSucceeded); });
    }

    startBookingProcess(e) {
        if (e) {
            e.preventDefault();
        }
        if (this.payment_handler.current) {
            this.payment_handler.current.triggerSubmit();
        }
    }

    onPaymentConfirmed(result) {
        if (result.setupIntent) {
            let advertID = this.state.advert.id;
            let roomID = this.state.housing.id.startsWith("room_") ? this.state.housing.id : null;
            let discountID = this.state.discount_code_result && this.state.discount_code_result.discount ?
                this.state.discount_code_result.discount.id : null;
            let that = this;
            if (this.state.booking_type === BookingType.direct) {
                this.setState({booking_in_process: "processes>booking>booking_in_process"});
                createShortTermBookingProcess(advertID, roomID, this.state.start_date.getTime(),
                    this.state.end_date.getTime(), result.setupIntent.id, discountID, (response) => {
                        let state = {
                            booking_in_process: null,
                            process_result: response
                        };
                        if (response.errorCode) {
                            console.warn(response.errorCode + ": " + response.message);
                            that.setState(state);
                        }
                        else if (response.booking_id) {
                            let user = JSON.parse(JSON.stringify(that.props.userData));
                            if (!user.bookings) {
                                user.bookings = [response.booking_id];
                            }
                            else {
                                user.bookings.push(response.booking_id);
                            }
                            that.props.onUpdateUserData?.(user, () => {
                                that.setState(state);
                            });
                        }
                    })
            }
            else {
                this.setState({booking_in_process: "processes>booking>inquiry_in_process"});
                let form = new FormData(this.inquiry_form.current);
                createInquiry(advertID, roomID, this.state.start_date.getTime(),
                    this.state.end_date.getTime(), result.setupIntent.id, discountID,
                    form.get("message"), (response) => {
                        let state = {
                            booking_in_process: null,
                            process_result: response
                        };
                        if (response.errorCode) {
                            console.warn(response.errorCode + ": " + response.message);
                            that.setState(state);
                        }
                        else {
                            let user = JSON.parse(JSON.stringify(that.props.userData));
                            let update = false;
                            if (response.conversations) {
                                user.conversations = response.conversations;
                                update = true;
                            }
                            if (response.booking_id) {
                                if (!user.bookings) {
                                    user.bookings = [response.booking_id];
                                }
                                else {
                                    user.bookings.push(response.booking_id);
                                }
                                update = true;
                            }
                            if (update) {
                                that.props.onUpdateUserData?.(user, () => {
                                    that.setState(state);
                                });
                            }
                        }
                    })
            }
        }
        else {
            this.setState({booking_in_process: null})
        }
    }

    getErrorDescription() {
        let text = getLanguageEntry("error_codes>" +
            (this.state.process_result.errorCode === "ad_availability_err_12" ?
                "check_out_room_not_available_err" : this.state.process_result.errorCode));
        if (text.includes("#link#")) {
            let split = text.split("#link#");
            return <div className="description-container">
                {split[0]}
                <button className='link-button' onClick={() => { this.props.onContactSupport() }}>
                    {getLanguageEntry("general>support")}
                </button>
                {split[1]}
            </div>
        }
        return <div className="description-container">{text}</div>;
    }

    createChargeRow(charge, index, discount) {
        let chargeDiscount = 0;
        if (discount && index < discount.apply_to_charges) {
            chargeDiscount = Math.min(calculateDiscount(discount.discount, charge.amount), charge.amount);
        }
        return <tr key={charge.id + "-" + index}>
            <td>{getFormattedDate(new Date(charge.due_dates[0]))}</td>
            <td>{priceToString(charge.amount - chargeDiscount)}</td>
        </tr>;
    }

    createRedirectionLink() {
        if (this.state.process_result.success) {
            if (this.state.booking_type === BookingType.inquiry &&
                this.state.process_result.conversation_id) {
                return <NavigatorButton className="accent-icon-button"
                                        to={"/desktop/messages?conversation=" + this.state.process_result.conversation_id}>
                    {getLanguageEntry("processes>booking>to_chat")}
                </NavigatorButton>
            }
            if (this.state.booking_type === BookingType.direct &&
                this.state.process_result.booking_id) {
                return <NavigatorButton className="accent-icon-button" onBeforeClick={() => {
                    if (this.props.mode !== Mode.renter) {
                        this.props.onSwitchMode?.(Mode.renter);
                    }
                }}
                                        to={"/desktop/bookings/upcoming?id=" + this.state.process_result.booking_id}>
                    {getLanguageEntry("processes>booking>to_my_booking")}
                </NavigatorButton>
            }
        }
    }

    checkDiscountCode(e) {
        e.preventDefault();
        let code = new FormData(this.discount_form.current).get("discount_code");
        let that = this;
        this.setState({checking_discount_code: true});
        checkDiscountCode(code, (response) => {
            that.setState({
                checking_discount_code: false,
                discount_code_result: response
            });
        });
    }

    getDiscountCodeErrorMessage() {
        if (!this.state.discount_code_result || !this.state.discount_code_result.errorCode) {
            return null;
        }
        return <div className="description-container">
            {getLanguageEntry("error_codes>discount_errors>" + this.state.discount_code_result.errorCode)}
        </div>
    }

    getLandlordTermsCheckbox() {
        if (this.state.advert) {
            return createLandlordCheckbox(this.state.advert, (accepted) => {
                this.setState({landlord_terms_accepted: accepted});
            }, "terms");
        }
        return null;
    }

    getLandlordRevocationCheckbox() {
        if (this.state.advert) {
            return createLandlordCheckbox(this.state.advert, (accepted) => {
                this.setState({landlord_revocation_accepted: accepted});
            }, "revocation");
        }
        return null;
    }

    getLandlordPrivacyCheckbox() {
        if (this.state.advert) {
            return createLandlordCheckbox(this.state.advert, (accepted) => {
                this.setState({landlord_privacy_accepted: accepted});
            }, "privacy_policy");
        }
        return null;
    }


}

BookingModal.propTypes = {
    userData: PropTypes.any,
    onUpdateUserData: PropTypes.func.isRequired,
    onContactSupport: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(Object.values(Mode)).isRequired,
    onSwitchMode: PropTypes.func.isRequired
}
export default BookingModal;