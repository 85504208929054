import React from "react";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import {BOOKING_BLOCK_COLORS, getDayFromBegin, getLanguageEntry} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";
import FetchButton from "../buttons/FetchButton";
import Calendar from "../Calendar";
import Dropdown from "../input/Dropdown";
import {STANDARD_BLOCK_COLOR} from "../../pages/jackboard/submenus/Bookings";
import {addBookingFreePeriod, deleteBookingFreePeriod, updateUser} from "../../utils/RESTInterface";
import md5 from "md5";

class BookingFreePeriodModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            advert: null,
            room_id: null,
            period: null,
            selected_template: null,
            show_creator: false,
            show_editor: false,
            error: null,
            saving: false,
            deleting: false,
            saving_template: false,
            deleting_template: false
        }
        this.calendar = React.createRef();
        this.template_creator = React.createRef();
        this.template_editor = React.createRef();
    }

    render() {
        let template = this.getSelectedTemplate();
        let colorObject = this.getColorOfTemplate(template);
        let color = colorObject ? colorObject.name : null;
        return (
            <Modal className={"bfp-modal landlord"} show={this.state.show} size="lg" backdrop="static" keyboard={false}
                   onHide={() => { this.hide() }} dialogClassName="max-content" centered>
                <Modal.Header>
                    <h4>{getLanguageEntry("components>booking_free_period_modal>title")}</h4>
                    <button onClick={() => { this.hide() }} disabled={this.state.saving_template ||
                        this.state.deleting_template ||
                        this.state.saving || this.state.deleting}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>

                <Modal.Body>
                    <div style={{display: "grid", gap: "20px", justifyItems: "center"}}>
                        <div className="description-container">
                            {parse(getLanguageEntry("components>booking_free_period_modal>description"))}
                        </div>
                        <div style={{display: "grid", gap: "20px", justifyItems: "center"}}>
                            <div className="horizontal-form-group">
                                <Dropdown items={this.getBFPItems()} name="block_template"
                                          initialValue={this.state.selected_template}
                                          onChange={(_, v) => {this.setState({
                                              selected_template: v !== STANDARD_BLOCK_COLOR.value ? v : null,
                                              show_creator: false,
                                              show_editor: true,
                                              error: null
                                          })}}/>
                                {
                                    this.canAddTemplate() && !this.state.show_creator &&
                                    <button className="outline-button black" onClick={() => {this.setState({
                                        show_creator: true,
                                        show_editor: false,
                                        selected_template: null,
                                        error: null
                                    })}}>
                                        <FontAwesomeIcon icon={["fal", "plus"]}/>
                                        <span>{getLanguageEntry("components>booking_free_period_modal>new_marker")}</span>
                                    </button>
                                }
                            </div>
                            {
                                this.state.show_creator && !this.state.show_editor &&
                                <div className="horizontal-form-group">
                                    <form ref={this.template_creator} className="horizontal-form-group wrap"
                                          onSubmit={(e) => {this.onSaveTemplate(e)}}
                                          style={{justifyContent: "center"}}>
                                        <div className="horizontal-form-group">
                                            <label className="description-container">
                                                {getLanguageEntry("components>booking_free_period_modal>color_label")}
                                            </label>
                                            <Dropdown items={this.getAvailableColors()} onChange={() => {this.clearError()}}
                                                      name="color" required={true}/>
                                        </div>
                                        <div className="horizontal-form-group">
                                            <label className="description-container"
                                                   htmlFor="custom-booking-free-period-description">
                                                {getLanguageEntry("components>booking_free_period_modal>description_label")}
                                            </label>
                                            <input maxLength="20" id="custom-booking-free-period-description"
                                                   name="description" required={true} onKeyUp={() => {this.clearError()}}
                                                   placeholder={getLanguageEntry("components>booking_free_period_modal>description_placeholder")} />
                                        </div>
                                        <FetchButton className="simple-icon-button outline-button black"
                                                     disabled={this.state.saving_template ||
                                                         this.state.deleting_template ||
                                                         this.state.saving || this.state.deleting}
                                                     loading={this.state.saving_template} type="submit">
                                            <FontAwesomeIcon icon={["fal", "save"]}/>
                                        </FetchButton>
                                    </form>
                                    <button className="simple-icon-button outline-button black"
                                            onClick={() => { this.setState({
                                                show_creator: false,
                                                error: null}) }}>
                                        <FontAwesomeIcon icon={["fal", "times"]}/>
                                    </button>
                                </div>
                            }
                            {
                                this.state.show_editor && this.state.selected_template &&
                                <div className="horizontal-form-group">
                                    <form ref={this.template_editor} className="horizontal-form-group wrap"
                                          onSubmit={(e) => {this.onSaveTemplate(e, template)}}
                                          style={{justifyContent: "center"}}>
                                        <div className="horizontal-form-group">
                                            <label className="description-container">
                                                {getLanguageEntry("components>booking_free_period_modal>color_label")}
                                            </label>
                                            <Dropdown items={this.getAvailableColors(color)}  name="color"
                                                      initialValue={color} required={true} onChange={() => {this.clearError()}} />
                                        </div>
                                        <div className="horizontal-form-group">
                                            <label className="description-container"
                                                   htmlFor="custom-booking-free-period-description">
                                                {getLanguageEntry("components>booking_free_period_modal>description_label")}
                                            </label>
                                            <input maxLength="20" id="custom-booking-free-period-description"
                                                   defaultValue={template ? template.description : ""}
                                                   name="description" required={true} onKeyUp={() => {this.clearError()}}
                                                   placeholder={getLanguageEntry("components>booking_free_period_modal>description_placeholder")} />
                                        </div>
                                        <FetchButton className="simple-icon-button outline-button black"
                                                     disabled={this.state.saving_template ||
                                                         this.state.deleting_template ||
                                                         this.state.saving || this.state.deleting}
                                                     loading={this.state.saving_template} type="submit">
                                            <FontAwesomeIcon icon={["fal", "save"]}/>
                                        </FetchButton>
                                    </form>
                                    <FetchButton className="simple-icon-button outline-button black"
                                                 onClick={() => { this.onDeleteTemplate() }}
                                                 disabled={this.state.saving_template ||
                                                     this.state.deleting_template ||
                                                     this.state.saving || this.state.deleting}
                                                 loading={this.state.deleting_template}>
                                        <FontAwesomeIcon icon={["fal", "trash"]}/>
                                    </FetchButton>
                                </div>
                            }
                        </div>
                        {
                            this.state.error &&
                            <div className="description-container">
                                <FontAwesomeIcon icon={["fal", "exclamation-circle"]} style={{color: "red", marginRight: "3px"}}/>
                                {this.state.error}
                            </div>
                        }
                        <Calendar ref={this.calendar} hideOnBlur={false} useFooter={false} countDays={true}
                                  classList={["always-show"]} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        this.state.period &&
                        <FetchButton loading={this.state.deleting} loadingText={"general>deleting"} className={"outline-button black"}
                                     disabled={this.state.saving_template || this.state.deleting_template || this.state.saving || this.state.deleting}
                                     onClick={() => {this.onDeletePeriod()}}>
                            <FontAwesomeIcon icon={["fal", "trash"]}/>
                            <span>{getLanguageEntry("general>delete")}</span>
                        </FetchButton>
                    }
                    <FetchButton loading={this.state.saving} loadingText={"general>saving"} className={"outline-button black"}
                                 disabled={this.state.saving_template || this.state.deleting_template || this.state.saving || this.state.deleting}
                                 onClick={() => {this.onSavePeriod()}}>
                        <FontAwesomeIcon icon={["fal", "save"]}/>
                        <span>{getLanguageEntry("general>save")}</span>
                    </FetchButton>
                </Modal.Footer>
            </Modal>
        )
    }

    show(advert, roomID, period, date) {
        this.setState({
            show: true,
            advert: advert,
            room_id: roomID ?? null,
            period: period,
            selected_template: period && period.description ? period.description : STANDARD_BLOCK_COLOR.value,
            show_creator: false,
            show_editor: period && period.description,
            error: null,
        }, () => {
            if (this.calendar.current) {
                if (period) {
                    let start = getDayFromBegin(new Date(period.start));
                    let end = getDayFromBegin(new Date(period.end));
                    this.calendar.current.setDates(start, end, false, false, "start");
                }
                else {
                    if (!date) {
                        date = getDayFromBegin();
                    }
                    this.calendar.current.setDates(getDayFromBegin(date), null, false, true, "start");
                }
            }
        });
    }

    hide() {
        this.setState({
            show: false,
            advert: null,
            room_id: null,
            period: null,
            selected_template: null,
            show_creator: false,
            show_editor: false,
            error: null,
            saving: false,
            deleting: false,
            saving_template: false,
            deleting_template: false
        });
    }

    getBFPItems() {
        let items = [STANDARD_BLOCK_COLOR];
        if (this.props.userData.booking_block_markers) {
            for (const bbm of this.props.userData.booking_block_markers) {
                items.push({
                    value: bbm.id,
                    label: bbm.description,
                    color: BOOKING_BLOCK_COLORS.filter(b => b.name === bbm.color)[0].hex_color
                })
            }
        }
        return items;
    }

    getAvailableColors(skipColor) {
        let items = [];
        for (const color of BOOKING_BLOCK_COLORS) {
            if (!this.props.userData.booking_block_markers ||
                !this.props.userData.booking_block_markers.filter(b => (!skipColor || skipColor !== color.name) &&
                    b.color === color.name)[0]) {
                items.push({
                    value: color.name,
                    label: color.name.split("_").map(n => n.charAt(0).toLocaleUpperCase() + n.substring(1)).join(" "),
                    color: color.hex_color  })
            }
        }
        return items;
    }

    getSelectedTemplate() {
        if (this.props.userData.booking_block_markers && this.state.selected_template) {
            return this.props.userData.booking_block_markers
                .filter(bbm => bbm.id === this.state.selected_template)[0];
        }
        return null;
    }

    getColorOfTemplate(template) {
        if (!template) {
            return null;
        }
        let color = BOOKING_BLOCK_COLORS.filter(c => c.name === template.color)[0];
        return color ?? null;
    }

    canAddTemplate() {
        return !this.props.userData.booking_block_markers ||
        BOOKING_BLOCK_COLORS.filter(c => !this.props.userData.booking_block_markers
            .filter(b => b.color === c.name)[0]).length > 0;
    }

    clearError() {
        if (this.state.error) {
            this.setState({error: null})
        }
    }

    onSaveTemplate(e, template) {
        e.preventDefault();
        let formData = new FormData((template ? this.template_editor.current : this.template_creator.current));
        let description = formData.get("description");
        let color = formData.get("color");
        if (this.props.userData.booking_block_markers) {
            for (const marker of this.props.userData.booking_block_markers) {
                if ((!template || template.id !== marker.id) && marker.description.toLowerCase() === description.toLowerCase()) {
                    this.setState({
                        error: getLanguageEntry("components>booking_free_period_modal>same_name_error")});
                    return;
                }
            }
        }
        if (!template || template.description !== description || template.color !== color) {
            let markerList = this.props.userData.booking_block_markers ?
                this.props.userData.booking_block_markers.slice(0) : [];
            if (template) {
                let newTemplate = markerList.filter(t => t.id === template.id)[0];
                newTemplate.description = description;
                newTemplate.color = color;
            }
            else {
                template = {
                    id: "cMark_" + md5("" + Date.now() + '@' + description + '>' + color),
                    description: description,
                    color: color
                }
                markerList.push(template);
            }
            this.setState({
                saving_template: true,
                error: null
            });
            let that = this;
            updateUser(this.props.userData.id, {booking_block_markers: markerList}, (response) => {
                let state = {
                    saving: false,
                    deleting: false,
                    saving_template: false,
                    deleting_template: false
                };
                if (response.errorCode !== undefined) {
                    console.error(response.errorCode + ": " + response.message);
                    that.setState(state);
                }
                else {
                    that.props.onUpdateUserSession?.(response, () => {
                        state.selected_template = template.id;
                        state.show_editor = true;
                        state.show_creator = false;
                        that.setState(state);
                    });
                }
            });
        }
        else {
            this.setState({
                show_editor: false,
                selected_template: template.id,
                error: null
            })
        }
    }

    onDeleteTemplate() {
        if (!this.state.selected_template) {
            this.setState({
                show_creator: false,
                show_editor: false,
                error: null
            });
        }
        else if (this.props.userData.booking_block_markers) {
            let markerList = this.props.userData.booking_block_markers.filter(b => b.id !== this.state.selected_template);
            if (markerList.length !== this.props.userData.booking_block_markers.length) {
                this.setState({
                    deleting_template: true,
                    error: null
                });
                let that = this;
                updateUser(this.props.userData.id, {booking_block_markers: markerList}, (response) => {
                    let state = {
                        saving: false,
                        deleting: false,
                        saving_template: false,
                        deleting_template: false
                    };
                    if (response.errorCode !== undefined) {
                        console.error(response.errorCode + ": " + response.message);
                        that.setState(state);
                    }
                    else {
                        state.selected_template = null;
                        state.show_editor = false;
                        that.setState(state, () => {
                            that.props.onUpdateUserSession?.(response);
                        });
                    }
                });
            }
        }
    }

    onSavePeriod() {
        let start = this.calendar.current.getSelectedStart();
        let end = this.calendar.current.getSelectedEnd();
        if (!start) {
            this.calendar.current.onStartClick();
            this.setState({error: getLanguageEntry("components>booking_free_period_modal>no_dates_error")});
            return;
        }
        if (!end) {
            this.calendar.current.onEndClick();
            this.setState({error: getLanguageEntry("components>booking_free_period_modal>no_dates_error")});
            return;
        }
        let period = this.state.period ? JSON.parse(JSON.stringify(this.state.period)) : {};
        period.start = start.getTime();
        period.end = end.getTime();
        if (this.state.selected_template &&
            this.state.selected_template !== STANDARD_BLOCK_COLOR.value) {
            period.description = this.state.selected_template;
        }
        if (!period.id || period.start !== this.state.period.start ||
            period.end !== this.state.period.end ||period.description !== this.state.period.description) {
            let that = this;
            this.setState({
                saving: true,
                error: null
            });
            addBookingFreePeriod(this.state.advert.id, this.state.room_id,
                period, (response) => {
                    let state = {
                        saving: false,
                        deleting: false,
                        saving_template: false,
                        deleting_template: false
                    };
                    if (response.errorCode) {
                        state.error = response.errorCode + ": " + response.message;
                    }
                    else if (response.data) {
                        that.props.onAdvertUpdate?.(response.data);
                        state.show = false;
                    }
                    that.setState(state);
                });
        }
        else {
            this.hide();
        }
    }

    onDeletePeriod() {
        if (this.state.period && this.state.period.id) {
            this.setState({
                deleting: true,
                error: null
            });
            let that = this;
            deleteBookingFreePeriod(this.state.advert.id, this.state.room_id,
                this.state.period.id, (response) => {
                    let state = {
                        saving: false,
                        deleting: false,
                        saving_template: false,
                        deleting_template: false
                    };
                    if (response.errorCode) {
                        state.error = response.errorCode + ": " + response.message;
                    }
                    else if (response.data) {
                        that.props.onAdvertUpdate?.(response.data);
                        state.show = false;
                    }
                    that.setState(state);
                });
        }
        else {
            this.hide();
        }
    }
}

BookingFreePeriodModal.propTypes = {
    userData: PropTypes.any.isRequired,
    onAdvertUpdate: PropTypes.func.isRequired,
    onUpdateUserSession: PropTypes.func.isRequired
}
BookingFreePeriodModal.defaultProps = {}
export default BookingFreePeriodModal;