import React from "react";
import PropTypes from "prop-types";
import '../../css/pages/search-page.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    BARRIER_FREE,
    calculateRealUnitRent,
    collectHousingListAttributes,
    convertMilliseconds,
    convertMSToDays,
    determineSearchTargets,
    determineTargetHousing,
    filterDataToQuery,
    findSurfaceLimits,
    getLanguageEntry,
    getNextPage,
    PRICE_RANGE_MAXIMUM,
    queryParamsToFilterData,
    SURFACE_RANGE_MAXIMUM,
    unpackAdvert
} from "../utils/Helper";
import {searchAdverts} from "../utils/RESTInterface";
import SearchFilterModal from "../components/modals/SearchFilterModal";
import HousingPreview, {PREVIEW_TYPES} from "../components/HousingPreview";
import _uniqueId from "lodash/uniqueId";
import GoogleMaps from "../components/google/GoogleMaps";
import {Overlay, Tooltip} from "react-bootstrap";
import Calendar from "../components/Calendar";
import {HOUSING_CACHE, SEARCH_CACHE, USER_CACHE} from "../utils/CacheHandler.ts";
import CacheObject from "../utils/cache/objects/CacheObject.ts";
import HousingCacheObject from "../utils/cache/objects/HousingCacheObject.ts";
import ImageViewer from "react-simple-image-viewer";
import {AdvertType} from "../utils/Types.ts";
import FetchButton from "../components/buttons/FetchButton";

const SORTING = {
    rating: "rating",
    price_ascending: "price_ascending",
    price_descending: "price_descending",
    //distance: distance,
    newest_first: "newest_first",
    oldest_first: "oldest_first"
}

class SearchPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_sort: false,
            sorting: null,
            searching: false,
            start: null,
            end: null,
            filter: {},
            response: null,
            result_list: null,
            result_list_matches: [],
            result_count: 0,
            result_per_page: 5,
            page: 0,
            focused_advert: { index: -1, focus: false },
            show_map: false,
            overlay_map: window.innerWidth < 951,
            show_actualize_search_tooltip: false,
            show_image_viewer: false,
            first_image_index: 0,
            images: []
        }
        this.sort_menu = React.createRef();
        this.filter_modal = React.createRef();
        this.calendar = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("resize", () => { this.onSearchResize()});
        let params = new URLSearchParams(window.location.search);
        let filter = queryParamsToFilterData(params);
        if (this.calendar.current !== null) {
            this.calendar.current.setDates(filter.start, filter.end);
        }
        this.triggerSearch(filter);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.page !== this.state.page) {
            document.documentElement.scrollTo({
                top: 0,
                left: 0
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => { this.onSearchResize()});
    }

    render() {
        let visibleResults = this.applySorting();
        let optionalAdverts = visibleResults.length === 0 ? this.pickRandomAdverts() : null;
        return (
            <div id="search-page">
                <div id="search-filter-collapse-header">
                    <div className="search-filter-box-content">
                        <Calendar hAlign="left" ref={this.calendar}>
                            <FetchButton className="outline-button accent" id="actualize-search-in-calendar" loading={this.state.searching}
                                         loadingText={"search_page>searching"} onClick={() => {
                                             this.actualizeSearch();
                                             this.calendar.current.onHide();
                                         }}>
                                <FontAwesomeIcon icon={["fal", "search"]} />
                                <span>{getLanguageEntry("search_page>adjust_search")}</span>
                            </FetchButton>
                        </Calendar>
                        <Overlay show={this.state.show_actualize_search_tooltip} target={this.calendar.current}
                                 placement="bottom">
                            <Tooltip>{getLanguageEntry("search_page>actualize_search_tooltip")}</Tooltip>
                        </Overlay>
                    </div>
                    <button className="outline-button white" id="call-filter-modal" disabled={this.state.searching}
                            onClick={() => { this.showFilterModal() }}>
                        <FontAwesomeIcon icon={["fal", "sliders"]} />
                        <span>{getLanguageEntry("search_page>filtering")}</span>
                    </button>
                    <FetchButton className="outline-button white" id="actualize-search" loading={this.state.searching}
                                 loadingText={"search_page>searching"} onClick={() => { this.actualizeSearch() }}>
                        <FontAwesomeIcon icon={["fal", "search"]} />
                        <span>{getLanguageEntry("search_page>adjust_search")}</span>
                    </FetchButton>
                </div>
                <div id="search-page-body">
                    <div id="search-result-container">
                        {
                            this.state.searching &&
                            <div id="search-loading">
                                <img src="https://roomjack.blob.core.windows.net/roomjack/animations/loading_animation.gif" alt="Roomjack Loading"/>
                            </div>
                        }
                        {
                            !this.state.searching && this.state.result_list &&
                            visibleResults.length > 0 &&
                            <div id="search-result-header">
                                <div>
                                    <div className="description-container">
                                        {
                                            this.state.result_count <= 10 &&
                                            getLanguageEntry("search_page>search_result_count_label" + (this.state.result_count === 1 ? "" : "_plural"))
                                                    .replace('#', '' + this.state.result_count)
                                        }
                                        {
                                            this.state.result_count > 10 && this.state.result_count % 10 > 0 &&
                                            getLanguageEntry("search_page>search_result_count_label_more").replace('#', '' + this.state.result_count)
                                        }
                                    </div>
                                    <div id="search-result-location-label">
                                        {
                                            this.state.filter.location &&
                                            getLanguageEntry("search_page>housings_in").replace('#', this.state.filter.location)
                                        }
                                    </div>
                                </div>
                                <div id="search-sorting">
                                    <button id="search-sort-button" className="outline-button accent" tabIndex="1"
                                            onClick={() => { this.showSortMenu() }}
                                            onBlur={(e) => { this.handleSortMenuBlur(e) } }>
                                        <FontAwesomeIcon icon={["fal", "arrow-down-wide-short"]} />
                                        <span>{getLanguageEntry("search_page>sort_by")}</span>
                                    </button>
                                    <div id="search-sort-container" className={this.state.show_sort ? "show" : ""}
                                         ref={this.sort_menu} onBlur={(e) => {this.handleSortMenuBlur(e)}} tabIndex="1">
                                        {
                                            Object.values(SORTING).map((v, i) => {
                                                return <button key={_uniqueId(v)} onClick={() => {this.setSorting(v)}}>
                                                    {
                                                        this.state.sorting === v &&
                                                        <FontAwesomeIcon icon={["fal", "check"]} />
                                                    }
                                                    {getLanguageEntry("search_page>sorting>" + v)}
                                                </button>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            !this.state.searching && this.state.result_list &&
                             visibleResults.length === 0 &&
                            <div id="no-search-results">
                                <img src="https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear.png"
                                     alt="sad-bear" />
                                <div id="no-search-result-description">
                                    <div id="no-search-result-header">
                                        {getLanguageEntry("search_page>no_results_header")}
                                    </div>
                                    <div id="no-search-result-content">
                                        <p>
                                            {getLanguageEntry("search_page>" + (this.state.result_list.length === 0 ?
                                            "no_results_search" : "no_results_filter"))}
                                        </p>
                                        {
                                            this.state.result_list.length > 0 &&
                                            <p>
                                                {getLanguageEntry("search_page>optional_adverts")}
                                            </p>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            !this.state.searching && this.state.result_list &&
                            visibleResults.length === 0 && optionalAdverts.length > 0 &&
                            <div id="optional-adverts">
                                {
                                    optionalAdverts.map((a, i) => {
                                        return <HousingPreview {...this.props} advert={a}
                                                               linkPath={"expose"}
                                                               key={_uniqueId("optional-advert-" + i)}
                                                               type={PREVIEW_TYPES.optional}
                                                               span={this.state.start !== null && this.state.end !== null ?
                                                                   {start: this.state.start, end: this.state.end} : undefined}
                                                               onMouseOver={(e, a) => { this.setMapCenter(e, a, i) } }
                                                               onMouseLeave={(e, a) => { this.resetMapCenter() }}
                                                               onViewImage={(index, images) => { this.openImageView(index, images)}}/>
                                    })

                                }
                            </div>
                        }
                        {
                            !this.state.searching && this.state.result_list && visibleResults.length > 0 &&
                            <div id="search-result-scroller">
                                {
                                    visibleResults.map((r, i) => {
                                        if (r.gold) {
                                            return <HousingPreview {...this.props} className={"roomjack-container gold"}
                                                                   linkPath={"expose"}
                                                                   key={_uniqueId(r.id + "-" + i)} type={PREVIEW_TYPES.search}
                                                                   advert={r}
                                                                   span={this.state.start !== null && this.state.end !== null ?
                                                                       {start: this.state.start, end: this.state.end} : undefined}
                                                                   onMouseOver={(e, a) => { this.setMapCenter(e, a, i) } }
                                                                   onMouseLeave={(e, a) => { this.resetMapCenter() }}
                                                                   onViewImage={(index, images) => { this.openImageView(index, images)}}/>
                                        }
                                        else {
                                            let colorBoost = r.color_accent !== undefined &&
                                            r.color_accent.payment_intent_id !== undefined ?
                                                " color-boost" : "";
                                            return <HousingPreview {...this.props} className={"roomjack-container" + colorBoost}
                                                                   linkPath={"expose"}
                                                                   key={_uniqueId(r.id + "-" + i)}
                                                                   type={PREVIEW_TYPES.search}
                                                                   advert={r}
                                                                   housing={determineTargetHousing(r)}
                                                                   span={this.state.start !== null && this.state.end !== null ?
                                                                       {start: this.state.start, end: this.state.end} : undefined}
                                                                   onMouseOver={(e, a) => { this.setMapCenter(e, a, i) } }
                                                                   onMouseLeave={(e, a) => { this.resetMapCenter() }}
                                                                   onViewImage={(index, images) => { this.openImageView(index, images)}}/>
                                        }
                                    })
                                }
                            </div>
                        }
                        {
                            !this.state.searching && this.state.result_list &&
                            visibleResults.length > 0 &&
                            this.state.result_count > this.state.result_per_page &&
                            <div className="pagination">
                                <button onClick={() => { this.getNextPage(-1) } } disabled={this.state.page <= 0}>
                                    <FontAwesomeIcon icon={["fal", "chevron-left"]}/>
                                </button>
                                {
                                    Array.from(Array(Math.ceil(this.state.result_count / this.state.result_per_page))).map((_, page) => {
                                        return <button key={"search-result-page-" + page}
                                                       onClick={() => { this.setState({page: page})}}
                                                       className={this.state.page === page ? "selected": ""}>
                                            {page + 1}
                                        </button>
                                    })
                                }
                                <button onClick={() => { this.getNextPage(1) } }
                                    disabled={(this.state.page + 1) * this.state.result_per_page >= this.state.result_count}>
                                    <FontAwesomeIcon icon={["fal", "chevron-right"]}/>
                                </button>
                            </div>
                        }
                    </div>
                    {
                        this.state.overlay_map && this.state.show_map &&
                        <div className="fade-out"></div>
                    }
                    {
                        this.state.overlay_map &&
                        <button className="map-toggle" onClick={() => { this.setState({show_map: !this.state.show_map})}}>
                            <FontAwesomeIcon icon={this.state.show_map ? ["fas", "times"] : ["fal", "map-location-dot"]} />
                            {
                                !this.state.show_map &&
                                <span>{getLanguageEntry("search_page>show_map")}</span>
                            }
                        </button>
                    }
                    <GoogleMaps  className={"search-map-container" + (this.state.overlay_map ? " overlay" + (this.state.show_map ? " show" : "") : "")}
                                 focusedAdvert={this.state.focused_advert} zoom={8}
                                 adverts={visibleResults.length > 0 ? visibleResults : optionalAdverts}/>
                </div>
                <SearchFilterModal onTestFilter={(filterData) => { return this.testFilter(filterData)}}
                                   onApplyFilter={(filterData) => { this.applyFilter(filterData) }}
                                   ref={this.filter_modal}/>
                {
                    this.state.show_image_viewer &&
                    <ImageViewer src={this.state.images} currentIndex={this.state.first_image_index}
                                 closeOnClickOutside={true} disableScroll={true}
                                 onClose={() => {this.setState({show_image_viewer: false})}}
                                 backgroundStyle={{zIndex: 11}}/>
                }
            </div>
        )
    }

    showSortMenu() {
        this.setState({ show_sort: true });
    }

    hideSortMenu() {
        this.setState({ show_sort: false });
    }

    handleSortMenuBlur(e) {
        if (!this.sort_menu.current.contains(e.relatedTarget)) {
            this.hideSortMenu();
        }
    }

    showFilterModal() {
        if (this.filter_modal.current !== null) {
            this.filter_modal.current.show(this.state.filter);
        }
    }

    applyFilter(filterData, resultList) {
        let newState = this.testFilter(filterData, resultList);
        newState.filter = filterData;
        newState.page = 0;
        this.setState(newState);
    }

    testFilter(filterData, resultList) {
        let resultMatch = this.getFilteredResults(filterData, resultList === undefined ? this.state.result_list : resultList);
        return {
            result_list_matches: resultMatch,
            result_count: resultMatch.length
        };
    }

    getFilteredResults(filterData, resultList) {
        let filteredResults = [];
        if (resultList !== null && resultList !== undefined) {
            for (const advert of resultList) {
                let target = determineTargetHousing(advert);
                if (this.doesAdvertTargetMatchFilter(advert, target, filterData)) {
                    filteredResults.push(advert);
                }
            }
        }
        return filteredResults;
    }

    doesAdvertTargetMatchFilter(advert, target, filter) {
        if (advert === null) {
            return false;
        }
        if (target === null || target === undefined) {
            target = advert;
        }
        //region general
        if (filter.location !== undefined && filter.location !== null &&
            filter.location !== '') {
            if (filter.radius !== undefined && filter.position !== undefined) {

            }
            else {
                if (advert.address.city.toLowerCase() !== filter.location.toLowerCase()) {
                    return false;
                }
            }
        }

        if (filter.housing_types !== undefined) {
            if (target.id.startsWith('room')) {
                if (!filter.housing_types.includes(AdvertType.room)) {
                    return false;
                }
            }
            else {
                if (!filter.housing_types.includes(advert.advert_type) &&
                    !filter.housing_types.includes(advert.type)) {
                    return false;
                }
            }
        }

        if (filter.booking_type !== undefined && advert.booking_type !== filter.booking_type) {
            return false;
        }

        let start = this.state.start ? this.state.start : filter.start;
        let end = this.state.end ? this.state.end : filter.end;
        let bookingLength = convertMSToDays(end.getTime() - start.getTime());
        let bookingOffsetDays = convertMilliseconds(start.getTime() - Date.now(), 'day', 'floor');
        let relevantPricing = bookingLength >= 30 ? target.long_term_pricing : target.pricing;
        if (relevantPricing !== undefined) {
            let realDailyPrice = calculateRealUnitRent(start, end, bookingOffsetDays, relevantPricing);
            if (filter.min_price !== undefined && realDailyPrice < filter.min_price) {
                return false;
            }
            if (filter.max_price !== undefined && filter.max_price < PRICE_RANGE_MAXIMUM * 100 &&
                realDailyPrice > filter.max_price) {
                return false;
            }
        }

        if (advert.id !== target.id) {
            if ((filter.min_surface !== undefined &&
                    (target.surface_size === undefined || target.surface_size < filter.min_surface)) ||
                (filter.max_surface !== undefined && filter.max_surface < SURFACE_RANGE_MAXIMUM &&
                    (target.surface_size === undefined || target.surface_size > filter.max_surface))) {
                return false;
            }
        }
        else {
            if (filter.min_surface !== undefined || filter.max_surface !== undefined) {
                let surfaceLimits = findSurfaceLimits(advert.rooms);
                if (surfaceLimits.min === undefined && surfaceLimits.max === undefined) {
                    return false;
                }
                let biggestSurface = surfaceLimits.biggest();
                let smallestSurface = surfaceLimits.smallest();
                if ((filter.min_surface !== undefined && filter.min_surface > biggestSurface) ||
                    (filter.max_surface !== undefined && filter.max_surface < smallestSurface)) {
                    return false;
                }
            }
        }

        if (filter.barrier_free && !advert.characteristics.includes(BARRIER_FREE)) {
            return false;
        }

        if (filter.free_cancellable && advert.cancellation_conditions !== undefined) {
            return false;
        }
        //endregion

        //region equipment
        if (filter.equipment !== undefined) {
            let equipment = collectHousingListAttributes(advert, target).equipment;
            if (!filter.equipment.every(e => equipment.includes(e))) {
                return false;
            }
        }
        //endregion

        //region characteristic
        if (filter.characteristics !== undefined && filter.characteristics.length > 0 &&
            advert.characteristics !== undefined &&
            !advert.characteristics.some(c => filter.characteristics.includes(c))) {
            return false;
        }
        //endregion
        return true;
    }

    getNextPage(direction) {
        this.setState({
                page: getNextPage(this.state.page, direction, Math.ceil(this.state.result_count / this.state.result_per_page))})
    }

    applySorting() {
        let sortedList = this.state.result_list_matches.slice(0);
        switch (this.state.sorting) {
            case SORTING.rating:
                sortedList.sort((a, b) => {
                    if (a.gold) {
                        if (b.gold) {
                            return a.gold - b.gold;
                        }
                        else {
                            return -1;
                        }
                    }
                    if (b.gold) {
                        return 1;
                    }
                    return b.rating - a.rating;
                });
                break;
            case SORTING.price_ascending:
                sortedList.sort((a, b) => {
                    if (a.gold) {
                        if (b.gold) {
                            return a.gold - b.gold;
                        }
                        else {
                            return -1;
                        }
                    }
                    if (b.gold) {
                        return 1;
                    }
                    return a.rent - b.rent;
                });
                break;
            case SORTING.price_descending:
                sortedList.sort((a, b) => {
                    if (a.gold) {
                        if (b.gold) {
                            return a.gold - b.gold;
                        }
                        else {
                            return -1;
                        }
                    }
                    if (b.gold) {
                        return 1;
                    }
                    return b.rent - a.rent;
                });
                break;
            /*case 'sort-by-distance':
                if (searchCriteria.position !== undefined) {
                    results.sort((a, b) => {
                        let aPos = a.data.address.location;
                        let bPos = b.data.address.location;
                        if (aPos === undefined) {
                            if (bPos === undefined) {
                                return 0;
                            }
                            return 1;
                        }
                        if (bPos === undefined) {
                            return -1;
                        }
                        return calculateApproximateDistanceKM(bPos, searchCriteria.position) -
                            calculateApproximateDistanceKM(aPos, searchCriteria.position);
                    });
                }
                break;*/
            case SORTING.newest_first:
                sortedList.sort((a, b) => {
                    if (a.gold) {
                        if (b.gold) {
                            return a.gold - b.gold;
                        }
                        else {
                            return -1;
                        }
                    }
                    if (b.gold) {
                        return 1;
                    }
                    return b.creation_ts - a.creation_ts;
                });
                break;
            case SORTING.oldest_first:
                sortedList.sort((a, b) => {
                    if (a.gold) {
                        if (b.gold) {
                            return a.gold - b.gold;
                        }
                        else {
                            return -1;
                        }
                    }
                    if (b.gold) {
                        return 1;
                    }
                    return a.creation_ts - b.creation_ts;
                });
                break;
            default:
                sortedList.sort((a, b) => {
                    if (a.gold) {
                        if (b.gold) {
                            return a.gold - b.gold;
                        }
                        else {
                            return -1;
                        }
                    }
                    if (b.gold) {
                        return 1;
                    }
                    return a.search_index - b.search_index;
                });
                break;
        }
        let start = this.state.page * this.state.result_per_page;
        let end = (this.state.page + 1) * this.state.result_per_page;
        return sortedList.slice(start, end);
    }

    setSorting(sorting) {
        this.setState({
            show_sort: false,
            sorting: this.state.sorting === sorting ? null : sorting
        });
    }

    pickRandomAdverts() {
        // try to pick 3 random results
        if (this.state.result_list === null) {
            return [];
        }
        let indexes = [];
        for (let i=0; i<this.state.result_list.length; i++) {
            indexes.push(i);
        }
        let randomIndexes = [];
        for (let i=0; i<Math.min(3, indexes.length); i++) {
            let ri = indexes[Math.floor(Math.random() * indexes.length)];
            randomIndexes.push(ri);
            indexes = indexes.filter(i => i !== ri);
        }
        let optionalAdverts = [];
        for (const index of randomIndexes) {
            optionalAdverts.push(this.state.result_list[index]);
        }
        return optionalAdverts;
    }

    setMapCenter(e, advert, index) {
        if (advert.address !== undefined && advert.address.location !== undefined &&
            (this.state.focused_advert.index !== index || !this.state.focused_advert.focus)) {
            this.setState({focused_advert: { index: index, focus: true }});
        }
    }

    resetMapCenter() {
        this.setState({focused_advert: { index: this.state.focused_advert.index, focus: false }});
    }

    onSearchResize() {
        let overlayMap = window.innerWidth < 951;
        if (this.state.overlay_map !== overlayMap) {
            this.setState({overlay_map: overlayMap});
        }
    }

    actualizeSearch() {
        if (this.calendar.current.getSelectedStart() === null) {
            this.calendar.current.onStartClick();
        }
        else if (this.calendar.current.getSelectedEnd() === null) {
            this.calendar.current.onEndClick();
        }
        else if (this.calendar.current.getSelectedStart() === this.state.start &&
            this.calendar.current.getSelectedEnd() === this.state.end) {
            if (!this.state.show_actualize_search_tooltip) {
                this.setState({show_actualize_search_tooltip: true});
                setTimeout(() => {
                    this.setState({show_actualize_search_tooltip: false});
                }, 2000);
            }
        }
        else {
            let filter = this.state.filter;
            filter.start = this.calendar.current.getSelectedStart();
            filter.end = this.calendar.current.getSelectedEnd();
            this.triggerSearch(filter);
        }
    }

    triggerSearch(filter) {
        let searchID = filterDataToQuery(filter);
        window.history.replaceState(null, '', '/search' + searchID);
        let cachedResult = SEARCH_CACHE.getCacheObjectData(searchID);
        if (cachedResult) {
            let state = {
                start: filter.start,
                end: filter.end,
                filter: filter,
                result_list: cachedResult
            }
            let filterMatches = this.testFilter(filter, cachedResult);
            state.result_list_matches = filterMatches.result_list_matches;
            state.result_count = filterMatches.result_count;
            state.sorting = null;
            state.focused_advert = { index: -1, focus: false };
            this.setState(state);
        }
        else {
            this.setState({
                searching: true,
                start: filter.start,
                end: filter.end,
                filter: filter
            });
            let that = this;
            let searchFilter = JSON.parse(JSON.stringify(filter));
            searchFilter.search_from = filter.start.getTime();
            searchFilter.search_to = filter.end.getTime();
            delete searchFilter.start;
            delete searchFilter.end;
            searchAdverts(searchFilter, function(response) {
                let state = {
                    searching: false,
                    result_list: null,
                    response: response,
                    page: 0
                };
                if (response.errorCode !== undefined) {
                    console.error(response.errorCode + ": " + response.message);
                }
                else {
                    for (let housing of response.data.result_list) {
                        unpackAdvert(housing);
                    }
                    let resultList = determineSearchTargets(response.data.result_list);
                    SEARCH_CACHE.addCacheObject(searchID, new CacheObject(resultList));
                    let now = Date.now();
                    for (const result of resultList) {
                        let housingID = result.id;
                        let targetFound = result.search_target;
                        if (!targetFound) {
                            if (result.rooms) {
                                let targetRoom = result.rooms.filter(r => r.search_target)[0];
                                if (targetRoom !== undefined) {
                                    housingID += "SP" + targetRoom.id;
                                    targetFound = true;
                                }
                            }
                        }
                        if (targetFound) {
                            HOUSING_CACHE.addCacheObject(housingID, new HousingCacheObject(result, now));
                        }
                        if (result.owner) {
                            USER_CACHE.addCacheObject(result.owner.id, new CacheObject(result.owner, now));
                        }
                    }
                    let filterMatches = that.testFilter(filter, resultList);
                    state.result_list = resultList;
                    state.result_list_matches = filterMatches.result_list_matches;
                    state.result_count = filterMatches.result_count;
                    state.sorting = null;
                    state.focused_advert = { index: -1, focus: false };
                }
                that.setState(state);
            })
        }
    }

    openImageView(index, images) {
        this.setState({
            show_image_viewer: true,
            first_image_index: index,
            images: images
        })
    }

}
SearchPage.propTypes = {
    callLogin: PropTypes.func.isRequired,
    onUpdateUserData: PropTypes.func.isRequired,
    userData: PropTypes.object
}
export default SearchPage;