import React from "react";
import PropTypes from "prop-types";
import AdvertEditorBlock from "../../../../../components/advert/AdvertEditorBlock";
import AdvertHierarchy from "../../../../../components/advert/AdvertHierarchy";
import {getLanguageEntry} from "../../../../../utils/Helper";
import {AdvertEditorTab, AdvertType} from "../../../../../utils/Types.ts";
import Dropdown from "../../../../../components/input/Dropdown";
import md5 from "md5";
import _uniqueId from "lodash/uniqueId";
import RoomAdderModal from "../../../../../components/modals/RoomAdderModal";
import {ADVERT_SUB_TYPE_MAP, MAX_ROOM_COUNT} from "../../../../../../resources/housings";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SpatialModal from "../../../../../components/modals/SpatialModal";

const LANG_PATH = "jackboard>adverts>advert_creator>";

class AdvertTypeTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            edited_advert: JSON.parse(JSON.stringify(props.advert))
        }
        this.room_adder_modal = React.createRef();
        this.spatial_modal = React.createRef();
        this.new_rooms = new Set();
    }

    extractData() {
        return this.state.edited_advert;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let prevAdvert = JSON.stringify(prevProps.advert);
        let advert = JSON.stringify(this.props.advert);
        if (prevAdvert !== advert && advert.first_publishing) {
            this.new_rooms.clear();
        }
    }

    render() {
        let saved = !!this.props.advert.id;
        return (
            <div className={"editor-tab" + (this.props.disabled ? " disabled" : "")}>
                <AdvertEditorBlock title={"advert_attributes>room_type"}
                                   info={LANG_PATH + "room_type>infobox_description_" + (saved ? "2" : "1")}
                                   description={LANG_PATH + "room_type>box_description"}
                                   invalid={this.props.requirements.advert_type}>
                    {
                        this.props.advert.id && this.state.edited_advert.advert_type &&
                        <div className="description-container">
                            <span>{getLanguageEntry(LANG_PATH + "room_type>advert_room_type")} </span>
                            <span><strong>{getLanguageEntry("advert_attributes>advert_types>" +
                                (this.state.edited_advert.type ?? this.state.edited_advert.advert_type))}</strong>.</span>
                        </div>
                    }
                    {
                        (!this.props.advert.id || !this.props.advert.advert_type) &&
                        <Dropdown items={this.getAdvertTypeItems()} name="advert_type"
                                  invalid={this.props.requirements.advert_type}
                                  onChange={(e, v) => {
                                      let advert = JSON.parse(JSON.stringify(this.state.edited_advert));
                                      advert.type = v;
                                      advert.advert_type = ADVERT_SUB_TYPE_MAP[v];
                                      advert.bookable = true;
                                      advert.rooms_bookable = false;
                                      if (advert.rooms) {
                                          advert.rooms.forEach((r) => {
                                              r.bookable = false;
                                          });
                                      }
                                      this.setState({
                                          edited_advert: advert
                                      }, () => {this.actualizeRequirements()});
                                  }}
                                  initialValue={this.props.advert.type ?? this.props.advert.advert_type}/>
                    }
                </AdvertEditorBlock>
                <AdvertEditorBlock title={LANG_PATH + "room_hierarchy>title"} id="advert-hierarchy-builder"
                                   info={LANG_PATH + "room_hierarchy>infobox_description"}
                                   description={LANG_PATH + "room_hierarchy>box_description"}>
                    {
                        this.state.edited_advert.advert_type &&
                        <AdvertHierarchy advert={this.state.edited_advert} b2b={this.props.userData.b2b}
                                         onAddRoom={() => {this.handleOnAddRoom()}}
                                         deletable={(roomID) => this.new_rooms.has(roomID)}
                                         onDeleteRoom={(roomID) => {this.deleteRoom(roomID)} }
                                         editable={() => true}
                                         onEdit={(housing) => {this.callSpatialModal(housing)}}
                                         onSortChildren={(newOrder) => {this.onSortRooms(newOrder)}}
                                         createContent={(housing) => {return this.createRoomBoxContent(housing)}}/>
                    }
                    {
                        !this.state.edited_advert.advert_type &&
                        <div className="description-container no-advert-type">
                            <FontAwesomeIcon icon={["fal", "exclamation-circle"]}/>
                            {getLanguageEntry(LANG_PATH + "advert_type_missing")}
                        </div>
                    }
                </AdvertEditorBlock>
                <RoomAdderModal ref={this.room_adder_modal} />
                <SpatialModal ref={this.spatial_modal} />
            </div>
        )
    }

    handleOnAddRoom() {
        if (this.state.edited_advert.rooms &&
            this.state.edited_advert.rooms.length >= MAX_ROOM_COUNT) {
            return;
        }
        this.callRoomAdderModal();
        /*if (this.state.edited_advert.rooms && this.state.edited_advert.rooms.length > 0) {
            this.callSpatialModal({
                id: 'room_' + md5(_uniqueId("custom_room@" + new Date())),
                bookable: false,
                contingent: 1
            });
        }
        else {
            this.callRoomAdderModal();
        }*/
    }

    callRoomAdderModal() {
        if (this.room_adder_modal.current) {
            this.room_adder_modal.current.show(this.state.edited_advert, (roomMap) => {
                let roomArray = Object.keys(roomMap).filter((type, _) => roomMap[type] > 0)
                    .map((type, _) => Array(roomMap[type]).fill(type));
                this.addRooms(roomArray.flat(), 1, null, false, null);
            });
        }
    }

    callSpatialModal(housing) {
        if (this.spatial_modal.current) {
            this.spatial_modal.current.show(housing, (roomData) => {
                let advert = JSON.parse(JSON.stringify(this.state.edited_advert));
                if (roomData.id && roomData.id.startsWith("room")) {
                    if (advert.rooms) {
                        let index = advert.rooms.findIndex(r => r.id === roomData.id);
                        if (index < 0) {
                            advert.rooms.push(roomData);
                            this.new_rooms.add(roomData.id);
                        }
                        else {
                            advert.rooms.splice(index, 1, roomData);
                        }
                    }
                    else {
                        advert.rooms = [roomData];
                        this.new_rooms.add(roomData.id);
                    }
                    if (advert.type !== AdvertType.room) {
                        let initialValue = 0;
                        const advertSurface = advert.rooms.reduce((accumulator, currentValue) => accumulator + currentValue.surface_size ?? 0, initialValue)
                        if (advertSurface > 0) {
                            advert.surface_size = Math.max(advert.surface_size ?? 0, advertSurface);
                        }
                    }
                    this.setState({edited_advert: advert}, () => {this.actualizeRequirements()});
                }
                else {
                    if (advert.type !== AdvertType.room) {
                        let initialValue = 0;
                        const advertSurface = advert.rooms ?
                            advert.rooms.reduce((accumulator, currentValue) => accumulator + currentValue.surface_size ?? 0, initialValue) : 0;
                        if (advertSurface > 0) {
                            roomData.surface_size = Math.max(advert.surface_size ?? 0, advertSurface);
                        }
                    }
                    this.setState({edited_advert: roomData}, () => {this.actualizeRequirements()});
                }
            }, this.state.edited_advert.type ?? this.state.edited_advert.advert_type);
        }
    }

    addRooms(roomTypes, contingent, name, bookable, surfaceSize) {
        let advert = JSON.parse(JSON.stringify(this.state.edited_advert));
        let rooms;
        if (!advert.rooms) {
            advert.rooms = [];
        }
        rooms = advert.rooms;
        let id = 'room_';
        let timestamp = new Date();
        for (const roomType of roomTypes) {
            for (let i=0; i<contingent; i++) {
                let room = {
                    id: id + md5(_uniqueId(roomType + "@" + timestamp)),
                    type: roomType,
                    bookable: bookable
                };
                if (name !== null) {
                    room.name = name;
                }
                if (surfaceSize !== null) {
                    room.surface_size = surfaceSize;
                }
                rooms.push(room);
                this.new_rooms.add(room.id);
            }
        }
        this.setState({edited_advert: advert});
    }

    deleteRoom(roomID) {
        let advert = JSON.parse(JSON.stringify(this.state.edited_advert));
        if (advert.rooms) {
            let actualLength = advert.rooms.length;
            advert.rooms = advert.rooms.filter(r => r.id !== roomID);
            if (advert.rooms.length !== actualLength) {
                if (advert.rooms.length === 0) {
                    delete advert.rooms;
                }
                this.new_rooms.delete(roomID);
                this.setState({edited_advert: advert});
            }
        }
    }

    getAdvertTypeItems() {
        let types = Object.values(AdvertType);
        return types.map((type, _) => {
            return {value: type, label: "advert_attributes>advert_types>" + type};
        });
    }

    createRoomBoxContent(housing) {
        return <table>
            <tbody>
            <tr>
                <td><FontAwesomeIcon icon={["fal", "expand-arrows"]} fixedWidth={true} /></td>
                <td>{housing.surface_size ?? "-"} m²</td>
            </tr>
            </tbody>
        </table>;
    }

    actualizeRequirements() {
        if (this.props.checkRequirements) {
            this.props.updateRequirements?.(this.extractData());
        }
    }

    getTabTpe() {
        return AdvertEditorTab.advert_type;
    }

    onSortRooms(newRoomOrder) {
        let advert = {...this.state.edited_advert};
        advert.rooms = newRoomOrder;
        this.setState({edited_advert: advert});
    }

}

AdvertTypeTab.propTypes = {
    advert: PropTypes.any.isRequired,
    userData: PropTypes.any.isRequired,
    updateRequirements: PropTypes.func.isRequired,
    checkRequirements: PropTypes.bool.isRequired,
    requirements: PropTypes.any.isRequired
}
export default AdvertTypeTab;