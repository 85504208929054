import React from "react";
import "../../../css/components/advert/housing-property.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import {getLanguageEntry} from "../../utils/Helper";

function HousingProperty(props) {

    const determineText = () => {
        if (getLanguageEntry(props.name) === props.name) {
            if (typeof props.value === 'string') {
                return props.value;
            }
            else if (props.value.custom_name !== undefined) {
                return props.value.custom_name;
            }
        }
        else {
            if (typeof props.value === 'string') {
                return getLanguageEntry(props.name);
            }
            else if (props.value.type !== undefined) {
                let text = getLanguageEntry(props.name);
                if (props.value.type === 'night_rest_period') {
                    if (props.value.data.start !== undefined) {
                        text += ' ' + getLanguageEntry("general>from").toLowerCase() + ' ' +
                            props.value.data.start;
                    }
                    if (props.value.data.end !== undefined) {
                        text += ' ' + getLanguageEntry("general>to").toLowerCase() + ' ' +
                            props.value.data.end;
                    }
                }
                return text;
            }
        }
        return '';
    }

    return (
        <div className="housing-property">
            <FontAwesomeIcon className="property-icon" icon={props.icon} fixedWidth={true} />
            <div>{determineText()}</div>
            {
                props.onDelete &&
                <button className="attribute-control delete" onClick={() => {props.onDelete()}}>
                    <FontAwesomeIcon icon={["fal", "trash"]}/>
                </button>
            }
        </div>
    )

}

HousingProperty.propTypes = {
    value: PropTypes.any.isRequired,
    icon: PropTypes.arrayOf(PropTypes.string).isRequired,
    name: PropTypes.string,
    onDelete: PropTypes.func
}
export default HousingProperty;