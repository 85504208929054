import React from "react";
import {AdvertEditorTab} from "../../../../../utils/Types.ts";
import {
    getLanguageEntry, isDev,
    MAX_DESCRIPTION_LENGTH,
    MAX_TITLE_LENGTH
} from "../../../../../utils/Helper";
import TextArea from "../../../../../components/input/TextArea";
import HousingProperty from "../../../../../components/advert/HousingProperty";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import AdvertEditorBlock from "../../../../../components/advert/AdvertEditorBlock";
import HouseRuleEditor from "../../../../../components/modals/HouseRuleEditor";
import AIDescriptionGenerator from "../../../../../components/advert/AIDescriptionGenerator";
import AIAssistant from "../../../../../components/AIAssistant";

const LANG_PATH = "jackboard>adverts>advert_creator>";

class DescriptionTab extends React.Component {

    constructor(props) {
        super(props);
        this.title = props.advert.title ?? "";
        this.description = props.advert.description ?? "";
        this.title_input = React.createRef();
        this.description_input = React.createRef();
        this.custom_house_rule = React.createRef();
        this.house_rule_selector = React.createRef();
        this.house_rule_editor = React.createRef();
        this.state = {
            house_rules: props.advert.house_rules,
            house_rule_creator: false,
            house_rule_selector: false,
            show_ai_modal: false,
        }
    }

    extractData() {
        return {
            title: this.title_input.current.value.substring(0, MAX_TITLE_LENGTH),
            description: this.description_input.current.value.substring(0, MAX_DESCRIPTION_LENGTH),
            house_rules: this.state.house_rules
        };
    }

    render() {
        return(
            <div className={"editor-tab" + (this.props.disabled ? " disabled" : "")} style={{position: "relative"}}>
                <AdvertEditorBlock title={"advert_attributes>title"} description={LANG_PATH + "title>box_description"}
                                   info={LANG_PATH + "title>infobox_description"}
                                   invalid={this.props.requirements.title}>
                    <TextArea initialValue={this.title} rows={2} maxLength={MAX_TITLE_LENGTH} ref={this.title_input}
                              id="advert-title-input" invalid={this.props.requirements.title}
                              onChange={() => {this.actualizeRequirements()}} />
                </AdvertEditorBlock>
                <AdvertEditorBlock title={"advert_attributes>description"} description={LANG_PATH + "description>box_description"}
                                   info={LANG_PATH + "description>infobox_description"}
                                   invalid={this.props.requirements.description}>
                    <TextArea initialValue={this.description} maxLength={MAX_DESCRIPTION_LENGTH}
                              ref={this.description_input} invalid={this.props.requirements.description}
                              onChange={() => {this.actualizeRequirements()}} />
                </AdvertEditorBlock>
                <AdvertEditorBlock title={"advert_attributes>house_rules>name"} description={LANG_PATH + "house_rules>box_description"}
                                   info={LANG_PATH + "house_rules>infobox_description"} id="house-rules">

                    {
                        this.state.house_rules && this.state.house_rules.length > 0 &&
                        <div>
                            {
                                this.state.house_rules.map((r, i) => {
                                    return <HousingProperty key={r.type + "-" + i} value={r}
                                                            //onDelete={() => {this.removeHouseRule(r)}}
                                                            icon={["fal", "scroll"]}
                                                            name={"advert_attributes>house_rules>list>" + r.type} />
                                })
                            }
                        </div>
                    }
                    {
                        <button className="outline-button accent"
                                onClick={() => {this.callHouseRuleEditor()}}>
                            <FontAwesomeIcon icon={["fal", "pencil"]} />
                            <span>
                                        {getLanguageEntry(LANG_PATH + "house_rules>edit_house_rules")}
                                    </span>
                        </button>
                    }
                </AdvertEditorBlock>
                <HouseRuleEditor ref={this.house_rule_editor} onSave={(houseRules) => {
                    this.setState({house_rules: houseRules && houseRules.length > 0 ? houseRules : null});
                }} />
                {
                    this.state.show_ai_modal &&
                    <AIDescriptionGenerator advert={this.props.advert}
                        onAccept={(title, desc) => {
                        this.title_input.current.value = title;
                        this.description_input.current.value = desc;
                        this.actualizeRequirements();
                    }} onCancel={() => {this.setState({show_ai_modal: false})}} />
                }
                {
                    isDev() &&
                    <div className="ai-assistant-container">
                        <AIAssistant description={`${LANG_PATH}description>ai_label`}
                                     callback={() => {
                                         this.setState({show_ai_modal: true});
                                     }} />
                    </div>
                }
            </div>
        )
    }

    callHouseRuleEditor() {
        if (this.house_rule_editor.current) {
            this.house_rule_editor.current.show(this.state.house_rules)
        }
    }

    actualizeRequirements() {
        if (this.props.checkRequirements) {
            this.props.updateRequirements?.(this.extractData());
        }
    }

    getTabTpe() {
        return AdvertEditorTab.description;
    }

}
DescriptionTab.propTypes = {
    advert: PropTypes.any.isRequired,
    userData: PropTypes.any.isRequired,
    updateRequirements: PropTypes.func.isRequired,
    checkRequirements: PropTypes.bool.isRequired,
    requirements: PropTypes.any.isRequired
}
export default DescriptionTab;