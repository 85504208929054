export const COUNTRIES = [
    {
        "translations":{
            "br":"áustria",
            "pt":"áustria",
            "nl":"Oostenrijk",
            "hr":"Austrija",
            "fa":"اتریش",
            "de":"Österreich",
            "es":"Austria",
            "fr":"Autriche",
            "ja":"オーストリア",
            "it":"Austria"
        },
        "flag":"https://restcountries.eu/data/aut.svg",
        "name":"Austria",
        "alpha2Code":"AT",
        "callingCodes":[
            "43"
        ]
    },
    {
        "translations":{
            "br":"Alemanha",
            "pt":"Alemanha",
            "nl":"Duitsland",
            "hr":"Njemačka",
            "fa":"آلمان",
            "de":"Deutschland",
            "es":"Alemania",
            "fr":"Allemagne",
            "ja":"ドイツ",
            "it":"Germania"
        },
        "flag":"https://restcountries.eu/data/deu.svg",
        "name":"Germany",
        "alpha2Code":"DE",
        "callingCodes":[
            "49"
        ]
    },
    {
        "translations":{
            "br":"Suíça",
            "pt":"Suíça",
            "nl":"Zwitserland",
            "hr":"Švicarska",
            "fa":"سوئیس",
            "de":"Schweiz",
            "es":"Suiza",
            "fr":"Suisse",
            "ja":"スイス",
            "it":"Svizzera"
        },
        "flag":"https://restcountries.eu/data/che.svg",
        "name":"Switzerland",
        "alpha2Code":"CH",
        "callingCodes":[
            "41"
        ]
    },
    {
        "translations":{
            "br":"Afeganistão",
            "pt":"Afeganistão",
            "nl":"Afghanistan",
            "hr":"Afganistan",
            "fa":"افغانستان",
            "de":"Afghanistan",
            "es":"Afganistán",
            "fr":"Afghanistan",
            "ja":"アフガニスタン",
            "it":"Afghanistan"
        },
        "flag":"https://restcountries.eu/data/afg.svg",
        "name":"Afghanistan",
        "alpha2Code":"AF",
        "callingCodes":[
            "93"
        ]
    },
    {
        "translations":{
            "br":"Ilhas de Aland",
            "pt":"Ilhas de Aland",
            "nl":"Ålandeilanden",
            "hr":"Ålandski otoci",
            "fa":"جزایر الند",
            "de":"Åland",
            "es":"Alandia",
            "fr":"Åland",
            "ja":"オーランド諸島",
            "it":"Isole Aland"
        },
        "flag":"https://restcountries.eu/data/ala.svg",
        "name":"Åland Islands",
        "alpha2Code":"AX",
        "callingCodes":[
            "358"
        ]
    },
    {
        "translations":{
            "br":"Albânia",
            "pt":"Albânia",
            "nl":"Albanië",
            "hr":"Albanija",
            "fa":"آلبانی",
            "de":"Albanien",
            "es":"Albania",
            "fr":"Albanie",
            "ja":"アルバニア",
            "it":"Albania"
        },
        "flag":"https://restcountries.eu/data/alb.svg",
        "name":"Albania",
        "alpha2Code":"AL",
        "callingCodes":[
            "355"
        ]
    },
    {
        "translations":{
            "br":"Argélia",
            "pt":"Argélia",
            "nl":"Algerije",
            "hr":"Alžir",
            "fa":"الجزایر",
            "de":"Algerien",
            "es":"Argelia",
            "fr":"Algérie",
            "ja":"アルジェリア",
            "it":"Algeria"
        },
        "flag":"https://restcountries.eu/data/dza.svg",
        "name":"Algeria",
        "alpha2Code":"DZ",
        "callingCodes":[
            "213"
        ]
    },
    {
        "translations":{
            "br":"Samoa Americana",
            "pt":"Samoa Americana",
            "nl":"Amerikaans Samoa",
            "hr":"Američka Samoa",
            "fa":"ساموآی آمریکا",
            "de":"Amerikanisch-Samoa",
            "es":"Samoa Americana",
            "fr":"Samoa américaines",
            "ja":"アメリカ領サモア",
            "it":"Samoa Americane"
        },
        "flag":"https://restcountries.eu/data/asm.svg",
        "name":"American Samoa",
        "alpha2Code":"AS",
        "callingCodes":[
            "1684"
        ]
    },
    {
        "translations":{
            "br":"Andorra",
            "pt":"Andorra",
            "nl":"Andorra",
            "hr":"Andora",
            "fa":"آندورا",
            "de":"Andorra",
            "es":"Andorra",
            "fr":"Andorre",
            "ja":"アンドラ",
            "it":"Andorra"
        },
        "flag":"https://restcountries.eu/data/and.svg",
        "name":"Andorra",
        "alpha2Code":"AD",
        "callingCodes":[
            "376"
        ]
    },
    {
        "translations":{
            "br":"Angola",
            "pt":"Angola",
            "nl":"Angola",
            "hr":"Angola",
            "fa":"آنگولا",
            "de":"Angola",
            "es":"Angola",
            "fr":"Angola",
            "ja":"アンゴラ",
            "it":"Angola"
        },
        "flag":"https://restcountries.eu/data/ago.svg",
        "name":"Angola",
        "alpha2Code":"AO",
        "callingCodes":[
            "244"
        ]
    },
    {
        "translations":{
            "br":"Anguila",
            "pt":"Anguila",
            "nl":"Anguilla",
            "hr":"Angvila",
            "fa":"آنگویلا",
            "de":"Anguilla",
            "es":"Anguilla",
            "fr":"Anguilla",
            "ja":"アンギラ",
            "it":"Anguilla"
        },
        "flag":"https://restcountries.eu/data/aia.svg",
        "name":"Anguilla",
        "alpha2Code":"AI",
        "callingCodes":[
            "1264"
        ]
    },
    {
        "translations":{
            "br":"Antártida",
            "pt":"Antárctida",
            "nl":"Antarctica",
            "hr":"Antarktika",
            "fa":"جنوبگان",
            "de":"Antarktika",
            "es":"Antártida",
            "fr":"Antarctique",
            "ja":"南極大陸",
            "it":"Antartide"
        },
        "flag":"https://restcountries.eu/data/ata.svg",
        "name":"Antarctica",
        "alpha2Code":"AQ",
        "callingCodes":[
            "672"
        ]
    },
    {
        "translations":{
            "br":"Antígua e Barbuda",
            "pt":"Antígua e Barbuda",
            "nl":"Antigua en Barbuda",
            "hr":"Antigva i Barbuda",
            "fa":"آنتیگوا و باربودا",
            "de":"Antigua und Barbuda",
            "es":"Antigua y Barbuda",
            "fr":"Antigua-et-Barbuda",
            "ja":"アンティグア・バーブーダ",
            "it":"Antigua e Barbuda"
        },
        "flag":"https://restcountries.eu/data/atg.svg",
        "name":"Antigua and Barbuda",
        "alpha2Code":"AG",
        "callingCodes":[
            "1268"
        ]
    },
    {
        "translations":{
            "br":"Argentina",
            "pt":"Argentina",
            "nl":"Argentinië",
            "hr":"Argentina",
            "fa":"آرژانتین",
            "de":"Argentinien",
            "es":"Argentina",
            "fr":"Argentine",
            "ja":"アルゼンチン",
            "it":"Argentina"
        },
        "flag":"https://restcountries.eu/data/arg.svg",
        "name":"Argentina",
        "alpha2Code":"AR",
        "callingCodes":[
            "54"
        ]
    },
    {
        "translations":{
            "br":"Armênia",
            "pt":"Arménia",
            "nl":"Armenië",
            "hr":"Armenija",
            "fa":"ارمنستان",
            "de":"Armenien",
            "es":"Armenia",
            "fr":"Arménie",
            "ja":"アルメニア",
            "it":"Armenia"
        },
        "flag":"https://restcountries.eu/data/arm.svg",
        "name":"Armenia",
        "alpha2Code":"AM",
        "callingCodes":[
            "374"
        ]
    },
    {
        "translations":{
            "br":"Aruba",
            "pt":"Aruba",
            "nl":"Aruba",
            "hr":"Aruba",
            "fa":"آروبا",
            "de":"Aruba",
            "es":"Aruba",
            "fr":"Aruba",
            "ja":"アルバ",
            "it":"Aruba"
        },
        "flag":"https://restcountries.eu/data/abw.svg",
        "name":"Aruba",
        "alpha2Code":"AW",
        "callingCodes":[
            "297"
        ]
    },
    {
        "translations":{
            "br":"Austrália",
            "pt":"Austrália",
            "nl":"Australië",
            "hr":"Australija",
            "fa":"استرالیا",
            "de":"Australien",
            "es":"Australia",
            "fr":"Australie",
            "ja":"オーストラリア",
            "it":"Australia"
        },
        "flag":"https://restcountries.eu/data/aus.svg",
        "name":"Australia",
        "alpha2Code":"AU",
        "callingCodes":[
            "61"
        ]
    },
    {
        "translations":{
            "br":"Azerbaijão",
            "pt":"Azerbaijão",
            "nl":"Azerbeidzjan",
            "hr":"Azerbajdžan",
            "fa":"آذربایجان",
            "de":"Aserbaidschan",
            "es":"Azerbaiyán",
            "fr":"Azerbaïdjan",
            "ja":"アゼルバイジャン",
            "it":"Azerbaijan"
        },
        "flag":"https://restcountries.eu/data/aze.svg",
        "name":"Azerbaijan",
        "alpha2Code":"AZ",
        "callingCodes":[
            "994"
        ]
    },
    {
        "translations":{
            "br":"Bahamas",
            "pt":"Baamas",
            "nl":"Bahama’s",
            "hr":"Bahami",
            "fa":"باهاما",
            "de":"Bahamas",
            "es":"Bahamas",
            "fr":"Bahamas",
            "ja":"バハマ",
            "it":"Bahamas"
        },
        "flag":"https://restcountries.eu/data/bhs.svg",
        "name":"Bahamas",
        "alpha2Code":"BS",
        "callingCodes":[
            "1242"
        ]
    },
    {
        "translations":{
            "br":"Bahrein",
            "pt":"Barém",
            "nl":"Bahrein",
            "hr":"Bahrein",
            "fa":"بحرین",
            "de":"Bahrain",
            "es":"Bahrein",
            "fr":"Bahreïn",
            "ja":"バーレーン",
            "it":"Bahrein"
        },
        "flag":"https://restcountries.eu/data/bhr.svg",
        "name":"Bahrain",
        "alpha2Code":"BH",
        "callingCodes":[
            "973"
        ]
    },
    {
        "translations":{
            "br":"Bangladesh",
            "pt":"Bangladeche",
            "nl":"Bangladesh",
            "hr":"Bangladeš",
            "fa":"بنگلادش",
            "de":"Bangladesch",
            "es":"Bangladesh",
            "fr":"Bangladesh",
            "ja":"バングラデシュ",
            "it":"Bangladesh"
        },
        "flag":"https://restcountries.eu/data/bgd.svg",
        "name":"Bangladesh",
        "alpha2Code":"BD",
        "callingCodes":[
            "880"
        ]
    },
    {
        "translations":{
            "br":"Barbados",
            "pt":"Barbados",
            "nl":"Barbados",
            "hr":"Barbados",
            "fa":"باربادوس",
            "de":"Barbados",
            "es":"Barbados",
            "fr":"Barbade",
            "ja":"バルバドス",
            "it":"Barbados"
        },
        "flag":"https://restcountries.eu/data/brb.svg",
        "name":"Barbados",
        "alpha2Code":"BB",
        "callingCodes":[
            "1246"
        ]
    },
    {
        "translations":{
            "br":"Bielorrússia",
            "pt":"Bielorrússia",
            "nl":"Wit-Rusland",
            "hr":"Bjelorusija",
            "fa":"بلاروس",
            "de":"Weißrussland",
            "es":"Bielorrusia",
            "fr":"Biélorussie",
            "ja":"ベラルーシ",
            "it":"Bielorussia"
        },
        "flag":"https://restcountries.eu/data/blr.svg",
        "name":"Belarus",
        "alpha2Code":"BY",
        "callingCodes":[
            "375"
        ]
    },
    {
        "translations":{
            "br":"Bélgica",
            "pt":"Bélgica",
            "nl":"België",
            "hr":"Belgija",
            "fa":"بلژیک",
            "de":"Belgien",
            "es":"Bélgica",
            "fr":"Belgique",
            "ja":"ベルギー",
            "it":"Belgio"
        },
        "flag":"https://restcountries.eu/data/bel.svg",
        "name":"Belgium",
        "alpha2Code":"BE",
        "callingCodes":[
            "32"
        ]
    },
    {
        "translations":{
            "br":"Belize",
            "pt":"Belize",
            "nl":"Belize",
            "hr":"Belize",
            "fa":"بلیز",
            "de":"Belize",
            "es":"Belice",
            "fr":"Belize",
            "ja":"ベリーズ",
            "it":"Belize"
        },
        "flag":"https://restcountries.eu/data/blz.svg",
        "name":"Belize",
        "alpha2Code":"BZ",
        "callingCodes":[
            "501"
        ]
    },
    {
        "translations":{
            "br":"Benin",
            "pt":"Benim",
            "nl":"Benin",
            "hr":"Benin",
            "fa":"بنین",
            "de":"Benin",
            "es":"Benín",
            "fr":"Bénin",
            "ja":"ベナン",
            "it":"Benin"
        },
        "flag":"https://restcountries.eu/data/ben.svg",
        "name":"Benin",
        "alpha2Code":"BJ",
        "callingCodes":[
            "229"
        ]
    },
    {
        "translations":{
            "br":"Bermudas",
            "pt":"Bermudas",
            "nl":"Bermuda",
            "hr":"Bermudi",
            "fa":"برمودا",
            "de":"Bermuda",
            "es":"Bermudas",
            "fr":"Bermudes",
            "ja":"バミューダ",
            "it":"Bermuda"
        },
        "flag":"https://restcountries.eu/data/bmu.svg",
        "name":"Bermuda",
        "alpha2Code":"BM",
        "callingCodes":[
            "1441"
        ]
    },
    {
        "translations":{
            "br":"Butão",
            "pt":"Butão",
            "nl":"Bhutan",
            "hr":"Butan",
            "fa":"بوتان",
            "de":"Bhutan",
            "es":"Bután",
            "fr":"Bhoutan",
            "ja":"ブータン",
            "it":"Bhutan"
        },
        "flag":"https://restcountries.eu/data/btn.svg",
        "name":"Bhutan",
        "alpha2Code":"BT",
        "callingCodes":[
            "975"
        ]
    },
    {
        "translations":{
            "br":"Bolívia",
            "pt":"Bolívia",
            "nl":"Bolivia",
            "hr":"Bolivija",
            "fa":"بولیوی",
            "de":"Bolivien",
            "es":"Bolivia",
            "fr":"Bolivie",
            "ja":"ボリビア多民族国",
            "it":"Bolivia"
        },
        "flag":"https://restcountries.eu/data/bol.svg",
        "name":"Bolivia (Plurinational State of)",
        "alpha2Code":"BO",
        "callingCodes":[
            "591"
        ]
    },
    {
        "translations":{
            "br":"Bonaire",
            "pt":"Bonaire",
            "fa":"بونیر",
            "de":"Bonaire, Sint Eustatius und Saba",
            "fr":"Bonaire, Saint-Eustache et Saba",
            "it":"Bonaire, Saint-Eustache e Saba"
        },
        "flag":"https://restcountries.eu/data/bes.svg",
        "name":"Bonaire, Sint Eustatius and Saba",
        "alpha2Code":"BQ",
        "callingCodes":[
            "5997"
        ]
    },
    {
        "translations":{
            "br":"Bósnia e Herzegovina",
            "pt":"Bósnia e Herzegovina",
            "nl":"Bosnië en Herzegovina",
            "hr":"Bosna i Hercegovina",
            "fa":"بوسنی و هرزگوین",
            "de":"Bosnien und Herzegowina",
            "es":"Bosnia y Herzegovina",
            "fr":"Bosnie-Herzégovine",
            "ja":"ボスニア・ヘルツェゴビナ",
            "it":"Bosnia ed Erzegovina"
        },
        "flag":"https://restcountries.eu/data/bih.svg",
        "name":"Bosnia and Herzegovina",
        "alpha2Code":"BA",
        "callingCodes":[
            "387"
        ]
    },
    {
        "translations":{
            "br":"Botsuana",
            "pt":"Botsuana",
            "nl":"Botswana",
            "hr":"Bocvana",
            "fa":"بوتسوانا",
            "de":"Botswana",
            "es":"Botswana",
            "fr":"Botswana",
            "ja":"ボツワナ",
            "it":"Botswana"
        },
        "flag":"https://restcountries.eu/data/bwa.svg",
        "name":"Botswana",
        "alpha2Code":"BW",
        "callingCodes":[
            "267"
        ]
    },
    {
        "translations":{
            "br":"Ilha Bouvet",
            "pt":"Ilha Bouvet",
            "nl":"Bouveteiland",
            "hr":"Otok Bouvet",
            "fa":"جزیره بووه",
            "de":"Bouvetinsel",
            "es":"Isla Bouvet",
            "fr":"Île Bouvet",
            "ja":"ブーベ島",
            "it":"Isola Bouvet"
        },
        "flag":"https://restcountries.eu/data/bvt.svg",
        "name":"Bouvet Island",
        "alpha2Code":"BV",
        "callingCodes":[
            ""
        ]
    },
    {
        "translations":{
            "br":"Brasil",
            "pt":"Brasil",
            "nl":"Brazilië",
            "hr":"Brazil",
            "fa":"برزیل",
            "de":"Brasilien",
            "es":"Brasil",
            "fr":"Brésil",
            "ja":"ブラジル",
            "it":"Brasile"
        },
        "flag":"https://restcountries.eu/data/bra.svg",
        "name":"Brazil",
        "alpha2Code":"BR",
        "callingCodes":[
            "55"
        ]
    },
    {
        "translations":{
            "br":"Território Britânico do Oceano íÍdico",
            "pt":"Território Britânico do Oceano Índico",
            "nl":"Britse Gebieden in de Indische Oceaan",
            "hr":"Britanski Indijskooceanski teritorij",
            "fa":"قلمرو بریتانیا در اقیانوس هند",
            "de":"Britisches Territorium im Indischen Ozean",
            "es":"Territorio Británico del Océano Índico",
            "fr":"Territoire britannique de l'océan Indien",
            "ja":"イギリス領インド洋地域",
            "it":"Territorio britannico dell'oceano indiano"
        },
        "flag":"https://restcountries.eu/data/iot.svg",
        "name":"British Indian Ocean Territory",
        "alpha2Code":"IO",
        "callingCodes":[
            "246"
        ]
    },
    {
        "translations":{
            "br":"Ilhas Menores Distantes dos Estados Unidos",
            "pt":"Ilhas Menores Distantes dos Estados Unidos",
            "nl":"Kleine afgelegen eilanden van de Verenigde Staten",
            "hr":"Mali udaljeni otoci SAD-a",
            "fa":"جزایر کوچک حاشیه‌ای ایالات متحده آمریکا",
            "de":"Kleinere Inselbesitzungen der Vereinigten Staaten",
            "es":"Islas Ultramarinas Menores de Estados Unidos",
            "fr":"Îles mineures éloignées des États-Unis",
            "ja":"合衆国領有小離島",
            "it":"Isole minori esterne degli Stati Uniti d'America"
        },
        "flag":"https://restcountries.eu/data/umi.svg",
        "name":"United States Minor Outlying Islands",
        "alpha2Code":"UM",
        "callingCodes":[
            ""
        ]
    },
    {
        "translations":{
            "br":"Ilhas Virgens Britânicas",
            "pt":"Ilhas Virgens Britânicas",
            "nl":"Britse Maagdeneilanden",
            "hr":"Britanski Djevičanski Otoci",
            "fa":"جزایر ویرجین بریتانیا",
            "de":"Britische Jungferninseln",
            "es":"Islas Vírgenes del Reino Unido",
            "fr":"Îles Vierges britanniques",
            "ja":"イギリス領ヴァージン諸島",
            "it":"Isole Vergini Britanniche"
        },
        "flag":"https://restcountries.eu/data/vgb.svg",
        "name":"Virgin Islands (British)",
        "alpha2Code":"VG",
        "callingCodes":[
            "1284"
        ]
    },
    {
        "translations":{
            "br":"Ilhas Virgens Americanas",
            "pt":"Ilhas Virgens Americanas",
            "nl":"Verenigde Staten Maagdeneilanden",
            "fa":"جزایر ویرجین آمریکا",
            "de":"Amerikanische Jungferninseln",
            "es":"Islas Vírgenes de los Estados Unidos",
            "fr":"Îles Vierges des États-Unis",
            "ja":"アメリカ領ヴァージン諸島",
            "it":"Isole Vergini americane"
        },
        "flag":"https://restcountries.eu/data/vir.svg",
        "name":"Virgin Islands (U.S.)",
        "alpha2Code":"VI",
        "callingCodes":[
            "1 340"
        ]
    },
    {
        "translations":{
            "br":"Brunei",
            "pt":"Brunei",
            "nl":"Brunei",
            "hr":"Brunej",
            "fa":"برونئی",
            "de":"Brunei",
            "es":"Brunei",
            "fr":"Brunei",
            "ja":"ブルネイ・ダルサラーム",
            "it":"Brunei"
        },
        "flag":"https://restcountries.eu/data/brn.svg",
        "name":"Brunei Darussalam",
        "alpha2Code":"BN",
        "callingCodes":[
            "673"
        ]
    },
    {
        "translations":{
            "br":"Bulgária",
            "pt":"Bulgária",
            "nl":"Bulgarije",
            "hr":"Bugarska",
            "fa":"بلغارستان",
            "de":"Bulgarien",
            "es":"Bulgaria",
            "fr":"Bulgarie",
            "ja":"ブルガリア",
            "it":"Bulgaria"
        },
        "flag":"https://restcountries.eu/data/bgr.svg",
        "name":"Bulgaria",
        "alpha2Code":"BG",
        "callingCodes":[
            "359"
        ]
    },
    {
        "translations":{
            "br":"Burkina Faso",
            "pt":"Burquina Faso",
            "nl":"Burkina Faso",
            "hr":"Burkina Faso",
            "fa":"بورکینافاسو",
            "de":"Burkina Faso",
            "es":"Burkina Faso",
            "fr":"Burkina Faso",
            "ja":"ブルキナファソ",
            "it":"Burkina Faso"
        },
        "flag":"https://restcountries.eu/data/bfa.svg",
        "name":"Burkina Faso",
        "alpha2Code":"BF",
        "callingCodes":[
            "226"
        ]
    },
    {
        "translations":{
            "br":"Burundi",
            "pt":"Burúndi",
            "nl":"Burundi",
            "hr":"Burundi",
            "fa":"بوروندی",
            "de":"Burundi",
            "es":"Burundi",
            "fr":"Burundi",
            "ja":"ブルンジ",
            "it":"Burundi"
        },
        "flag":"https://restcountries.eu/data/bdi.svg",
        "name":"Burundi",
        "alpha2Code":"BI",
        "callingCodes":[
            "257"
        ]
    },
    {
        "translations":{
            "br":"Camboja",
            "pt":"Camboja",
            "nl":"Cambodja",
            "hr":"Kambodža",
            "fa":"کامبوج",
            "de":"Kambodscha",
            "es":"Camboya",
            "fr":"Cambodge",
            "ja":"カンボジア",
            "it":"Cambogia"
        },
        "flag":"https://restcountries.eu/data/khm.svg",
        "name":"Cambodia",
        "alpha2Code":"KH",
        "callingCodes":[
            "855"
        ]
    },
    {
        "translations":{
            "br":"Camarões",
            "pt":"Camarões",
            "nl":"Kameroen",
            "hr":"Kamerun",
            "fa":"کامرون",
            "de":"Kamerun",
            "es":"Camerún",
            "fr":"Cameroun",
            "ja":"カメルーン",
            "it":"Camerun"
        },
        "flag":"https://restcountries.eu/data/cmr.svg",
        "name":"Cameroon",
        "alpha2Code":"CM",
        "callingCodes":[
            "237"
        ]
    },
    {
        "translations":{
            "br":"Canadá",
            "pt":"Canadá",
            "nl":"Canada",
            "hr":"Kanada",
            "fa":"کانادا",
            "de":"Kanada",
            "es":"Canadá",
            "fr":"Canada",
            "ja":"カナダ",
            "it":"Canada"
        },
        "flag":"https://restcountries.eu/data/can.svg",
        "name":"Canada",
        "alpha2Code":"CA",
        "callingCodes":[
            "1"
        ]
    },
    {
        "translations":{
            "br":"Cabo Verde",
            "pt":"Cabo Verde",
            "nl":"Kaapverdië",
            "hr":"Zelenortska Republika",
            "fa":"کیپ ورد",
            "de":"Kap Verde",
            "es":"Cabo Verde",
            "fr":"Cap Vert",
            "ja":"カーボベルデ",
            "it":"Capo Verde"
        },
        "flag":"https://restcountries.eu/data/cpv.svg",
        "name":"Cabo Verde",
        "alpha2Code":"CV",
        "callingCodes":[
            "238"
        ]
    },
    {
        "translations":{
            "br":"Ilhas Cayman",
            "pt":"Ilhas Caimão",
            "nl":"Caymaneilanden",
            "hr":"Kajmanski otoci",
            "fa":"جزایر کیمن",
            "de":"Kaimaninseln",
            "es":"Islas Caimán",
            "fr":"Îles Caïmans",
            "ja":"ケイマン諸島",
            "it":"Isole Cayman"
        },
        "flag":"https://restcountries.eu/data/cym.svg",
        "name":"Cayman Islands",
        "alpha2Code":"KY",
        "callingCodes":[
            "1345"
        ]
    },
    {
        "translations":{
            "br":"República Centro-Africana",
            "pt":"República Centro-Africana",
            "nl":"Centraal-Afrikaanse Republiek",
            "hr":"Srednjoafrička Republika",
            "fa":"جمهوری آفریقای مرکزی",
            "de":"Zentralafrikanische Republik",
            "es":"República Centroafricana",
            "fr":"République centrafricaine",
            "ja":"中央アフリカ共和国",
            "it":"Repubblica Centrafricana"
        },
        "flag":"https://restcountries.eu/data/caf.svg",
        "name":"Central African Republic",
        "alpha2Code":"CF",
        "callingCodes":[
            "236"
        ]
    },
    {
        "translations":{
            "br":"Chade",
            "pt":"Chade",
            "nl":"Tsjaad",
            "hr":"Čad",
            "fa":"چاد",
            "de":"Tschad",
            "es":"Chad",
            "fr":"Tchad",
            "ja":"チャド",
            "it":"Ciad"
        },
        "flag":"https://restcountries.eu/data/tcd.svg",
        "name":"Chad",
        "alpha2Code":"TD",
        "callingCodes":[
            "235"
        ]
    },
    {
        "translations":{
            "br":"Chile",
            "pt":"Chile",
            "nl":"Chili",
            "hr":"Čile",
            "fa":"شیلی",
            "de":"Chile",
            "es":"Chile",
            "fr":"Chili",
            "ja":"チリ",
            "it":"Cile"
        },
        "flag":"https://restcountries.eu/data/chl.svg",
        "name":"Chile",
        "alpha2Code":"CL",
        "callingCodes":[
            "56"
        ]
    },
    {
        "translations":{
            "br":"China",
            "pt":"China",
            "nl":"China",
            "hr":"Kina",
            "fa":"چین",
            "de":"China",
            "es":"China",
            "fr":"Chine",
            "ja":"中国",
            "it":"Cina"
        },
        "flag":"https://restcountries.eu/data/chn.svg",
        "name":"China",
        "alpha2Code":"CN",
        "callingCodes":[
            "86"
        ]
    },
    {
        "translations":{
            "br":"Ilha Christmas",
            "pt":"Ilha do Natal",
            "nl":"Christmaseiland",
            "hr":"Božićni otok",
            "fa":"جزیره کریسمس",
            "de":"Weihnachtsinsel",
            "es":"Isla de Navidad",
            "fr":"Île Christmas",
            "ja":"クリスマス島",
            "it":"Isola di Natale"
        },
        "flag":"https://restcountries.eu/data/cxr.svg",
        "name":"Christmas Island",
        "alpha2Code":"CX",
        "callingCodes":[
            "61"
        ]
    },
    {
        "translations":{
            "br":"Ilhas Cocos",
            "pt":"Ilhas dos Cocos",
            "nl":"Cocoseilanden",
            "hr":"Kokosovi Otoci",
            "fa":"جزایر کوکوس",
            "de":"Kokosinseln",
            "es":"Islas Cocos o Islas Keeling",
            "fr":"Îles Cocos",
            "ja":"ココス（キーリング）諸島",
            "it":"Isole Cocos e Keeling"
        },
        "flag":"https://restcountries.eu/data/cck.svg",
        "name":"Cocos (Keeling) Islands",
        "alpha2Code":"CC",
        "callingCodes":[
            "61"
        ]
    },
    {
        "translations":{
            "br":"Colômbia",
            "pt":"Colômbia",
            "nl":"Colombia",
            "hr":"Kolumbija",
            "fa":"کلمبیا",
            "de":"Kolumbien",
            "es":"Colombia",
            "fr":"Colombie",
            "ja":"コロンビア",
            "it":"Colombia"
        },
        "flag":"https://restcountries.eu/data/col.svg",
        "name":"Colombia",
        "alpha2Code":"CO",
        "callingCodes":[
            "57"
        ]
    },
    {
        "translations":{
            "br":"Comores",
            "pt":"Comores",
            "nl":"Comoren",
            "hr":"Komori",
            "fa":"کومور",
            "de":"Union der Komoren",
            "es":"Comoras",
            "fr":"Comores",
            "ja":"コモロ",
            "it":"Comore"
        },
        "flag":"https://restcountries.eu/data/com.svg",
        "name":"Comoros",
        "alpha2Code":"KM",
        "callingCodes":[
            "269"
        ]
    },
    {
        "translations":{
            "br":"Congo",
            "pt":"Congo",
            "nl":"Congo [Republiek]",
            "hr":"Kongo",
            "fa":"کنگو",
            "de":"Kongo",
            "es":"Congo",
            "fr":"Congo",
            "ja":"コンゴ共和国",
            "it":"Congo"
        },
        "flag":"https://restcountries.eu/data/cog.svg",
        "name":"Congo",
        "alpha2Code":"CG",
        "callingCodes":[
            "242"
        ]
    },
    {
        "translations":{
            "br":"RD Congo",
            "pt":"RD Congo",
            "nl":"Congo [DRC]",
            "hr":"Kongo, Demokratska Republika",
            "fa":"جمهوری کنگو",
            "de":"Kongo (Dem. Rep.)",
            "es":"Congo (Rep. Dem.)",
            "fr":"Congo (Rép. dém.)",
            "ja":"コンゴ民主共和国",
            "it":"Congo (Rep. Dem.)"
        },
        "flag":"https://restcountries.eu/data/cod.svg",
        "name":"Congo (Democratic Republic of the)",
        "alpha2Code":"CD",
        "callingCodes":[
            "243"
        ]
    },
    {
        "translations":{
            "br":"Ilhas Cook",
            "pt":"Ilhas Cook",
            "nl":"Cookeilanden",
            "hr":"Cookovo Otočje",
            "fa":"جزایر کوک",
            "de":"Cookinseln",
            "es":"Islas Cook",
            "fr":"Îles Cook",
            "ja":"クック諸島",
            "it":"Isole Cook"
        },
        "flag":"https://restcountries.eu/data/cok.svg",
        "name":"Cook Islands",
        "alpha2Code":"CK",
        "callingCodes":[
            "682"
        ]
    },
    {
        "translations":{
            "br":"Costa Rica",
            "pt":"Costa Rica",
            "nl":"Costa Rica",
            "hr":"Kostarika",
            "fa":"کاستاریکا",
            "de":"Costa Rica",
            "es":"Costa Rica",
            "fr":"Costa Rica",
            "ja":"コスタリカ",
            "it":"Costa Rica"
        },
        "flag":"https://restcountries.eu/data/cri.svg",
        "name":"Costa Rica",
        "alpha2Code":"CR",
        "callingCodes":[
            "506"
        ]
    },
    {
        "translations":{
            "br":"Croácia",
            "pt":"Croácia",
            "nl":"Kroatië",
            "hr":"Hrvatska",
            "fa":"کرواسی",
            "de":"Kroatien",
            "es":"Croacia",
            "fr":"Croatie",
            "ja":"クロアチア",
            "it":"Croazia"
        },
        "flag":"https://restcountries.eu/data/hrv.svg",
        "name":"Croatia",
        "alpha2Code":"HR",
        "callingCodes":[
            "385"
        ]
    },
    {
        "translations":{
            "br":"Cuba",
            "pt":"Cuba",
            "nl":"Cuba",
            "hr":"Kuba",
            "fa":"کوبا",
            "de":"Kuba",
            "es":"Cuba",
            "fr":"Cuba",
            "ja":"キューバ",
            "it":"Cuba"
        },
        "flag":"https://restcountries.eu/data/cub.svg",
        "name":"Cuba",
        "alpha2Code":"CU",
        "callingCodes":[
            "53"
        ]
    },
    {
        "translations":{
            "br":"Curaçao",
            "pt":"Curaçao",
            "nl":"Curaçao",
            "fa":"کوراسائو",
            "de":"Curaçao",
            "fr":"Curaçao",
            "it":"Curaçao"
        },
        "flag":"https://restcountries.eu/data/cuw.svg",
        "name":"Curaçao",
        "alpha2Code":"CW",
        "callingCodes":[
            "599"
        ]
    },
    {
        "translations":{
            "br":"Chipre",
            "pt":"Chipre",
            "nl":"Cyprus",
            "hr":"Cipar",
            "fa":"قبرس",
            "de":"Zypern",
            "es":"Chipre",
            "fr":"Chypre",
            "ja":"キプロス",
            "it":"Cipro"
        },
        "flag":"https://restcountries.eu/data/cyp.svg",
        "name":"Cyprus",
        "alpha2Code":"CY",
        "callingCodes":[
            "357"
        ]
    },
    {
        "translations":{
            "br":"República Tcheca",
            "pt":"República Checa",
            "nl":"Tsjechië",
            "hr":"Češka",
            "fa":"جمهوری چک",
            "de":"Tschechische Republik",
            "es":"República Checa",
            "fr":"République tchèque",
            "ja":"チェコ",
            "it":"Repubblica Ceca"
        },
        "flag":"https://restcountries.eu/data/cze.svg",
        "name":"Czech Republic",
        "alpha2Code":"CZ",
        "callingCodes":[
            "420"
        ]
    },
    {
        "translations":{
            "br":"Dinamarca",
            "pt":"Dinamarca",
            "nl":"Denemarken",
            "hr":"Danska",
            "fa":"دانمارک",
            "de":"Dänemark",
            "es":"Dinamarca",
            "fr":"Danemark",
            "ja":"デンマーク",
            "it":"Danimarca"
        },
        "flag":"https://restcountries.eu/data/dnk.svg",
        "name":"Denmark",
        "alpha2Code":"DK",
        "callingCodes":[
            "45"
        ]
    },
    {
        "translations":{
            "br":"Djibuti",
            "pt":"Djibuti",
            "nl":"Djibouti",
            "hr":"Džibuti",
            "fa":"جیبوتی",
            "de":"Dschibuti",
            "es":"Yibuti",
            "fr":"Djibouti",
            "ja":"ジブチ",
            "it":"Gibuti"
        },
        "flag":"https://restcountries.eu/data/dji.svg",
        "name":"Djibouti",
        "alpha2Code":"DJ",
        "callingCodes":[
            "253"
        ]
    },
    {
        "translations":{
            "br":"Dominica",
            "pt":"Dominica",
            "nl":"Dominica",
            "hr":"Dominika",
            "fa":"دومینیکا",
            "de":"Dominica",
            "es":"Dominica",
            "fr":"Dominique",
            "ja":"ドミニカ国",
            "it":"Dominica"
        },
        "flag":"https://restcountries.eu/data/dma.svg",
        "name":"Dominica",
        "alpha2Code":"DM",
        "callingCodes":[
            "1767"
        ]
    },
    {
        "translations":{
            "br":"República Dominicana",
            "pt":"República Dominicana",
            "nl":"Dominicaanse Republiek",
            "hr":"Dominikanska Republika",
            "fa":"جمهوری دومینیکن",
            "de":"Dominikanische Republik",
            "es":"República Dominicana",
            "fr":"République dominicaine",
            "ja":"ドミニカ共和国",
            "it":"Repubblica Dominicana"
        },
        "flag":"https://restcountries.eu/data/dom.svg",
        "name":"Dominican Republic",
        "alpha2Code":"DO",
        "callingCodes":[
            "1809",
            "1829",
            "1849"
        ]
    },
    {
        "translations":{
            "br":"Equador",
            "pt":"Equador",
            "nl":"Ecuador",
            "hr":"Ekvador",
            "fa":"اکوادور",
            "de":"Ecuador",
            "es":"Ecuador",
            "fr":"Équateur",
            "ja":"エクアドル",
            "it":"Ecuador"
        },
        "flag":"https://restcountries.eu/data/ecu.svg",
        "name":"Ecuador",
        "alpha2Code":"EC",
        "callingCodes":[
            "593"
        ]
    },
    {
        "translations":{
            "br":"Egito",
            "pt":"Egipto",
            "nl":"Egypte",
            "hr":"Egipat",
            "fa":"مصر",
            "de":"Ägypten",
            "es":"Egipto",
            "fr":"Égypte",
            "ja":"エジプト",
            "it":"Egitto"
        },
        "flag":"https://restcountries.eu/data/egy.svg",
        "name":"Egypt",
        "alpha2Code":"EG",
        "callingCodes":[
            "20"
        ]
    },
    {
        "translations":{
            "br":"El Salvador",
            "pt":"El Salvador",
            "nl":"El Salvador",
            "hr":"Salvador",
            "fa":"السالوادور",
            "de":"El Salvador",
            "es":"El Salvador",
            "fr":"Salvador",
            "ja":"エルサルバドル",
            "it":"El Salvador"
        },
        "flag":"https://restcountries.eu/data/slv.svg",
        "name":"El Salvador",
        "alpha2Code":"SV",
        "callingCodes":[
            "503"
        ]
    },
    {
        "translations":{
            "br":"Guiné Equatorial",
            "pt":"Guiné Equatorial",
            "nl":"Equatoriaal-Guinea",
            "hr":"Ekvatorijalna Gvineja",
            "fa":"گینه استوایی",
            "de":"Äquatorial-Guinea",
            "es":"Guinea Ecuatorial",
            "fr":"Guinée-Équatoriale",
            "ja":"赤道ギニア",
            "it":"Guinea Equatoriale"
        },
        "flag":"https://restcountries.eu/data/gnq.svg",
        "name":"Equatorial Guinea",
        "alpha2Code":"GQ",
        "callingCodes":[
            "240"
        ]
    },
    {
        "translations":{
            "br":"Eritreia",
            "pt":"Eritreia",
            "nl":"Eritrea",
            "hr":"Eritreja",
            "fa":"اریتره",
            "de":"Eritrea",
            "es":"Eritrea",
            "fr":"Érythrée",
            "ja":"エリトリア",
            "it":"Eritrea"
        },
        "flag":"https://restcountries.eu/data/eri.svg",
        "name":"Eritrea",
        "alpha2Code":"ER",
        "callingCodes":[
            "291"
        ]
    },
    {
        "translations":{
            "br":"Estônia",
            "pt":"Estónia",
            "nl":"Estland",
            "hr":"Estonija",
            "fa":"استونی",
            "de":"Estland",
            "es":"Estonia",
            "fr":"Estonie",
            "ja":"エストニア",
            "it":"Estonia"
        },
        "flag":"https://restcountries.eu/data/est.svg",
        "name":"Estonia",
        "alpha2Code":"EE",
        "callingCodes":[
            "372"
        ]
    },
    {
        "translations":{
            "br":"Etiópia",
            "pt":"Etiópia",
            "nl":"Ethiopië",
            "hr":"Etiopija",
            "fa":"اتیوپی",
            "de":"Äthiopien",
            "es":"Etiopía",
            "fr":"Éthiopie",
            "ja":"エチオピア",
            "it":"Etiopia"
        },
        "flag":"https://restcountries.eu/data/eth.svg",
        "name":"Ethiopia",
        "alpha2Code":"ET",
        "callingCodes":[
            "251"
        ]
    },
    {
        "translations":{
            "br":"Ilhas Malvinas",
            "pt":"Ilhas Falkland",
            "nl":"Falklandeilanden [Islas Malvinas]",
            "hr":"Falklandski Otoci",
            "fa":"جزایر فالکلند",
            "de":"Falklandinseln",
            "es":"Islas Malvinas",
            "fr":"Îles Malouines",
            "ja":"フォークランド（マルビナス）諸島",
            "it":"Isole Falkland o Isole Malvine"
        },
        "flag":"https://restcountries.eu/data/flk.svg",
        "name":"Falkland Islands (Malvinas)",
        "alpha2Code":"FK",
        "callingCodes":[
            "500"
        ]
    },
    {
        "translations":{
            "br":"Ilhas Faroé",
            "pt":"Ilhas Faroé",
            "nl":"Faeröer",
            "hr":"Farski Otoci",
            "fa":"جزایر فارو",
            "de":"Färöer-Inseln",
            "es":"Islas Faroe",
            "fr":"Îles Féroé",
            "ja":"フェロー諸島",
            "it":"Isole Far Oer"
        },
        "flag":"https://restcountries.eu/data/fro.svg",
        "name":"Faroe Islands",
        "alpha2Code":"FO",
        "callingCodes":[
            "298"
        ]
    },
    {
        "translations":{
            "br":"Fiji",
            "pt":"Fiji",
            "nl":"Fiji",
            "hr":"Fiđi",
            "fa":"فیجی",
            "de":"Fidschi",
            "es":"Fiyi",
            "fr":"Fidji",
            "ja":"フィジー",
            "it":"Figi"
        },
        "flag":"https://restcountries.eu/data/fji.svg",
        "name":"Fiji",
        "alpha2Code":"FJ",
        "callingCodes":[
            "679"
        ]
    },
    {
        "translations":{
            "br":"Finlândia",
            "pt":"Finlândia",
            "nl":"Finland",
            "hr":"Finska",
            "fa":"فنلاند",
            "de":"Finnland",
            "es":"Finlandia",
            "fr":"Finlande",
            "ja":"フィンランド",
            "it":"Finlandia"
        },
        "flag":"https://restcountries.eu/data/fin.svg",
        "name":"Finland",
        "alpha2Code":"FI",
        "callingCodes":[
            "358"
        ]
    },
    {
        "translations":{
            "br":"França",
            "pt":"França",
            "nl":"Frankrijk",
            "hr":"Francuska",
            "fa":"فرانسه",
            "de":"Frankreich",
            "es":"Francia",
            "fr":"France",
            "ja":"フランス",
            "it":"Francia"
        },
        "flag":"https://restcountries.eu/data/fra.svg",
        "name":"France",
        "alpha2Code":"FR",
        "callingCodes":[
            "33"
        ]
    },
    {
        "translations":{
            "br":"Guiana Francesa",
            "pt":"Guiana Francesa",
            "nl":"Frans-Guyana",
            "hr":"Francuska Gvajana",
            "fa":"گویان فرانسه",
            "de":"Französisch Guyana",
            "es":"Guayana Francesa",
            "fr":"Guayane",
            "ja":"フランス領ギアナ",
            "it":"Guyana francese"
        },
        "flag":"https://restcountries.eu/data/guf.svg",
        "name":"French Guiana",
        "alpha2Code":"GF",
        "callingCodes":[
            "594"
        ]
    },
    {
        "translations":{
            "br":"Polinésia Francesa",
            "pt":"Polinésia Francesa",
            "nl":"Frans-Polynesië",
            "hr":"Francuska Polinezija",
            "fa":"پلی‌نزی فرانسه",
            "de":"Französisch-Polynesien",
            "es":"Polinesia Francesa",
            "fr":"Polynésie française",
            "ja":"フランス領ポリネシア",
            "it":"Polinesia Francese"
        },
        "flag":"https://restcountries.eu/data/pyf.svg",
        "name":"French Polynesia",
        "alpha2Code":"PF",
        "callingCodes":[
            "689"
        ]
    },
    {
        "translations":{
            "br":"Terras Austrais e Antárticas Francesas",
            "pt":"Terras Austrais e Antárticas Francesas",
            "nl":"Franse Gebieden in de zuidelijke Indische Oceaan",
            "hr":"Francuski južni i antarktički teritoriji",
            "fa":"سرزمین‌های جنوبی و جنوبگانی فرانسه",
            "de":"Französische Süd- und Antarktisgebiete",
            "es":"Tierras Australes y Antárticas Francesas",
            "fr":"Terres australes et antarctiques françaises",
            "ja":"フランス領南方・南極地域",
            "it":"Territori Francesi del Sud"
        },
        "flag":"https://restcountries.eu/data/atf.svg",
        "name":"French Southern Territories",
        "alpha2Code":"TF",
        "callingCodes":[
            ""
        ]
    },
    {
        "translations":{
            "br":"Gabão",
            "pt":"Gabão",
            "nl":"Gabon",
            "hr":"Gabon",
            "fa":"گابن",
            "de":"Gabun",
            "es":"Gabón",
            "fr":"Gabon",
            "ja":"ガボン",
            "it":"Gabon"
        },
        "flag":"https://restcountries.eu/data/gab.svg",
        "name":"Gabon",
        "alpha2Code":"GA",
        "callingCodes":[
            "241"
        ]
    },
    {
        "translations":{
            "br":"Gâmbia",
            "pt":"Gâmbia",
            "nl":"Gambia",
            "hr":"Gambija",
            "fa":"گامبیا",
            "de":"Gambia",
            "es":"Gambia",
            "fr":"Gambie",
            "ja":"ガンビア",
            "it":"Gambia"
        },
        "flag":"https://restcountries.eu/data/gmb.svg",
        "name":"Gambia",
        "alpha2Code":"GM",
        "callingCodes":[
            "220"
        ]
    },
    {
        "translations":{
            "br":"Geórgia",
            "pt":"Geórgia",
            "nl":"Georgië",
            "hr":"Gruzija",
            "fa":"گرجستان",
            "de":"Georgien",
            "es":"Georgia",
            "fr":"Géorgie",
            "ja":"グルジア",
            "it":"Georgia"
        },
        "flag":"https://restcountries.eu/data/geo.svg",
        "name":"Georgia",
        "alpha2Code":"GE",
        "callingCodes":[
            "995"
        ]
    },
    {
        "translations":{
            "br":"Gana",
            "pt":"Gana",
            "nl":"Ghana",
            "hr":"Gana",
            "fa":"غنا",
            "de":"Ghana",
            "es":"Ghana",
            "fr":"Ghana",
            "ja":"ガーナ",
            "it":"Ghana"
        },
        "flag":"https://restcountries.eu/data/gha.svg",
        "name":"Ghana",
        "alpha2Code":"GH",
        "callingCodes":[
            "233"
        ]
    },
    {
        "translations":{
            "br":"Gibraltar",
            "pt":"Gibraltar",
            "nl":"Gibraltar",
            "hr":"Gibraltar",
            "fa":"جبل‌طارق",
            "de":"Gibraltar",
            "es":"Gibraltar",
            "fr":"Gibraltar",
            "ja":"ジブラルタル",
            "it":"Gibilterra"
        },
        "flag":"https://restcountries.eu/data/gib.svg",
        "name":"Gibraltar",
        "alpha2Code":"GI",
        "callingCodes":[
            "350"
        ]
    },
    {
        "translations":{
            "br":"Grécia",
            "pt":"Grécia",
            "nl":"Griekenland",
            "hr":"Grčka",
            "fa":"یونان",
            "de":"Griechenland",
            "es":"Grecia",
            "fr":"Grèce",
            "ja":"ギリシャ",
            "it":"Grecia"
        },
        "flag":"https://restcountries.eu/data/grc.svg",
        "name":"Greece",
        "alpha2Code":"GR",
        "callingCodes":[
            "30"
        ]
    },
    {
        "translations":{
            "br":"Groelândia",
            "pt":"Gronelândia",
            "nl":"Groenland",
            "hr":"Grenland",
            "fa":"گرینلند",
            "de":"Grönland",
            "es":"Groenlandia",
            "fr":"Groenland",
            "ja":"グリーンランド",
            "it":"Groenlandia"
        },
        "flag":"https://restcountries.eu/data/grl.svg",
        "name":"Greenland",
        "alpha2Code":"GL",
        "callingCodes":[
            "299"
        ]
    },
    {
        "translations":{
            "br":"Granada",
            "pt":"Granada",
            "nl":"Grenada",
            "hr":"Grenada",
            "fa":"گرنادا",
            "de":"Grenada",
            "es":"Grenada",
            "fr":"Grenade",
            "ja":"グレナダ",
            "it":"Grenada"
        },
        "flag":"https://restcountries.eu/data/grd.svg",
        "name":"Grenada",
        "alpha2Code":"GD",
        "callingCodes":[
            "1473"
        ]
    },
    {
        "translations":{
            "br":"Guadalupe",
            "pt":"Guadalupe",
            "nl":"Guadeloupe",
            "hr":"Gvadalupa",
            "fa":"جزیره گوادلوپ",
            "de":"Guadeloupe",
            "es":"Guadalupe",
            "fr":"Guadeloupe",
            "ja":"グアドループ",
            "it":"Guadeloupa"
        },
        "flag":"https://restcountries.eu/data/glp.svg",
        "name":"Guadeloupe",
        "alpha2Code":"GP",
        "callingCodes":[
            "590"
        ]
    },
    {
        "translations":{
            "br":"Guam",
            "pt":"Guame",
            "nl":"Guam",
            "hr":"Guam",
            "fa":"گوام",
            "de":"Guam",
            "es":"Guam",
            "fr":"Guam",
            "ja":"グアム",
            "it":"Guam"
        },
        "flag":"https://restcountries.eu/data/gum.svg",
        "name":"Guam",
        "alpha2Code":"GU",
        "callingCodes":[
            "1671"
        ]
    },
    {
        "translations":{
            "br":"Guatemala",
            "pt":"Guatemala",
            "nl":"Guatemala",
            "hr":"Gvatemala",
            "fa":"گواتمالا",
            "de":"Guatemala",
            "es":"Guatemala",
            "fr":"Guatemala",
            "ja":"グアテマラ",
            "it":"Guatemala"
        },
        "flag":"https://restcountries.eu/data/gtm.svg",
        "name":"Guatemala",
        "alpha2Code":"GT",
        "callingCodes":[
            "502"
        ]
    },
    {
        "translations":{
            "br":"Guernsey",
            "pt":"Guernsey",
            "nl":"Guernsey",
            "hr":"Guernsey",
            "fa":"گرنزی",
            "de":"Guernsey",
            "es":"Guernsey",
            "fr":"Guernesey",
            "ja":"ガーンジー",
            "it":"Guernsey"
        },
        "flag":"https://restcountries.eu/data/ggy.svg",
        "name":"Guernsey",
        "alpha2Code":"GG",
        "callingCodes":[
            "44"
        ]
    },
    {
        "translations":{
            "br":"Guiné",
            "pt":"Guiné",
            "nl":"Guinee",
            "hr":"Gvineja",
            "fa":"گینه",
            "de":"Guinea",
            "es":"Guinea",
            "fr":"Guinée",
            "ja":"ギニア",
            "it":"Guinea"
        },
        "flag":"https://restcountries.eu/data/gin.svg",
        "name":"Guinea",
        "alpha2Code":"GN",
        "callingCodes":[
            "224"
        ]
    },
    {
        "translations":{
            "br":"Guiné-Bissau",
            "pt":"Guiné-Bissau",
            "nl":"Guinee-Bissau",
            "hr":"Gvineja Bisau",
            "fa":"گینه بیسائو",
            "de":"Guinea-Bissau",
            "es":"Guinea-Bisáu",
            "fr":"Guinée-Bissau",
            "ja":"ギニアビサウ",
            "it":"Guinea-Bissau"
        },
        "flag":"https://restcountries.eu/data/gnb.svg",
        "name":"Guinea-Bissau",
        "alpha2Code":"GW",
        "callingCodes":[
            "245"
        ]
    },
    {
        "translations":{
            "br":"Guiana",
            "pt":"Guiana",
            "nl":"Guyana",
            "hr":"Gvajana",
            "fa":"گویان",
            "de":"Guyana",
            "es":"Guyana",
            "fr":"Guyane",
            "ja":"ガイアナ",
            "it":"Guyana"
        },
        "flag":"https://restcountries.eu/data/guy.svg",
        "name":"Guyana",
        "alpha2Code":"GY",
        "callingCodes":[
            "592"
        ]
    },
    {
        "translations":{
            "br":"Haiti",
            "pt":"Haiti",
            "nl":"Haïti",
            "hr":"Haiti",
            "fa":"هائیتی",
            "de":"Haiti",
            "es":"Haiti",
            "fr":"Haïti",
            "ja":"ハイチ",
            "it":"Haiti"
        },
        "flag":"https://restcountries.eu/data/hti.svg",
        "name":"Haiti",
        "alpha2Code":"HT",
        "callingCodes":[
            "509"
        ]
    },
    {
        "translations":{
            "br":"Ilha Heard e Ilhas McDonald",
            "pt":"Ilha Heard e Ilhas McDonald",
            "nl":"Heard- en McDonaldeilanden",
            "hr":"Otok Heard i otočje McDonald",
            "fa":"جزیره هرد و جزایر مک‌دونالد",
            "de":"Heard und die McDonaldinseln",
            "es":"Islas Heard y McDonald",
            "fr":"Îles Heard-et-MacDonald",
            "ja":"ハード島とマクドナルド諸島",
            "it":"Isole Heard e McDonald"
        },
        "flag":"https://restcountries.eu/data/hmd.svg",
        "name":"Heard Island and McDonald Islands",
        "alpha2Code":"HM",
        "callingCodes":[
            ""
        ]
    },
    {
        "translations":{
            "br":"Vaticano",
            "pt":"Vaticano",
            "nl":"Heilige Stoel",
            "hr":"Sveta Stolica",
            "fa":"سریر مقدس",
            "de":"Heiliger Stuhl",
            "es":"Santa Sede",
            "fr":"voir Saint",
            "ja":"聖座",
            "it":"Santa Sede"
        },
        "flag":"https://restcountries.eu/data/vat.svg",
        "name":"Holy See",
        "alpha2Code":"VA",
        "callingCodes":[
            "379"
        ]
    },
    {
        "translations":{
            "br":"Honduras",
            "pt":"Honduras",
            "nl":"Honduras",
            "hr":"Honduras",
            "fa":"هندوراس",
            "de":"Honduras",
            "es":"Honduras",
            "fr":"Honduras",
            "ja":"ホンジュラス",
            "it":"Honduras"
        },
        "flag":"https://restcountries.eu/data/hnd.svg",
        "name":"Honduras",
        "alpha2Code":"HN",
        "callingCodes":[
            "504"
        ]
    },
    {
        "translations":{
            "br":"Hong Kong",
            "pt":"Hong Kong",
            "nl":"Hongkong",
            "hr":"Hong Kong",
            "fa":"هنگ‌کنگ",
            "de":"Hong Kong",
            "es":"Hong Kong",
            "fr":"Hong Kong",
            "ja":"香港",
            "it":"Hong Kong"
        },
        "flag":"https://restcountries.eu/data/hkg.svg",
        "name":"Hong Kong",
        "alpha2Code":"HK",
        "callingCodes":[
            "852"
        ]
    },
    {
        "translations":{
            "br":"Hungria",
            "pt":"Hungria",
            "nl":"Hongarije",
            "hr":"Mađarska",
            "fa":"مجارستان",
            "de":"Ungarn",
            "es":"Hungría",
            "fr":"Hongrie",
            "ja":"ハンガリー",
            "it":"Ungheria"
        },
        "flag":"https://restcountries.eu/data/hun.svg",
        "name":"Hungary",
        "alpha2Code":"HU",
        "callingCodes":[
            "36"
        ]
    },
    {
        "translations":{
            "br":"Islândia",
            "pt":"Islândia",
            "nl":"IJsland",
            "hr":"Island",
            "fa":"ایسلند",
            "de":"Island",
            "es":"Islandia",
            "fr":"Islande",
            "ja":"アイスランド",
            "it":"Islanda"
        },
        "flag":"https://restcountries.eu/data/isl.svg",
        "name":"Iceland",
        "alpha2Code":"IS",
        "callingCodes":[
            "354"
        ]
    },
    {
        "translations":{
            "br":"Índia",
            "pt":"Índia",
            "nl":"India",
            "hr":"Indija",
            "fa":"هند",
            "de":"Indien",
            "es":"India",
            "fr":"Inde",
            "ja":"インド",
            "it":"India"
        },
        "flag":"https://restcountries.eu/data/ind.svg",
        "name":"India",
        "alpha2Code":"IN",
        "callingCodes":[
            "91"
        ]
    },
    {
        "translations":{
            "br":"Indonésia",
            "pt":"Indonésia",
            "nl":"Indonesië",
            "hr":"Indonezija",
            "fa":"اندونزی",
            "de":"Indonesien",
            "es":"Indonesia",
            "fr":"Indonésie",
            "ja":"インドネシア",
            "it":"Indonesia"
        },
        "flag":"https://restcountries.eu/data/idn.svg",
        "name":"Indonesia",
        "alpha2Code":"ID",
        "callingCodes":[
            "62"
        ]
    },
    {
        "translations":{
            "br":"Costa do Marfim",
            "pt":"Costa do Marfim",
            "nl":"Ivoorkust",
            "hr":"Obala Bjelokosti",
            "fa":"ساحل عاج",
            "de":"Elfenbeinküste",
            "es":"Costa de Marfil",
            "fr":"Côte d'Ivoire",
            "ja":"コートジボワール",
            "it":"Costa D'Avorio"
        },
        "flag":"https://restcountries.eu/data/civ.svg",
        "name":"Côte d'Ivoire",
        "alpha2Code":"CI",
        "callingCodes":[
            "225"
        ]
    },
    {
        "translations":{
            "br":"Irã",
            "pt":"Irão",
            "nl":"Iran",
            "hr":"Iran",
            "fa":"ایران",
            "de":"Iran",
            "es":"Iran",
            "fr":"Iran",
            "ja":"イラン・イスラム共和国"
        },
        "flag":"https://restcountries.eu/data/irn.svg",
        "name":"Iran (Islamic Republic of)",
        "alpha2Code":"IR",
        "callingCodes":[
            "98"
        ]
    },
    {
        "translations":{
            "br":"Iraque",
            "pt":"Iraque",
            "nl":"Irak",
            "hr":"Irak",
            "fa":"عراق",
            "de":"Irak",
            "es":"Irak",
            "fr":"Irak",
            "ja":"イラク",
            "it":"Iraq"
        },
        "flag":"https://restcountries.eu/data/irq.svg",
        "name":"Iraq",
        "alpha2Code":"IQ",
        "callingCodes":[
            "964"
        ]
    },
    {
        "translations":{
            "br":"Irlanda",
            "pt":"Irlanda",
            "nl":"Ierland",
            "hr":"Irska",
            "fa":"ایرلند",
            "de":"Irland",
            "es":"Irlanda",
            "fr":"Irlande",
            "ja":"アイルランド",
            "it":"Irlanda"
        },
        "flag":"https://restcountries.eu/data/irl.svg",
        "name":"Ireland",
        "alpha2Code":"IE",
        "callingCodes":[
            "353"
        ]
    },
    {
        "translations":{
            "br":"Ilha de Man",
            "pt":"Ilha de Man",
            "nl":"Isle of Man",
            "hr":"Otok Man",
            "fa":"جزیره من",
            "de":"Insel Man",
            "es":"Isla de Man",
            "fr":"Île de Man",
            "ja":"マン島",
            "it":"Isola di Man"
        },
        "flag":"https://restcountries.eu/data/imn.svg",
        "name":"Isle of Man",
        "alpha2Code":"IM",
        "callingCodes":[
            "44"
        ]
    },
    {
        "translations":{
            "br":"Israel",
            "pt":"Israel",
            "nl":"Israël",
            "hr":"Izrael",
            "fa":"اسرائیل",
            "de":"Israel",
            "es":"Israel",
            "fr":"Israël",
            "ja":"イスラエル",
            "it":"Israele"
        },
        "flag":"https://restcountries.eu/data/isr.svg",
        "name":"Israel",
        "alpha2Code":"IL",
        "callingCodes":[
            "972"
        ]
    },
    {
        "translations":{
            "br":"Itália",
            "pt":"Itália",
            "nl":"Italië",
            "hr":"Italija",
            "fa":"ایتالیا",
            "de":"Italien",
            "es":"Italia",
            "fr":"Italie",
            "ja":"イタリア",
            "it":"Italia"
        },
        "flag":"https://restcountries.eu/data/ita.svg",
        "name":"Italy",
        "alpha2Code":"IT",
        "callingCodes":[
            "39"
        ]
    },
    {
        "translations":{
            "br":"Jamaica",
            "pt":"Jamaica",
            "nl":"Jamaica",
            "hr":"Jamajka",
            "fa":"جامائیکا",
            "de":"Jamaika",
            "es":"Jamaica",
            "fr":"Jamaïque",
            "ja":"ジャマイカ",
            "it":"Giamaica"
        },
        "flag":"https://restcountries.eu/data/jam.svg",
        "name":"Jamaica",
        "alpha2Code":"JM",
        "callingCodes":[
            "1876"
        ]
    },
    {
        "translations":{
            "br":"Japão",
            "pt":"Japão",
            "nl":"Japan",
            "hr":"Japan",
            "fa":"ژاپن",
            "de":"Japan",
            "es":"Japón",
            "fr":"Japon",
            "ja":"日本",
            "it":"Giappone"
        },
        "flag":"https://restcountries.eu/data/jpn.svg",
        "name":"Japan",
        "alpha2Code":"JP",
        "callingCodes":[
            "81"
        ]
    },
    {
        "translations":{
            "br":"Jersey",
            "pt":"Jersey",
            "nl":"Jersey",
            "hr":"Jersey",
            "fa":"جرزی",
            "de":"Jersey",
            "es":"Jersey",
            "fr":"Jersey",
            "ja":"ジャージー",
            "it":"Isola di Jersey"
        },
        "flag":"https://restcountries.eu/data/jey.svg",
        "name":"Jersey",
        "alpha2Code":"JE",
        "callingCodes":[
            "44"
        ]
    },
    {
        "translations":{
            "br":"Jordânia",
            "pt":"Jordânia",
            "nl":"Jordanië",
            "hr":"Jordan",
            "fa":"اردن",
            "de":"Jordanien",
            "es":"Jordania",
            "fr":"Jordanie",
            "ja":"ヨルダン",
            "it":"Giordania"
        },
        "flag":"https://restcountries.eu/data/jor.svg",
        "name":"Jordan",
        "alpha2Code":"JO",
        "callingCodes":[
            "962"
        ]
    },
    {
        "translations":{
            "br":"Cazaquistão",
            "pt":"Cazaquistão",
            "nl":"Kazachstan",
            "hr":"Kazahstan",
            "fa":"قزاقستان",
            "de":"Kasachstan",
            "es":"Kazajistán",
            "fr":"Kazakhstan",
            "ja":"カザフスタン",
            "it":"Kazakistan"
        },
        "flag":"https://restcountries.eu/data/kaz.svg",
        "name":"Kazakhstan",
        "alpha2Code":"KZ",
        "callingCodes":[
            "76",
            "77"
        ]
    },
    {
        "translations":{
            "br":"Quênia",
            "pt":"Quénia",
            "nl":"Kenia",
            "hr":"Kenija",
            "fa":"کنیا",
            "de":"Kenia",
            "es":"Kenia",
            "fr":"Kenya",
            "ja":"ケニア",
            "it":"Kenya"
        },
        "flag":"https://restcountries.eu/data/ken.svg",
        "name":"Kenya",
        "alpha2Code":"KE",
        "callingCodes":[
            "254"
        ]
    },
    {
        "translations":{
            "br":"Kiribati",
            "pt":"Quiribáti",
            "nl":"Kiribati",
            "hr":"Kiribati",
            "fa":"کیریباتی",
            "de":"Kiribati",
            "es":"Kiribati",
            "fr":"Kiribati",
            "ja":"キリバス",
            "it":"Kiribati"
        },
        "flag":"https://restcountries.eu/data/kir.svg",
        "name":"Kiribati",
        "alpha2Code":"KI",
        "callingCodes":[
            "686"
        ]
    },
    {
        "translations":{
            "br":"Kuwait",
            "pt":"Kuwait",
            "nl":"Koeweit",
            "hr":"Kuvajt",
            "fa":"کویت",
            "de":"Kuwait",
            "es":"Kuwait",
            "fr":"Koweït",
            "ja":"クウェート",
            "it":"Kuwait"
        },
        "flag":"https://restcountries.eu/data/kwt.svg",
        "name":"Kuwait",
        "alpha2Code":"KW",
        "callingCodes":[
            "965"
        ]
    },
    {
        "translations":{
            "br":"Quirguistão",
            "pt":"Quirguizistão",
            "nl":"Kirgizië",
            "hr":"Kirgistan",
            "fa":"قرقیزستان",
            "de":"Kirgisistan",
            "es":"Kirguizistán",
            "fr":"Kirghizistan",
            "ja":"キルギス",
            "it":"Kirghizistan"
        },
        "flag":"https://restcountries.eu/data/kgz.svg",
        "name":"Kyrgyzstan",
        "alpha2Code":"KG",
        "callingCodes":[
            "996"
        ]
    },
    {
        "translations":{
            "br":"Laos",
            "pt":"Laos",
            "nl":"Laos",
            "hr":"Laos",
            "fa":"لائوس",
            "de":"Laos",
            "es":"Laos",
            "fr":"Laos",
            "ja":"ラオス人民民主共和国",
            "it":"Laos"
        },
        "flag":"https://restcountries.eu/data/lao.svg",
        "name":"Lao People's Democratic Republic",
        "alpha2Code":"LA",
        "callingCodes":[
            "856"
        ]
    },
    {
        "translations":{
            "br":"Letônia",
            "pt":"Letónia",
            "nl":"Letland",
            "hr":"Latvija",
            "fa":"لتونی",
            "de":"Lettland",
            "es":"Letonia",
            "fr":"Lettonie",
            "ja":"ラトビア",
            "it":"Lettonia"
        },
        "flag":"https://restcountries.eu/data/lva.svg",
        "name":"Latvia",
        "alpha2Code":"LV",
        "callingCodes":[
            "371"
        ]
    },
    {
        "translations":{
            "br":"Líbano",
            "pt":"Líbano",
            "nl":"Libanon",
            "hr":"Libanon",
            "fa":"لبنان",
            "de":"Libanon",
            "es":"Líbano",
            "fr":"Liban",
            "ja":"レバノン",
            "it":"Libano"
        },
        "flag":"https://restcountries.eu/data/lbn.svg",
        "name":"Lebanon",
        "alpha2Code":"LB",
        "callingCodes":[
            "961"
        ]
    },
    {
        "translations":{
            "br":"Lesoto",
            "pt":"Lesoto",
            "nl":"Lesotho",
            "hr":"Lesoto",
            "fa":"لسوتو",
            "de":"Lesotho",
            "es":"Lesotho",
            "fr":"Lesotho",
            "ja":"レソト",
            "it":"Lesotho"
        },
        "flag":"https://restcountries.eu/data/lso.svg",
        "name":"Lesotho",
        "alpha2Code":"LS",
        "callingCodes":[
            "266"
        ]
    },
    {
        "translations":{
            "br":"Libéria",
            "pt":"Libéria",
            "nl":"Liberia",
            "hr":"Liberija",
            "fa":"لیبریا",
            "de":"Liberia",
            "es":"Liberia",
            "fr":"Liberia",
            "ja":"リベリア",
            "it":"Liberia"
        },
        "flag":"https://restcountries.eu/data/lbr.svg",
        "name":"Liberia",
        "alpha2Code":"LR",
        "callingCodes":[
            "231"
        ]
    },
    {
        "translations":{
            "br":"Líbia",
            "pt":"Líbia",
            "nl":"Libië",
            "hr":"Libija",
            "fa":"لیبی",
            "de":"Libyen",
            "es":"Libia",
            "fr":"Libye",
            "ja":"リビア",
            "it":"Libia"
        },
        "flag":"https://restcountries.eu/data/lby.svg",
        "name":"Libya",
        "alpha2Code":"LY",
        "callingCodes":[
            "218"
        ]
    },
    {
        "translations":{
            "br":"Liechtenstein",
            "pt":"Listenstaine",
            "nl":"Liechtenstein",
            "hr":"Lihtenštajn",
            "fa":"لیختن‌اشتاین",
            "de":"Liechtenstein",
            "es":"Liechtenstein",
            "fr":"Liechtenstein",
            "ja":"リヒテンシュタイン",
            "it":"Liechtenstein"
        },
        "flag":"https://restcountries.eu/data/lie.svg",
        "name":"Liechtenstein",
        "alpha2Code":"LI",
        "callingCodes":[
            "423"
        ]
    },
    {
        "translations":{
            "br":"Lituânia",
            "pt":"Lituânia",
            "nl":"Litouwen",
            "hr":"Litva",
            "fa":"لیتوانی",
            "de":"Litauen",
            "es":"Lituania",
            "fr":"Lituanie",
            "ja":"リトアニア",
            "it":"Lituania"
        },
        "flag":"https://restcountries.eu/data/ltu.svg",
        "name":"Lithuania",
        "alpha2Code":"LT",
        "callingCodes":[
            "370"
        ]
    },
    {
        "translations":{
            "br":"Luxemburgo",
            "pt":"Luxemburgo",
            "nl":"Luxemburg",
            "hr":"Luksemburg",
            "fa":"لوکزامبورگ",
            "de":"Luxemburg",
            "es":"Luxemburgo",
            "fr":"Luxembourg",
            "ja":"ルクセンブルク",
            "it":"Lussemburgo"
        },
        "flag":"https://restcountries.eu/data/lux.svg",
        "name":"Luxembourg",
        "alpha2Code":"LU",
        "callingCodes":[
            "352"
        ]
    },
    {
        "translations":{
            "br":"Macau",
            "pt":"Macau",
            "nl":"Macao",
            "hr":"Makao",
            "fa":"مکائو",
            "de":"Macao",
            "es":"Macao",
            "fr":"Macao",
            "ja":"マカオ",
            "it":"Macao"
        },
        "flag":"https://restcountries.eu/data/mac.svg",
        "name":"Macao",
        "alpha2Code":"MO",
        "callingCodes":[
            "853"
        ]
    },
    {
        "translations":{
            "br":"Macedônia",
            "pt":"Macedónia",
            "nl":"Macedonië",
            "hr":"Makedonija",
            "fa":"",
            "de":"Mazedonien",
            "es":"Macedonia",
            "fr":"Macédoine",
            "ja":"マケドニア旧ユーゴスラビア共和国",
            "it":"Macedonia"
        },
        "flag":"https://restcountries.eu/data/mkd.svg",
        "name":"Macedonia (the former Yugoslav Republic of)",
        "alpha2Code":"MK",
        "callingCodes":[
            "389"
        ]
    },
    {
        "translations":{
            "br":"Madagascar",
            "pt":"Madagáscar",
            "nl":"Madagaskar",
            "hr":"Madagaskar",
            "fa":"ماداگاسکار",
            "de":"Madagaskar",
            "es":"Madagascar",
            "fr":"Madagascar",
            "ja":"マダガスカル",
            "it":"Madagascar"
        },
        "flag":"https://restcountries.eu/data/mdg.svg",
        "name":"Madagascar",
        "alpha2Code":"MG",
        "callingCodes":[
            "261"
        ]
    },
    {
        "translations":{
            "br":"Malawi",
            "pt":"Malávi",
            "nl":"Malawi",
            "hr":"Malavi",
            "fa":"مالاوی",
            "de":"Malawi",
            "es":"Malawi",
            "fr":"Malawi",
            "ja":"マラウイ",
            "it":"Malawi"
        },
        "flag":"https://restcountries.eu/data/mwi.svg",
        "name":"Malawi",
        "alpha2Code":"MW",
        "callingCodes":[
            "265"
        ]
    },
    {
        "translations":{
            "br":"Malásia",
            "pt":"Malásia",
            "nl":"Maleisië",
            "hr":"Malezija",
            "fa":"مالزی",
            "de":"Malaysia",
            "es":"Malasia",
            "fr":"Malaisie",
            "ja":"マレーシア",
            "it":"Malesia"
        },
        "flag":"https://restcountries.eu/data/mys.svg",
        "name":"Malaysia",
        "alpha2Code":"MY",
        "callingCodes":[
            "60"
        ]
    },
    {
        "translations":{
            "br":"Maldivas",
            "pt":"Maldivas",
            "nl":"Maldiven",
            "hr":"Maldivi",
            "fa":"مالدیو",
            "de":"Malediven",
            "es":"Maldivas",
            "fr":"Maldives",
            "ja":"モルディブ",
            "it":"Maldive"
        },
        "flag":"https://restcountries.eu/data/mdv.svg",
        "name":"Maldives",
        "alpha2Code":"MV",
        "callingCodes":[
            "960"
        ]
    },
    {
        "translations":{
            "br":"Mali",
            "pt":"Mali",
            "nl":"Mali",
            "hr":"Mali",
            "fa":"مالی",
            "de":"Mali",
            "es":"Mali",
            "fr":"Mali",
            "ja":"マリ",
            "it":"Mali"
        },
        "flag":"https://restcountries.eu/data/mli.svg",
        "name":"Mali",
        "alpha2Code":"ML",
        "callingCodes":[
            "223"
        ]
    },
    {
        "translations":{
            "br":"Malta",
            "pt":"Malta",
            "nl":"Malta",
            "hr":"Malta",
            "fa":"مالت",
            "de":"Malta",
            "es":"Malta",
            "fr":"Malte",
            "ja":"マルタ",
            "it":"Malta"
        },
        "flag":"https://restcountries.eu/data/mlt.svg",
        "name":"Malta",
        "alpha2Code":"MT",
        "callingCodes":[
            "356"
        ]
    },
    {
        "translations":{
            "br":"Ilhas Marshall",
            "pt":"Ilhas Marshall",
            "nl":"Marshalleilanden",
            "hr":"Maršalovi Otoci",
            "fa":"جزایر مارشال",
            "de":"Marshallinseln",
            "es":"Islas Marshall",
            "fr":"Îles Marshall",
            "ja":"マーシャル諸島",
            "it":"Isole Marshall"
        },
        "flag":"https://restcountries.eu/data/mhl.svg",
        "name":"Marshall Islands",
        "alpha2Code":"MH",
        "callingCodes":[
            "692"
        ]
    },
    {
        "translations":{
            "br":"Martinica",
            "pt":"Martinica",
            "nl":"Martinique",
            "hr":"Martinique",
            "fa":"مونتسرات",
            "de":"Martinique",
            "es":"Martinica",
            "fr":"Martinique",
            "ja":"マルティニーク",
            "it":"Martinica"
        },
        "flag":"https://restcountries.eu/data/mtq.svg",
        "name":"Martinique",
        "alpha2Code":"MQ",
        "callingCodes":[
            "596"
        ]
    },
    {
        "translations":{
            "br":"Mauritânia",
            "pt":"Mauritânia",
            "nl":"Mauritanië",
            "hr":"Mauritanija",
            "fa":"موریتانی",
            "de":"Mauretanien",
            "es":"Mauritania",
            "fr":"Mauritanie",
            "ja":"モーリタニア",
            "it":"Mauritania"
        },
        "flag":"https://restcountries.eu/data/mrt.svg",
        "name":"Mauritania",
        "alpha2Code":"MR",
        "callingCodes":[
            "222"
        ]
    },
    {
        "translations":{
            "br":"Maurício",
            "pt":"Maurícia",
            "nl":"Mauritius",
            "hr":"Mauricijus",
            "fa":"موریس",
            "de":"Mauritius",
            "es":"Mauricio",
            "fr":"Île Maurice",
            "ja":"モーリシャス",
            "it":"Mauritius"
        },
        "flag":"https://restcountries.eu/data/mus.svg",
        "name":"Mauritius",
        "alpha2Code":"MU",
        "callingCodes":[
            "230"
        ]
    },
    {
        "translations":{
            "br":"Mayotte",
            "pt":"Mayotte",
            "nl":"Mayotte",
            "hr":"Mayotte",
            "fa":"مایوت",
            "de":"Mayotte",
            "es":"Mayotte",
            "fr":"Mayotte",
            "ja":"マヨット",
            "it":"Mayotte"
        },
        "flag":"https://restcountries.eu/data/myt.svg",
        "name":"Mayotte",
        "alpha2Code":"YT",
        "callingCodes":[
            "262"
        ]
    },
    {
        "translations":{
            "br":"México",
            "pt":"México",
            "nl":"Mexico",
            "hr":"Meksiko",
            "fa":"مکزیک",
            "de":"Mexiko",
            "es":"México",
            "fr":"Mexique",
            "ja":"メキシコ",
            "it":"Messico"
        },
        "flag":"https://restcountries.eu/data/mex.svg",
        "name":"Mexico",
        "alpha2Code":"MX",
        "callingCodes":[
            "52"
        ]
    },
    {
        "translations":{
            "br":"Micronésia",
            "pt":"Micronésia",
            "nl":"Micronesië",
            "hr":"Mikronezija",
            "fa":"ایالات فدرال میکرونزی",
            "de":"Mikronesien",
            "es":"Micronesia",
            "fr":"Micronésie",
            "ja":"ミクロネシア連邦",
            "it":"Micronesia"
        },
        "flag":"https://restcountries.eu/data/fsm.svg",
        "name":"Micronesia (Federated States of)",
        "alpha2Code":"FM",
        "callingCodes":[
            "691"
        ]
    },
    {
        "translations":{
            "br":"Moldávia",
            "pt":"Moldávia",
            "nl":"Moldavië",
            "hr":"Moldova",
            "fa":"مولداوی",
            "de":"Moldawie",
            "es":"Moldavia",
            "fr":"Moldavie",
            "ja":"モルドバ共和国",
            "it":"Moldavia"
        },
        "flag":"https://restcountries.eu/data/mda.svg",
        "name":"Moldova (Republic of)",
        "alpha2Code":"MD",
        "callingCodes":[
            "373"
        ]
    },
    {
        "translations":{
            "br":"Mônaco",
            "pt":"Mónaco",
            "nl":"Monaco",
            "hr":"Monako",
            "fa":"موناکو",
            "de":"Monaco",
            "es":"Mónaco",
            "fr":"Monaco",
            "ja":"モナコ",
            "it":"Principato di Monaco"
        },
        "flag":"https://restcountries.eu/data/mco.svg",
        "name":"Monaco",
        "alpha2Code":"MC",
        "callingCodes":[
            "377"
        ]
    },
    {
        "translations":{
            "br":"Mongólia",
            "pt":"Mongólia",
            "nl":"Mongolië",
            "hr":"Mongolija",
            "fa":"مغولستان",
            "de":"Mongolei",
            "es":"Mongolia",
            "fr":"Mongolie",
            "ja":"モンゴル",
            "it":"Mongolia"
        },
        "flag":"https://restcountries.eu/data/mng.svg",
        "name":"Mongolia",
        "alpha2Code":"MN",
        "callingCodes":[
            "976"
        ]
    },
    {
        "translations":{
            "br":"Montenegro",
            "pt":"Montenegro",
            "nl":"Montenegro",
            "hr":"Crna Gora",
            "fa":"مونته‌نگرو",
            "de":"Montenegro",
            "es":"Montenegro",
            "fr":"Monténégro",
            "ja":"モンテネグロ",
            "it":"Montenegro"
        },
        "flag":"https://restcountries.eu/data/mne.svg",
        "name":"Montenegro",
        "alpha2Code":"ME",
        "callingCodes":[
            "382"
        ]
    },
    {
        "translations":{
            "br":"Montserrat",
            "pt":"Monserrate",
            "nl":"Montserrat",
            "hr":"Montserrat",
            "fa":"مایوت",
            "de":"Montserrat",
            "es":"Montserrat",
            "fr":"Montserrat",
            "ja":"モントセラト",
            "it":"Montserrat"
        },
        "flag":"https://restcountries.eu/data/msr.svg",
        "name":"Montserrat",
        "alpha2Code":"MS",
        "callingCodes":[
            "1664"
        ]
    },
    {
        "translations":{
            "br":"Marrocos",
            "pt":"Marrocos",
            "nl":"Marokko",
            "hr":"Maroko",
            "fa":"مراکش",
            "de":"Marokko",
            "es":"Marruecos",
            "fr":"Maroc",
            "ja":"モロッコ",
            "it":"Marocco"
        },
        "flag":"https://restcountries.eu/data/mar.svg",
        "name":"Morocco",
        "alpha2Code":"MA",
        "callingCodes":[
            "212"
        ]
    },
    {
        "translations":{
            "br":"Moçambique",
            "pt":"Moçambique",
            "nl":"Mozambique",
            "hr":"Mozambik",
            "fa":"موزامبیک",
            "de":"Mosambik",
            "es":"Mozambique",
            "fr":"Mozambique",
            "ja":"モザンビーク",
            "it":"Mozambico"
        },
        "flag":"https://restcountries.eu/data/moz.svg",
        "name":"Mozambique",
        "alpha2Code":"MZ",
        "callingCodes":[
            "258"
        ]
    },
    {
        "translations":{
            "br":"Myanmar",
            "pt":"Myanmar",
            "nl":"Myanmar",
            "hr":"Mijanmar",
            "fa":"میانمار",
            "de":"Myanmar",
            "es":"Myanmar",
            "fr":"Myanmar",
            "ja":"ミャンマー",
            "it":"Birmania"
        },
        "flag":"https://restcountries.eu/data/mmr.svg",
        "name":"Myanmar",
        "alpha2Code":"MM",
        "callingCodes":[
            "95"
        ]
    },
    {
        "translations":{
            "br":"Namíbia",
            "pt":"Namíbia",
            "nl":"Namibië",
            "hr":"Namibija",
            "fa":"نامیبیا",
            "de":"Namibia",
            "es":"Namibia",
            "fr":"Namibie",
            "ja":"ナミビア",
            "it":"Namibia"
        },
        "flag":"https://restcountries.eu/data/nam.svg",
        "name":"Namibia",
        "alpha2Code":"NA",
        "callingCodes":[
            "264"
        ]
    },
    {
        "translations":{
            "br":"Nauru",
            "pt":"Nauru",
            "nl":"Nauru",
            "hr":"Nauru",
            "fa":"نائورو",
            "de":"Nauru",
            "es":"Nauru",
            "fr":"Nauru",
            "ja":"ナウル",
            "it":"Nauru"
        },
        "flag":"https://restcountries.eu/data/nru.svg",
        "name":"Nauru",
        "alpha2Code":"NR",
        "callingCodes":[
            "674"
        ]
    },
    {
        "translations":{
            "br":"Nepal",
            "pt":"Nepal",
            "nl":"Nepal",
            "hr":"Nepal",
            "fa":"نپال",
            "de":"Népal",
            "es":"Nepal",
            "fr":"Népal",
            "ja":"ネパール",
            "it":"Nepal"
        },
        "flag":"https://restcountries.eu/data/npl.svg",
        "name":"Nepal",
        "alpha2Code":"NP",
        "callingCodes":[
            "977"
        ]
    },
    {
        "translations":{
            "br":"Holanda",
            "pt":"Países Baixos",
            "nl":"Nederland",
            "hr":"Nizozemska",
            "fa":"پادشاهی هلند",
            "de":"Niederlande",
            "es":"Países Bajos",
            "fr":"Pays-Bas",
            "ja":"オランダ",
            "it":"Paesi Bassi"
        },
        "flag":"https://restcountries.eu/data/nld.svg",
        "name":"Netherlands",
        "alpha2Code":"NL",
        "callingCodes":[
            "31"
        ]
    },
    {
        "translations":{
            "br":"Nova Caledônia",
            "pt":"Nova Caledónia",
            "nl":"Nieuw-Caledonië",
            "hr":"Nova Kaledonija",
            "fa":"کالدونیای جدید",
            "de":"Neukaledonien",
            "es":"Nueva Caledonia",
            "fr":"Nouvelle-Calédonie",
            "ja":"ニューカレドニア",
            "it":"Nuova Caledonia"
        },
        "flag":"https://restcountries.eu/data/ncl.svg",
        "name":"New Caledonia",
        "alpha2Code":"NC",
        "callingCodes":[
            "687"
        ]
    },
    {
        "translations":{
            "br":"Nova Zelândia",
            "pt":"Nova Zelândia",
            "nl":"Nieuw-Zeeland",
            "hr":"Novi Zeland",
            "fa":"نیوزیلند",
            "de":"Neuseeland",
            "es":"Nueva Zelanda",
            "fr":"Nouvelle-Zélande",
            "ja":"ニュージーランド",
            "it":"Nuova Zelanda"
        },
        "flag":"https://restcountries.eu/data/nzl.svg",
        "name":"New Zealand",
        "alpha2Code":"NZ",
        "callingCodes":[
            "64"
        ]
    },
    {
        "translations":{
            "br":"Nicarágua",
            "pt":"Nicarágua",
            "nl":"Nicaragua",
            "hr":"Nikaragva",
            "fa":"نیکاراگوئه",
            "de":"Nicaragua",
            "es":"Nicaragua",
            "fr":"Nicaragua",
            "ja":"ニカラグア",
            "it":"Nicaragua"
        },
        "flag":"https://restcountries.eu/data/nic.svg",
        "name":"Nicaragua",
        "alpha2Code":"NI",
        "callingCodes":[
            "505"
        ]
    },
    {
        "translations":{
            "br":"Níger",
            "pt":"Níger",
            "nl":"Niger",
            "hr":"Niger",
            "fa":"نیجر",
            "de":"Niger",
            "es":"Níger",
            "fr":"Niger",
            "ja":"ニジェール",
            "it":"Niger"
        },
        "flag":"https://restcountries.eu/data/ner.svg",
        "name":"Niger",
        "alpha2Code":"NE",
        "callingCodes":[
            "227"
        ]
    },
    {
        "translations":{
            "br":"Nigéria",
            "pt":"Nigéria",
            "nl":"Nigeria",
            "hr":"Nigerija",
            "fa":"نیجریه",
            "de":"Nigeria",
            "es":"Nigeria",
            "fr":"Nigéria",
            "ja":"ナイジェリア",
            "it":"Nigeria"
        },
        "flag":"https://restcountries.eu/data/nga.svg",
        "name":"Nigeria",
        "alpha2Code":"NG",
        "callingCodes":[
            "234"
        ]
    },
    {
        "translations":{
            "br":"Niue",
            "pt":"Niue",
            "nl":"Niue",
            "hr":"Niue",
            "fa":"نیووی",
            "de":"Niue",
            "es":"Niue",
            "fr":"Niue",
            "ja":"ニウエ",
            "it":"Niue"
        },
        "flag":"https://restcountries.eu/data/niu.svg",
        "name":"Niue",
        "alpha2Code":"NU",
        "callingCodes":[
            "683"
        ]
    },
    {
        "translations":{
            "br":"Ilha Norfolk",
            "pt":"Ilha Norfolk",
            "nl":"Norfolkeiland",
            "hr":"Otok Norfolk",
            "fa":"جزیره نورفک",
            "de":"Norfolkinsel",
            "es":"Isla de Norfolk",
            "fr":"Île de Norfolk",
            "ja":"ノーフォーク島",
            "it":"Isola Norfolk"
        },
        "flag":"https://restcountries.eu/data/nfk.svg",
        "name":"Norfolk Island",
        "alpha2Code":"NF",
        "callingCodes":[
            "672"
        ]
    },
    {
        "translations":{
            "br":"Coreia do Norte",
            "pt":"Coreia do Norte",
            "nl":"Noord-Korea",
            "hr":"Sjeverna Koreja",
            "fa":"کره جنوبی",
            "de":"Nordkorea",
            "es":"Corea del Norte",
            "fr":"Corée du Nord",
            "ja":"朝鮮民主主義人民共和国",
            "it":"Corea del Nord"
        },
        "flag":"https://restcountries.eu/data/prk.svg",
        "name":"Korea (Democratic People's Republic of)",
        "alpha2Code":"KP",
        "callingCodes":[
            "850"
        ]
    },
    {
        "translations":{
            "br":"Ilhas Marianas",
            "pt":"Ilhas Marianas",
            "nl":"Noordelijke Marianeneilanden",
            "hr":"Sjevernomarijanski otoci",
            "fa":"جزایر ماریانای شمالی",
            "de":"Nördliche Marianen",
            "es":"Islas Marianas del Norte",
            "fr":"Îles Mariannes du Nord",
            "ja":"北マリアナ諸島",
            "it":"Isole Marianne Settentrionali"
        },
        "flag":"https://restcountries.eu/data/mnp.svg",
        "name":"Northern Mariana Islands",
        "alpha2Code":"MP",
        "callingCodes":[
            "1670"
        ]
    },
    {
        "translations":{
            "br":"Noruega",
            "pt":"Noruega",
            "nl":"Noorwegen",
            "hr":"Norveška",
            "fa":"نروژ",
            "de":"Norwegen",
            "es":"Noruega",
            "fr":"Norvège",
            "ja":"ノルウェー",
            "it":"Norvegia"
        },
        "flag":"https://restcountries.eu/data/nor.svg",
        "name":"Norway",
        "alpha2Code":"NO",
        "callingCodes":[
            "47"
        ]
    },
    {
        "translations":{
            "br":"Omã",
            "pt":"Omã",
            "nl":"Oman",
            "hr":"Oman",
            "fa":"عمان",
            "de":"Oman",
            "es":"Omán",
            "fr":"Oman",
            "ja":"オマーン",
            "it":"oman"
        },
        "flag":"https://restcountries.eu/data/omn.svg",
        "name":"Oman",
        "alpha2Code":"OM",
        "callingCodes":[
            "968"
        ]
    },
    {
        "translations":{
            "br":"Paquistão",
            "pt":"Paquistão",
            "nl":"Pakistan",
            "hr":"Pakistan",
            "fa":"پاکستان",
            "de":"Pakistan",
            "es":"Pakistán",
            "fr":"Pakistan",
            "ja":"パキスタン",
            "it":"Pakistan"
        },
        "flag":"https://restcountries.eu/data/pak.svg",
        "name":"Pakistan",
        "alpha2Code":"PK",
        "callingCodes":[
            "92"
        ]
    },
    {
        "translations":{
            "br":"Palau",
            "pt":"Palau",
            "nl":"Palau",
            "hr":"Palau",
            "fa":"پالائو",
            "de":"Palau",
            "es":"Palau",
            "fr":"Palaos",
            "ja":"パラオ",
            "it":"Palau"
        },
        "flag":"https://restcountries.eu/data/plw.svg",
        "name":"Palau",
        "alpha2Code":"PW",
        "callingCodes":[
            "680"
        ]
    },
    {
        "translations":{
            "br":"Palestina",
            "pt":"Palestina",
            "nl":"Palestijnse gebieden",
            "hr":"Palestina",
            "fa":"فلسطین",
            "de":"Palästina",
            "es":"Palestina",
            "fr":"Palestine",
            "ja":"パレスチナ",
            "it":"Palestina"
        },
        "flag":"https://restcountries.eu/data/pse.svg",
        "name":"Palestine, State of",
        "alpha2Code":"PS",
        "callingCodes":[
            "970"
        ]
    },
    {
        "translations":{
            "br":"Panamá",
            "pt":"Panamá",
            "nl":"Panama",
            "hr":"Panama",
            "fa":"پاناما",
            "de":"Panama",
            "es":"Panamá",
            "fr":"Panama",
            "ja":"パナマ",
            "it":"Panama"
        },
        "flag":"https://restcountries.eu/data/pan.svg",
        "name":"Panama",
        "alpha2Code":"PA",
        "callingCodes":[
            "507"
        ]
    },
    {
        "translations":{
            "br":"Papua Nova Guiné",
            "pt":"Papua Nova Guiné",
            "nl":"Papoea-Nieuw-Guinea",
            "hr":"Papua Nova Gvineja",
            "fa":"پاپوآ گینه نو",
            "de":"Papua-Neuguinea",
            "es":"Papúa Nueva Guinea",
            "fr":"Papouasie-Nouvelle-Guinée",
            "ja":"パプアニューギニア",
            "it":"Papua Nuova Guinea"
        },
        "flag":"https://restcountries.eu/data/png.svg",
        "name":"Papua New Guinea",
        "alpha2Code":"PG",
        "callingCodes":[
            "675"
        ]
    },
    {
        "translations":{
            "br":"Paraguai",
            "pt":"Paraguai",
            "nl":"Paraguay",
            "hr":"Paragvaj",
            "fa":"پاراگوئه",
            "de":"Paraguay",
            "es":"Paraguay",
            "fr":"Paraguay",
            "ja":"パラグアイ",
            "it":"Paraguay"
        },
        "flag":"https://restcountries.eu/data/pry.svg",
        "name":"Paraguay",
        "alpha2Code":"PY",
        "callingCodes":[
            "595"
        ]
    },
    {
        "translations":{
            "br":"Peru",
            "pt":"Peru",
            "nl":"Peru",
            "hr":"Peru",
            "fa":"پرو",
            "de":"Peru",
            "es":"Perú",
            "fr":"Pérou",
            "ja":"ペルー",
            "it":"Perù"
        },
        "flag":"https://restcountries.eu/data/per.svg",
        "name":"Peru",
        "alpha2Code":"PE",
        "callingCodes":[
            "51"
        ]
    },
    {
        "translations":{
            "br":"Filipinas",
            "pt":"Filipinas",
            "nl":"Filipijnen",
            "hr":"Filipini",
            "fa":"جزایر الندفیلیپین",
            "de":"Philippinen",
            "es":"Filipinas",
            "fr":"Philippines",
            "ja":"フィリピン",
            "it":"Filippine"
        },
        "flag":"https://restcountries.eu/data/phl.svg",
        "name":"Philippines",
        "alpha2Code":"PH",
        "callingCodes":[
            "63"
        ]
    },
    {
        "translations":{
            "br":"Ilhas Pitcairn",
            "pt":"Ilhas Picárnia",
            "nl":"Pitcairneilanden",
            "hr":"Pitcairnovo otočje",
            "fa":"پیتکرن",
            "de":"Pitcairn",
            "es":"Islas Pitcairn",
            "fr":"Îles Pitcairn",
            "ja":"ピトケアン",
            "it":"Isole Pitcairn"
        },
        "flag":"https://restcountries.eu/data/pcn.svg",
        "name":"Pitcairn",
        "alpha2Code":"PN",
        "callingCodes":[
            "64"
        ]
    },
    {
        "translations":{
            "br":"Polônia",
            "pt":"Polónia",
            "nl":"Polen",
            "hr":"Poljska",
            "fa":"لهستان",
            "de":"Polen",
            "es":"Polonia",
            "fr":"Pologne",
            "ja":"ポーランド",
            "it":"Polonia"
        },
        "flag":"https://restcountries.eu/data/pol.svg",
        "name":"Poland",
        "alpha2Code":"PL",
        "callingCodes":[
            "48"
        ]
    },
    {
        "translations":{
            "br":"Portugal",
            "pt":"Portugal",
            "nl":"Portugal",
            "hr":"Portugal",
            "fa":"پرتغال",
            "de":"Portugal",
            "es":"Portugal",
            "fr":"Portugal",
            "ja":"ポルトガル",
            "it":"Portogallo"
        },
        "flag":"https://restcountries.eu/data/prt.svg",
        "name":"Portugal",
        "alpha2Code":"PT",
        "callingCodes":[
            "351"
        ]
    },
    {
        "translations":{
            "br":"Porto Rico",
            "pt":"Porto Rico",
            "nl":"Puerto Rico",
            "hr":"Portoriko",
            "fa":"پورتو ریکو",
            "de":"Puerto Rico",
            "es":"Puerto Rico",
            "fr":"Porto Rico",
            "ja":"プエルトリコ",
            "it":"Porto Rico"
        },
        "flag":"https://restcountries.eu/data/pri.svg",
        "name":"Puerto Rico",
        "alpha2Code":"PR",
        "callingCodes":[
            "1787",
            "1939"
        ]
    },
    {
        "translations":{
            "br":"Catar",
            "pt":"Catar",
            "nl":"Qatar",
            "hr":"Katar",
            "fa":"قطر",
            "de":"Katar",
            "es":"Catar",
            "fr":"Qatar",
            "ja":"カタール",
            "it":"Qatar"
        },
        "flag":"https://restcountries.eu/data/qat.svg",
        "name":"Qatar",
        "alpha2Code":"QA",
        "callingCodes":[
            "974"
        ]
    },
    {
        "translations":{
            "br":"Kosovo",
            "pt":"Kosovo",
            "hr":"Kosovo",
            "fa":"کوزوو",
            "es":"Kosovo",
            "de":"Kosovo"
        },
        "flag":"https://restcountries.eu/data/kos.svg",
        "name":"Republic of Kosovo",
        "alpha2Code":"XK",
        "callingCodes":[
            "383"
        ]
    },
    {
        "translations":{
            "br":"Reunião",
            "pt":"Reunião",
            "nl":"Réunion",
            "hr":"Réunion",
            "fa":"رئونیون",
            "de":"Réunion",
            "es":"Reunión",
            "fr":"Réunion",
            "ja":"レユニオン",
            "it":"Riunione"
        },
        "flag":"https://restcountries.eu/data/reu.svg",
        "name":"Réunion",
        "alpha2Code":"RE",
        "callingCodes":[
            "262"
        ]
    },
    {
        "translations":{
            "br":"Romênia",
            "pt":"Roménia",
            "nl":"Roemenië",
            "hr":"Rumunjska",
            "fa":"رومانی",
            "de":"Rumänien",
            "es":"Rumania",
            "fr":"Roumanie",
            "ja":"ルーマニア",
            "it":"Romania"
        },
        "flag":"https://restcountries.eu/data/rou.svg",
        "name":"Romania",
        "alpha2Code":"RO",
        "callingCodes":[
            "40"
        ]
    },
    {
        "translations":{
            "br":"Rússia",
            "pt":"Rússia",
            "nl":"Rusland",
            "hr":"Rusija",
            "fa":"روسیه",
            "de":"Russland",
            "es":"Rusia",
            "fr":"Russie",
            "ja":"ロシア連邦",
            "it":"Russia"
        },
        "flag":"https://restcountries.eu/data/rus.svg",
        "name":"Russian Federation",
        "alpha2Code":"RU",
        "callingCodes":[
            "7"
        ]
    },
    {
        "translations":{
            "br":"Ruanda",
            "pt":"Ruanda",
            "nl":"Rwanda",
            "hr":"Ruanda",
            "fa":"رواندا",
            "de":"Ruanda",
            "es":"Ruanda",
            "fr":"Rwanda",
            "ja":"ルワンダ",
            "it":"Ruanda"
        },
        "flag":"https://restcountries.eu/data/rwa.svg",
        "name":"Rwanda",
        "alpha2Code":"RW",
        "callingCodes":[
            "250"
        ]
    },
    {
        "translations":{
            "br":"São Bartolomeu",
            "pt":"São Bartolomeu",
            "nl":"Saint Barthélemy",
            "hr":"Saint Barthélemy",
            "fa":"سن-بارتلمی",
            "de":"Saint-Barthélemy",
            "es":"San Bartolomé",
            "fr":"Saint-Barthélemy",
            "ja":"サン・バルテルミー",
            "it":"Antille Francesi"
        },
        "flag":"https://restcountries.eu/data/blm.svg",
        "name":"Saint Barthélemy",
        "alpha2Code":"BL",
        "callingCodes":[
            "590"
        ]
    },
    {
        "translations":{
            "br":"Santa Helena",
            "pt":"Santa Helena",
            "nl":"Sint-Helena",
            "hr":"Sveta Helena",
            "fa":"سنت هلنا، اسنشن و تریستان دا کونا",
            "de":"Sankt Helena",
            "es":"Santa Helena",
            "fr":"Sainte-Hélène",
            "ja":"セントヘレナ・アセンションおよびトリスタンダクーニャ",
            "it":"Sant'Elena"
        },
        "flag":"https://restcountries.eu/data/shn.svg",
        "name":"Saint Helena, Ascension and Tristan da Cunha",
        "alpha2Code":"SH",
        "callingCodes":[
            "290"
        ]
    },
    {
        "translations":{
            "br":"São Cristóvão e Neves",
            "pt":"São Cristóvão e Neves",
            "nl":"Saint Kitts en Nevis",
            "hr":"Sveti Kristof i Nevis",
            "fa":"سنت کیتس و نویس",
            "de":"St. Kitts und Nevis",
            "es":"San Cristóbal y Nieves",
            "fr":"Saint-Christophe-et-Niévès",
            "ja":"セントクリストファー・ネイビス",
            "it":"Saint Kitts e Nevis"
        },
        "flag":"https://restcountries.eu/data/kna.svg",
        "name":"Saint Kitts and Nevis",
        "alpha2Code":"KN",
        "callingCodes":[
            "1869"
        ]
    },
    {
        "translations":{
            "br":"Santa Lúcia",
            "pt":"Santa Lúcia",
            "nl":"Saint Lucia",
            "hr":"Sveta Lucija",
            "fa":"سنت لوسیا",
            "de":"Saint Lucia",
            "es":"Santa Lucía",
            "fr":"Saint-Lucie",
            "ja":"セントルシア",
            "it":"Santa Lucia"
        },
        "flag":"https://restcountries.eu/data/lca.svg",
        "name":"Saint Lucia",
        "alpha2Code":"LC",
        "callingCodes":[
            "1758"
        ]
    },
    {
        "translations":{
            "br":"Saint Martin",
            "pt":"Ilha São Martinho",
            "nl":"Saint-Martin",
            "hr":"Sveti Martin",
            "fa":"سینت مارتن",
            "de":"Saint Martin",
            "es":"Saint Martin",
            "fr":"Saint-Martin",
            "ja":"サン・マルタン（フランス領）",
            "it":"Saint Martin"
        },
        "flag":"https://restcountries.eu/data/maf.svg",
        "name":"Saint Martin (French part)",
        "alpha2Code":"MF",
        "callingCodes":[
            "590"
        ]
    },
    {
        "translations":{
            "br":"Saint-Pierre e Miquelon",
            "pt":"São Pedro e Miquelon",
            "nl":"Saint Pierre en Miquelon",
            "hr":"Sveti Petar i Mikelon",
            "fa":"سن پیر و میکلن",
            "de":"Saint-Pierre und Miquelon",
            "es":"San Pedro y Miquelón",
            "fr":"Saint-Pierre-et-Miquelon",
            "ja":"サンピエール島・ミクロン島",
            "it":"Saint-Pierre e Miquelon"
        },
        "flag":"https://restcountries.eu/data/spm.svg",
        "name":"Saint Pierre and Miquelon",
        "alpha2Code":"PM",
        "callingCodes":[
            "508"
        ]
    },
    {
        "translations":{
            "br":"São Vicente e Granadinas",
            "pt":"São Vicente e Granadinas",
            "nl":"Saint Vincent en de Grenadines",
            "hr":"Sveti Vincent i Grenadini",
            "fa":"سنت وینسنت و گرنادین‌ها",
            "de":"Saint Vincent und die Grenadinen",
            "es":"San Vicente y Granadinas",
            "fr":"Saint-Vincent-et-les-Grenadines",
            "ja":"セントビンセントおよびグレナディーン諸島",
            "it":"Saint Vincent e Grenadine"
        },
        "flag":"https://restcountries.eu/data/vct.svg",
        "name":"Saint Vincent and the Grenadines",
        "alpha2Code":"VC",
        "callingCodes":[
            "1784"
        ]
    },
    {
        "translations":{
            "br":"Samoa",
            "pt":"Samoa",
            "nl":"Samoa",
            "hr":"Samoa",
            "fa":"ساموآ",
            "de":"Samoa",
            "es":"Samoa",
            "fr":"Samoa",
            "ja":"サモア",
            "it":"Samoa"
        },
        "flag":"https://restcountries.eu/data/wsm.svg",
        "name":"Samoa",
        "alpha2Code":"WS",
        "callingCodes":[
            "685"
        ]
    },
    {
        "translations":{
            "br":"San Marino",
            "pt":"São Marinho",
            "nl":"San Marino",
            "hr":"San Marino",
            "fa":"سان مارینو",
            "de":"San Marino",
            "es":"San Marino",
            "fr":"Saint-Marin",
            "ja":"サンマリノ",
            "it":"San Marino"
        },
        "flag":"https://restcountries.eu/data/smr.svg",
        "name":"San Marino",
        "alpha2Code":"SM",
        "callingCodes":[
            "378"
        ]
    },
    {
        "translations":{
            "br":"São Tomé e Príncipe",
            "pt":"São Tomé e Príncipe",
            "nl":"Sao Tomé en Principe",
            "hr":"Sveti Toma i Princip",
            "fa":"کواترو دو فرویرو",
            "de":"São Tomé und Príncipe",
            "es":"Santo Tomé y Príncipe",
            "fr":"Sao Tomé-et-Principe",
            "ja":"サントメ・プリンシペ",
            "it":"São Tomé e Príncipe"
        },
        "flag":"https://restcountries.eu/data/stp.svg",
        "name":"Sao Tome and Principe",
        "alpha2Code":"ST",
        "callingCodes":[
            "239"
        ]
    },
    {
        "translations":{
            "br":"Arábia Saudita",
            "pt":"Arábia Saudita",
            "nl":"Saoedi-Arabië",
            "hr":"Saudijska Arabija",
            "fa":"عربستان سعودی",
            "de":"Saudi-Arabien",
            "es":"Arabia Saudí",
            "fr":"Arabie Saoudite",
            "ja":"サウジアラビア",
            "it":"Arabia Saudita"
        },
        "flag":"https://restcountries.eu/data/sau.svg",
        "name":"Saudi Arabia",
        "alpha2Code":"SA",
        "callingCodes":[
            "966"
        ]
    },
    {
        "translations":{
            "br":"Senegal",
            "pt":"Senegal",
            "nl":"Senegal",
            "hr":"Senegal",
            "fa":"سنگال",
            "de":"Senegal",
            "es":"Senegal",
            "fr":"Sénégal",
            "ja":"セネガル",
            "it":"Senegal"
        },
        "flag":"https://restcountries.eu/data/sen.svg",
        "name":"Senegal",
        "alpha2Code":"SN",
        "callingCodes":[
            "221"
        ]
    },
    {
        "translations":{
            "br":"Sérvia",
            "pt":"Sérvia",
            "nl":"Servië",
            "hr":"Srbija",
            "fa":"صربستان",
            "de":"Serbien",
            "es":"Serbia",
            "fr":"Serbie",
            "ja":"セルビア",
            "it":"Serbia"
        },
        "flag":"https://restcountries.eu/data/srb.svg",
        "name":"Serbia",
        "alpha2Code":"RS",
        "callingCodes":[
            "381"
        ]
    },
    {
        "translations":{
            "br":"Seicheles",
            "pt":"Seicheles",
            "nl":"Seychellen",
            "hr":"Sejšeli",
            "fa":"سیشل",
            "de":"Seychellen",
            "es":"Seychelles",
            "fr":"Seychelles",
            "ja":"セーシェル",
            "it":"Seychelles"
        },
        "flag":"https://restcountries.eu/data/syc.svg",
        "name":"Seychelles",
        "alpha2Code":"SC",
        "callingCodes":[
            "248"
        ]
    },
    {
        "translations":{
            "br":"Serra Leoa",
            "pt":"Serra Leoa",
            "nl":"Sierra Leone",
            "hr":"Sijera Leone",
            "fa":"سیرالئون",
            "de":"Sierra Leone",
            "es":"Sierra Leone",
            "fr":"Sierra Leone",
            "ja":"シエラレオネ",
            "it":"Sierra Leone"
        },
        "flag":"https://restcountries.eu/data/sle.svg",
        "name":"Sierra Leone",
        "alpha2Code":"SL",
        "callingCodes":[
            "232"
        ]
    },
    {
        "translations":{
            "br":"Singapura",
            "pt":"Singapura",
            "nl":"Singapore",
            "hr":"Singapur",
            "fa":"سنگاپور",
            "de":"Singapur",
            "es":"Singapur",
            "fr":"Singapour",
            "ja":"シンガポール",
            "it":"Singapore"
        },
        "flag":"https://restcountries.eu/data/sgp.svg",
        "name":"Singapore",
        "alpha2Code":"SG",
        "callingCodes":[
            "65"
        ]
    },
    {
        "translations":{
            "br":"Sint Maarten",
            "pt":"São Martinho",
            "nl":"Sint Maarten",
            "fa":"سینت مارتن",
            "de":"Sint Maarten (niederl. Teil)",
            "fr":"Saint Martin (partie néerlandaise)",
            "it":"Saint Martin (parte olandese)"
        },
        "flag":"https://restcountries.eu/data/sxm.svg",
        "name":"Sint Maarten (Dutch part)",
        "alpha2Code":"SX",
        "callingCodes":[
            "1721"
        ]
    },
    {
        "translations":{
            "br":"Eslováquia",
            "pt":"Eslováquia",
            "nl":"Slowakije",
            "hr":"Slovačka",
            "fa":"اسلواکی",
            "de":"Slowakei",
            "es":"República Eslovaca",
            "fr":"Slovaquie",
            "ja":"スロバキア",
            "it":"Slovacchia"
        },
        "flag":"https://restcountries.eu/data/svk.svg",
        "name":"Slovakia",
        "alpha2Code":"SK",
        "callingCodes":[
            "421"
        ]
    },
    {
        "translations":{
            "br":"Eslovênia",
            "pt":"Eslovénia",
            "nl":"Slovenië",
            "hr":"Slovenija",
            "fa":"اسلوونی",
            "de":"Slowenien",
            "es":"Eslovenia",
            "fr":"Slovénie",
            "ja":"スロベニア",
            "it":"Slovenia"
        },
        "flag":"https://restcountries.eu/data/svn.svg",
        "name":"Slovenia",
        "alpha2Code":"SI",
        "callingCodes":[
            "386"
        ]
    },
    {
        "translations":{
            "br":"Ilhas Salomão",
            "pt":"Ilhas Salomão",
            "nl":"Salomonseilanden",
            "hr":"Solomonski Otoci",
            "fa":"جزایر سلیمان",
            "de":"Salomonen",
            "es":"Islas Salomón",
            "fr":"Îles Salomon",
            "ja":"ソロモン諸島",
            "it":"Isole Salomone"
        },
        "flag":"https://restcountries.eu/data/slb.svg",
        "name":"Solomon Islands",
        "alpha2Code":"SB",
        "callingCodes":[
            "677"
        ]
    },
    {
        "translations":{
            "br":"Somália",
            "pt":"Somália",
            "nl":"Somalië",
            "hr":"Somalija",
            "fa":"سومالی",
            "de":"Somalia",
            "es":"Somalia",
            "fr":"Somalie",
            "ja":"ソマリア",
            "it":"Somalia"
        },
        "flag":"https://restcountries.eu/data/som.svg",
        "name":"Somalia",
        "alpha2Code":"SO",
        "callingCodes":[
            "252"
        ]
    },
    {
        "translations":{
            "br":"República Sul-Africana",
            "pt":"República Sul-Africana",
            "nl":"Zuid-Afrika",
            "hr":"Južnoafrička Republika",
            "fa":"آفریقای جنوبی",
            "de":"Republik Südafrika",
            "es":"República de Sudáfrica",
            "fr":"Afrique du Sud",
            "ja":"南アフリカ",
            "it":"Sud Africa"
        },
        "flag":"https://restcountries.eu/data/zaf.svg",
        "name":"South Africa",
        "alpha2Code":"ZA",
        "callingCodes":[
            "27"
        ]
    },
    {
        "translations":{
            "br":"Ilhas Geórgias do Sul e Sandwich do Sul",
            "pt":"Ilhas Geórgia do Sul e Sanduíche do Sul",
            "nl":"Zuid-Georgia en Zuidelijke Sandwicheilanden",
            "hr":"Južna Georgija i otočje Južni Sandwich",
            "fa":"جزایر جورجیای جنوبی و ساندویچ جنوبی",
            "de":"Südgeorgien und die Südlichen Sandwichinseln",
            "es":"Islas Georgias del Sur y Sandwich del Sur",
            "fr":"Géorgie du Sud-et-les Îles Sandwich du Sud",
            "ja":"サウスジョージア・サウスサンドウィッチ諸島",
            "it":"Georgia del Sud e Isole Sandwich Meridionali"
        },
        "flag":"https://restcountries.eu/data/sgs.svg",
        "name":"South Georgia and the South Sandwich Islands",
        "alpha2Code":"GS",
        "callingCodes":[
            "500"
        ]
    },
    {
        "translations":{
            "br":"Coreia do Sul",
            "pt":"Coreia do Sul",
            "nl":"Zuid-Korea",
            "hr":"Južna Koreja",
            "fa":"کره شمالی",
            "de":"Südkorea",
            "es":"Corea del Sur",
            "fr":"Corée du Sud",
            "ja":"大韓民国",
            "it":"Corea del Sud"
        },
        "flag":"https://restcountries.eu/data/kor.svg",
        "name":"Korea (Republic of)",
        "alpha2Code":"KR",
        "callingCodes":[
            "82"
        ]
    },
    {
        "translations":{
            "br":"Sudão do Sul",
            "pt":"Sudão do Sul",
            "nl":"Zuid-Soedan",
            "hr":"Južni Sudan",
            "fa":"سودان جنوبی",
            "de":"Südsudan",
            "es":"Sudán del Sur",
            "fr":"Soudan du Sud",
            "ja":"南スーダン",
            "it":"Sudan del sud"
        },
        "flag":"https://restcountries.eu/data/ssd.svg",
        "name":"South Sudan",
        "alpha2Code":"SS",
        "callingCodes":[
            "211"
        ]
    },
    {
        "translations":{
            "br":"Espanha",
            "pt":"Espanha",
            "nl":"Spanje",
            "hr":"Španjolska",
            "fa":"اسپانیا",
            "de":"Spanien",
            "es":"España",
            "fr":"Espagne",
            "ja":"スペイン",
            "it":"Spagna"
        },
        "flag":"https://restcountries.eu/data/esp.svg",
        "name":"Spain",
        "alpha2Code":"ES",
        "callingCodes":[
            "34"
        ]
    },
    {
        "translations":{
            "br":"Sri Lanka",
            "pt":"Sri Lanka",
            "nl":"Sri Lanka",
            "hr":"Šri Lanka",
            "fa":"سری‌لانکا",
            "de":"Sri Lanka",
            "es":"Sri Lanka",
            "fr":"Sri Lanka",
            "ja":"スリランカ",
            "it":"Sri Lanka"
        },
        "flag":"https://restcountries.eu/data/lka.svg",
        "name":"Sri Lanka",
        "alpha2Code":"LK",
        "callingCodes":[
            "94"
        ]
    },
    {
        "translations":{
            "br":"Sudão",
            "pt":"Sudão",
            "nl":"Soedan",
            "hr":"Sudan",
            "fa":"سودان",
            "de":"Sudan",
            "es":"Sudán",
            "fr":"Soudan",
            "ja":"スーダン",
            "it":"Sudan"
        },
        "flag":"https://restcountries.eu/data/sdn.svg",
        "name":"Sudan",
        "alpha2Code":"SD",
        "callingCodes":[
            "249"
        ]
    },
    {
        "translations":{
            "br":"Suriname",
            "pt":"Suriname",
            "nl":"Suriname",
            "hr":"Surinam",
            "fa":"سورینام",
            "de":"Suriname",
            "es":"Surinam",
            "fr":"Surinam",
            "ja":"スリナム",
            "it":"Suriname"
        },
        "flag":"https://restcountries.eu/data/sur.svg",
        "name":"Suriname",
        "alpha2Code":"SR",
        "callingCodes":[
            "597"
        ]
    },
    {
        "translations":{
            "br":"Svalbard",
            "pt":"Svalbard",
            "nl":"Svalbard en Jan Mayen",
            "hr":"Svalbard i Jan Mayen",
            "fa":"سوالبارد و یان ماین",
            "de":"Svalbard und Jan Mayen",
            "es":"Islas Svalbard y Jan Mayen",
            "fr":"Svalbard et Jan Mayen",
            "ja":"スヴァールバル諸島およびヤンマイエン島",
            "it":"Svalbard e Jan Mayen"
        },
        "flag":"https://restcountries.eu/data/sjm.svg",
        "name":"Svalbard and Jan Mayen",
        "alpha2Code":"SJ",
        "callingCodes":[
            "4779"
        ]
    },
    {
        "translations":{
            "br":"Suazilândia",
            "pt":"Suazilândia",
            "nl":"Swaziland",
            "hr":"Svazi",
            "fa":"سوازیلند",
            "de":"Swasiland",
            "es":"Suazilandia",
            "fr":"Swaziland",
            "ja":"スワジランド",
            "it":"Swaziland"
        },
        "flag":"https://restcountries.eu/data/swz.svg",
        "name":"Swaziland",
        "alpha2Code":"SZ",
        "callingCodes":[
            "268"
        ]
    },
    {
        "translations":{
            "br":"Suécia",
            "pt":"Suécia",
            "nl":"Zweden",
            "hr":"Švedska",
            "fa":"سوئد",
            "de":"Schweden",
            "es":"Suecia",
            "fr":"Suède",
            "ja":"スウェーデン",
            "it":"Svezia"
        },
        "flag":"https://restcountries.eu/data/swe.svg",
        "name":"Sweden",
        "alpha2Code":"SE",
        "callingCodes":[
            "46"
        ]
    },
    {
        "translations":{
            "br":"Síria",
            "pt":"Síria",
            "nl":"Syrië",
            "hr":"Sirija",
            "fa":"سوریه",
            "de":"Syrien",
            "es":"Siria",
            "fr":"Syrie",
            "ja":"シリア・アラブ共和国",
            "it":"Siria"
        },
        "flag":"https://restcountries.eu/data/syr.svg",
        "name":"Syrian Arab Republic",
        "alpha2Code":"SY",
        "callingCodes":[
            "963"
        ]
    },
    {
        "translations":{
            "br":"Taiwan",
            "pt":"Taiwan",
            "nl":"Taiwan",
            "hr":"Tajvan",
            "fa":"تایوان",
            "de":"Taiwan",
            "es":"Taiwán",
            "fr":"Taïwan",
            "ja":"台湾（中華民国）",
            "it":"Taiwan"
        },
        "flag":"https://restcountries.eu/data/twn.svg",
        "name":"Taiwan",
        "alpha2Code":"TW",
        "callingCodes":[
            "886"
        ]
    },
    {
        "translations":{
            "br":"Tajiquistão",
            "pt":"Tajiquistão",
            "nl":"Tadzjikistan",
            "hr":"Tađikistan",
            "fa":"تاجیکستان",
            "de":"Tadschikistan",
            "es":"Tayikistán",
            "fr":"Tadjikistan",
            "ja":"タジキスタン",
            "it":"Tagikistan"
        },
        "flag":"https://restcountries.eu/data/tjk.svg",
        "name":"Tajikistan",
        "alpha2Code":"TJ",
        "callingCodes":[
            "992"
        ]
    },
    {
        "translations":{
            "br":"Tanzânia",
            "pt":"Tanzânia",
            "nl":"Tanzania",
            "hr":"Tanzanija",
            "fa":"تانزانیا",
            "de":"Tansania",
            "es":"Tanzania",
            "fr":"Tanzanie",
            "ja":"タンザニア",
            "it":"Tanzania"
        },
        "flag":"https://restcountries.eu/data/tza.svg",
        "name":"Tanzania, United Republic of",
        "alpha2Code":"TZ",
        "callingCodes":[
            "255"
        ]
    },
    {
        "translations":{
            "br":"Tailândia",
            "pt":"Tailândia",
            "nl":"Thailand",
            "hr":"Tajland",
            "fa":"تایلند",
            "de":"Thailand",
            "es":"Tailandia",
            "fr":"Thaïlande",
            "ja":"タイ",
            "it":"Tailandia"
        },
        "flag":"https://restcountries.eu/data/tha.svg",
        "name":"Thailand",
        "alpha2Code":"TH",
        "callingCodes":[
            "66"
        ]
    },
    {
        "translations":{
            "br":"Timor Leste",
            "pt":"Timor Leste",
            "nl":"Oost-Timor",
            "hr":"Istočni Timor",
            "fa":"تیمور شرقی",
            "de":"Timor-Leste",
            "es":"Timor Oriental",
            "fr":"Timor oriental",
            "ja":"東ティモール",
            "it":"Timor Est"
        },
        "flag":"https://restcountries.eu/data/tls.svg",
        "name":"Timor-Leste",
        "alpha2Code":"TL",
        "callingCodes":[
            "670"
        ]
    },
    {
        "translations":{
            "br":"Togo",
            "pt":"Togo",
            "nl":"Togo",
            "hr":"Togo",
            "fa":"توگو",
            "de":"Togo",
            "es":"Togo",
            "fr":"Togo",
            "ja":"トーゴ",
            "it":"Togo"
        },
        "flag":"https://restcountries.eu/data/tgo.svg",
        "name":"Togo",
        "alpha2Code":"TG",
        "callingCodes":[
            "228"
        ]
    },
    {
        "translations":{
            "br":"Tokelau",
            "pt":"Toquelau",
            "nl":"Tokelau",
            "hr":"Tokelau",
            "fa":"توکلائو",
            "de":"Tokelau",
            "es":"Islas Tokelau",
            "fr":"Tokelau",
            "ja":"トケラウ",
            "it":"Isole Tokelau"
        },
        "flag":"https://restcountries.eu/data/tkl.svg",
        "name":"Tokelau",
        "alpha2Code":"TK",
        "callingCodes":[
            "690"
        ]
    },
    {
        "translations":{
            "br":"Tonga",
            "pt":"Tonga",
            "nl":"Tonga",
            "hr":"Tonga",
            "fa":"تونگا",
            "de":"Tonga",
            "es":"Tonga",
            "fr":"Tonga",
            "ja":"トンガ",
            "it":"Tonga"
        },
        "flag":"https://restcountries.eu/data/ton.svg",
        "name":"Tonga",
        "alpha2Code":"TO",
        "callingCodes":[
            "676"
        ]
    },
    {
        "translations":{
            "br":"Trinidad e Tobago",
            "pt":"Trindade e Tobago",
            "nl":"Trinidad en Tobago",
            "hr":"Trinidad i Tobago",
            "fa":"ترینیداد و توباگو",
            "de":"Trinidad und Tobago",
            "es":"Trinidad y Tobago",
            "fr":"Trinité et Tobago",
            "ja":"トリニダード・トバゴ",
            "it":"Trinidad e Tobago"
        },
        "flag":"https://restcountries.eu/data/tto.svg",
        "name":"Trinidad and Tobago",
        "alpha2Code":"TT",
        "callingCodes":[
            "1868"
        ]
    },
    {
        "translations":{
            "br":"Tunísia",
            "pt":"Tunísia",
            "nl":"Tunesië",
            "hr":"Tunis",
            "fa":"تونس",
            "de":"Tunesien",
            "es":"Túnez",
            "fr":"Tunisie",
            "ja":"チュニジア",
            "it":"Tunisia"
        },
        "flag":"https://restcountries.eu/data/tun.svg",
        "name":"Tunisia",
        "alpha2Code":"TN",
        "callingCodes":[
            "216"
        ]
    },
    {
        "translations":{
            "br":"Turquia",
            "pt":"Turquia",
            "nl":"Turkije",
            "hr":"Turska",
            "fa":"ترکیه",
            "de":"Türkei",
            "es":"Turquía",
            "fr":"Turquie",
            "ja":"トルコ",
            "it":"Turchia"
        },
        "flag":"https://restcountries.eu/data/tur.svg",
        "name":"Turkey",
        "alpha2Code":"TR",
        "callingCodes":[
            "90"
        ]
    },
    {
        "translations":{
            "br":"Turcomenistão",
            "pt":"Turquemenistão",
            "nl":"Turkmenistan",
            "hr":"Turkmenistan",
            "fa":"ترکمنستان",
            "de":"Turkmenistan",
            "es":"Turkmenistán",
            "fr":"Turkménistan",
            "ja":"トルクメニスタン",
            "it":"Turkmenistan"
        },
        "flag":"https://restcountries.eu/data/tkm.svg",
        "name":"Turkmenistan",
        "alpha2Code":"TM",
        "callingCodes":[
            "993"
        ]
    },
    {
        "translations":{
            "br":"Ilhas Turcas e Caicos",
            "pt":"Ilhas Turcas e Caicos",
            "nl":"Turks- en Caicoseilanden",
            "hr":"Otoci Turks i Caicos",
            "fa":"جزایر تورکس و کایکوس",
            "de":"Turks- und Caicosinseln",
            "es":"Islas Turks y Caicos",
            "fr":"Îles Turques-et-Caïques",
            "ja":"タークス・カイコス諸島",
            "it":"Isole Turks e Caicos"
        },
        "flag":"https://restcountries.eu/data/tca.svg",
        "name":"Turks and Caicos Islands",
        "alpha2Code":"TC",
        "callingCodes":[
            "1649"
        ]
    },
    {
        "translations":{
            "br":"Tuvalu",
            "pt":"Tuvalu",
            "nl":"Tuvalu",
            "hr":"Tuvalu",
            "fa":"تووالو",
            "de":"Tuvalu",
            "es":"Tuvalu",
            "fr":"Tuvalu",
            "ja":"ツバル",
            "it":"Tuvalu"
        },
        "flag":"https://restcountries.eu/data/tuv.svg",
        "name":"Tuvalu",
        "alpha2Code":"TV",
        "callingCodes":[
            "688"
        ]
    },
    {
        "translations":{
            "br":"Uganda",
            "pt":"Uganda",
            "nl":"Oeganda",
            "hr":"Uganda",
            "fa":"اوگاندا",
            "de":"Uganda",
            "es":"Uganda",
            "fr":"Uganda",
            "ja":"ウガンダ",
            "it":"Uganda"
        },
        "flag":"https://restcountries.eu/data/uga.svg",
        "name":"Uganda",
        "alpha2Code":"UG",
        "callingCodes":[
            "256"
        ]
    },
    {
        "translations":{
            "br":"Ucrânia",
            "pt":"Ucrânia",
            "nl":"Oekraïne",
            "hr":"Ukrajina",
            "fa":"وکراین",
            "de":"Ukraine",
            "es":"Ucrania",
            "fr":"Ukraine",
            "ja":"ウクライナ",
            "it":"Ucraina"
        },
        "flag":"https://restcountries.eu/data/ukr.svg",
        "name":"Ukraine",
        "alpha2Code":"UA",
        "callingCodes":[
            "380"
        ]
    },
    {
        "translations":{
            "br":"Emirados árabes Unidos",
            "pt":"Emirados árabes Unidos",
            "nl":"Verenigde Arabische Emiraten",
            "hr":"Ujedinjeni Arapski Emirati",
            "fa":"امارات متحده عربی",
            "de":"Vereinigte Arabische Emirate",
            "es":"Emiratos Árabes Unidos",
            "fr":"Émirats arabes unis",
            "ja":"アラブ首長国連邦",
            "it":"Emirati Arabi Uniti"
        },
        "flag":"https://restcountries.eu/data/are.svg",
        "name":"United Arab Emirates",
        "alpha2Code":"AE",
        "callingCodes":[
            "971"
        ]
    },
    {
        "translations":{
            "br":"Reino Unido",
            "pt":"Reino Unido",
            "nl":"Verenigd Koninkrijk",
            "hr":"Ujedinjeno Kraljevstvo",
            "fa":"بریتانیای کبیر و ایرلند شمالی",
            "de":"Vereinigtes Königreich",
            "es":"Reino Unido",
            "fr":"Royaume-Uni",
            "ja":"イギリス",
            "it":"Regno Unito"
        },
        "flag":"https://restcountries.eu/data/gbr.svg",
        "name":"United Kingdom of Great Britain and Northern Ireland",
        "alpha2Code":"GB",
        "callingCodes":[
            "44"
        ]
    },
    {
        "translations":{
            "br":"Estados Unidos",
            "pt":"Estados Unidos",
            "nl":"Verenigde Staten",
            "hr":"Sjedinjene Američke Države",
            "fa":"ایالات متحده آمریکا",
            "de":"Vereinigte Staaten von Amerika",
            "es":"Estados Unidos",
            "fr":"États-Unis",
            "ja":"アメリカ合衆国",
            "it":"Stati Uniti D'America"
        },
        "flag":"https://restcountries.eu/data/usa.svg",
        "name":"United States of America",
        "alpha2Code":"US",
        "callingCodes":[
            "1"
        ]
    },
    {
        "translations":{
            "br":"Uruguai",
            "pt":"Uruguai",
            "nl":"Uruguay",
            "hr":"Urugvaj",
            "fa":"اروگوئه",
            "de":"Uruguay",
            "es":"Uruguay",
            "fr":"Uruguay",
            "ja":"ウルグアイ",
            "it":"Uruguay"
        },
        "flag":"https://restcountries.eu/data/ury.svg",
        "name":"Uruguay",
        "alpha2Code":"UY",
        "callingCodes":[
            "598"
        ]
    },
    {
        "translations":{
            "br":"Uzbequistão",
            "pt":"Usbequistão",
            "nl":"Oezbekistan",
            "hr":"Uzbekistan",
            "fa":"ازبکستان",
            "de":"Usbekistan",
            "es":"Uzbekistán",
            "fr":"Ouzbékistan",
            "ja":"ウズベキスタン",
            "it":"Uzbekistan"
        },
        "flag":"https://restcountries.eu/data/uzb.svg",
        "name":"Uzbekistan",
        "alpha2Code":"UZ",
        "callingCodes":[
            "998"
        ]
    },
    {
        "translations":{
            "br":"Vanuatu",
            "pt":"Vanuatu",
            "nl":"Vanuatu",
            "hr":"Vanuatu",
            "fa":"وانواتو",
            "de":"Vanuatu",
            "es":"Vanuatu",
            "fr":"Vanuatu",
            "ja":"バヌアツ",
            "it":"Vanuatu"
        },
        "flag":"https://restcountries.eu/data/vut.svg",
        "name":"Vanuatu",
        "alpha2Code":"VU",
        "callingCodes":[
            "678"
        ]
    },
    {
        "translations":{
            "br":"Venezuela",
            "pt":"Venezuela",
            "nl":"Venezuela",
            "hr":"Venezuela",
            "fa":"ونزوئلا",
            "de":"Venezuela",
            "es":"Venezuela",
            "fr":"Venezuela",
            "ja":"ベネズエラ・ボリバル共和国",
            "it":"Venezuela"
        },
        "flag":"https://restcountries.eu/data/ven.svg",
        "name":"Venezuela (Bolivarian Republic of)",
        "alpha2Code":"VE",
        "callingCodes":[
            "58"
        ]
    },
    {
        "translations":{
            "br":"Vietnã",
            "pt":"Vietname",
            "nl":"Vietnam",
            "hr":"Vijetnam",
            "fa":"ویتنام",
            "de":"Vietnam",
            "es":"Vietnam",
            "fr":"Viêt Nam",
            "ja":"ベトナム",
            "it":"Vietnam"
        },
        "flag":"https://restcountries.eu/data/vnm.svg",
        "name":"Viet Nam",
        "alpha2Code":"VN",
        "callingCodes":[
            "84"
        ]
    },
    {
        "translations":{
            "br":"Wallis e Futuna",
            "pt":"Wallis e Futuna",
            "nl":"Wallis en Futuna",
            "hr":"Wallis i Fortuna",
            "fa":"والیس و فوتونا",
            "de":"Wallis und Futuna",
            "es":"Wallis y Futuna",
            "fr":"Wallis-et-Futuna",
            "ja":"ウォリス・フツナ",
            "it":"Wallis e Futuna"
        },
        "flag":"https://restcountries.eu/data/wlf.svg",
        "name":"Wallis and Futuna",
        "alpha2Code":"WF",
        "callingCodes":[
            "681"
        ]
    },
    {
        "translations":{
            "br":"Saara Ocidental",
            "pt":"Saara Ocidental",
            "nl":"Westelijke Sahara",
            "hr":"Zapadna Sahara",
            "fa":"جمهوری دموکراتیک عربی صحرا",
            "de":"Westsahara",
            "es":"Sahara Occidental",
            "fr":"Sahara Occidental",
            "ja":"西サハラ",
            "it":"Sahara Occidentale"
        },
        "flag":"https://restcountries.eu/data/esh.svg",
        "name":"Western Sahara",
        "alpha2Code":"EH",
        "callingCodes":[
            "212"
        ]
    },
    {
        "translations":{
            "br":"Iêmen",
            "pt":"Iémen",
            "nl":"Jemen",
            "hr":"Jemen",
            "fa":"یمن",
            "de":"Jemen",
            "es":"Yemen",
            "fr":"Yémen",
            "ja":"イエメン",
            "it":"Yemen"
        },
        "flag":"https://restcountries.eu/data/yem.svg",
        "name":"Yemen",
        "alpha2Code":"YE",
        "callingCodes":[
            "967"
        ]
    },
    {
        "translations":{
            "br":"Zâmbia",
            "pt":"Zâmbia",
            "nl":"Zambia",
            "hr":"Zambija",
            "fa":"زامبیا",
            "de":"Sambia",
            "es":"Zambia",
            "fr":"Zambie",
            "ja":"ザンビア",
            "it":"Zambia"
        },
        "flag":"https://restcountries.eu/data/zmb.svg",
        "name":"Zambia",
        "alpha2Code":"ZM",
        "callingCodes":[
            "260"
        ]
    },
    {
        "translations":{
            "br":"Zimbabwe",
            "pt":"Zimbabué",
            "nl":"Zimbabwe",
            "hr":"Zimbabve",
            "fa":"زیمباوه",
            "de":"Simbabwe",
            "es":"Zimbabue",
            "fr":"Zimbabwe",
            "ja":"ジンバブエ",
            "it":"Zimbabwe"
        },
        "flag":"https://restcountries.eu/data/zwe.svg",
        "name":"Zimbabwe",
        "alpha2Code":"ZW",
        "callingCodes":[
            "263"
        ]
    }
]

export const PHONE_PREFIXES = [
    {
        "value": "+1",
        "country": "as"
    },
    {
        "value": "+1",
        "country": "ai"
    },
    {
        "value": "+1",
        "country": "ag"
    },
    {
        "value": "+1",
        "country": "bs"
    },
    {
        "value": "+1",
        "country": "bb"
    },
    {
        "value": "+1",
        "country": "bm"
    },
    {
        "value": "+1",
        "country": "vg"
    },
    {
        "value": "+1",
        "country": "ca"
    },
    {
        "value": "+1",
        "country": "ky"
    },
    {
        "value": "+1",
        "country": "dm"
    },
    {
        "value": "+1",
        "country": "do"
    },
    {
        "value": "+1",
        "country": "gd"
    },
    {
        "value": "+1",
        "country": "gu"
    },
    {
        "value": "+1",
        "country": "jm"
    },
    {
        "value": "+1",
        "country": "ms"
    },
    {
        "value": "+1",
        "country": "mp"
    },
    {
        "value": "+1",
        "country": "pr"
    },
    {
        "value": "+1",
        "country": "kn"
    },
    {
        "value": "+1",
        "country": "lc"
    },
    {
        "value": "+1",
        "country": "vc"
    },
    {
        "value": "+1",
        "country": "sx"
    },
    {
        "value": "+1",
        "country": "tt"
    },
    {
        "value": "+1",
        "country": "tc"
    },
    {
        "value": "+1",
        "country": "vi"
    },
    {
        "value": "+1",
        "country": "us"
    },
    {
        "value": "+20",
        "country": "eg"
    },
    {
        "value": "+211",
        "country": "ss"
    },
    {
        "value": "+212",
        "country": "ma"
    },
    {
        "value": "+212",
        "country": "eh"
    },
    {
        "value": "+213",
        "country": "dz"
    },
    {
        "value": "+216",
        "country": "tn"
    },
    {
        "value": "+218",
        "country": "ly"
    },
    {
        "value": "+220",
        "country": "gm"
    },
    {
        "value": "+221",
        "country": "sn"
    },
    {
        "value": "+222",
        "country": "mr"
    },
    {
        "value": "+223",
        "country": "ml"
    },
    {
        "value": "+224",
        "country": "gn"
    },
    {
        "value": "+225",
        "country": "ci"
    },
    {
        "value": "+226",
        "country": "bf"
    },
    {
        "value": "+227",
        "country": "ne"
    },
    {
        "value": "+228",
        "country": "tg"
    },
    {
        "value": "+229",
        "country": "bj"
    },
    {
        "value": "+230",
        "country": "mu"
    },
    {
        "value": "+231",
        "country": "lr"
    },
    {
        "value": "+232",
        "country": "sl"
    },
    {
        "value": "+233",
        "country": "gh"
    },
    {
        "value": "+234",
        "country": "ng"
    },
    {
        "value": "+235",
        "country": "td"
    },
    {
        "value": "+236",
        "country": "cf"
    },
    {
        "value": "+237",
        "country": "cm"
    },
    {
        "value": "+238",
        "country": "cv"
    },
    {
        "value": "+239",
        "country": "st"
    },
    {
        "value": "+240",
        "country": "gq"
    },
    {
        "value": "+241",
        "country": "ga"
    },
    {
        "value": "+242",
        "country": "cg"
    },
    {
        "value": "+243",
        "country": "cd"
    },
    {
        "value": "+244",
        "country": "ao"
    },
    {
        "value": "+245",
        "country": "gw"
    },
    {
        "value": "+246",
        "country": "io"
    },
    {
        "value": "+248",
        "country": "sc"
    },
    {
        "value": "+249",
        "country": "sd"
    },
    {
        "value": "+250",
        "country": "rw"
    },
    {
        "value": "+251",
        "country": "et"
    },
    {
        "value": "+252",
        "country": "so"
    },
    {
        "value": "+253",
        "country": "dj"
    },
    {
        "value": "+254",
        "country": "ke"
    },
    {
        "value": "+255",
        "country": "tz"
    },
    {
        "value": "+256",
        "country": "ug"
    },
    {
        "value": "+257",
        "country": "bi"
    },
    {
        "value": "+258",
        "country": "mz"
    },
    {
        "value": "+260",
        "country": "zm"
    },
    {
        "value": "+261",
        "country": "mg"
    },
    {
        "value": "+262",
        "country": "yt"
    },
    {
        "value": "+262",
        "country": "re"
    },
    {
        "value": "+263",
        "country": "zw"
    },
    {
        "value": "+264",
        "country": "na"
    },
    {
        "value": "+265",
        "country": "mw"
    },
    {
        "value": "+266",
        "country": "ls"
    },
    {
        "value": "+267",
        "country": "bw"
    },
    {
        "value": "+268",
        "country": "sz"
    },
    {
        "value": "+269",
        "country": "km"
    },
    {
        "value": "+27",
        "country": "za"
    },
    {
        "value": "+290",
        "country": "sh"
    },
    {
        "value": "+291",
        "country": "er"
    },
    {
        "value": "+297",
        "country": "aw"
    },
    {
        "value": "+298",
        "country": "fo"
    },
    {
        "value": "+299",
        "country": "gl"
    },
    {
        "value": "+30",
        "country": "gr"
    },
    {
        "value": "+31",
        "country": "nl"
    },
    {
        "value": "+32",
        "country": "be"
    },
    {
        "value": "+33",
        "country": "fr"
    },
    {
        "value": "+34",
        "country": "es"
    },
    {
        "value": "+350",
        "country": "gi"
    },
    {
        "value": "+351",
        "country": "pt"
    },
    {
        "value": "+352",
        "country": "lu"
    },
    {
        "value": "+353",
        "country": "ie"
    },
    {
        "value": "+354",
        "country": "is"
    },
    {
        "value": "+355",
        "country": "al"
    },
    {
        "value": "+356",
        "country": "mt"
    },
    {
        "value": "+357",
        "country": "cy"
    },
    {
        "value": "+358",
        "country": "fi"
    },
    {
        "value": "+359",
        "country": "bg"
    },
    {
        "value": "+36",
        "country": "hu"
    },
    {
        "value": "+370",
        "country": "lt"
    },
    {
        "value": "+371",
        "country": "lv"
    },
    {
        "value": "+372",
        "country": "ee"
    },
    {
        "value": "+373",
        "country": "md"
    },
    {
        "value": "+374",
        "country": "am"
    },
    {
        "value": "+375",
        "country": "by"
    },
    {
        "value": "+376",
        "country": "ad"
    },
    {
        "value": "+377",
        "country": "mc"
    },
    {
        "value": "+378",
        "country": "sm"
    },
    {
        "value": "+379",
        "country": "va"
    },
    {
        "value": "+380",
        "country": "ua"
    },
    {
        "value": "+381",
        "country": "rs"
    },
    {
        "value": "+382",
        "country": "me"
    },
    {
        "value": "+383",
        "country": "xk"
    },
    {
        "value": "+385",
        "country": "hr"
    },
    {
        "value": "+386",
        "country": "si"
    },
    {
        "value": "+387",
        "country": "ba"
    },
    {
        "value": "+389",
        "country": "mk"
    },
    {
        "value": "+39",
        "country": "it"
    },
    {
        "value": "+40",
        "country": "ro"
    },
    {
        "value": "+41",
        "country": "ch"
    },
    {
        "value": "+420",
        "country": "cz"
    },
    {
        "value": "+421",
        "country": "sk"
    },
    {
        "value": "+423",
        "country": "li"
    },
    {
        "value": "+43",
        "country": "at"
    },
    {
        "value": "+44",
        "country": "gg"
    },
    {
        "value": "+44",
        "country": "im"
    },
    {
        "value": "+44",
        "country": "je"
    },
    {
        "value": "+44",
        "country": "gb"
    },
    {
        "value": "+45",
        "country": "dk"
    },
    {
        "value": "+46",
        "country": "se"
    },
    {
        "value": "+47",
        "country": "no"
    },
    {
        "value": "+47",
        "country": "sj"
    },
    {
        "value": "+48",
        "country": "pl"
    },
    {
        "value": "+49",
        "country": "de"
    },
    {
        "value": "+500",
        "country": "fk"
    },
    {
        "value": "+501",
        "country": "bz"
    },
    {
        "value": "+502",
        "country": "gt"
    },
    {
        "value": "+503",
        "country": "sv"
    },
    {
        "value": "+504",
        "country": "hn"
    },
    {
        "value": "+505",
        "country": "ni"
    },
    {
        "value": "+506",
        "country": "cr"
    },
    {
        "value": "+507",
        "country": "pa"
    },
    {
        "value": "+508",
        "country": "pm"
    },
    {
        "value": "+509",
        "country": "ht"
    },
    {
        "value": "+51",
        "country": "pe"
    },
    {
        "value": "+52",
        "country": "mx"
    },
    {
        "value": "+53",
        "country": "cu"
    },
    {
        "value": "+54",
        "country": "ar"
    },
    {
        "value": "+55",
        "country": "br"
    },
    {
        "value": "+56",
        "country": "cl"
    },
    {
        "value": "+57",
        "country": "co"
    },
    {
        "value": "+58",
        "country": "ve"
    },
    {
        "value": "+590",
        "country": "bl"
    },
    {
        "value": "+590",
        "country": "mf"
    },
    {
        "value": "+591",
        "country": "bo"
    },
    {
        "value": "+592",
        "country": "gy"
    },
    {
        "value": "+593",
        "country": "ec"
    },
    {
        "value": "+595",
        "country": "py"
    },
    {
        "value": "+597",
        "country": "sr"
    },
    {
        "value": "+598",
        "country": "uy"
    },
    {
        "value": "+599",
        "country": "cw"
    },
    {
        "value": "+60",
        "country": "my"
    },
    {
        "value": "+61",
        "country": "au"
    },
    {
        "value": "+61",
        "country": "cx"
    },
    {
        "value": "+61",
        "country": "cc"
    },
    {
        "value": "+62",
        "country": "id"
    },
    {
        "value": "+63",
        "country": "ph"
    },
    {
        "value": "+64",
        "country": "nz"
    },
    {
        "value": "+64",
        "country": "pn"
    },
    {
        "value": "+65",
        "country": "sg"
    },
    {
        "value": "+66",
        "country": "th"
    },
    {
        "value": "+670",
        "country": "tl"
    },
    {
        "value": "+672",
        "country": "aq"
    },
    {
        "value": "+673",
        "country": "bn"
    },
    {
        "value": "+674",
        "country": "nr"
    },
    {
        "value": "+675",
        "country": "pg"
    },
    {
        "value": "+676",
        "country": "to"
    },
    {
        "value": "+677",
        "country": "sb"
    },
    {
        "value": "+678",
        "country": "vu"
    },
    {
        "value": "+679",
        "country": "fj"
    },
    {
        "value": "+680",
        "country": "pw"
    },
    {
        "value": "+681",
        "country": "wf"
    },
    {
        "value": "+682",
        "country": "ck"
    },
    {
        "value": "+683",
        "country": "nu"
    },
    {
        "value": "+685",
        "country": "ws"
    },
    {
        "value": "+686",
        "country": "ki"
    },
    {
        "value": "+687",
        "country": "nc"
    },
    {
        "value": "+688",
        "country": "tv"
    },
    {
        "value": "+689",
        "country": "pf"
    },
    {
        "value": "+690",
        "country": "tk"
    },
    {
        "value": "+691",
        "country": "fm"
    },
    {
        "value": "+692",
        "country": "mh"
    },
    {
        "value": "+7",
        "country": "kz"
    },
    {
        "value": "+7",
        "country": "ru"
    },
    {
        "value": "+81",
        "country": "jp"
    },
    {
        "value": "+82",
        "country": "kr"
    },
    {
        "value": "+84",
        "country": "vn"
    },
    {
        "value": "+850",
        "country": "kp"
    },
    {
        "value": "+852",
        "country": "hk"
    },
    {
        "value": "+853",
        "country": "mo"
    },
    {
        "value": "+855",
        "country": "kh"
    },
    {
        "value": "+856",
        "country": "la"
    },
    {
        "value": "+86",
        "country": "cn"
    },
    {
        "value": "+880",
        "country": "bd"
    },
    {
        "value": "+886",
        "country": "tw"
    },
    {
        "value": "+90",
        "country": "tr"
    },
    {
        "value": "+91",
        "country": "in"
    },
    {
        "value": "+92",
        "country": "pk"
    },
    {
        "value": "+93",
        "country": "af"
    },
    {
        "value": "+94",
        "country": "lk"
    },
    {
        "value": "+95",
        "country": "mm"
    },
    {
        "value": "+960",
        "country": "mv"
    },
    {
        "value": "+961",
        "country": "lb"
    },
    {
        "value": "+962",
        "country": "jo"
    },
    {
        "value": "+963",
        "country": "sy"
    },
    {
        "value": "+964",
        "country": "iq"
    },
    {
        "value": "+965",
        "country": "kw"
    },
    {
        "value": "+966",
        "country": "sa"
    },
    {
        "value": "+967",
        "country": "ye"
    },
    {
        "value": "+968",
        "country": "om"
    },
    {
        "value": "+970",
        "country": "ps"
    },
    {
        "value": "+971",
        "country": "ae"
    },
    {
        "value": "+972",
        "country": "il"
    },
    {
        "value": "+973",
        "country": "bh"
    },
    {
        "value": "+974",
        "country": "qa"
    },
    {
        "value": "+975",
        "country": "bt"
    },
    {
        "value": "+976",
        "country": "mn"
    },
    {
        "value": "+977",
        "country": "np"
    },
    {
        "value": "+98",
        "country": "ir"
    },
    {
        "value": "+992",
        "country": "tj"
    },
    {
        "value": "+993",
        "country": "tm"
    },
    {
        "value": "+994",
        "country": "az"
    },
    {
        "value": "+995",
        "country": "ge"
    },
    {
        "value": "+996",
        "country": "kg"
    },
    {
        "value": "+998",
        "country": "uz"
    }
]