import React from 'react';
import PropTypes from "prop-types";
import {getLanguageEntry} from "../utils/Helper";

class CustomLink extends React.Component {

    render() {
        return (
            <a href={this.props.href} rel={this.props.rel} target={this.props.target}>{getLanguageEntry(this.props.textPath)}</a>
        )
    }

}
CustomLink.propTypes = {
    textPath: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    rel: PropTypes.string,
    target: PropTypes.string
}
export default CustomLink;