import PropTypes from "prop-types";

function Loading(props) {

    return(
        <div className="full-screen-loading" style={{width: '100%', height: 'calc(100vh - var(--main-banner-height))',
            display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <img style={{width: props.imageWidth}}
                src="https://roomjack.blob.core.windows.net/roomjack/animations/loading_animation.gif" alt="Roomjack Loading"/>
        </div>
    )
}
Loading.propTypes = {
    imageWidth: PropTypes.string.isRequired
}
export default Loading;