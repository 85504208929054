import React from "react";
import {ADVERT_CACHE} from "../../../../utils/CacheHandler.ts";
import {getAdverts, postAdvert} from "../../../../utils/RESTInterface";
import Loading from "../../../../components/Loading";
import {
    collectHousingListAttributes,
    getLanguageEntry, MAX_BOOKING_LENGTH,
    MIN_BOOKING_LENGTH,
    unpackWidgetData
} from "../../../../utils/Helper";
import {Route} from "react-router-dom";
import {Navigate, Routes} from "react-router";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    AccessType, AdvertAreaStatus, AdvertEditorTab,
} from "../../../../utils/Types.ts";
import BookingSettingsTab from "./editor_tabs/BookingSettingsTab";
import DescriptionTab from "./editor_tabs/DescriptionTab";
import LocationTab from "./editor_tabs/LocationTab";
import AdvertTypeTab from "./editor_tabs/AdvertTypeTab";
import EquipmentTab from "./editor_tabs/EquipmentTab";
import ImagesTab from "./editor_tabs/ImagesTab";
import PricingTab from "./editor_tabs/PricingTab";
import FetchButton from "../../../../components/buttons/FetchButton";
import parse from "html-react-parser";
import ReportModal from "../../../../components/modals/ReportModal";
import AlertModal from "../../../../components/modals/AlertModal";
import AdvertSavingProgressModal from "../../../../components/modals/AdvertSavingProgressModal";

const LANG_PATH = "jackboard>adverts>advert_creator>";
export const BASIC_ADVERT = {
    bookable: false,
    pro_tool: true,
    auto_reminder: false,
    address: {},
    characteristics: [],
    title: "",
    description: "",
    booking_type: "direct",
    min_booking_length: MIN_BOOKING_LENGTH,
    max_booking_length: MAX_BOOKING_LENGTH
}

class AdvertEditor extends React.Component {

    REQUIREMENT_MAP = {
        location: {
            status: "",
            requirements: {}
        },
        advert_type: {
            status: "",
            requirements: {}
        },
        equipment: {
            status: "",
            requirements: {}
        },
        images: {
            status: "",
            requirements: {}
        },
        description: {
            status: "",
            requirements: {}
        },
        pricing: {
            status: "",
            requirements: {}
        },
        booking_settings: {
            status: "",
            requirements: {}
        },
    }

    PATH_ORDER = [
        "location", "advert-type", "equipment",
        "images", "description", "pricing", "booking-settings"
    ]

    constructor(props) {
        super(props);
        this.menu_items = [];
        for (const editorTab of Object.values(AdvertEditorTab)) {
            this.menu_items.push({
                name: editorTab,
                path: "/desktop/adverts/editor/" + editorTab.replaceAll("_", "-"),
                lang: LANG_PATH + "tab_headers>" + editorTab
            })
        }
        this.state = {
            initializing: false,
            original_advert: {...BASIC_ADVERT},
            edited_advert: {...BASIC_ADVERT},
            requirement_map: JSON.parse(JSON.stringify(this.REQUIREMENT_MAP)),
            saving: false,
            publishing: false,
            silent_saving: false,
            upload_progress: 0,
            response: null
        }
        this.active_tab = React.createRef();
        this.alert_modal = React.createRef();
        this.report_modal = React.createRef();
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let advertID = params.get("id");
        if (advertID) {
            let cachedAdvert = this.setAdvert(advertID, false);
            if (!cachedAdvert) {
                this.setState({initializing: true});
                let that = this;
                getAdverts(() => {
                    that.setAdvert(advertID, true);
                });
            }
        }
        else {
            this.setAdvert(null, true);
        }
    }

    render() {
        let url = window.location.pathname;
        let menuUrl = url.substring(url.lastIndexOf("/") + 1);
        let tabIndex = this.PATH_ORDER.indexOf(menuUrl);
        let disableSurface = this.state.saving || this.state.publishing;
        return <div id="advert-editor">
            {
                this.state.initializing &&
                <Loading imageWidth="250px" />
            }
            {
                !this.state.initializing &&
                <div className="screen-size-invalid">
                    <img src="https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear.png"
                         alt="Screen size too small"/>
                    <div className="roomjack-headline">
                        {getLanguageEntry(LANG_PATH + "screen_to_small_header")}
                    </div>
                    <div className="description-container">
                        {getLanguageEntry(LANG_PATH + "screen_to_small_description")}
                    </div>
                </div>
            }
            {
                !this.state.initializing &&
                <div className="screen-size-valid">
                    <div className="tab-button-container">
                        {
                            this.menu_items.map((item, _) => {
                                let requirements = this.state.requirement_map[item.name];
                                let icon = "";
                                if (requirements.status.length > 0) {
                                    if (requirements.status === AdvertAreaStatus.completed) {
                                        icon = "check-circle";
                                    }
                                    if (requirements.status === AdvertAreaStatus.warning) {
                                        icon = "exclamation-circle";
                                    }
                                    if (requirements.status === AdvertAreaStatus.invalid) {
                                        icon = "exclamation-triangle";
                                    }
                                }
                                return <button className={this.getTabButtonClass(menuUrl, item.name)} key={item.name}
                                               onClick={() => {this.switchToTab(item.path + window.location.search)}}
                                               disabled={disableSurface}>
                                    {getLanguageEntry(item.lang)}
                                    {
                                        icon.length > 0 &&
                                        <FontAwesomeIcon icon={["fal", icon]} />
                                    }
                                </button>
                            })
                        }
                    </div>
                    <Routes>
                        <Route exact path="/location" element={<LocationTab advert={this.state.edited_advert}
                                                                            userData={this.props.userData}
                                                                            checkRequirements={this.state.requirement_map.location.status.length > 0}
                                                                            updateRequirements={(result) => {this.updateRequirements(AdvertEditorTab.location, {}, result, null, this.state.requirement_map.location.status.length > 0)}}
                                                                            requirements={this.state.requirement_map.location.requirements}
                                                                            ref={this.active_tab}
                                                                            disabled={disableSurface}/>} />
                        <Route exact path="/advert-type" element={<AdvertTypeTab advert={this.state.edited_advert}
                                                                                 userData={this.props.userData}
                                                                                 checkRequirements={this.state.requirement_map.advert_type.status.length > 0}
                                                                                 updateRequirements={(result) => {this.updateRequirements(AdvertEditorTab.advert_type, {}, result, null, this.state.requirement_map.advert_type.status.length > 0)}}
                                                                                 requirements={this.state.requirement_map.advert_type.requirements}
                                                                                 ref={this.active_tab}
                                                                                 disabled={disableSurface}/>} />
                        <Route exact path="/equipment" element={<EquipmentTab advert={this.state.edited_advert}
                                                                              userData={this.props.userData}
                                                                              checkRequirements={this.state.requirement_map.equipment.status.length > 0}
                                                                              updateRequirements={(result) => {this.updateRequirements(AdvertEditorTab.equipment, {}, result, null, this.state.requirement_map.equipment.status.length > 0)}}
                                                                              requirements={this.state.requirement_map.equipment.requirements}
                                                                              ref={this.active_tab}
                                                                              disabled={disableSurface} />} />
                        <Route exact path="/images" element={<ImagesTab advert={this.state.edited_advert}
                                                                        userData={this.props.userData}
                                                                        checkRequirements={this.state.requirement_map.images.status.length > 0}
                                                                        updateRequirements={(result) => {this.updateRequirements(AdvertEditorTab.images, {}, result, null, this.state.requirement_map.images.status.length > 0)}}
                                                                        requirements={this.state.requirement_map.images.requirements}
                                                                        ref={this.active_tab}
                                                                        disabled={disableSurface} />}/>
                        <Route exact path="/description" element={<DescriptionTab advert={this.state.edited_advert}
                                                                                  userData={this.props.userData}
                                                                                  checkRequirements={this.state.requirement_map.description.status.length > 0}
                                                                                  updateRequirements={(result) => {this.updateRequirements(AdvertEditorTab.description, {}, result, null, this.state.requirement_map.description.status.length > 0)}}
                                                                                  requirements={this.state.requirement_map.description.requirements}
                                                                                  ref={this.active_tab}
                                                                                  disabled={disableSurface} />}/>
                        <Route exact path="/pricing" element={<PricingTab advert={this.state.edited_advert}
                                                                          userData={this.props.userData}
                                                                          checkRequirements={this.state.requirement_map.pricing.status.length > 0}
                                                                          updateRequirements={(result) => {this.updateRequirements(AdvertEditorTab.pricing, {}, result, null, this.state.requirement_map.pricing.status.length > 0)}}
                                                                          requirements={this.state.requirement_map.pricing.requirements}
                                                                          ref={this.active_tab}
                                                                          disabled={disableSurface}/>} />
                        <Route exact path="/booking-settings" element={<BookingSettingsTab advert={this.state.edited_advert}
                                                                                           userData={this.props.userData}
                                                                                           checkRequirements={this.state.requirement_map.booking_settings.status.length > 0}
                                                                                           updateRequirements={(result) => {this.updateRequirements(AdvertEditorTab.booking_settings, {}, result, null, this.state.requirement_map.booking_settings.status.length > 0)}}
                                                                                           requirements={this.state.requirement_map.booking_settings.requirements}
                                                                                           ref={this.active_tab}
                                                                                           disabled={disableSurface}/>}></Route>
                        <Route exact path={"/*"} element={<Navigate to={`/desktop/adverts/editor/location${this.state.edited_advert && this.state.edited_advert.id ? '?id=' + this.state.edited_advert.id : ''}`} />} />
                    </Routes>
                    <div className="horizontal-form-group">
                        {
                            tabIndex > 0 &&
                            <button onClick={() => {this.switchToTab("/desktop/adverts/editor/" + this.PATH_ORDER[tabIndex - 1])}}
                                    className={"outline-button gray"} disabled={disableSurface}>
                                <FontAwesomeIcon icon={["fal", "chevron-left"]}/>
                                <span>{getLanguageEntry("general>back")}</span>
                            </button>
                        }
                        <button className="outline-button black" onClick={() => {this.checkForCompleteness()}}
                                disabled={disableSurface}>
                            <FontAwesomeIcon icon={["fas", "cogs"]}/>
                            <span>{getLanguageEntry(LANG_PATH + "buttons>check_for_completeness")}</span>
                        </button>
                        <FetchButton loading={this.state.saving || this.state.silent_saving}
                                     disabled={this.state.publishing} loadingText={"general>saving"}
                                     className="outline-button black" onClick={() => {this.postAdvert(false)}}>
                            <FontAwesomeIcon icon={["fas", "save"]}/>
                            <span>{getLanguageEntry("general>save")}</span>
                        </FetchButton>
                        {
                            tabIndex > -1 && tabIndex < this.PATH_ORDER.length - 1 &&
                            <button onClick={() => {this.switchToTab("/desktop/adverts/editor/" + this.PATH_ORDER[tabIndex + 1])}}
                                     className={"outline-button accent"} disabled={disableSurface}>
                                <span>{getLanguageEntry("general>next")}</span>
                                <FontAwesomeIcon icon={["fas", "chevron-right"]}/>
                            </button>
                        }
                        {
                            tabIndex > -1 && tabIndex === this.PATH_ORDER.length - 1 &&
                            <FetchButton loading={this.state.publishing} loadingText={"general>saving"}
                                         disabled={this.state.saving || this.state.silent_saving}
                                         onClick={() => {this.postAdvert(true, true)}}
                                         className="outline-button accept">
                                <FontAwesomeIcon icon={["fal", "save"]}/>
                                <span>{getLanguageEntry(LANG_PATH + "buttons>save_and_publish")}</span>
                            </FetchButton>
                        }
                    </div>
                    <div className="description-container">
                        {parse(getLanguageEntry(LANG_PATH + "buttons>need_help"))}
                    </div>
                </div>
            }
            <AlertModal ref={this.alert_modal}/>
            <ReportModal ref={this.report_modal} userData={this.props.userData} />
            <AdvertSavingProgressModal uploadProgress={this.state.upload_progress} response={this.state.response}
                                       onCloseModal={() => {this.setState({response: null})}}
                                       show={!!this.state.saving || !!this.state.publishing || !!this.state.response}
                                       onContactSupport={(reason) => {this.callReportModal(reason)}}/>
        </div>
    }

    setAdvert(advertID, setEmpty) {
        let cachedAdvert;
        if (advertID) {
            cachedAdvert = ADVERT_CACHE.getCacheObject(advertID);
        }
        if (cachedAdvert) {
            let requirementMap = this.checkAllRequirements(cachedAdvert.getData());
            this.setState({
                initializing: false,
                original_advert: JSON.parse(JSON.stringify(cachedAdvert.getData())),
                edited_advert: JSON.parse(JSON.stringify(cachedAdvert.getData())),
                requirement_map: requirementMap
            });
        }
        else if (setEmpty) {
            this.setState({
                initializing: false,
                original_advert: {...BASIC_ADVERT},
                edited_advert: {...BASIC_ADVERT},
                requirement_map: JSON.parse(JSON.stringify(this.REQUIREMENT_MAP))
            })
        }
        return cachedAdvert;
    }

    getTabButtonClass(url, menuItem) {
        let classList = ["tab-button"];
        if ((menuItem === "location" && url === "editor") ||
            menuItem.replaceAll("_", "-") === url) {
            classList.push("selected")
        }
        if (this.state.requirement_map[menuItem].status.length > 0) {
            classList.push(this.state.requirement_map[menuItem].status);
        }
        return classList.join(" ");
    }

    switchToTab(path) {
        if (this.active_tab.current) {
            let updateRequirements = window.location.pathname !== path;
            let tabData = this.active_tab.current.extractData();
            this.updateAdvertData(tabData, this.active_tab.current.getTabTpe(), path, updateRequirements);
            let previousData = this.assignData({});
            let advertData = this.assignData(tabData);
            if (!this.state.saving && !this.state.silent_saving && !this.state.publishing &&
                JSON.stringify(previousData) !== JSON.stringify(advertData)) {
                let that = this;
                this.setState({silent_saving: true});
                postAdvert(advertData, false, (response) => {
                    let update = false;
                    let user = JSON.parse(JSON.stringify(that.props.userData));
                    if (response.widgets) {
                        unpackWidgetData(response.widgets);
                        user.widgets = response.widgets;
                        update = true;
                    }
                    if (!user.adverts) {
                        user.adverts = [response.data.id];
                        update = true;
                    }
                    else if (!user.adverts.includes(response.data.id)) {
                        user.adverts.push(response.data.id);
                        update = true;
                    }
                    if (update) {
                        that.props.onUpdateUserData?.(user);
                    }
                    that.setState({
                        edited_advert: JSON.parse(JSON.stringify(response.data)),
                        original_advert: response.data,
                        saving: false,
                        publishing: false,
                        silent_saving: false,
                        response: null
                    });
                });
            }
        }
        else {
            this.props.navHook?.(path);
        }
        setTimeout(() => window.scrollTo(0, 0), 100);
    }

    updateAdvertData(result, tab, goTo, updateRequirements=true) {
        let advertData = this.assignData(result);
        this.updateRequirements(tab, {edited_advert: advertData}, advertData, goTo, updateRequirements);
    }

    assignData(data) {
        let advertData = JSON.parse(JSON.stringify(this.state.edited_advert));
        Object.entries(data).forEach(([k, v], _) => {
            if (v === null || v === undefined) {
                delete advertData[k];
            }
            else if (v !== advertData[k]) {
                advertData[k] = v;
            }
        });
        return advertData;
    }

    updateRequirements(tab, state, data, goTo, updateRequirements) {
        if (this.state.saving || this.state.publishing || this.state.silent_saving) {
            return;
        }
        if (updateRequirements) {
            let requirementMap = this.state.requirement_map;
            switch (tab) {
                case AdvertEditorTab.location:
                    Object.assign(requirementMap, this.checkLocationData(data));
                    break;
                case AdvertEditorTab.advert_type:
                    Object.assign(requirementMap, this.checkAdvertTypeData(data));
                    break;
                case AdvertEditorTab.equipment:
                    Object.assign(requirementMap, this.checkEquipmentData(data));
                    break;
                case AdvertEditorTab.images:
                    Object.assign(requirementMap, this.checkImagesData(data));
                    break;
                case AdvertEditorTab.description:
                    Object.assign(requirementMap, this.checkDescriptionData(data));
                    break;
                case AdvertEditorTab.pricing:
                    Object.assign(requirementMap, this.checkPricingData(data));
                    break;
                case AdvertEditorTab.booking_settings:
                    Object.assign(requirementMap, this.checkBookingSettingsData(data));
                    break;
                default:
                    break;
            }
            state.requirements = requirementMap;
        }
        this.setState(state, () => {
            if (goTo) {
                this.props.navHook?.(goTo);
            }
        });
    }

    checkLocationData(data) {
        let requirements = {};
        let status = "completed";
        if (!data.address || !data.address.location) {
            status = "warning";
        }
        if (!data.address || !data.address.line_1) {
            requirements.line_1 = true;
            status = "invalid";
        }
        if (!data.address || !data.address.city) {
            requirements.city = true;
            status = "invalid";
        }
        if (!data.address || !data.address.postal_code) {
            requirements.postal_code = true;
            status = "invalid";
        }
        if (!data.address || !data.address.country) {
            requirements.country = true;
            status = "invalid";
        }
        return {
            location: {
                status: status,
                requirements: requirements
            }
        };
    }

    checkAdvertTypeData(data) {
        let requirements = {};
        let status = "completed";
        if (!data.advert_type) {
            requirements.advert_type = true;
            status = "invalid";
        }
        return {
            advert_type: {
                status: status,
                requirements: requirements
            }
        }
    }

    checkEquipmentData(data) {
        let requirements = {};
        let status = "completed";
        if (!data.characteristics || data.characteristics.length === 0) {
            requirements.characteristics = true;
            status = "invalid";
        }
        let equipment = collectHousingListAttributes(data).equipment;
        if (equipment.length  === 0) {
            requirements.equipment = true;
            status = "invalid";
        }
        return {
            equipment: {
                status: status,
                requirements: requirements
            }
        }
    }

    checkImagesData(data) {
        let requirements = {};
        let status = "completed";
        let images = collectHousingListAttributes(data).images;
        if (images.length  === 0) {
            requirements.images = true;
            status = "warning";
        }
        return {
            images: {
                status: status,
                requirements: requirements
            }
        }
    }

    checkDescriptionData(data) {
        let requirements = {};
        let status = "completed";
        if (!data.title || data.title.length === 0) {
            requirements.title = true;
            status = "invalid";
        }
        if (!data.description || data.description.length === 0) {
            requirements.description = true;
            status = "invalid";
        }
        return {
            description: {
                status: status,
                requirements: requirements
            }
        };
    }

    checkPricingData(data) {
        let requirements = {};
        let status = "completed";
        let minBookingLength = data.min_booking_length;
        let maxBookingLength = data.max_booking_length;
        if (data.cancellation_conditions) {
            for (const c of data.cancellation_conditions) {
                if (!c.percent || c.days === undefined || c.days === null ||
                    c.clock_time === undefined || c.clock_time === null) {
                    requirements.cancellation_conditions = true;
                    status = "invalid";
                    break;
                }
            }
        }
        if (!data.bookable && (!data.rooms || !data.rooms.filter(r => r.bookable)[0])) {
            requirements.bookable = true;
            status = "invalid";
        }
        if (data.bookable) {
            if (minBookingLength < 30 &&
                (!data.pricing || !data.pricing.rent)) {
                requirements.main = { pricing: true };
                status = "invalid";
            }
            if (maxBookingLength > 29 &&
                (!data.long_term_pricing || !data.long_term_pricing.rent)) {
                requirements.main = { long_term_pricing: true };
                status = "invalid";
            }
        }
        if (data.rooms) {
            for (const room of data.rooms.filter(r => r.bookable)) {
                if (minBookingLength < 30 && (!room.pricing || !room.pricing.rent)) {
                    requirements[room.id] = { pricing: true};
                    status = "invalid";
                }
                if (maxBookingLength > 29 && (!room.long_term_pricing || !room.long_term_pricing.rent)) {
                    requirements[room.id] = { long_term_pricing: true};
                    status = "invalid";
                }
            }
        }
        return {
            pricing: {
                status: status,
                requirements: requirements
            }
        };
    }

    checkBookingSettingsData(data) {
        let requirements = {};
        let status = "completed";
        if (!data.booking_offset) {
            requirements.booking_offset = true;
            status = "invalid";
        }
        if (!data.access || data.access.length === 0) {
            requirements.access = true;
            status = "invalid";
        }
        else {
            let misc = data.access.filter(a => a.type === AccessType.miscellaneous)[0];
            if (misc &&
                (!misc.code || misc.code.length === 0)) {
                requirements.misc_access_description = true;
                status = "invalid";
            }
        }
        if (!data.terms_of_service || !data.revocation || !data.privacy_policy) {
            requirements.legal_docs = true;
            if (status !== "invalid") {
                status = "warning";
            }
        }
        return {
            booking_settings: {
                status: status,
                requirements: requirements
            }
        };
    }

    checkAllRequirements(advert) {
        let requirementMap = JSON.parse(JSON.stringify(this.REQUIREMENT_MAP));
        Object.assign(requirementMap, this.checkLocationData(advert));
        Object.assign(requirementMap, this.checkAdvertTypeData(advert));
        Object.assign(requirementMap, this.checkEquipmentData(advert));
        Object.assign(requirementMap, this.checkImagesData(advert));
        Object.assign(requirementMap, this.checkDescriptionData(advert));
        Object.assign(requirementMap, this.checkPricingData(advert));
        Object.assign(requirementMap, this.checkBookingSettingsData(advert));
        return requirementMap
    }

    checkForCompleteness() {
        let advertData = this.assignData(this.active_tab.current.extractData());
        let requirementMap = this.checkAllRequirements(advertData);
        this.setState({
            edited_advert: advertData,
            requirement_map: requirementMap
        });
        this.showDataCheckResult(requirementMap);
    }

    postAdvert(publish, sendNotification=false) {
        if (this.state.saving) {
            return;
        }
        this.setState({
            publishing: publish,
            saving: !publish,
            silent_saving: false,
            upload_progress: 0
        }, () => {
            let advert = this.assignData(this.active_tab.current.extractData());
            if (publish) {
                advert.published = true;
            }
            let that = this;
            postAdvert(advert, sendNotification, (response) => {
                let update = false;
                let user = JSON.parse(JSON.stringify(that.props.userData));
                if (response.widgets) {
                    unpackWidgetData(response.widgets);
                    user.widgets = response.widgets;
                    update = true;
                }
                if (!user.adverts) {
                    user.adverts = [response.data.id];
                    update = true;
                }
                else if (!user.adverts.includes(response.data.id)) {
                    user.adverts.push(response.data.id);
                    update = true;
                }
                if (update) {
                    that.props.onUpdateUserData?.(user);
                }
                let requirements = {...that.state.requirement_map};
                if (advert.id) {
                    requirements = that.checkAllRequirements(response.data);
                }
                response.toPublish = publish;
                that.setState({
                    edited_advert: JSON.parse(JSON.stringify(response.data)),
                    original_advert: response.data,
                    saving: false,
                    publishing: false,
                    silent_saving: false,
                    requirement_map: requirements,
                    response: response
                });
            }, (event) => {
                this.setState({
                    upload_progress: Math.round((100 * event.loaded) / event.total)
                });
            });
        });
    }

    showDataCheckResult(requirements) {
        if (this.alert_modal.current) {
            let title = LANG_PATH + "alerts>data_check_title";
            let success = Object.values(requirements).filter(r => r.status === "invalid").length === 0;
            let mainText = getLanguageEntry(LANG_PATH + "alerts>data_check_" + (success ? "success" : "failure"));
            let imageWarning = requirements.images.status === "warning" ?
                getLanguageEntry(LANG_PATH + "alerts>data_check_missing_images") : null;
            let locationWarning = requirements.location.status === "warning" ?
                getLanguageEntry(LANG_PATH + "alerts>data_check_missing_location") : null;
            let content = <div className="description-container">
                <p>{mainText}</p>
                {
                    imageWarning && <p>
                        <FontAwesomeIcon style={{marginRight: "3px", color: "orange"}} icon={["fal", "warning"]}/>
                        {imageWarning}
                    </p>
                }
                {
                    locationWarning && <p>
                        <FontAwesomeIcon style={{marginRight: "3px", color: "orange"}} icon={["fal", "warning"]}/>
                        {locationWarning}
                    </p>
                }
            </div>;
            let image = success ?
                "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/hardfacts/thumbs_up.jpg" :
                "https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear.png";
            let onAccept = () => { this.alert_modal.current.hide(); }
            this.alert_modal.current.show(title, content, onAccept, null, "OK", null, null, image, success ? "rounded": "");
        }
    }

    callReportModal(reason) {
        if (this.report_modal.current) {
            this.report_modal.current.show(null, reason)
        }
    }

}
AdvertEditor.propTypes = {
    userData: PropTypes.any.isRequired,
    navHook: PropTypes.func.isRequired,
    onUpdateUserData: PropTypes.func.isRequired
}
export default AdvertEditor;