import {getTimeUnitInMS, TIME_UNIT_VALUE_MAP} from "./Helper.js";
// @ts-ignore
import Cache from "./cache/Cache.ts";
// @ts-ignore
import HousingCacheObject from "./cache/objects/HousingCacheObject.ts";
// @ts-ignore
import CacheObject from "./cache/objects/CacheObject.ts";
// @ts-ignore
import CacheList from "./cache/CacheList.ts";
// @ts-ignore
import StatisticCache from "./cache/StatisticCache.ts";
// @ts-ignore
import InvoiceCache from "./cache/InvoiceCache.ts";
// @ts-ignore
import PayoutCache from "./cache/PayoutCache.ts";

export const USER_CACHE = new Cache<CacheObject>(getTimeUnitInMS(60, TIME_UNIT_VALUE_MAP.MINUTE));
export const HOUSING_CACHE = new Cache<HousingCacheObject>(getTimeUnitInMS(60, TIME_UNIT_VALUE_MAP.MINUTE));
export const SEARCH_CACHE = new Cache<CacheObject>(getTimeUnitInMS(10, TIME_UNIT_VALUE_MAP.MINUTE));
export const ADVERT_CACHE = new Cache<CacheObject>();
export const RECOMMENDATION_CACHE = new CacheList(getTimeUnitInMS(15, TIME_UNIT_VALUE_MAP.MINUTE));
export const FAVORITE_CACHE = new CacheList(getTimeUnitInMS(60, TIME_UNIT_VALUE_MAP.MINUTE));
export const STATISTIC_CACHE = new StatisticCache();
export const PAYOUT_CACHE = new PayoutCache();
export const BOOKING_INVOICES_CACHE = new InvoiceCache();
export const UNPAID_RENTS_CACHE = new CacheList((getTimeUnitInMS(30, TIME_UNIT_VALUE_MAP.MINUTE)));
export const PAYMENT_INTENT_CACHE = new Cache<CacheObject>(getTimeUnitInMS(60, TIME_UNIT_VALUE_MAP.MINUTE));
export const BOOKING_CACHE = new Cache<CacheObject>(getTimeUnitInMS(10, TIME_UNIT_VALUE_MAP.MINUTE));
export const TODO_CACHE = new CacheList(getTimeUnitInMS(60, TIME_UNIT_VALUE_MAP.MINUTE));
export const CONVERSATION_CACHE = new CacheList(getTimeUnitInMS(60, TIME_UNIT_VALUE_MAP.MINUTE));
export const WIDGET_CACHE = new Cache<CacheObject>(getTimeUnitInMS(15, TIME_UNIT_VALUE_MAP.MINUTE));

export function resetCache() {
    USER_CACHE.reset();
    HOUSING_CACHE.reset();
    SEARCH_CACHE.reset();
    ADVERT_CACHE.reset();
    RECOMMENDATION_CACHE.reset();
    FAVORITE_CACHE.reset();
    STATISTIC_CACHE.reset();
    PAYOUT_CACHE.reset();
    BOOKING_INVOICES_CACHE.reset();
    UNPAID_RENTS_CACHE.reset();
    BOOKING_CACHE.reset();
    TODO_CACHE.reset();
    CONVERSATION_CACHE.reset();
    WIDGET_CACHE.reset();
}