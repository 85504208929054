import React from "react";
import PropTypes from "prop-types";
import "../../css/components/review-viewer.css";
import {getFormattedDate, getLanguageEntry, getNextPage, USER_REVIEW_CRITERIA_MAP} from "../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getRatingInformationOfAdverts, getRatingInformationOfUser} from "../utils/StatisticHelper";
import _uniqueId from "lodash/uniqueId";
import {requestReviewAuthors} from "../utils/RESTInterface";
import Avatar from "./Avatar";
import {USER_CACHE} from "../utils/CacheHandler.ts";
import {AdvertReviewCriteria, Mode, ReviewType} from "../utils/Types.ts";

class ReviewViewer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            review_per_page: 6,
            authors: {},
            reviews: this.props.reviews.slice(0)
        }
    }

    componentDidMount() {
        if (this.props.reviews !== undefined) {
            let authors = {};
            let authorIDs = this.props.reviews
                .filter(r => {
                    let cachedUser = USER_CACHE.getCacheObjectData(r.author_id);
                    if (cachedUser) {
                        authors[r.author_id] = cachedUser;
                        return false;
                    }
                    return true;
                })
                .map((r, i) => r.author_id);
            if (authorIDs.length > 0) {
                let that = this;
                requestReviewAuthors(authorIDs, function(response) {
                    if (response.users !== undefined) {
                        for (const user of response.users) {
                            authors[user.id] = user;
                        }
                        let reviews = that.state.reviews.filter(r => authors[r.author_id] !== undefined);
                        that.setState({
                            authors: authors,
                            reviews: reviews
                        });
                    }
                });
            }
            else {
                let reviews = this.state.reviews.filter(r => authors[r.author_id] !== undefined);
                this.setState({
                    authors: authors,
                    reviews: reviews
                });
            }
        }
    }

    render() {
        let start = this.state.page * this.state.review_per_page;
        let end = (this.state.page + 1) * this.state.review_per_page;
        let visibleReviews = this.state.reviews.slice(start, end);
        let ratingObject;
        let ratingPool;
        if (this.props.reviewType === ReviewType.advert) {
            ratingObject = getRatingInformationOfAdverts([{reviews: this.state.reviews, published: true, complete: true}]);
            ratingPool = Object.values(AdvertReviewCriteria);
        }
        else {
            ratingObject = getRatingInformationOfUser(this.state.reviews, -1, -1, this.props.user_mode);
            ratingPool = Object.values(USER_REVIEW_CRITERIA_MAP.general)
                .concat(Object.values(USER_REVIEW_CRITERIA_MAP[this.props.user_mode]));
        }
        return (
            <div className="review-viewer">
                {
                    (!this.state.reviews || this.state.reviews.length === 0) &&
                    <div className="no-review-placeholder description-container">
                        {
                            this.props.reviewType === ReviewType.advert ?
                                getLanguageEntry("components>reviews>advert_review>no_review_available") :
                                getLanguageEntry("components>reviews>user_review>no_review_available")
                                    .replace("#", this.props.target.first_name)
                        }
                    </div>
                }
                {
                    this.state.reviews !== undefined && this.state.reviews.length > 0 &&
                    <div className="review-grid">
                        <div className="general-rating">
                            <FontAwesomeIcon icon={["fas", "fa-star"]} />
                            <span>
                                {
                                    ratingObject.rating_value === 0 ? "-" :
                                        ('' + ratingObject.rating_value.toFixed(1))
                                            .replace('.', ',')
                                }
                            </span>
                            <span>
                                ({
                                this.state.reviews.length + ' ' +
                                getLanguageEntry("general>rating" + (this.state.reviews.length > 1 ? "s" : ""))
                                })
                            </span>
                        </div>
                        <div className="rating-value-container">
                            {
                                ratingPool.map((r, i) => {
                                    return <div className="rating-value-bar" key={_uniqueId(r)}>
                                        <div className="rating-type">
                                            {getLanguageEntry("components>reviews>" + this.props.reviewType + "_review>ratings>" + r)}
                                        </div>
                                        <div className="statistic-bar-bg">
                                            <div className="statistic-bar-fg" style={{width: (ratingObject[r] / 5 * 100) + '%'}}></div>
                                        </div>
                                        <div className="rating-value">{ratingObject[r].toFixed(1).replace('.', ',')}</div>
                                    </div>
                                })
                            }
                        </div>
                        <div className="text-review-container">
                            {
                                visibleReviews.map((r, i) => {
                                    let author = this.state.authors[r.author_id];
                                    return author !== undefined ? <div className="text-review-item" key={_uniqueId(r.author_id)}>
                                        <div className="text-review-header">
                                            <Avatar data={author.avatar} size={40} />
                                            <div className="header-info">
                                                <div className="author-name">{author.first_name}</div>
                                                <div className="timestamp">
                                                    {getFormattedDate(new Date(r.timestamp), false, false, false, false)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-review-content">{r.description}</div>
                                    </div> : ''
                                })
                            }
                        </div>
                        {
                            this.state.review_per_page <= this.state.reviews.length &&
                            <div className="pagination">
                                <button onClick={() => { this.getNextPage(-1) } } disabled={this.state.page <= 0}>
                                    <FontAwesomeIcon icon={["fal", "chevron-left"]}/>
                                </button>
                                {
                                    Array.from(Array(Math.ceil(this.state.reviews.length / this.state.review_per_page))).map((_, page) => {
                                        return <button key={"review-page-" + page}
                                                       onClick={() => { this.setState({page: page})}}
                                                       className={this.state.page === page ? "selected": ""}>
                                            {page + 1}
                                        </button>
                                    })
                                }
                                <button onClick={() => { this.getNextPage(1) } }
                                        disabled={(this.state.page + 1) * this.state.review_per_page >= this.state.result_count}>
                                    <FontAwesomeIcon icon={["fal", "chevron-right"]}/>
                                </button>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }

    getNextPage(direction) {
        this.setState({page: getNextPage(this.state.page, direction, Math.ceil(this.state.result_count / this.state.review_per_page))})
    }

}

ReviewViewer.propTypes = {
    reviewType: PropTypes.oneOf(Object.values(ReviewType)).isRequired,
    reviews: PropTypes.arrayOf(PropTypes.object),
    target: PropTypes.object,
    user_mode: PropTypes.oneOf(Object.values(Mode))
}
ReviewViewer.defaultProps = {
    user_mode: Mode.landlord
}
export default ReviewViewer;