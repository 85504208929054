import React from "react";
import PropTypes from "prop-types";
import "../../css/pages/expose.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    buildTextLinkContainer, calculateInvoice, collectHousingListAttributes, convertMilliseconds, copyTextToClipboard,
    determineHousingRatingLabel, determineTargetHousing, filterDataToQuery,
    getFormattedDate, getLanguageEntry, getNightsBetweenDates, getReviewsOfHousing, getTimeUnitInMS, priceToString,
    queryParamsToFilterData, TIME_UNIT_VALUE_MAP, getLanguageNameList,
} from "../utils/Helper";
import {
    addFavoriteHousing, deleteFavoriteHousing, getHousing, incrementHousingCounter, proofHousingAvailability
} from "../utils/RESTInterface";
import {CHARACTERISTIC_ICON_MAP, EQUIPMENT_ICON_MAP} from "../../resources/housings";
import HousingProperty from "../components/advert/HousingProperty";
import GoogleMaps from "../components/google/GoogleMaps";
import Avatar from "../components/Avatar";
import _uniqueId from "lodash/uniqueId";
import {Modal} from "react-bootstrap";
import FetchButton from "../components/buttons/FetchButton";
import ReviewViewer from "../components/ReviewViewer";
import {HOUSING_CACHE} from "../utils/CacheHandler.ts";
import Calendar from "../components/Calendar";
import ReportModal from "../components/modals/ReportModal";
import MessageModal from "../components/modals/MessageModal";
import ImageViewer from 'react-simple-image-viewer';
import Loading from "../components/Loading";
import {BookingType, HousingCounterType, ReportReason, ReviewType, Mode} from "../utils/Types.ts";
import BookingModal from "../components/modals/BookingModal";
import parse from "html-react-parser";
import {Link} from "react-router-dom";

const VISIBLE_EQUIPMENT = 6;
const VISIBLE_HOUSE_RULES = 3;
const MAX_VISIBLE_DESCRIPTION_LENGTH_ON_MOBILE = 500;

class Expose extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            advert: null,
            target: null,
            housing_id: '',
            response: null,
            show_all_equipment: false,
            show_all_house_rules: false,
            show_share_modal: false,
            start: null,
            end: null,
            availability_message: null,
            availability_check_needed: true,
            checking_availability: false,
            show_jack_out: false,
            show_image_viewer: false,
            first_image_index: 0,
            truncate_description: window.innerWidth < 751
        }
        this.calendar = React.createRef();
        this.report_modal = React.createRef();
        this.message_modal = React.createRef();
        this.booking_modal = React.createRef();
        this.jackout_container = React.createRef();
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let filter = queryParamsToFilterData(params);
        let housingID = window.location.pathname.replace("/expose/", "");
        if (housingID.includes("?")) {
            housingID = housingID.substring(0, housingID.indexOf("?"));
        }
        let cachedHousingObject = HOUSING_CACHE.getCacheObject(housingID);
        let cachedHousing = cachedHousingObject ? cachedHousingObject.getData() : null;
        if (cachedHousing) {
            let target = determineTargetHousing(cachedHousing);
            let listAttributes = collectHousingListAttributes(cachedHousing, target);
            let state = {
                advert: cachedHousing,
                target: target,
                images: listAttributes.images,
                equipment: listAttributes.equipment,
                housing_id: housingID,
                start: filter.start,
                end: filter.end,
                availability_check_needed: cachedHousingObject.availabilityCheckNeeded(getTimeUnitInMS(10, TIME_UNIT_VALUE_MAP.MINUTE))
            }
            let adjustedFilter = {
                start: filter.start,
                end: filter.end,
            };
            window.history.replaceState(null, '', '/expose/' + housingID + filterDataToQuery(adjustedFilter));
            this.setState(state, function() {
                if (this.calendar.current !== null) {
                    this.calendar.current.setDates(this.state.start, this.state.end);
                }
            });
        }
        else {
            let that = this;
            this.setState({loading: true});
            getHousing(housingID, function(response) {
                if (response.errorCode !== undefined) {
                    console.log(response.errorCode + ": " + response.message);
                    that.props.navHook('/404');
                }
                else if (response.data !== undefined) {
                    let state = { loading: false };
                    state.advert = response.data;
                    state.target = determineTargetHousing(state.advert);
                    let listAttributes = collectHousingListAttributes(state.advert, state.target.id === state.advert.id ? null : state.target);
                    state.images = listAttributes.images;
                    state.equipment = listAttributes.equipment;
                    state.show_all_equipment = false;
                    state.show_all_house_rules = false;
                    state.show_share_modal = false;
                    state.housing_id = housingID;
                    state.start = filter.start;
                    state.end = filter.end;
                    state.availability_check_needed = true;
                    let adjustedFilter = {
                        start: filter.start,
                        end: filter.end
                    };
                    window.history.replaceState(null, '', '/expose/' + housingID + filterDataToQuery(adjustedFilter));
                    that.setState(state,function() {
                        if (this.calendar.current !== null) {
                            this.calendar.current.setDates(this.state.start, this.state.end);
                        }
                    });
                }
            });
        }
        window.addEventListener("resize", () => {
            this.handleResize();
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => {
            this.handleResize();
        });
    }

    render() {
        let equipmentList = [];
        let houseRuleList = [];
        let shareLink = '';
        let invoice = null;
        let relevantPricing = null;
        let lengthMS = this.state.start && this.state.end ? this.state.end - this.state.start : null;
        let directBooking = false;
        let inquiry = false;
        let contactOnly = false;
        if (this.state.advert !== null) {
            if (this.state.equipment !== undefined) {
                if (!this.state.show_all_equipment && this.state.equipment.length > VISIBLE_EQUIPMENT) {
                    equipmentList = this.state.equipment.slice(0, VISIBLE_EQUIPMENT);
                }
                else {
                    equipmentList = equipmentList.concat(this.state.equipment);
                }
            }
            if (this.state.advert !== null && this.state.advert.house_rules !== undefined) {
                if (!this.state.show_all_house_rules && this.state.advert.house_rules.length > VISIBLE_HOUSE_RULES) {
                    houseRuleList = this.state.advert.house_rules.slice(0, VISIBLE_HOUSE_RULES);
                }
                else {
                    houseRuleList = houseRuleList.concat(this.state.advert.house_rules);
                }
            }
            shareLink = window.location.origin + "/expose/" + this.state.housing_id;
            let dayCount = getNightsBetweenDates(this.state.end, this.state.start);
            let bookingOffsetDays = convertMilliseconds(this.state.start.getTime() - Date.now(), 'day', 'floor');
            relevantPricing = dayCount >= 30 && this.state.target.long_term_pricing ?
                this.state.target.long_term_pricing : (this.state.target.pricing ?? this.state.target.long_term_pricing);
            invoice = calculateInvoice(this.state.start, this.state.end, bookingOffsetDays, relevantPricing);
            directBooking = this.state.advert.booking_type === BookingType.direct && lengthMS < getTimeUnitInMS(365) &&
                (this.state.advert.owner.b2b || lengthMS < getTimeUnitInMS(30));
            inquiry = lengthMS < getTimeUnitInMS(365) && (this.state.advert.booking_type === BookingType.inquiry ||
                (!this.state.advert.owner.b2b && lengthMS >= getTimeUnitInMS(30)));
            contactOnly = lengthMS >= getTimeUnitInMS(365);
        }
        return (
            <div id="expose">
                {
                    this.state.loading &&
                    <Loading imageWidth="15vw"/>
                }
                {
                    !this.state.loading && this.state.advert !== null &&
                    <div className="container">
                        <div className="expose-header">
                            {
                                this.state.advert.reg_no !== undefined &&
                                <div className="advert-registration-no">
                                    {getLanguageEntry("advert_attributes>registration_number_short") +
                                        ": " + this.state.advert.reg_no
                                    }
                                </div>
                            }
                            <div className="expose-main-column">
                                <div className="housing-type-label">{
                                    this.state.target.id === this.state.advert.id ?
                                        getLanguageEntry("advert_attributes>advert_types>" + this.state.target.type) :
                                        (this.state.target.type === undefined ?
                                            getLanguageEntry("advert_attributes>advert_types>apartment") :
                                            getLanguageEntry("advert_attributes>room_types>" + this.state.target.type))
                                }</div>
                                <h1 className="housing-title-label">{this.state.advert.title}</h1>
                                {/*TODO*/}
                                {/*<validatable-dropdown className="expose-housing-selector"></validatable-dropdown>*/}
                                <div className="housing-good-to-know">
                                    <div className="rating">
                                        <FontAwesomeIcon icon={["fas", "star"]}/>
                                        <span className="rating-label">{getLanguageEntry("general>rating")}</span>
                                        <span className="rating-value">
                                            {determineHousingRatingLabel(this.state.advert, this.state.target)}
                                        </span>
                                    </div>
                                    {
                                        this.state.target.surface_size !== undefined &&
                                        <div className="room-size">
                                            <FontAwesomeIcon icon={["fal", "expand-arrows"]}/>
                                            <span className="room-size-label">{getLanguageEntry("search_page>filters>living_space")}</span>
                                            <span className="room-size-value">{this.state.target.surface_size} m²</span>
                                        </div>
                                    }
                                    <div className="small-screen-header-buttons">
                                        <button className="share-button" onClick={() => { this.callShareModal() }}>
                                            <FontAwesomeIcon icon={["fal", "share-square"]}/>
                                            <span>{getLanguageEntry("expose>buttons>share")}</span>
                                        </button>
                                        <button className="favorite-marker" onClick={(e) => { this.handleFavorit(e) }}>
                                            <FontAwesomeIcon className="outline" icon={["fal", "heart"]} />
                                            <FontAwesomeIcon className={this.getFavoriteIconClass()} icon={["fas", "heart"]}/>
                                            <span>{getLanguageEntry("expose>buttons>favorize")}</span>
                                        </button>
                                        <button className="report-button" onClick={() => {
                                            this.callReportModal(ReportReason.advert_report)
                                        }}>
                                            <FontAwesomeIcon icon={["fal", "bullhorn"]} />
                                            <span>{getLanguageEntry("expose>buttons>report_advert")}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="expose-side-column">
                                <button className="share-button" onClick={() => { this.callShareModal() }}>
                                    <FontAwesomeIcon icon={["fal", "share-square"]}/>
                                    <span>{getLanguageEntry("expose>buttons>share")}</span>
                                </button>
                                <button className="favorite-marker" onClick={(e) => { this.handleFavorit(e) }}>
                                    <FontAwesomeIcon className="outline" icon={["fal", "heart"]} />
                                    <FontAwesomeIcon className={this.getFavoriteIconClass()} icon={["fas", "heart"]}/>
                                    <span>{getLanguageEntry("expose>buttons>favorize")}</span>
                                </button>
                                <button className="report-button" onClick={() => {
                                    this.callReportModal(ReportReason.advert_report)
                                }}>
                                    <FontAwesomeIcon icon={["fal", "bullhorn"]} />
                                    <span>{getLanguageEntry("expose>buttons>report_advert")}</span>
                                </button>
                            </div>
                        </div>
                        <div className="housing-image-viewer">
                            <div className="expose-main-column">
                                {
                                    this.state.images.length > 0 &&
                                    <img src={this.state.images[0]} alt="Advert"
                                         onClick={() => {this.openImageViewer(0)}}/>
                                }
                            </div>
                            <div className="expose-side-column">
                                <div className="next-image">
                                    {
                                        this.state.images.length > 1 &&
                                        <img src={this.state.images[1]} alt="Advert"
                                             onClick={() => {this.openImageViewer(1)}}/>
                                    }
                                </div>
                                <div className="next-image">
                                    {
                                        this.state.images.length > 2 &&
                                        <img src={this.state.images[2]} alt="Advert"
                                             onClick={() => {this.openImageViewer(2)}}/>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="housing-detail-container">
                            <div className="expose-main-column">
                                <div className="roomjack-container">
                                    <h4 className="housing-description-label">
                                        {getLanguageEntry("advert_attributes>description")}
                                    </h4>
                                    <div className="housing-description housing-text-container">
                                        <div>{this.getDescriptionText()}</div>
                                        {
                                            this.state.advert && this.state.advert.description &&
                                            this.state.advert.description.length > MAX_VISIBLE_DESCRIPTION_LENGTH_ON_MOBILE &&
                                            <button className="outline-button black description-toggle"
                                                    onClick={() => { this.toggleDescription() }}>
                                                { this.state.truncate_description ?
                                                    getLanguageEntry("expose>buttons>show_more") :
                                                    getLanguageEntry("expose>buttons>show_less")}
                                            </button>
                                        }
                                    </div>
                                </div>
                                {
                                    this.state.advert.characteristics !== undefined &&
                                    <div className="characteristics roomjack-container">
                                        <h4 className="characteristics-label">
                                            {getLanguageEntry("expose>labels>housing_attributes_label")}
                                        </h4>
                                        <div className="characteristics-grid">
                                            {
                                                this.state.advert.characteristics.map((c, i) => {
                                                    let langEntryPath = 'advert_attributes>characteristics>' + c;
                                                    return <HousingProperty key={c} icon={CHARACTERISTIC_ICON_MAP[c]}
                                                                            value={c} name={langEntryPath}/>
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                <div className="interactive">
                                    {
                                        this.state.advert.address.location !== undefined &&
                                        <GoogleMaps adverts={[this.state.advert]} className="housing-tile map"
                                                    zoom={15}/>
                                    }
                                    <Link className="housing-tile contact" to={"/user/" + this.state.advert.owner.id + "?mode=landlord"}>
                                        <Avatar className="owner-avatar" size={128} data={this.state.advert.owner.avatar} />
                                        <div className="contact-owner-label housing-tile-label">
                                            {
                                                getLanguageEntry("expose>labels>contact_owner_label")
                                                    .replace("@", this.state.advert.owner.first_name)
                                            }
                                        </div>
                                        {
                                            this.state.advert.owner.languages &&
                                            <div className="owner-languages housing-tile-detail-container">
                                                {this.getLanguageLabel()}
                                            </div>
                                        }
                                        <button className="contact-owner accent-icon-button fill"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault()
                                                    this.callMessageModal() }}>
                                            {getLanguageEntry("general>send_message")}
                                        </button>
                                    </Link>
                                    <div className="housing-tile share">
                                        <div className="image-mask">
                                            <div className="image-background"></div>
                                            <img className="rounded" src="https://roomjack.blob.core.windows.net/roomjack/content-images/share.png" alt="Share advert" />
                                        </div>
                                        <div className="share-housing-label housing-tile-label">
                                            {getLanguageEntry("expose>labels>share_with_friends_label")}
                                        </div>
                                        <div className="housing-tile-detail-container">
                                            <FontAwesomeIcon icon={["fab", "facebook-f"]}/>
                                            <FontAwesomeIcon icon={["fab", "whatsapp"]}/>
                                            <FontAwesomeIcon icon={["fab", "twitter"]}/>
                                            <FontAwesomeIcon icon={["fal", "envelope"]}/>
                                        </div>
                                        <button className="share-housing accent-icon-button fill"
                                                onClick={() => { this.callShareModal() }}>
                                            {getLanguageEntry("expose>buttons>share_now")}
                                        </button>
                                    </div>
                                </div>
                                <div className="roomjack-container">
                                    <h4 className="housing-equipment-label">
                                        {getLanguageEntry("expose>labels>housing_equipment_label")}
                                    </h4>
                                    {
                                        equipmentList.length > 0 &&
                                        <div className="housing-equipment-grid">
                                            {
                                                equipmentList.map((e, i) => {
                                                    let icon = EQUIPMENT_ICON_MAP[e] === undefined ?
                                                        ["fal", "box-open"] : EQUIPMENT_ICON_MAP[e];
                                                    let langEntryPath = 'advert_attributes>equipment>' + e;
                                                    return <HousingProperty icon={icon} key={e} value={e}
                                                                            name={langEntryPath}/>
                                                })
                                            }
                                            {
                                                this.state.equipment.length > VISIBLE_EQUIPMENT &&
                                                <button className="outline-button black equipment-toggle"
                                                        onClick={() => { this.toggleEquipments() }}>
                                                    { this.state.show_all_equipment ?
                                                        getLanguageEntry("expose>buttons>equipment_toggle_hide") :
                                                        getLanguageEntry("expose>buttons>equipment_toggle_show")
                                                        .replace("#", '' + this.state.equipment.length)}
                                                </button>
                                            }
                                        </div>
                                    }
                                    {
                                        equipmentList.length === 0 &&
                                        <div className="description-container">
                                            {getLanguageEntry("expose>labels>no_equipment_placeholder")}
                                        </div>
                                    }
                                </div>
                                <div className="housing-house-rules roomjack-container">
                                    <h4 className="house-rules-label">
                                        {getLanguageEntry("advert_attributes>house_rules>name")}
                                    </h4>
                                    {
                                        this.state.advert.house_rules && this.state.advert.house_rules.length > 0 &&
                                        <div className="housing-house-rules-grid">
                                            {
                                                houseRuleList.map((hr, i) => {
                                                    let icon = ["fal", "scroll"];
                                                    let langEntryPath = 'advert_attributes>house_rules>list>' + hr.type;
                                                    return <HousingProperty icon={icon} value={hr} key={_uniqueId("house_rule-" + i)}
                                                                            name={langEntryPath}/>
                                                })
                                            }
                                            {
                                                this.state.advert.house_rules.length > VISIBLE_HOUSE_RULES &&
                                                <button className="outline-button black equipment-toggle"
                                                        onClick={() => { this.toggleHouseRules() }}>
                                                    { this.state.show_all_house_rules ?
                                                        getLanguageEntry("expose>buttons>house_rules_toggle_hide") :
                                                        getLanguageEntry("expose>buttons>house_rules_toggle_show")
                                                            .replace("#", '' + this.state.advert.house_rules.length)}
                                                </button>
                                            }
                                        </div>
                                    }
                                    {
                                        (!this.state.advert.house_rules || this.state.advert.house_rules.length === 0) &&
                                        <div className="description-container">
                                            {getLanguageEntry("expose>labels>no_house_rules_placeholder")}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="jack-out expose-side-column">
                                <div className={"jack-out-container" + (this.state.show_jack_out ? "" : " collapsed")}
                                     tabIndex={0} onBlur={(e) => {this.handleJackOutBlur(e)}}
                                     onFocus={(e) => {this.handleJackOutFocus()}}
                                     ref={this.jackout_container}>
                                    {
                                        relevantPricing !== null &&
                                        <div className="price-per-unit-label">
                                            {getLanguageEntry("search_page>" + (relevantPricing.long_term ? "price_per_month" : "price_per_day"))}
                                        </div>
                                    }
                                    {
                                        invoice !== null &&
                                        <div className="price-per-unit">
                                            {priceToString(invoice.rent_per_unit, true)}
                                        </div>
                                    }
                                    {
                                        invoice !== null &&
                                        <div className="price-sum-label">
                                            {getLanguageEntry("search_page>sum")}: <b>{priceToString(invoice.total_with_discount)}</b>
                                        </div>
                                    }
                                    <Calendar hAlign="right" labels={{start: "Jack-in", end: "Jack-out"}} ref={this.calendar}
                                              onChange={(data) => {
                                                  this.setState({
                                                      availability_check_needed: true,
                                                      availability_message: null,
                                                      start: data.start,
                                                      end: data.end,
                                                      response: null
                                                  })
                                              }}/>
                                    {
                                        this.getAvailabilityMessage()
                                    }
                                    {
                                        // for the moment, we only allow a length less than a year
                                        !this.state.availability_check_needed &&
                                        <button className="start-booking-checkout-button accent-icon-button"
                                                onClick={() => {contactOnly ?
                                                    this.callMessageModal() : this.callBookingModal()}}>
                                            {
                                                getLanguageEntry(directBooking ? "expose>buttons>book_now" :
                                                    (inquiry ? "expose>buttons>inquiry_now" : "processes>booking>contact_owner"))
                                            }
                                        </button>
                                    }
                                    {
                                        this.state.availability_check_needed &&
                                        <FetchButton className="check-availability-button accent-icon-button"
                                                     loading={this.state.checking_availability}
                                                     loadingText="expose>buttons>check_availability_loading"
                                                     onClick={() => { this.startAvailabilityCheck() }}>
                                            {getLanguageEntry("expose>buttons>check_availability")}
                                        </FetchButton>
                                    }
                                    <button className="show-jack-out" onClick={() => { this.showJackOut(true) }}>
                                        <FontAwesomeIcon icon={["fas", "chevron-double-up"]}/>
                                    </button>
                                    <button className="hide-jack-out" onClick={() => { this.showJackOut(false) }}>
                                        <FontAwesomeIcon icon={["fas", "times-circle"]}/>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="housing-review-container roomjack-container">
                            <ReviewViewer reviewType={ReviewType.advert} target={this.state.target}
                                          reviews={getReviewsOfHousing(this.state.advert, this.state.target)} />
                        </div>
                        <Modal show={this.state.show_share_modal} className="share-modal" centered>
                            <Modal.Header>
                                <h4>{getLanguageEntry("expose>labels>share_housing")}</h4>
                                <button onClick={() => {this.setState({show_share_modal: false})}}>
                                    <FontAwesomeIcon icon={["fal", "close"]}/>
                                </button>
                            </Modal.Header>

                            <Modal.Body>
                                <a className="share-button facebook" target="_blank" rel="noreferrer nofollow"
                                   href={"https://facebook.com/sharer/sharer.php?u=" + shareLink}
                                   onClick={() => { incrementHousingCounter(this.state.housing_id, HousingCounterType.sharing) }}>
                                    <FontAwesomeIcon icon={["fab", "facebook-f"]} fixedWidth={true} />
                                    <span>Facebook</span>
                                </a>
                                <a className="share-button whatsapp" target="_blank" rel="noreferrer nofollow"
                                   href={'https://api.whatsapp.com/send?text=' + this.state.advert.title + ' ' + shareLink}
                                   onClick={() => { incrementHousingCounter(this.state.housing_id, HousingCounterType.sharing) }}>
                                    <FontAwesomeIcon icon={["fab", "whatsapp"]} fixedWidth={true} />
                                    <span>WhatsApp</span>
                                </a>
                                <a className="share-button twitter" target="_blank" rel="noreferrer nofollow"
                                   href={"https://twitter.com/intent/tweet?url=" + shareLink}
                                   onClick={() => { incrementHousingCounter(this.state.housing_id, HousingCounterType.sharing) }}>
                                    <FontAwesomeIcon icon={["fab", "twitter"]} fixedWidth={true} />
                                    <span>Twitter</span>
                                </a>
                                <a className="share-button email" href={this.createEmailShareLink(shareLink)}
                                   onClick={() => { incrementHousingCounter(this.state.housing_id, HousingCounterType.sharing) }}>
                                    <FontAwesomeIcon icon={["fal", "envelope"]} fixedWidth={true} />
                                    <span>Email</span>
                                </a>
                                <label htmlFor="expose-share-link">
                                    {getLanguageEntry("expose>labels>copy_link")}
                                </label>
                                <div>
                                    <input className="share-link" id="expose-share-link" readOnly={true}
                                           defaultValue={shareLink} />
                                    <button onClick={() => {
                                        copyTextToClipboard(shareLink);
                                        incrementHousingCounter(this.state.housing_id, HousingCounterType.sharing)
                                    }}>
                                        <FontAwesomeIcon icon={["fal", "copy"]} />
                                    </button>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                }
                <ReportModal ref={this.report_modal} userData={this.props.userData} />
                <MessageModal ref={this.message_modal} userData={this.props.userData}
                              onUpdateUserData={this.props.onUpdateUserData}/>
                <BookingModal ref={this.booking_modal} userData={this.props.userData}
                              onContactSupport={() => {this.callReportModal(ReportReason.bug_report)}}
                              onUpdateUserData={this.props.onUpdateUserData} mode={this.props.mode}
                              onSwitchMode={this.props.onSwitchMode} onHide={(bookingSuccess) => {
                                  if (bookingSuccess) {
                                      this.setState({availability_check_needed: null});
                                  }}}/>
                {
                    this.state.show_image_viewer &&
                    <ImageViewer src={this.state.images} currentIndex={this.state.first_image_index}
                                 closeOnClickOutside={true} disableScroll={true}
                                 onClose={() => {this.setState({show_image_viewer: false})}}
                                 backgroundStyle={{zIndex: 11}}/>
                }
            </div>
        )
    }

    getLanguageLabel() {
        let langList = getLanguageNameList(this.state.advert.owner.languages);
        if (langList.length === 0) {
            return "";
        }
        let label = getLanguageEntry("expose>labels>speaks") + " ";
        if (langList.length > 2) {
            label += langList.slice(0, 2).join(", ") + "<br>" + getLanguageEntry("general>and")
                + " " + getLanguageEntry("expose>labels>more_langauges")
        }
        else {
            label += langList.join(" " + getLanguageEntry("general>and") + " ");
        }
        return parse(label);
    }

    getFavoriteIconClass() {
        let className = "inner";
        if (this.props.userData && this.props.userData.favorite_housings &&
            this.props.userData.favorite_housings.includes(this.state.housing_id)) {
            className += " selected";
        }
        return className;
    }

    handleFavorit(e) {
        e.preventDefault();
        if (this.props.userData === undefined || this.props.userData === null) {
            this.props.callLogin();
        }
        else {
            let that = this;
            if (this.props.userData.favorite_housings === undefined ||
                !this.props.userData.favorite_housings.includes(this.state.housing_id)) {
                addFavoriteHousing(this.state.housing_id, (response) => {
                    if (response.errorCode !== undefined) {
                        console.error(response.errorCode + ": " + response.message);
                    }
                    else {
                        let user = JSON.parse(JSON.stringify(this.props.userData));
                        if (!user.favorite_housings) {
                            user.favorite_housings = [response.housing_id];
                            that.props.onUpdateUserData?.(user);
                        }
                        else {
                            if (!user.favorite_housings.filter(id => id === response.housing_id)[0]) {
                                user.favorite_housings.push(response.housing_id);
                                that.props.onUpdateUserData?.(user);
                            }
                        }
                    }
                });
            }
            else {
                deleteFavoriteHousing(this.state.housing_id, (response) => {
                    if (response.errorCode !== undefined) {
                        console.error(response.errorCode + ": " + response.message);
                    }
                    else {
                        let user = JSON.parse(JSON.stringify(that.props.userData));
                        if (user.favorite_housings !== undefined) {
                            user.favorite_housings = user.favorite_housings.filter(id => id !== response.housing_id);
                            that.props.onUpdateUserData?.(user);
                        }
                    }
                });
            }
        }
    }

    toggleEquipments() {
        this.setState({show_all_equipment: !this.state.show_all_equipment});
    }

    toggleHouseRules() {
        this.setState({show_all_house_rules: !this.state.show_all_house_rules});
    }

    toggleDescription() {
        this.setState({truncate_description: !this.state.truncate_description});
    }

    callShareModal() {
        this.setState({show_share_modal: !this.state.show_share_modal});
    }

    createEmailShareLink(shareLink) {
        let emailBody = this.props.userData === undefined || this.props.userData === null ?
            getLanguageEntry("expose>share_housing_mail_text_anonymous").replace('@', shareLink) :
            getLanguageEntry("expose>share_housing_mail_text").replace('@', shareLink)
                .replace('#', this.props.userData.first_name);
        let emailSubject = 'roomjack - ' + this.state.advert.title;
        return 'mailto:?to=&body=' + emailBody + '&subject=' + emailSubject;
    }

    startAvailabilityCheck() {
        if (this.calendar.current.getSelectedStart() === null) {
            this.calendar.current.onStartClick();
            return;
        }
        if (this.calendar.current.getSelectedEnd() === null) {
            this.calendar.current.onEndClick();
            return;
        }
        this.setState({ checking_availability: true });
        let roomID = null;
        let split = this.state.housing_id.split("SP");
        if (split.length > 1) {
            if (split[1].startsWith("room")) {
                roomID = split[1];
            }
        }
        let that = this;
        proofHousingAvailability(this.state.advert.id, roomID,
            this.calendar.current.getSelectedStart().getTime(), this.calendar.current.getSelectedEnd().getTime(),
            function (response) {
                let state = {
                    response: response,
                    checking_availability: false
                };
                let errorCode = response.errorCode;
                if (errorCode !== undefined) {
                    state.availability_message = errorCode
                }
                else {
                    state.availability_check_needed = false;
                    state.availability_message = "success";
                    if (that.state.target && response.available_count) {
                        let target = JSON.parse(JSON.stringify(that.state.target));
                        target.available_count = response.available_count;
                        state.target = target;
                    }
                }
                that.setState(state);
            });
    }

    getAvailabilityMessage() {
        if (this.state.availability_message) {
            switch (this.state.availability_message) {
                case 'ad_availability_err_0':
                case 'ad_availability_err_2':
                case 'ad_availability_err_3':
                case 'ad_availability_err_5':
                case 'ad_availability_err_8':
                case 'ad_availability_err_9':
                case 'ad_availability_err_10':
                    return buildTextLinkContainer("availability-message-container",
                        "error_codes>" + this.state.availability_message, [{
                            text_path: "general>support",
                            url: "mailto:support@roomjack.at"
                        }]);
                case 'ad_availability_err_7':
                    let today = new Date();
                    today.setDate(today.getDate() + this.state.advert.booking_offset + 1);
                    return <div className="availability-message-container">
                        {getLanguageEntry("error_codes>" + this.state.availability_message)
                            .replace('#', getFormattedDate(today, true, false, false, true))}
                    </div>;
                case 'ad_availability_err_13':
                    return <div className="availability-message-container">
                        {getLanguageEntry("error_codes>" + this.state.availability_message)
                            .replace('x', '' + this.state.advert.min_booking_length)
                            .replace('y', '' + this.state.advert.max_booking_length)}
                    </div>;
                case 'success':
                    return <div className="availability-message-container">
                        {getLanguageEntry("expose>housing_available")}
                    </div>;
                default:
                    return <div className="availability-message-container">
                        {getLanguageEntry(`error_codes>${this.state.availability_message}`)}
                    </div>;
            }
        }
    }

    callMessageModal() {
        if (this.props.userData) {
            this.message_modal.current.show(this.state.advert.owner);
        }
        else {
            this.props.callLogin();
        }
    }

    callReportModal(reason=ReportReason.advert_report) {
        this.report_modal.current.show(reason === ReportReason.advert_report ? this.state.housing_id : null, reason);
    }

    callBookingModal(checkoutSessionData) {
        if (this.props.userData) {
            this.booking_modal.current.show(this.state.advert, this.state.target,
                this.state.start, this.state.end);
        }
        else {
            this.props.callLogin();
        }
    }

    showJackOut(show) {
        if (show && this.jackout_container.current) {
            this.jackout_container.current.focus()
        }
        if (!show) {
            this.setState({show_jack_out: false})
        }
    }

    handleJackOutFocus() {
        if (window.innerWidth <= 990 && this.jackout_container.current) {
            if (!this.state.show_jack_out) {
                this.setState({show_jack_out: true});
            }
        }
    }

    handleJackOutBlur(e) {
        if (window.innerWidth <= 990 && this.jackout_container.current) {
            if (!this.jackout_container.current.contains(e.relatedTarget)) {
                this.setState({show_jack_out: false});
            }
        }
    }

    openImageViewer(index) {
        this.setState({
            show_image_viewer: true,
            first_image_index: index
        })
    }

    getDescriptionText() {
        let description = this.state.advert && this.state.advert.description ?
            this.state.advert.description : "";
        if (description.length > MAX_VISIBLE_DESCRIPTION_LENGTH_ON_MOBILE &&
            this.state.truncate_description) {
            return parse((description.substring(0, MAX_VISIBLE_DESCRIPTION_LENGTH_ON_MOBILE) + "...")
                .replaceAll("\n", "<br>"));
        }
        else {
            return parse(description.replaceAll("\n", "<br>"))
        }
    }

    handleResize() {
        if (window.innerWidth > 750) {
            this.setState({truncate_description: false});
        }
        if (window.innerWidth < 751) {
            this.setState({truncate_description: true});
        }
    }

}

Expose.propTypes = {
    userData: PropTypes.object,
    callLogin: PropTypes.func.isRequired,
    onUpdateUserData: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(Object.values(Mode)).isRequired,
    onSwitchMode: PropTypes.func.isRequired
}
Expose.defaultProps = {}
export default Expose;