import React from "react";
import {Modal} from "react-bootstrap";
import {
    BOOSTERS,
    buildTextLinkContainer, getCountLabel,
    getFormattedDate,
    getLanguageEntry,
    priceToString, SUPPORTED_PAYMENT_GATEWAYS
} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";
import FetchButton from "../buttons/FetchButton";
import {buyBooster} from "../../utils/RESTInterface";
import PropTypes from "prop-types";
import {BoosterType, PaymentTarget} from "../../utils/Types.ts";
import {uniqueId} from "lodash";
import PaymentHandler from "../input/PaymentHandler";

function BoosterContainer(props) {

    let advertBoosterName = props.type;
    if (advertBoosterName !== BoosterType.color_accent) {
        advertBoosterName += "_boosters";
    }
    let advertBooster = props.advert[advertBoosterName];
    let active = advertBooster && (props.type === BoosterType.color_accent || advertBooster[0].expiry_date > Date.now());
    let classAddition = props.type === BoosterType.basic_package ||
    (props.type === BoosterType.color_accent && active) ? " disabled" : (active ? " active" : "");
    let langData = getLanguageEntry("processes>booster_modal");
    let boosterLangData = getLanguageEntry("processes>booster_modal>booster_types>" + props.type);
    let booster = BOOSTERS.filter(b => b.type === props.type)[0];
    let costLabel = props.type === BoosterType.basic_package ? langData.free :
        (props.type === BoosterType.color_accent && props.advert.color_accent ?
            langData.purchased : langData.price_from + priceToString(booster.pricing[0].price));
    let status = props.type === BoosterType.basic_package || (props.type === BoosterType.color_accent && active) ?
        langData.booster_activated : (active ? langData.boosted : null);
    let statusDate;
    if (active && props.type !== BoosterType.basic_package && props.type !== BoosterType.color_accent) {
        statusDate = getFormattedDate(new Date(advertBooster[0].expiry_date), true, true,
                false, true, false, false, false);
    }


    return <div className="booster-container">
        <div className={"booster-tile" + classAddition} onClick={() => {props.onClick?.(props.type)}}>
            <div className="roomjack-headline">{boosterLangData.title}</div>
            <div className="booster-description">{boosterLangData.description}</div>
            <div className="booster-costs">{costLabel}</div>
        </div>
        {
            status &&
            <div className="description-container" style={{textAlign: "center", fontSize: "11px"}}>
                <div>{status}</div>
                {
                    statusDate && <div>{statusDate}</div>
                }
            </div>
        }
    </div>

}
BoosterContainer.propTypes = {
    type: PropTypes.oneOf(Object.keys(BoosterType)).isRequired,
    advert: PropTypes.any.isRequired,
    onClick: PropTypes.func
}

class BoosterModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            loading: false,
            advert: null,
            response: null,
            selected_booster: null,
            loading_text: null,
            days: null,
            checkout_session_type: null
        }
        this.payment_handler = React.createRef();
        this.form = React.createRef();
    }

    render() {
        return (<Modal className={"booster-modal landlord"} show={this.state.show} backdrop="static"
                       keyboard={false} onHide={() => { this.hide() }} dialogClassName="booster-modal-width" centered>
            <Modal.Header>
                <h4>{getLanguageEntry("processes>booster_modal>title")}</h4>
                <button onClick={() => { this.hide() }} disabled={this.state.loading}>
                    <FontAwesomeIcon icon={["fal", "close"]}/>
                </button>
            </Modal.Header>

            <Modal.Body style={{display: "grid", gap: "20px" }}>
                {
                    this.state.advert && !this.state.response && !this.state.selected_booster &&
                    <div className="desktop-submenu-grid">
                        {
                            Object.keys(BoosterType)
                                .map((type, _) => {
                                    let clickEvent = type !== BoosterType.basic_package &&
                                        (type !== BoosterType.color_accent || !this.state.advert.color_accent) ?
                                        (t) => {this.setState({selected_booster: t})} : null;
                                    return <BoosterContainer key={uniqueId(type)} type={type} advert={this.state.advert}
                                                             onClick={clickEvent} />
                                })
                        }
                    </div>
                }
                {
                    this.state.advert && !this.state.response && this.state.selected_booster &&
                    <div style={{display: "grid", gap: "25px"}}>
                        <div className="description-container">
                            {getLanguageEntry("processes>booster_modal>booster_types>" +
                                this.state.selected_booster + ">description" )}
                        </div>
                        <form ref={this.form} style={{display: "grid", gap: "25px"}} id="form1"
                              onSubmit={(e) => {this.startBuyBooster(e)}}>
                            {this.createPriceBox()}
                            <div className="form-group">
                                <input type="checkbox" id="booster-revocation" required={true} name={"revocation"} />
                                <label htmlFor="booster-revocation" >
                                    {
                                        buildTextLinkContainer("description-container",
                                            "processes>registration>revocation_policy_label",
                                            [{
                                                text_path: "processes>registration>revocation_policy",
                                                url: "/widerrufsbelehrung.html",
                                                rel: "noreferrer nofollow",
                                                target: "_blank"
                                            }])
                                    }
                                </label>
                            </div>
                        </form>
                        <div style={{border: "1px solid lightgray", borderRadius: "20px", padding: "15px"}}>
                            <div className="roomjack-container-header">
                                {getLanguageEntry("components>payment_handler>header")}
                            </div>
                            <PaymentHandler requestPMs={true} target={PaymentTarget.platform} ref={this.payment_handler}
                                            paymentGateways={SUPPORTED_PAYMENT_GATEWAYS}
                                            onPaymentConfirmed={(r) => {this.buyBooster(r)}}
                                            getCheckoutSuccessParams={() => "advert=" + this.state.advert.id}
                                            getCheckoutData={() => {return this.getBoosterCheckoutData()}}
                                            onPaymentGatewaySelected={(paymentMethod) => {this.setState({
                                                checkout_session_type: paymentMethod})}}
                                            onConfirmationStarted={() => {
                                                this.setState({
                                                    loading: true,
                                                    loading_text: "components>payment_handler>verifying_payment_method"
                                                })
                                            }} />
                        </div>
                        <div className="horizontal-form-group">
                            <button className="outline-button black" style={{marginRight: "auto"}}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                            selected_booster: null,
                                            checkout_session_type: null
                                        })}} disabled={this.state.loading}>
                                {getLanguageEntry("general>back")}
                            </button>
                            <FetchButton type="submit" loading={this.state.loading} className="outline-button accent"
                                         loadingText={this.state.loading_text} form="form1">
                                {getLanguageEntry("processes>booster_modal>buy_now")}
                            </FetchButton>
                        </div>
                    </div>
                }
                {
                    this.state.advert && this.state.response &&
                    <div className="process-modal-result-container">
                        <div className="process-result-image">
                            <img src={this.state.response.errorCode ?
                                "https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear.png" :
                                "https://roomjack.blob.core.windows.net/roomjack/content-images/High-Five.gif"}
                                 alt={"Booster purchase " + (this.state.response.errorCode ? "failure" : "success")} />
                        </div>
                        <div className="process-result-message-box">
                            <div className="header">
                                {getLanguageEntry("processes>booster_modal>header_" +
                                    (this.state.response.errorCode ? "failure" : "success"))}
                            </div>
                            {
                                this.state.response.errorCode &&
                                <div className="description-container">
                                    <span>{getLanguageEntry("general>please_contact_support")}</span>
                                    <button className="link-button" onClick={() => {this.props.onContactSupport()}}>
                                        {getLanguageEntry("general>support")}
                                    </button>.
                                </div>
                            }
                            {
                                !this.state.response.errorCode &&
                                <div className="description-container">
                                    {getLanguageEntry("processes>booster_modal>description_success")}
                                </div>
                            }
                        </div>
                    </div>
                }
            </Modal.Body>
        </Modal>)
    }

    show(advert, sessionSuccess=false) {
        this.setState({
            show: true,
            loading: false,
            advert: advert,
            response: sessionSuccess ? {} : null,
            selected_booster: null,
            loading_text: null,
            days: null,
            checkout_session_type: null
        })
    }

    hide() {
        this.setState({
            show: false
        }, () => {this.props.onHide?.()});
    }

    createPriceBox() {
        let booster = BOOSTERS.filter(b => b.type === this.state.selected_booster)[0];
        if (booster) {
            if (booster.pricing.length === 1) {
                return <div className="description-container">
                    {
                        parse(getLanguageEntry("processes>booster_modal>cost_label")
                            .replace("#", priceToString(booster.pricing[0].price)))
                    }
                </div>
            }
            else {
                return <div>
                    {
                        booster.pricing.map((p, i) => {
                            return <div className="form-group" key={uniqueId(booster.type + "-price_" + i)}>
                                <input type="radio" name="days" value={p.days} id={"price-" + p.days} required={true}
                                       defaultChecked={this.state.days === p.days || i === 0}
                                       onClick={() => {this.setState({days: p.days})}}
                                       disabled={this.state.checkout_session_type}/>
                                <label htmlFor={"price-" + p.days}>
                                    {
                                        parse(getLanguageEntry("processes>booster_modal>multi_cost_label")
                                            .replace("#", p.days).replace("€", priceToString(p.price)))
                                    }
                                </label>
                            </div>
                        })
                    }
                </div>
            }
        }
    }

    startBuyBooster(e) {
        e.preventDefault();
        let form = new FormData(this.form.current);
        this.setState({
            days: form.get("days") && form.get("days").length > 0 ? parseInt(form.get("days")) : null
        });
        if (this.payment_handler.current) {
            this.payment_handler.current.triggerSubmit(e);
        }
    }

    buyBooster(data) {
        let id ;
        if (data) {
            if (typeof data === "string" && data.startsWith("cs_")) {
                id = data;
            }
            else if (typeof data === "object" && data.setupIntent) {
                id = data.setupIntent.id;
            }
        }
        if (id) {
            let that = this;
            this.setState({loading_text: "processes>booster_modal>buying"});
            buyBooster(this.state.advert.id, this.state.selected_booster, this.state.days, id,
                (response) => {
                    that.setState({
                       loading: false,
                       loading_text: null,
                       response: response
                    });
                });
        }
        else {
            this.setState({
                loading: false,
                loading_text: null
            })
        }
    }

    getBoosterCheckoutData() {
        if (this.state.selected_booster) {
            let booster = BOOSTERS.filter(b => b.type === this.state.selected_booster)[0];
            if (!booster) {
                return null;
            }
            let checkoutData = {
                metadata: {
                    booster: this.state.advert.id,
                    type: this.state.selected_booster
                }
            };
            checkoutData.description = getLanguageEntry("processes>booster_modal>booster_types>" + this.state.selected_booster + ">title");
            if (this.state.selected_booster !== BoosterType.color_accent) {
                let form = new FormData(this.form.current);
                let days = parseInt(form.get("days"));
                if (isNaN(days) || booster.pricing.filter(p => p.days === days).length === 0) {
                    return null;
                }
                checkoutData.metadata.days = days;
                let pricingObject = booster.pricing.filter(p => p.days === days)[0];
                if (!pricingObject || !pricingObject.price) {
                    return null;
                }
                checkoutData.amount = pricingObject.price;
                checkoutData.description += " - " + getCountLabel(days, "day");
            }
            else {
                checkoutData.amount = booster.pricing[0].price;
            };
            return checkoutData;
        }
        return null;
    }

}
BoosterModal.propTypes = {
    onContactSupport: PropTypes.func.isRequired
}
export default BoosterModal;