import React from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import '../../css/components/image-slider.css'
import {getNextPage} from "../utils/Helper";

class ImageSlider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            index: this.props.initialIndex !== undefined ?
                Math.max(0, Math.min(this.props.images.length - 1, this.props.initialIndex)) : 0
        }
    }

    render() {
        let style = {
            backgroundImage: "url(" + this.getMainImage() + ")"
        }
        if (this.props.onViewImage) {
            style.cursor = 'pointer';
        }
        return (
            <div className="image-slider">
                {
                    this.props.images.length > 1 &&
                    <button onClick={(e) => { this.switch(e, -1) }}>
                        <FontAwesomeIcon icon={["fal", "chevron-left"]}/>
                    </button>
                }
                <div className="main-image" style={style}
                     onClick={(e) => {this.viewImage(e)}}></div>
                {
                    this.props.images.length > 1 &&
                    <button onClick={(e) => { this.switch(e, 1) }}>
                        <FontAwesomeIcon icon={["fal", "chevron-right"]}/>
                    </button>
                }
            </div>
        )
    }

    switch(e, direction) {
        e.stopPropagation();
        e.preventDefault();
        this.setState({index: getNextPage(this.state.index, direction, this.props.images.length)})
    }

    getMainImage() {
        return this.props.images[this.state.index];
    }

    viewImage(e) {
        if (this.props.onViewImage) {
            e.preventDefault();
            this.props.onViewImage(this.state.index, this.props.images);
        }
    }

}

ImageSlider.propTypes = {
    images: PropTypes.arrayOf(PropTypes.string),
    initialIndex: PropTypes.number,
    onViewImage: PropTypes.func
}
ImageSlider.defaultProps = {
    images: [ "https://roomjack.blob.core.windows.net/roomjack/content-images/placeholder-image.png" ],
    initialIndex: 0
}
export default ImageSlider;