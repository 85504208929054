import React from "react";
import PropTypes from "prop-types";
import {getDayFromBegin, getLanguageEntry, getTimeUnitInMS, PRODUCTION_START} from "../../../../utils/Helper";
import {Doughnut} from "react-chartjs-2";

class CancellationRate extends React.Component {

    render() {
        let chartData = this.determineChartData();
        return(
            <div id="cancellation-rate-overview" className="statistic-container">
                <div className="statistic-title">
                    {getLanguageEntry("jackboard>stats>submenus>cancellation_rate>title")}
                </div>
                <div className="content-container">
                    <div className="roomjack-container">
                        <Doughnut data={chartData.data} options={chartData.options}
                                  style={{width: "250px", height: "250px"}}
                                  width={250} height={250}/>
                        <div className="doughnut-center-label">
                            <div>{chartData.percent}%</div>
                            <div>{getLanguageEntry("jackboard>stats>submenus>cancellation_rate>cancelled")}</div>
                        </div>
                    </div>
                </div>
            </div>)
    }

    determineChartData() {
        let start = PRODUCTION_START.getTime();
        let end = getDayFromBegin().getTime();
        if (this.props.interval !== -1) {
            start = end - getTimeUnitInMS(this.props.interval);
        }
        let allBookings = this.props.bookings.filter(b =>
            this.props.adverts.filter(a => a.id === b.advert_id).length > 0 &&
            b.booking_ts >= start && b.booking_ts <= end);
        let cancelledBookings = allBookings.filter(b => b.cancelled);
        let percent = allBookings.length > 0 ? Math.round(cancelledBookings.length / allBookings.length * 100) : 0;
        let dataPoints = [ percent, 100 - percent ];
        let dataObject = {
            datasets: [{
                data: dataPoints,
                fill: true,
                backgroundColor: [
                    '#5C2727',
                    '#BBBBBB'
                ],
                hoverOffset: 0
            }]
        };
        let options = {
            responsive: false,
            maintainAspectRatio: true,
            tooltips: { enabled: false },
            hover: { mode: null },
            events: [],
            cutout: '85%',
            borderRadius: 1000,
            animation: {
                duration: 2000
            }
        }
        return { data: dataObject, options: options, percent: percent };
    }

}

CancellationRate.propTypes = {
    adverts: PropTypes.array,
    bookings: PropTypes.array,
    interval: PropTypes.number
}
export default CancellationRate;