import React from 'react';
import {Col, Form, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {recover, startRecovery, updateUser} from "../../utils/RESTInterface";
import md5 from "md5";
import {
    EMAIL,
    PASSWORD,
    getLanguageEntry,
    SUPPORT_MAIL_ADDRESS,
    isValidPassword
} from "../../utils/Helper";
import PropTypes from "prop-types";
import '../../../css/components/modals/recovery.css'
import SecretInputField from "../input/SecretInputField";
import FetchButton from "../buttons/FetchButton";

class Recovery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            recovery_step: 0,
            server_response: null,
            password_error_message: null,
            loading: false,
            loading_text: ""
        }
        this.recovery_mail_form = React.createRef();
        this.recovery_code_form = React.createRef();
        this.recovery_password_form = React.createRef();
    }

    render() {
        return(
            <Modal id={this.props.id} className={this.props.className} show={this.state.show} backdrop="static"
                   keyboard={false} onHide={() => { this.hide() }} dialogClassName="max-content" centered>
                <Modal.Header>
                    <h4>{getLanguageEntry("processes>recovery>title")}</h4>
                    <button onClick={() => { this.hide() }}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <div id="recovery-mail-info" className="description-container mb-2">
                            <p>{getLanguageEntry("processes>recovery>recovery_info")}</p>
                            <p>
                                <span>{getLanguageEntry("processes>recovery>forgot_email_info")} </span>
                                <a href={"mailto:" + SUPPORT_MAIL_ADDRESS}>{getLanguageEntry("general>contact_support")}</a>
                            </p>
                        </div>
                        {
                            this.state.recovery_step === 0 &&
                            <div>
                                <form ref={this.recovery_mail_form}
                                      onSubmit={(e) => { this.triggerMailCheck(e); }}>
                                    <Form.Group className="mb-3" as={Col} controlId={EMAIL}>
                                        <Form.Label className="description-container v-form-label" column>
                                            {getLanguageEntry("general>email")}
                                        </Form.Label>
                                        <Form.Control type={EMAIL} name={EMAIL} onChange={() => { this.resetRecoveryError() }}
                                                      placeholder={getLanguageEntry("general>email_placeholder")}
                                                      required />
                                    </Form.Group>
                                    <div className="description-container mb-3">
                                        {this.getRecoveryErrorMessage()}
                                    </div>
                                    <FetchButton type="submit" className="accent-icon-button mb-3"
                                                 loading={this.state.loading} loadingText={this.state.loading_text}>
                                        <FontAwesomeIcon icon={["fal", "envelope"]}/>
                                        <span>{getLanguageEntry("general>request_code")}</span>
                                    </FetchButton>
                                </form>
                            </div>
                        }
                        {
                            this.state.recovery_step === 1 &&
                            <div>
                                <form ref={this.recovery_code_form}
                                      onSubmit={(e) => {this.triggerCodeCheck(e); }}>
                                    <Form.Group className="mb-3" as={Col} controlId="recovery_code">
                                        <Form.Label className="description-container v-form-label" column>
                                            {getLanguageEntry("general>verification_code")}
                                        </Form.Label>
                                        <Form.Control type="text" name="recovery_code" maxLength={10}
                                                      onChange={() => { this.resetRecoveryError() }}
                                                      placeholder={getLanguageEntry("general>verification_code_placeholder")}
                                                      required />
                                    </Form.Group>
                                    <div className="description-container mb-3">
                                        {this.getRecoveryErrorMessage()}
                                    </div>
                                    <FetchButton type="submit" className="accent-icon-button mb-3"
                                                 loading={this.state.loading} loadingText={this.state.loading_text}>
                                        <FontAwesomeIcon icon={["fal", "undo"]}/>
                                        <span>{getLanguageEntry("processes>recovery>reset_password")}</span>
                                    </FetchButton>
                                </form>
                            </div>
                        }
                        {
                            this.state.recovery_step === 2 &&
                            <div>
                                <form ref={this.recovery_password_form}
                                        onSubmit={(e) => {this.triggerPasswordChange(e)}}>
                                    <Form.Group className="mb-3" as={Col} controlId={PASSWORD}>
                                        <Form.Label className="description-container v-form-label" column>
                                            {getLanguageEntry("general>new_password")}
                                        </Form.Label>
                                        <SecretInputField name={PASSWORD} isValid={isValidPassword}
                                                          onChange={() => { this.resetPasswordError() }}
                                                          tooltip={"general>password_info"} required />
                                    </Form.Group>
                                    <Form.Group className="mb-3" as={Col} controlId="confirm_password">
                                        <Form.Label className="description-container v-form-label" column>
                                            {getLanguageEntry("general>confirm_new_password")}
                                        </Form.Label>
                                        <SecretInputField name="confirm_password" onChange={() => { this.resetPasswordError() }}
                                                          isValid={(p) => { return this.isConfirmPasswortValid(p) }} required />
                                    </Form.Group>
                                    <div className="description-container mb-3">
                                        {this.getRecoveryErrorMessage()}
                                    </div>
                                    <FetchButton type="submit" className="accent-icon-button mb-3"
                                                 loading={this.state.loading} loadingText={this.state.loadingText}>
                                        <FontAwesomeIcon icon={["fal", "arrows-rotate"]}/>
                                        <span>{getLanguageEntry("general>change_password")}</span>
                                    </FetchButton>
                                </form>
                            </div>
                        }
                        <button id="to-login" className="outline-button accent" onClick={() => this.toLogin()}>
                            {getLanguageEntry("processes>recovery>back_to_login")}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    reset() {
        this.setState({
            show: false,
            recovery_step: 0,
            server_response: null,
            password_error_message: null,
            loading_text: ""
        });
    }

    show() {
        this.setState({ show: 1 });
    }

    hide() {
        this.setState({ show: 0 }, () => { this.reset() });
    }

    triggerMailCheck(e) {
        e.preventDefault();
        let emailData = new FormData(this.recovery_mail_form.current);
        let that = this;
        this.setState({
            loading: true,
            loading_text: "general>requesting_code"
        });
        startRecovery(emailData.get(EMAIL).toLowerCase(), function(response) {
            let mailSuccess = response.errorCode === undefined && response.success;
            that.setState({
                server_response: response,
                recovery_step: mailSuccess ? 1 : 0,
                loading: false,
                loading_text: ""
            });
        });
    }

    triggerCodeCheck(e) {
        e.preventDefault();
        let codeData = new FormData(this.recovery_code_form.current);
        let that = this;
        this.setState({
            loading: true,
            loading_text: "processes>recovery>proof_code"
        });
        recover(md5(codeData.get("recovery_code")), function(response) {
            let codeSuccess = response.errorCode === undefined && response.success;
            that.setState({
                server_response: response,
                recovery_step: codeSuccess ? 2 : 1,
                loading: false,
                loading_text: ""
            });
        });
    }

    triggerPasswordChange(e){
        e.preventDefault();
        let passwordData = new FormData(this.recovery_password_form.current);
        if (!isValidPassword(passwordData.get(PASSWORD))) {
            this.setState({ password_error_message: "general>password_error" });
        }
        else if (passwordData.get(PASSWORD) !== passwordData.get("confirm_password")) {
            this.setState({ password_error_message: "general>confirm_password_error" });
        }
        else {
            this.setState({
                password_error_message: null,
                loading: true,
                loading_text: "general>saving"
            });
            let that = this;
            let update = { password: md5(passwordData.get(PASSWORD)) };
            updateUser(this.state.server_response.userID, update, function(response) {
                that.setState({
                    server_response: response,
                    loading: false,
                    loading_text: "" }, () => {
                    that.handleRecoveryResult();
                })
            });
        }
    }

    isConfirmPasswortValid(password) {
        let passwordData = new FormData(this.recovery_password_form.current);
        return isValidPassword(password) && passwordData.get(PASSWORD) === password;
    }

    resetRecoveryError() {
        this.setState({ server_response: null });
    }

    resetPasswordError() {
        this.setState({ password_error_message: null });
    }

    getRecoveryErrorMessage() {
        if (this.state.recovery_step === 2 && this.state.password_error_message !== null) {
            return getLanguageEntry(this.state.password_error_message);
        }
        if (this.state.server_response === null) {
            return '';
        }
        if (this.state.server_response.errorCode !== undefined) {
            let errorCode = this.state.server_response.errorCode;
            return getLanguageEntry('error_codes>' + errorCode);
        }
        return '';
    }

    handleRecoveryResult() {
        if (this.state.server_response.errorCode === undefined &&
            this.state.server_response.user !== undefined) {
            this.props.onRecover(this.state.server_response);
            this.hide();
        }
    }

    toLogin() {
        this.hide();
        this.props.toLogin();
    }

}
Recovery.propTypes = {
    id: PropTypes.string.isRequired,
    toLogin: PropTypes.func.isRequired,
    onRecover: PropTypes.func.isRequired,
}
export default Recovery;