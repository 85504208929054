import React from "react";
import PropTypes from "prop-types";
import {fillInvoiceNumber, getFormattedDate, getLanguageEntry, priceToString} from "../../utils/Helper";
import parse from "html-react-parser";
import InvoiceLogo from "./InvoiceLogo";

const LANG_PATH = "components>invoicing>";

class RoomJackFeeInvoice extends React.Component {

    render() {
        let bookingCountry =
            this.props.booking.address !== undefined &&
            this.props.booking.address.country !== undefined ?
            this.props.booking.address.country.toLowerCase() : "at";
        let otherEUCountry = bookingCountry !== "at";
        let b2b_customer = this.props.userData.b2b !== undefined && this.props.userData.b2b;
        let amount = this.props.charge.application_fee;
        let valueAddedTaxPercent = 20;
        if (bookingCountry === "de") {
            valueAddedTaxPercent = b2b_customer ? 0 : 19;
        }
        let netAmount = amount / ((100 + valueAddedTaxPercent) / 100);
        let valueAddedTax = amount - netAmount;
        let applicationFeePercent = this.props.charge.application_fee_percent;
        let bookingDate = new Date(this.props.booking.booking_ts)
        let bookingDateString = getFormattedDate(bookingDate, false, false, false, true, false, false);
        let yy = ('' + bookingDate.getFullYear()).substring(2);
        let MM = (bookingDate.getMonth() + 1 > 9 ? '' : '0') + (bookingDate.getMonth() + 1);
        let dd = (bookingDate.getDate() > 9 ? '' : '0') + bookingDate.getDate();
        let invoiceNo = yy + MM + dd + '-1-' + fillInvoiceNumber(this.props.charge.invoice_no);
        return <div className="page roomjack-invoice">
            <InvoiceLogo />
            <div className="invoice-body">
                <div className="invoice-header">
                    <div className="invoice-header-left-col">
                        <div className="roomjack-address">
                            <p>RoomJack GmbH</p>
                            <p>Steinhüblstraße 1</p>
                            <p>4800 Attnang-Puchheim</p>
                            <p>UID: ATU775200005</p>
                        </div>
                        <div className="customer-data">
                            {
                                this.props.userData.b2b && this.props.userData.company &&
                                this.props.userData.company !== "-" &&
                                <p>{this.props.userData.company}</p>
                            }
                            {
                                (!this.props.userData.b2b || !this.props.userData.company ||
                                this.props.userData.company === "-") &&
                                <p>
                                    {this.props.userData.first_name + " " + this.props.userData.last_name}
                                </p>
                            }
                            {
                                this.props.userData.address && this.props.userData.address.line_1 &&
                                <p>
                                    {this.props.userData.address.line_1}
                                </p>
                            }
                            {
                                this.props.userData.address && this.props.userData.address.postal_code &&
                                this.props.userData.address.city && this.props.userData.address.country &&
                                <p>
                                    {
                                        this.props.userData.address.postal_code + " " +
                                        this.props.userData.address.city + ", " +
                                        this.props.userData.address.country
                                    }
                                </p>
                            }
                        </div>
                    </div>
                    <div className="invoice-header-right-col">
                        <div className="invoice-data">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            {getLanguageEntry(LANG_PATH + "invoice_number_short")}:
                                        </td>
                                        <td>
                                            {invoiceNo}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {getLanguageEntry(LANG_PATH + "invoice_date")}:
                                        </td>
                                        <td>
                                            {bookingDateString}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {getLanguageEntry(LANG_PATH + "customer_id")}:
                                        </td>
                                        <td>
                                            {this.props.userData.id}
                                        </td>
                                    </tr>
                                    {
                                        this.props.userData.vat_id &&
                                        <tr>
                                            <td>
                                                {getLanguageEntry(LANG_PATH + "customer_vat_id")}:
                                            </td>
                                            <td>
                                                {this.props.userData.vat_id}
                                            </td>
                                        </tr>
                                    }
                                    <tr>
                                        <td>
                                            {getLanguageEntry(LANG_PATH + "advert_id")}:
                                        </td>
                                        <td>
                                            {this.props.booking.advert_id}
                                        </td>
                                    </tr>
                                    {
                                        this.props.booking.reg_no &&
                                        <tr>
                                            <td>
                                                {getLanguageEntry(LANG_PATH + "registration_number_short")}:
                                            </td>
                                            <td>
                                                {this.props.booking.reg_no}
                                            </td>
                                        </tr>
                                    }
                                    <tr>
                                        <td>
                                            {getLanguageEntry("general>booking_code")}:
                                        </td>
                                        <td>
                                            {this.props.booking.confirmation_code}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {getLanguageEntry("general>booking_date")}:
                                        </td>
                                        <td>
                                            {bookingDateString}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {getLanguageEntry("general>booking_start")}:
                                        </td>
                                        <td>
                                            {getFormattedDate(new Date(this.props.booking.start), false, false, false, true, false, false)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {getLanguageEntry("general>booking_end")}:
                                        </td>
                                        <td>
                                            {getFormattedDate(new Date(this.props.booking.end), false, false, false, true, false, false)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="invoice-content">
                    <div className="invoice-title">
                        {getLanguageEntry(LANG_PATH + "invoice") + " " +
                            getLanguageEntry(LANG_PATH + "number_short") + ": " + invoiceNo}
                    </div>
                    <table className="invoice-detail-table">
                        <tbody>
                        <tr className="table-header bottom-padding">
                            <th>{getLanguageEntry(LANG_PATH + "position_short")}</th>
                            <th>{getLanguageEntry(LANG_PATH + "invoice_term")}</th>
                            <th>{getLanguageEntry(LANG_PATH + "service_date")}</th>
                            <th>{getLanguageEntry("general>booking_code")}</th>
                            <th>{getLanguageEntry(LANG_PATH + "share")}</th>
                            <th>{getLanguageEntry(LANG_PATH + "price_sum")}</th>
                        </tr>
                        <tr className="booster-data bottom-padding">
                            <td>1</td>
                            <td>{parse(getLanguageEntry(LANG_PATH + "roomjack_fee_invoice_term"))}</td>
                            <td>{bookingDateString}</td>
                            <td>{this.props.booking.confirmation_code}</td>
                            <td>{Math.round(applicationFeePercent)}%</td>
                            <td>{priceToString(netAmount)}</td>
                        </tr>
                        <tr className="table-sum bottom-padding">
                            <td></td>
                            <td>{getLanguageEntry(LANG_PATH + "sum_positions")}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{priceToString(netAmount)}</td>
                        </tr>
                        <tr className="value-added-tax bottom-padding">
                            <td></td>
                            <td>{getLanguageEntry(LANG_PATH + "plus_value_added_tax")
                                .replace("[taxPercent]", "" + valueAddedTaxPercent)}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{priceToString(valueAddedTax)}</td>
                        </tr>
                        <tr className="invoice-amount">
                            <td></td>
                            <td>{getLanguageEntry(LANG_PATH + "invoice_amount")}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{priceToString(amount)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="roomjack-fee-information">
                    <span>{parse(getLanguageEntry(LANG_PATH + "roomjack_fee_information"))}</span>
                    {
                        otherEUCountry && b2b_customer &&
                        <span> {getLanguageEntry(LANG_PATH + "reverse_charge_regulation")}</span>
                    }
                </div>
                <div className="invoice-greeting">
                    {parse(getLanguageEntry(LANG_PATH + "thanking"))}
                </div>
            </div>
            {/*<InvoiceFooter />*/}
        </div>;
    }

}
RoomJackFeeInvoice.propTypes = {
    booking: PropTypes.any.isRequired,
    charge: PropTypes.any.isRequired,
    userData: PropTypes.any.isRequired
}
export default RoomJackFeeInvoice;