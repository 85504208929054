import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import {formatDate, getLanguageEntry, centsToPriceWithComma} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import DragSortList from "../DragSortList";

const Columns = {
    "name": true,
    "code": true,
    "start": true,
    "end": true,
    "sum": true,
    "stripe_fee": true,
    "application_fee": true,
    "net": true,
    "currency": true,
    "payout_date": true,
};

function BookingCSVExporter({data, onClose, filename}) {
    const [columnSelection, setColumnSelection] = useState(Columns)
    const [columnOrder, setColumnOrder] = useState(Object.keys(Columns));
    const [download, setDownload] = useState({
        invoiceCount: 0,
        url: null
    });

    const createColumnItem = function(item) {
        return <div className="description-container">
            {getLanguageEntry(`components>csv_export>columns>${item}`)}
        </div>
    }

    const onChangeSelection = function(e) {
        const newSelection = {...columnSelection};
        newSelection[e.target.value] = e.target.checked;
        let newOrder;
        if (columnOrder.includes(e.target.value)) {
            newOrder = columnOrder.filter(i => i !== e.target.value);
        }
        else {
            newOrder = [...columnOrder, e.target.value];
        }
        setColumnSelection(newSelection);
        setColumnOrder(newOrder);
    }

    const createCSV = function () {
        if (columnOrder.length > 0) {
            let csvContent = `${columnOrder.map(n => getLanguageEntry(`components>csv_export>columns>${n}`)).join(";")}\n`;
            for (const charge of data) {
                const refundAmount = charge.refund_amount ?? 0;
                const amount = charge.amount - refundAmount;
                const stripeFee = charge.stripe_fee ?? 0;
                const appFee = charge.application_fee ?? 0;
                const net = charge.payout_amount ?? amount - stripeFee - appFee;
                const payoutDate = charge.payout_date ? formatDate(new Date(charge.payout_date)) : "-";
                columnOrder.forEach((c, i) => {
                    switch (c) {
                        case "name":
                            csvContent += `${charge.booking.booker.last_name.toUpperCase()}, ${charge.booking.booker.first_name}`;
                            break;
                        case "code":
                            csvContent += `${charge.booking.confirmation_code}`;
                            break;
                        case "start":
                            csvContent += `${formatDate(charge.booking.start)}`;
                            break;
                        case "end":
                            csvContent += `${formatDate(charge.booking.end)}`;
                            break;
                        case "sum":
                            csvContent += `${centsToPriceWithComma(amount)}`;
                            break;
                        case "stripe_fee":
                            csvContent += `${centsToPriceWithComma(stripeFee)}`;
                            break;
                        case "application_fee":
                            csvContent += `${centsToPriceWithComma(-appFee)}`;
                            break;
                        case "net":
                            csvContent += `${centsToPriceWithComma(net)}`;
                            break;
                        case "currency":
                            csvContent += `EUR`;
                            break;
                        case "payout_date":
                            csvContent += `${payoutDate}`;
                            break;
                    }
                    csvContent += i < columnOrder.length - 1 ? ";" : "\n";
                });
                const blob = new Blob([csvContent], {type: 'text/csv'});
                setDownload({
                    url: window.URL.createObjectURL(blob),
                    filename: `roomjack_einkommen_${filename}.csv`
                });
            }
        }
    }

    useEffect(function() {
        console.log("lkasjhjerflsjdhf")
        createCSV();
    }, [columnOrder]);

    useEffect(function () {
        createCSV();
    }, [])

    return <Modal className={"landlord"} show={true} size="xl" backdrop="static" keyboard={false}
                  onHide={onClose} dialogClassName="max-content" centered>
        <Modal.Header>
            <h4>{getLanguageEntry("components>csv_export>title")}</h4>
            <button onClick={onClose}>
                <FontAwesomeIcon icon={["fal", "close"]}/>
            </button>
        </Modal.Header>

        <Modal.Body style={{display: "grid", justifyItems: "center", gap: "10px"}}>
            <div className="description-container">
                {getLanguageEntry("components>csv_export>description")}
            </div>
            <div className="form-group v">
                {
                    Object.entries(columnSelection).map(([key, value], _) => {
                       return <div className="form-group" key={key}>
                           <input
                               id={key}
                               type="checkbox"
                               value={key}
                               checked={value}
                               onChange={onChangeSelection}/>
                           <label htmlFor={key} className="description-container">
                               {getLanguageEntry(`components>csv_export>columns>${key}`)}
                           </label>
                       </div>
                    })
                }
            </div>
            <DragSortList itemWrapper={createColumnItem} items={columnOrder} onOrderChange={setColumnOrder} />
        </Modal.Body>
        <Modal.Footer>
            {
                download.url &&
                <a href={download.url} download={download.filename} className="outline-button accent">
                    <FontAwesomeIcon icon={["fal", "file-download"]} />
                    <span>{getLanguageEntry("components>csv_export>download")}</span>
                </a>
            }
        </Modal.Footer>
    </Modal>
}
BookingCSVExporter.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
}
export default BookingCSVExporter;