import { Navigate, Outlet } from 'react-router';
import PropTypes from "prop-types";
import {useEffect, useState} from "react";

function ProtectedRoutes(props) {
    const [isAuth, setIsAuth] = useState();

    useEffect(() => {
        new Promise((resolve) => {
            (function waitForLoading() {
                if (!props.authObject.loading) {
                    return resolve();
                }
                else {
                    setTimeout(() => { waitForLoading() }, 30);
                }
            })();
        }).then(function(res) {
            setIsAuth(props.authObject.isAuthorized);
            return isAuth;
        })
    });

    if (isAuth === undefined) return null;
    return isAuth ? <Outlet /> : <Navigate to={props.redirection} />;
}

ProtectedRoutes.propTypes = {
    authObject: PropTypes.exact({
        loading: PropTypes.bool.isRequired,
        isAuthorized: PropTypes.bool.isRequired
    }).isRequired,
    redirection: PropTypes.string.isRequired
}
ProtectedRoutes.defaultProps = {
    redirection: "/"
}
export default ProtectedRoutes;