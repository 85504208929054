import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";

function NavigatorButton(props) {
    const navigate = useNavigate();
    const onNavigate = (e) => {
        props.onBeforeClick?.(e);
        if (props.to !== undefined && props.to.length > 0) {
            let target = props.getDynamicLink !== undefined ? props.getDynamicLink() : props.to;
            navigate(target);
        }
        props.onAfterClick?.(e);
    }

    return (
        <button className={props.className} id={props.id} onClick={onNavigate}>
            { props.children }
        </button>
    )

}

NavigatorButton.propTypes = {
    id: PropTypes.string,
    to: PropTypes.string.isRequired,
    className: PropTypes.string,
    onBeforeClick: PropTypes.func,
    onAfterClick: PropTypes.func,
    getDynamicLink: PropTypes.func
}

export default NavigatorButton;