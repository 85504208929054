import React from "react";
import {Modal} from "react-bootstrap";
import {getLanguageEntry, MAX_HOUSE_RULE_LENGTH} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {HouseRuleType} from "../../utils/Types.ts";
import PropTypes from "prop-types";
import _uniqueId from "lodash/uniqueId";

const LANG_PATH = "jackboard>adverts>advert_creator>house_rules>";

class HouseRuleEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            house_rules: [],
            show_night_rest: false,
            custom_rules: {}
        }
        this.form = React.createRef();
        this.custom_house_rule = React.createRef();
    }

    render() {
        let nightRestPeriod = this.state.house_rules.filter(r => r.type === HouseRuleType.night_rest_period)[0];
        return(
            <Modal className={"house-rule-editor landlord"} show={this.state.show}
                   onHide={() => { this.hide() }} dialogClassName="max-content" centered>
                <Modal.Header>
                    <h4>{getLanguageEntry(LANG_PATH + "edit_house_rules")}</h4>
                    <button onClick={() => { this.hide() }}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>
                <Modal.Body style={{display: "grid", gap: "10px"}}>
                    <div className="roomjack-headline">
                        {getLanguageEntry(LANG_PATH + "predefined_rules")}
                    </div>
                    <form ref={this.form} id="house-rule-form" style={{display: "grid", gap: "10px"}}
                          onSubmit={(e) => {this.saveHouseRules(e)}}>
                        {
                            Object.values(HouseRuleType).filter(r => r !== HouseRuleType.night_rest_period &&
                                r !== HouseRuleType.custom)
                                .map((rule, _) => {
                                    return <div className="form-group" key={rule}>
                                        <input type="checkbox" id={rule} style={{height: "32px", display: "flex"}}
                                               name={rule} defaultChecked={this.state.house_rules &&
                                            this.state.house_rules.filter(r => r.type === rule)[0]}/>
                                        <label htmlFor={rule}>
                                            {
                                                getLanguageEntry("advert_attributes>house_rules>list>" + rule)
                                            }
                                        </label>
                                    </div>
                                })
                        }
                        {
                            <div className="horizontal-form-group" style={{gap: "5px"}}>
                                <div className="form-group" key={HouseRuleType.night_rest_period}
                                     style={{gap: "5px"}}>
                                    <input type="checkbox" id={HouseRuleType.night_rest_period}
                                           name={HouseRuleType.night_rest_period}
                                           defaultChecked={!!nightRestPeriod}
                                           onChange={(e) =>
                                           {this.setState({show_night_rest: e.target.checked})}}/>
                                    <label htmlFor={HouseRuleType.night_rest_period}>
                                        {
                                            getLanguageEntry("advert_attributes>house_rules>list>night_rest_period")
                                        }
                                    </label>
                                </div>
                                {
                                    this.state.show_night_rest &&
                                    <div className="horizontal-form-group" style={{gap: "5px", width: "max-content"}}>
                                                        <span className="description-container">
                                                            {getLanguageEntry("general>from").toLowerCase()}
                                                        </span>
                                        <input type="time" name="from" required={true}
                                               defaultValue={nightRestPeriod && nightRestPeriod.data.start ?
                                                   nightRestPeriod.data.start : ""}/>
                                    </div>
                                }
                                {
                                    this.state.show_night_rest &&
                                    <div className="horizontal-form-group" style={{gap: "5px", width: "max-content"}}>
                                                        <span className="description-container">
                                                            {getLanguageEntry("general>to").toLowerCase()}
                                                        </span>
                                        <input type="time" name="to" required={true}
                                               defaultValue={nightRestPeriod && nightRestPeriod.data.end ?
                                                   nightRestPeriod.data.end : ""}/>
                                    </div>
                                }
                            </div>
                        }
                    </form>
                    <div className="roomjack-headline">
                        {getLanguageEntry(LANG_PATH + "custom_rules")}
                    </div>
                    <div style={{display: "grid", gap: "5px"}}>
                        {
                            Object.keys(this.state.custom_rules).map((r, i) => {
                                return <div className="horizontal-form-group" key={r.custom_name}>
                                    {
                                        !this.state.custom_rules[r].edit &&
                                        <div className="description-container">
                                            {this.state.custom_rules[r].rule.custom_name}
                                        </div>
                                    }
                                    {
                                        !this.state.custom_rules[r].edit &&
                                        <button className="simple-icon-button border" style={{marginLeft: "auto"}}
                                                onClick={() => {
                                                    let customRules = {...this.state.custom_rules};
                                                    customRules[r].edit = true;
                                                    this.setState({custom_rules: customRules});
                                                }}>
                                            <FontAwesomeIcon icon={["fal", "pencil"]}/>
                                        </button>
                                    }
                                    {
                                        this.state.custom_rules[r].edit &&
                                        <form className="horizontal-form-group" style={{flex: "1"}}
                                              onSubmit={(e) => {this.editCustomHouseRule(e, r)}}>
                                            <input type="text" maxLength={MAX_HOUSE_RULE_LENGTH} name="custom_name"
                                                   defaultValue={this.state.custom_rules[r].rule.custom_name}
                                                   required={true}/>
                                            <button className="simple-icon-button border" style={{marginLeft: "auto"}}
                                                    type="submit">
                                                <FontAwesomeIcon icon={["fal", "check"]}/>
                                            </button>
                                        </form>
                                    }
                                    {
                                        this.state.custom_rules[r].edit &&
                                        <button className="simple-icon-button border"
                                                onClick={() => {
                                                    let customRules = {...this.state.custom_rules};
                                                    delete customRules[r];
                                                    this.setState({custom_rules: customRules});
                                                }}>
                                            <FontAwesomeIcon icon={["fal", "trash"]}/>
                                        </button>
                                    }
                                </div>
                            })
                        }
                        <form ref={this.custom_house_rule} id="custom-house-rule" className="horizontal-form-group"
                              onSubmit={(e) => {this.addCustomHouseRule(e)}}>
                            <input type="text" maxLength={MAX_HOUSE_RULE_LENGTH} required={true}
                                   name="custom_name" style={{flex: "1"}}
                                   placeholder={getLanguageEntry(LANG_PATH + "custom_house_rule_placeholder")}/>
                            <button className="simple-icon-button border" type="submit" form="custom-house-rule"
                                    style={{marginLeft: "auto"}}>
                                <FontAwesomeIcon icon={["fal", "plus"]} />
                            </button>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="outline-button accent" type="submit" form="house-rule-form">
                        <FontAwesomeIcon icon={["fal", "check"]}/>
                        <span>{getLanguageEntry("general>done")}</span>
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    show(houseRules) {
        let customRules = {};
        if (houseRules) {
            let customRuleArray = houseRules.filter(r => r.type === HouseRuleType.custom);
            for (let i=0; i<customRuleArray.length; i++) {
                customRules[_uniqueId("custom-rule-" + i + "@" + Date.now())] =
                    { edit: false, rule: customRuleArray[i] };
            }
        }
        this.setState({
            show: true,
            house_rules: houseRules ?? [],
            show_night_rest: houseRules &&
                !!houseRules.filter(hr => hr.type === HouseRuleType.night_rest_period)[0],
            custom_rules: customRules
        });
    }

    hide() {
        this.setState({
            show: false,
            house_rules: [],
            show_night_rest: false,
            custom_rules: []
        })
    }

    addCustomHouseRule(e) {
        e.preventDefault();
        let formData = new FormData(this.custom_house_rule.current);
        let houseRules = {...this.state.custom_rules};
        let rule = { type: HouseRuleType.custom, custom_name: formData.get("custom_name") };
        houseRules[_uniqueId("custom-rule-" + Object.keys(houseRules).length + "@" + Date.now())] =
            {edit: false, rule: rule};
        this.setState({
            custom_rules: houseRules
        }, () => {this.custom_house_rule.current.reset()});
    }

    editCustomHouseRule(e, id) {
        e.preventDefault();
        let formData = new FormData(e.target);
        let houseRules = {...this.state.custom_rules};
        let rule = { type: HouseRuleType.custom, custom_name: formData.get("custom_name") };
        houseRules[id] = {edit: false, rule: rule};
        this.setState({
            custom_rules: houseRules
        });
    }

    saveHouseRules(e) {
        e.preventDefault();
        let data = new FormData(this.form.current);
        let houseRules = [];
        for (const key of data.keys()) {
            let rule = {};
            if (key === HouseRuleType.night_rest_period) {
                rule.type = key;
                rule.data = {
                    start: data.get("from"),
                    end: data.get("to")
                }
            }
            else if (key.startsWith("custom_rule")) {
                rule.type = HouseRuleType.custom;
                rule.custom_name = key;
            }
            else if (data.get(key) === "on") {
                rule.type = key;
            }
            if (rule.type) {
                houseRules.push(rule);
            }
        }
        houseRules = houseRules.concat(Object.values(this.state.custom_rules).map(r => r.rule));
        if (this.custom_house_rule.current) {
            let formData = new FormData(this.custom_house_rule.current);
            if (formData.get("custom_name") && formData.get("custom_name").length > 0) {
                houseRules.push({type: HouseRuleType.custom, custom_name: formData.get("custom_name")});
            }
        }
        this.props.onSave?.(houseRules.length > 0 ? houseRules : null);
        this.hide();
    }

}
HouseRuleEditor.propTypes = {
    onSave: PropTypes.func.isRequired
}
export default HouseRuleEditor;