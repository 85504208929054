import {Modal} from "react-bootstrap";
import {copyTextToClipboard, determineBookableHousings, findTargetHousing, getLanguageEntry} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import parse from "html-react-parser";
import FetchButton from "../buttons/FetchButton";
import {addCalendarSyncMapEntry, deleteCalendarSyncMapEntry} from "../../utils/RESTInterface";
import PropTypes from "prop-types";

class CalendarSyncModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            advert: null,
            room_id: null,
            housing: null,
            show_creator: false,
            saving: null,
            deleting: null
        }
    }

    render() {
        return (
            <Modal className={"calendar-sync-modal landlord"} show={this.state.show} size="lg" backdrop="static"
                   keyboard={false} onHide={() => { this.hide() }} dialogClassName="max-content" centered>
                <Modal.Header>
                    <h4>{getLanguageEntry("components>calendar_sync_modal>title")}</h4>
                    <button onClick={() => { this.hide() }} disabled={this.state.deleting !== null || this.state.saving !== null}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>

                <Modal.Body>
                    <div className="description-container">
                        {parse(getLanguageEntry("components>calendar_sync_modal>description"))}
                    </div>
                    <div style={{display: "grid", gap: "20px"}}>
                        <div className="horizontal-form-group">
                            <label htmlFor="housing-sync-link" className="description-container">
                                {getLanguageEntry("components>calendar_sync_modal>sync_link_label")}
                            </label>
                            <input id="housing-sync-link" readOnly value={this.state.housing_id} style={{flex: 1}}/>
                            <button className="simple-icon-button" onClick={() => { copyTextToClipboard(this.state.housing_id); }}>
                                <FontAwesomeIcon icon={["fal", "copy"]}/>
                            </button>
                        </div>
                        {
                            this.state.housing && this.state.housing.calendar_sync_map &&
                            <div id="calendar-sync-list">
                                {
                                    Object.values(this.state.housing.calendar_sync_map).map((se, i) => {
                                        return <div className="horizontal-form-group" key={se.id}>
                                            <form className="horizontal-form-group" style={{flex: 1}}
                                                  onSubmit={(e) => this.saveCalendarSyncEntry(e, se)}>
                                                <div className="horizontal-form-group">
                                                    <label htmlFor="new-entry-name" className="description-container">
                                                        {getLanguageEntry("general>name")}
                                                    </label>
                                                    <input className="entry-name-input" maxLength="20" name="name"
                                                           defaultValue={se.name} required={true}/>
                                                </div>
                                                <div className="horizontal-form-group" style={{flex: 1}}>
                                                    <label htmlFor="new-entry-url" className="description-container">URL</label>
                                                    <input type="url" id="new-entry-url" name="url" required={true}
                                                           style={{flex: 1}}  defaultValue={se.url}/>
                                                </div>
                                                <FetchButton className="simple-icon-button outline-button black"
                                                             disabled={this.state.deleting !== null || this.state.saving !== null}
                                                             type="submit" loading={this.state.saving === se.id}>
                                                    <FontAwesomeIcon icon={["fal", "save"]} />
                                                </FetchButton>
                                            </form>
                                            <FetchButton className="simple-icon-button outline-button black"
                                                         loading={this.state.deleting === se.id}
                                                         disabled={this.state.deleting !== null || this.state.saving !== null}
                                                    onClick={() => {this.deleteCalendarSyncEntry(se)}}>
                                                <FontAwesomeIcon icon={["fal", "trash"]} />
                                            </FetchButton>
                                        </div>
                                    })
                                }
                            </div>
                        }
                        {
                            this.state.show_creator &&
                            <div className="horizontal-form-group">
                                <form className="horizontal-form-group" style={{flex: 1}}
                                      onSubmit={(e) => {this.saveCalendarSyncEntry(e)}}>
                                    <div className="horizontal-form-group">
                                        <label htmlFor="new-entry-name" className="description-container">
                                            {getLanguageEntry("general>name")}
                                        </label>
                                        <input id="new-entry-name" maxLength="20" required={true} name="name"/>
                                    </div>
                                    <div className="horizontal-form-group" style={{flex: 1}}>
                                        <label htmlFor="new-entry-url" className="description-container">URL</label>
                                        <input type="url" id="new-entry-url" required={true} name="url"
                                               style={{flex: 1}}/>
                                    </div>
                                    <FetchButton loading={this.state.saving === "new"} type="submit"
                                                 disabled={this.state.deleting !== null || this.state.saving !== null}
                                                 className="simple-icon-button outline-button black">
                                        <FontAwesomeIcon icon={["fal", "save"]} />
                                    </FetchButton>
                                </form>
                                <button className="simple-icon-button outline-button black"
                                        disabled={this.state.deleting !== null || this.state.saving !== null}
                                        onClick={() => {this.setState({
                                            show_creator: false,
                                            error: null
                                        })}}>
                                    <FontAwesomeIcon icon={["fal", "times"]} />
                                </button>
                            </div>
                        }
                        {
                            this.state.error &&
                            <div className="description-container" style={{textAlign: "center"}}>
                                {this.state.error}
                            </div>
                        }
                        {
                            !this.state.show_creator &&
                            <button className="outline-button black" style={{width: "max-content", justifySelf: "center"}}
                                    onClick={() => {this.setState({
                                        show_creator: true,
                                        error: null
                                    })}}>
                                <FontAwesomeIcon icon={["fal", "plus"]} />
                                <span>
                                    {getLanguageEntry("components>calendar_sync_modal>add_button_text")}
                                </span>
                            </button>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    show(advert, roomID, housing) {
        let housingID = advert.id;
        if (roomID) {
            housingID += "SP" + roomID;
        }
        this.setState({
            show: advert && housing,
            advert: advert,
            room_id: roomID ?? null,
            housing: housing,
            housing_id: housingID,
            error: null,
            show_creator: false
        });
    }

    hide() {
        this.setState({
            show: false,
            error: null,
            show_creator: false
        });
    }

    saveCalendarSyncEntry(e, syncEntry) {
        e.preventDefault();
        let formData = new FormData(e.target);
        let name = formData.get("name");
        let url = formData.get("url");
        if (!url.startsWith("https://")) {
            this.setState({error: getLanguageEntry("components>calendar_sync_modal>errors>invalid_url")});
            return;
        }
        let bookableHousings = determineBookableHousings(this.state.advert, false);
        let calendarSyncMap = bookableHousings.map((h, _) => h.calendar_sync_map ? Object.values(h.calendar_sync_map) : []).flat();
        if (Object.values(calendarSyncMap).filter(se => (!syncEntry || se.id !== syncEntry.id) && se.url === url).length > 0) {
            this.setState({error: getLanguageEntry("components>calendar_sync_modal>errors>url_in_use")});
            return;
        }
        if (this.state.housing && this.state.housing.calendar_sync_map &&
            Object.values(this.state.housing.calendar_sync_map)
                .filter(se => (!syncEntry || se.id !== syncEntry.id) && se.name === name).length > 0) {
            this.setState({error: getLanguageEntry("components>calendar_sync_modal>errors>name_in_use")});
            return;
        }
        this.setState({
            saving: syncEntry ? syncEntry.id : "new",
            error: null
        });
        let that = this;
        addCalendarSyncMapEntry(this.state.advert.id, this.state.room_id, name, url,
            syncEntry ? syncEntry.id : null, (response) => {
                let state = {
                    saving: null,
                    show_creator: false
                }
                if (response.errorCode) {
                    state.error = getLanguageEntry("components>calendar_sync_modal>errors>" +
                    (response.errorCode === "invalid_calendar_feed" ? "invalid_feed" : "invalid_input"))
                }
                else if (response.data) {
                    state.advert = response.data;
                    state.error = null;
                    state.housing = findTargetHousing(response.data, this.state.housing.id);
                    that.props.onAdvertUpdate?.(response.data);
                }
                that.setState(state);
            });
    }

    deleteCalendarSyncEntry(syncEntry) {
        this.setState({
            deleting: syncEntry.id,
            error: null
        });
        let that = this;
        deleteCalendarSyncMapEntry(this.state.advert.id, this.state.room_id,
            syncEntry.id, (response) => {
                let state = {
                    deleting: null,
                    show_creator: false
                }
                if (response.data) {
                    state.advert = response.data;
                    state.housing = findTargetHousing(response.data, this.state.housing.id);
                    that.props.onAdvertUpdate?.(response.data);
                }
                that.setState(state);
            });
    }

}
CalendarSyncModal.propTypes = {
    onAdvertUpdate: PropTypes.func.isRequired
}
export default CalendarSyncModal;