import React from "react";
import PropTypes from "prop-types";
import Loading from "../../../components/Loading";
import {getLanguageEntry} from "../../../utils/Helper";
import GanttChart from "../../../components/gantt/GanttChart";
import AlertModal from "../../../components/modals/AlertModal";
import {ADVERT_CACHE} from "../../../utils/CacheHandler.ts";
import {getAdverts} from "../../../utils/RESTInterface";
import CurrentPriceModal from "../../../components/modals/CurrentPriceModal";

class CurrentPrice extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            adverts: []
        }
        this.alert_modal = React.createRef();
        this.current_price_modal = React.createRef();
    }

    componentDidMount() {
        this.updateAdverts();
    }

    render() {
        return (
            <div id="current-price-tool">
                {
                    this.state.loading &&
                    <Loading imageWidth="250px"/>
                }
                {
                    !this.state.loading &&
                    <h1 className="desktop-tool-title">
                        {getLanguageEntry("jackboard>current_price>title")}
                    </h1>
                }
                {
                    !this.state.loading &&
                    <div className="description-container">
                        {
                            getLanguageEntry("jackboard>current_price>description")
                        }
                    </div>
                }
                {
                    !this.state.loading &&
                    <GanttChart onAlert={(...args) => this.callAlertModal(...args)} adverts={this.state.adverts}
                                onCurrentPrice={(...args) => this.callCurrentPriceModal(...args)}
                                userData={this.props.userData} ganttType={"current-price"} />
                }
                <AlertModal ref={this.alert_modal} />
                <CurrentPriceModal ref={this.current_price_modal} onAdvertUpdate={() => {this.updateAdverts()}}/>
            </div>
        )
    }

    callAlertModal(...args) {
        if (this.alert_modal.current) {
            this.alert_modal.current.show(...args);
        }
    }

    callCurrentPriceModal(advert, roomID, currentPrice, date) {
        if (this.current_price_modal.current) {
            this.current_price_modal.current.show(advert, roomID, currentPrice, date);
        }
    }

    updateAdverts() {
        if (this.props.userData.adverts) {
            let cache = ADVERT_CACHE.getCacheObjects(this.props.userData.adverts);
            if (cache.length === this.props.userData.adverts) {
                this.setState(
                    {adverts: cache.filter(a => a.business_model !== "long_term")})
            }
            else {
                let that = this;
                this.setState({loading: true});
                getAdverts((response) => {
                    that.setState({
                        loading: false,
                        adverts: response.data.filter(a =>
                            a.business_model !== "long_term" &&
                            (!a.delete || !a.delete.active) &&
                            (!a.blocking || !a.blocking.active))
                    });
                })
            }
        }
    }

}

CurrentPrice.propTypes = {
    userData: PropTypes.any.isRequired
}
CurrentPrice.defaultProps = {}
export default CurrentPrice;