import React from 'react';
import {Modal} from "react-bootstrap";
import {
    getLanguageEntry, MAX_MESSAGE_LENGTH
} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TextArea from "../input/TextArea";
import "../../../css/components/modals/report-modal.css";
import PropTypes from "prop-types";
import {postReview} from "../../utils/RESTInterface";
import FetchButton from "../buttons/FetchButton";
import {RoomJackReviewCriteria, Mode} from "../../utils/Types.ts";
import _uniqueId from "lodash/uniqueId";
import RatingInput from "../input/RatingInput";

const LANG_PATH = "processes>reviews>";

class ReviewModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            loading: false,
            mode: null,
            process_result: null
        }
        this.review_form = React.createRef();
    }

    render() {
        if (!this.props.userData) {
            return <></>;
        }

        return(
            <Modal className={"review-modal user " + this.props.mode} show={this.state.show} size="lg" backdrop="static"
                   keyboard={false} onHide={() => { this.hide() }} dialogClassName="max-content" centered>
                <Modal.Header>
                    <h4>{getLanguageEntry('components>reviews>roomjack_review>header')}</h4>
                    <button onClick={() => { this.hide() }} disabled={this.state.loading}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>
                {
                    !this.state.process_result &&
                    <Modal.Body>
                        <div className="description-container">{getLanguageEntry('components>reviews>roomjack_review>description_1')}</div>
                        <form ref={this.review_form} id="review_form"
                              onSubmit={(e) => { this.sendReview(e) } }>
                            <div className="review-detail-input-container">
                                <table>
                                    <tbody>
                                    {
                                        Object.values(RoomJackReviewCriteria).map((r, _) => {
                                            return <tr key={_uniqueId("review-" + r)}>
                                                <td className="roomjack-headline">
                                                    {getLanguageEntry('components>reviews>roomjack_review>rating>' + r)}
                                                </td>
                                                <td>
                                                    <RatingInput name={r} />
                                                </td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <TextArea label={'components>reviews>roomjack_review>review_text_label'} rows={5} name="description" maxLength={MAX_MESSAGE_LENGTH} />
                        </form>
                    </Modal.Body>
                }
                {
                    this.state.process_result &&
                    <Modal.Body>
                        <div className="process-modal-result-container">
                            <div className="process-result-image">
                                <img src={this.state.process_result.errorCode ?
                                    "https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear.png" :
                                    "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/hardfacts/thumbs_up.jpg"}
                                     alt={"User review " + (this.state.process_result.errorCode ? "failure" : "success")}/>
                            </div>
                            <div className="process-result-message-box">
                                <div className="header">
                                    {getLanguageEntry(LANG_PATH + (this.state.process_result.errorCode ?
                                        "failure" : "roomjack_review>thanking"))}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                }
                {
                    !this.state.process_result &&
                    <Modal.Footer>
                        <FetchButton className="outline-button accent" loading={this.state.loading}
                                     loadingText={LANG_PATH + "buttons>transmit_review"}
                                     form="review_form" type="submit">
                            {getLanguageEntry(LANG_PATH + "buttons>send_review")}
                        </FetchButton>
                    </Modal.Footer>
                }
            </Modal>
        )
    }

    show() {
        this.setState({
                show: true,
                process_result: null
            }
        );
    }

    hide() {
        this.setState({
            show: false,
            mode: null,
            process_result: null
        })
    }

    sendReview(e) {
        e.preventDefault();
        if (this.review_form.current) {
            let formData = new FormData(this.review_form.current);
            let reviewData = {};
            if (formData.get("description") && formData.get("description").length > 0) {
                reviewData.description = formData.get("description").substring(0, MAX_MESSAGE_LENGTH);
            }
            for (const [k, v] of formData.entries()) {
                let value = parseInt("" + v);
                if (k !== "description" && isNaN(value)) {
                    return;
                }
                reviewData[k] = value;
            }
            this.setState({loading: true});
            let that = this;
            postReview(reviewData, (response) => {
                if (response.data) {
                    let user = JSON.parse(JSON.stringify(that.props.userData));
                    user.roomjack_review = response.data;
                    that.props.onUpdateUserData?.(user);
                }
                that.setState({
                    loading: false,
                    process_result: response
                });
            });
        }
    }
}
ReviewModal.propTypes = {
    userData: PropTypes.object,
    mode: PropTypes.oneOf(Object.values(Mode)).isRequired,
    onUpdateUserData: PropTypes.func.isRequired
}
ReviewModal.defaultProps = {

}
export default ReviewModal;

