import React from "react";
import PropTypes from "prop-types";
import {InputGroup} from "react-bootstrap";
import {getLanguageEntry, parsePriceInput, PRICE_LIMIT} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UnitType} from "../../utils/Types.ts";

class UnitInputField extends React.Component {

    constructor(props) {
        super(props);
        let initialType = props.type;
        if (props.initialValue && props.initialValue.unit) {
            initialType = props.initialValue.unit;
        }
        this.state = {
            type: initialType
        }
        this.field = React.createRef();
    }

    render() {
        return(
            <InputGroup className="unit-input-field">
                {
                    !this.props.controlled &&
                    <input type={this.state.type === UnitType.clock ? "time" : "number"} tabIndex={0}
                           min={this.getMin()} max={this.getMax()}
                           step={this.state.type === UnitType.clock ? this.props.step * 60000 : this.props.step}
                           placeholder={this.props.placeholder !== undefined ? getLanguageEntry(this.props.placeholder) : ""}
                           required={this.props.required} ref={this.field} name={this.props.name}
                           onChange={() => { this.props.onChange?.(this.value) }}
                           onBlur={(e) => { this.props.onBlur?.(e) }}
                           disabled={this.props.disabled}
                           defaultValue={this.getDefaultValue()} id={this.props.id}
                           style={this.props.style}/>
                }
                {
                    this.props.controlled &&
                    <input type={this.state.type === UnitType.clock ? "time" : "number"} tabIndex={0}
                           min={this.getMin()} max={this.getMax()}
                           step={this.state.type === UnitType.clock ? this.props.step * 60000 : this.props.step}
                           placeholder={this.props.placeholder !== undefined ? getLanguageEntry(this.props.placeholder) : ""}
                           required={this.props.required} ref={this.field} name={this.props.name}
                           onChange={(e) => { this.props.onChange(e) }}
                           onBlur={(e) => { this.props.onBlur?.(e) }}
                           disabled={this.props.disabled}
                           value={this.props.value} id={this.props.id}
                           style={this.props.style}/>
                }
                <InputGroup.Text>
                    {
                        this.props.types &&
                        <button onClick={(e) => {this.getNextType(e)}}>
                            <FontAwesomeIcon icon={["fal", this.getIcon()]} fixedWidth={true}/>
                        </button>
                    }
                    {
                        !this.props.types &&
                        <FontAwesomeIcon icon={["fal", this.getIcon()]} fixedWidth={true}/>
                    }
                </InputGroup.Text>
            </InputGroup>
        )
    }

    getIcon() {
        switch (this.state.type) {
            case UnitType.currency:
                return "euro-sign";
            case UnitType.percent:
                return "percent";
            case UnitType.people:
                return "users";
            case UnitType.nights:
                return "house-night";
            case UnitType.clock:
                return "clock"
            default:
                return "hashtag";
        }
    }

    getMin() {
        switch (this.state.type) {
            case UnitType.currency:
            case UnitType.count:
                return Math.max(0, this.props.min ?? 0);
            case UnitType.clock:
                return this.props.min ?? "00:00";
            default:
                return Math.max(1, this.props.min ?? 1);
        }
    }

    getMax() {
        switch (this.state.type) {
            case UnitType.currency:
                return Math.min(PRICE_LIMIT, this.props.max ?? PRICE_LIMIT);
            case UnitType.percent:
                return Math.min(100, this.props.max ?? 100);
            case UnitType.clock:
                return this.props.max ?? "23:59";
            default:
                return this.props.max;
        }
    }

    get value() {
        if (this.props.types) {
            if (this.state.type === UnitType.currency) {
                return {
                    unit: this.state.type,
                    value: parsePriceInput(this.field.current.value).costs_cent
                }
            }
            return {
                unit: this.state.type,
                value: parseInt(this.field.current.value)
            }
        }
        return this.state.type === UnitType.clock ? this.field.current.value :
            this.state.type === UnitType.currency ?
            parsePriceInput(this.field.current.value).costs_cent :
                parseInt(this.field.current.value);
    }

    set value(value) {
        if (this.props.types) {
            let unit = value.unit;
            if (value.value) {
                this.field.current.value = unit === UnitType.currency ? value.value / 100 : value.value;
            }
            if (unit) {
                this.setState({type: unit})
            }
        }
        else {
            this.field.current.value = value;
        }
    }

    getDefaultValue() {
        if (this.props.types && this.props.initialValue &&
            this.props.initialValue.value) {
            let value = this.props.initialValue.value;
            return value && this.props.initialValue.unit === UnitType.currency ? value / 100 : value;
        }
        return this.props.initialValue;
    }

    getNextType(e) {
        e.preventDefault();
        if (this.props.types) {
            let index = this.props.types.indexOf(this.state.type);
            if (index >= 0) {
                index = (index + 1) % this.props.types.length;
                this.setState({type: this.props.types[index]}, () => {this.props.onChange?.(this.value)})
            }
        }
    }

}

UnitInputField.propTypes = {
    type: PropTypes.oneOf(Object.values(UnitType)).isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string,
        PropTypes.exact({unit: PropTypes.oneOf(Object.values(UnitType)), value: PropTypes.number})]),
    disabled: PropTypes.bool,
    name: PropTypes.string,
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    step: PropTypes.number,
    id: PropTypes.string,
    style: PropTypes.any,
    types: PropTypes.array,
    controller: PropTypes.bool,
}
UnitInputField.defaultProps = {
    required: false,
    disabled: false,
    step: 1,
    controlled: false
}
export default UnitInputField;