import React from "react";
import {Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getLanguageEntry} from "../../utils/Helper";
import {AdvertType, RoomType} from "../../utils/Types.ts";
import Dropdown from "../input/Dropdown";

const LANG_PATH = "jackboard>adverts>advert_creator>spatial_properties>";

class SpatialModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            housing: null,
            advert_type: null,
            on_update_room: null
        }
        this.form = React.createRef();
    }

    render() {
        let title = getLanguageEntry(LANG_PATH + "title") + " - ";
        let addNameInput = false;
        let addMicroLocation = false;
        if (this.state.housing) {
            if (!this.state.housing.id || !this.state.housing.id.startsWith("room")) {
                title += getLanguageEntry("jackboard>adverts>advert_creator>main_advert");
                addMicroLocation = this.state.type === AdvertType.house;
                if (this.state.housing.id &&
                    !this.state.housing.id.startsWith("room") &&
                    this.state.advert_type === AdvertType.room) {
                    addNameInput = true;
                }
            }
            else {
                title += this.state.housing.name ??
                    getLanguageEntry("advert_attributes>room_types>" + (this.state.housing.type ?? RoomType.other))
                addNameInput = true;
            }
        }
        else {
            return <></>
        }
        return (
            <Modal className="spatial-modal landlord" show={this.state.show}
                   onHide={() => { this.hide() }} dialogClassName="max-content" centered>
                <Modal.Header>
                    <h4>{title}</h4>
                    <button onClick={() => { this.hide() }}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>

                <Modal.Body>
                    <form ref={this.form} id="spatial-data-form"
                          onSubmit={(e) => {this.onSave(e)}}>
                        <table className="advert-editor-modal-table">
                            <tbody>
                                {
                                    addNameInput &&
                                    <tr>
                                        <td>
                                            {getLanguageEntry(LANG_PATH + "room_name")}
                                            {this.state.housing.bookable &&
                                                <FontAwesomeIcon icon={["fal", "info-circle"]}
                                                                 title={getLanguageEntry(LANG_PATH + "unit_name_description")} />}
                                        </td>
                                        <td>
                                            <input type="text" defaultValue={this.state.housing.name} name="name"/>
                                        </td>
                                    </tr>
                                }
                                <tr>
                                    <td>
                                        {getLanguageEntry(LANG_PATH + "surface_size_label")}
                                    </td>
                                    <td>
                                        <input type="number" name="surface_size" defaultValue={this.state.housing.surface_size}
                                               min={.5} step={.5} onKeyDown={(e) => {
                                            if (e.code === "Space") {
                                                e.preventDefault();
                                            }
                                        }}/>
                                    </td>
                                </tr>
                                {

                                }
                            </tbody>
                        </table>
                        {
                            addMicroLocation &&
                            <div className="roomjack-headline">
                                {getLanguageEntry(LANG_PATH + "micro_location>title")}
                                <FontAwesomeIcon icon={["fal", "info-circle"]}
                                                 title={getLanguageEntry(LANG_PATH + "micro_location>infobox_description")} />
                            </div>
                        }
                        {
                            addMicroLocation &&
                            <table className="advert-editor-modal-table">
                                <tbody>
                                    <tr>
                                        <td>
                                            {getLanguageEntry(LANG_PATH + "micro_location>floor_number_label")}
                                        </td>
                                        <td>
                                            <input type="text" defaultValue={this.state.housing.floor_no} name="floor_no"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {getLanguageEntry(LANG_PATH + "micro_location>door_number_label")}
                                        </td>
                                        <td>
                                            <input type="text" defaultValue={this.state.housing.door_no} name="door_no"/>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        }
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button className="outline-button accent" type="submit" form="spatial-data-form">
                        {getLanguageEntry("general>save")}
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    show(housing, onUpdateRoom, advertType) {
        this.setState({
            show: true,
            housing: housing,
            advert_type: advertType,
            on_update_room: onUpdateRoom
        })
    }

    hide() {
        this.setState({
            show: false,
            housing: null,
            advert_type: null,
            on_update_room: null
        })
    }

    onSave(e) {
        e.preventDefault();
        if (this.form.current) {
            let formData = new FormData(this.form.current);
            let housing = this.state.housing;
            for (const key of formData.keys()) {
                if (!formData.get(key) || formData.get(key).length === 0) {
                    delete housing[key];
                }
                else {
                    let value = null;
                    switch (key) {
                        case "surface_size":
                            value = parseFloat(formData.get(key));
                            if (isNaN(value)) {
                                value = null;
                            }
                            break;
                        case "type":
                            if (Object.values(RoomType).includes(formData.get(key))) {
                                value = formData.get(key);
                            }
                            break;
                        case "bookable":
                            value = formData.get(key) === "true";
                            break;
                        default:
                            value = formData.get(key);
                            break;
                    }
                    if (value) {
                        housing[key] = value;
                    }
                }
            }
            this.state.on_update_room?.(housing);
            this.hide();
        }
    }

    createRoomTypeDropdown() {
        let items = [];
        for (const roomType of Object.values(RoomType)) {
            items.push({value: roomType, label: "advert_attributes>room_types>" + roomType})
        }
        return <Dropdown required={true} items={items} name="type" />;
    }

}
export default SpatialModal;