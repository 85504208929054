import React from "react";
import PropTypes from "prop-types";
import {
    BOOKING_BLOCK_COLORS,
    filterOverlappingPeriods, filterOverlappingPricePeriods,
    getDayFromBegin, getFormattedDate,
    getLanguageEntry,
    getNextDay, hexToRGBString, priceToString,
    truncateText
} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _uniqueId from "lodash/uniqueId";
import {Tooltip} from "@mui/material";
import parse from "html-react-parser";

class GanttHousingRow extends React.Component {

    constructor(props) {
        super(props);
        this.row_header = React.createRef();
        this.housing_id = this.props.advert.id;
        if (this.props.room) {
            this.housing_id += "+" + this.props.room.id;
        }
        this.override = this.isOverride();
    }

    render() {
        return (
            <tr className="table-data-row">
                <td className="border" ref={this.row_header}>
                    {
                        <div>
                    <span title={this.props.title.length > 20 ? this.props.title : null}>
                        {truncateText(this.props.title, 20)}
                    </span>
                            {
                                this.props.onCollapse &&
                                <button onClick={() => this.props.onCollapse()}>
                                    <FontAwesomeIcon icon={["fal", (this.props.collapsed ? "chevron-down" : "chevron-up")]}/>
                                </button>
                            }
                            {
                                this.props.rowType !== "group" && this.props.onSync && !this.override &&
                                <button onClick={() => {this.callCalendarSync()}}>
                                    <FontAwesomeIcon icon={["fal", "arrows-rotate"]}/>
                                </button>
                            }
                            {
                                !this.props.onCollapse && this.override &&
                                <div className="cm-info"
                                     title={getLanguageEntry("jackboard>bookings>controlled_by_third_party")}>
                                    <FontAwesomeIcon icon={["fad", "tower-control"]}/>
                                </div>
                            }
                        </div>
                    }
                </td>
                {this.createDataCells().map((c, _) => c)}
            </tr>
        )
    }

    createDataCells() {
        let cells = [];
        let day = new Date(this.props.actualMonth);
        let nextDay = getNextDay(day);
        let today = getDayFromBegin();
        let target = this.props.room ? this.props.room : this.props.advert;
        let inactive = !target.bookable;
        let allBookingBlocks = [];
        let allExternalBookings = [];
        if (target.bookable) {
            if (this.props.advert.booking_free_periods) {
                allBookingBlocks = allBookingBlocks.concat(Object.values(this.props.advert.booking_free_periods));
            }
            if (this.props.advert.external_booking_blocks) {
                allExternalBookings = allExternalBookings.concat(Object.values(this.props.advert.external_booking_blocks));
            }
            if (this.props.rowType === "advert") {
                if (this.props.advert.rooms) {
                    for (const room of this.props.advert.rooms) {
                        if (room.booking_free_periods) {
                            allBookingBlocks = allBookingBlocks.concat(Object.values(room.booking_free_periods));
                        }
                        if (room.external_booking_blocks) {
                            allExternalBookings = allExternalBookings.concat(Object.values(room.external_booking_blocks));
                        }
                    }
                }
            }
            else {
                if (target.booking_free_periods) {
                    allBookingBlocks = allBookingBlocks.concat(Object.values(target.booking_free_periods));
                }
                if (target.external_booking_blocks) {
                    allExternalBookings = allExternalBookings.concat(Object.values(target.external_booking_blocks));
                }
            }
        }

        const filterBookings = (bookings, dayDate, nextDayDate) => {
            return bookings.filter(b =>
                (b.start >= dayDate.getTime() && b.start < nextDayDate.getTime()) ||
                (b.end > dayDate.getTime() && b.end <= nextDayDate.getTime()) ||
                (b.start < dayDate.getTime() && b.end >= nextDayDate.getTime()));
        }

        for (let i=0; i<31; i++) {
            let cellKey = _uniqueId(target.id + "-day-" + day.getTime());
            let overlappingBookings = [];
            let otherBookings = []; let targetBookings = []; let currentPrice;
            let externalBookings = filterOverlappingPeriods(allExternalBookings, day.getTime());
            let bookingFreePeriods = filterOverlappingPeriods(allBookingBlocks, day.getTime());
            let classList = ["border"];
            if (this.override) {
                classList.push("override")
            }
            if (day.getTime() < today.getTime()) {
                classList.push("past");
            }
            if (getNextDay(day).getDate() === 1) {
                classList.push("last");
            }
            if (inactive) {
                classList.push("inactive");
            }
            if (this.props.ganttType === "booking") {
                if (!inactive && this.props.bookings) {
                    overlappingBookings = filterBookings(this.props.bookings, day, nextDay);
                }
                switch (this.props.rowType) {
                    // case room
                    // - reserved: if there exists a booking with its room id
                    // - blocked: if exists any booking not with the id of this room
                    case "room":
                        targetBookings = overlappingBookings.filter(b => b.room_id === target.id);
                        break;
                    // case main advert
                    // - reserved: if there exists a booking where only the advert id is set
                    // - inactive: already handled
                    // - blocked: if there exists a booking where room id is set
                    default:
                        otherBookings = overlappingBookings.filter(b => b.room_id);
                        targetBookings = overlappingBookings.filter(b => !b.room_id);
                        break;
                }
                if (targetBookings.length > 0) {
                    cells.push(this.createBookingCell(targetBookings, classList, day, nextDay, cellKey));
                }
                else if (otherBookings.length > 0) {
                    classList.push("blocked");
                    cells.push(this.createBookingCell(otherBookings, classList, day, nextDay, cellKey, true));
                }
                else if (externalBookings && externalBookings.length > 0) {
                    let targetIDs = target.external_booking_blocks ? target.external_booking_blocks.map(b => b.id) : [];
                    if (externalBookings.map(b => b.id).filter(id => targetIDs.includes(id)).length > 0) {
                        cells.push(this.createExternalBookingCell(externalBookings[0], classList, cellKey));
                    }
                    else {
                        classList.push("blocked");
                        cells.push(this.createExternalBookingCell(externalBookings[0], classList, cellKey, true));
                    }
                }
                else if (bookingFreePeriods && bookingFreePeriods.length > 0) {
                    let targetIDs = target.booking_free_periods ? Object.keys(target.booking_free_periods) : [];
                    if (bookingFreePeriods.map(b => b.id).filter(id => targetIDs.includes(id)).length > 0) {
                        cells.push(this.createBookingFreePeriodCell(bookingFreePeriods[0], classList, cellKey, false, day));
                    }
                    else {
                        classList.push("blocked");
                        cells.push(this.createBookingFreePeriodCell(bookingFreePeriods[0], classList, cellKey, true, day));
                    }
                }
                else {
                    classList.push("free");
                    let cellDate = new Date(day);
                    cells.push(<td className={classList.join(" ")} key={cellKey} onClick={!classList.includes("past") ? () => {
                        this.callBookingBlockModal(cellDate);
                    } : null}></td>)
                }
            }
            else {
                if (!inactive) {
                    if (target.pricing.current_price_periods) {
                        currentPrice = filterOverlappingPricePeriods(target.pricing.current_price_periods, day)[0];
                    }
                    if (externalBookings && externalBookings.length > 0) {
                        let targetIDs = target.external_booking_blocks ? target.external_booking_blocks.map(b => b.id) : [];
                        if (externalBookings.map(b => b.id).filter(id => targetIDs.includes(id)).length > 0) {
                            cells.push(this.createExternalBookingCell(externalBookings[0], classList, cellKey));
                        }
                        else {
                            classList.push("blocked");
                            cells.push(this.createExternalBookingCell(externalBookings[0], classList, cellKey, true));
                        }
                    }
                    else if (bookingFreePeriods && bookingFreePeriods.length > 0) {
                        let targetIDs = target.booking_free_periods ? Object.keys(target.booking_free_periods) : [];
                        if (bookingFreePeriods.map(b => b.id).filter(id => targetIDs.includes(id)).length > 0) {
                            cells.push(this.createBookingFreePeriodCell(bookingFreePeriods[0], classList, cellKey, false, day));
                        }
                        else {
                            classList.push("blocked");
                            cells.push(this.createBookingFreePeriodCell(bookingFreePeriods[0], classList, cellKey, true, day));
                        }
                    }
                    else if (currentPrice) {
                        classList.push("current-price");
                        cells.push(this.createCurrentPriceCell(currentPrice, currentPrice.price, classList, cellKey, day));
                    }
                    else {
                        classList.push("standard-price");
                        cells.push(this.createCurrentPriceCell(null, target.pricing.rent, classList, cellKey, day));
                    }
                }
                else {
                    cells.push(<td className={classList.join(" ")} key={cellKey}></td>)
                }
            }
            day = getNextDay(day);
            nextDay = getNextDay(nextDay);
            if (day.getMonth() !== this.props.actualMonth.getMonth()) {
                break;
            }
        }
        return cells;
    }

    createBookingCell(bookings, classList, day, nextDay, key, blocked=false) {
        classList.push("reserved");
        let divideCell = bookings.length > 1 ||
            (bookings[0].start >= day.getTime() && bookings[0].start < nextDay.getTime()) ||
            (bookings[0].end >= day.getTime() && bookings[0].end < nextDay.getTime());
        if (!divideCell) {
            if (blocked) {
                return <td className={classList.join(" ")} key={key}></td>;
            }
            return <Tooltip title={this.createPopoverTable(bookings[0])} arrow key={key}>
                <td className={classList.join(" ")}
                    onClick={() => {this.props.onBookingDetail(bookings[0])}}>
                    <FontAwesomeIcon icon={["fal", "check"]}/>
                </td>
            </Tooltip>;
        }
        else {
            let checkOutBooking = bookings.filter(b =>
                b.end >= day.getTime() && b.end < nextDay.getTime())[0];
            let checkInBooking = bookings.filter(b =>
                b.start >= day.getTime() && b.start < nextDay.getTime())[0];
            classList.push("divided");
            if (blocked) {
                return <td className={classList.join(" ")} key={key}>
                    <div className="check-in-out-container">
                        {
                            checkOutBooking &&
                            <div className="check-out"></div>
                        }
                        {
                            checkInBooking &&
                            <div className="check-in"></div>
                        }
                    </div>
                </td>;
            }
            return <td className={classList.join(" ")} key={key}>
                <div className="check-in-out-container">
                    {
                        checkOutBooking &&
                        <Tooltip title={this.createPopoverTable(checkOutBooking)} arrow>
                            <div className="check-out" onClick={() => {this.props.onBookingDetail(checkOutBooking)}}>
                                <FontAwesomeIcon icon={["fal", "house-person-leave"]}/>
                            </div>
                        </Tooltip>
                    }
                    {
                        checkInBooking &&
                        <Tooltip title={this.createPopoverTable(checkInBooking)} arrow>
                            <div className="check-in" onClick={() => {this.props.onBookingDetail(checkInBooking)}}>
                                <FontAwesomeIcon icon={["fal", "house-person-arrive"]}/>
                            </div>
                        </Tooltip>
                    }
                </div>
            </td>;
        }
    }

    createExternalBookingCell(externalBooking, classList, key, blocked) {
        classList.push('external-booking-block');
        if (blocked) {
            return <td className={classList.join(" ")} key={key}></td>
        }
        let tooltip = getLanguageEntry("jackboard>bookings>blocked_external");
        if (externalBooking.channel_manager) {
            tooltip += " " + externalBooking.channel_manager
        }
        else if (externalBooking.description) {
            if (externalBooking.description.startsWith("ical_")) {
                let housing = this.props.room ?? this.props.advert;
                let se;
                if (housing.calendar_sync_map && (se = housing.calendar_sync_map[externalBooking.description])) {
                    tooltip += " " + se.name;
                }
                else {
                    tooltip += " iCal";
                }
            }
            else {
                tooltip += " " + externalBooking.description
            }
        }
        else {
            tooltip += " " + getLanguageEntry("general>third_party_software");
        }
        return <td className={classList.join(" ")} title={tooltip} key={key}>
            <FontAwesomeIcon icon={["fal", "circle-minus"]}/>
        </td>
    }

    createBookingFreePeriodCell(bookingFreePeriod, classList, key, blocked, day) {
        classList.push('booking-block');
        let tooltip = getLanguageEntry("jackboard>bookings>blocked_by_user");
        let style;
        if (bookingFreePeriod.description !== undefined && bookingFreePeriod.description !== null) {
            let customBlock = undefined;
            if (this.props.userData.booking_block_markers) {
                customBlock = this.props.userData.booking_block_markers.filter(b => b.id === bookingFreePeriod.description)[0];
            }
            if (customBlock !== undefined) {
                tooltip = customBlock.description;
                let color = BOOKING_BLOCK_COLORS.filter(c => c.name === customBlock.color)[0].hex_color;
                style = { backgroundColor: hexToRGBString(color)};
            }
        }
        if (blocked || this.props.ganttType === "current-price") {
            return <td className={classList.join(" ")} key={key} style={style} title={tooltip}></td>
        }
        if (!classList.includes("past") && !this.override) {
            tooltip += " (" + getLanguageEntry("jackboard>bookings>edit_block_info") + ")";
        }
        let cellDate = new Date(day);
        return <td className={classList.join(" ")} style={style} onClick={() => {
            this.callBookingBlockModal(cellDate, bookingFreePeriod);
        }} title={tooltip} key={key} >
            <FontAwesomeIcon icon={["far", "ban"]}/>
        </td>;
    }

    createHotelRoomGroupBookingCell(bookings, externalBookings, bookingFreePeriods, classList, key, day) {
        let tooltip;
        let externalCount; let bookingFreeCount;
        if (this.override) {
            externalBookings.sort((a, b) => b.creation_ts - a.creation_ts);
            externalCount = externalBookings && externalBookings.length > 0 ?
                externalBookings[0].contingent - bookings.length : 0;
            bookingFreeCount = 0;
        }
        else {
            externalCount = Math.max(externalBookings.map((b, _) => b.contingent ?? 0));
            bookingFreeCount = Math.max(bookingFreePeriods.map((b, _) => b.contingent ?? 0));
        }
        if (bookings.length > 0 || externalBookings.length > 0 || bookingFreePeriods.length > 0) {
            tooltip = "";
            if (bookings.length > 0) {
                tooltip += getLanguageEntry("jackboard>bookings>bookings_at_roomjack") + ": " + bookings.length;
            }
            if (externalCount) {
                if (tooltip.length > 0) {
                    tooltip += "\n";
                }
                tooltip += getLanguageEntry("jackboard>bookings>external_bookings") + ": " + externalCount;
            }
            if (!this.override && bookingFreePeriods.length > 0) {
                if (tooltip.length > 0) {
                    tooltip += "\n";
                }
                tooltip += getLanguageEntry("jackboard>bookings>blocks_at_roomjack") + ": " + bookingFreePeriods.length
            }
        }
        if (!tooltip) {
            let cellDate = new Date(day);
            return <td key={key} className={classList.join(" ")} onClick={() => {
                this.callBookingBlockModal(cellDate);
            }}></td>
        }
        return <td className={classList.join(" ")} title={tooltip} key={key} >
            <div className="group-overview">
                {
                    bookings.length > 0 &&
                    <div className="reserved" onClick={() => {this.props.onBookingDetail?.(bookings)}}>
                        {bookings.length}
                    </div>
                }
                {
                    externalBookings.length > 0 &&
                    <div className="external-booking-block">{externalCount}</div>
                }
                {
                    bookingFreePeriods.length > 0 &&
                    <div className="booking-block">{bookingFreeCount}</div>
                }

            </div>
        </td>;
    }

    createPopoverTable(booking) {
        let start = getFormattedDate(new Date(booking.start), false, false, false, true, false, false,);
        let end = getFormattedDate(new Date(booking.end), false, false, false, true, false, false,);
        let bookingDate = getFormattedDate(new Date(booking.booking_ts), false, false, false, true, false, false,);
        return <table>
            <tbody>
                <tr>
                    <td>{getLanguageEntry("general>booking_start") + ': '}</td>
                    <td style={{textAlign: "right"}}>{start}</td>
                </tr>
                <tr>
                    <td>{getLanguageEntry("general>booking_end") + ': '}</td>
                    <td style={{textAlign: "right"}}>{end}</td>
                </tr>
                <tr>
                    <td>{getLanguageEntry("general>booking_date") + ': '}</td>
                    <td style={{textAlign: "right"}}>{bookingDate}</td>
                </tr>
                <tr>
                    <td>{getLanguageEntry("general>booking_code") + ': '}</td>
                    <td style={{textAlign: "right"}}>{booking.confirmation_code}</td>
                </tr>
                <tr>
                    <td>{getLanguageEntry("general>guest") + ': '}</td>
                    <td style={{textAlign: "right"}}>{booking.booker.first_name + ' ' + booking.booker.last_name}</td>
                </tr>
            </tbody>
        </table>;
    }

    createCurrentPriceCell(currentPrice, price, classList, key, day) {
        let onClick = classList.includes("past") ? null : () => {
            this.callCurrentPriceModal(day, currentPrice)
        }
        return <td className={classList.join(" ")} key={key} title={priceToString(price)} onClick={onClick}>
            {
                classList.includes("current-price") &&
                <strong>{priceToString(price, false, 0)}</strong>
            }
            {
                classList.includes("standard-price") &&
                priceToString(price, false, 0)
            }
        </td>;
    }

    callCalendarSync() {
        let housing = this.props.room ?? this.props.advert;
        let roomID = this.props.room ? this.props.room.id : null;
        this.props.onSync?.(this.props.advert, roomID, housing);
    }

    callBookingBlockModal(day, block) {
        if (this.override) {
            this.alertOverride();
            return;
        }
        let roomID = this.props.room ? this.props.room.id : null;
        this.props.onBlock?.(this.props.advert, roomID, block, day);
    }

    callCurrentPriceModal(day, currentPrice) {
        if (this.override) {
            this.alertOverride();
            return;
        }
        let roomID = this.props.room ? this.props.room.id : null;
        this.props.onCurrentPrice?.(this.props.advert, roomID, currentPrice, day);
    }

    isOverride() {
        return this.props.advert && this.props.advert.connected_channel_manager;
        /*if (this.props.userData.zimmersoftware && this.props.userData.zimmersoftware.accounts) {
            let account = this.props.userData.zimmersoftware.accounts
                .filter(a => a.maintained_data_id === this.props.advert.id)[0];
            if (account) {
                let housingID = this.getHousingID();
                if (account.wiring && Object.values(account.wiring).filter(id => id.startsWith(housingID))) {
                    return true;
                }
            }
        }
        return false;*/
    }

    alertOverride() {
        this.props.onAlert?.("general>attention",
            <div className="description-container">
                {parse(getLanguageEntry("jackboard>bookings>control_override"))}
            </div>, null, null, null, null, "OK");
    }

}

GanttHousingRow.propTypes = {
    // required fields
    userData: PropTypes.any.isRequired,
    advert: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    actualMonth: PropTypes.instanceOf(Date).isRequired,
    rowType: PropTypes.oneOf(["advert", "room", "group"]).isRequired,
    ganttType: PropTypes.oneOf(["booking", "current-price"]).isRequired,
    // optional fields
    room: PropTypes.any,
    collapsed: PropTypes.bool,
    bookings: PropTypes.array,
    // delegate methods
    onCollapse: PropTypes.func,
    onBookingDetail: PropTypes.func,
    onSync: PropTypes.func,
    onBlock: PropTypes.func,
    onCurrentPrice: PropTypes.func,
    onAlert: PropTypes.func
}
GanttHousingRow.defaultProps = {
    collapsed: true
}
export default GanttHousingRow;