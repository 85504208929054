// @ts-ignore
import CacheObject from "./CacheObject.ts";

class HousingCacheObject extends CacheObject {

    private readonly availability_check:number;

    constructor(data, availability_check=-1) {
        super(data);
        this.availability_check = availability_check;
    }

    public availabilityCheckNeeded(lifetime:number) {
        return this.availability_check < 0 ||
            this.availability_check + lifetime < Date.now();
    }

    public getData(): object {
        return super.getData();
    }

    public isValid(lifetime:number): boolean {
        return super.isValid(lifetime);
    }

}
export default HousingCacheObject;