import React from "react";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import {getLanguageEntry} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NavigatorButton from "../buttons/NavigatorButton";
import parse from "html-react-parser";
import {uniqueId} from "lodash";

class AdvertSavingProgressModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }

    render() {
        let uploading = !this.props.response;
        let image; let body;
        if (!uploading) {
            if (this.props.response) {
                if (this.props.response.toPublish) {
                    if (this.props.response.requirements ||
                        (this.props.response.verified !== undefined && !this.props.response.verified) ||
                        this.props.response.withdrawal_active || this.props.response.address ||
                        this.props.response.phone || this.props.response.vat_id ||
                        this.props.response.tax_country || this.props.response.company_reg_no ||
                        this.props.response.operating_countries || this.props.response.b2b ||
                        this.props.response.birthday || this.props.response.tax_id) {
                        image = "https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear.png";
                        body = this.getPublicationError();
                    }
                    else {
                        image = "https://roomjack.blob.core.windows.net/roomjack/content-images/High-Five.gif";
                        body = <div className="header">
                            {getLanguageEntry("jackboard>adverts>advert_creator>alerts>save_and_publish_successful")}
                        </div>
                    }
                }
                else {
                    image = "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/hardfacts/thumbs_up.jpg";
                    body =
                        <div className="header">
                            {parse(getLanguageEntry("jackboard>adverts>advert_creator>alerts>save_successful"))}
                        </div>;
                }
            }
        }
        return (<Modal className={"advert-publication-modal landlord"} show={this.props.show} size="lg" backdrop="static"
                       keyboard={false} onHide={() => { this.hide() }} dialogClassName="max-content" centered>
            <Modal.Header>
                <h4>{getLanguageEntry("jackboard>adverts>advert_creator>alerts>publish_title")}</h4>
                {
                    !uploading &&
                    <button onClick={() => { this.hide() }}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                }
            </Modal.Header>

            <Modal.Body style={{display: "grid", gap: "20px" }}>
                {
                    !uploading &&
                    <div className="process-modal-result-container">
                        <div className="process-result-image">
                            <img src={image} alt={"Advert publication result"} />
                        </div>
                        <div className="process-result-message-box">
                            {body}
                        </div>
                    </div>
                }
                {
                    uploading &&
                    <div className="process-modal-result-container">
                        <div className="process-result-image">
                            <FontAwesomeIcon icon={["fal", this.props.uploadProgress < 100 ? "cloud-arrow-up" : "save"]} beatFade={true}/>
                        </div>
                        <div className="process-result-message-box">
                            {
                                this.props.uploadProgress < 100 &&
                                <div className="upload-bar">
                                    <div className="upload-bar-progress" style={{width: this.props.uploadProgress + "%"}}></div>
                                    <div className="upload-bar-indicator">{this.props.uploadProgress}%</div>
                                </div>
                            }
                            <div className="description-container">
                                {
                                    this.props.uploadProgress < 100 ?
                                    getLanguageEntry("jackboard>adverts>advert_creator>alerts>upload_in_progress") :
                                        getLanguageEntry("jackboard>adverts>advert_creator>alerts>save_in_progress")
                                }
                            </div>
                        </div>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                {
                    this.props.response && this.getFooterButton()
                }
            </Modal.Footer>
        </Modal>)
    }

    /*show(result) {
        this.setState({
            show: true,
            result: result
        })
    }*/

    hide() {
        this.props.onCloseModal();
    }

    getFooterButton() {
        if (this.props.response.requirements) {
            return <button className="outline-button accent" onClick={() => {this.hide()}}>
                {getLanguageEntry("jackboard>adverts>advert_creator>buttons>fulfill_requirements")}
            </button>
        }
        else if (this.props.response.verified !== undefined && !this.props.response.verified) {
            return <NavigatorButton to="/profile/account" className="outline-button black">
                {getLanguageEntry("processes>advert_publication>verify_now")}
            </NavigatorButton>
        }
        else if (this.props.response.withdrawal_active) {
            return <NavigatorButton to="/profile/account" className="outline-button black">
                {getLanguageEntry("processes>advert_publication>to_my_account")}
            </NavigatorButton>
        }
        else if (this.props.response.address|| this.props.response.phone ||
            this.props.response.tax_id || this.props.response.vat_id ||
             this.props.response.tax_country || this.props.response.company_reg_no ||
            this.props.response.operating_countries || this.props.response.birthday) {
            return <NavigatorButton to="/profile/overview" className="outline-button black">
                {getLanguageEntry("processes>advert_publication>to_my_profile")}
            </NavigatorButton>
        }
        else if (this.props.response.toPublish) {
            return <NavigatorButton to={"/desktop/adverts/overview"} className="outline-button accent">
                {getLanguageEntry("jackboard>adverts>advert_creator>buttons>to_overview")}
            </NavigatorButton>
        }
    }

    getPublicationError() {
        let result = this.props.response;
        let verified = result.verified === undefined || result.verified;
        if (result.requirements) {
            return <div style={{textAlign: "justify"}}>
                <div className="description-container">
                    {getLanguageEntry("processes>advert_publication>publishing_failed_requirements")}
                </div>
                <div className="description-container">
                    <i>{getLanguageEntry("processes>advert_publication>publishing_failed_requirements_info")}</i>
                </div>
            </div>;
        }
        else if (!verified) {
            return <div className="description-container" style={{textAlign: "justify"}}>
                {getLanguageEntry("processes>advert_publication>publishing_failed_verification")}
            </div>;
        }
        else if (result.withdrawal_active) {
            return <div className="description-container" style={{textAlign: "justify"}}>
                {parse(getLanguageEntry("processes>advert_publication>publishing_failed_rp_acceptance"))}
            </div>;
        }
        else if (result.address|| result.phone || result.tax_id || result.vat_id ||
            result.tax_country || result.company_reg_no || result.operating_countries || result.birthday) {
            let items = []; let path = "jackboard>adverts>errors>missing_";
            if (result.address !== undefined) {
                items.push(<li key={uniqueId("missing_address")}>{getLanguageEntry(path + "address")}</li>);
            }
            if (result.phone !== undefined) {
                items.push(<li key={uniqueId("missing_phone")}>{getLanguageEntry(path + "phone")}</li>);
            }
            if (result.tax_id !== undefined) {
                items.push(<li key={uniqueId("missing_tax_id")}>{getLanguageEntry(path + "tax_id")}</li>);
            }
            if (result.vat_id !== undefined) {
                items.push(<li key={uniqueId("missing_vat_id")}>{getLanguageEntry(path + "vat_id")}</li>);
            }
            if (result.tax_country !== undefined) {
                items.push(<li key={uniqueId("missing_tax_country")}>{getLanguageEntry(path + "tax_country")}</li>);
            }
            if (result.company_reg_no !== undefined) {
                items.push(<li key={uniqueId("missing_c_reg_no")}>{getLanguageEntry(path + "company_reg_no")}</li>);
            }
            if (result.operating_countries !== undefined) {
                items.push(<li key={uniqueId("missing_op_country")}>{getLanguageEntry(path + "operating_countries")}</li>);
            }
            if (result.birthday !== undefined) {
                items.push(<li key={uniqueId("missing_birthday")}>{getLanguageEntry(path + "birthday")}</li>);
            }
            return <div className="description-container" style={{display: "grid", gap: "10px", textAlign: "justify"}}>
                {getLanguageEntry("processes>advert_publication>publishing_failed_missing_user_data_header")}
                <ul className="bulleted">
                    {items.map((item, _) => item)}
                </ul>
                {getLanguageEntry("processes>advert_publication>publishing_failed_missing_user_data_footer")}
            </div>;
        }
        return null;
    }

}

AdvertSavingProgressModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onContactSupport: PropTypes.func.isRequired,
    uploadProgress: PropTypes.oneOf(Array.from(Array(101)).map((_, i) => i)).isRequired,
    onCloseModal: PropTypes.func.isRequired
}
export default AdvertSavingProgressModal;