import React from "react";
import PropTypes from "prop-types";
import {
    calculateInvoiceFromObject,
    createCancellationConditionList,
    getFormattedDate,
    getLanguageEntry, getNightsBetweenDates
} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BookingDetailTable from "./BookingDetailTable";
import BookingInvoiceTable from "./BookingInvoiceTable";
import BookingTransactionDetails from "./BookingTransactionDetails";
import FetchButton from "../buttons/FetchButton";
import {downloadReceipt} from "../../utils/RESTInterface";
import _uniqueId from "lodash/uniqueId";
import {BOOKING_CACHE} from "../../utils/CacheHandler.ts";

class BookingOverviewContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            downloading_receipt: false,
            booking: props.booking
        }
        this.transaction_details = React.createRef();
        this.container = React.createRef();
    }

    componentDidMount() {
        if (this.props.open && this.container.current) {
            this.container.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }

    render() {
        let startDate = new Date(this.state.booking.start);
        let endDate = new Date(this.state.booking.end);
        let cancellationTSDate = null;
        if (this.state.booking.cancellation_ts !== undefined) {
            cancellationTSDate = new Date(this.state.booking.cancellation_ts);
        }
        let intervalString =
            getFormattedDate(startDate, true, false, false, true, false, false) +
            ' - ' +
            getFormattedDate(endDate, true, false, false, true, false, false);
        let now = Date.now();
        let active = now >= startDate.getTime() && now <= endDate.getTime() && cancellationTSDate === null;
        const canBeCancelled = !this.state.booking.cancelled && !this.state.booking.discount &&
            this.state.booking.booker === this.props.userData.id &&
            this.state.booking.start > Date.now();
        let addLongTermCancellationInfo = canBeCancelled &&
            getNightsBetweenDates(this.state.booking.end, this.state.booking.start) > 29
        let addCancellationList = canBeCancelled &&
            getNightsBetweenDates(this.state.booking.end, this.state.booking.start) < 30;
        let invoice = calculateInvoiceFromObject(this.state.booking);
        const title = this.state.booking.room_name ?
            `${this.state.booking.room_name}, ${this.state.booking.booker.last_name}` : this.state.booking.title;
        return(
            <div className={"booking-overview-container roomjack-container" + (this.state.open ? " open" : "")}
                 ref={this.container}>
                <div className="booking-span-header" onClick={() => { this.toggle() } }>
                    <div className={"interval description-container" + (active ? " active" : "")}>{intervalString}</div>
                    <div className="housing-title">{title}</div>
                    <FontAwesomeIcon icon={["fal", "chevron-right"]}/>
                </div>
                <div className="booking-container-body">
                    <BookingDetailTable booking={this.state.booking} userData={this.props.userData}
                                        onSendMessage={this.props.onSendMessage}/>
                    <div className="booking-table-header">
                        {getLanguageEntry("components>booking_detail_table>" +
                            (this.state.booking.invoice_data.pricing.long_term ? "rent_in_detail" : "booking_costs_in_detail"))}
                    </div>
                    <BookingInvoiceTable booking={this.state.booking}
                                         show_discount={this.props.userData.id === this.state.booking.booker}/>
                    {
                        this.state.booking.booker === this.props.userData.id &&
                        <div className="booking-table-header">
                            {getLanguageEntry("components>booking_detail_table>transaction_history")}
                        </div>
                    }
                    {
                        this.state.booking.booker === this.props.userData.id &&
                        <BookingTransactionDetails booking={this.state.booking} userData={this.props.userData}
                                                   onDetailsLoaded={() => {this.updateContainer()}}
                                                   ref={this.transaction_details}/>
                    }
                    {
                        addCancellationList &&
                        <div className="booking-table-header">
                            {getLanguageEntry("processes>booking>cancellation_condition_title")}
                        </div>
                    }
                    {
                        addCancellationList &&
                        <div>
                            {
                                createCancellationConditionList(
                                    this.state.booking.invoice_data.cancellation_conditions,
                                    invoice, this.state.booking.start)
                                    .map((c, _) => c)
                            }
                        </div>
                    }
                    {
                        addLongTermCancellationInfo &&
                        <div className="description-container">
                            {getLanguageEntry("processes>booking>cancellation_long_term")}
                        </div>
                    }
                    {
                        this.createControls()
                    }
                </div>
            </div>
        )
    }

    open() {
        this.setState({ open: true });
    }

    close() {
        this.setState({ open: false });
    }

    toggle() {
        if (this.state.open) {
            this.close();
        }
        else {
            this.open();
        }
    }

    createControls() {
        let controls = [];
        let booking = this.state.booking;
        if (booking.booker === this.props.userData.id) {
            if (booking.charge_data && booking.charge_data.charges) {
                // if the booking has at least one charge with a payment intent id,
                // create the download receipt button for the container
                if (this.transaction_details.current && booking.charge_data.charges
                    .filter(c => c.payment_intent_id !== undefined).length > 0) {
                    let getReceipt = () => {
                        let charge = this.transaction_details.current.getSelectedCharge();
                        if (charge.receipt_url) {
                            window.open(charge.receipt_url, "_blank", "noreferrer nofollow noopener");
                        }
                        else {
                            let that = this;
                            this.setState({downloading_receipt: true});
                            downloadReceipt(booking.id, charge, () => {
                                that.setState({downloading_receipt: false});
                                if (charge.receipt_url) {
                                    window.open(charge.receipt_url, "_blank", "noreferrer nofollow noopener");
                                }
                            });
                        }
                    };
                    controls.push(
                        <FetchButton className="accent-icon-button" loading={this.state.downloading_receipt}
                                     loadingText="general>loading" onClick={() => {getReceipt()}}
                                     key={_uniqueId(booking.id + "-receipt")}>
                            <FontAwesomeIcon icon={["fal", "receipt"]}/>
                            <span>{getLanguageEntry("general>receipt")}</span>
                        </FetchButton>
                    );
                }
                // if the booking has unpaid charges in the future
                // add a button for changing the payment method
                if (!booking.cancelled &&
                    booking.charge_data.charges.filter(c => c.payment_intent_id === undefined).length > 0) {
                    controls.push(
                        <button className="accent-icon-button" onClick={() => {this.props.onChangePaymentMethod()}}
                                key={_uniqueId(booking.id + "-change-pm")}>
                            <FontAwesomeIcon icon={["fal", "cog"]}/>
                            <span>{getLanguageEntry("jackboard>bookings>change_payment_method")}</span>
                        </button>
                    );
                }
            }
        }
        else {
            controls.push(
                <button className="accent-icon-button" key={_uniqueId(booking.id + "-detail")}
                        onClick={() => {this.props.onBookingDetail(booking, () => {this.updateContainer()})}}>
                    <FontAwesomeIcon icon={["fal", "magnifying-glass"]}/>
                    <span>{getLanguageEntry("jackboard>bookings>detail_view")}</span>
                </button>
            );
        }
        if (!booking.cancelled && !booking.discount && booking.booker === this.props.userData.id &&
            booking.start > Date.now() && getNightsBetweenDates(booking.end, booking.start)  < 30) {
            controls.push(
                <button className="accent-icon-button" onClick={() => {this.props.onCancelBooking()}}
                        key={_uniqueId(booking.id + "-cancel")}>
                    <FontAwesomeIcon icon={["fal", "times-octagon"]}/>
                    <span>{getLanguageEntry("processes>booking_cancellation>title")}</span>
                </button>
            )
        }
        return controls.length > 0 ?
            <div className="controls">
                {controls.map((c, _) => c)}
            </div> : null;
    }

    updateContainer() {
        let booking = BOOKING_CACHE.getCacheObject(this.state.booking.id);
        if (booking) {
            this.setState({booking: booking.getData()});
        }
    }

}

BookingOverviewContainer.propTypes = {
    booking: PropTypes.object.isRequired,
    userData: PropTypes.object.isRequired,
    onSendMessage: PropTypes.func.isRequired,
    onChangePaymentMethod: PropTypes.func.isRequired,
    onCancelBooking: PropTypes.func.isRequired,
    onBookingDetail: PropTypes.func,
    open: PropTypes.bool
}
BookingOverviewContainer.defaultProps = {
    open: false
}
export default BookingOverviewContainer;