import React from "react";
import {Modal} from "react-bootstrap";
import {AZURE_STORAGE_PATH, getLanguageEntry, megaByteToByte} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import FileUploader from "../input/FileUploader";

const LANG_PATH = "components>legal_doc_modal>";

class LegalDocumentModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            type: null,
            link: null,
            file: null,
            error_code: null
        }
        this.link_input = React.createRef();
        this.file_uploader = React.createRef();
    }

    render() {
        return <Modal className="legal-link-modal" show={this.state.show}
                      onHide={() => { this.hide() }} dialogClassName="max-content" centered>
            <Modal.Header>
                {
                    this.state.type &&
                    <h4>{getLanguageEntry(LANG_PATH + this.state.type + "_title")}</h4>
                }
                <button onClick={() => { this.hide() }}>
                    <FontAwesomeIcon icon={["fal", "close"]}/>
                </button>
            </Modal.Header>

            <Modal.Body style={{display: "grid", gap: "10px"}}>
                <div className="description-container" style={{textAlign: "justify"}}>
                    {getLanguageEntry(LANG_PATH + "legal_link_description")}
                </div>
                {
                    !this.state.file &&
                    <div className="horizontal-form-group">
                        <label htmlFor="legal-link" className="description-container">
                            {getLanguageEntry("components>legal_doc_modal>input_label")}
                        </label>
                        <input type="text" name="url" value={this.state.link ?? ""} style={{flex: "1"}}
                               ref={this.link_input}
                               placeholder={getLanguageEntry(LANG_PATH + this.state.type + "_placeholder")}
                               onChange={(e) => {
                                   e.preventDefault();
                                   this.setState({
                                       link: e.target.value.length === 0 ? null : e.target.value,
                                       error_code: null
                                   });
                               }}/>
                        {
                            this.state.link && this.state.link.length > 0 &&
                            <button className="simple-icon-button" onClick={() => {
                                this.setState({
                                    link: null,
                                    error_code: null
                                });
                            }}>
                                <FontAwesomeIcon icon={["fal", "times"]} />
                            </button>
                        }
                    </div>
                }
                {
                    !this.state.link && !this.state.file &&
                    <div className="description-container" style={{textAlign: "center"}}>
                        {getLanguageEntry("general>or")}...
                    </div>
                }
                {
                    !this.state.link &&
                    <FileUploader files={this.state.file ? [this.state.file] : []}
                                  maxFileSize={megaByteToByte(10)}
                                  dropFileCount={1} supportedFileTypes={["application/pdf"]} ref={this.file_uploader}
                                  fileSelectorHeader={LANG_PATH + "file_selector_header"}
                                  fileSelectorInfo={LANG_PATH + "file_selector_info"}
                                  onFileSelected={(fileList) => {
                                      if (fileList.length > 0) {
                                          this.setState({
                                              file: fileList[0],
                                              link: null
                                          });
                                      }
                                      else {
                                          this.setState({file: null});
                                      }
                                  }} />
                }
                {
                    this.state.error_code &&
                    <div className="description-container" style={{textAlign: "center", marginTop: "20px"}}>
                        {getLanguageEntry(this.state.error_code)}
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <button className="outline-button accent" onClick={() => {this.onSave()}}>
                    <FontAwesomeIcon icon={["fal", "save"]} />
                    <span>{getLanguageEntry("general>save")}</span>
                </button>
            </Modal.Footer>
        </Modal>
    }

    show(type, document) {
        this.setState({
            show: true,
            type: type,
            link: document && !document.startsWith(AZURE_STORAGE_PATH) && !document.startsWith("data:") ? document : null,
            file: document && (document.startsWith(AZURE_STORAGE_PATH) || document.startsWith("data:")) ? document : null,
            error_code: null
        });
    }

    hide() {
        this.setState({show: false});
    }

    onSave() {
        if (!this.state.file && this.state.link) {
            if (this.state.link.length > 0) {
                if (!this.state.link.startsWith("https://")) {
                    this.setState({error_code: LANG_PATH + "url_without_ssh"});
                }
                else {
                    this.props.onSave?.(this.state.type, this.state.link);
                    this.hide();
                }
                return;
            }
        }
        if (this.state.file) {
            this.props.onSave?.(this.state.type, this.state.file);
        }
        else if (!this.state.link || this.state.link.length === 0) {
            this.props.onSave?.(this.state.type, null);
        }
        this.hide();
    }

}
LegalDocumentModal.propTypes = {
    onSave: PropTypes.func.isRequired
}
export default LegalDocumentModal;