import React from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "../../../css/components/gantt/gantt-chart.css";
import _uniqueId from "lodash/uniqueId";
import {
    areDatesOnSameDay,
    getDayFromBegin,
    getLanguageEntry,
    getMonthBegin,
    getNextDay,
    isAdvertAvailable
} from "../../utils/Helper";
import GanttAdvertTable from "./GanttAdvertTable";
import NavigatorButton from "../buttons/NavigatorButton";

class GanttChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            actual_month: getMonthBegin(getDayFromBegin())
        };
    }

    componentDidMount() {
        this.updateMaps();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.adverts !== this.props.adverts || prevProps.bookings !== this.props.bookings) {
            this.updateMaps();
        }
    }

    render() {
        if (this.state.advert_map && this.props.adverts && this.props.adverts.length > 0 &&
            this.props.adverts.filter(a => a.published).length > 0) {
            return <div className="roomjack-container">
                <div className="gantt-container">
                    <table className="gantt-header">
                        <thead>
                        <tr>
                            <th>
                                <div>
                                    <button onClick={() => { this.showNextMonth(-1) }} className="back">
                                        <FontAwesomeIcon icon={["fal", "chevron-left"]}/>
                                    </button>
                                    <span>
                                        {getLanguageEntry("general>month_names_short>" +
                                                this.state.actual_month.getMonth()) +
                                            " " + this.state.actual_month.getFullYear()}
                                    </span>
                                    <button onClick={() => { this.showNextMonth(1) }} className="forward">
                                        <FontAwesomeIcon icon={["fal", "chevron-right"]}/>
                                    </button>
                                </div>
                            </th>
                            {
                                this.createHeaderCells().map((cell, i) => cell)
                            }
                        </tr>
                        </thead>
                    </table>
                    {
                        this.props.adverts.filter(a => this.state.advert_map[a.id]).map((a, _) => {
                            return <GanttAdvertTable advert={a} actualMonth={this.state.actual_month} key={_uniqueId(a.id)}
                                                     ganttType={this.props.ganttType} bookings={this.booking_map[a.id]}
                                                     rooms={this.state.advert_map[a.id].rooms}
                                                     roomGroups={this.state.advert_map[a.id].room_groups}
                                                     roomGroupStateMap={this.state.advert_map[a.id].room_group_state_map}
                                                     onSetRoomGroupState={(aID, gID, show) => {this.setRoomGroupState(aID, gID, show)}}
                                                     onBookingDetail={this.props.onBookingDetail}
                                                     onSync={this.props.onSync} onBlock={this.props.onBlock}
                                                     onCurrentPrice={this.props.onCurrentPrice}
                                                     onAlert={this.props.onAlert} userData={this.props.userData}/>
                        })
                    }
                </div>
            </div>
        }
        else if ((!this.props.adverts || this.props.adverts.length === 0 ||
            this.props.adverts.filter(a => a.published).length === 0)) {
            return <div className="roomjack-container no-data-container">
                <img src="https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear.png"
                     alt="No adverts" style={{objectFit: "contain"}}/>
                <div className="description-container">
                    {getLanguageEntry("jackboard>bookings>labels>no_adverts_label")}
                </div>
                <NavigatorButton to="/desktop/adverts" className="outline-button accent">
                    {getLanguageEntry("jackboard>bookings>labels>to_advert_creator")}
                </NavigatorButton>
            </div>
        }
    }

    setRoomGroupState(advertID, groupID, show) {
        let state = {...this.state};
        if (state.advert_map && state.advert_map[advertID] &&
            state.advert_map[advertID].room_group_state_map) {
            state.advert_map[advertID].room_group_state_map[groupID] = show;
            this.setState(state);
        }
    }

    createHeaderCells() {
        let cells = [];
        let day = new Date(this.state.actual_month);
        let today = getDayFromBegin();
        for (let i=0; i<31; i++) {
            let className = "";
            if (areDatesOnSameDay(day, today)) {
                className = "today";
            }
            else if (day.getTime() < today.getTime()) {
                className = "past";
            }
            cells.push(
                <th key={_uniqueId("day-" + i)} className={className}>
                    <div>
                        {day.getMonth() === this.state.actual_month.getMonth() ? day.getDate() : ''}
                    </div>
                    <div>
                        {day.getMonth() === this.state.actual_month.getMonth() ?
                            getLanguageEntry("general>day_names_short>" + day.getDay()) : ''}
                    </div>
                </th>
            )
            day = getNextDay(day);
        }
        return cells;
    }

    showNextMonth(dir) {
        let nextMonth = new Date(this.state.actual_month);
        nextMonth.setMonth(nextMonth.getMonth() + dir);
        this.setState({actual_month: nextMonth});
    }

    updateMaps() {
        let state = {};
        this.booking_map = {};
        if (this.props.bookings) {
            for (const booking of this.props.bookings) {
                if (booking.cancelled) {
                    continue;
                }
                if (!this.booking_map[booking.advert_id]) {
                    this.booking_map[booking.advert_id] = [];
                }
                this.booking_map[booking.advert_id].push(booking);
            }
        }
        if (this.props.adverts) {
            let advertMap = {};
            for (const advert of this.props.adverts.filter(a => isAdvertAvailable(a))) {
                let housingMap = {};
                let roomGroupStateMap = {};
                if (advert.rooms) {
                    housingMap.rooms = {};
                    let index = 0;
                    for (const room of advert.rooms) {
                        if (room.bookable) {
                            housingMap.rooms[room.id] = {
                                room: room,
                                index: index++,
                                title: room.name
                            }
                        }
                    }
                }
                housingMap.room_group_state_map = roomGroupStateMap;
                advertMap[advert.id] = housingMap;
            }
            state.advert_map = advertMap;
        }
        this.setState(state);
    }

}
GanttChart.propTypes = {
    // required fields
    adverts: PropTypes.array.isRequired,
    ganttType: PropTypes.oneOf(["booking", "current-price"]).isRequired,
    userData: PropTypes.any.isRequired,
    // optional fields
    bookings: PropTypes.array,
    // delegate methods
    onBookingDetail: PropTypes.func,
    onSync: PropTypes.func,
    onBlock: PropTypes.func,
    onCurrentPrice: PropTypes.func,
    onAlert: PropTypes.func
}
export default GanttChart;