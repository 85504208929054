import React from 'react';
import {Navigate, Routes} from "react-router";
import {Link, Route} from "react-router-dom";
import ProfileOverview from "./submenus/ProfileOverview";
import SubpageSideMenu from "../../components/SubpageSideMenu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getLanguageEntry} from "../../utils/Helper";
import "../../../css/pages/profile/profile-page.css";
import PropTypes from "prop-types";
import Notifications from "./submenus/Notifications";
import PasswordSettings from "./submenus/PasswordSettings";
import AccountSettings from "./submenus/AccountSettings";
import ThirdPartySoftware from "./submenus/ThirdPartySoftware";
import ProtectedRoutes from "../../utils/ProtectedRoutes";
import {Mode} from "../../utils/Types.ts";

class ProfilePage extends React.Component {

    constructor(props) {
        super(props);
        this.menu_items = [
            {
                mainPath: "/profile/overview",
                subPath: "/profile/overview",
                lang: "profile_menu>profile_overview>button",
                icon: ["fas", "user"]
            },
            {
                mainPath: "/profile/account",
                subPath: "/profile/account",
                lang: "profile_menu>account_settings>button",
                icon: ["fas", "user-gear"]
            },
            {
                mainPath: "/profile/change-password",
                subPath: "/profile/change-password",
                lang: "profile_menu>password_settings>button",
                icon: ["fas", "user-shield"]
            },
            /*{
                mainPath: "/profile/third-party-software",
                subPath: "/profile/third-party-software",
                lang: "profile_menu>third_party_software>button",
                icon: ["fas", "circle-nodes"],
                shouldRender: () => { return this.addThirdPartyMenu() }
            },*/
            {
                mainPath: "/profile/notifications",
                subPath: "/profile/notifications",
                lang: "profile_menu>notifications>button",
                icon: ["fas", "bell"]
            }
        ]
    }

    render() {
        let url = window.location.pathname;
        return(
            <div id="profile-page" className="subpage-container">
                <SubpageSideMenu items={this.menu_items} mode={this.props.mode} />
                <div id="profile-editor-surface">
                    <h1>{this.getTitle()}</h1>
                    <div id="profile-editor-tool-container">
                        <Routes>
                            <Route exact path={"/overview"} element={<ProfileOverview mode={this.props.mode}
                                                                                      onUpdateUserSession={this.props.onUpdateUserSession}
                                                                                      userData={this.props.userData} />}/>
                            <Route exact path={"/account"} element={<AccountSettings mode={this.props.mode}
                                                                                     onUpdateUserSession={this.props.onUpdateUserSession}
                                                                                     userData={this.props.userData}
                                                                                     onStatusChangeSuccess={this.props.onStatusChangeSuccess}/>}/>
                            <Route exact path={"/change-password"} element={<PasswordSettings mode={this.props.mode}
                                                                                              onUpdateUserSession={this.props.onUpdateUserSession}
                                                                                              userData={this.props.userData}/>}/>
                            {/*<Route element={
                                <ProtectedRoutes redirection="profile/overview"
                                    authObject={{loading: false, isAuthorized: this.props.mode === Mode.landlord}} />}>
                                <Route exact path={"/third-party-software/*"} element={<ThirdPartySoftware mode={this.props.mode}
                                                                                                           onUpdateUserSession={this.props.onUpdateUserSession}
                                                                                                           userData={this.props.userData}/>}/>
                            </Route>*/}
                            <Route exact path={"/notifications"} element={<Notifications mode={this.props.mode}
                                                                                         userData={this.props.userData}
                                                                                         onUpdateUserSession={this.props.onUpdateUserSession} />}/>
                            <Route exact path={"/*"} element={<Navigate to="/profile/overview" />}/>
                        </Routes>
                    </div>
                    {
                        url.includes(this.menu_items[0].subPath) &&
                        <div id="profile-editor-helper-buttons" className="desktop-submenu-grid">
                            <Link to="/desktop/dashboard">
                                <div className="desktop-submenu-tile" data-toggle="tooltip"
                                     data-html="false" data-placement="bottom"
                                     title={getLanguageEntry("profile_menu>to_jackboard_tooltip")}>
                                    <div className="tile-icon-container">
                                        <FontAwesomeIcon icon={["fal", "table-columns"]} />
                                    </div>
                                    <div className="tile-text-container">
                                        {getLanguageEntry("profile_menu>to_jackboard")}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    }
                </div>
            </div>
        )
    }

    addThirdPartyMenu() {
        return this.props.userData.adverts && this.props.mode === Mode.landlord;
    }

    getTitle() {
        switch (window.location.pathname.replace("/profile/", "")) {
            case "change-password":
                return getLanguageEntry("profile_menu>password_settings>button");
            case "account":
                return getLanguageEntry("profile_menu>account_settings>button");
            case "notifications":
                return getLanguageEntry("profile_menu>notifications>button");
            case "third-party-software":
                let url = window.location.pathname;
                if (!url.endsWith("/third-party-software")) {
                    return getLanguageEntry("profile_menu>third_party_software>supported_software>" +
                        url.split("third-party-software/")[1].replaceAll("/", "")
                        + ">title");
                }
                else {
                    return getLanguageEntry("profile_menu>third_party_software>third_party_software_header")
                }
            default:
                return getLanguageEntry("profile_menu>profile_overview>button");
        }
    }

}
ProfilePage.propTypes = {
    onStatusChangeSuccess: PropTypes.func.isRequired,
    onUpdateUserSession: PropTypes.func.isRequired,
    userData: PropTypes.object,
    mode: PropTypes.oneOf(Object.values(Mode)).isRequired
}
export default ProfilePage;