import React from "react";
import PropTypes from "prop-types";
import Avatar from "./Avatar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    getConversationPreviewText,
    getLanguageEntry,
    getTimestampLabel
} from "../utils/Helper";

const LANG_PATH = "jackboard>messages>"

class ConversationPreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_menu: false
        }
    }

    render() {
        let conversation = this.props.data;
        let user = conversation.user ?? null;
        let userStatus = this.getUserStatus();
        return(
            <div className={"conversation-preview roomjack-container" + (this.props.selected ? " selected" : "")}
                 onClick={() => {
                     if (!this.props.blocked) {
                         this.props.onSelectConversation(this.props.data)
                     }
                 }}
                 onMouseLeave={() => {this.showMenu(false)}}>
                {
                    this.props.loading &&
                    <div className="conversation-status loading">
                        <FontAwesomeIcon icon={["fad", "spinner-third"]} spin={true}/>
                    </div>
                }
                {
                    !this.props.loading && this.props.pinned &&
                    <button className="conversation-status interactive"
                            onClick={() => {this.toggleConversationPinStatus()}}>
                        <FontAwesomeIcon icon={["fas", "thumbtack"]}/>
                    </button>
                }
                <div className={"avatar" + (userStatus.length > 0 ? " " + userStatus : "")}>
                    <Avatar size={64} data={user ? user.avatar : null}/>
                    <div className="message-type-icon">
                        <FontAwesomeIcon icon={this.getConversationPreviewIcon()} />
                    </div>
                    {
                        userStatus.length > 0 &&
                        <FontAwesomeIcon icon={["fal", (userStatus === "deleted" ? "times-circle" :
                            (userStatus === "pause" ? "pause-circle" : "ban"))]}/>
                    }
                </div>
                <div className="conversation-info-container">
                    <div className="message-info-container">
                                                <span className="roomjack-headline">
                                                    {user.first_name + " " + user.last_name}
                                                </span>
                        <span className="description-container">
                            {getTimestampLabel(conversation.messages[0].timestamp)}
                        </span>
                    </div>
                    <div className="description-container">
                        {getConversationPreviewText(this.props.data.messages[0])}
                    </div>
                    {
                        !this.props.blocked &&
                        <div className="conversation-preview-control">
                            <button onClick={() => {this.showMenu(!this.state.show_menu)}}>
                                <FontAwesomeIcon icon={["fal", "bars"]}/>
                            </button>
                            {
                                this.state.show_menu &&
                                <div className="conversation-preview-menu">
                                    <button className="pin-control" onClick={() => {this.toggleConversationPinStatus()}}>
                                        <FontAwesomeIcon icon={["fal", "thumbtack"]} fixedWidth={true} />
                                        <span>
                                        {getLanguageEntry(LANG_PATH + "buttons>" + (this.props.pinned ? "unpin" : "pin") + "_conversation")}
                                    </span>
                                    </button>
                                    <button className="delete" onClick={() => {this.deleteConversation()}}>
                                        <FontAwesomeIcon icon={["fal", "trash"]} fixedWidth={true} />
                                        <span>
                                        {getLanguageEntry(LANG_PATH + "buttons>delete_conversation")}
                                    </span>
                                    </button>
                                    <button className="report" onClick={() => {this.onReportUser()}}>
                                        <FontAwesomeIcon icon={["fal", "exclamation-triangle"]} fixedWidth={true} />
                                        <span>
                                        {getLanguageEntry(LANG_PATH + "buttons>report_user")}
                                    </span>
                                    </button>
                                </div>
                            }
                        </div>
                    }
                </div>
                {
                    !!this.props.data.unread_msg_count &&
                    <div className="marker">
                        {this.props.data.unread_msg_count > 3 ? "3+" : this.props.data.unread_msg_count}
                    </div>
                }
            </div>
        )
    }

    getUserStatus() {
        let user = this.props.data.user ?? null;
        if (user) {
            if (user.delete && (user.delete === true || user.delete.active)) {
                return "deleted";
            }
            if (user.pause && (user.pause === true || user.pause.active)) {
                return "paused";
            }
            if (user.blocking && (user.blocking === true || user.blocking.active)) {
                return "blocked";
            }
        }
        return "";
    }

    getConversationPreviewIcon() {
        let message = this.props.data.messages[0];
        if (message) {
            let icon = ["fal", "comment-dots"];
            if (message.image !== undefined) {
                icon = ["fal", "image"];
            }
            else if (message.inquiry !== undefined) {
                icon = ["fal", "calendar-circle-user"];
            }
            else if (message.booking !== undefined) {
                icon = ["fal", "calendar-circle-plus"];
            }
            else if (message.invoice_data !== undefined) {
                icon = ["fal", "file-invoice"];
            }
            return icon;
        }
    }

    showMenu(show) {
        this.setState({show_menu: show})
    }
    
    toggleConversationPinStatus() {
        this.setState({
            show_menu: false
        });
        this.props.onPinConversation?.(this.props.data.id);
    }

    deleteConversation() {
        this.setState({
            show_menu: false
        });
        this.props.onDeleteConversation?.(this.props.data.id);
    }

    onReportUser() {
        this.setState({show_menu: false});
        this.props.onReport(this.props.data.user.id);
    }
    
}

ConversationPreview.propTypes = {
    data: PropTypes.any.isRequired,
    userData: PropTypes.any.isRequired,
    onReport: PropTypes.func.isRequired,
    onDeleteConversation: PropTypes.func.isRequired,
    onPinConversation: PropTypes.func.isRequired,
    onSelectConversation: PropTypes.func.isRequired,
    blocked: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    pinned: PropTypes.bool.isRequired,
    selected: PropTypes.bool
}
export default ConversationPreview;