import React from "react";
import PropTypes from "prop-types";
import {buildTextLinkButtonContainer, getLanguageEntry } from "../../../utils/Helper";
import {Link} from "react-router-dom";
import {Route, Routes} from "react-router";
import ZimmerSoftware from "./third_party_connectors/ZimmerSoftware";
import ReportModal from "../../../components/modals/ReportModal";
import {ReportReason} from "../../../utils/Types.ts";

class ThirdPartySoftware extends React.Component {

    constructor(props) {
        super(props);
        this.report_modal = React.createRef();
    }

    render() {
        let buttonArray = [{text_path: "general>here", onClick: () => { this.callReportModal() }}]
        return(
            <div id="third-party-software">
                <Routes>
                    <Route exact path="/" element={
                        <div className="roomjack-container">
                            <div className="roomjack-container-header">
                                {getLanguageEntry("profile_menu>third_party_software>channel_manager_title")}
                            </div>
                            {buildTextLinkButtonContainer("description-container",
                                "profile_menu>third_party_software>channel_manager_description", buttonArray)}
                            <div className="desktop-submenu-grid">
                                <Link to="/profile/third-party-software/zimmersoftware">
                                    <div id="zimmersoftware-button" className="desktop-submenu-tile">
                                        <div className="tile-icon-container">
                                            <div></div>
                                        </div>
                                        <div className="tile-text-container">ZimmerSoftware</div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    }></Route>
                    <Route exact path="/zimmersoftware" element={<ZimmerSoftware userData={this.props.userData}
                                                                                 onUpdateUserSession={this.props.onUpdateUserSession} />}></Route>
                </Routes>
                <ReportModal ref={this.report_modal} userData={this.props.userData}/>
            </div>
        )
    }

    callReportModal() {
        if (this.report_modal.current !== null) {
            this.report_modal.current.show(null, ReportReason.contact);
        }
    }

}

ThirdPartySoftware.propTypes = {
    onUpdateUserSession: PropTypes.func.isRequired,
    userData: PropTypes.object.isRequired,
    mode: PropTypes.string.isRequired
}
export default ThirdPartySoftware;