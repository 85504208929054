import React from "react";
import {
    AccessType,
    AdvertEditorTab,
    BookingType,
} from "../../../../../utils/Types.ts";
import {
    createDayDropdown, getFileNameFromBase64,
    getLanguageEntry,
    MAX_ACCESS_DESCRIPTION_LENGTH,
    MAX_MISC_ACCESS_TEXT_LENGTH
} from "../../../../../utils/Helper";
import CustomToggle from "../../../../../components/input/CustomToggle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TextArea from "../../../../../components/input/TextArea";
import PropTypes from "prop-types";
import AdvertEditorBlock from "../../../../../components/advert/AdvertEditorBlock";
import LegalDocumentModal from "../../../../../components/modals/LegalDocumentModal";
import Dropdown from "../../../../../components/input/Dropdown";

const LANG_PATH = "jackboard>adverts>advert_creator>";

const ClockTimes = [
    {value: "06:00", label: "06:00"}, {value: "06:30", label: "06:30"},
    {value: "07:00", label: "07:00"}, {value: "07:30", label: "07:30"},
    {value: "08:00", label: "08:00"}, {value: "08:30", label: "08:30"},
    {value: "09:00", label: "09:00"}, {value: "09:30", label: "09:30"},
    {value: "10:00", label: "10:00"}, {value: "10:30", label: "10:30"},
    {value: "11:00", label: "11:00"}, {value: "11:30", label: "11:30"},
    {value: "12:00", label: "12:00"}, {value: "12:30", label: "12:30"},
    {value: "13:00", label: "13:00"}, {value: "13:30", label: "13:30"},
    {value: "14:00", label: "14:00"}, {value: "14:30", label: "14:30"},
    {value: "15:00", label: "15:00"}, {value: "15:30", label: "15:30"},
    {value: "16:00", label: "16:00"}, {value: "16:30", label: "16:30"},
    {value: "17:00", label: "17:00"}, {value: "17:30", label: "17:30"},
    {value: "18:00", label: "18:00"}
]

class BookingSettingsTab extends React.Component {

    constructor(props) {
        super(props);
        let miscAccess = props.advert.access && props.advert.access.filter(a => a.type === AccessType.miscellaneous)[0];
        let keyCodeAccess = props.advert.access && props.advert.access.filter(a => a.type === AccessType.key_storage)[0];
        this.state = {
            key_storage: keyCodeAccess,
            key_code: keyCodeAccess ? keyCodeAccess.code : "",
            misc: miscAccess,
            misc_desc: miscAccess ? miscAccess.code : "",
            revocation: props.advert.revocation ?? "",
            terms_of_service: props.advert.terms_of_service ?? "",
            privacy_policy: props.advert.privacy_policy ?? ""
        }
        this.booking_settings_form = React.createRef();
        this.misc_advert_info = React.createRef();
        this.terms_of_service = React.createRef();
        this.revocation = React.createRef();
        this.privacy_policy = React.createRef();
        this.access = React.createRef();
        this.legal_doc_modal = React.createRef();
    }

    extractData() {
        let settingsFormData = new FormData(this.booking_settings_form.current);
        let miscData = new FormData(this.misc_advert_info.current);
        let accessFormData = new FormData(this.access.current);
        let checkIn = settingsFormData.get("check_in_time").length > 0 ? settingsFormData.get("check_in_time") : undefined;
        let checkOut = settingsFormData.get("check_out_time").length > 0 ? settingsFormData.get("check_out_time") : undefined;
        let access = [];
        for (const accessType of Object.values(AccessType)) {
            if (accessFormData.get(accessType) === "on") {
                let accessData = {type: accessType};
                if (accessType === AccessType.key_storage &&
                    accessFormData.get("key_storage_code").length > 0) {
                    accessData.code = accessFormData.get("key_storage_code");
                }
                if (accessType === AccessType.miscellaneous &&
                    accessFormData.get("misc_access").length > 0) {
                    accessData.code = accessFormData.get("misc_access");
                }
                access.push(accessData);
            }
        }
        return {
            booking_type: settingsFormData.get("booking_type") ?? BookingType.direct,
            booking_offset: settingsFormData.get("booking_offset").length > 0 ?
                parseInt(settingsFormData.get("booking_offset")) : undefined,
            check_in_time: checkIn,
            check_out_time: checkOut,
            reg_no: miscData.get("reg_no").length > 0 ? miscData.get("reg_no") : undefined,
            land_reg_no: miscData.get("land_reg_no").length > 0 ? miscData.get("land_reg_no") : undefined,
            access_description: accessFormData.get("access_description").length > 0 ? accessFormData.get("access_description") : undefined,
            access: access.length > 0 ? access : undefined,
            wifi_ssid: accessFormData.get("wifi_ssid").length > 0 ? accessFormData.get("wifi_ssid") : undefined,
            wifi_code: accessFormData.get("wifi_code").length > 0 ? accessFormData.get("wifi_code") : undefined,
            terms_of_service: this.state.terms_of_service && this.state.terms_of_service.length > 0 ?
                this.state.terms_of_service : undefined,
            revocation: this.state.revocation && this.state.revocation.length > 0 ?
                this.state.revocation : undefined,
            privacy_policy: this.state.privacy_policy && this.state.privacy_policy.length > 0 ?
                this.state.privacy_policy : undefined,

        };
    }

    render() {
        return (
            <div className={"editor-tab" + (this.props.disabled ? " disabled" : "")}>
                <AdvertEditorBlock title={LANG_PATH + "booking_settings>title"}
                                   info={LANG_PATH + "booking_settings>infobox_description"}
                                   invalid={this.props.requirements.booking_offset}>
                    <form ref={this.booking_settings_form}>
                        <table className="advert-editor-table">
                            <tbody>
                            <tr>
                                <td>
                                    <label className="description-container" htmlFor="booking_type">
                                        {getLanguageEntry(LANG_PATH + "booking_settings>booking_type_label")}
                                    </label>
                                </td>
                                <td>
                                    <CustomToggle id="booking_type" name="booking_type"
                                                  defaultValue={this.props.advert.booking_type ?? BookingType.direct}
                                                  leftValue={BookingType.inquiry} rightValue={BookingType.direct} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label className="description-container" htmlFor="booking_offset">
                                        {getLanguageEntry(LANG_PATH + "booking_settings>booking_offset_label")}
                                    </label>
                                </td>
                                <td>
                                    {createDayDropdown(Array.from(Array(30)).map((_, d) => d+1),
                                        "booking_offset", null, () => {this.actualizeRequirements()},
                                        this.props.advert.booking_offset, undefined, false,
                                        this.props.requirements.booking_offset)}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label className="description-container" htmlFor="check_in_time">
                                        {getLanguageEntry(LANG_PATH + "booking_settings>jack_in_time_label")}
                                    </label>
                                </td>
                                <td>
                                    <Dropdown
                                        name="check_in_time"
                                        initialValue={this.props.advert.check_in_time}
                                        items={ClockTimes}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label className="description-container" htmlFor="check_out_time">
                                        {getLanguageEntry(LANG_PATH + "booking_settings>jack_out_time_label")}
                                    </label>
                                </td>
                                <td>
                                    <Dropdown
                                        name="check_out_time"
                                        initialValue={this.props.advert.check_out_time}
                                        items={ClockTimes}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </AdvertEditorBlock>
                <AdvertEditorBlock title={LANG_PATH + "access>title"}
                                   info={LANG_PATH + "access>infobox_description"}
                                   invalid={this.props.requirements.access ||
                                       this.props.requirements.misc_access_description}
                                   invalid_tooltip={LANG_PATH + "requirements>access"}>
                    <form ref={this.access}>
                        <TextArea initialValue={this.props.advert.access_description} name="access_description"
                                  label={LANG_PATH + "access>access_label"} rows={3}
                                  maxLength={MAX_ACCESS_DESCRIPTION_LENGTH}
                                  onChange={() => {this.actualizeRequirements()}}/>
                        {
                            Object.values(AccessType).filter(r => r !== AccessType.miscellaneous &&
                                r !== AccessType.key_storage)
                                .map((access, _) => {
                                    return <div className="form-group fixed-height" key={access} style={{height: "32px", display: "flex", alignItems: "center"}}>
                                        <input type="checkbox" id={access} name={access}
                                               defaultChecked={this.props.advert.access && !!this.props.advert.access
                                                   .filter(a => a.type === access)[0]}
                                               onClick={() => {this.actualizeRequirements()}}/>
                                        <label htmlFor={access}>
                                            {
                                                getLanguageEntry(LANG_PATH + "access>access_types>" + access)
                                            }
                                        </label>
                                    </div>
                                })
                        }
                        <div className="horizontal-form-group" style={{height: "32px", display: "flex", alignItems: "center"}}>
                            <div className="form-group fixed-height">
                                <input type="checkbox" id={AccessType.key_storage}
                                       name={AccessType.key_storage}
                                       onClick={(e) => {
                                           this.setState({key_storage: e.target.checked},
                                               () => {this.actualizeRequirements()})
                                       }}
                                       defaultChecked={!!this.state.key_storage}/>
                                <label htmlFor={AccessType.key_storage}>
                                    {
                                        getLanguageEntry(LANG_PATH + "access>access_types>key_storage")
                                    }
                                </label>
                            </div>
                            {
                                this.state.key_storage &&
                                <input type="text" maxLength={50} defaultValue={this.state.key_code} required={true}
                                       name="key_storage_code"
                                       placeholder={getLanguageEntry(LANG_PATH + "access>key_storage_code")}/>
                            }
                        </div>
                        <div className="form-group fixed-height" key={AccessType.miscellaneous}>
                            <input type="checkbox" id={AccessType.miscellaneous}
                                   name={AccessType.miscellaneous}
                                   onClick={(e) => {
                                       this.setState({misc: e.target.checked}, () => {this.actualizeRequirements()})
                                   }}
                                   defaultChecked={this.props.advert.access && !!this.props.advert.access
                                       .filter(a => a.type === AccessType.miscellaneous)[0]}/>
                            <label htmlFor={AccessType.miscellaneous}>
                                {
                                    getLanguageEntry(LANG_PATH + "access>access_types>miscellaneous")
                                }
                            </label>
                        </div>
                        {
                            this.state.misc &&
                            <TextArea initialValue={this.state.misc_desc} required={true}
                                      invalid={this.props.requirements.misc_access_description}
                                      maxLength={MAX_MISC_ACCESS_TEXT_LENGTH} rows={3} name="misc_access"
                                      placeholder={LANG_PATH + "access>misc_access_placeholder"}
                                      onChange={() => {this.actualizeRequirements()}} />
                        }
                        <table className="advert-editor-table">
                            <tbody>
                            <tr>
                                <td className="description-container">
                                    {getLanguageEntry(LANG_PATH + "access>wifi_ssid")}
                                </td>
                                <td>
                                    <input type="text" maxLength={100} name="wifi_ssid"
                                           defaultValue={this.props.advert.wifi_ssid}/>
                                </td>
                            </tr>
                            <tr>
                                <td className="description-container">
                                    {getLanguageEntry(LANG_PATH + "access>wifi_code")}
                                </td>
                                <td>
                                    <input type="text" maxLength={100} name="wifi_code"
                                           defaultValue={this.props.advert.wifi_code}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </AdvertEditorBlock>
                <AdvertEditorBlock title={LANG_PATH + "legal_links>title"}
                                   description={LANG_PATH + "legal_links>legal_links_info"}
                                   warning={this.props.requirements.legal_docs}>
                    <table className="advert-editor-table">
                        <tbody>
                            <tr>
                                <td>
                                    <label className="description-container" htmlFor="terms-of-service">
                                        {getLanguageEntry(LANG_PATH + "legal_links>terms_of_service")}
                                    </label>
                                </td>
                                <td>
                                    <div className="horizontal-form-group" style={{width: "max-content"}}>
                                        {
                                            !this.state.terms_of_service &&
                                            <button className="outline-button black"
                                                    onClick={() => {this.callLegalDocumentModal("terms_of_service")}}>
                                                <FontAwesomeIcon icon={["fal", "plus"]} />
                                                <span>{getLanguageEntry(LANG_PATH + "legal_links>add_terms")}</span>
                                            </button>
                                        }
                                        {
                                            this.state.terms_of_service &&
                                            <input id="terms-of-service" name="terms_of_service" readOnly={true}
                                                   className={this.props.advert.terms_of_service !== undefined &&
                                                   !this.props.advert.terms_of_service ? "invalid" : ""}
                                                   value={getFileNameFromBase64(this.state.terms_of_service)}
                                                   onClick={() => {this.callLegalDocumentModal("terms_of_service")}} />
                                        }
                                        {
                                            this.state.terms_of_service &&
                                            this.state.terms_of_service.startsWith("https://") &&
                                            <a href={this.state.terms_of_service} target="_blank"
                                               rel="noreferrer nofollow">
                                                <FontAwesomeIcon icon={["fal", "up-right-from-square"]} />
                                            </a>
                                        }
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label className="description-container" htmlFor="revocation">
                                        {getLanguageEntry(LANG_PATH + "legal_links>revocation")}
                                    </label>
                                </td>
                                <td>
                                    <div className="horizontal-form-group" style={{width: "max-content"}}>
                                        {
                                            !this.state.revocation &&
                                            <button className="outline-button black"
                                                    onClick={() => {this.callLegalDocumentModal("revocation")}}>
                                                <FontAwesomeIcon icon={["fal", "plus"]} />
                                                <span>{getLanguageEntry(LANG_PATH + "legal_links>add_revocation")}</span>
                                            </button>
                                        }
                                        {
                                            this.state.revocation &&
                                            <input id="revocation" name="revocation" readOnly={true}
                                                   value={getFileNameFromBase64(this.state.revocation)}
                                                   className={this.props.advert.revocation !== undefined &&
                                                   !this.props.advert.revocation ? "invalid" : ""}
                                                   onClick={() => {this.callLegalDocumentModal("revocation")}}/>
                                        }
                                        {
                                            this.state.revocation && this.state.revocation.startsWith("https://") &&
                                            <a href={this.state.revocation} target="_blank"
                                               rel="noreferrer nofollow">
                                                <FontAwesomeIcon icon={["fal", "up-right-from-square"]} />
                                            </a>
                                        }
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label className="description-container" htmlFor="privacy_policy">
                                        {getLanguageEntry(LANG_PATH + "legal_links>privacy_policy")}
                                    </label>
                                </td>
                                <td>
                                    <div className="horizontal-form-group" style={{width: "max-content"}}>
                                        {
                                            !this.state.privacy_policy &&
                                            <button className="outline-button black"
                                                    onClick={() => {this.callLegalDocumentModal("privacy_policy")}}>
                                                <FontAwesomeIcon icon={["fal", "plus"]} />
                                                <span>{getLanguageEntry(LANG_PATH + "legal_links>add_privacy_policy")}</span>
                                            </button>
                                        }
                                        {
                                            this.state.privacy_policy &&
                                            <input id="privacy_policy" name="privacy_policy" readOnly={true}
                                                   value={getFileNameFromBase64(this.state.privacy_policy)}
                                                   className={this.props.advert.privacy_policy !== undefined &&
                                                   !this.props.advert.privacy_policy ? "invalid" : ""}
                                                   onClick={() => {this.callLegalDocumentModal("privacy_policy")}}/>
                                        }
                                        {
                                            this.state.privacy_policy && this.state.privacy_policy.startsWith("https://") &&
                                            <a href={this.state.privacy_policy} target="_blank"
                                               rel="noreferrer nofollow">
                                                <FontAwesomeIcon icon={["fal", "up-right-from-square"]} />
                                            </a>
                                        }
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </AdvertEditorBlock>
                <AdvertEditorBlock title={LANG_PATH + "miscellaneous_advert_info>title"}>
                    <form ref={this.misc_advert_info}>
                        <table className="advert-editor-table">
                            <tbody>
                            <tr>
                                <td>
                                    <label className="description-container" htmlFor="reg_no">
                                        {getLanguageEntry(LANG_PATH + "miscellaneous_advert_info>registration_number_label")}
                                        <FontAwesomeIcon icon={["fas", "info-circle"]} style={{marginLeft: "5px", color: "var(--tertiary-accent-color)"}}
                                                         title={getLanguageEntry(LANG_PATH + "miscellaneous_advert_info>reg_no_info")}/>
                                    </label>
                                </td>
                                <td>
                                    <input type="text" id="reg_no" defaultValue={this.props.advert.reg_no}
                                           name="reg_no"/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label className="description-container" htmlFor="land_reg_no">
                                        {getLanguageEntry(LANG_PATH + "miscellaneous_advert_info>land_reg_no_label")}
                                        <FontAwesomeIcon icon={["fas", "info-circle"]} style={{marginLeft: "5px", color: "var(--tertiary-accent-color)"}}
                                                         title={getLanguageEntry(LANG_PATH + "miscellaneous_advert_info>land_reg_no_info")}/>
                                    </label>
                                </td>
                                <td>
                                    <input type="text" id="land_reg_no" defaultValue={this.props.advert.land_reg_no}
                                           name="land_reg_no"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </AdvertEditorBlock>
                <LegalDocumentModal onSave={(type, url) => {
                    let state = {};
                    state[type] = url ?? "";
                    this.setState(state);
                }} ref={this.legal_doc_modal} />
            </div>
        )
    }

    actualizeRequirements() {
        if (this.props.checkRequirements) {
            this.props.updateRequirements?.(this.extractData());
        }
    }

    getTabTpe() {
        return AdvertEditorTab.booking_settings;
    }

    callLegalDocumentModal(type) {
        if (this.legal_doc_modal.current) {
            this.legal_doc_modal.current.show(type, this.state[type]);
        }
    }

}
BookingSettingsTab.propTypes = {
    advert: PropTypes.any.isRequired,
    userData: PropTypes.any.isRequired,
    updateRequirements: PropTypes.func.isRequired,
    checkRequirements: PropTypes.bool.isRequired,
    requirements: PropTypes.any.isRequired
}
export default BookingSettingsTab;