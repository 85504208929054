import React from "react";
import PropTypes from "prop-types";
import {createVATDropdown, getLanguageEntry, parsePriceInput} from "../../utils/Helper";
import {UnitType} from "../../utils/Types.ts";
import UnitInputField from "./UnitInputField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class FeeEditor extends React.Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.input = React.createRef();
        this.tax_selector = React.createRef();
        this.langPath = this.props.isFee ?
            "jackboard>adverts>advert_creator>pricing>additional_fees>" :
            "jackboard>adverts>advert_creator>pricing>running_costs>";
        this.state = {
            name: this.props.data ? this.props.data.name : '',
            tax: this.props.data ? this.props.data.tax : '',
            price: this.props.data ? (this.props.data.price / 100).toString() : ''
        }
    }

    onNameChange(e) {
        e.preventDefault();
        this.setState({
            name: e.target.value
        });
    }

    onTaxChange(e, value) {
        e.preventDefault();
        this.setState({
            tax: value
        });
    }

    onPriceChange(e) {
        e.preventDefault();
        this.setState({
            price: e.target.value
        });
    }

    handleBlur(e) {
        e.preventDefault();
        if (!this.form.current.contains(e.relatedTarget)) {
            this.props.onChange?.(this.value);
        }
    }

    render() {
        return <form className="fee-editor horizontal-form-group" ref={this.form}
                     onBlur={(e) => {this.handleBlur(e)}}
                     onSubmit={(e) => {e.preventDefault()}}>
            {
                (!this.props.name || !this.props.predefinedNames ||
                    !this.props.predefinedNames[this.props.name]) &&
                <input type="text" maxLength={20} required={true} name="name"
                       value={this.state.name} onChange={(e) => {this.onNameChange(e)}}
                       placeholder={getLanguageEntry(this.langPath + "name_placeholder")} />
            }
            {
                this.props.name && this.props.predefinedNames &&
                this.props.predefinedNames[this.props.name] &&
                <div className="description-container">
                    {getLanguageEntry(this.langPath + "types>" + this.props.name)}
                </div>
            }
            {
                createVATDropdown([7, 19], "tax", this.tax_selector, (e, val) => {this.onTaxChange(e, val)},
                    this.props.data ? this.props.data.tax : null, "components>invoicing>value_added_tax", false, false)
            }
            <UnitInputField placeholder={this.langPath + "price_placeholder"} type={UnitType.currency} required={true}
                            value={this.state.price} onChange={(e) => {this.onPriceChange(e)}} ref={this.input}
                            name="price" controlled={true}/>
            <button className="simple-icon-button" onClick={(e) => {this.emptyForm(e)}}>
                <FontAwesomeIcon icon={["fal", "trash"]} />
            </button>
        </form>
    }

    get value() {
        let formData = new FormData(this.form.current);
        if ((formData.get("name") === null || formData.get("name").length === 0) &&
            formData.get("price").length === 0) {
            return null;
        }
        let price = parsePriceInput(formData.get("price"));
        let name = formData.has("name") ? formData.get("name") : this.props.name;
        let fee = {
            name: name
        };
        if (this.tax_selector.current && this.tax_selector.current.value) {
            fee.tax = this.tax_selector.current.value;
        }
        if (price.valid) {
            fee.price = price.costs_cent;
        }
        return fee;
    }

    isValid() {
        let valid = true;
        if (!this.props.isPredefined) {
            valid = this.form.current.checkValidity();
        }
        if (!valid) {
            if (this.props.name)
                this.form.current.reportValidity();
        }
        return valid;
    }

    emptyForm(e) {
        e.preventDefault();
        this.setState({
            price: '',
            tax: '',
        }, () => {
            this.form.current.reset();
            this.tax_selector.current.value = null;
            this.props.onChange?.(null);
        });
    }

}

FeeEditor.propTypes = {
    name: PropTypes.string,
    data: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    isFee: PropTypes.bool.isRequired,
    isPredefined: PropTypes.bool.isRequired,
    predefinedNames: PropTypes.object
}
export default FeeEditor;