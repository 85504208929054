import React from "react";
import {Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getLanguageEntry} from "../../utils/Helper";
import {MAX_ROOM_COUNT, ROOM_ICON_MAP} from "../../../resources/housings";
import {RoomType} from "../../utils/Types.ts";

class RoomAdderModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            on_add_room: null,
            room_selection_map: {},
            roomStartMap: {}
        }
        this.grid = React.createRef();
        this.eventAdded = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.eventAdded && this.grid.current) {
            this.grid.current.addEventListener("contextmenu", e => e.preventDefault());
            this.eventAdded = true;
        }
    }

    render() {
        return (
            <Modal className="room-adder-modal landlord" show={this.state.show}
                   onHide={() => { this.hide() }} dialogClassName="max-content" centered>
                <Modal.Header>
                    <h4>{getLanguageEntry("jackboard>adverts>advert_creator>room_adder_modal>title")}</h4>
                    <button onClick={() => { this.hide() }}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>
                <Modal.Body style={{display: "grid", gap: "20px"}}>
                    <div className="description-container">
                        {getLanguageEntry("jackboard>adverts>advert_creator>room_adder_modal>description")
                            .replace("#", "" + MAX_ROOM_COUNT)}
                    </div>
                    <div className="desktop-submenu-grid" style={{justifyContent: "center"}} ref={this.grid}>
                        {Object.keys(this.state.room_selection_map).map((roomType, _) => {
                            return <button className={"desktop-submenu-tile selectable" +
                                (this.state.room_selection_map[roomType] > 0 ? " selected" : "")}
                                           onMouseUp={(e) => {this.onRoomTileClicked(e, roomType)}} key={roomType}>
                                <div className="tile-icon-container">
                                    <FontAwesomeIcon icon={["fal", ROOM_ICON_MAP[roomType]]} />
                                </div>
                                <div className="tile-text-container">
                                    {getLanguageEntry("advert_attributes>room_types>" + roomType)}
                                </div>
                                {
                                    this.state.room_selection_map[roomType] > 0 &&
                                    <div className="tile-text-container" style={{position: "absolute", right: "8px", bottom: "8px"}}>
                                        {this.state.room_selection_map[roomType]}x
                                    </div>
                                }
                            </button>
                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="roomjack-headline" style={{marginRight: "auto"}}>
                        {getLanguageEntry("jackboard>adverts>advert_creator>room_adder_modal>counter")
                            .replace("{x}", "" + this.getCount())
                            .replace("{max}", "" + MAX_ROOM_COUNT)
                        }
                    </div>
                    <button className="outline-button accent" onClick={() => {
                        this.state.on_add_room?.(this.getNewRoomsMap());
                        this.hide();
                    }}>
                        {getLanguageEntry("general>done")}
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
    
    show(advert, onAddRoom) {
        let roomStartMap = {};
        for (const roomType of Object.values(RoomType)) {
            roomStartMap[roomType] = advert && advert.rooms
                ? advert.rooms.filter((r) => r.type === roomType).length
                : 0;
        }
        this.setState({
            show: true,
            on_add_room: onAddRoom,
            room_selection_map: {...roomStartMap},
            roomStartMap,
            advert: advert,
        })
    }

    hide() {
        this.setState({
            show: false,
            on_add_room: null
        })
    }

    onRoomTileClicked(e, roomType) {
        if (e.button === 0 && this.getCount() < MAX_ROOM_COUNT) {
            let room_selection_map = {...this.state.room_selection_map};
            room_selection_map[roomType] += 1;
            this.setState({room_selection_map: room_selection_map})
        }
        if (e.button === 2) {
            let room_selection_map = {...this.state.room_selection_map};
            const startCount = this.state.roomStartMap[roomType];
            if (!this.state.advert || !this.state.advert.first_publishing ||
                room_selection_map[roomType] - 1 >= startCount) {
                room_selection_map[roomType] = Math.max(0, room_selection_map[roomType] - 1);
                this.setState({room_selection_map: room_selection_map});
            }
        }
    }

    getCount() {
        if (Object.keys(this.state.room_selection_map).length === 0) {
            return 0;
        }
        return Object.values(this.state.room_selection_map).reduce((a, b) => a + b);
    }

    getNewRoomsMap() {
        let map = {};
        Object.keys(this.state.room_selection_map).forEach((roomType) => {
            map[roomType] = this.state.room_selection_map[roomType];
            if (this.state.roomStartMap[roomType]) {
                map[roomType] = Math.max(0, map[roomType] - this.state.roomStartMap[roomType]);
            }
        });
        return map;
    }

}
export default RoomAdderModal;