import React from "react";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import {getLanguageEntry} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FetchButton from "../buttons/FetchButton";
import PaymentHandler from "../input/PaymentHandler";
import {PaymentTarget} from "../../utils/Types.ts";
import {
    changePaymentMethodForBooking,
    startPaymentMethodChange
} from "../../utils/RESTInterface";

class PaymentMethodChangeModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            booking: null,
            change_result: null,
            changing: null,
            loading: false
        }
        this.payment_handler = React.createRef();
    }

    render() {
        return (
            <Modal className={"payment-method-change-modal"} show={this.state.show} size="lg" backdrop="static"
                   keyboard={false} onHide={() => { this.hide() }} dialogClassName="max-content" centered>
                <Modal.Header>
                    <h4>{getLanguageEntry("processes>payment_method_change>title")}</h4>
                    <button onClick={() => { this.hide() }} disabled={this.state.loading || this.state.changing}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>

                <Modal.Body>
                    {
                        this.state.booking && !this.state.change_result &&
                        <div style={{display: "grid", gap: "20px"}}>
                            <div className="description-container">
                                {getLanguageEntry("processes>payment_method_change>description")}
                            </div>
                            <PaymentHandler target={PaymentTarget.connect} ref={this.payment_handler}
                                            onPaymentConfirmed={(r) => {this.onPaymentConfirmed(r)}}
                                            accountOwnerID={this.state.booking.owner.id} waitForPMs={true}
                                            onConfirmationStarted={() => {
                                                this.setState({changing: "components>payment_handler>verifying_payment_method"})
                                            }}/>
                            <FetchButton className="outline-button accent" loading={this.state.changing !== null}
                                         loadingText={this.state.changing} onClick={(e) => {this.startPaymentMethodChange(e)}}
                                         disabled={this.state.loading} style={{width: "max-content", justifySelf: "center"}}>
                                {getLanguageEntry("general>save")}
                            </FetchButton>
                        </div>
                    }
                    {
                        this.state.booking && this.state.change_result &&
                        <div className="process-modal-result-container">
                            <div className="process-result-image">
                                <img src={this.state.change_result.success ?
                                    "https://roomjack.blob.core.windows.net/roomjack/animations/highfive.gif" :
                                    "https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear.png"}
                                     alt={"Payment method change " +
                                         (this.state.change_result.success ? "success" : "failed")} />
                            </div>
                            <div className="process-result-message-box">
                                <div className="header">
                                    {
                                        getLanguageEntry("processes>payment_method_change>" +
                                            (this.state.change_result.success ? "process_succeeded" : "process_failed"))
                                    }
                                </div>
                                {
                                    !this.state.change_result.success &&
                                    <div className="description-container">
                                        <span>{getLanguageEntry("general>contact_support_for_link")}</span>
                                        <button className="link-button" onClick={() => {this.props.onContactSupport?.()}}>
                                            {getLanguageEntry("general>here")}
                                        </button>.
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        )
    }

    show(booking) {
        this.setState({
            show: true,
            booking: booking,
            change_result: null,
            changing: null,
            loading: true
        });
        let that = this;
        startPaymentMethodChange(booking.id, (response) => {
            if (response.data && that.payment_handler.current) {
                that.payment_handler.current.createDropdownItems(response.data, response.payment_method);
            }
            that.setState({loading: false})
        });
    }

    hide() {
        this.setState({
            show: false,
            booking: null,
            change_result: null,
            changing: null
        })
    }

    startPaymentMethodChange(e) {
        if (this.payment_handler.current) {
            console.log(this.payment_handler.current.hasChanged());
            if (this.payment_handler.current.hasChanged()) {
                this.payment_handler.current.triggerSubmit(e);
            }
            else {
                this.hide();
            }
        }
    }

    onPaymentConfirmed(response) {
        if (response.setupIntent) {
            let that = this;
            this.setState({changing: "processes>payment_method_change>process_running"});
            changePaymentMethodForBooking(this.state.booking.id, response.setupIntent.id, (response) => {
                    that.setState({
                        changing: null,
                        change_result: response
                    });
                    if (response.errorCode) {
                        console.warn(response.errorCode + ": " + response.message);
                    }
                    else if (response.booking) {
                        that.props.onBookingUpdated(response.booking);
                    }
                })
        }
        else {
            this.setState({change_result: null})
        }
    }

}

PaymentMethodChangeModal.propTypes = {
    onContactSupport: PropTypes.func.isRequired,
    userData: PropTypes.any.isRequired,
    onBookingUpdated: PropTypes.func.isRequired,
}
PaymentMethodChangeModal.defaultProps = {}
export default PaymentMethodChangeModal;