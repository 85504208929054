import React from "react";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import {calculateInvoiceFromObject, createCancellationConditionList, getLanguageEntry} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FetchButton from "../buttons/FetchButton";
import BookingDetailTable from "../booking/BookingDetailTable";
import BookingInvoiceTable from "../booking/BookingInvoiceTable";
import BookingTransactionDetails from "../booking/BookingTransactionDetails";
import _uniqueId from "lodash/uniqueId";
import {downloadReceipt, getBookingTransactionDetails} from "../../utils/RESTInterface";
import RoomJackFeeInvoice from "../printables/RoomJackFeeInvoice";
import {BOOKING_CACHE} from "../../utils/CacheHandler.ts";
import Dropdown from "../input/Dropdown";

class BookingDetailModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            booking: null,
            downloading_receipt: false,
            downloading_invoice: false,
            selected_charge: null
        }
    }

    render() {
        let addCancellationInfo = this.state.booking && !this.state.booking.cancelled && this.state.booking.start > Date.now() &&
            !this.state.booking.invoice_data.pricing.long_term;
        let invoice = this.state.booking ? calculateInvoiceFromObject(this.state.booking) : null;
        return(
            <Modal className={"booking-detail-modal " + this.props.mode} show={this.state.show} size="lg"
                   onHide={() => { this.hide() }} dialogClassName="max-content" centered>
                <Modal.Header>
                    <h4>{getLanguageEntry("components>booking_detail_modal>title")}</h4>
                    <button onClick={() => { this.hide() }} disabled={this.state.loading}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>

                <Modal.Body>
                    {
                        this.state.booking && this.state.bookings.length > 1 && this.createBookingSelector()
                    }
                    <div className="booking-detail-container">
                        <BookingDetailTable booking={this.state.booking} userData={this.props.userData}
                                            onSendMessage={this.props.onSendMessage}/>
                        {
                            this.state.booking &&
                            <div className="booking-table-header">
                                {getLanguageEntry("components>booking_detail_table>" +
                                    (this.state.booking.invoice_data.pricing.long_term ? "rent_in_detail" : "booking_costs_in_detail"))}
                            </div>
                        }
                        <BookingInvoiceTable booking={this.state.booking} detailed={true}
                                             show_discount={!!this.state.booking && this.props.userData.id === this.state.booking.booker}/>
                        <div className="booking-table-header">
                            {getLanguageEntry("components>booking_detail_table>transaction_history")}
                        </div>
                        <BookingTransactionDetails booking={this.state.booking} userData={this.props.userData}
                                                   onDetailsLoaded={() => {this.updateBooking()}}
                                                   onChargeSelected={(charge) => {this.setState({selected_charge: charge})}}/>
                        {
                            addCancellationInfo &&
                            <div className="booking-table-header">
                                {getLanguageEntry("processes>booking>cancellation_condition_title")}
                            </div>
                        }
                        {
                            addCancellationInfo &&
                            <div>
                                {
                                    createCancellationConditionList(
                                        this.state.booking.invoice_data.cancellation_conditions,
                                        invoice, this.state.booking.start).map((c, _) => c)
                                }
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        this.state.booking && this.state.booking.invoice_data.pro_tool &&
                        this.state.booking.charge_data && this.state.booking.charge_data.charges &&
                        this.state.selected_charge && this.state.selected_charge.paid &&
                        <button className="outline-button accent" onClick={() => {
                            this.props.onCreateInvoice(this.state.booking, this.state.selected_charge);
                            this.hide();
                        }}>
                            <FontAwesomeIcon icon={["fal", "file-invoice"]}/>
                            <span>{getLanguageEntry("jackboard>bookings>print_guest_invoice")}</span>
                        </button>
                    }
                    {
                        this.state.booking && this.state.booking.charge_data &&
                        this.state.booking.charge_data.charges &&
                        this.state.selected_charge && this.state.selected_charge.paid_off &&
                        <FetchButton className="outline-button accent" loading={this.state.downloading_invoice}
                                loadingText="general>loading" onClick={() => {
                                    if (!this.state.booking.transaction_details_loaded) {
                                        this.setState({downloading_invoice: true});
                                        let that = this;
                                        getBookingTransactionDetails(this.state.booking.id, () => {
                                            that.updateBooking(() => {
                                                that.showLandlordInvoice()
                                            });
                                        })
                                    }
                                    else {
                                        this.showLandlordInvoice();
                                    }
                                }}
                                key={_uniqueId(this.state.booking.id + "-invoice")}>
                            <FontAwesomeIcon icon={["fal", "file-invoice"]}/>
                            <span>{getLanguageEntry("jackboard>bookings>print_landlord_invoice")}</span>
                        </FetchButton>
                    }
                    {
                        this.state.booking && this.state.booking.charge_data &&
                        this.state.booking.charge_data.charges &&
                        this.state.selected_charge && this.state.selected_charge.payment_intent_id &&
                        <FetchButton className="outline-button accent" loading={this.state.downloading_receipt}
                                     loadingText="general>loading" onClick={() => {this.getReceipt()}}
                                     key={_uniqueId(this.state.booking.id + "-receipt")}>
                            <FontAwesomeIcon icon={["fal", "receipt"]}/>
                            <span>{getLanguageEntry("general>receipt")}</span>
                        </FetchButton>
                    }
                </Modal.Footer>
            </Modal>
        )
    }

    show(bookings, onUpdate) {
        this.setState({
            show: true,
            bookings: bookings,
            booking: bookings[0],
            selected_charge: bookings[0].charge_data && bookings[0].charge_data.charges ?
                bookings[0].charge_data.charges[0] : null,
            on_update: onUpdate
        });
    }

    hide() {
        this.setState({
            show: false
        })
    }

    getReceipt() {
        if (!this.state.selected_charge || !this.state.selected_charge.payment_intent_id) {
            return;
        }
        let charge = this.state.selected_charge;
        if (charge.receipt_url) {
            window.open(charge.receipt_url, "_blank", "noreferrer nofollow noopener");
        }
        else {
            let that = this;
            this.setState({downloading_receipt: true});
            downloadReceipt(this.state.booking.id, charge, () => {
                that.setState({downloading_receipt: false});
                if (charge.receipt_url) {
                    window.open(charge.receipt_url, "_blank", "noreferrer nofollow noopener");
                }
            });
        }
    }

    updateBooking(callback) {
        let booking = BOOKING_CACHE.getCacheObject(this.state.booking.id);
        if (booking) {
            booking = booking.getData();
            let charge = null;
            if (this.state.selected_charge) {
                charge = booking.charge_data.charges
                    .filter(c => c.id === this.state.selected_charge.id)[0];
            }
            let bookings = this.state.bookings;
            bookings.splice(bookings.findIndex(b => b.id === booking.id), 1, booking);
            this.setState({
                bookings: bookings,
                booking: booking,
                selected_charge: charge,
                downloading_invoice: false
            }, () => {callback?.()});
        }
        this.state.on_update?.();
    }

    showLandlordInvoice() {
        let doc = <RoomJackFeeInvoice booking={this.state.booking} charge={this.state.selected_charge}
                                      userData={this.props.userData} />
        this.props.onViewInvoice?.(doc, `${this.state.booking.confirmation_code}.pdf`);
    }

    createBookingSelector() {
        if (!this.state.bookings) {
            return;
        }
        let items = [];
        for (const booking of this.state.bookings) {
            items.push({
                value: booking.id,
                label: booking.confirmation_code + ", " + booking.booker.first_name + " " + booking.booker.last_name
            })
        }
        return <Dropdown items={items} onChange={(v) => {
            let booking = this.state.bookings.filter(b => b.id === v)[0];
            if (booking) {
                this.setState({booking: booking});
            }
        }} defaultValue={this.state.booking.id} name={"booking"}/>
    }

}

BookingDetailModal.propTypes = {
    userData: PropTypes.object.isRequired,
    onSendMessage: PropTypes.func.isRequired,
    onViewInvoice: PropTypes.func.isRequired,
    onCreateInvoice: PropTypes.func.isRequired
}
BookingDetailModal.defaultProps = {}
export default BookingDetailModal;