import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getLanguageEntry} from "../utils/Helper";
import PropTypes from "prop-types";
import NavigatorButton from "./buttons/NavigatorButton";
import {Mode} from "../utils/Types.ts";

class MainMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hide: true
        }
        this.menu = React.createRef();
    }

    render() {
        return(
            <div id="main-banner-menu-container" className="main-banner-content-container" ref={this.menu}
                 tabIndex="0" onBlur={(e) => { this.handleFocus(e)}} hidden={this.state.hide}>
                <NavigatorButton id="main-banner-menu-desktop" className="main-banner-menu-entry"
                                 to="/desktop/dashboard">
                    <FontAwesomeIcon icon={["fal", "table-columns"]} />
                    <span>{getLanguageEntry("main_banner>main_menu>dashboard")}</span>
                </NavigatorButton>
                <NavigatorButton id="main-banner-menu-profile" className="main-banner-menu-entry"
                                 to="/profile/overview">
                    <FontAwesomeIcon icon={["fal", "user-circle"]} />
                    <span>{getLanguageEntry("main_banner>main_menu>profile")}</span>
                </NavigatorButton>
                <NavigatorButton id="main-banner-menu-mode-toggle" className="main-banner-menu-entry"
                                 to={this.props.mode === Mode.renter ? "/vermieten" : "/"}
                                 onBeforeClick={() => {this.props.switchMode()}}>
                    <FontAwesomeIcon icon={["fal", "arrows-rotate"]} />
                    <span>{this.getModeToggleText()}</span>
                </NavigatorButton>
                <button id="main-banner-menu-logout" className="main-banner-menu-entry"
                        onClick={() => { this.props.onLogout() }}>
                    <FontAwesomeIcon icon={["fal", "arrow-right-from-bracket"]} />
                    <span>{getLanguageEntry("main_banner>main_menu>logout")}</span>
                </button>
            </div>
        )
    }

    show() {
        this.setState({hide: false},
            () => { this.menu.current.focus(); });
    }

    hide() {
        this.setState({hide: true});
    }

    toggle() {
        if (this.state.hide) {
            this.show();
        }
        else {
            this.hide();
        }
    }

    handleFocus(e) {
        if (!this.menu.current.parentElement.contains(e.relatedTarget)) {
            this.hide();
        }
    }

    getModeToggleText() {
        return getLanguageEntry("main_banner>main_menu>mode") + ": " +
            getLanguageEntry("main_banner>mode_toggle>" + this.props.mode);
    }

}
MainMenu.propTypes = {
    onLogout: PropTypes.func.isRequired,
    switchMode: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(Object.values(Mode)).isRequired
}
export default MainMenu;