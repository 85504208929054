import React from "react";
import {Modal} from "react-bootstrap";
import {
    createVATDropdown,
    getDayFromBegin,
    getLanguageEntry,
    getNextDay,
    getTimeUnitInMS,
} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UnitType} from "../../utils/Types.ts";
import UnitInputField from "../input/UnitInputField";
import {
    PREDEFINED_FEE_TYPES,
    PREDEFINED_LONG_TERM_FEE_TYPES,
    PREDEFINED_RUNNING_COSTS
} from "../../../resources/housings";
import FeeEditor from "../input/FeeEditor";
import _uniqueId from "lodash/uniqueId";
import CustomToggle from "../input/CustomToggle";
import BookingInvoiceTable from "../booking/BookingInvoiceTable";

const LANG_PATH = "jackboard>adverts>advert_creator>pricing>"

class PricingModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            on_save: null,
            advert: {},
            housing: {},
            title: getLanguageEntry(LANG_PATH + "title"),
            tab: 0,
            pro_tool: false
        }

        this.dailyRent = React.createRef();
        this.cleaningFee = React.createRef();
        this.serviceFee = React.createRef();
        this.shortTermEarlyDiscount = React.createRef();
        this.shortTermFeeReferenceMap = {};

        this.monthlyRent = React.createRef();
        this.heatingCosts = React.createRef();
        this.waterCosts = React.createRef();
        this.electricityCosts = React.createRef();
        this.endCleaning = React.createRef();
        this.longTermEarlyDiscount = React.createRef();
        this.longTermFeeReferenceMap = {};
        this.runningCostReferenceMap = {};
    }

    render() {
        let pricing = this.state.housing.pricing;
        let longTermPricing = this.state.housing.long_term_pricing;
        let exampleMissing;
        if (this.state.advert.max_booking_length > 29) {
            exampleMissing = !longTermPricing || !longTermPricing.rent;
        }
        if (this.state.advert.min_booking_length < 30) {
            exampleMissing = !pricing || !pricing.rent;
        }
        let dayBooking; let weekBooking; let monthBooking;
        if (!exampleMissing) {
            let tomorrow = getNextDay(getDayFromBegin());
            let booking = {
                start: tomorrow.getTime(),
            }
            if (this.state.tab === 0 && pricing) {
                let invoiceData = {
                    pricing: pricing,
                    pro_tool: this.state.pro_tool
                }
                dayBooking = {
                    ...booking,
                    invoice_data: invoiceData,
                    end: getNextDay(tomorrow).getTime()
                };
                weekBooking = {
                    ...booking,
                    invoice_data: invoiceData,
                    end: new Date(tomorrow.getTime() + getTimeUnitInMS(7)).getTime()
                };
            }
            if (longTermPricing && (this.state.advert.min_booking_length > 29 || this.state.tab === 1)) {
                monthBooking = {
                    ...booking,
                    invoice_data: {
                        pricing: longTermPricing,
                        pro_tool: this.state.pro_tool
                    },
                    end: new Date(tomorrow.getTime() + getTimeUnitInMS(30)).getTime()
                };
            }
        }
        return (
            <Modal className="pricing-modal landlord" show={this.state.show} size="xl"
                   onHide={() => { this.hide() }} dialogClassName="max-content" centered>
                <Modal.Header>
                    <h4>{this.state.title}</h4>
                    <button onClick={() => { this.hide() }}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>
                {
                    this.state.housing &&
                    <Modal.Body>
                        <div className="pricing-model-container">
                            {
                                this.state.advert.min_booking_length < 30 &&
                                this.state.advert.max_booking_length > 29 &&
                                <div className="horizontal-form-group">
                                    <div className="accent-radio-button">
                                        <input type="radio" value="short-term" id="short-term-tab-button" readOnly={true}
                                               checked={this.state.tab === 0} name="pricing-model"
                                               onClick={(e) => {this.setTab(0)}}/>
                                        <label htmlFor="short-term-tab-button">
                                            {getLanguageEntry(LANG_PATH + "short_term_tab")}
                                        </label>
                                    </div>
                                    <div className="accent-radio-button">
                                        <input type="radio" value="long-term" id="long-term-tab-button" readOnly={true}
                                               checked={this.state.tab === 1} name="pricing-model"
                                               onClick={(e) => {this.setTab(1)}}/>
                                        <label htmlFor="long-term-tab-button">
                                            {getLanguageEntry(LANG_PATH + "long_term_tab")}
                                        </label>
                                    </div>
                                </div>
                            }
                            {
                                (this.state.advert.max_booking_length < 30 ||
                                 (this.state.advert.min_booking_length < 30 && this.state.tab === 0)) &&
                                <div className="pricing-tab">
                                    <div className={"pricing-unit-container"}>
                                        <div className="roomjack-headline">
                                            <span>{getLanguageEntry(LANG_PATH + "base_fee")}</span>
                                        </div>
                                        <form id="daily-fee" className="horizontal-form-group" ref={this.dailyRent}>
                                            <label className="description-container" htmlFor="modal-price">
                                                {getLanguageEntry(LANG_PATH + "daily_fee")}
                                            </label>
                                            {
                                                createVATDropdown([7, 19], "tax", null, (_, tax) => {this.editDailyRentTax(tax)},
                                                    pricing ? pricing.tax : null, "components>invoicing>value_added_tax", false, false)
                                            }
                                            <UnitInputField type={UnitType.currency} id="modal-price"
                                                            required={true} name="rent"
                                                            onChange={(val) => {this.editDailyRent(val)}}
                                                            initialValue={pricing && pricing.rent ? pricing.rent / 100 : ''}/>
                                        </form>
                                    </div>
                                    <div className={"pricing-unit-container"}>
                                        <div className="roomjack-headline">
                                            <span>{getLanguageEntry(LANG_PATH + "additional_fees>header")}</span>
                                            <FontAwesomeIcon icon={["fal", "info-circle"]} title={getLanguageEntry(LANG_PATH + "additional_fees>description")}/>
                                        </div>
                                        <div className="pricing-modal-grid">
                                            <FeeEditor name={PREDEFINED_FEE_TYPES.cleaning_fee} ref={this.cleaningFee}
                                                       predefinedNames={PREDEFINED_FEE_TYPES} isFee={true}
                                                       data={pricing && pricing.additional_fees ?
                                                           pricing.additional_fees.filter(f => f.name === PREDEFINED_FEE_TYPES.cleaning_fee)[0] : undefined}
                                                       isPredefined={true}
                                                       onChange={(fee) => {this.editShortTermFee(PREDEFINED_FEE_TYPES.cleaning_fee, fee)}}/>
                                            <FeeEditor name={PREDEFINED_FEE_TYPES.service_fee} ref={this.serviceFee}
                                                       predefinedNames={PREDEFINED_FEE_TYPES} isFee={true}
                                                       data={pricing && pricing.additional_fees ?
                                                           pricing.additional_fees.filter(f => f.name === PREDEFINED_FEE_TYPES.service_fee)[0] : undefined}
                                                       isPredefined={true}
                                                       onChange={(fee) => {this.editShortTermFee(PREDEFINED_FEE_TYPES.service_fee, fee)}}/>
                                            {
                                                pricing && pricing.additional_fees &&
                                                pricing.additional_fees.filter(f => PREDEFINED_FEE_TYPES[f.name] === undefined)
                                                    .map((f, i) => {
                                                        return <FeeEditor name={f.name} data={f} isFee={true}
                                                                          predefinedNames={PREDEFINED_FEE_TYPES}
                                                                          ref={this.shortTermFeeReferenceMap[f.name]}
                                                                          key={_uniqueId(f.name + "-" + i)}
                                                                          isPredefined={false}
                                                                          onChange={(fee) => {this.editShortTermFee(f.name, fee)}} />
                                                    })
                                            }
                                            {
                                                (!pricing || !pricing.additional_fees ||
                                                pricing.additional_fees.filter(f => !PREDEFINED_FEE_TYPES[f.name]).length < 5) &&
                                                <button className="outline-button black" onClick={() => {this.addShortTermFee()}}>
                                                    <FontAwesomeIcon icon={["fal", "plus"]}/>
                                                    <span>{getLanguageEntry(LANG_PATH + "additional_fees>add_custom_fee")}</span>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <div className={"pricing-unit-container"}>
                                        <div className="roomjack-headline">
                                            <span>{getLanguageEntry(LANG_PATH + "discounts")}</span>
                                        </div>
                                        <table className="advert-editor-table">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <label className="description-container" htmlFor="modal-weekly-discount">
                                                        {getLanguageEntry(LANG_PATH + "weekly_discount")}
                                                    </label>
                                                </td>
                                                <td>
                                                    <UnitInputField type={UnitType.percent} id="modal-weekly-discount"
                                                                    types={[UnitType.percent, UnitType.currency]}
                                                                    onChange={(val) => {this.editWeeklyDiscount(val)}}
                                                                    initialValue={pricing && pricing.weekly_discount ?
                                                                        pricing.weekly_discount : ''}/>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <form id="short-term-early" ref={this.shortTermEarlyDiscount}>
                                            <table className="advert-editor-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="advert-editor-table-header">
                                                            {getLanguageEntry(LANG_PATH + "early_booker_discount")}
                                                            <FontAwesomeIcon icon={["fal", "info-circle"]}
                                                                             title={getLanguageEntry(LANG_PATH + "early_discount_week_label_info")}/>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <label className="description-container" htmlFor="modal-early-discount-week">
                                                                {getLanguageEntry(LANG_PATH + "early_discount_week_placeholder")}
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <UnitInputField type={UnitType.count} min={1}
                                                                            id="modal-early-discount-week"
                                                                            onChange={(val) => {this.editShortTermEarlyWeeks(val)}}
                                                                            required={!!pricing && !!pricing.early_booker_discount &&
                                                                                !!pricing.early_booker_discount.discount}
                                                                            initialValue={pricing && pricing.early_booker_discount ?
                                                                                pricing.early_booker_discount.weeks : ''}/>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <label className="description-container" htmlFor="modal-early-discount">
                                                                {getLanguageEntry(LANG_PATH + "early_discount_percent_label")}
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <UnitInputField type={UnitType.percent} id="modal-early-discount"
                                                                            types={[UnitType.percent, UnitType.currency]}
                                                                            required={!!pricing && !!pricing.early_booker_discount &&
                                                                                !!pricing.early_booker_discount.weeks}
                                                                            onChange={(val) => {this.editShortTermEarlyDiscount(val)}}
                                                                            initialValue={pricing && pricing.early_booker_discount ?
                                                                                pricing.early_booker_discount.discount : ''}/>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                    <div className={"pricing-unit-container"}>
                                        <div className="roomjack-headline">
                                            <span>{getLanguageEntry(LANG_PATH + "regulations")}</span>
                                        </div>
                                        <table className="advert-editor-table">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <label className="description-container">
                                                        {getLanguageEntry(LANG_PATH + "small_business")}
                                                        <FontAwesomeIcon icon={["fal", "info-circle"]}
                                                                         title={getLanguageEntry(LANG_PATH + "small_business_info")} />
                                                    </label>
                                                </td>
                                                <td>
                                                    <CustomToggle rightValue={true} leftValue={false}
                                                                  onChange={(val) => {this.editShortTermSmallBusiness(val)}}
                                                                  defaultValue={pricing && !!pricing.small_business_regulation}
                                                                  name="small_business_regulation"/>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            {
                                (this.state.advert.min_booking_length > 29 ||
                                (this.state.advert.max_booking_length > 29 && this.state.tab === 1)) &&
                                <div className="pricing-tab">
                                    <div className={"pricing-unit-container"}>
                                        <div className="roomjack-headline">
                                            <span>{getLanguageEntry(LANG_PATH + "base_fee")}</span>
                                        </div>
                                        <form id="monthly-fee" className="horizontal-form-group" ref={this.monthlyRent}>
                                            <label className="description-container" htmlFor="modal-price">
                                                {getLanguageEntry(LANG_PATH + "monthly_fee")}
                                            </label>
                                            {
                                                createVATDropdown([7, 19], "tax", null, (_, tax) => {this.editMonthlyRentTax(tax)},
                                                    longTermPricing ? longTermPricing.tax : null, "components>invoicing>value_added_tax", false, false)
                                            }
                                            <UnitInputField type={UnitType.currency} id="modal-price"
                                                            required={true} name="rent"
                                                            onChange={(val) => {this.editMonthlyRent(val)}}
                                                            initialValue={longTermPricing && longTermPricing.rent ?
                                                                longTermPricing.rent / 100 : ''}/>
                                        </form>
                                    </div>
                                    <div className={"pricing-unit-container"}>
                                        <div className="roomjack-headline">
                                            <span>{getLanguageEntry(LANG_PATH + "running_costs>header")}</span>
                                            <FontAwesomeIcon icon={["fal", "info-circle"]} title={getLanguageEntry(LANG_PATH + "running_costs>fee_description")}/>
                                        </div>
                                        <div className="pricing-modal-grid">
                                            <FeeEditor name={PREDEFINED_RUNNING_COSTS.heating_costs} ref={this.heatingCosts}
                                                       predefinedNames={PREDEFINED_RUNNING_COSTS} isFee={false}
                                                       data={longTermPricing && longTermPricing.running_costs ?
                                                           longTermPricing.running_costs
                                                               .filter(f => f.name === PREDEFINED_RUNNING_COSTS.heating_costs)[0] : undefined}
                                                       isPredefined={true}
                                                       onChange={(cost) => {this.editRunningCost(PREDEFINED_RUNNING_COSTS.heating_costs, cost)}}/>
                                            <FeeEditor name={PREDEFINED_RUNNING_COSTS.water_costs} ref={this.waterCosts}
                                                       predefinedNames={PREDEFINED_RUNNING_COSTS} isFee={false}
                                                       data={longTermPricing && longTermPricing.running_costs ?
                                                           longTermPricing.running_costs
                                                               .filter(f => f.name === PREDEFINED_RUNNING_COSTS.water_costs)[0] : undefined}
                                                       isPredefined={true}
                                                       onChange={(cost) => {this.editRunningCost(PREDEFINED_RUNNING_COSTS.water_costs, cost)}}/>
                                            <FeeEditor name={PREDEFINED_RUNNING_COSTS.electricity_costs} ref={this.electricityCosts}
                                                       predefinedNames={PREDEFINED_RUNNING_COSTS} isFee={false}
                                                       data={longTermPricing && longTermPricing.running_costs ?
                                                           longTermPricing.running_costs
                                                               .filter(f => f.name === PREDEFINED_RUNNING_COSTS.electricity_costs)[0] : undefined}
                                                       isPredefined={true}
                                                       onChange={(cost) => {this.editRunningCost(PREDEFINED_RUNNING_COSTS.electricity_costs, cost)}}/>
                                            {
                                                longTermPricing && longTermPricing.running_costs &&
                                                longTermPricing.running_costs.filter(f => PREDEFINED_RUNNING_COSTS[f.name] === undefined)
                                                    .map((f, i) => {
                                                        return <FeeEditor name={f.name} data={f} isFee={false}
                                                                          predefinedNames={PREDEFINED_RUNNING_COSTS}
                                                                          ref={this.runningCostReferenceMap[f.name]}
                                                                          key={_uniqueId(f.name + "-" + i)}
                                                                          isPredefined={false}
                                                                          onChange={(cost) => {this.editRunningCost(f.name, cost)}} />
                                                    })
                                            }
                                            {
                                                (!longTermPricing || !longTermPricing.running_costs ||
                                                    longTermPricing.running_costs
                                                        .filter(f => !PREDEFINED_RUNNING_COSTS[f.name]).length < 5) &&
                                                <button className="outline-button black" onClick={() => {this.addRunningCost()}}>
                                                    <FontAwesomeIcon icon={["fal", "plus"]}/>
                                                    <span>{getLanguageEntry(LANG_PATH + "running_costs>add_running_cost")}</span>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <div className={"pricing-unit-container"}>
                                        <div className="roomjack-headline">
                                            <span>{getLanguageEntry(LANG_PATH + "additional_fees>header")}</span>
                                            <FontAwesomeIcon icon={["fal", "info-circle"]} title={getLanguageEntry(LANG_PATH + "running_costs>cost_description")}/>
                                        </div>
                                        <div className="pricing-modal-grid">
                                            <FeeEditor name={PREDEFINED_LONG_TERM_FEE_TYPES.end_cleaning} ref={this.endCleaning}
                                                       predefinedNames={PREDEFINED_LONG_TERM_FEE_TYPES} isFee={true}
                                                       data={longTermPricing && longTermPricing.additional_fees ?
                                                           longTermPricing.additional_fees
                                                               .filter(f => f.name === PREDEFINED_LONG_TERM_FEE_TYPES.end_cleaning)[0] : undefined}
                                                       isPredefined={true}
                                                       onChange={(fee) => {this.editLongTermFee(PREDEFINED_LONG_TERM_FEE_TYPES.end_cleaning, fee)}}/>
                                            {
                                                longTermPricing && longTermPricing.additional_fees &&
                                                longTermPricing.additional_fees.filter(f => PREDEFINED_LONG_TERM_FEE_TYPES[f.name] === undefined)
                                                    .map((f, i) => {
                                                        return <FeeEditor name={f.name} data={f} isFee={true}
                                                                          predefinedNames={PREDEFINED_LONG_TERM_FEE_TYPES}
                                                                          ref={this.longTermFeeReferenceMap[f.name]}
                                                                          key={_uniqueId(f.name + "-" + i)}
                                                                          isPredefined={false}
                                                                          onChange={(fee) => {this.editLongTermFee(f.name, fee)}} />
                                                    })
                                            }
                                            {
                                                (!longTermPricing || !longTermPricing.additional_fees ||
                                                    longTermPricing.additional_fees.length < 5) &&
                                                <button className="outline-button black" onClick={() => {this.addLongTermFee()}}>
                                                    <FontAwesomeIcon icon={["fal", "plus"]}/>
                                                    <span>{getLanguageEntry(LANG_PATH + "additional_fees>add_custom_fee")}</span>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <div className={"pricing-unit-container"}>
                                        <div className="roomjack-headline">
                                            <span>{getLanguageEntry(LANG_PATH + "discounts")}</span>
                                        </div>
                                        <table className="advert-editor-table">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <label className="description-container" htmlFor="modal-monthly-discount">
                                                        {getLanguageEntry(LANG_PATH + "monthly_discount")}
                                                    </label>
                                                </td>
                                                <td>
                                                    <UnitInputField type={UnitType.percent} id="modal-monthly-discount"
                                                                    types={[UnitType.percent, UnitType.currency]}
                                                                    onChange={(val) => {this.editMonthlyDiscount(val)}}
                                                                    initialValue={longTermPricing && longTermPricing.monthly_discount ?
                                                                        longTermPricing.monthly_discount : ''}/>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <form id="long-term-early" ref={this.longTermEarlyDiscount} >
                                            <table className="advert-editor-table">
                                                <tbody>
                                                <tr>
                                                    <td className="advert-editor-table-header">
                                                        {getLanguageEntry(LANG_PATH + "early_booker_discount")}
                                                        <FontAwesomeIcon icon={["fal", "info-circle"]}
                                                                         title={getLanguageEntry(LANG_PATH + "early_discount_week_label_info")}/>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label className="description-container" htmlFor="modal-lt-early-discount-week">
                                                            {getLanguageEntry(LANG_PATH + "early_discount_week_placeholder")}
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <UnitInputField type={UnitType.count} min={1}
                                                                        id="modal-lt-early-discount-week"
                                                                        onChange={(val) => {this.editLongTermEarlyWeeks(val)}}
                                                                        required={!!longTermPricing && !!longTermPricing.early_booker_discount &&
                                                                            !!longTermPricing.early_booker_discount.discount}
                                                                        initialValue={longTermPricing && longTermPricing.early_booker_discount ?
                                                                            longTermPricing.early_booker_discount.weeks : ''}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label className="description-container" htmlFor="modal-early-discount">
                                                            {getLanguageEntry(LANG_PATH + "early_discount_percent_label")}
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <UnitInputField type={UnitType.percent} id="modal-early-discount"
                                                                        types={[UnitType.percent, UnitType.currency]}
                                                                        required={!!longTermPricing && !!longTermPricing.early_booker_discount &&
                                                                            !!longTermPricing.early_booker_discount.weeks}
                                                                        onChange={(val) => {this.editLongTermEarlyDiscount(val)}}
                                                                        initialValue={longTermPricing && longTermPricing.early_booker_discount ?
                                                                            longTermPricing.early_booker_discount.discount : ''}/>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                    <div className={"pricing-unit-container"}>
                                        <div className="roomjack-headline">
                                            <span>{getLanguageEntry(LANG_PATH + "regulations")}</span>
                                        </div>
                                        <table className="advert-editor-table">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <label className="description-container">
                                                            {getLanguageEntry(LANG_PATH + "small_business")}
                                                            <FontAwesomeIcon icon={["fal", "info-circle"]}
                                                                             title={getLanguageEntry(LANG_PATH + "small_business_info")} />
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <CustomToggle rightValue={true} leftValue={false}
                                                                      onChange={(val) => {this.editLongTermSmallBusiness(val)}}
                                                                      defaultValue={longTermPricing && !!longTermPricing.small_business_regulation}
                                                                      name="small_business_regulation"/>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="pricing-example">
                            <div className="roomjack-headline">
                                {getLanguageEntry(LANG_PATH + "examples")}
                            </div>
                            {
                                exampleMissing &&
                                <div className="description-container">
                                    {getLanguageEntry(LANG_PATH + "example_data_needed")}
                                </div>
                            }
                            {
                                !exampleMissing && this.state.tab === 0 && dayBooking &&
                                <div className="example-container">
                                    <div className="roomjack-headline description-container">
                                        {getLanguageEntry(LANG_PATH + "example_for_day")}
                                    </div>
                                    <BookingInvoiceTable booking={dayBooking} detailed={true} show_win={true}
                                                         show_discount={false}/>
                                </div>
                            }
                            {
                                !exampleMissing && this.state.tab === 0 && weekBooking &&
                                <div className="example-container">
                                    <div className="roomjack-headline description-container">
                                        {getLanguageEntry(LANG_PATH + "example_for_week")}
                                    </div>
                                    <BookingInvoiceTable booking={weekBooking} detailed={true} show_win={true}
                                                         show_discount={false} />
                                </div>
                            }
                            {
                                !exampleMissing && monthBooking &&
                                <div className="example-container">
                                    <div className="roomjack-headline description-container">
                                        {getLanguageEntry(LANG_PATH + "example_for_month")}
                                    </div>
                                    <BookingInvoiceTable booking={monthBooking} detailed={true} show_win={true}
                                                         show_discount={false} />
                                </div>
                            }
                        </div>
                    </Modal.Body>
                }
                <Modal.Footer>
                    <button className="outline-button accent" onClick={() => {
                        let valid = true;
                        if (this.state.advert.min_booking_length > 30 || this.state.tab === 1) {
                            valid = this.validateLongTermSettings();
                        }
                        if (this.state.advert.max_booking_length < 1 || this.state.tab === 0) {
                            valid = this.validateShortTermSettings();
                        }
                        if (valid) {
                            this.state.on_save?.(this.state.housing);
                            this.hide();
                        }
                    }}>
                        {getLanguageEntry("general>done")}
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    show(advert, housing, onSave, title, proTool) {
        this.shortTermFeeReferenceMap = {};
        // create references for all custom fee
        if (housing && housing.pricing && housing.pricing.additional_fees) {
            for (const fee of housing.pricing.additional_fees.filter(f => !PREDEFINED_FEE_TYPES[f.name])) {
                this.shortTermFeeReferenceMap[fee.name] = React.createRef();
            }
        }
        // create references for all long term custom fee
        if (housing && housing.long_term_pricing && housing.long_term_pricing.additional_fees) {
            for (const fee of housing.long_term_pricing.additional_fees.filter(f => !PREDEFINED_LONG_TERM_FEE_TYPES[f.name])) {
                this.longTermFeeReferenceMap[fee.name] = React.createRef();
            }
        }
        // create references for all custom costs
        if (housing && housing.long_term_pricing && housing.long_term_pricing.running_costs) {
            for (const rc of housing.long_term_pricing.running_costs.filter(r => !PREDEFINED_RUNNING_COSTS[r.name])) {
                this.runningCostReferenceMap[rc.name] = React.createRef();
            }
        }
        this.setState({
            show: true,
            on_save: onSave,
            advert: advert,
            housing: housing,
            title: title ?? getLanguageEntry(LANG_PATH + "title"),
            tab: 0,
            pro_tool: proTool
        });
    }

    hide() {
        this.setState({
            show: false,
            on_save: null,
            advert: {},
            housing: {},
            title: getLanguageEntry(LANG_PATH + "title"),
            tab: 0,
            pro_tool: false
        });
    }

    setTab(index) {
        if (this.state.tab !== index) {
            if (this.state.tab === 0 && index === 1) {
                let valid = this.validateShortTermSettings();
                if (valid) {
                    this.setState({tab: index});
                }
            }
            if (this.state.tab === 1 && index === 0) {
                let valid = this.validateLongTermSettings();
                if (valid) {
                    this.setState({tab: index});
                }
                this.setState({tab: index});
            }
        }
    }

    //region short term pricing
    editDailyRent(rent) {
        let housing = JSON.parse(JSON.stringify(this.state.housing));
        if (!housing.pricing) {
            housing.pricing = {};
        }
        if (rent > 0) {
            housing.pricing.rent = rent;
            housing.bookable = true;
        }
        else {
            delete housing.pricing.rent;
            housing.bookable = false;
        }
        this.setState({housing});
    }

    editDailyRentTax(tax) {
        let housing = JSON.parse(JSON.stringify(this.state.housing));
        if (!housing.pricing) {
            housing.pricing = {};
        }
        if (tax) {
            housing.pricing.tax = tax;
        }
        else {
            delete housing.pricing.tax;
        }
        this.setState({housing});
    }

    addShortTermFee() {
        let name = `${getLanguageEntry(LANG_PATH + "additional_fees>custom_fee_name")}-${Date.now()}`;
        let housing = {...this.state.housing};
        if (!housing.pricing) {
            housing.pricing = {};
        }
        if (!housing.pricing.additional_fees) {
            housing.pricing.additional_fees = [];
        }
        housing.pricing.additional_fees.push({ name });
        this.shortTermFeeReferenceMap[name] = React.createRef();
        this.setState({housing});
    }

    editShortTermFee(name, fee) {
        let housing = {...this.state.housing};
        if (!housing.pricing) {
            housing.pricing = {};
        }
        if (fee) {
            if (!housing.pricing.additional_fees) {
                housing.pricing.additional_fees = [];
            }
            let index = housing.pricing.additional_fees.findIndex(f => f.name === name);
            if (index > -1) {
                housing.pricing.additional_fees.splice(index, 1, fee);
            }
            else {
                housing.pricing.additional_fees.push(fee);
            }
        }
        else {
            delete this.shortTermFeeReferenceMap[name];
            let index = housing.pricing.additional_fees.findIndex(f => f.name === name);
            if (index > -1) {
                housing.pricing.additional_fees.splice(index, 1);
            }
        }
        this.setState({housing});
    }

    editWeeklyDiscount(discount) {
        let housing = JSON.parse(JSON.stringify(this.state.housing));
        if (!housing.pricing) {
            housing.pricing = {};
        }
        if (isNaN(discount.value)) {
            delete housing.pricing.weekly_discount;
        }
        else {
            housing.pricing.weekly_discount = discount;
        }
        this.setState({housing});
    }

    editShortTermEarlyWeeks(weeks) {
        let housing = JSON.parse(JSON.stringify(this.state.housing));
        if (!housing.pricing) {
            housing.pricing = {};
        }
        if (isNaN(weeks)) {
            if (housing.pricing.early_booker_discount) {
                delete housing.pricing.early_booker_discount.weeks;
            }
        }
        else {
            if (!housing.pricing.early_booker_discount) {
                housing.pricing.early_booker_discount = {};
            }
            housing.pricing.early_booker_discount.weeks = weeks;
        }
        this.setState({housing});
    }

    editShortTermEarlyDiscount(discount) {
        let housing = JSON.parse(JSON.stringify(this.state.housing));
        if (!housing.pricing) {
            housing.pricing = {};
        }
        if (isNaN(discount.value)) {
            if (housing.pricing.early_booker_discount) {
                delete housing.pricing.early_booker_discount.discount;
            }
        }
        else {
            if (!housing.pricing.early_booker_discount) {
                housing.pricing.early_booker_discount = {};
            }
            housing.pricing.early_booker_discount.discount = discount;
        }
        console.log(housing.pricing.early_booker_discount);
        this.setState({housing});
    }

    editShortTermSmallBusiness(val) {
        let housing = JSON.parse(JSON.stringify(this.state.housing));
        if (!housing.pricing) {
            housing.pricing = {};
        }
        housing.pricing.small_business_regulation = val;
        this.setState({housing});
    }

    validateShortTermSettings() {
        let rentValid = this.dailyRent.current.checkValidity();
        if (!rentValid) {
            this.dailyRent.current.reportValidity();
        }
        const cleaningFeeValid = this.cleaningFee.current.isValid();
        const serviceFeeValid = this.serviceFee.current.isValid();
        let allFeesValid = true;
        for (const fee of Object.values(this.shortTermFeeReferenceMap)) {
            allFeesValid &= fee.current.isValid();
        }
        const earlyValid = this.shortTermEarlyDiscount.current.checkValidity();
        if (!earlyValid) {
            this.shortTermEarlyDiscount.current.reportValidity();
        }
        return rentValid && cleaningFeeValid && serviceFeeValid && allFeesValid && earlyValid;
    }
    //endregion

    //region long term pricing
    editMonthlyRent(rent) {
        let housing = JSON.parse(JSON.stringify(this.state.housing));
        if (!housing.long_term_pricing) {
            housing.long_term_pricing = {
                long_term: true,
            };
        }
        if (rent > 0) {
            housing.long_term_pricing.rent = rent;
            housing.bookable = true;
        }
        else {
            delete housing.long_term_pricing.rent;
            housing.bookable = false;
        }
        this.setState({housing});
    }

    editMonthlyRentTax(tax) {
        let housing = JSON.parse(JSON.stringify(this.state.housing));
        if (!housing.long_term_pricing) {
            housing.long_term_pricing = {
                long_term: true,
            };
        }
        if (tax) {
            housing.long_term_pricing.tax = tax;
        }
        else {
            delete housing.long_term_pricing.tax;
        }
        this.setState({housing});
    }

    addRunningCost() {
        let name = `${getLanguageEntry(LANG_PATH + "additional_fees>custom_cost_name")}-${Date.now()}`;
        let housing = {...this.state.housing};
        if (!housing.long_term_pricing) {
            housing.long_term_pricing = {
                long_term: true,
            };
        }
        if (!housing.long_term_pricing.running_costs) {
            housing.pricing.running_costs = [];
        }
        housing.long_term_pricing.running_costs.push({ name });
        this.runningCostReferenceMap[name] = React.createRef();
        this.setState({housing});
    }

    editRunningCost(name, cost) {
        let housing = {...this.state.housing};
        if (!housing.long_term_pricing) {
            housing.long_term_pricing = {
                long_term: true,
            };
        }
        if (cost) {
            if (!housing.long_term_pricing.running_costs) {
                housing.pricing.running_costs = [];
            }
            let index = housing.long_term_pricing.running_costs.findIndex(f => f.name === name);
            if (index > -1) {
                housing.long_term_pricing.running_costs.splice(index, 1, cost);
            }
            else {
                housing.long_term_pricing.running_costs.push(cost);
            }
        }
        else {
            delete this.runningCostReferenceMap[name];
            let index = housing.long_term_pricing.running_costs.findIndex(f => f.name === name);
            if (index > -1) {
                housing.long_term_pricing.running_costs.splice(index, 1);
            }
        }
        this.setState({housing});
    }

    addLongTermFee() {
        let name = `${getLanguageEntry(LANG_PATH + "additional_fees>custom_fee_name")}-${Date.now()}`;
        let housing = {...this.state.housing};
        if (!housing.long_term_pricing) {
            housing.long_term_pricing = {
                long_term: true,
            };
        }
        if (!housing.long_term_pricing.additional_fees) {
            housing.long_term_pricing.additional_fees = [];
        }
        housing.long_term_pricing.additional_fees.push({ name });
        this.longTermFeeReferenceMap[name] = React.createRef();
        this.setState({housing});
    }

    editLongTermFee(name, fee) {
        let housing = {...this.state.housing};
        if (!housing.long_term_pricing) {
            housing.long_term_pricing = {
                long_term: true,
            };
        }
        if (fee) {
            if (!housing.long_term_pricing.additional_fees) {
                housing.long_term_pricing.additional_fees = [];
            }
            let index = housing.long_term_pricing.additional_fees.findIndex(f => f.name === name);
            if (index > -1) {
                housing.long_term_pricing.additional_fees.splice(index, 1, fee);
            }
            else {
                housing.long_term_pricing.additional_fees.push(fee);
            }
        }
        else {
            delete this.longTermFeeReferenceMap[name];
            if (housing.long_term_pricing.additional_fees) {
                let index = housing.long_term_pricing.additional_fees.findIndex(f => f.name === name);
                if (index > -1) {
                    housing.long_term_pricing.additional_fees.splice(index, 1);
                }
            }
        }
        this.setState({housing});
    }

    editMonthlyDiscount(discount) {
        let housing = JSON.parse(JSON.stringify(this.state.housing));
        if (!housing.long_term_pricing) {
            housing.long_term_pricing = {
                long_term: true,
            };
        }
        if (isNaN(discount.value)) {
            delete housing.long_term_pricing.monthly_discount;
        }
        else {
            housing.long_term_pricing.monthly_discount = discount;
        }
        this.setState({housing});
    }

    editLongTermEarlyWeeks(weeks) {
        let housing = JSON.parse(JSON.stringify(this.state.housing));
        if (!housing.long_term_pricing) {
            housing.long_term_pricing = {
                long_term: true,
            };
        }
        if (isNaN(weeks)) {
            if (housing.long_term_pricing.early_booker_discount) {
                delete housing.long_term_pricing.early_booker_discount.weeks;
            }
        }
        else {
            if (!housing.long_term_pricing.early_booker_discount) {
                housing.long_term_pricing.early_booker_discount = {};
            }
            housing.long_term_pricing.early_booker_discount.weeks = weeks;
        }
        this.setState({housing});
    }

    editLongTermEarlyDiscount(discount) {
        let housing = JSON.parse(JSON.stringify(this.state.housing));
        if (!housing.long_term_pricing) {
            housing.long_term_pricing = {
                long_term: true,
            };
        }
        if (isNaN(discount.value)) {
            if (housing.long_term_pricing.early_booker_discount) {
                delete housing.long_term_pricing.early_booker_discount.discount;
            }
        }
        else {
            if (!housing.long_term_pricing.early_booker_discount) {
                housing.long_term_pricing.early_booker_discount = {};
            }
            housing.long_term_pricing.early_booker_discount.discount = discount;
        }
        this.setState({housing});
    }

    editLongTermSmallBusiness(val) {
        let housing = JSON.parse(JSON.stringify(this.state.housing));
        if (!housing.long_term_pricing) {
            housing.long_term_pricing = {
                long_term: true,
            };
        }
        housing.long_term_pricing.small_business_regulation = val;
        this.setState({housing});
    }

    validateLongTermSettings() {
        let rentValid = this.monthlyRent.current.checkValidity();
        if (!rentValid) {
            this.monthlyRent.current.reportValidity();
        }
        const heatingCostsValid = this.heatingCosts.current.isValid();
        const waterCostsValid = this.waterCosts.current.isValid();
        const electricityCostsValid = this.electricityCosts.current.isValid();
        const endCleaningValid = this.endCleaning.current.isValid();
        let allCostsValid = true;
        for (const cost of Object.values(this.runningCostReferenceMap)) {
            allCostsValid &= cost.current.isValid();
        }
        let allFeesValid = true;
        for (const fee of Object.values(this.longTermFeeReferenceMap)) {
            allFeesValid &= fee.current.isValid();
        }
        const earlyValid = this.longTermEarlyDiscount.current.checkValidity();
        if (!earlyValid) {
            this.longTermEarlyDiscount.current.reportValidity();
        }
        return rentValid && heatingCostsValid && waterCostsValid && electricityCostsValid &&
            endCleaningValid && allCostsValid && allFeesValid && earlyValid;
    }
    //endregion
}
export default PricingModal;