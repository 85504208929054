import React from "react";
import {Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getLanguageEntry} from "../../utils/Helper";
import PropTypes from "prop-types";
import BookingDetailTable from "../booking/BookingDetailTable";
import BookingInvoiceTable from "../booking/BookingInvoiceTable";
import {acceptInquiry, declineInquiry} from "../../utils/RESTInterface";
import FetchButton from "../buttons/FetchButton";

class InquiryEditModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            inquiry: null,
            accepting_inquiry: false,
            declining_inquiry: false,
            response: null
        }
    }

    render() {
        return (
            <Modal className="inquiry-edit-modal" show={this.state.show} size="lg" backdrop="static" keyboard={false}
                   onHide={() => { this.hide() }} dialogClassName="max-content" centered>
                <Modal.Header>
                    <h4>{getLanguageEntry("processes>inquiry_edit_modal>details_of_inquiry")}</h4>
                    <button onClick={() => { this.hide() }}
                            disabled={this.state.accepting_inquiry || this.state.declining_inquiry}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>

                <Modal.Body>
                    {
                        this.state.inquiry && !this.state.response &&
                        <div className="container-fluid" style={{display: "grid", gap: "20px"}}>
                            <BookingDetailTable booking={this.state.inquiry} userData={this.props.userData} />
                            <BookingInvoiceTable booking={this.state.inquiry} detailed={true} show_discount={false}/>
                        </div>
                    }
                    {
                        this.state.inquiry && this.state.response &&
                        <div className="process-modal-result-container">
                            <div className="process-result-image">
                                <img src={this.state.response.success ?
                                    "https://roomjack.blob.core.windows.net/roomjack/animations/highfive.gif" :
                                    "https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear.png"}
                                     alt={"Process" + (this.state.response.success ? " success" : " failed")} />
                            </div>
                            <div className="process-result-message-box">
                                <div className="header">
                                    {
                                        !this.state.response.success &&
                                        getLanguageEntry("processes>inquiry_edit_modal>process_failed")
                                    }
                                    {
                                        this.state.response.success &&
                                        getLanguageEntry(`processes>inquiry_edit_modal>${this.state.response.process_type}_success`)
                                    }
                                </div>
                                {
                                    this.getErrorMessage()
                                }
                            </div>
                        </div>
                    }
                </Modal.Body>
                {
                    this.state.inquiry && !this.state.response &&
                    <Modal.Footer>
                        <FetchButton className="outline-button decline" style={{width: "max-content"}}
                                     onClick={() => {this.declineInquiry()}} loading={this.state.declining_inquiry}
                                     loadingText={getLanguageEntry("processes>inquiry_edit_modal>declining_inquiry")}>
                            <FontAwesomeIcon icon={["fal", "times"]}/>
                            <span>{getLanguageEntry("processes>inquiry_edit_modal>decline_inquiry")}</span>
                        </FetchButton>
                        <FetchButton className="outline-button accept" style={{width: "max-content", marginLeft: "auto"}}
                                     onClick={() => {this.acceptInquiry()}} loading={this.state.accepting_inquiry}
                                     loadingText={getLanguageEntry("processes>inquiry_edit_modal>accepting_inquiry")}>
                            <FontAwesomeIcon icon={["fal", "check"]}/>
                            <span>{getLanguageEntry("processes>inquiry_edit_modal>accept_inquiry")}</span>
                        </FetchButton>
                    </Modal.Footer>
                }
            </Modal>
        )
    }

    show(inquiry) {
        this.setState({
            show: true,
            inquiry: inquiry,
            accepting_inquiry: false,
            declining_inquiry: false,
            response: null
        })
    }

    hide() {
        this.setState({
            show: false,
            inquiry: null,
            response: null,
        })
    }

    acceptInquiry() {
        this.setState({accepting_inquiry: true});
        let that = this;
        acceptInquiry(this.state.inquiry.id, this.props.lastMsgID, this.props.userData.id, (response) => {
            if (response.success) {
                response.process_type = 'accept';
            }
            that.setState({
                accepting_inquiry: false,
                response: response
            });
            if (response.success) {
                that.props.onUpdateConversation();
            }
        })
    }

    declineInquiry() {
        this.setState({declining_inquiry: true});
        let that = this;
        declineInquiry(this.state.inquiry.id, this.props.lastMsgID, this.props.userData.id, (response) => {
            if (response.success) {
                response.process_type = 'decline';
            }
            that.setState({
                declining_inquiry: false,
                response: response
            });
            if (response.success) {
                that.props.onUpdateConversation();
            }
        })
    }
    
    getErrorMessage() {
        if (!this.state.response || !this.state.response.errorCode) {
            return null;
        }
        if (this.state.response.errorCode.startsWith('ad_availability_err')) {
            return <div className="description-container">
                {getLanguageEntry("error_codes>" + this.state.response.errorCode)}
            </div>
        }
        else {
            return <div className="description-container">
                {getLanguageEntry("general>please_contact_support")}
                <button className='link-button' onClick={() => { this.props.onContactSupport() }}>
                    {getLanguageEntry("general>support")}
                </button>
            </div>
        }
    }

}
InquiryEditModal.propTypes = {
    userData: PropTypes.any.isRequired,
    onUpdateConversation: PropTypes.func.isRequired,
    lastMsgID: PropTypes.string,
    onContactSupport: PropTypes.func.isRequired
}
export default InquiryEditModal;