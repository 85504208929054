import React from "react";
import PropTypes from "prop-types";
import {Form, Modal} from "react-bootstrap";
import {
    activateBreakLines,
    buildTextLinkContainer,
    getLanguageEntry,
    MAX_MESSAGE_LENGTH
} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FetchButton from "../buttons/FetchButton";
import {deactivateAccount, startAccountStatusChange} from "../../utils/RESTInterface";
import TextArea from "../input/TextArea";
import md5 from "md5";

class AccountStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            mode: '',
            response: null,
            misc_reason: false,
            requesting_code: false,
            security_code_valid: false,
            process_failed: false
        }
        this.reason_form = React.createRef();
    }

    render() {
        return (
            <Modal id={this.props.id} className={this.props.className} show={this.state.show} backdrop="static"
                   keyboard={false} onHide={() => { this.hide() }} dialogClassName="max-content" size="lg" centered>
                <Modal.Header>
                    <h4>{getLanguageEntry(getLanguageEntry("profile_menu>account_settings>" + this.state.mode + "_account_header"))}</h4>
                    <button onClick={() => { this.hide() }}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>

                <Modal.Body>
                    <div id="account-status-change-container">
                        <div id="account-status-change-description-container">
                            <img src="https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear_honey.png"
                                 alt="sad-bear" />
                                <div id="account-status-change-description">
                                    <div id="account-status-change-description-header">
                                        {this.getDescriptionHeader()}
                                    </div>
                                    <div id="account-status-change-description-content">
                                        {this.getDescriptionContent()}
                                    </div>
                                </div>
                        </div>
                        {
                            this.state.response !== null && !this.state.process_failed &&
                            this.state.response.errorCode === undefined &&
                            <div id="account-status-change-reason-container">
                                <form ref={this.reason_form} onSubmit={(e) => { this.changeAccountStatus(e) }}>
                                    {
                                        Object.entries(getLanguageEntry("profile_menu>change_account_status>" + this.state.mode + "_reasons"))
                                            .map(([k,v], i) => {
                                                return <div className="form-group" key={k}>
                                                    <input type="radio" id={k} name="reason" value={k}
                                                           onClick={() => { this.setState({misc_reason: k === "misc_reason"})}} required/>
                                                    <label htmlFor={k}>{v}</label>
                                                </div>
                                            })
                                    }
                                    {
                                        this.state.misc_reason &&
                                        <div className="mt-2">
                                            <TextArea cols={30} rows={5} maxLength={MAX_MESSAGE_LENGTH} name="misc_reason"
                                                      label="profile_menu>change_account_status>misc_reason_description"
                                                      required={true}/>
                                        </div>
                                    }
                                    <div className="form-group mt-4" id="security-code-group">
                                        <label htmlFor="security-code" className="description-container">
                                            {getLanguageEntry("general>security_code")}
                                        </label>
                                        <Form.Control id="security-code" name="security_code"
                                            placeholder={getLanguageEntry("general>security_code_placeholder")}
                                            required onKeyUp={(e) => {this.proofSecurityCode(e)}}/>
                                    </div>
                                    {
                                        this.state.security_code_valid &&
                                        <FetchButton loading={this.state.loading} className="outline-button accent mt-4"
                                                     loadingText={"profile_menu>change_account_status>" + (this.state.mode === 'delete' ? "deleting" : "pausing") + "_account"}
                                                     type="submit">
                                            <FontAwesomeIcon icon={["fal", (this.state.mode === "delete" ? "trash-alt" : "pause-circle")]}/>
                                            <span>{getLanguageEntry("profile_menu>change_account_status>" + this.state.mode + "_button")}</span>
                                        </FetchButton>
                                    }
                                </form>
                            </div>
                        }
                        {
                            this.state.response !== null && this.state.response.errorCode !== undefined &&
                            buildTextLinkContainer("description-container mb-2 mt-2",
                                "error_codes>" + this.state.response.errorCode,
                                [{
                                    text_path: "footer>support_column>faq_help",
                                    url: "/faq.html",
                                    rel: "noreferrer nofollow",
                                    target: "_blank"
                                }]
                            )
                        }
                        {
                            this.state.response === null &&
                            <FetchButton className="outline-button accent"
                                         onClick={() => { this.requestCode() }} loading={this.state.requesting_code}
                                         loadingText="general>requesting_code">
                                <FontAwesomeIcon icon={["fal", "shield-keyhole"]}/>
                                <span>
                                    {getLanguageEntry("general>request_security_code")}
                                </span>
                            </FetchButton>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    reset() {
        this.setState({
            show: false,
            mode: '',
            response: null,
            misc_reason: false,
            requesting_code: false,
            security_code_valid: false,
            process_failed: false
        });
    }

    show(mode) {
        this.setState({
            show: true,
            mode: mode
        })
    }

    hide() {
        this.setState({ show: false}, () => { this.reset(); });
    }

    getDescriptionHeader() {
        return getLanguageEntry("profile_menu>change_account_status>" + this.state.mode + "_description_header");
    }

    getDescriptionContent() {
        let path = "profile_menu>change_account_status>" + this.state.mode + "_description_content";
        if (this.props.userData.adverts !== undefined && this.props.userData.adverts.length > 0) {
            path += "_adverts";
        }
        return activateBreakLines(getLanguageEntry(path));
    }

    requestCode() {
        let that = this;
        this.setState({requesting_code: true});
        startAccountStatusChange(this.state.mode, function(response) {
            that.setState( {
                requesting_code: false,
                response: response
            });
        });
    }

    changeAccountStatus(e) {
        e.preventDefault();
        let that = this;
        let statusForm = new FormData(this.reason_form.current);
        let reason = statusForm.get("reason");
        if (reason === "misc_reason") {
            reason = statusForm.get("misc_reason");
        }
        let code = md5(statusForm.get("security_code"));
        this.setState({loading: true});
        deactivateAccount(this.state.mode, reason, code, function(response) {
            if (response.errorCode !== undefined) {
                that.setState({
                    response: response,
                    loading: false,
                    process_failed: true
                })
            }
            else {
                that.setState({show: false});
                that.props.onStatusChangeSuccess?.(response);
            }
        });
    }

    proofSecurityCode(e) {
        e.preventDefault();
        let statusForm = new FormData(this.reason_form.current);
        let code = md5(statusForm.get("security_code"));
        this.setState({security_code_valid: code === this.state.response.confirmation_code});
    }

}
AccountStatus.propTypes = {
    onStatusChangeSuccess: PropTypes.func.isRequired,
    userData: PropTypes.object,
    mode: PropTypes.string.isRequired
}
AccountStatus.defaultProps = {}
export default AccountStatus;