import React from 'react';
import './css/main.css';
import "./css/components/printables.css";
import './css/pages/content/content-pages.css';
import './css/components/input/append-input-fields.css';
import './css/pages/landing/landing-pages.css';
import { DeviceUUID } from 'device-uuid';
import {Link, Route, Routes, useNavigate} from "react-router-dom";
import {
  DEVICE_ID, LANGUAGE, SESSION_ID, WHITE_LOGO, COLOR_LOGO,
  extractPredefinedProperties, extractSessionID, getLanguageEntry, unpackNotificationData, unpackUser,
  getPageType, setRoomJackReviewModalTriggered, LATEST_APP_VERSION, resetScroll, setCountry
} from "./js/utils/Helper";

import MainLandingPage from "./js/pages/landing/MainLandingPage";
import LandlordLandingPage from "./js/pages/landing/LandlordLandingPage";

import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {far} from "@fortawesome/pro-regular-svg-icons";
import {fas} from "@fortawesome/pro-solid-svg-icons";
import {fad} from "@fortawesome/pro-duotone-svg-icons";
import {fal} from "@fortawesome/pro-light-svg-icons";
import {fat} from "@fortawesome/pro-thin-svg-icons";
import {fab} from "@fortawesome/free-brands-svg-icons";
import {loginPublic, logout, makeAxiosCall, RequestType} from "./js/utils/RESTInterface";
import Login from "./js/components/modals/Login";
import NavigatorButton from "./js/components/buttons/NavigatorButton";
import MainMenu from "./js/components/MainMenu";
import Recovery from "./js/components/modals/Recovery";
import Registration from "./js/components/modals/Registration";
import GreenContentPage from "./js/pages/content/GreenContentPage";
import NotificationCenter from "./js/components/NotificationCenter";
import Avatar from "./js/components/Avatar";
import ProfilePage from "./js/pages/profile/ProfilePage";
import ProtectedRoutes from "./js/utils/ProtectedRoutes";
import SearchPage from "./js/pages/SearchPage";
import Expose from "./js/pages/Expose";
import ReportModal from "./js/components/modals/ReportModal";
import UserProfile from "./js/pages/UserProfile";
import PageNotFound from "./js/pages/PageNotFound";
import RentResponsibleContentPage from "./js/pages/content/RentResponsibleContentPage";
import B2BLandingPage from "./js/pages/content/B2BLandingPage";
import JackBoard from "./js/pages/jackboard/JackBoard";
import LegalNoticeContentPage from "./js/pages/content/LegalNoticeContentPage";
import PartnersContentPage from "./js/pages/content/PartnersContentPage";
import HelpPage from "./js/pages/HelpPage";
import {HELP_PAGES} from "./resources/contents/ContentPageContent";
import _uniqueId from "lodash/uniqueId";
import { Chart as ChartJS, ArcElement, BarElement,
  Tooltip, Legend, CategoryScale, LinearScale } from "chart.js";
import {resetCache} from "./js/utils/CacheHandler.ts";
import {AccountType, Mode, Page, ReportReason} from "./js/utils/Types.ts";
import ComboReviewModal from "./js/components/modals/ComboReviewModal";
import ModeSwitchAnimation from "./js/components/ModeSwitchAnimation";
import {Modal} from "react-bootstrap";
import packageJson from '../package.json';
import {HeidenheimContentPage, HorbContentPage} from "./js/pages/content/NewsContentPages";

library.add(fal,far,fas,fad,fat,fab);

ChartJS.register(
    BarElement, ArcElement,
    Tooltip, Legend, CategoryScale, LinearScale);

function addNavigation(Component) {
  return props => <Component navHook={useNavigate()} />
}

class App extends React.Component {

  constructor(props) {
    super(props);
    makeAxiosCall("https://api.country.is", RequestType.GET, null, null, (response) => {
      if (response && response.country) {
        setCountry(response.country.toLowerCase());
      }
    });
    let deviceID = localStorage.getItem(DEVICE_ID);
    if (!deviceID) {
      let du = new DeviceUUID().parse();
      let dua = [
        du.language,
        du.platform,
        du.os,
        du.cpuCores,
        du.isAuthoritative,
        du.silkAccelerated,
        du.isKindleFire,
        du.isDesktop,
        du.isMobile,
        du.isTablet,
        du.isWindows,
        du.isLinux,
        du.isLinux64,
        du.isMac,
        du.isiPad,
        du.isiPhone,
        du.isiPod,
        du.isSmartTV,
        du.pixelDepth,
        du.isTouchScreen,
        '' + Date.now()
      ];
      deviceID = du.hashMD5(dua.join(':'));
      localStorage.setItem(DEVICE_ID, deviceID);
    }
    let browserSupportCheck = localStorage.getItem("browser_support_check");
    let showBrowserSupportAlert = false;
    if (browserSupportCheck !== "true") {
      if (navigator.userAgent.indexOf("Firefox") === -1 &&
          navigator.userAgent.indexOf("Edg") === -1 &&
          navigator.userAgent.indexOf("Chrome") === -1) {
        showBrowserSupportAlert = true;
      }
      localStorage.setItem("browser_support_check", "true");
    }
    let language = localStorage.getItem(LANGUAGE) !== null ? localStorage.getItem(LANGUAGE) : "en";

    let url = window.location.pathname;
    let initialMode = url === '/vermieten' ? Mode.landlord : Mode.renter;

    this.state = {
      user: null,
      session_id: localStorage.getItem(SESSION_ID),
      "device-id": deviceID,
      app_lang: language,
      mode: initialMode,
      show_notifications: false,
      show_browser_support_alert: showBrowserSupportAlert
    };

    this.loginResult = {
      loading: true,
      isAuthorized: false
    }

    this.login = React.createRef();
    this.recovery = React.createRef();
    this.registration = React.createRef();
    this.main_menu = React.createRef();
    this.avatar = React.createRef();
    this.report_modal = React.createRef();
    this.user_review_modal = React.createRef();

    // logic for update notes can be added here
    let cachedVersion = localStorage.getItem("version");
    if (packageJson.version !== cachedVersion) {
      localStorage.setItem("version", packageJson);
    }
  }

  componentDidMount() {
    let initialMode = window.location.pathname === '/vermieten' ? Mode.landlord : Mode.renter;
    loginPublic((response) => {
      this.executeLoginDataExtraction(response, initialMode, true);
    });
  }

  render() {
    return (
        <div>
          <div id="main-banner" className={this.determineBannerClass()}>
            <div>
              <Link to={this.state.mode === Mode.renter ? "/" : "/vermieten"}>
                <div id="main-banner-logo-container">
                  <img id="main-banner-logo" src={this.determineBannerLogo()} alt="RoomJack-Logo"/>
                </div>
              </Link>
              { this.state.user === null &&
                  <NavigatorButton id="main-banner-mode-switcher" className="outline-button"
                                   onBeforeClick={ () => {this.toggleMode(false)} }
                                   to={this.state.mode === Mode.renter ? '/vermieten' : '/'}>
                    {getLanguageEntry("main_banner>public_mode_toggle>" + this.state.mode)}
                  </NavigatorButton>
              }
              { this.state.user !== null &&
                  <div id="main-banner-toggle-container" className="toggle-container">
                    <NavigatorButton id="renter-mode" to={getPageType() === Page.main ? '/' : ''}
                                     className={"mode-toggle" + (this.state.mode === Mode.renter ? " selected" : "")}
                                     onBeforeClick={ () => { this.switchMode(Mode.renter)}}>
                      {getLanguageEntry("main_banner>mode_toggle>renter")}
                    </NavigatorButton>
                    <NavigatorButton id="landlord-mode" to={getPageType() === Page.main ? '/vermieten' : ''}
                                     className={"mode-toggle" + (this.state.mode === Mode.landlord ? " selected" : "")}
                                     onBeforeClick={ () => { this.switchMode(Mode.landlord)}}>
                      {getLanguageEntry("main_banner>mode_toggle>landlord")}
                    </NavigatorButton>
                    <div className={"mode-toggle-background " + this.state.mode}></div>
                  </div>
              }
              <div id="main-banner-control-container" className={this.state.user === null ? "public" : ""}>
                {
                    this.state.user !== null &&
                    <NotificationCenter userData={this.state.user} mode={this.state.mode}
                                        onUpdateUserData={(user, callback) => { this.updateUserData(user, callback) }}
                                        onCreateReview={(booking) => {this.callComboReviewModal(booking)}}/>
                }
                <div id="main-banner-avatar-container" tabIndex="0" onClick={() => { this.onAvatarClick() }}>
                  <div id="avatar-button" tabIndex="0">
                    {
                        this.state.user === null &&
                        <FontAwesomeIcon id="avatar-icon" icon={["fal", "user-circle"]} />
                    }
                    {
                        this.state.user !== null &&
                        <Avatar size={50} data={this.state.user.avatar} />
                    }
                    <MainMenu ref={this.main_menu} onLogout={() => { this.logout()} } mode={this.state.mode}
                              switchMode={() => { this.toggleMode(true) }}/>
                  </div>
                  <div id="welcome-text" tabIndex="0">
                      <span className="prefix">
                        {
                          this.state.user === null ?
                              getLanguageEntry("main_banner>login").toUpperCase() :
                              getLanguageEntry("main_banner>welcome")
                        }
                      </span>
                    {
                        this.state.user !== null &&
                        <span className="data"> {this.state.user.first_name} </span>
                    }
                    {
                        this.state.user !== null &&
                        <span className="suffix">!</span>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="main-body" className={this.getModeClass()}>
            <Routes>
              <Route exact path='/' element={<MainLandingPage />}/>} />
              <Route exact path='/vermieten' element={<LandlordLandingPage userData={this.state.user}
                                                                           onRegister={(accountType) => {
                                                                             this.showModal(this.registration, accountType);
                                                                           }} />} />
              <Route exact path='/search' element={<SearchPage userData={this.state.user} callLogin={() => { this.callLogin() }}
                                                               onUpdateUserData={(user, callback) => { this.updateUserData(user, callback) }} />} />
              <Route exact path={'/expose/*'} element={<Expose userData={this.state.user} callLogin={() => { this.callLogin() }}
                                                               mode={this.state.mode} onSwitchMode={(nextMode) => {
                                                                  console.log(nextMode);
                                                                  this.switchMode(nextMode)
                                                               }}
                                                               onUpdateUserData={(user, callback) => { this.updateUserData(user, callback) }}
                                                               {...this.props}/>}  />
              <Route exact path='/content/roomjack-is-green' element={<GreenContentPage />} />
              <Route exact path='/content/heidenheim' element={<HeidenheimContentPage />} />
              <Route exact path='/content/horb-am-neckar' element={<HorbContentPage />} />
              <Route exact path='/content/rent-responsible' element={<RentResponsibleContentPage />} />
              <Route exact path='/content/b2b' element={<B2BLandingPage userData={this.state.user}
                                                                        onRegister={() => {
                                                                          this.showModal(this.registration, AccountType.b2b);
                                                                        }} />} />
              <Route exact path='/legal-notice' element={<LegalNoticeContentPage />} />
              <Route exact path='/partners' element={<PartnersContentPage />} />
              <Route exact path={'/user/*'} element={<UserProfile mode={this.state.mode} navHook={this.props.navHook}
                                                                  userData={this.state.user}
                                                                  callLogin={() => { this.callLogin() }}
                                                                  onUpdateUserData={(user, callback) => { this.updateUserData(user, callback) }}/>} />
              <Route element={<ProtectedRoutes authObject={this.loginResult} redirection={this.state.mode === Mode.renter ? "/" : "/vermieten"}/>}>
                <Route exact path='/profile/*' element={<ProfilePage userData={this.state.user} mode={this.state.mode}
                                                               onUpdateUserSession={(user, callback) => { this.updateUserSession(user, callback) }}
                                                               onStatusChangeSuccess={(response => { this.logoutUser(response) })} />} />
                <Route exact path='/desktop/*' element={<JackBoard userData={this.state.user} mode={this.state.mode}
                                                                   navHook={this.props.navHook}
                                                                   onUpdateUserSession={(response, callback) => {this.updateUserSession(response, callback) }}
                                                                   onUpdateUserData={(user, callback) => { this.updateUserData(user, callback) }} />} />
              </Route>
              {
                HELP_PAGES.map((page, i) => {
                  return <Route exact path={page.url} key={_uniqueId(page.id)}
                                element={<HelpPage id={page.id} data={page.getData()} title={page.title}
                                                   image={page.image} categories={page.categories}/>}/>
                })
              }
              <Route exact path={'/404'} element={<PageNotFound onContact={() => {this.callReportModal()}} />}/>
              <Route exact path={'/*'} element={<PageNotFound onContact={() => {this.callReportModal()}} />}/>
            </Routes>
          </div>
          <div id="main-page-footer">
            <div className="container">
              <div id="main-page-footer-links">
                <div id="main-page-footer-logo-container">
                  <img src="https://roomjack.blob.core.windows.net/roomjack/email-images/roomjack_white.svg"
                       alt="roomjack Logo"/>
                </div>
                <div className="version-block"><FontAwesomeIcon icon={["fal", "copyright"]}/> Copyright {new Date().getFullYear()} roomjack v{packageJson.version}</div>
                <div className="landlord-block">
                  <div className="footer-column-header">{getLanguageEntry("footer>landlord_column>title")}</div>
                  <ul>
                    <li>
                      <NavigatorButton to="/vermieten" className="link-button"
                                       onBeforeClick={ () => {
                                         resetScroll();
                                         if (this.state.mode !== Mode.landlord) {
                                           this.switchMode(Mode.landlord);
                                         }
                                       }}>
                        {getLanguageEntry("footer>landlord_column>become_landlord")}
                      </NavigatorButton>
                    </li>
                    <li>
                      <Link to="/content/rent-responsible" className="link-button">
                        {getLanguageEntry("footer>landlord_column>rent_responsible")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/content/b2b" className="link-button">
                        {getLanguageEntry("footer>landlord_column>become_b2b")}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="legal-links">
                  <Link to="/agb.html" target="_blank" rel="nofollow noreferrer">{getLanguageEntry("footer>legal_links>terms_of_service")}</Link>
                  <Link to="/privacy-policy.html" target="_blank" rel="nofollow noreferrer">{getLanguageEntry("footer>legal_links>privacy_policy")}</Link>
                  <Link to="/legal-notice" target="_blank" rel="nofollow noreferrer">{getLanguageEntry("footer>legal_links>legal_notice")}</Link>
                </div>
                <div className="community-block">
                  <div className="footer-column-header">{getLanguageEntry("footer>community_column>title")}</div>
                  <ul>
                    <li>
                      <Link to="/tips-tricks" className="link-button">
                        {getLanguageEntry("footer>community_column>tips_tricks")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/partners" target="_blank">
                        {getLanguageEntry("footer>community_column>partner")}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="support-block">
                  <div className="footer-column-header">{getLanguageEntry("footer>support_column>title")}</div>
                  <ul>
                    <li>
                      <Link to="/faq-help" className="link-button">
                        {getLanguageEntry("footer>support_column>faq_help")}
                      </Link>
                    </li>
                    <li>
                      <button className="link-button" onClick={() => { this.callReportModal(null, ReportReason.bug_report)}} >
                        {getLanguageEntry("footer>support_column>report_bug")}
                      </button>
                    </li>
                    <li>
                      <button className="link-button" onClick={() => { this.callReportModal(null, ReportReason.contact)}}>
                        {getLanguageEntry("footer>support_column>contact")}
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="social-media-block">
                  <span id="footer-lang-country-currency"></span>
                  <div id="follow-us">
                    <span>{getLanguageEntry("footer>social_media>follow_us")}</span>
                    <a href="https://www.facebook.com/roomjack.at" rel="noreferrer" target="_blank"><i className="fab fa-facebook-f"></i></a>
                    <a href="https://instagram.com/roomjack.at?igshid=YmMyMTA2M2Y=" rel="noreferrer" target="_blank"><i className="fab fa-instagram"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Login id="main-login" className={this.getModeClass()} ref={this.login}
                 toRegistration={() => {this.showModal(this.registration)}}
                 toRecovery={() => { this.showModal(this.recovery) }}
                 onLoginSuccess={(response) => { this.executeLoginDataExtraction(response, this.state.mode) }} />
          <Recovery id="main-recovery" className={this.getModeClass()} ref={this.recovery}
                    toLogin={() => { this.showModal(this.login) }}
                    onRecover={(response) => { this.executeLoginDataExtraction(response, this.state.mode) }} />
          <Registration id="main-registration" className={this.getModeClass()} ref={this.registration}
                        onRegister={(response) => { this.executeLoginDataExtraction(response, this.state.mode) }}/>
          <ReportModal ref={this.report_modal} userData={this.state.user} />
          <ComboReviewModal ref={this.user_review_modal} userData={this.state.user} mode={this.state.mode}
                            onUpdateUserData={(user) => {this.updateUserData(user)}}/>
          <ModeSwitchAnimation mode={this.state.mode} user={!!this.state.user} />
          {
            this.state.version_update_available &&
              <Modal show={true} backdrop="static" keyboard={false} className="new-version-alert" centered>
                <Modal.Body>
                  <img src="https://roomjack.blob.core.windows.net/roomjack/content-images/faultier_party.png"
                       alt="Faultier verkündet neue Version"/>
                  <div className="roomjack-headline">
                    {getLanguageEntry("alerts>update_available>description")}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button className="accent-icon-button" onClick={() => {window.location.reload()}}>
                    <FontAwesomeIcon icon={["fal", "up"]}/>
                    <span>
                      {getLanguageEntry("alerts>update_available>button")}
                    </span>
                  </button>
                </Modal.Footer>
              </Modal>
          }
          {
            this.state.show_browser_support_alert &&
              <Modal show={true} className="browser-support-alert" onHide={() => {this.setState({show_browser_support_alert: false})}} centered>
                <Modal.Body>
                  <img src="https://roomjack.blob.core.windows.net/roomjack/email-images/think.png"
                       alt="Browser möglicherweise nicht vollständig unterstützt"/>
                  <div className="description-container">
                    {getLanguageEntry("alerts>browser_support>description")}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button className="accent-icon-button" onClick={() => {this.setState({show_browser_support_alert: false})}}>
                    <span>
                      {getLanguageEntry("alerts>browser_support>button")}
                    </span>
                  </button>
                </Modal.Footer>
              </Modal>
          }
        </div>
    );
  }

  toggleMode() {
    this.switchMode(this.state.mode === Mode.renter ? Mode.landlord : Mode.renter);
  }

  switchMode(mode) {
    if (mode !== this.state.mode) {
      this.setState({ mode: mode });
    }
  }

  determineBannerClass() {
    let bannerClass = this.getModeClass();
    let actualPage = getPageType();

    if (actualPage !== Page.main) {
      bannerClass += " sticky";
      if (actualPage !== Page.search && actualPage !== Page.expose &&
          actualPage !== Page.booking_checkout) {
        bannerClass += " lifted";
      }
    }
    return bannerClass;
  }

  getModeClass() {
    return this.state.mode === Mode.landlord ? Mode.landlord : '';
  }

  determineBannerLogo() {
    return getPageType() === Page.main ? WHITE_LOGO : COLOR_LOGO;
  }

  onAvatarClick() {
    if (this.state.user === null) {
      this.callLogin();
    }
    else if (this.main_menu.current != null) {
      this.main_menu.current.toggle();
    }
  }

  callLogin() {
    this.showModal(this.login);
  }

  showModal(modalRef, accountType=null) {
    if (modalRef.current !== null) {
      modalRef.current.show(accountType);
    }
  }

  executeLoginDataExtraction(response, initialMode, checkVersion=false) {
    if (initialMode === undefined) {
      initialMode = this.state.mode
    }
    extractPredefinedProperties(response);
    let state = extractSessionID(response);
    let nextMode = initialMode;
    if (response.user !== undefined) {
      state.user = unpackUser(response.user);
      if (response.notifications !== undefined) {
        unpackNotificationData(response.notifications);
        state.user.notification_data = response.notifications;
      }
      nextMode =
          state.user.preferred_menu_mode !== undefined && Mode[state.user.preferred_menu_mode] !== undefined ?
              state.user.preferred_menu_mode : initialMode;
      if (getPageType() === Page.main) {
        this.props.navHook(nextMode === Mode.renter ? '/' : '/desktop/dashboard')
      }
      setRoomJackReviewModalTriggered(!!response.user.roomjack_review);
    }
    state.mode = nextMode;
    localStorage.setItem(SESSION_ID, state.session_id);
    this.loginResult.isAuthorized = response.user !== undefined;
    this.loginResult.loading = false;
    if (checkVersion && packageJson.version !== LATEST_APP_VERSION) {
      state.version_update_available = true;
    }
    this.setState(state);
  }

  updateUserSession(response, callback) {
    let state = {};
    if (response.user !== undefined) {
      state.user = unpackUser(response.user);
      if (response.notifications !== undefined) {
        let notifications = { pending_reviews: response.notifications }
        unpackNotificationData(notifications);
        state.user.notification_data = notifications;
      }
      this.setState(state, () => {
        callback?.();
      });
    }
  }

  updateUserData(userData, callback) {
    this.setState({user: userData}, () => {
      callback?.();
    });
  }

  logout() {
    let that = this;
    logout(function(response) {
      if (response.errorCode !== undefined) {
         console.log(response.errorCode + ": " + response.message)
      }
      else {
        that.logoutUser(response);
      }
    });
  }

  logoutUser(response) {
    this.props.navHook('/');
    let state = { user: null, mode: Mode.renter }
    state = extractSessionID(response, state);
    localStorage.setItem(SESSION_ID, state.session_id);
    resetCache();
    this.setState(state);
  }

  callReportModal(targetID, reason) {
    this.report_modal.current.show(targetID, reason);
  }

  callComboReviewModal(booking) {
    if (this.user_review_modal.current) {
      this.user_review_modal.current.show(booking);
    }
  }


  //region server response handling


  // maybe not needed anymore
  /*setLoginSuccessCallback(callback, resetAfterExecution=true) {
    loginSuccessCallback = callback;
    resetLoginCallback = resetAfterExecution;
  }*/

  /*executeLoginSuccessCallback() {
    loginSuccessCallback?.();
    if (resetLoginCallback) {
      loginSuccessCallback = null;
    }
  }*/
  //endregion
}
export default addNavigation(App);
