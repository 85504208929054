import React from "react";
import PropTypes from "prop-types";
import {
    getDateClockTime,
    getLanguageEntry,
    prepareDisplayedMessage
} from "../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FetchButton from "./buttons/FetchButton";
import {downloadGuestInvoice} from "../utils/RESTInterface";

const LANG_PATH = "jackboard>messages>"

class MessageContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            message: props.message,
            downloading_invoice: false
        }
    }

    render() {
        let metaContent = this.state.message.booking || this.state.message.inquiry || this.state.message.invoice_data ?
            this.getMetaContent() : null;
        let containerClassList = this.getContainerClass();
        let editButtonType = "";
        if (!containerClassList.includes("own") &&
            this.state.message.inquiry &&
            this.state.message.inquiry.data &&
            !this.state.message.inquiry.data.accepted &&
            this.state.message.inquiry.data.owner === this.props.userData.id) {
            editButtonType = "inquiry";
        }

        return (
            <div className={containerClassList.join(" ")}>
                <div className={"message-background" + (this.props.isSelected ? " selected" : "") +
                    (metaContent && metaContent.onclick ? " interactive" : "")}
                     onClick={metaContent && metaContent.onclick ? () => { metaContent.onclick()} : null }>
                    <div className="message-name-label">
                        {
                            this.state.message.sender_id === this.props.userData.id ?
                                getLanguageEntry(LANG_PATH + "labels>you") : this.props.partner
                        }
                        {
                            this.state.message.inquiry &&
                            <span>
                                {
                                    getLanguageEntry(LANG_PATH + "message_footers>" +
                                        (this.props.userData.id === this.state.message.sender_id ?
                                            "outgoing" : "incoming") + "_inquiry")
                                }
                            </span>
                        }
                    </div>
                    {
                        metaContent && metaContent.metadata
                    }
                    {
                        this.state.message.message &&
                        <div className="message-text-container description-container">
                            {prepareDisplayedMessage(this.state.message.message, true).map((i, _) => i.object)}
                        </div>
                    }
                    {
                        this.state.message.image &&
                        <img className="image-container" src={this.state.message.image} alt=""/>
                    }
                    {
                        this.state.message.read !== undefined && !this.state.message.read &&
                        <div className="marker">
                            <FontAwesomeIcon icon={["fas", "asterisk"]} />
                        </div>
                    }
                    <div className="message-timestamp">
                        {getDateClockTime(this.state.message.timestamp)}
                    </div>
                </div>
                {
                    editButtonType.length > 0 &&
                    <button className="message-content-editor" onClick={() => {
                        this.props.onEditInquiry?.(this.state.message.inquiry.data);
                    }}>
                        <FontAwesomeIcon icon={["fas", "pencil"]} />
                        <span>{getLanguageEntry(LANG_PATH + "inquiry_advert_overview>edit_" + editButtonType)}</span>
                    </button>
                }
            </div>
        )
    }

    getContainerClass() {
        let containerClassList = ["message-container"];
        if (this.state.message.sender_id === this.props.userData.id) {
            containerClassList.push("own");
        }
        return containerClassList;
    }

    getMetaContent() {
        let m = this.state.message;
        let result = {};
        if (m.inquiry) {
            if (m.inquiry.data) {
                result.metadata =
                    <div className="message-metadata-container">
                        <FontAwesomeIcon icon={["fal", "info-circle"]}/>
                        {
                            !m.inquiry.accepted &&
                            getLanguageEntry(LANG_PATH + "message_footers>waiting_for_inquiry_edit")
                        }
                        {
                            m.inquiry.accepted &&
                            getLanguageEntry(LANG_PATH + "message_footers>inquiry_accepted")
                        }
                    </div>;
                result.onclick = () => {
                    this.props.onSelectMessage?.(m)
                };
            }
            else {
                result.metadata =
                    <div className="message-metadata-container">
                        <FontAwesomeIcon icon={["fal", "info-circle"]}/>
                        {getLanguageEntry(LANG_PATH + "message_footers>inquiry_" +
                            (m.inquiry.accepted === undefined ? "expired" : (m.inquiry.accepted ? "accepted" : "declined")))}
                    </div>;
            }
        }
        if (m.invoice_data) {
            if (m.invoice_data.charge_data) {
                result.metadata = <div className="message-metadata-container">
                    {getLanguageEntry(LANG_PATH + "message_footers>message_contains_invoice")}
                    <button className="outline-button accent" onClick={() => {this.printInvoice()}}>
                        <FontAwesomeIcon icon={["fal", "download"]}/>
                        <span>{getLanguageEntry(LANG_PATH + "message_footers>open_invoice")}</span>
                    </button>
                </div>;
            }
            else {
                result.metadata = <div className="message-metadata-container">
                    {getLanguageEntry(LANG_PATH + "message_footers>message_contains_invoice")}
                    <FetchButton className="outline-button accent" loading={this.state.downloading_invoice}
                                 loadingText={"general>downloading"} onClick={() => {this.downloadInvoiceData()}}>
                        <FontAwesomeIcon icon={["fal", "download"]}/>
                        <span>{getLanguageEntry(LANG_PATH + "message_footers>download_invoice")}</span>
                    </FetchButton>
                </div>;
            }
        }
        return result;
    }

    downloadInvoiceData() {
        this.setState({downloading_invoice: true});
        let that = this;
        let id = this.state.message.invoice_data;
        downloadGuestInvoice(id, this.props.conversationID, this.state.message.id, (response) => {
            let state = {
                downloading_invoice: false
            }
            if (response.invoice_data) {
                let message = this.state.message;
                message.invoice_data = response.invoice_data;
                message.invoice_data.invoice_data_id = id;
                state.message = message;
            }
            that.setState(state);
        });
    }

    printInvoice() {
        if (this.state.message.invoice_data.charge_data) {
            let chargeID = this.state.message.invoice_data.invoice_data_id.split('>')[1];
            let charge = this.state.message.invoice_data.charge_data.charges.filter(c => c.id === chargeID)[0];
            if (charge) {
                this.props.onPrintInvoice?.(this.state.message.invoice_data, charge);
            }
        }
    }

}

MessageContainer.propTypes = {
    conversationID: PropTypes.string.isRequired,
    message: PropTypes.any.isRequired,
    partner: PropTypes.string.isRequired,
    userData: PropTypes.any.isRequired,
    onSelectMessage: PropTypes.func.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onPrintInvoice: PropTypes.func.isRequired,
    onEditInquiry: PropTypes.func.isRequired,
}
export default MessageContainer;