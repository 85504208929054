//region advert statistics
import {
    calculateInvoiceFromObject, getDayFromBegin, getNightsBetweenDates,
    getTimeUnitInMS,
    isAdvertAvailable, isLeapYear,
    LeapYearDays, NormalYearDays,
    USER_REVIEW_CRITERIA_MAP
} from "./Helper";
import {PREDEFINED_FEE_TYPES, PREDEFINED_LONG_TERM_FEE_TYPES, PREDEFINED_RUNNING_COSTS} from "../../resources/housings";

export function getGeneralAdvertRatingAverage(reviews) {
    if (reviews === null || reviews === undefined || reviews.length === 0) {
        return 0;
    }
    let generalRating = 0;
    for (const review of reviews) {
        let sum = review.location_rating + review.equipment_rating + review.cleanliness_rating +
            review.price_performance_rating + review.info_accuracy_rating + review.check_in_rating +
            review.wifi_rating;
        generalRating += (sum / 7.0);
    }
    return generalRating / reviews.length;
}

export function getAdvertRatingTypeAverage(ratingType, reviews) {
    let ratingAverage = 0;
    if (reviews.length > 0) {
        for (const review of reviews) {
            ratingAverage += review[ratingType];
        }
        ratingAverage /= reviews.length;
    }
    return ratingAverage;
}

export function getRatingInformationOfAdverts(adverts, start=-1, end=-1,
                                              onlyCompleteAndPublished=true) {
    let generalRating = 0;
    let locationRating = 0;
    let equipmentRating = 0;
    let cleanlinessRating = 0;
    let pricePerformanceRating = 0;
    let infoAccuracyRating = 0;
    let checkInRating = 0;
    let wifiRating = 0;
    let distribution = [0, 0, 0, 0, 0];
    let advertWithReviews = 0;
    let reviews = [];
    for (const advert of adverts) {
        if (!isAdvertAvailable(advert, onlyCompleteAndPublished)) {
            continue;
        }
        let advertReviews = advert.reviews ? Object.values(advert.reviews) : [];
        let newReviews = advertReviews.filter(r =>
            (!start || start < 0 || r.timestamp >= start) && (!end || end < 0 || r.timestamp <= end));
        reviews = reviews.concat(newReviews);
        if (newReviews.length > 0) {
            advertWithReviews += 1;
            let average = getGeneralAdvertRatingAverage(newReviews);
            distribution[Math.floor(average) - 1] += 1;
            generalRating += average;
            locationRating += getAdvertRatingTypeAverage('location_rating', newReviews);
            equipmentRating += getAdvertRatingTypeAverage('equipment_rating', newReviews);
            cleanlinessRating += getAdvertRatingTypeAverage('cleanliness_rating', newReviews);
            pricePerformanceRating += getAdvertRatingTypeAverage('price_performance_rating', newReviews);
            infoAccuracyRating += getAdvertRatingTypeAverage('info_accuracy_rating', newReviews);
            checkInRating += getAdvertRatingTypeAverage('check_in_rating', newReviews);
            wifiRating += getAdvertRatingTypeAverage('wifi_rating', newReviews);
        }
    }
    if (advertWithReviews > 0) {
        generalRating /= advertWithReviews;
        locationRating /= advertWithReviews;
        equipmentRating /= advertWithReviews;
        cleanlinessRating /= advertWithReviews;
        pricePerformanceRating /= advertWithReviews;
        infoAccuracyRating /= advertWithReviews;
        checkInRating /= advertWithReviews;
        wifiRating /= advertWithReviews
    }
    return {
        rating_value: generalRating,
        rating_count: reviews.length,
        distribution: distribution,
        location_rating: locationRating,
        equipment_rating: equipmentRating,
        cleanliness_rating: cleanlinessRating,
        price_performance_rating: pricePerformanceRating,
        info_accuracy_rating: infoAccuracyRating,
        check_in_rating: checkInRating,
        wifi_rating: wifiRating,
        reviews: reviews};
}
//endregion

//region user statistics
export function getGeneralUserRatingAverage(reviews, start=-1, end=-1, mode=null) {
    if (reviews === null || reviews === undefined || reviews.length === 0) {
        return 0;
    }
    let generalRating = 0;
    reviews = reviews.filter(r =>
        (start < 0 || r.timestamp >= start) && (end < 0 || r.timestamp <= end));
    if (mode !== null) {
        reviews = reviews.filter(r => r.type === mode);
    }
    for (const review of reviews) {
        let ratingTypeCount = Object.keys(review.evaluation_criteria).length;
        let ratings = 0;
        for (const rating of Object.values(review.evaluation_criteria)) {
            ratings += rating;
        }
        generalRating += (ratings / ratingTypeCount);
    }
    return reviews.length === 0 ? 0 : generalRating / reviews.length;
}

export function getRatingInformationOfUser(reviews, start=-1, end=-1, mode) {
    reviews = reviews.filter(r => {
        return (start < 0 || r.timestamp >= start) && (end < 0 || r.timestamp <= end) &&
        (mode === undefined || mode === null || mode === r.type)
    });

    let result = {
        rating_value: reviews.length > 0 ? getGeneralUserRatingAverage(reviews) : 0,
        rating_count: reviews.length
    };

    let distribution = [0, 0, 0, 0, 0];
    let ratingTypes = Object.values(USER_REVIEW_CRITERIA_MAP.general);
    if (USER_REVIEW_CRITERIA_MAP[mode] !== undefined) {
        ratingTypes = ratingTypes.concat(Object.values(USER_REVIEW_CRITERIA_MAP[mode]));
    }
    for (const ratingType of ratingTypes) {
        if (result[ratingType] === undefined) {
            result[ratingType] = 0;
        }
        if (reviews.length > 0) {
            result[ratingType] += getUserRatingTypeAverage(ratingType, reviews);
        }
    }

    for (const review of reviews) {
        let average = getGeneralUserRatingAverage([review]);
        distribution[Math.floor(average) - 1] += 1;
    }

    result.distribution = distribution;
    result.reviews = reviews;
    return result;
}

function getUserRatingTypeAverage(ratingType, reviews) {
    let ratingAverage = 0;
    if (reviews.length > 0) {
        for (const review of reviews) {
            ratingAverage += review.evaluation_criteria[ratingType];
        }
        ratingAverage /= reviews.length;
    }
    return ratingAverage;
}
//endregion

//region booking statistics
export function getCompletedBookingsOfAdvertsInPeriod(bookings, adverts=null, start=null, end=null) {
    if (!bookings) {
        return [];
    }
    // consider only completed bookings
    return bookings.filter(b => b.payment_setup === true &&
        b.end < Date.now() && (!start || b.booking_ts >= start) && (!end || b.booking_ts <= end) &&
        (!adverts || adverts.includes(b.advert_id)));
}

export function getIncome(bookings, adverts=null, start=null, end=null) {
    let filteredBookings = getCompletedBookingsOfAdvertsInPeriod(bookings, adverts, start, end);
    let income = 0;
    for (const booking of filteredBookings) {
        for (const charge of booking.charge_data.charges) {
            if (charge.payout_amount !== undefined) {
                income += charge.payout_amount;
            }
        }
    }
    return income;
}

export function calculateOccupancyRate(adverts, bookings, start, end) {
    // if there are no adverts or no bookings return simply 0
    if (adverts === null || adverts === undefined || adverts.length === 0 ||
        bookings === null || bookings === undefined || bookings.length === 0) {
        return 0;
    }
    let actualDayEnd = start + getTimeUnitInMS(1);
    let occupancyArray = [];
    // add metadata to the adverts => calculate for each housing of each advert
    // how many bookable subunit it has
    for (const advert of adverts) {
        advert.bookable_subunits = 0;
        advert.subunits = 0;
        if (advert.rooms) {
            advert.bookable_subunits += advert.rooms.filter(r => r.bookable).length;
            advert.subunits += advert.rooms.length;
        }
    }
    const filterBookings = (bookingList, dayEnd) => {
        return bookingList.filter(b =>
            (b.start >= start && b.start < dayEnd) ||
            (b.end > start && b.end <= dayEnd) ||
            (b.start < start && b.end > dayEnd)
        );
    }
    // iterate through from the start the until the end by the day
    // and determine the occupancy rate for each day
    while (actualDayEnd <= end) {
        // filter the bookings which overlaps the actual day
        let overlappingBookings = filterBookings(bookings, actualDayEnd);
        if (overlappingBookings.length === 0) {
            occupancyArray.push(0);
        }
        else {
            let advertBookingMap = {};
            // iterate through all bookings
            for (const booking of overlappingBookings) {
                // get the target advert
                let advert;
                if (!advertBookingMap[booking.advert_id]) {
                    advert = adverts.filter(a =>
                        a.id === booking.advert_id
                    )[0];
                    advertBookingMap[booking.advert_id] = JSON.parse(JSON.stringify(advert));
                }
                advert = advertBookingMap[booking.advert_id];
                // now if the advert is in the map, it means there is some booking for it
                // count now all bookings for specific subunits
                if (booking.room_id) {
                    if (!advert.subunit_bookings) {
                        advert.subunit_bookings = 1;
                    }
                    else {
                        advert.subunit_bookings += 1;
                    }
                }
            }
            // we start at 0% and add to it for each advert the occupancy percent for that advert
            let overallPercent = 0;
            for (const advert of Object.values(advertBookingMap)) {
                let advertPercent = 100;
                if (advert.subunit_bookings !== undefined) {
                    advertPercent = Math.ceil((advert.subunit_bookings / advert.bookable_subunits) * 100);
                }
                overallPercent += advertPercent;
            }
            // finally we must divide by the number of adverts
            occupancyArray.push(overallPercent / adverts.length);
        }
        start = actualDayEnd;
        actualDayEnd += getTimeUnitInMS(1);
    }
    return occupancyArray.reduce((partialSum, a) => partialSum + a, 0) / occupancyArray.length;
}

export function determineOccupancyChartData(start, end, adverts, bookings) {
    let percent = Math.round(calculateOccupancyRate(adverts, bookings, start, end));
    let dataPoints = [ percent, 100 - percent ];
    let dataObject = {
        datasets: [{
            data: dataPoints,
            fill: true,
            backgroundColor: [
                '#CB8D37',
                '#BBBBBB'
            ],
            hoverOffset: 0
        }]
    };
    let options = {
        responsive: false,
        maintainAspectRatio: false,
        tooltips: { enabled: false },
        hover: { mode: null },
        events: [],
        cutout: '85%',
        borderRadius: 1000,
        animation: {
            duration: 2000
        }
    }
    return { data: dataObject, options: options, percent: percent };
}

export function collectClicksOfAdverts(adverts) {
    let clickList = [];
    for (const advert of adverts) {
        if (advert.clicks) {
            clickList.push(advert.clicks);
            if (advert.rooms) {
                for (const room of advert.rooms) {
                    if (room.bookable && room.clicks) {
                        clickList.push(room.clicks);
                    }
                }
            }
        }
    }
    return clickList;
}

export function calculateIncomeData(bookings, adverts = null, start=null, end=null) {
    let filteredBookings = getCompletedBookingsOfAdvertsInPeriod(bookings, adverts, start, end);
    let total = 0;
    let basisFee = 0;
    let rents = 0;
    let cleaningFee = 0;
    let serviceFee = 0;
    let endCleaning = 0;
    let miscFee = 0;
    let heatingCosts = 0;
    let waterCosts = 0;
    let electricityCosts = 0;
    let miscCosts = 0;
    let cancellationFees = 0;
    let incomeObject = {};
    for (const booking of filteredBookings) {
        let invoice = calculateInvoiceFromObject(booking);
        let baseFeeRatio = invoice.subtotal / invoice.total_with_discount;
        if (booking.invoice_data.pricing.long_term) {
            let costRatioMap = {};
            if (invoice.running_costs) {
                for (const costType of Object.keys(invoice.running_costs)) {
                    costRatioMap[costType] = invoice.running_costs[costType] / invoice.total_with_discount;
                }
            }
            if (booking.charge_data !== undefined && booking.charge_data.charges !== undefined) {
                for (const charge of booking.charge_data.charges.filter(c => c.payout_amount !== undefined)) {
                    if (charge.cancelled) {
                        cancellationFees += charge.payout_amount;
                        total += charge.payout_amount;
                    }
                    else {
                        total += charge.payout_amount;
                        rents += charge.payout_amount * baseFeeRatio;
                        for (const costType of Object.keys(costRatioMap)) {
                            switch (costType) {
                                case PREDEFINED_RUNNING_COSTS.heating_costs:
                                    heatingCosts += charge.payout_amount * costRatioMap[costType];
                                    break;
                                case PREDEFINED_RUNNING_COSTS.water_costs:
                                    waterCosts += charge.payout_amount * costRatioMap[costType];
                                    break;
                                case PREDEFINED_RUNNING_COSTS.electricity_costs:
                                    electricityCosts += charge.payout_amount * costRatioMap[costType];
                                    break;
                                default:
                                    miscCosts += charge.payout_amount * costRatioMap[costType];
                                    break;
                            }
                        }
                    }
                }
            }
        }
        else {
            let feeRatioMap = {};
            if (invoice.additional_fees) {
                for (const feeType of Object.keys(invoice.additional_fees)) {
                    feeRatioMap[feeType] = invoice.additional_fees[feeType] / invoice.total_with_discount;
                }
            }
            if (booking.charge_data !== undefined && booking.charge_data.charges !== undefined) {
                for (const charge of booking.charge_data.charges.filter(c => c.payout_amount !== undefined)) {
                    if (charge.cancelled) {
                        cancellationFees += charge.payout_amount;
                        total += charge.payout_amount;
                    }
                    else {
                        total += charge.payout_amount;
                        basisFee += charge.payout_amount * baseFeeRatio;
                        for (const feeType of Object.keys(feeRatioMap)) {
                            switch (feeType) {
                                case PREDEFINED_FEE_TYPES.cleaning_fee:
                                    cleaningFee += charge.payout_amount * feeRatioMap[feeType];
                                    break;
                                case PREDEFINED_FEE_TYPES.service_fee:
                                    serviceFee += charge.payout_amount * feeRatioMap[feeType];
                                    break;
                                case PREDEFINED_LONG_TERM_FEE_TYPES.end_cleaning:
                                    endCleaning += charge.payout_amount * feeRatioMap[feeType];
                                    break;
                                default:
                                    miscFee += charge.payout_amount * feeRatioMap[feeType];
                                    break;
                            }
                        }
                    }
                }
            }
        }
    }
    incomeObject.base_fee = basisFee;
    incomeObject.rents = rents;
    incomeObject.cleaning_fee = cleaningFee;
    incomeObject.service_fee = serviceFee;
    incomeObject.misc_fee = miscFee;
    incomeObject.total = total;
    incomeObject.heating_costs = heatingCosts;
    incomeObject.water_costs = waterCosts;
    incomeObject.electricity_costs = electricityCosts;
    incomeObject.misc_costs = miscCosts;
    incomeObject.cancellation_fees = cancellationFees;
    incomeObject.end_cleaning = endCleaning;
    return incomeObject;
}

export function calculateLengthOfStayData(bookings, adverts = null, start=null, end=null) {
    let filteredBookings = getCompletedBookingsOfAdvertsInPeriod(bookings, adverts, start, end);
    filteredBookings.sort((a, b) => { return a.booking_ts - b.booking_ts; });
    let data = { min_length: Number.MAX_VALUE, max_length: 0, dataPoints: {} };
    let startDate = new Date(start);
    let endDate = new Date(end);
    while (startDate.getTime() < endDate.getTime()) {
        if (startDate.getMonth() === 0) {
            let monthLength = isLeapYear(startDate.getFullYear()) ? LeapYearDays[1] : NormalYearDays[1];
            data.dataPoints[(startDate.getMonth() % 12 + 1)  + '.' + startDate.getFullYear()] = {
                min_length: Number.MAX_VALUE, max_length: 0, average_length: 0, bookings: 0
            };
            startDate.setDate(startDate.getDate() + monthLength);
            let daysLeft = 30 - monthLength;
            if (daysLeft > 0) {
                data.dataPoints[(startDate.getMonth() % 12 + 1)  + '.' + startDate.getFullYear()] = {
                    min_length: Number.MAX_VALUE, max_length: 0, average_length: 0, bookings: 0
                };
                startDate.setDate(startDate.getDate() + daysLeft);
            }
        }
        else {
            data.dataPoints[(startDate.getMonth() % 12 + 1)  + '.' + startDate.getFullYear()] = {
                min_length: Number.MAX_VALUE, max_length: 0, average_length: 0, bookings: 0
            };
            startDate.setDate(startDate.getDate() + 30);
        }
    }
    let lengthSum = 0;
    for (const booking of filteredBookings) {
        let bookingDate = new Date(booking.booking_ts);
        let length = getNightsBetweenDates(new Date(booking.end), new Date(booking.start));
        lengthSum += length;
        let id = (bookingDate.getMonth() % 12 + 1)  + '.' + bookingDate.getFullYear();
        let lengthDataObject = data.dataPoints[id];
        if (data.dataPoints[id] !== undefined) {
            data.min_length = Math.min(length, lengthDataObject.min_length);
            data.max_length = Math.max(length, lengthDataObject.max_length);
            lengthDataObject.min_length = Math.min(length, lengthDataObject.min_length);
            lengthDataObject.max_length = Math.max(length, lengthDataObject.max_length);
            lengthDataObject.average_length += length;
            lengthDataObject.bookings++;
        }
    }
    data.average_length = filteredBookings.length > 0 ? lengthSum / filteredBookings.length : 0;
    for (const dataPoint of Object.values(data.dataPoints)) {
        if (dataPoint.bookings > 0) {
            dataPoint.average_length /= dataPoint.bookings;
        }
        if (dataPoint.min_length === Number.MAX_VALUE) {
            dataPoint.min_length = 0;
        }
    }
    if (data.min_length === Number.MAX_VALUE) {
        data.min_length = 0;
    }
    return data;
}

export function determineClickBookingData(interval, bookings, adverts) {
    let start = null;
    let end = null;
    if (interval !== -1) {
        end = getDayFromBegin().getTime();
        start = end - getTimeUnitInMS(interval);
    }
    let bookingCount = 0; let clickCount = 0;
    if (bookings && adverts) {
        bookingCount = getCompletedBookingsOfAdvertsInPeriod(bookings, adverts.map((a, i) => a.id), start, end).length;
        clickCount = collectClicksOfAdverts(adverts).length;
    }
    return { bookings: bookingCount, clicks: clickCount }
}
//endregion

export function filterValidReviews(reviews, authors) {
    let reviewList = [];
    if (reviews && authors) {
        for (const authorID of Object.keys(reviews)) {
            if (authors[authorID]) {
                reviewList.push(reviews[authorID]);
            }
        }
    }
    return reviewList;
}