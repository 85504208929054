import React from "react";
import PropTypes from "prop-types";
import { getDayFromBegin, getLanguageEntry, getTimeUnitInMS, priceToString } from "../../../../utils/Helper";
import {Pie} from "react-chartjs-2";
import parse from "html-react-parser";
import {calculateIncomeData} from "../../../../utils/StatisticHelper";
import {BookingLengthType} from "../../../../utils/Types.ts";

class Income extends React.Component {

    render() {
        let rowTitlePath = "jackboard>stats>submenus>income>details>row_titles>";
        let chartData = this.determineStatisticData();
        let placeholder;
        if (chartData.no_data) {
            placeholder = getLanguageEntry("jackboard>stats>submenus>income>" +
                (this.props.bookingType === BookingLengthType.short_term ? "no_short_term_bookings" : "no_long_term_bookings"));
        }
        else if (chartData.income.total <= 0) {
            placeholder = getLanguageEntry("jackboard>stats>submenus>income>" +
                (this.props.bookingType === BookingLengthType.short_term ? "no_short_term_data" : "no_long_term_data"));
        }
        return(
            <div id="income-overview" className="statistic-container">
                <div className="statistic-title">
                    {getLanguageEntry("jackboard>stats>submenus>income>title")}
                </div>
                <div className="content-container">
                    <div className="statistic-data-container">
                        <div className="roomjack-container">
                            {
                                chartData.income.total > 0 &&
                                <Pie data={chartData.data} options={chartData.options}
                                width={this.props.breakpoint < 750 ? 300 : 400} height={300} />
                            }
                            {
                                placeholder &&
                                <div id="no-income-placeholder">{parse(placeholder)}</div>
                            }
                        </div>
                    </div>
                    <div className="statistic-overview-container">
                        <div id="income-detail-container" className="roomjack-container">
                            <div className="statistic-title">
                                {getLanguageEntry("jackboard>stats>submenus>income>details>title")}
                            </div>
                            <table>
                                <tbody>
                                {
                                    this.props.bookingType !== BookingLengthType.short_term &&
                                    <tr>
                                        <td>{getLanguageEntry(rowTitlePath + "rents")}</td>
                                        <td>{priceToString(chartData.income.rents, true)}</td>
                                    </tr>
                                }
                                {
                                    this.props.bookingType !== BookingLengthType.long_term &&
                                    <tr>
                                        <td>{getLanguageEntry(rowTitlePath + "base_fee")}</td>
                                        <td>{priceToString(chartData.income.base_fee, true)}</td>
                                    </tr>
                                }
                                {
                                    this.props.bookingType !== BookingLengthType.short_term &&
                                    <tr>
                                        <td>{getLanguageEntry(rowTitlePath + "heating_costs")}</td>
                                        <td>{priceToString(chartData.income.heating_costs, true)}</td>
                                    </tr>
                                }
                                {
                                    this.props.bookingType !== BookingLengthType.short_term &&
                                    <tr>
                                        <td>{getLanguageEntry(rowTitlePath + "water_costs")}</td>
                                        <td>{priceToString(chartData.income.water_costs, true)}</td>
                                    </tr>
                                }
                                {
                                    this.props.bookingType !== BookingLengthType.short_term &&
                                    <>
                                        <tr>
                                            <td>{getLanguageEntry(rowTitlePath + "electricity_costs")}</td>
                                            <td>{priceToString(chartData.income.electricity_costs, true)}</td>
                                        </tr>
                                        <tr>
                                            <td>{getLanguageEntry(rowTitlePath + "misc_costs")}</td>
                                            <td>{priceToString(chartData.income.misc_costs, true)}</td>
                                        </tr>
                                        <tr>
                                            <td>{getLanguageEntry(rowTitlePath + "end_cleaning")}</td>
                                            <td>{priceToString(chartData.income.end_cleaning, true)}</td>
                                        </tr>
                                    </>
                                }
                                {
                                    this.props.bookingType !== BookingLengthType.long_term &&
                                    <>
                                        <tr>
                                            <td>{getLanguageEntry(rowTitlePath + "service_fee")}</td>
                                            <td>{priceToString(chartData.income.service_fee, true)}</td>
                                        </tr>
                                        <tr>
                                            <td>{getLanguageEntry(rowTitlePath + "cleaning_fee")}</td>
                                            <td>{priceToString(chartData.income.cleaning_fee, true)}</td>
                                        </tr>
                                    </>
                                }
                                <tr>
                                    <td>{getLanguageEntry(rowTitlePath + "misc_fee")}</td>
                                    <td>{priceToString(chartData.income.misc_fee, true)}</td>
                                </tr>
                                <tr id="sum-row">
                                    <td>
                                        {
                                            getLanguageEntry(rowTitlePath + "sum")
                                        }
                                    </td>
                                    <td>
                                        {
                                            priceToString(chartData.income.total, true)
                                        }
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    determineStatisticData() {
        let start = null;
        let end = null;
        if (this.props.interval !== -1) {
            end = getDayFromBegin().getTime();
            start = end - getTimeUnitInMS(this.props.interval);
        }
        const longTermMinLength = getTimeUnitInMS(30);
        let relevantBookings = this.props.bookings.filter(b =>
            (this.props.bookingType === "all") ||
            (this.props.bookingType === BookingLengthType.long_term && (b.end - b.start) >= longTermMinLength) ||
            (this.props.bookingType === BookingLengthType.short_term && (b.end - b.start) < longTermMinLength));
        let incomeData = calculateIncomeData(relevantBookings, this.props.adverts.map((a, i) => a.id), start, end);

        let options = {
            responsive: true,
            showTooltips: true,
            maintainAspectRatio: false,
            offset: 0,
            borderRadius: 5,
            animation: {
                duration: 2000
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            let price = context.dataset.data[context.dataIndex];
                            let initValue = 0;
                            let sum = context.dataset.data.reduce((acc, val) => acc + val, initValue);
                            let percent = (price / sum * 100).toFixed(1);
                            return ": " + percent + "%";
                        },
                        title: function(context) {

                            return context[0].label.toUpperCase();
                        }
                    },
                    backgroundColor: '#fff',
                    borderColor: '#000',
                    borderWidth: '1',
                    titleFont: {
                        family: 'urbane-rounded, sans-serif',
                        size: 10
                    },
                    titleColor: '#000',
                    bodyColor: '#000',
                    bodyFont: {
                        family: 'urbane-rounded, sans-serif',
                        size: 10
                    },
                },
                legend: {
                    display: true,
                    position: this.props.breakpoint < 750 ? 'bottom' : 'right',
                    labels: {
                        color: '#888888',
                        boxWidth: 40,
                        font: {
                            family: 'open-sans, sans-serif',
                            size: this.props.breakpoint < 750 ? 12 : 14
                        },
                        usePointStyle: true,
                        pointStyle: 'circle'
                    },
                    backgroundColor: '#ff0000',
                }
            },
            layout: {
                padding: 5
            }
        };
        let legendLabels = [];
        let dataPoints = [];
        let legendLabelPath = "jackboard>stats>submenus>income>details>row_titles>";
        if (this.props.bookingType !== BookingLengthType.short_term) {
            dataPoints.push(incomeData.rents);
            legendLabels.push(getLanguageEntry(legendLabelPath + "rents"));
        }
        if (this.props.bookingType !== BookingLengthType.long_term) {
            dataPoints.push(incomeData.base_fee);
            legendLabels.push(getLanguageEntry(legendLabelPath + "base_fee"));
        }
        if (this.props.bookingType !== BookingLengthType.short_term) {
            dataPoints.push(
                incomeData.heating_costs,
                incomeData.water_costs,
                incomeData.electricity_costs,
                incomeData.misc_costs,
                incomeData.end_cleaning);
            legendLabels.push(
                getLanguageEntry(legendLabelPath + "heating_costs"),
                getLanguageEntry(legendLabelPath + "water_costs"),
                getLanguageEntry(legendLabelPath + "electricity_costs"),
                getLanguageEntry(legendLabelPath + "misc_costs"),
                getLanguageEntry(legendLabelPath + "end_cleaning"),
                );
        }
        if (this.props.bookingType !== BookingLengthType.long_term) {
            dataPoints.push(
                incomeData.service_fee,
                incomeData.cleaning_fee);
            legendLabels.push(
                getLanguageEntry(legendLabelPath + "service_fee"),
                getLanguageEntry(legendLabelPath + "cleaning_fee"),
            );
        }
        dataPoints.push(incomeData.misc_fee);
        legendLabels.push(getLanguageEntry(legendLabelPath + "misc_fee"));

        let dataObject = {
            datasets: [{
                data: dataPoints,
                fill: true,
                backgroundColor: [
                    '#E9B400',
                    '#D86E36',
                    '#68AB55',
                    '#985025',
                    '#5C2727',
                    '#1DA1F2',
                    '#E1C1A6',
                    '#874ACC',
                    '#3B5998',
                    '#888888'
                ],
                borderWidth: 0
            }],
            labels: legendLabels
        };
        return {
            data: dataObject,
            options: options,
            income: incomeData,
            no_data: relevantBookings.length === 0
        };
    }

}

Income.propTypes = {
    adverts: PropTypes.array,
    bookings: PropTypes.array,
    interval: PropTypes.number,
    bookingType: PropTypes.oneOf([...Object.values(BookingLengthType), "all"]),
    breakpoint: PropTypes.number
}
export default Income;