import React from "react";
import PropTypes from "prop-types";
import "../../../css/components/input/image-uploader.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SUPPORTED_IMAGE_TYPES, getLanguageEntry, fileListToBase64} from "../../utils/Helper";

class ImageUploader extends React.Component
{

    constructor(props) {
        super(props);
        this.state = {
            images: [],
            drop_valid: "",
            loading: false
        }
        this.input = React.createRef();
    }

    render() {
        let dropzoneIcon;
        if (this.state.loading) {
            dropzoneIcon = <FontAwesomeIcon icon={["fad", "loader"]} spin={true}/>
        }
        else {
            switch (this.state.drop_valid) {
                case "valid":
                    dropzoneIcon = <FontAwesomeIcon icon={["fal", "check-circle"]}/>;
                    break;
                case "invalid":
                    dropzoneIcon = <FontAwesomeIcon icon={["fal", "ban"]}/>;
                    break;
                default:
                    dropzoneIcon = <FontAwesomeIcon icon={["fal", "image"]}/>;
                    break;
            }
        }
        return (
            <div className={"image-uploader" + (this.props.className !== undefined ? " " + this.props.className : "")}>
                <div className={"drop-zone " + this.state.drop_valid} onClick={() => { this.openInput() }}
                     onDragStart={(e) => { e.dataTransfer.effectAllowed = "all" }}
                     onDragOver={(e) => { this.onDragOver(e) } }
                     onDragEnd={() => { this.abortDrag() }} onDragLeave={() => { this.abortDrag() }}
                     onDrop={(e) => { this.receiveDrop(e) }}>
                    {
                        (this.props.images.length === 0 || this.props.showImage  === -1) &&
                        <div className={"drop-zone__placeholder c-flex" + (this.state.loading ? " disabled" : "")}>
                            {dropzoneIcon}
                            <span className="image-selector-header-label">
                                {getLanguageEntry("components>image_upload_modal>dropzone_placeholder")}
                            </span>
                            <span className="image-selector-info-label">
                                {getLanguageEntry("components>image_upload_modal>dropzone_info")}
                            </span>
                        </div>
                    }
                    {
                        this.props.images.length > 0 && this.props.showImage >= 0 &&
                        this.props.showImage < this.props.images.length &&
                        <div className="drop-zone__image c-flex">
                            <img src={this.props.images[this.props.showImage]} alt="Dynamic content"/>
                            {
                                this.props.onDeleteActualImage &&
                                <button className="image-remover simple-icon-button border"
                                        onClick={() => {this.props.onDeleteActualImage()}}>
                                    <FontAwesomeIcon icon={["fas", "trash-alt"]} />
                                </button>
                            }
                        </div>
                    }
                    <input type="file" className="drop-zone__input" multiple={this.props.dropFileCount > 1}
                           onChange={(e) => {this.receiveDrop(e)}}
                           accept={SUPPORTED_IMAGE_TYPES.join(", ")} ref={this.input}
                           disabled={this.state.loading}/>
                </div>
            </div>
        )
    }

    openInput() {
        if (!this.state.loading && this.props.images.length < this.props.dropFileCount &&
            this.input.current !== null) {
            this.input.current.click();
        }
    }

    onDragOver(e) {
        e.preventDefault();
        if (this.state.loading || e.dataTransfer.items.length === 0) return;
        let valid = false;
        for (let i=0; i<Math.min((this.props.dropFileCount - this.props.images.length), e.dataTransfer.items.length); i++) {
            valid = valid ||
                (SUPPORTED_IMAGE_TYPES.includes(e.dataTransfer.items[i].type));
        }
        this.setState({ drop_valid: (valid ? "valid" : "invalid") });
    }

    abortDrag() {
        this.setState({ drop_valid: "" });
    }

    receiveDrop(e) {
        e.preventDefault();
        if (this.state.loading) {
            return;
        }
        let wrongFormat = false;
        let tooManyImage = false;
        let fileSizeTooBig = false;
        let images = this.props.images.slice(0);
        let files = [];
        let receivedData = !e.dataTransfer ? e.target.files : e.dataTransfer.files;
        for (let i=0; i<Math.min((this.props.dropFileCount - this.props.images.length), receivedData.length); i++) {
            if (SUPPORTED_IMAGE_TYPES.includes(receivedData[i].type)) {
                if (receivedData[i].size <= this.props.maxFileSize) {
                    files.push(receivedData[i]);
                }
                else {
                    fileSizeTooBig = true;
                }
            }
            else {
                wrongFormat = true;
            }
        }
        if (this.props.dropFileCount > 1 &&
            receivedData.length > (this.props.dropFileCount - images.length)) {
            tooManyImage = true;
        }
        if (tooManyImage || wrongFormat || fileSizeTooBig) {
            this.props.onDropError?.(tooManyImage, wrongFormat, fileSizeTooBig);
        }
        this.loadImagesFromFiles(files, images);
    }

    async loadImagesFromFiles(files, images) {
        this.setState({
            loading: true,
            drop_valid: ""
        });
        const loadedImages = await fileListToBase64(files);
        images = images.concat(loadedImages);
        this.setState({
            loading: false
        });
        this.props.onAddImage?.(images);
    }

}
ImageUploader.propTypes = {
    className: PropTypes.string,
    header: PropTypes.string,
    info: PropTypes.string,
    dropFileCount: PropTypes.number,
    maxFileSize: PropTypes.number.isRequired,
    onDropError: PropTypes.func,
    onAddImage: PropTypes.func,
    images: PropTypes.array.isRequired,
    showImage: PropTypes.number,
    onDeleteActualImage: PropTypes.func
}
ImageUploader.defaultProps = {
    dropFileCount: 1,
    showImage: 0
}
export default ImageUploader;