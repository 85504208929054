import React from "react";
import PropTypes from "prop-types";
import {
    determineBookableHousings, truncateText, getLanguageEntry,
    PASSWORD, USERNAME
} from "../../../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Form, Col, Modal} from "react-bootstrap";
import "../../../../../css/pages/profile/third_party_connectors/zimmersoftware.css";
import {ADVERT_CACHE} from "../../../../utils/CacheHandler.ts";
import {
    addChannelManager, deleteChannelManager,
    getAdverts,
    getChannelManager,
    updateChannelManagerAccount
} from "../../../../utils/RESTInterface";
import Dropdown from "../../../../components/input/Dropdown";
import SecretInputField from "../../../../components/input/SecretInputField";
import FetchButton from "../../../../components/buttons/FetchButton";
import _uniqueId from "lodash/uniqueId";
import AlertModal from "../../../../components/modals/AlertModal";
import {SupportedChannelManager} from "../../../../utils/Types.ts";

class ZimmerSoftware extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            show_setup_modal: false,
            not_connected_adverts: [],
            connected_adverts: [],
            target_account: null,
            connecting_account: false,
            requesting_account_connection_status: false,
            account_connection_result: null,
            updating_account: false
        }
        this.setup_form = React.createRef();
        this.update_account_form = React.createRef();
        this.alert_modal = React.createRef();
    }

    componentDidMount() {
        this.updateAdvertLists();
    }

    render() {
        return (
            <div id="zimmersoftware-setup">
                <div className="roomjack-container">
                    {
                        this.state.loading &&
                        <div className="loading">
                            <FontAwesomeIcon icon={["fad", "spinner-third"]} spin={true} />
                            <span>{getLanguageEntry("general>loading_data")}</span>
                        </div>
                    }
                    {
                        !this.state.loading &&
                        <div>
                            <div className="description-container">
                                {
                                    (this.state.not_connected_adverts.length > 0 || this.state.connected_adverts.length > 0) &&
                                    getLanguageEntry("profile_menu>third_party_software>supported_software>zimmersoftware>description")
                                }
                                {
                                    this.state.not_connected_adverts.length === 0 && this.state.connected_adverts.length === 0 &&
                                    getLanguageEntry("profile_menu>third_party_software>supported_software>zimmersoftware>no_adverts_description")
                                }
                            </div>
                            {
                                this.state.connected_adverts.length > 0 && this.props.userData.zimmersoftware &&
                                this.props.userData.zimmersoftware.accounts &&
                                this.props.userData.zimmersoftware.accounts.length > 0 &&
                                <table className="mt-3">
                                    <tbody>
                                        <tr>
                                            <th>{getLanguageEntry("profile_menu>third_party_software>supported_software>zimmersoftware>overview_table>account")}</th>
                                            <th>{getLanguageEntry("profile_menu>third_party_software>supported_software>zimmersoftware>overview_table>advert")}</th>
                                            <th>{getLanguageEntry("profile_menu>third_party_software>supported_software>zimmersoftware>overview_table>connection_status")}</th>
                                            <th>{getLanguageEntry("profile_menu>third_party_software>supported_software>zimmersoftware>overview_table>action")}</th>
                                        </tr>
                                    {
                                        this.props.userData.zimmersoftware.accounts.map((account, i) => {
                                            return <tr key={_uniqueId(account.username)}>
                                                <td>{account.username}</td>
                                                <td>{this.getAdvertTitle(account.maintained_data_id)}</td>
                                                <td>{this.determineSyncStatus(account)}</td>
                                                <td>
                                                    <button onClick={() => { this.callSetupModal(account)}}>
                                                        <FontAwesomeIcon icon={["fal", "pencil"]}/>
                                                    </button>
                                                    <button onClick={() => {this.deleteAccount(account)}}>
                                                        <FontAwesomeIcon icon={["fal", "trash"]}/>
                                                    </button>
                                                </td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </table>
                            }
                            {
                                this.state.not_connected_adverts.length > 0 &&
                                <button className="outline-button accent mt-3" onClick={() => { this.callSetupModal() }}>
                                    <FontAwesomeIcon icon={["fal", "plus"]} />
                                    <span>{getLanguageEntry("profile_menu>third_party_software>supported_software>zimmersoftware>connect_account")}</span>
                                </button>
                            }
                        </div>
                    }
                </div>
                <Modal id="zimmersoftware-setup-modal" show={this.state.show_setup_modal} size="lg"
                       onHide={() => { this.hideSetupModal() }} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <h4>
                            {getLanguageEntry("profile_menu>third_party_software>supported_software>zimmersoftware>setup_account")}
                        </h4>
                        <button onClick={() => { this.hideSetupModal() }}>
                            <FontAwesomeIcon icon={["fal", "close"]}/>
                        </button>
                    </Modal.Header>

                    <Modal.Body>
                        {
                            this.state.target_account &&
                            <div>
                                {
                                    this.state.requesting_account_connection_status &&
                                    <div className="loading">
                                        <FontAwesomeIcon icon={["fad", "spinner-third"]} spin/>
                                        <span>{getLanguageEntry("profile_menu>third_party_software>supported_software>zimmersoftware>setup_assistant>checking_account_status")}</span>
                                    </div>
                                }
                                {
                                    !this.state.requesting_account_connection_status &&
                                    <form ref={this.update_account_form} onSubmit={(e) => this.updateAccount(e)}>
                                        <div>
                                            <Form.Group as={Col} controlId={PASSWORD} className="mb-2">
                                                <Form.Label className="description-container v-form-label">
                                                    {getLanguageEntry("profile_menu>third_party_software>supported_software>zimmersoftware>setup_assistant>zimmersoftware_password")}
                                                </Form.Label>
                                                <SecretInputField name={PASSWORD}
                                                                  initialValue={this.state.target_account.password}/>
                                            </Form.Group>
                                        </div>
                                        {
                                            this.state.account_connection_result.errorCode &&
                                            <div className="description-container mb-4">
                                                {this.getAccountConnectionError()}
                                            </div>
                                        }
                                        {
                                            !this.state.account_connection_result.errorCode &&
                                            this.createWiringTable()
                                        }
                                        <FetchButton type="submit" loading={this.state.updating_account}
                                                     className="outline-button accent mt-5"
                                                     loadingText={"profile_menu>third_party_software>supported_software>zimmersoftware>setup_assistant>updating_account"}>
                                            <FontAwesomeIcon icon={["fal", "save"]}/>
                                            <span>{getLanguageEntry("general>save")}</span>
                                        </FetchButton>
                                    </form>
                                }
                            </div>
                        }
                        {
                            !this.state.target_account &&
                            <div>
                                <div className="description-header">
                                    {getLanguageEntry("profile_menu>third_party_software>supported_software>zimmersoftware>setup_assistant>connect_account_header")}
                                </div>
                                <div className="description-container mb-4">
                                    {getLanguageEntry("profile_menu>third_party_software>supported_software>zimmersoftware>setup_assistant>connect_account_description")}
                                </div>
                                <form ref={this.setup_form} onSubmit={(e) => { this.connectZimmerSoftwareAccount(e) }}>
                                    <Form.Group as={Col} controlId={"advert"} className="mb-2">
                                        <Form.Label className="description-container v-form-label">
                                            {getLanguageEntry("profile_menu>third_party_software>supported_software>zimmersoftware>setup_assistant>advert_to_connect")}
                                        </Form.Label>
                                        <Dropdown required={true} items={this.getNotConnectedAdvertList()} name={"advert_id"}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId={USERNAME} className="mb-2">
                                        <Form.Label className="description-container v-form-label">
                                            {getLanguageEntry("general>username")}
                                        </Form.Label>
                                        <Form.Control type="text" name={USERNAME} required={true}
                                                      placeholder={getLanguageEntry("profile_menu>third_party_software>supported_software>zimmersoftware>setup_assistant>zimmersoftware_username")}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId={PASSWORD} className="mb-4">
                                        <Form.Label className="description-container v-form-label">
                                            {getLanguageEntry("general>password")}
                                        </Form.Label>
                                        <SecretInputField  name={PASSWORD} required={true}
                                                           placeholder={"profile_menu>third_party_software>supported_software>zimmersoftware>setup_assistant>zimmersoftware_password"}/>
                                    </Form.Group>
                                    {
                                        this.state.account_connection_result &&
                                        this.state.account_connection_result.errorCode &&
                                        <div className="description-container mb-4">
                                            {this.getAccountConnectionError()}
                                        </div>
                                    }
                                    <FetchButton type="submit" loading={this.state.connecting_account} className="outline-button accent"
                                                 loadingText={"profile_menu>third_party_software>supported_software>zimmersoftware>setup_assistant>connecting_account"}>
                                        <FontAwesomeIcon icon={["fal", "plug"]} />
                                        <span>{getLanguageEntry("profile_menu>third_party_software>supported_software>zimmersoftware>connect_account")}</span>
                                    </FetchButton>
                                </form>
                            </div>
                        }
                    </Modal.Body>
                </Modal>
                <AlertModal ref={this.alert_modal} />
            </div>
        )
    }

    updateAdvertLists() {
        if (this.props.userData.adverts) {
            let fetch = false;
            for (const advertID of this.props.userData.adverts) {
                if (!ADVERT_CACHE.getCacheObjectData(advertID)) {
                    fetch = true;
                    break;
                }
            }
            if (fetch) {
                let that = this;
                this.setState({loading: true});
                getAdverts((response) => {
                    that.setupAdvertLists(response.data);
                });
            }
            else {
                this.setupAdvertLists(ADVERT_CACHE.getCachedDataList()
                    .filter(a => this.props.userData.adverts.includes(a.id)));
            }
        }
    }

    setupAdvertLists(adverts) {
        let state = { loading: false };
        state.connected_adverts = [];
        state.not_connected_adverts = [];
        if (adverts) {
            for (const advert of adverts) {
                if (advert.published && advert.complete &&
                    (!advert.delete || !advert.delete.active) &&
                    (!advert.blocking || !advert.blocking.active)) {
                    if (this.props.userData.zimmersoftware !== undefined &&
                        this.props.userData.zimmersoftware.accounts &&
                        this.props.userData.zimmersoftware.accounts.filter(a => a.maintained_data_id === advert.id).length > 0) {
                        state.connected_adverts.push(advert);
                    }
                    else {
                        state.not_connected_adverts.push(advert);
                    }
                }
            }
        }
        this.setState(state);
    }

    callSetupModal(account) {
        let state = {
            show_setup_modal: true,
            target_account: account,
            requesting_account_connection_status: false
        }
        if (account) {
            state.requesting_account_connection_status = true;
            this.setState(state);
            let that = this;
            getChannelManager(SupportedChannelManager.zimmersoftware, { username: account.username},
                (response) => {
                    that.setState({
                        requesting_account_connection_status: false,
                        account_connection_result: response
                    });
                });
        }
        else {
            this.setState(state);
        }
    }

    hideSetupModal() {
        this.setState({show_setup_modal: false})
    }

    getNotConnectedAdvertList() {
        let items = [];
        for (const advert of this.state.not_connected_adverts) {
            items.push({value: advert.id, label: advert.title});
        }
        return items;
    }

    connectZimmerSoftwareAccount(e) {
        e.preventDefault();
        let data = new FormData(this.setup_form.current);
        let body = {};
        data.forEach((value, key) => (body[key] = value));
        this.setState({
            connecting_account: true,
            account_connection_result: null
        });
        let that = this;
        addChannelManager(SupportedChannelManager.zimmersoftware, body, (response) => {
            let state = {
                connecting_account: false,
                account_connection_result: response
            }
            if (!response.errorCode && response.user &&
                response.user.zimmersoftware && response.user.zimmersoftware.accounts) {
                let account = response.user.zimmersoftware.accounts.filter(a => a.maintained_data_id === data.get("advert_id"))[0];
                if (account !== undefined) {
                    state.target_account = account;
                }
                that.props.onUpdateUserSession?.(response, () => {
                    that.setState(state);
                    that.updateAdvertLists();
                });
            }
            else {
                that.setState(state);
            }
        });
    }

    getAccountConnectionError() {
        if (this.state.account_connection_result &&
            this.state.account_connection_result.errorCode) {
            switch (this.state.account_connection_result.errorCode) {
                case "user_not_found":
                case "wrong_pw_err":
                case "account_connected_err":
                    return getLanguageEntry("profile_menu>third_party_software>supported_software>zimmersoftware>error_codes>" + this.state.account_connection_result.errorCode);
                default:
                    return getLanguageEntry("error_codes>" + this.state.account_connection_result.errorCode);
            }
        }
        return "";
    }

    getAdvertTitle(id) {
        let advert = this.state.connected_adverts.filter(a => a.id === id)[0];
        if (advert) {
            return truncateText(advert.title, 70);
        }
        return "Inserat nicht gefunden";
    }

    determineSyncStatus(account) {
        if (account.wiring) {
            let advert = this.state.connected_adverts.filter(a => a.id === account.maintained_data_id)[0];
            if (advert) {
                let allSync = true;
                for (const housing of determineBookableHousings(advert)) {
                    if (!Object.values(account.wiring).includes(housing.id)) {
                        allSync = false;
                        break;
                    }
                }
                if (allSync) {
                    return <span><FontAwesomeIcon icon={["fas", "check"]}/>{getLanguageEntry("general>complete")}</span>;
                }
            }
        }
        return <span><FontAwesomeIcon icon={["fas", "exclamation-triangle"]}/>{getLanguageEntry("general>incomplete")}</span>;
    }

    createWiringTable() {
        let advert = this.state.connected_adverts.filter(a => a.id === this.state.target_account.maintained_data_id)[0];
        if (advert) {
            return <table id="account-wiring" className="mt-3">
                <tbody>
                <tr>
                    <th>room</th>
                    <th>{getLanguageEntry("profile_menu>third_party_software>supported_software>zimmersoftware>setup_assistant>room_category")}</th>
                </tr>
                {
                    determineBookableHousings(advert).map((h, i) => {
                        let housingLabel = "";
                        if (h.id === advert.id) {
                            housingLabel = advert.title;
                        }
                        else if (h.id.startsWith("room")) {
                            housingLabel = h.name ? h.name : getLanguageEntry("advert_attributes>room_types>" + h.type);
                        }
                        let initialValue = null;
                        if (this.state.target_account.wiring) {
                            for (const key of Object.keys(this.state.target_account.wiring)) {
                                if (this.state.target_account.wiring[key] === h.id) {
                                    initialValue = key;
                                    break;
                                }
                            }
                        }
                        return <tr key={h.id}>
                            <td>
                                <input type="text" defaultValue={housingLabel} readOnly={true} name={"housing-name-" + i} />
                            </td>
                            <td>
                                <Dropdown name={h.id} items={this.getRoomCategoryItems()}
                                          initialValue={initialValue} />
                            </td>
                        </tr>
                    })
                }
                </tbody>
            </table>
        }
        return '';
    }

    updateAccount(e) {
        e.preventDefault();
        let updateForm = new FormData(this.update_account_form.current);
        let update = { username: this.state.target_account.username };
        let wiring = {};
        if (updateForm.get(PASSWORD) !== this.state.target_account.password) {
            update.password = updateForm.get(PASSWORD);
        }
        for (const entry of updateForm.entries()) {
            if (entry[0] !== PASSWORD && !entry[0].includes("housing-name") &&
                entry[1] !== null && entry[1] !== 'none') {
                wiring[entry[1]] = entry[0];
            }
        }
        if (Object.keys(wiring).length > 0) {
            update.wiring = wiring;
        }
        else {
            update.wiring = "null";
        }
        let that = this;
        this.setState({updating_account: true});
        updateChannelManagerAccount(SupportedChannelManager.zimmersoftware, update, (response) => {
            that.setState({
                updating_account: false,
                account_connection_result: response,
                show_setup_modal: false
            });
            if (!response.errorCode && response.user) {
                that.props.onUpdateUserSession?.(response);
            }
        });
    }

    deleteAccount(account) {
        let title = "general>warning";
        let content = <div className="description-container">
            {getLanguageEntry("profile_menu>third_party_software>supported_software>zimmersoftware>alert>delete_warning")}
        </div>;
        let that = this;
        this.alert_modal.current.show(title, content, () => {
            that.alert_modal.current.setLoading(true, "profile_menu>third_party_software>supported_software>zimmersoftware>deleting_account");
            deleteChannelManager(SupportedChannelManager.zimmersoftware, {username: account.username},
                (response) => {
                that.alert_modal.current.hide();
                if (response.errorCode) {
                    alert(response.errorCode + ": " + response.message);
                }
                else if (response.user) {
                    that.props.onUpdateUserSession?.(response, () => {
                        that.updateAdvertLists();
                    });
                }
            })
        }, ["fal", "trash"], "profile_menu>third_party_software>supported_software>zimmersoftware>delete_account",
            ["fal", "times"], "general>abort", null, "");
    }

    getRoomCategoryItems() {
        let items = [{value: null, label: '-'}];
        if (this.state.account_connection_result.zimmersoftware &&
            this.state.account_connection_result.zimmersoftware.Roomcategory) {
            for (const roomCat of this.state.account_connection_result.zimmersoftware.Roomcategory) {
                items.push({value: roomCat.Roomcategory, label: roomCat.Name + ' (' + roomCat.Roomcategory + ')'});
            }
        }
        return items;
    }

}
ZimmerSoftware.propTypes = {
    onUpdateUserSession: PropTypes.func.isRequired,
    userData: PropTypes.object.isRequired
}
export default ZimmerSoftware;