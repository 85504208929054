import React from 'react';
import {Container, Carousel } from 'react-bootstrap';
import {
    NEWS,
    RENTER_CAROUSEL,
    RENTER_FACTS,
    RENTER_SPECIAL_SEARCH_CATEGORIES
} from "../../../resources/contents/LandingPageContent";
import {
    collectHousingListAttributes,
    createAdvertLocationDropdown, createNewsBox, determineAdvertNameLabel,
    filterDataToQuery,
    getLanguageEntry
} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NavigatorButton from "../../components/buttons/NavigatorButton";
import Calendar from "../../components/Calendar";
import {Link} from "react-router-dom";
import {RECOMMENDATION_CACHE} from "../../utils/CacheHandler.ts";
import {getStartPageRecommendations} from "../../utils/RESTInterface";
import CarouselGrid from "../../components/CarouselGrid";

class MainLandingPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            start: null,
            end: null,
            recommendations: null
        }
        this.location = React.createRef();
    }

    componentDidMount() {
        let recommendationCache = RECOMMENDATION_CACHE.getCache();
        if (recommendationCache) {
            this.setState({
                recommendations: recommendationCache
            });
        }
        else {
            let that = this;
            getStartPageRecommendations((response) => {
                if (response.result_list) {
                    that.setState({ recommendations: response.result_list });
                }
            });
        }
    }

    render() {
        return(
            <div id="renter-landing-page" className="landing-page">
                <div id="rlp-search-header" className="landing-page-carousel-header">
                    <div id="rlp-header">
                        <div className="header-static-image-container">
                            <div className="container">
                                <img className="header-image-container" alt="students&business-man"
                                     src="https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/carousel/students+businessman.png" />
                            </div>
                        </div>
                        <Carousel slide={true} interval={4000} indicators={false} controls={false}>
                            {RENTER_CAROUSEL.map((item, index) => {
                                return <Carousel.Item key={"renter-carousel-item-" + index}>
                                    <img src={item.image_url} alt={"background-" + index} />
                                    <Carousel.Caption>
                                        <Container>
                                            <div className="carousel-header-info-container">
                                                <div>{getLanguageEntry("landing_pages>renter>carousel_prefix")}</div><div className="accent">{getLanguageEntry(item.slogan)}</div>
                                            </div>
                                        </Container>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            })}
                        </Carousel>
                    </div>
                </div>
                <div id="hard-facts">
                    <div id="rlp-search-container" className="container" tabIndex="0">
                        <div id="rlp-search">
                            <div id="rlp-search-location-container">
                                {createAdvertLocationDropdown("main-location", this.location, "landing_pages>renter>search_bar>location_placeholder",
                                    null, "none", ["fal", "location-dot"])}
                            </div>
                            <Calendar icon={["fal", "calendar-day"]} onChange={(payload) => {
                                if (payload.start !== this.state.start || payload.end !== this.state.end) {
                                    this.setState(payload);
                                }
                            }}/>
                            <NavigatorButton to="/search" id="rlp-search-button" className="accent-icon-button"
                                             getDynamicLink={() => { return this.getSearchLink() }}>
                                <FontAwesomeIcon icon={["fas", "search"]}/>
                                <span>{getLanguageEntry("landing_pages>renter>search_bar>search")}</span>
                            </NavigatorButton>
                        </div>
                    </div>
                    <div className="container">
                        <h1>{getLanguageEntry("landing_pages>renter>hard_facts_title")}</h1>
                        <div id="hard-facts-description">{getLanguageEntry("landing_pages>renter>hard_facts_description")}</div>
                        <div className="hard-fact-row">
                            {
                                RENTER_FACTS.map((item, index) => {
                                    return <div className="hard-fact-column" key={"hard-fact-" + index}>
                                        <img src={item.image_url} alt={getLanguageEntry(item.image_alt)}/>
                                        <h3 className="hard-fact-column-title">{(index + 1) + ". " + getLanguageEntry(item.title)}</h3>
                                        <div className="hard-fact-column-content">{getLanguageEntry(item.content)}</div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                <div id="rlp-recommendations">
                    <div className="container">
                        <h2 className="area-title">{getLanguageEntry("landing_pages>renter>recommendations>title")}</h2>
                        <CarouselGrid items={this.getRecommendationItems()} id="recommendation-carousel"
                                      nextButton={<FontAwesomeIcon icon={["fal", "chevron-circle-right"]} />}
                                      prevButton={<FontAwesomeIcon icon={["fal", "chevron-circle-left"]} />}/>
                    </div>
                </div>
                <div id="renter-news-room" className="news-room">
                    <div className="container">
                        <h2 className="area-title">{getLanguageEntry("landing_pages>shared_content>news>title")}</h2>
                        <CarouselGrid items={this.getRenterNewsItems()} id="renter-news-carousel" breakpoints={{
                            780: {
                                slidesPerView: 1,
                                slidesPerGroup: 1
                            },
                            950: {
                                slidesPerView: 2,
                                slidesPerGroup: 2
                            },
                            1100: {
                                slidesPerView: 3,
                                slidesPerGroup: 3
                            },
                        /*580: {
                                slidesPerView: 1,
                                slidesPerGroup: 1
                            },
                            680: {
                                slidesPerView: 2,
                                slidesPerGroup: 2
                            },
                            780: {
                                slidesPerView: 3,
                                slidesPerGroup: 3
                            },
                            950: {
                                slidesPerView: 4,
                                slidesPerGroup: 4
                            }*/
                        }}  />
                    </div>
                </div>
                <div className="roomjack-is-green teaser-container">
                    <div className="teaser-background-image"></div>
                    <div className="container">
                        <div className="left-side">
                            <div className="teaser-header-container">
                                <div>{getLanguageEntry("landing_pages>shared_content>roomjack_is_green>header_1")}</div>
                                <div>{getLanguageEntry("landing_pages>shared_content>roomjack_is_green>header_2")}</div>
                            </div>
                            <div className="teaser-description-container">
                                {getLanguageEntry("landing_pages>shared_content>roomjack_is_green>description")}
                            </div>
                            <a href="/content/roomjack-is-green" className="outline-button">
                                <span>{getLanguageEntry("landing_pages>shared_content>roomjack_is_green>button_text")}</span>
                            </a>
                        </div>
                        <div className="right-side">
                            <img src="https://roomjack.blob.core.windows.net/roomjack/content-images/nachhaltiger_dude.png"
                                 alt={getLanguageEntry("landing_pages>shared_content>roomjack_is_green>image_alt")} />
                        </div>
                    </div>
                </div>
                <div id="rlp-special-search">
                    <div className="container">
                        <h2 className="area-title">{getLanguageEntry("landing_pages>renter>special_search>title")}</h2>
                        <div className="area-description">{getLanguageEntry("landing_pages>renter>special_search>description")}</div>
                        <CarouselGrid items={this.getSpecialSearchItems()} id="special-search-carousel"/>
                    </div>
                </div>
                <div className="parallax">
                    <div className="container">
                        <div className="parallax-text">
                            {getLanguageEntry("landing_pages>renter>special_search>citation")}
                        </div>
                        <div className="description-container">- Augustinus Aurelius</div>
                    </div>
                    <span role="img" aria-label="Nachhaltiger ökologischer Mann"> </span>
                </div>
                <div className="landing-page-rental-info">
                    <div className="container">
                        <div className="landing-page-rental-info-container">
                            <div>
                                <div className="header small">{getLanguageEntry("general>now")}</div>
                                <div className="header">{getLanguageEntry("landing_pages>renter>become_landlord>room")}</div>
                                <div className="header">{getLanguageEntry("landing_pages>renter>become_landlord>rent")}</div>
                                <div>{getLanguageEntry("landing_pages>renter>become_landlord>description")}</div>
                                <Link className="outline-button" to="/vermieten">
                                    <FontAwesomeIcon icon={["fal", "home-alt"]} /><span>{getLanguageEntry("landing_pages>renter>become_landlord>button_text")}</span>
                                </Link>
                            </div>
                        </div>
                        <div className="landing-page-rental-info-image-container">
                            <img
                                src="https://roomjack.blob.core.windows.net/roomjack/landlord-landing-page/backgrounds/vermieter.png"
                                alt={getLanguageEntry("landing_page>renter>become_landlord>image_alt")} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getSearchLink() {
        let link = "/search";
        let filter = {};
        if (this.location.current !== null && this.location.current.value &&
            this.location.current.value.length > 0 && this.location.current.value !== "none") {
            filter.location = this.location.current.value;
        }
        if (this.state.start !== null) {
            filter.start = this.state.start;
        }
        if (this.state.end !== null) {
            filter.end = this.state.end;
        }
        let query = filterDataToQuery(filter);
        return link + query;
    }

    getSpecialSearchItems() {
        return RENTER_SPECIAL_SEARCH_CATEGORIES.map((item, _) => {
            let link = "/search";
            let filter = {
                characteristics: item.characteristics
            };
            link += filterDataToQuery(filter);
            return <Link className="area-item" to={link}>
                <div className="area-image">
                    <img src={item.image_url} alt={getLanguageEntry("landing_pages>renter>special_search>cat>" + item.name + ">image_alt")} />
                </div>
                <div className="area-item-title">{getLanguageEntry("landing_pages>renter>special_search>cat>" + item.name + ">name")}</div>
            </Link>
        })
    }

    getRenterNewsItems() {
        return NEWS.renter.concat(NEWS.shared).map((news, i) => {
            return createNewsBox(news, i)
        });
    }

    getRecommendationItems() {
        if (!this.state.recommendations) {
            return Array.from(Array(4)).map((_, i) => {
                return <div className="area-item fetching shine">
                    <div className="area-image">
                        <FontAwesomeIcon icon={["fal", "image"]}/>
                    </div>
                    <div className="area-item-title"> </div>
                    <div className="area-item-info"> </div>
                </div>
            })
        }
        else {
            return this.state.recommendations.map((recommendation, _) => {
                let image = collectHousingListAttributes(recommendation).images[0];
                let style = image ?
                    { backgroundImage: 'url(' + image + ')' } :
                    { backgroundColor: 'lightgray' };
                return <Link className="area-item" to={'/expose/' + recommendation.id}
                             key={recommendation.id}>
                    <div className="area-image" style={style}>
                        {
                            !image &&
                            <FontAwesomeIcon icon={["fal", "image"]} />
                        }
                    </div>
                    <div className="area-item-title">
                        {
                            recommendation.title
                        }
                    </div>
                    <div className="area-item-info">
                        {determineAdvertNameLabel(recommendation)}
                    </div>
                </Link>
            });
        }
    }

}
export default MainLandingPage;