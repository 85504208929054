import React from "react";
import PropTypes from "prop-types";
import "../../../css/components/advert/advert-hierarchy.css";
import {getLanguageEntry, truncateText} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AdvertType} from "../../utils/Types.ts";
import {ADVERT_SUB_TYPE_MAP, MAX_ROOM_COUNT, ROOM_ICON_MAP} from "../../../resources/housings";

const LANG_PATH = "jackboard>adverts>advert_creator>";

class AdvertHierarchy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sort_mode: false,
            sort_map: []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.onSortChildren && this.state.sort_mode &&
            prevProps.advert.type !== this.props.advert.type) {
            this.setState({ sort_mode: false});
        }
    }

    onSwitchSortMode() {
        if (this.state.sort_mode) {
            let newOrder = [...this.props.advert.rooms];
            let sortMap = this.state.sort_map;
            let max = Math.max(...sortMap);
            for (let i=0; i<sortMap.length; i++) {
                if (sortMap[i] < 0) {
                    sortMap[i] = ++max;
                }
            }
            for (let i=0; i<sortMap.length; i++) {
                newOrder[sortMap[i]] = this.props.advert.rooms[i];
            }
            this.setState({
                sort_mode: false
            }, () => {
                this.props.onSortChildren(newOrder);
            });
        }
        else {
            this.setState({
                sort_mode: true,
                sort_map: Array(this.props.advert.rooms.length).fill(-1)
            });
        }
    }

    onSelectChild(index) {
        let sortMap = [...this.state.sort_map];
        let actualValue = sortMap[index];
        if (actualValue === -1) {
            sortMap[index] = Math.max(...sortMap) + 1;
        }
        else {
            for (let i=0; i<sortMap.length; i++) {
                if (sortMap[i] === actualValue) {
                    sortMap[i] = -1;
                }
                else if (sortMap[i] > actualValue) {
                    sortMap[i] -= 1;
                }
            }
        }
        this.setState({
            sort_map: sortMap
        });
    }

    render() {
        return(
            <RoomTreeBox housing={this.props.advert} isParent={true} onAddRoom={this.props.onAddRoom}
                         title={getLanguageEntry("advert_attributes>advert_types>" +
                             (this.props.advert.type ?? this.props.advert.advert_type))}
                         showBookable={this.props.showBookable}
                         roomsBookable={this.props.advert.rooms_bookable}
                         deletable={this.props.deletable} onDeleteRoom={this.props.onDeleteRoom}
                         editable={this.props.editable} onEdit={this.props.onEdit}
                         requirements={this.props.requirements} createContent={this.props.createContent}
                         sortMode={this.state.sort_mode} sortMap={this.state.sort_map}
                         onSwitchSortMode={this.props.onSortChildren ? () => {this.onSwitchSortMode()} : null}
                         onSelectChild={this.props.onSortChildren ? (index) => {this.onSelectChild(index)} : null} />
        )
    }

}
AdvertHierarchy.propTypes = {
    advert: PropTypes.any.isRequired,
    onAddRoom: PropTypes.func,
    onEdit: PropTypes.func.isRequired,
    editable: PropTypes.func.isRequired,
    createContent: PropTypes.func.isRequired,
    deletable: PropTypes.func,
    onDeleteRoom: PropTypes.func,
    requirements: PropTypes.any,
    onSortChildren: PropTypes.func,
    showBookable: PropTypes.bool,
};
AdvertHierarchy.defaultProps = {
    showBookable: false
};
export default AdvertHierarchy;

const RoomTreeBox = (props) => {
    let type = props.isParent ? ADVERT_SUB_TYPE_MAP[props.housing.type] : AdvertType.room;
    let canAddRooms = props.isParent && (!props.housing.rooms || props.housing.rooms.length < MAX_ROOM_COUNT);

    let invalid = props.requirements &&
        ((props.isParent && props.requirements.main) ||
            (!props.isParent && props.requirements[props.housing.id]));

    let classList = ["room-tree-box", type];
    if (props.sortMode && props.isParent) {
        classList.push("sort-mode")
    }

    return <div className={classList.join(" ")}>
        <RoomBox title={props.title} housing={props.housing} sortMode={props.sortMode} index={props.index}
                 onSortClick={props.isParent ? null : props.onSelectChild}
                 sortNumber={props.sortMap ? props.sortMap[props.index] : undefined}
                 deletable={(roomID) => props.deletable && !props.isParent && props.deletable(roomID)}
                 onDelete={props.onDeleteRoom}
                 showBookable={props.showBookable &&
                     ((props.isParent && !props.roomsBookable) ||
                     (!props.isParent && props.roomsBookable))}
                 editable={props.editable} onEdit={props.onEdit} createContent={props.createContent}
                 invalid={!!invalid}/>
        {
            props.isParent &&
            <div className="child-container room">
                {
                    props.housing.rooms && props.housing.rooms.map((r, i) => {
                        return <RoomTreeBox key={r.id} isParent={false} housing={r}
                                            title={r.name ?? getLanguageEntry("advert_attributes>room_types>" + r.type)}
                                            deletable={props.deletable} onDeleteRoom={props.onDeleteRoom}
                                            editable={props.editable} onEdit={props.onEdit} sortMode={props.sortMode}
                                            sortMap={props.sortMap} onSelectChild={props.onSelectChild}
                                            createContent={props.createContent}
                                            showBookable={props.showBookable}
                                            roomsBookable={props.roomsBookable}
                                            requirements={props.requirements} index={i} />
                    })
                }
                {
                    canAddRooms && props.onAddRoom && !props.sortMode &&
                    <button className="hierarchy-room-adder room"
                            onClick={() => {props.onAddRoom()}}>
                        <FontAwesomeIcon icon={["fat", "plus-circle"]}/>
                        <span>{getLanguageEntry(LANG_PATH + "buttons>add_room")}</span>
                    </button>
                }
                {
                    props.onSwitchSortMode && props.housing.rooms && props.housing.rooms.length > 1 &&
                    <button className="outline-button black room-sorter" onClick={() => {props.onSwitchSortMode()}}
                            title={getLanguageEntry(LANG_PATH + "sort_room_info")}>
                        <FontAwesomeIcon icon={["fas", props.sortMode ? "check" : "arrow-down-big-small"]} />
                        <span>
                                {getLanguageEntry(LANG_PATH + (props.sortMode ? "sort_rooms_done" : "sort_rooms"))}
                            </span>
                    </button>
                }
                {
                    props.onSwitchSortMode && props.sortMode && props.housing.rooms && props.housing.rooms.length > 1 &&
                    <div className="description-container sort-description">
                        <FontAwesomeIcon icon={["fal", "info-circle"]} />
                        {getLanguageEntry(LANG_PATH + "sort_room_description")}
                    </div>
                }
            </div>
        }
    </div>;

}
RoomTreeBox.propTypes = {
    housing: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    isParent: PropTypes.bool.isRequired,
    onAddRoom: PropTypes.func,
    deletable: PropTypes.func,
    onDeleteRoom: PropTypes.func,
    onEdit: PropTypes.func.isRequired,
    editable: PropTypes.func.isRequired,
    createContent: PropTypes.func.isRequired,
    requirements: PropTypes.any,
    onSwitchSortMode: PropTypes.func,
    onSelectChild: PropTypes.func,
    sortMode: PropTypes.bool,
    sortMap: PropTypes.arrayOf(PropTypes.number),
    index: PropTypes.number,
    showBookable: PropTypes.bool.isRequired,
    roomsBookable: PropTypes.bool
}

const RoomBox = (props) => {

    let classList = ["room-box"];
    // if the housing is the advert, the next variable will be house, apartment or room
    if (props.housing.id && props.housing.id.startsWith("room")) {
        classList.push(AdvertType.room);
    }
    else if (props.housing.type) {
        classList.push(ADVERT_SUB_TYPE_MAP[props.housing.type]);
    }
    if (props.invalid) {
        classList.push("invalid");
    }
    if (props.className) {
        classList.push(props.className);
    }
    let roomType = props.housing.id && props.housing.id.startsWith("room") ? props.housing.type : null;
    let icon;
    if (roomType) {
        icon = <FontAwesomeIcon icon={["fal", ROOM_ICON_MAP[roomType]]} />;
    }
    else {
        let boxIcon = props.housing.type === AdvertType.house ? AdvertType.house : ADVERT_SUB_TYPE_MAP[props.housing.type];
        icon = <img src={"https://roomjack.blob.core.windows.net/roomjack/icons/icon_" + boxIcon + ".svg"} alt={boxIcon} />;
    }

    let onBoxClick = null;

    if (props.sortMode && props.onSortClick) {
        onBoxClick = () => {props.onSortClick(props.index);};
        classList.push("clickable");
    }
    else if (props.editable(props.housing) && props.onEdit) {
        onBoxClick = (e) => {
            e.stopPropagation();
            props.onEdit(props.housing);
        }
        classList.push("clickable");
    }

    return <div className={classList.join(" ")} onClick={onBoxClick}>
        <div className="room-box-header" title={props.title}>
            <span>{truncateText(getLanguageEntry(props.title), 15)}</span>
            {
                props.editable(props.housing) && props.onEdit && !props.sortMode &&
                <button className="edit-room-box" onClick={(e) => {
                    e.stopPropagation();
                    props.onEdit(props.housing)}
                }>
                    <FontAwesomeIcon icon={["fal", "pencil"]} />
                </button>
            }
            {
                props.deletable(props.housing.id) && !props.sortMode &&
                <button className="delete-room-box" onClick={(e) => {
                    e.stopPropagation();
                    props.onDelete(props.housing.id)}
                }>
                    <FontAwesomeIcon icon={["fal", "trash"]} />
                </button>
            }
        </div>
        <div className="room-box-body">
            <div className="icon-container">{icon}</div>
            {
                props.createContent &&
                <div className="room-box-properties">{props.createContent(props.housing)}</div>
            }
        </div>
        {
            props.showBookable && props.housing.bookable &&
            <div className="bookable-sign">
                <FontAwesomeIcon icon={["fal", "euro"]}/>
            </div>
        }
        {
            props.sortMode && props.sortNumber > -1 &&
            <div className="room-box-overlay">
                {props.sortNumber + 1}
            </div>
        }
    </div>

}
RoomBox.propTypes = {
    housing: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    deletable: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    editable: PropTypes.func.isRequired,
    createContent: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    className: PropTypes.string,
    sortMode: PropTypes.bool,
    onSortClick: PropTypes.func,
    sortNumber: PropTypes.number,
    index: PropTypes.number,
    showBookable: PropTypes.bool.isRequired,
}