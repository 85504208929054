export const RENTER_CAROUSEL = [
    {
        slogan: "landing_pages>renter>slogans>studies",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/carousel/header_studium.jpg"
    },
    {
        slogan: "landing_pages>renter>slogans>business_trip",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/carousel/header_businesstrip.jpg"
    },
    {
        slogan: "landing_pages>renter>slogans>work_week",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/carousel/header_arbeitswoche.jpg"
    },
    {
        slogan: "landing_pages>renter>slogans>job",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/carousel/header_job.jpg"
    },
    {
        slogan: "landing_pages>renter>slogans>module_week",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/carousel/header_modulwoche.jpg"
    },
    {
        slogan: "landing_pages>renter>slogans>theory_block",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/carousel/header_theorieblock.jpg"
    },
    {
        slogan: "landing_pages>renter>slogans>internship",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/carousel/header_praktikum.jpg"
    },
    {
        slogan: "landing_pages>renter>slogans>project_day",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/carousel/header_projekttage.jpg"
    },
    {
        slogan: "landing_pages>renter>slogans>homeoffice",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/carousel/header_homeoffice.jpg"
    }
]

export const RENTER_FACTS = [
    {
        title: "landing_pages>renter>hard_facts>find>title",
        content: "landing_pages>renter>hard_facts>find>content",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/landlord-landing-page/backgrounds/genau_was_du_suchst.png",
        image_alt: "landing_pages>renter>hard_facts>find>image_alt"
    },
    {
        title: "landing_pages>renter>hard_facts>booking>title",
        content: "landing_pages>renter>hard_facts>booking>content",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/hardfacts/thumbs_up.jpg",
        image_alt: "landing_pages>renter>hard_facts>booking>image_alt"
    },
    {
        title: "landing_pages>renter>hard_facts>save_money>title",
        content: "landing_pages>renter>hard_facts>save_money>content",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/hardfacts/teaser3.png",
        image_alt: "landing_pages>renter>hard_facts>save_money>image_alt"
    }
]

export const RENTER_DESTINATIONS = [
    {
        city_name: "Wien",
        position: { lat: 48.20849, lng: 16.37208 },
        image: "wien.png"
    },
    {
        city_name: "Linz",
        position: { lat: 48.30639, lng: 14.28611 },
        image: "linz.png"
    },
    {
        city_name: "Salzburg",
        position: { lat: 47.79941, lng: 13.04399 },
        image: "salzburg.png"
    },
    {
        city_name: "Graz",
        position: { lat: 47.06667, lng: 15.45 },
        image: "graz.png"
    },
    {
        city_name: "Klagenfurt",
        position: { lat: 46.62472, lng: 14.30528 },
        image: "klagenfurt.png"
    },
    {
        city_name: "Eisenstadt",
        position: { lat: 47.84565, lng: 16.52327 },
        image: "eisenstadt.png"
    },
    {
        city_name: "St. Pölten",
        position: { lat: 48.2, lng: 15.63333 },
        image: "stpoelten.png"
    },
    {
        city_name: "Bregenz",
        position: { lat: 47.50311, lng: 9.7471 },
        image: "bregenz.png"
    },
    {
        city_name: "Innsbruck",
        position: { lat: 47.26266, lng: 11.39454 },
        image: "innsbruck.png"
    },
]

export const RENTER_SPECIAL_SEARCH_CATEGORIES = [
    {
        name: "barrier_free",
        characteristics: [ 'barrier_free' ],
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/special_search/room_barrierefrei.png",
    },
    {
        name: "garden",
        characteristics: [ 'garden', 'garden_cottage', 'pool', 'terrace' ],
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/special_search/room_garten.png",
    },
    {
        name: "silent_place",
        characteristics: [ 'quiet_location' ],
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/special_search/ruhige_lage.jpg",
    },
    {
        name: "city_centre",
        characteristics: [ 'central_location' ],
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/special_search/stadtzentrum.jpg",
    },
    {
        name: "relaxing",
        characteristics: [ 'garden', 'pool', 'sauna', 'terrace', 'quiet_location' ],
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/special_search/relaxen.jpg",
    },
    {
        name: "streaming",
        characteristics: [ 'highspeed_internet', 'internet', 'amazon_prime', 'dazn', 'disney', 'netflix', 'sky_x' ],
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/special_search/streaming.jpg",
    },
    {
        name: "cool",
        characteristics: [ 'room_ventilation', 'air_conditioning', 'pool' ],
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/special_search/coole_koepfe.jpg",
    },
    {
        name: "environment",
        characteristics: [ 'climate_friendly_construction', 'green_heating', 'green_electricity' ],
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/special_search/umweltbewusste.jpg",
    },
    {
        name: "shopping",
        characteristics: [ 'good_shopping' ],
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/special_search/shoppingverrueckte.jpg",
    },
]

export const LANDLORD_CAROUSEL = [
    {
        slogan: "landing_pages>landlord>slogans>rent>header",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/carousel/header_studium.jpg"
    },
    {
        slogan: "landing_pages>landlord>slogans>time>header",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/carousel/header_businesstrip.jpg"
    },
    {
        slogan: "landing_pages>landlord>slogans>overview>header",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/carousel/header_arbeitswoche.jpg"
    },
    {
        slogan: "landing_pages>landlord>slogans>responsible>header",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/carousel/header_job.jpg"
    },
]

export const LANDLORD_FUNCTIONS = [
    {
        icon: ["fal", "chart-tree-map"],
        title: "landing_pages>landlord>functions>items>0>title",
        content : "landing_pages>landlord>functions>items>0>content"
    },
    /*{
        icon: "fa-file-contract",
        title: "Digitale Mietverträge",
        content : "Unser integriertes Mietvertrags-Tool entspricht der EU-eIDAS Verordnung und erstellt qualifizierte elektronische Signaturen, die im Rechtsverkehr der handschriftlichen Signatur gleichzusetzen sind."
    },*/
    {
        icon: ["fal", "users-viewfinder"],
        title: "landing_pages>landlord>functions>items>1>title",
        content : "landing_pages>landlord>functions>items>1>content"
    },
    /*{
        icon: "fa-users-viewfinder",
        title: "Open-immo Schnittstelle",
        content : "Für gewerbliche Nutzer bieten wir entsprechende Schnittstellen an, um individuelle Software an roomjack anzukoppeln."
    },*/
    /*{
        icon: "fa-message-bot",
        title: "Automatische Nachrichten",
        content : "Perfektioniere dein Gästeerlebnis indem du automatische Nachrichten an deine Gäste versendest, um sie zu gewissen Zeitpunkten des Aufenthalts auf bestimmte Themen hinzuweisen."
    },*/
    {
        icon: ["fal", "credit-card"],
        title: "landing_pages>landlord>functions>items>2>title",
        content : "landing_pages>landlord>functions>items>2>content"
    },
    /*{
        icon: "fa-users-viewfinder",
        title: "Aufgabenübersicht",
        content: "Behalte deine ToDo’s für deine Jack-ins und Jack-outs im Auge, delegiere Aufgaben und erstelle Aufgabenlisten für deine Helfer und Mitarbeiter."
    }*/
]

export const LANDLORD_FEATURES = [
    {
        header: "landing_pages>landlord>features>items>0>header",
        content : "landing_pages>landlord>features>items>0>description",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/landlord-landing-page/features/kundengruppen[21706].png",
        image_alt: "landing_pages>landlord>features>items>0>image_alt"
    },
    {
        header: "landing_pages>landlord>features>items>1>header",
        content : "landing_pages>landlord>features>items>1>description",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/landlord-landing-page/features/superhost[21707].png",
        image_alt: "landing_pages>landlord>features>items>1>image_alt",
        links: [
            {
                text_path: "general>here",
                url: "/content/b2b"
            }
        ]
    },
    {
        header: "landing_pages>landlord>features>items>2>header",
        content : "landing_pages>landlord>features>items>2>description",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/landlord-landing-page/features/fuer_dich_da[21705].png",
        image_alt: "landing_pages>landlord>features>items>2>image_alt"
    }
]

export const LANDLORD_TESTIMONIALS = [
    {
        name: "Robert, 24, Linz",
        text: "landing_pages>landlord>testimonials>0>text",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/landlord-landing-page/carousel/student_in_classroom.jpeg",
        image_alt: "landing_pages>landlord>testimonials>0>image_alt",
    },
    {
        name: "Edith, 65, Wien",
        text: "landing_pages>landlord>testimonials>1>text",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/landlord-landing-page/carousel/old_woman_smiling.jpeg",
        image_alt: "landing_pages>landlord>testimonials>1>image_alt",
    },
    {
        name: "Sabine, 31 ,Salzburg",
        text: "landing_pages>landlord>testimonials>2>text",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/landlord-landing-page/carousel/woman_with_laptop.jpg",
        image_alt: "landing_pages>landlord>testimonials>2>image_alt",
    },
    {
        name: "Deb, 27, St. Pölten",
        text: "landing_pages>landlord>testimonials>3>text",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/landlord-landing-page/carousel/woman_in_kitchen.jpeg",
        image_alt: "landing_pages>landlord>testimonials>3>image_alt",
    },
    {
        name: "Alex, 36, Graz",
        text: "landing_pages>landlord>testimonials>4>text",
        image_url: "https://roomjack.blob.core.windows.net/roomjack/landlord-landing-page/carousel/man_relaxing_on_sofa.jpeg",
        image_alt: "landing_pages>landlord>testimonials>4>image_alt",
    }
]

export const NEWS = {
    shared: [
        {
            image_url: "url(\"https://roomjack.blob.core.windows.net/roomjack/content-images/heidenheim/Titelbild Heidneheim.jpeg\")",
            image_alt: "Heidenheim an der Brenz",
            date: new Date(),
            title: "landing_pages>shared_content>news>heidenheim>title",
            description: "landing_pages>shared_content>news>heidenheim>description",
            content_page: "heidenheim",
            url: "/content/heidenheim"
        },
        {
            image_url: "url(\"https://roomjack.blob.core.windows.net/roomjack/content-images/horb_am_neckar/Titelbild Horb.jpeg\")",
            image_alt: "Horb am Neckar",
            date: new Date(),
            title: "landing_pages>shared_content>news>horb>title",
            description: "landing_pages>shared_content>news>horb>description",
            content_page: "horb",
            url: "/content/horb-am-neckar"
        }
    ],
    renter: [

    ],
    landlord: [

    ]
}