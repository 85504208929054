import React from "react";
import {
    getCountLabel,
    getLanguageEntry,
    getTimeUnitInMS,
    millisecondsToClockTime,
    TIME_UNIT_VALUE_MAP
} from "../../utils/Helper";
import Dropdown from "../input/Dropdown";
import UnitInputField from "../input/UnitInputField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import {UnitType} from "../../utils/Types.ts";

const LANG_PATH = "jackboard>adverts>advert_creator>";

class CancellationConditionEditor extends React.Component {

    constructor(props) {
        super(props);
        this.items = [];
        for (let i=0; i<91; i++) {
            this.items.push({ value: i, label: () => this.getDayText(i)})
        }
        this.clockTimes = Array.from(Array(48),
            (_, i) => i * getTimeUnitInMS(30, TIME_UNIT_VALUE_MAP.MINUTE))
            .map((time, _) => {
                return { value: time, label: millisecondsToClockTime(time)}
            });
        this.form = React.createRef();
        this.state = {
            edit_mode: !props.data
        }
    }

    render() {
        if (this.state.edit_mode) {
            return (
                <form className="horizontal-form-group" ref={this.form}
                      onSubmit={(e) => { this.extractData(e) }}>
                    <span className="description-container">
                        {getLanguageEntry("general>from")}
                    </span>
                    <Dropdown items={this.items} required={true} name="days"
                              initialValue={this.props.data ? this.props.data.days : null} />
                    <span className="description-container">
                        {getLanguageEntry(LANG_PATH + "cancellation_conditions>at_time")}
                    </span>
                    <Dropdown items={this.clockTimes} name="clock_time" required={true}
                              initialValue={this.props.data ? this.props.data.clock_time : null} />
                    <span className="description-container">
                        {getLanguageEntry(LANG_PATH + "cancellation_conditions>before_start_of_rental")}
                    </span>
                    <UnitInputField type={UnitType.percent} name="percent" required={true}
                                    initialValue={this.props.data ? this.props.data.percent : null}/>
                    <span className="description-container">
                        {getLanguageEntry(LANG_PATH + "cancellation_conditions>cancellation_costs")}
                    </span>
                    <button className="simple-icon-button border" type="submit" style={{marginLeft: "auto"}}>
                        <FontAwesomeIcon icon={["fal", "save"]}/>
                    </button>
                    <button className="simple-icon-button border" onClick={() => {this.props.onDelete()}} >
                        <FontAwesomeIcon icon={["fal", this.props.data ? "trash" : "times"]}/>
                    </button>
                </form>
            )
        }
        return (
            <div className="horizontal-form-group">
                <span className="description-container">{this.getText()}</span>
                <button className="simple-icon-button border" onClick={() => {this.setState({edit_mode: true})}}
                        style={{marginLeft: "auto"}}>
                    <FontAwesomeIcon icon={["fal", "pencil"]}/>
                </button>
                <button className="simple-icon-button border" onClick={() => {this.props.onDelete()}}>
                    <FontAwesomeIcon icon={["fal", this.props.data ? "trash" : "times"]}/>
                </button>
            </div>
        )
    }

    getDayText(day) {
        let text;
        if (day === 0) {
            text = getLanguageEntry(LANG_PATH + "cancellation_conditions>day_of_arrival");
        }
        else {
            text = getCountLabel(day, "day") + " " + getLanguageEntry(LANG_PATH + "cancellation_conditions>before_arrival");
        }
        return text;
    }

    getText() {
        return getLanguageEntry("general>from") + " " +
            this.getDayText(this.props.data.days) + " " +
            getLanguageEntry(LANG_PATH + "cancellation_conditions>at_time") + " " +
            millisecondsToClockTime(this.props.data.clock_time) + " " +
            getLanguageEntry(LANG_PATH + "cancellation_conditions>before_start_of_rental") + " " +
            this.props.data.percent + "% " + getLanguageEntry(LANG_PATH + "cancellation_conditions>cancellation_costs");
    }

    extractData(e) {
        e.preventDefault();
        let formData = new FormData(this.form.current);
        this.props.onSave({
            days: parseInt(formData.get("days")),
            percent: parseInt(formData.get("percent")),
            clock_time: parseInt(formData.get("clock_time")),
        });
    }

}
CancellationConditionEditor.propTypes = {
    data: PropTypes.any,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
}
export default CancellationConditionEditor;