import {FAQ_HELP, TIPS_TRICKS} from "../../js/utils/Helper";

export const ROOMJACK_IS_GREEN = {
    parallax_background: "https://roomjack.blob.core.windows.net/roomjack/content-images/landscape.jpg",
    title: "content_pages>roomjack_is_green>title",
    header: "content_pages>roomjack_is_green>header",
    features: [
        {
            text: "content_pages>roomjack_is_green>features>0>text",
            url: "https://roomjack.blob.core.windows.net/roomjack/content-images/balkon.jpg",
            alt: "content_pages>roomjack_is_green>features>0>image_alt"
        },
        {
            text: "content_pages>roomjack_is_green>features>1>text",
            url: "https://roomjack.blob.core.windows.net/roomjack/content-images/green/ruhige_lage.jpg",
            alt: "content_pages>roomjack_is_green>features>1>image_alt"
        }
    ],
    paragraphs: [
        "content_pages>roomjack_is_green>paragraphs>0"
    ]
}
export const RENT_RESPONSIBLE = {
     features : [
        {
            url: "https://roomjack.blob.core.windows.net/roomjack/content-images/verantwortungsvoll_vermieten/bild1.jpg",
            alt: "Wohnung vermieten ist ein komplexes Thema"
        },
        {
            url: "https://roomjack.blob.core.windows.net/roomjack/content-images/verantwortungsvoll_vermieten/bild7.jpg",
            alt: "Fakten überprüfen"
        },
        {
            url: "https://roomjack.blob.core.windows.net/roomjack/content-images/verantwortungsvoll_vermieten/bild4.jpg",
            alt: "Vermieten mit Gewerbeberechtigung"
        },
        {
            url: "https://roomjack.blob.core.windows.net/roomjack/content-images/verantwortungsvoll_vermieten/bild6.jpg",
            alt: "Wie Zimmer vermieten"
        },
        {
            url: "https://roomjack.blob.core.windows.net/roomjack/content-images/verantwortungsvoll_vermieten/bild3.jpg",
            alt: "Wohnraumvermietung unterliegt dem Meldegesetz"
        },
        {
            url: "https://roomjack.blob.core.windows.net/roomjack/content-images/verantwortungsvoll_vermieten/bild2.jpg",
            alt: "Vermietung mit einem Steuerberater abklären"
        },
        {
            url: "https://roomjack.blob.core.windows.net/roomjack/content-images/verantwortungsvoll_vermieten/bild8.jpg",
            alt: "Mietverträge genau überprüfen"
        }
    ],
}
export const B2B = {
    features : [
        {
            url: "https://roomjack.blob.core.windows.net/roomjack/b2b-landing-page/b2b_img_2.png",
            alt:"PH",
        },
        {
            url:"https://roomjack.blob.core.windows.net/roomjack/b2b-landing-page/b2b_img_3.png",
            alt:"PH",
        },
        {
            url:"https://roomjack.blob.core.windows.net/roomjack/b2b-landing-page/b2b_img_1.png",
            alt:"PH",
        },
        {
            url:"https://roomjack.blob.core.windows.net/roomjack/b2b-landing-page/b2b_img_4.png",
            alt:"PH",
        }
    ]

}
export const HELP_PAGES = [
    {
        id: "faq-and-help",
        url: "/faq-help",
        title: "help_pages>faq_help>title",
        image: {
            url: "https://roomjack.blob.core.windows.net/roomjack/email-images/think.png",
            alt: "FAQ and help"
        },
        categories: [
            {
                id: "general",
                title: "help_pages>general"
            },
            {
                id: "renter",
                title: "main_banner>mode_toggle>renter"
            },
            {
                id: "landlord",
                title: "main_banner>mode_toggle>landlord"
            },
        ],
        getData: function() {
            return FAQ_HELP;
        }
    },
    {
        id: "tips-and-tricks",
        url: "/tips-tricks",
        title: "help_pages>tips_tricks>title",
        image: {
            url: "https://roomjack.blob.core.windows.net/roomjack/landlord-landing-page/carousel/verantwortungsvoll_vermieten.png",
            alt: "Tips and tricks"
        },
        categories: [
            {
                id: "renter",
                title: "help_pages>tips_tricks>categories>renter"
            },
            {
                id: "landlord",
                title: "help_pages>tips_tricks>categories>landlord"
            }
        ],
        getData: function() {
            return TIPS_TRICKS;
        }
    }
]
export const LINZ = {
    parallax_background: "https://roomjack.blob.core.windows.net/roomjack/content-images/linz/Panorama Linz2©Linz Tourismus_Benjamin Oberneder.jpg",
    parallax_alt: "Linz Panorama",
    parallax_copyright: "Benjamin Oberneder",
    features: [
        {
            url: "https://roomjack.blob.core.windows.net/roomjack/content-images/linz/Familienshooting5©Linz Tourismus_Robert Josipovic.jpg",
            alt: "",
            copyright: "Robert Josipovic",
            link: [{url: "https://www.tworabbitslinz.com/",
                text_path: "content_pages>linz>to_page", rel: "noreferrer nofollow", target: "_blank"}]
        },
        {
            url: "https://roomjack.blob.core.windows.net/roomjack/content-images/linz/Nibelungenbrücke_Donau1©Linz Tourismus_Robert Josipovic.jpg",
            alt: "",
            copyright: "Robert Josipovic",
            link: [{url: "https://www.tworabbitslinz.com/",
                text_path: "content_pages>linz>to_page", rel: "noreferrer nofollow", target: "_blank"}]
        },
        {
            url: "https://roomjack.blob.core.windows.net/roomjack/content-images/linz/AEC_0035©Linz Tourismus_E.Stephan.jpg",
            alt: "",
            copyright: "E. Stephan",
            link: [{url: "https://www.tworabbitslinz.com/",
                text_path: "content_pages>linz>to_page", rel: "noreferrer nofollow", target: "_blank"}]
        },
        {
            url: "https://roomjack.blob.core.windows.net/roomjack/content-images/linz/Luftaufnahme Linz 10©Linz Tourismus_Robert Josipovic.jpg",
            alt: "",
            copyright: "Robert Josipovic",
            link: []
        },
        {
            url: "https://roomjack.blob.core.windows.net/roomjack/content-images/linz/Hauptplatz©Linz Tourismus_Robert Josipovic.jpg",
            alt: "",
            copyright: "Robert Josipovic",
            link: []
        },
    ],
    closing_block: [
        {
            text_path: "visitlinz.at",
            url: "https://www.visitlinz.at",
            rel: "noreferrer nofollow",
            target: "_blank"
        },
        {
            text_path: "linztourismus.at",
            url: "https://www.linztourismus.at",
            rel: "noreferrer nofollow",
            target: "_blank"
        }
    ]

}
export const HEIDENHEIM = {
    parallax_background: "https://roomjack.blob.core.windows.net/roomjack/content-images/heidenheim/Titelbild Heidneheim.jpeg",
    parallax_alt: "Heidenheim an der Brenz",
    paragraphs: [
        "content_pages>heidenheim>paragraphs>0",
        "content_pages>heidenheim>paragraphs>1",
        "content_pages>heidenheim>paragraphs>2"
    ],
    features: [
        {
            url: "https://roomjack.blob.core.windows.net/roomjack/content-images/heidenheim/Schloss Heidneheim.jpeg",
            alt: "Schloss Hellenstein, Heidenheim",
            link: [{
                url: "https://www.tourismus-heidenheim.de/schlossberg/informationen+schloss+hellenstein",
                text_path: "Schloss Hellenstein",
                rel: "noreferrer nofollow",
                target: "_blank"
            }]
        },
        {
            url: "https://roomjack.blob.core.windows.net/roomjack/content-images/heidenheim/Brenzpark.jpeg",
            alt: "Brenzpark, Heidenheim",
            link: [{
                url: "https://www.brenzpark.de/startseite",
                text_path: "Brenzpark",
                rel: "noreferrer nofollow",
                target: "_blank"
            }]
        },
        {
            url: "https://roomjack.blob.core.windows.net/roomjack/content-images/heidenheim/Stadion Heidenheim.jpeg",
            alt: "Voith-Arena, Heidenheim",
            link: [
            {
                url: "https://www.fc-heidenheim.de",
                text_path: "1. FC Heidenheim",
                rel: "noreferrer nofollow",
                target: "_blank"
            },
            {
                url: "https://www.voith-arena.de/startseite.html",
                text_path: "Voith-Arena",
                rel: "noreferrer nofollow",
                target: "_blank"
            }]
        },
        {
            link: [{
                url: "https://www.heidekoepfe.de",
                text_path: "Heidenheim Heideköpfe",
                rel: "noreferrer nofollow",
                target: "_blank"
            }]
        },
        {
            link: [{
                url: "https://www.opernfestspiele.de",
                text_path: "Opernfestspiele",
                rel: "noreferrer nofollow",
                target: "_blank"
            }]
        }
    ],
    closing_paragraphs: [
        "content_pages>heidenheim>closing_paragraphs>0",
        "content_pages>heidenheim>closing_paragraphs>1"
    ]
}
export const HORB = {
    parallax_background: "https://roomjack.blob.core.windows.net/roomjack/content-images/horb_am_neckar/Titelbild Horb.jpeg",
    parallax_alt: "Horb am Neckar",
    parallax_copyright: "?",
    paragraphs: [
        "content_pages>horb>paragraphs>0",
        "content_pages>horb>paragraphs>1",
        "content_pages>horb>paragraphs>2"
    ],
    features: [
        {
            url: "https://roomjack.blob.core.windows.net/roomjack/content-images/horb_am_neckar/Horber Schloss.jpeg",
            alt: "Schloss, Horb",
            link: []
        },
        {
            url: "https://roomjack.blob.core.windows.net/roomjack/content-images/horb_am_neckar/Neckarpromenade.jpeg",
            alt: "Neckarpromenade",
            link: []
        },
        {
            url: "https://roomjack.blob.core.windows.net/roomjack/content-images/horb_am_neckar/Horb Altstadt.jpeg",
            alt: "Altstadt, Horb",
            link: []
        },
        {
            url: "https://roomjack.blob.core.windows.net/roomjack/content-images/horb_am_neckar/Horb Museum.jpeg",
            alt: "SchieferErlebnis",
            link: []
        }
    ],
    closing_paragraphs: [
        "content_pages>horb>closing_paragraphs>0",
        "content_pages>horb>closing_paragraphs>1"
    ]
}