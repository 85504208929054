import React from "react";
import PropTypes from "prop-types";
import {
    calculateFee,
    calculateInvoiceFromObject,
    calculateTax,
    getCountLabel,
    getFormattedDate,
    getLanguageEntry,
    priceToString
} from "../../utils/Helper";
import parse from "html-react-parser";
import _uniqueId from "lodash/uniqueId";
import {PREDEFINED_FEE_TYPES, PREDEFINED_LONG_TERM_FEE_TYPES} from "../../../resources/housings";

const LANG_PATH = "components>invoicing>";
class GuestInvoice extends React.Component {

    render() {
        let guest = this.props.userData.id === this.props.booking.booker ?
            this.props.userData : this.props.booking.booker;
        let landlord = this.props.userData.id === this.props.booking.owner ?
            this.props.userData : this.props.booking.owner;
        let bookingStart = getFormattedDate(new Date(this.props.booking.start), false, false, false, true, false, false);
        let bookingEnd = getFormattedDate(new Date(this.props.booking.end), false, false, false, true, false, false);
        let bookingDate = getFormattedDate(new Date(this.props.booking.booking_ts), false, false, false, true, false, false);
        let invoiceData = calculateInvoiceFromObject(this.props.booking);
        let valueAddedTaxSum = 0; let ratio = 1; let dailyGross = invoiceData.subtotal;
        let taxPercent = 0; let taxValue = 0; let position = 1;
        if (!this.props.booking.cancelled) {
            ratio = this.props.charge.amount / invoiceData.total_with_discount;
        }
        return <div className="page">
            <div className="invoice-body">
                <div className="invoice-header">
                    <div className="invoice-header-left-col">
                        <div className="landlord-data">
                            {
                                landlord.b2b && landlord.company && landlord.company !== "-" &&
                                <p>{landlord.company}</p>
                            }
                            {
                                (!landlord.b2b || !landlord.company || landlord.company === "-") &&
                                <p>
                                    {landlord.first_name + " " + landlord.last_name}
                                </p>
                            }
                            {
                                landlord.address && landlord.address.line_1 &&
                                <p>
                                    {landlord.address.line_1}
                                </p>
                            }
                            {
                                landlord.address && landlord.address.postal_code &&
                                landlord.address.city && landlord.address.country &&
                                <p>
                                    {
                                        landlord.address.postal_code + " " +
                                        landlord.address.city + ", " +
                                        landlord.address.country
                                    }
                                </p>
                            }
                            <p className="landlord-tax-id"></p>
                        </div>
                        <div className="booker-data">
                            <p className="booker-name">
                                {guest.first_name + " " + guest.last_name}
                            </p>
                            {
                                guest.address && guest.address.line_1 &&
                                <p>
                                    {guest.address.line_1}
                                </p>
                            }
                            {
                                guest.address && guest.address.postal_code &&
                                guest.address.city && guest.address.country &&
                                <p>
                                    {
                                        guest.address.postal_code + " " +
                                        guest.address.city + ", " +
                                        guest.address.country
                                    }
                                </p>
                            }
                        </div>
                    </div>
                    <div className="invoice-header-right-col">
                        <div className="invoice-data">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            {getLanguageEntry(LANG_PATH + "invoice_number_short")}:
                                        </td>
                                        <td>
                                            {this.props.booking.landlord_invoice_no}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {getLanguageEntry(LANG_PATH + "invoice_date")}:
                                        </td>
                                        <td>
                                            {bookingEnd}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {getLanguageEntry(LANG_PATH + "advert_id")}:
                                        </td>
                                        <td>
                                            {this.props.booking.advert_id}
                                        </td>
                                    </tr>
                                    {
                                        this.props.booking.reg_no &&
                                        <tr>
                                            <td>
                                                {getLanguageEntry(LANG_PATH + "registration_number_short")}:
                                            </td>
                                            <td>
                                                {this.props.booking.reg_no}
                                            </td>
                                        </tr>
                                    }
                                    <tr>
                                        <td>
                                            {getLanguageEntry("general>booking_code")}:
                                        </td>
                                        <td>
                                            {this.props.booking.confirmation_code}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {getLanguageEntry("general>booking_date")}:
                                        </td>
                                        <td>
                                            {bookingDate}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {getLanguageEntry("general>booking_start")}:
                                        </td>
                                        <td>
                                            {bookingStart}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {getLanguageEntry("general>booking_end")}:
                                        </td>
                                        <td>
                                            {bookingEnd}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="invoice-content">
                    <div className="invoice-title">
                        {getLanguageEntry(LANG_PATH + "invoice") + " " +
                            getLanguageEntry(LANG_PATH + "number_short") + ": " +
                            this.props.booking.landlord_invoice_no}
                    </div>
                    <table className="invoice-detail-table">
                        <tbody>
                            <tr className="table-header bottom-padding">
                                <th>{getLanguageEntry(LANG_PATH + "position_short")}</th>
                                <th>{getLanguageEntry(LANG_PATH + "invoice_term")}</th>
                                <th>{getLanguageEntry(LANG_PATH + "count")}</th>
                                <th>{getLanguageEntry(LANG_PATH + "discount")}</th>
                                <th>{getLanguageEntry(LANG_PATH + "net")}</th>
                                <th>{getLanguageEntry(LANG_PATH + "value_added_tax_short")}</th>
                                <th>{getLanguageEntry(LANG_PATH + "gross")}</th>
                            </tr>
                            {
                                this.props.booking.cancelled &&
                                <tr>
                                    <td>{position}</td>
                                    <td>
                                        {
                                            parse(getLanguageEntry(LANG_PATH + "guest_invoice_cancellation_term")
                                                .replace("[advertTitle]", this.props.booking.title)
                                                .replace("[advertID]", this.props.booking.advert_id)
                                                .replace("[regNo]", this.props.booking.reg_no ?? '-'))
                                        }
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>{priceToString(this.props.charge.amount)}</td>
                                    <td></td>
                                    <td>{priceToString(this.props.charge.amount)}</td>
                                </tr>
                            }
                            {
                                this.props.booking.cancelled &&
                                <tr className="table-sum bottom-padding">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{priceToString(this.props.charge.amount)}</td>
                                    <td>{/*TODO*/}</td>
                                    <td>{priceToString(this.props.charge.amount)}</td>
                                </tr>
                            }
                            {
                                !this.props.booking.cancelled &&
                                <tr>
                                    <td>{position++}</td>
                                    <td>
                                        {
                                            parse(getLanguageEntry(LANG_PATH + "guest_invoice_term")
                                                .replace("[advertTitle]", this.props.booking.title)
                                                .replace("[advertID]", this.props.booking.advert_id)
                                                .replace("[regNo]", this.props.booking.reg_no ?? '-'))
                                        }
                                    </td>
                                    <td>
                                        <span>{getCountLabel(invoiceData.day_count, "night")}</span>
                                    </td>
                                    <td>{priceToString(invoiceData.discounts)}</td>
                                    <td>{priceToString(invoiceData.subtotal * ratio)}</td>
                                    <td>
                                        {
                                            taxPercent > 0 &&
                                            taxPercent + "% (" + priceToString(taxValue * ratio) + ")"
                                        }
                                    </td>
                                    <td>{priceToString(dailyGross * ratio)}</td>
                                </tr>
                            }
                            {
                                invoiceData.additional_fee_sum > 0 &&
                                this.props.booking.invoice_data.pricing.additional_fees
                                    .filter(f => invoiceData.additional_fees[f.name] !== undefined)
                                    .map((f, i) => {
                                        let feeNet = calculateFee(invoiceData.day_count, f);
                                        let feeTaxPercent = 0;
                                        let feeTaxValue = 0;
                                        if (this.props.booking.invoice_data.pricing.taxes !== undefined) {
                                            let tax = this.props.booking.invoice_data.pricing.taxes
                                                .filter(t => t.target_fee_name === f && t.type === 'value_added_tax')[0];
                                            if (tax !== undefined) {
                                                feeTaxPercent = tax.value;
                                                feeTaxValue = calculateTax(tax, invoiceData.day_count,
                                                    0, this.props.booking.invoice_data.pricing.additional_fees);
                                            }
                                        }
                                        return <tr key={_uniqueId(f.name + "-" + i)}>
                                            <td>{position++}</td>
                                            <td>
                                                {
                                                    PREDEFINED_FEE_TYPES[f.name] || PREDEFINED_LONG_TERM_FEE_TYPES[f.name] ?
                                                    getLanguageEntry(`jackboard>adverts>advert_creator>pricing>additional_fees>types>${f.name}`) : f.name
                                                }
                                            </td>
                                            <td>1</td>
                                            <td>{priceToString(feeNet * ratio)}</td>
                                            <td>
                                                {
                                                    feeTaxPercent > 0 &&
                                                    feeTaxPercent + "% (" + priceToString(feeTaxValue * ratio) + ")"
                                                }
                                            </td>
                                            <td>{priceToString((feeNet + feeTaxValue) * ratio)}</td>
                                        </tr>
                                    })
                            }
                            {
                                !this.props.booking.cancelled &&
                                <tr className="table-sum bottom-padding">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{priceToString((invoiceData.total_with_discount - valueAddedTaxSum) * ratio)}</td>
                                    <td>
                                        {
                                            valueAddedTaxSum > 0 &&
                                            getLanguageEntry(LANG_PATH + "value_added_tax_short") + " " +
                                            priceToString(valueAddedTaxSum * ratio)
                                        }
                                    </td>
                                    <td>{priceToString(this.props.charge.amount)}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <div className="notice-container">
                        {
                            this.props.booking.invoice_data.pricing.small_business_regulation &&
                            <div>{getLanguageEntry(LANG_PATH + "small_business_regulation")}</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    }

}

GuestInvoice.propTypes = {
    booking: PropTypes.any.isRequired,
    charge: PropTypes.any.isRequired,
    userData: PropTypes.any.isRequired
}
export default GuestInvoice;