import React from "react";
import Loading from "../../../../components/Loading";
import {ADVERT_CACHE} from "../../../../utils/CacheHandler.ts";
import {getAdverts, getSessionPaymentIntent} from "../../../../utils/RESTInterface";
import {
    getLanguageEntry,
    getTimeUnitInMS,
    SUPPORTED_PAYMENT_GATEWAYS,
    TIME_UNIT_VALUE_MAP
} from "../../../../utils/Helper";
import PropTypes from "prop-types";
import NavigatorButton from "../../../../components/buttons/NavigatorButton";
import HousingPreview, {PREVIEW_TYPES} from "../../../../components/HousingPreview";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AdvertDeletionModal from "../../../../components/modals/AdvertDeletionModal";
import ReportModal from "../../../../components/modals/ReportModal";
import {ReportReason} from "../../../../utils/Types.ts";
import AdvertDuplicationModal from "../../../../components/modals/AdvertDuplicationModal";
import AdvertPublicationModal from "../../../../components/modals/AdvertPublicationModal";
import ProToolModal from "../../../../components/modals/ProToolModal";
import BoosterModal from "../../../../components/modals/BoosterModal";
import {STRIPE} from "../../../../utils/StripeHelper.ts";

function AdvertEditorContainer(props) {

    return <div className="advert-editor-container">
        <div className="controls left">
            {/*{
                props.advert.published &&
                <button className="booster" onClick={() => {props.onBuyBooster(props.advert)}}>
                    <FontAwesomeIcon icon={["fal", "circle-up"]} />
                    {getLanguageEntry("jackboard>adverts>advert_inspector>boost")}
                </button>
            }*/}
            {
                props.advert.published && props.userData.b2b &&
                <button className="duplication" onClick={() => {props.onDuplicateAdvert(props.advert)}}>
                    <FontAwesomeIcon icon={["fal", "copy"]} />
                    {getLanguageEntry("jackboard>adverts>advert_inspector>duplicate")}
                </button>
            }
            {
                props.advert.published && !props.userData.b2b &&
                <button className="pro-tool" onClick={() => { props.onChangeProToolStatus(props.advert) } }>
                    <FontAwesomeIcon icon={["fal", "crown"]} />
                    {getLanguageEntry("jackboard>adverts>advert_inspector>" + (props.advert.pro_tool ? "de" : "") + "activate_pro")}
                </button>
            }
        </div>
        <HousingPreview advert={props.advert} userData={props.userData}
                        type={PREVIEW_TYPES.overview} displayLastEdit={true} />
        <div className="controls right">
            <button className="delete" onClick={() => { props.onDeleteAdvert(props.advert) } }>
                <FontAwesomeIcon icon={["fal", "times-circle"]} />
                {getLanguageEntry("general>delete")}
            </button>
            <NavigatorButton to={"/desktop/adverts/editor/location?id=" + props.advert.id} className="edit">
                <FontAwesomeIcon icon={["fal", "pencil"]} />
                {getLanguageEntry("general>edit")}
            </NavigatorButton>
            <button className={props.advert.published ? "deactivate" : "activate"}
                    onClick={() => {props.onPublishAdvert(props.advert)}}>
                <FontAwesomeIcon icon={["fal", props.advert.published ? "file-times" : "globe"]} />
                {getLanguageEntry("jackboard>adverts>advert_inspector>" +
                    (props.advert.published ? "deactivate" : "publish"))}
            </button>
        </div>
    </div>

}
AdvertEditorContainer.propTypes = {
    userData: PropTypes.any.isRequired,
    advert: PropTypes.any.isRequired,
    onDeleteAdvert: PropTypes.func.isRequired,
    onPublishAdvert: PropTypes.func.isRequired,
    onDuplicateAdvert: PropTypes.func,
    onChangeProToolStatus: PropTypes.func,
    //onBuyBooster: PropTypes.func
}

class AdvertInspector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            adverts: []
        }
        this.advert_deletion = React.createRef();
        this.advert_duplication = React.createRef();
        this.advert_publication = React.createRef();
        this.pro_tool_modal = React.createRef();
        this.report_modal = React.createRef();
        //this.booster_modal = React.createRef();
    }

    componentDidMount() {
        this.updateAdverts();
    }

    /*componentDidUpdate(prevProps, prevState, snapshot) {
        if ((!prevProps.userData && this.props.userData) ||
            (prevState.adverts.length === 0 && this.state.adverts.length > 0)) {
            let params = new URLSearchParams(window.location.search);
            let advert;
            if (params.has("advert")) {
                advert = this.state.adverts.filter(a => a.id === params.get("advert"))[0];
            }
            let checkoutSessionType;
            if (advert && (checkoutSessionType = SUPPORTED_PAYMENT_GATEWAYS.filter(cs => params.has(cs))[0])) {
                if (params.get(checkoutSessionType).startsWith("cs_")) {
                    let checkoutSessionID = params.get(checkoutSessionType);
                    getSessionPaymentIntent(checkoutSessionID, (response) => {
                        if (response.success && response.client_secret) {
                            STRIPE.retrievePaymentIntent(response.client_secret)
                                .then((result) => {
                                    if (result.paymentIntent) {
                                        let pi = result.paymentIntent;
                                        let created = pi.created * 1000;
                                        // only if the payment intent was succeeded and is not older than 10 minutes
                                        if (created + getTimeUnitInMS(10, TIME_UNIT_VALUE_MAP.MINUTE) >= Date.now() &&
                                            pi.status === "succeeded") {
                                            this.callBoosterModal(advert, true);
                                        }
                                    }
                                    else {
                                        console.log(result.error);
                                    }
                                });
                        }
                    });
                }
            }
            if (params.size > 0) {
                window.history.replaceState(null, "", window.location.pathname);
            }
        }
    }*/

    render() {
        let publishedAdverts = this.state.adverts.filter(a => a.published);
        let unpublishedAdverts = this.state.adverts.filter(a => !a.published);
        return <div className="advert-inspector">
            {
                this.state.loading &&
                <Loading imageWidth="250px"/>
            }
            {
                !this.state.loading && publishedAdverts.length === 0 && unpublishedAdverts.length === 0 &&
                <div className="roomjack-container no-data-container">
                    <img src="https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear.png"
                         alt="No adverts" style={{objectFit: "contain"}}/>
                    <div className="description-container">
                        {getLanguageEntry("jackboard>adverts>advert_inspector>no_adverts")}
                    </div>
                    <NavigatorButton to="/desktop/adverts/editor" className="outline-button accent">
                        {getLanguageEntry("jackboard>bookings>labels>to_advert_creator")}
                    </NavigatorButton>
                </div>
            }
            {
                !this.state.loading && (publishedAdverts.length > 0 || unpublishedAdverts.length > 0) &&
                <div className="published-adverts roomjack-container">
                    <div className="roomjack-container-header">
                        {
                            getLanguageEntry("jackboard>adverts>advert_inspector>published_adverts")
                        }
                    </div>
                    {
                        publishedAdverts.length === 0 &&
                        <div className="description-container">
                            {
                                getLanguageEntry("jackboard>adverts>advert_inspector>no_published_adverts")
                            }
                        </div>
                    }
                    {
                        publishedAdverts.length > 0 &&
                        <div className="data-container">
                            {
                                publishedAdverts.map((a, i) => {
                                    return <AdvertEditorContainer advert={a} userData={this.props.userData} key={a.id}
                                                                  onDeleteAdvert={(advert) => {this.callAdvertDeletionModal(advert)}}
                                                                  onDuplicateAdvert={(advert) => {this.callAdvertDuplicationModal(advert)}}
                                                                  onPublishAdvert={(advert) => {this.callAdvertPublicationModal(advert)}}
                                                                  onChangeProToolStatus={(advert) => {this.callProToolModal(advert)}}/>
                                                                  /*onBuyBooster={(advert) => {this.callBoosterModal(advert)}}/>*/
                                })
                            }
                        </div>
                    }
                </div>
            }
            {
                !this.state.loading && (publishedAdverts.length > 0 || unpublishedAdverts.length > 0) &&
                <div className="unpublished-adverts roomjack-container">
                    <div className="roomjack-container-header">
                        {
                            getLanguageEntry("jackboard>adverts>advert_inspector>unpublished_adverts")
                        }
                    </div>
                    {
                        unpublishedAdverts.length === 0 &&
                        <div className="description-container">
                            {
                                getLanguageEntry("jackboard>adverts>advert_inspector>no_unpublished_adverts")
                            }
                        </div>
                    }
                    {
                        unpublishedAdverts.length > 0 &&
                        <div className="data-container">
                            {
                                unpublishedAdverts.map((a, i) => {
                                    return <AdvertEditorContainer advert={a} userData={this.props.userData} key={a.id}
                                                                  onDeleteAdvert={(advert) => {this.callAdvertDeletionModal(advert)}}
                                                                  onPublishAdvert={(advert) => {this.callAdvertPublicationModal(advert)}} />
                                })
                            }
                        </div>
                    }
                </div>
            }
            <AdvertDeletionModal ref={this.advert_deletion}
                                 onAdvertChanged={(data) => {this.onAdvertDeleted(data)}}
                                 onContactSupport={() => {this.callReportModal(ReportReason.bug_report)}}/>
            <AdvertDuplicationModal ref={this.advert_duplication}
                                    onAdvertDuplicated={(advert) => {this.onAdvertDuplicated(advert)}}
                                    onContactSupport={() => {this.callReportModal(ReportReason.bug_report)}}/>
            <AdvertPublicationModal ref={this.advert_publication}
                                 onAdvertChanged={() => {this.updateAdverts()}}
                                 onContactSupport={(reason) => {this.callReportModal(reason)}}/>
            <ProToolModal ref={this.pro_tool_modal} onAdvertChanged={() => {this.updateAdverts()}}
                          onContactSupport={() => {this.callReportModal(ReportReason.bug_report)}}/>
            {/*<BoosterModal ref={this.booster_modal} onContactSupport={(reason) => {this.callReportModal(reason)}}  />*/}
            <ReportModal ref={this.report_modal} userData={this.props.userData} />
        </div>
    }

    callAdvertDeletionModal(advert) {
        if (this.advert_deletion.current) {
            this.advert_deletion.current.show(advert);
        }
    }

    callAdvertDuplicationModal(advert) {
        if (this.advert_duplication.current && this.props.userData.b2b) {
            this.advert_duplication.current.show(advert);
        }
    }

    callAdvertPublicationModal(advert) {
        if (this.advert_publication.current) {
            this.advert_publication.current.show(advert);
        }
    }

    callProToolModal(advert) {
        if (this.pro_tool_modal.current) {
            this.pro_tool_modal.current.show(advert);
        }
    }

    /*callBoosterModal(advert, sessionSuccess=false) {
        if (this.booster_modal.current) {
            this.booster_modal.current.show(advert, sessionSuccess);
        }
    }*/

    callReportModal(reason) {
        if (this.report_modal.current) {
            this.report_modal.current.show(null, reason)
        }
    }

    updateAdverts() {
        if (this.props.userData.adverts) {
            let cache = ADVERT_CACHE.getCacheObjects(this.props.userData.adverts);
            if (cache.length === this.props.userData.adverts.length) {
                this.setState(
                    {adverts: cache.filter(a =>
                            (!a.delete || !a.delete.active) &&
                            (!a.blocking || !a.blocking.active))})
            }
            else {
                this.setState({loading: true});
                let that = this;
                getAdverts((response) => {
                    that.setState({
                        loading: false,
                        adverts: response.data.filter(a =>
                            (!a.delete || !a.delete.active) &&
                            (!a.blocking || !a.blocking.active))
                    });
                })
            }
        }
    }

    onAdvertDuplicated(newAdvert) {
        let user = JSON.parse(JSON.stringify(this.props.userData));
        if (!user.adverts) {
            user.adverts = [];
        }
        user.adverts.push(newAdvert.id);
        this.props.onUpdateUserData?.(user, () => {
            this.updateAdverts();
        });
    }

    onAdvertDeleted(data) {
        if (data && data.user) {
            this.props.onUpdateUserData?.(data.user, () => {
                this.updateAdverts();
            })
        }
        else {
            this.updateAdverts();
        }
    }

}
AdvertInspector.propTypes = {
    userData: PropTypes.any.isRequired,
    onUpdateUserData: PropTypes.func.isRequired
}
export default AdvertInspector;