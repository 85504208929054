import React from "react";
import PropTypes from "prop-types";
import _uniqueId from "lodash/uniqueId";
import {getLanguageEntry} from "../utils/Helper";

class FilterSelectorBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.defaultValue
        }
    }

    render() {
        return (
            <div className="interval-button-container">
                {
                    this.props.items.map((item, i) => {
                        let id = _uniqueId(item.value);
                        return <div className="accent-radio-button" key={id}>
                            <input type="radio" name={this.props.name} id={id} value={item.value}
                                   checked={this.state.value === item.value} readOnly={true}
                                   onClick={() => {
                                       this.setState({value: item.value});
                                       this.props.onChange?.(item.value)}
                            } />
                            <label htmlFor={id}>{getLanguageEntry(item.label)}</label>
                        </div>
                    })
                }
            </div>
        )
    }

}
FilterSelectorBar.propTypes = {
    items: PropTypes.arrayOf(PropTypes.exact({
        value: PropTypes.any.isRequired,
        label: PropTypes.string.isRequired,
        ignore: PropTypes.any
    })).isRequired,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.any,
    name: PropTypes.string
}
FilterSelectorBar.defaultProps = {
    name: "interval"
}
export default FilterSelectorBar;