import {AdvertType, RoomType} from "../js/utils/Types.ts";

export const ROOM_EQUIPMENT_MAP = {
    kitchen: ['oven', 'cutlery', 'bluetooth_speaker', 'ironing_board', 'iron',
        'steamer', 'dining_table', 'tv', 'fly_screen', 'freezer',
        'tableware', 'stove_top', 'induction_hob', 'coffee_machine', 'fridge',
        'microwave', 'pin_board', 'shelf', 'bench', 'kitchen_sink',
        'dishwasher', 'vacuum_cleaner', 'chairs', 'toaster', 'pots_and_pans',
        'dryer', 'water_heater', 'whiteboard', 'first_aid_kit', 'basic_cook_utils',
        'closet'],
    bathroom: ['lockable_door', 'bathtub', 'bluetooth_speaker', 'ironing_board', 'iron',
        'shower', 'fly_screen', 'hairdryer', 'clothes_rack', 'chest_of_drawers',
        'shelf', 'mirror', 'mirror_cabinet', 'toilet', 'curtains',
        'sink', 'dryer', 'clothes_drying_rack', 'first_aid_kit', 'washing_machine',
        'trash_can', 'basic_equipment', 'closet', 'fan'],
    toilet: ['lockable_door', 'fly_screen', 'shelf', 'mirror', 'mirror_cabinet',
        'toilet', 'sink', 'dryer', 'first_aid_kit', 'washing_machine',
        'trash_can', 'ventilation', 'closet'],
    bedroom: ['lockable_door', 'beamer', 'bed_90', 'bed_140', 'bluetooth_speaker',
        'ironing_board', 'iron', 'office_chair', 'couch', 'armchair',
        'couch_table', 'double_bed', 'multifunction_printer', 'bunk_bed', 'tv',
        'fly_screen', 'hairdryer', 'wardrobe', 'clothes_rack', 'clothes_rail',
        'chest_of_drawers', 'pin_board', 'shelf', 'childrens_travel_bed', 'roll_container',
        'couch_bed', 'writing_desk', 'desk_lamp', 'bench', 'mirror',
        'vacuum_cleaner', 'floor_lamp', 'chairs', 'tv_connection', 'curtains',
        'clothes_drying_rack', 'whiteboard', 'first_aid_kit', 'closet', 'nightstand',
        'safe'],
    living_room: ['lockable_door', 'beamer', 'bed_90', 'bed_140', 'bluetooth_speaker',
        'ironing_board', 'iron', 'office_chair', 'couch', 'armchair',
        'couch_table', 'double_bed', 'multifunction_printer', 'dining_table', 'tv',
        'fly_screen', 'fireplace', 'chest_of_drawers', 'pin_board', 'shelf',
        'childrens_travel_bed', 'roll_container', 'couch_bed', 'writing_desk', 'desk_lamp',
        'bench', 'vacuum_cleaner', 'floor_lamp', 'chairs', 'tv_connection',
        'curtains', 'clothes_drying_rack', 'whiteboard', 'first_aid_kit', 'closet',
        'carpet', 'safe'],
    working_room: ['lockable_door', 'beamer', 'bed_90', 'bed_140', 'bluetooth_speaker',
        'ironing_board', 'iron', 'office_chair', 'couch', 'armchair',
        'couch_table', 'double_bed', 'multifunction_printer', 'tv', 'fly_screen',
        'chest_of_drawers', 'pin_board', 'shelf', 'childrens_travel_bed', 'roll_container',
        'couch_bed', 'writing_desk', 'desk_lamp',  'bench', 'vacuum_cleaner',
        'floor_lamp', 'chairs', 'tv_connection', 'curtains', 'clothes_drying_rack',
        'whiteboard', 'first_aid_kit', 'closet'],
    childrens_room: ['lockable_door', 'beamer', 'bed_90', 'bed_140', 'bluetooth_speaker',
        'office_chair', 'couch', 'armchair', 'couch_table', 'double_bed',
        'bunk_bed', 'tv', 'fly_screen', 'crib_bed', 'wardrobe',
        'clothes_rail', 'chest_of_drawers', 'pin_board', 'shelf', 'childrens_travel_bed',
        'roll_container', 'couch_bed', 'writing_desk', 'desk_lamp', 'bench',
        'floor_lamp', 'chairs', 'tv_connection', 'curtains', 'clothes_drying_rack',
        'whiteboard', 'baby_changing_unit', 'first_aid_kit', 'closet'],
    corridor: ['ironing_board', 'iron', 'multifunction_printer', 'fly_screen', 'fireplace',
        'clothes_rack', 'pin_board', 'shelf', 'bench', 'mirror',
        'mirror_cabinet', 'vacuum_cleaner', 'floor_lamp', 'chairs', 'clothes_drying_rack',
        'whiteboard', 'first_aid_kit', 'coat_stand', 'closet'],
    other: ['cleaning_utensils', 'bbq', 'basic_equipment']
}
export const EQUIPMENT_ICON_MAP = {
    oven: ["fal", "oven"],
    cutlery: ["fal", "utensils"],
    bluetooth_speaker: ["fal", "speaker"],
    steamer: ["fal", "oven"],
    tv: ["fal", "tv"],
    freezer: ["fal", "refrigerator"],
    stove_top: ["fal", "hat-chef"],
    induction_hob: ["fal", "hat-chef"],
    coffee_machine: ["fal", "coffee-pot"],
    fridge: ["fal", "refrigerator"],
    microwave: ["fal", "microwave"],
    pin_board: ["fal", "pipe-section"],
    shelf: ["fal", "shelves"],
    kitchen_sink: ["fal", "sink"],
    sink: ["fal", "sink"],
    vacuum_cleaner: ["fal", "vacuum"],
    chairs: ["fal", "chair"],
    toaster: ["fal", "bread-slice"],
    pots_and_pans: ["fal", "mortar-pestle"],
    dryer: ["fal", "dryer"],
    whiteboard: ["fal", "chalkboard"],
    first_aid_kit: ["fal", "kit-medical"],
    lockable_door: ["fal", "lock"],
    bathtub: ["fal", "bath"],
    shower: ["fal", "shower"],
    clothes_rack: ["fal", "clothes-hanger"],
    chest_of_drawers: ["fal", "cabinet-filing"],
    toilet: ["fal", "toilet"],
    curtains: ["fal", "booth-curtain"],
    beamer: ["fal", "projector"],
    bed_90: ["fal", "bed"],
    bed_140: ["fal", "bed-front"],
    office_chair: ["fal", "chair-office"],
    couch: ["fal", "couch"],
    armchair: ["fal", "loveseat"],
    double_bed: ["fal", "bed-front"],
    multifunction_printer: ["fal", "print"],
    bunk_bed: ["fal", "bed-bunk"],
    childrens_travel_bed: ["fal", "bed-empty"],
    couch_bed: ["fal", "couch"],
    desk_lamp: ["fal", "lamp-desk"],
    floor_lamp: ["fal", "lamp-floor"],
    tv_connection: ["fal", "tv-retro"],
    fireplace: ["fal", "fireplace"],
    crib_bed: ["fal", "container-storage"],
    baby_changing_unit: ["fal", "baby"],
    washing_machine: ["fal", "washing-machine"],
    trash_can: ["fal", "trash"],
    ventilation: ["fal", "fan"],
    basic_cook_utils: ["fal", "bowl-spoon"],
    cleaning_utensils: ["fal", "vacuum"],
    bbq: ["fad", "steak"],
    basic_equipment: ["fal", "blanket"],
    writing_desk: ["fal", "lamp-desk"],
    dishwasher: ["fal", "washing-machine"],
    coat_stand: ["fal", "clothes-hanger"],
    closet: ["fal", "door-closed"],
    ironing_board: ["fal", "shirt"],
    iron: ["fal", "shirt"],
    dining_table: ["fal", "table-picnic"],
    fly_screen: ["fal", "window-frame"],
    tableware: ["fal", "utensils"],
    bench: ["fal", "bench-tree"],
    water_heater: ["fal", "heat"],
    hairdryer: ["fal", "heat"],
    mirror: ["fal", "window-frame"],
    mirror_cabinet: ["fal", "window-frame"],
    clothes_drying_rack: ["fal", "dryer"],
    couch_table: ["fal", "table-picnic"],
    wardrobe: ["fal", "cabinet-filing"],
    clothes_rail: ["fal", "clothes-hanger"],
    roll_container: ["fal", "cabinet-filing"],
    nightstand: ["fal", "lamp"],
    fan: ["fal", "fan"],
    carpet: ["fad", "rug"],
    safe: ["fal", "vault"]
}
export const CHARACTERISTIC_ICON_MAP = {
    contactless_jack_in: ["fal", "people-arrows-left-right"],
    central_location: ["fad", "map-location-dot"],
    good_public_transport: ["fal", "bus"],
    good_shopping: ["fal", "bags-shopping"],
    green_electricity: ["fal", "wind-turbine"],
    green_heating: ["fal", "leaf"],
    highspeed_internet: ["fal", "wifi"],
    internet: ["fal", "wifi"],
    near_to_university: ["fal", "building-columns"],
    quiet_location: ["fal", "snooze"],
    restaurants: ["fal", "utensils-alt"],
    bars: ["fal", "glass-martini"],
    whole_day_check_in: ["fal", "clock-rotate-left"],
    suitable_for_allergy_sufferers: ["fal", "allergies"],
    alarm_system: ["fal", "sensor-on"],
    amazon_prime: ["fab", "amazon"],
    elevator: ["fal", "sort-circle"],
    balcony: ["fa-regular", "window-frame"],
    barrier_free: ["fal", "wheelchair"],
    dazn: ["fa-regular", "tv"],
    disney: ["fa-regular", "tv"],
    charging_station: ["fal", "charging-station"],
    bike_parking: ["fal", "biking"],
    fire_extinguisher: ["fal", "fire-extinguisher"],
    fitness_devices: ["fal", "dumbbell"],
    garden: ["fal", "house-tree"],
    garden_cottage: ["fa-light", "house-tree"],
    free_parking: ["fal", "parking"],
    blinds: ["fal", "blinds"],
    air_conditioning: ["fal", "air-conditioner"],
    parking: ["fal", "parking"],
    netflix: ["fa-regular", "tv"],
    party_basement: ["fal", "glass-cheers"],
    pool: ["fal", "swimming-pool"],
    raffstore: ["fal", "blinds"],
    smoke_detector: ["fal", "sensor-cloud"],
    sauna: ["fal", "spa"],
    sky_x: ["fal", "tv"],
    terrace: ["fal", "window-frame"],
    room_ventilation: ["fal", "air-conditioner"],
    climate_friendly_construction: ["fal", "air-conditioner"],
    restaurant: ["fal", "fork-knife"],
    bar: ["fal", "martini-glass-citrus"],
    breakfast: ["fal", "croissant"],
    washroom: ["fal", "washing-machine"]
};
export const CHARACTERISTICS_CATEGORY_MAP = {
    building_technology: ['highspeed_internet', 'internet', 'green_electricity', 'green_heating', 'elevator', 'blinds', 'air_conditioning', 'raffstore', 'room_ventilation', 'climate_friendly_construction', 'washroom'],
    highlights: ['balcony', 'fitness_devices', 'garden', 'garden_cottage', 'party_basement', 'pool', 'sauna', 'terrace', 'bar', 'restaurant', 'breakfast'],
    jack_in_security: ['whole_day_check_in', 'contactless_jack_in', 'alarm_system', 'suitable_for_allergy_sufferers', 'barrier_free', 'fire_extinguisher', 'smoke_detector'],
    location_poi: ['bars', 'restaurants', 'good_shopping', 'near_to_university', 'quiet_location', 'central_location'],
    mobility: ['good_public_transport', 'charging_station', 'bike_parking', 'free_parking', 'parking'],
    streaming_service: ['amazon_prime', 'netflix', 'dazn', 'disney', 'sky_x']
};
export const ADVERT_SUB_TYPE_MAP = {
    room: AdvertType.room,
    house: AdvertType.apartment,
    apartment: AdvertType.apartment,
    flat: AdvertType.apartment,
    penthouse: AdvertType.apartment,
    loft: AdvertType.apartment,
    souterrain: AdvertType.apartment,
    garconniere: AdvertType.apartment,
    duplex: AdvertType.apartment,
}
export const MAX_ROOM_COUNT = 25;
export const PREDEFINED_FEE_TYPES = {
    cleaning_fee: 'cleaning_fee',
    service_fee: 'service_fee',
};
export const PREDEFINED_LONG_TERM_FEE_TYPES = {
    end_cleaning: 'end_cleaning',
};
export const PREDEFINED_RUNNING_COSTS = {
    heating_costs: 'heating_costs',
    water_costs: 'water_costs',
    electricity_costs: 'electricity_costs'
};
export const RENTABLE_ROOM_TYPES = [ RoomType.bedroom, RoomType.living_room, RoomType.childrens_room, RoomType.working_room, RoomType.other];
export const ROOM_ICON_MAP = {
    kitchen: 'hat-chef', bathroom: 'bath',
    toilet: 'toilet', bedroom: 'bed-alt',
    living_room: 'couch', childrens_room: 'baby',
    working_room: 'desktop', corridor: 'clothes-hanger',
    other: 'expand'
}