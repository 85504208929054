import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import "../../css/components/subpage-side-menu.css"
import NavigatorButton from "./buttons/NavigatorButton";
import _uniqueId from "lodash/uniqueId";
import {getLanguageEntry} from "../utils/Helper";

class SubpageSideMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            expanded: true
        }
        this.lastSize = window.innerWidth;
        this.menu = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("resize", () => {this.handleResize()});
        if (window.innerWidth < 951) {
            this.reduce();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => {this.handleResize()});
    }

    render() {
        let url = window.location.pathname;
        return(
            <div className={"subpage-side-menu" + (this.state.collapsed ? " collapsed" : "") +
                (this.state.expanded ? " expanded" : "")}
                 ref={this.menu}
                 onFocus={(e) => { this.onFocus(e) }}
                 onBlur={(e) => { this.onBlur(e) }} tabIndex={0}>
                <button className="small-screen-menu-opener" onClick={() => { this.expand() }}>
                    <FontAwesomeIcon icon={["fas", "bars"]} />
                </button>
                <div className="sticky-menu-button-container">
                    <button className="menu-collapse" onClick={() => { this.toggleMenu() }}>
                        <FontAwesomeIcon icon={["fal", "fa-chevron-circle-left"]} />
                    </button>
                    {
                        this.props.items.filter(i => {
                            return !i.shouldRender || i.shouldRender();
                        })
                            .map((item, index) => {
                            return <NavigatorButton to={item.subPath} key={_uniqueId(item.subPath + "-" + index)}
                                                    className={"subpage-menu-entry" + (url.startsWith(item.mainPath) ? " selected" : "")}>
                                <FontAwesomeIcon icon={item.icon} fixedWidth />
                                <span>{getLanguageEntry(item.lang)}</span>
                            </NavigatorButton>
                        })
                    }
                </div>
            </div>
        )
    }

    onFocus(e) {
        if (this.menu.current.contains(e.target)) {
            this.expand();
            if (e.target.nodeName === "BUTTON") {
                e.target.click();
            }
        }
    }

    onBlur(e) {
        if (!this.menu.current.contains(e.relatedTarget)) {
            this.reduce();
        }
    }
    
    toggleMenu() {
        if (this.state.collapsed) {
            this.explode();
        }
        else {
            this.collapse();
        }
    }

    explode() {
        if (this.state.collapsed) {
            this.setState({collapsed: false});
        }
    }

    collapse() {
        if (!this.state.collapsed) {
            this.setState({collapsed: true});
        }
    }

    expand() {
        if (!this.state.expanded) {
            this.setState({expanded: true});
        }
        this.menu.current.focus();
    }

    reduce() {
        if (this.state.expanded) {
            this.setState({expanded: false});
        }
    }

    handleResize() {
        if (window.innerWidth < 951 && this.lastSize > 950) {
            this.reduce();
        }
        this.lastSize = window.innerWidth;
    }

}
SubpageSideMenu.propTypes = {
    id: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.exact({
        mainPath: PropTypes.string.isRequired,
        subPath: PropTypes.string.isRequired,
        icon: PropTypes.arrayOf(PropTypes.string).isRequired,
        lang: PropTypes.string.isRequired,
        shouldRender: PropTypes.func
    })).isRequired
}
export default SubpageSideMenu;