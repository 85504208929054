import "../../css/components/ai-assistant.css";

import PropTypes from "prop-types";
import {useState} from "react";
import button from "bootstrap/js/src/button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";
import {getLanguageEntry} from "../utils/Helper";

const LANG_PATH = 'components>ai_assistant>';

export default function AIAssistant({description, btnText, callback, showInitial, maxWidth}) {
    const [collapsed, setCollapsed] = useState(!showInitial);

    const toggle = function() {
        setCollapsed(!collapsed);
    }

    return <div className="ai-assistant-wrapper">
        <div className="ai-assistant" style={{maxWidth: maxWidth}}>
            <button className="ai-toggle" onClick={toggle}>
                <FontAwesomeIcon icon={["fal", collapsed ? "robot" : "times"]} />
            </button>
            {
                !collapsed &&
                <>
                    <div className="description-container">{parse(getLanguageEntry(description))}</div>
                    <button onClick={callback} className="outline-button accent">
                        <FontAwesomeIcon icon={["fal", "robot"]} />
                        <span>{getLanguageEntry(btnText)}</span>
                    </button>
                </>
            }
        </div>
    </div>

}
AIAssistant.propTypes = {
    description: PropTypes.string.isRequired,
    btnText: PropTypes.string,
    callback: PropTypes.func.isRequired,
    showInitial: PropTypes.bool,
    maxWidth: PropTypes.string,
};
AIAssistant.defaultProps = {
    btnText: `${LANG_PATH}ask_jacki`,
    showInitial: true,
    maxWidth: "300px"
}