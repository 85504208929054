import React from "react";
import {Modal} from "react-bootstrap";
import {
    createAdvertLocationDropdown,
    getHousingTypeItems,
    getLanguageEntry, globalLanguage,
    RADIUS_VALUES
} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Dropdown from "../input/Dropdown";
import MultiDropdown from "../input/MultiDropdown";
import "../../../css/components/modals/search-filter-modal.css";
import {Slider, ThemeProvider} from "@mui/material";
import {rj_theme} from "../../utils/MaterialUIColorTheme";
import {languageData} from "../../../resources/language";
import PropTypes from "prop-types";
import {AdvertType, BookingType} from "../../utils/Types.ts";

const FILTER_TABS = {
    general: "general",
    equipment: "equipment",
    characteristic: "characteristic"
}
const PRICE_RANGE_MINIMUM = 1000;
const PRICE_RANGE_MAXIMUM = 500000;
const SURFACE_RANGE_MINIMUM = 5;
const SURFACE_RANGE_MAXIMUM = 500;
const DEFAULT_FILTER = {
    location: null,
    radius: 'none',
    housing_types: Object.values(AdvertType),
    min_price: 1000,
    max_price: 500000,
    min_surface: 5,
    max_surface: 500,
    free_cancellable: false,
    barrier_free: false,
    equipment: [],
    characteristics: []
}

class SearchFilterModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            tab: 'general',
            filter: JSON.parse(JSON.stringify(DEFAULT_FILTER)),
            new_filter: JSON.parse(JSON.stringify(DEFAULT_FILTER)),
            equipment_filter: '',
            characteristic_filter: ''
        }
        this.direct_booking = React.createRef();
        this.inquiry = React.createRef();
    }

    render() {
        let that = this;
        let filterResult = this.props.onTestFilter?.(this.state.new_filter);
        return(
            <Modal id="search-filter-modal" show={this.state.show} dialogClassName="max-content" size="lg" centered>
                <Modal.Header>
                    <h4>{getLanguageEntry("search_page>filters>refine_search_criteria")}</h4>
                    <button onClick={() => {this.reset()}}>
                        <FontAwesomeIcon icon={["fal", "times"]}/>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div id="filter-tab-selector">
                        {
                            Object.values(FILTER_TABS).map((type, _) => {
                                return <div className="accent-radio-button" key={type}>
                                    <input type="radio" value={type} id={type + "-tab-button"} readOnly={true}
                                           name="search-filter" checked={this.state.tab === type}
                                           onClick={() => this.activateTab(type)}/>
                                    <label htmlFor={type + "-tab-button"}>
                                        {getLanguageEntry("search_page>filters>" + type + "_filter")}
                                    </label>
                                </div>
                            })
                        }
                    </div>
                    <div id="filter-tab-container">
                        {
                            this.state.tab === FILTER_TABS.general &&
                            <div className="filter-tab">
                                <div className="filter-modal-row">
                                    <div className="form-group">
                                        <label>
                                            {getLanguageEntry("general>location")}
                                        </label>
                                        {
                                            createAdvertLocationDropdown("location", null,
                                                "landing_pages>renter>search_bar>location_placeholder", (e, value) => {
                                                    let filter = this.state.new_filter;
                                                    filter.location = value === "none" ? undefined : value;
                                                    this.setState(filter)
                                                }, this.state.filter.location ?? "none")
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="search-radius">
                                            {getLanguageEntry("search_page>filters>radius")}
                                        </label>
                                        <Dropdown items={this.getRadiusDropdownItems()} id="search-radius" name="radius"
                                                  initialValue={this.state.filter.radius} onChange={(e, value) => {
                                            let filter = this.state.new_filter;
                                            filter.radius = value;
                                            this.setState(filter)
                                        }}/>
                                    </div>
                                </div>
                                <div className="filter-modal-row">
                                    <div className="form-group">
                                        <label htmlFor="search-housing-type">
                                            {getLanguageEntry("search_page>filters>housing_type")}
                                        </label>
                                        <MultiDropdown id="search-housing-type" name="housing_types"
                                                       initialValue={this.state.filter.housing_types}
                                                       items={getHousingTypeItems()}
                                                       placeholder="advert_attributes>type_of_housing"
                                                       onChange={(e, value) => {
                                                           let filter = this.state.new_filter;
                                                           filter.housing_types = value;
                                                           this.setState({new_filter: filter})
                                                       }}/>
                                    </div>
                                </div>
                                <div className="filter-modal-row">
                                    <div className="form-group">
                                        <input id="direct-booking" type="checkbox" value="direct" ref={this.direct_booking}
                                               defaultChecked={this.state.filter.booking_type === undefined ?
                                                   true : this.state.filter.booking_type === BookingType.direct}
                                                onChange={(e) => {
                                            let filter = this.state.new_filter;
                                            if (e.target.checked) {
                                                if (this.inquiry.current.checked) {
                                                    delete filter.booking_type;
                                                }
                                                else {
                                                    filter.booking_type = BookingType.direct;
                                                }
                                            }
                                            else {
                                                if (this.inquiry.current.checked) {
                                                    filter.booking_type = BookingType.inquiry;
                                                }
                                                else {
                                                    delete filter.booking_type;
                                                }
                                            }
                                            this.setState({new_filter: filter})
                                        }}/>
                                        <label htmlFor="direct-booking">
                                            {getLanguageEntry("search_page>filters>direct")}
                                        </label>
                                    </div>
                                    <div className="form-group">
                                        <input id="inquiry-booking" type="checkbox" value="inquiry" ref={this.inquiry}
                                               defaultChecked={this.state.filter.booking_type === undefined ?
                                                   true : this.state.filter.booking_type === BookingType.inquiry}
                                               onChange={(e) => {
                                                   let filter = this.state.new_filter;
                                                   if (e.target.checked) {
                                                       if (this.direct_booking.current.checked) {
                                                           delete filter.booking_type;
                                                       }
                                                       else {
                                                           filter.booking_type = BookingType.inquiry;
                                                       }
                                                   }
                                                   else {
                                                       if (this.direct_booking.current.checked) {
                                                           filter.booking_type = BookingType.direct;
                                                       }
                                                       else {
                                                           delete filter.booking_type;
                                                       }
                                                   }
                                                   this.setState({new_filter: filter})
                                               }}/>
                                        <label htmlFor="inquiry-booking">
                                            {getLanguageEntry("search_page>filters>inquiry")}
                                        </label>
                                    </div>
                                </div>
                                <div id="filter-slider-row" className="filter-modal-row">
                                    <div className="form-group">
                                        <label htmlFor="search-price-range">
                                            {getLanguageEntry("general>price")}
                                        </label>
                                        <ThemeProvider theme={rj_theme}>
                                            <Slider min={PRICE_RANGE_MINIMUM} max={PRICE_RANGE_MAXIMUM} step={1000}
                                                    getAriaLabel={() => { getLanguageEntry("general>price") } }
                                                    value={[this.state.new_filter.min_price, this.state.new_filter.max_price]}
                                                    name="price" valueLabelDisplay='on'
                                                    onChange={(e, value) => {
                                                        let filter = this.state.new_filter;
                                                        filter.min_price = Math.min(...value);
                                                        filter.max_price = Math.max(...value);
                                                        this.setState({new_filter: filter});
                                                    }}
                                                    valueLabelFormat={(x) => { return "€" + (x < PRICE_RANGE_MAXIMUM ? (x / 1000) : (x / 1000) + '+') }}/>
                                        </ThemeProvider>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="search-surface-range">
                                            {getLanguageEntry("search_page>filters>living_space")}
                                        </label>
                                        <ThemeProvider theme={rj_theme}>
                                            <Slider min={SURFACE_RANGE_MINIMUM} max={SURFACE_RANGE_MAXIMUM} step={1}
                                                    getAriaLabel={() => { getLanguageEntry("search_page>filters>living_space") } }
                                                    value={[this.state.new_filter.min_surface, this.state.new_filter.max_surface]}
                                                    name="surface" valueLabelDisplay='on'
                                                    onChange={(e, value) => {
                                                        let filter = this.state.new_filter;
                                                        filter.min_surface = Math.min(...value);
                                                        filter.max_surface = Math.max(...value);
                                                        this.setState({new_filter: filter});
                                                    }}
                                                    valueLabelFormat={(x) => { return (x < SURFACE_RANGE_MAXIMUM ? x + " m²" : x + '+ m²') }}/>
                                        </ThemeProvider>
                                    </div>
                                </div>
                                <div className="filter-modal-row">
                                    <div className="form-group">
                                        <input id="barrier-free" type="checkbox" value="barrier-free"
                                               defaultChecked={this.state.filter.barrier_free}
                                               onChange={(e) => {
                                                    let filter = this.state.new_filter;
                                                    filter.barrier_free = e.target.checked;
                                                    this.setState({new_filter: filter})
                                                }}/>
                                        <label htmlFor="barrier-free">
                                            {getLanguageEntry("advert_attributes>characteristics>barrier_free")}
                                        </label>
                                    </div>
                                    <div className="form-group">
                                        <input id="free-cancellation" type="checkbox" value="free-cancellation"
                                               defaultChecked={this.state.filter.free_cancellable}
                                               onChange={(e) => {
                                                   let filter = this.state.new_filter;
                                                   filter.free_cancellable = e.target.checked;
                                                   this.setState({new_filter: filter})
                                               }}/>
                                        <label htmlFor="free-cancellation">
                                            {getLanguageEntry("search_page>filters>without_cancellation_conditions")}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.tab === FILTER_TABS.equipment &&
                            <div className="filter-tab">
                                <input type="text" onKeyUp={(e) => { that.setEquipmentFilter(e) }}/>
                                <div className="checkbox-grid">
                                    {
                                        Object.entries(languageData[globalLanguage].advert_attributes.equipment)
                                            .map(([k, v], _) => {
                                                if (that.state.equipment_filter.length !== 0 &&
                                                    !v.toLowerCase().includes(that.state.equipment_filter)) {
                                                    return '';
                                                }
                                                let id = "filter-" + k.replaceAll("_", "-");
                                                return <div className="form-group" key={id}>
                                                    <input type="checkbox" id={id} value={k} className="equipment"
                                                           defaultChecked={that.state.filter.equipment.includes(k)}
                                                           onChange={(e) => {
                                                               let filter = this.state.new_filter;
                                                               if (e.target.checked &&
                                                                   !filter.equipment.includes(k)) {
                                                                   filter.equipment.push(k);
                                                                   this.setState({new_filter: filter});
                                                               }
                                                               else if (!e.target.checked) {
                                                                   filter.equipment = filter.equipment.filter(e => e !== k);
                                                                   this.setState({new_filter: filter});
                                                               }
                                                           }}/>
                                                    <label htmlFor={id} id={id + '-label'}>{v}</label>
                                                </div>
                                        })
                                    }
                                </div>
                            </div>
                        }
                        {
                            this.state.tab === FILTER_TABS.characteristic &&
                            <div className="filter-tab">
                                <input type="text" onKeyUp={(e) => { that.setCharacteristicFilter(e) } }/>
                                <div className="checkbox-grid">
                                    {
                                        Object.entries(languageData[globalLanguage].advert_attributes.characteristics)
                                            .map(([k, v], _) => {
                                                if (that.state.characteristic_filter.length !== 0 &&
                                                    !v.toLowerCase().includes(that.state.characteristic_filter)) {
                                                    return '';
                                                }
                                                let id = "filter-" + k.replaceAll("_", "-");
                                                return <div className="form-group" key={id}>
                                                    <input type="checkbox" id={id} value={k} className="characteristic"
                                                           defaultChecked={that.state.filter.characteristics.includes(k)}
                                                           onChange={(e) => {
                                                               let filter = this.state.new_filter;
                                                               if (e.target.checked &&
                                                                   !filter.characteristics.includes(k)) {
                                                                   filter.characteristics.push(k);
                                                                   this.setState({new_filter: filter});
                                                               }
                                                               else if (!e.target.checked) {
                                                                   filter.characteristics = filter.characteristics.filter(e => e !== k);
                                                                   this.setState({new_filter: filter});
                                                               }
                                                           }}/>
                                                    <label htmlFor={id} id={id + '-label'}>{v}</label>
                                                </div>
                                            })
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="outline-button accent" onClick={() => { this.applyFilterData() }}>
                        {getLanguageEntry("search_page>filters>apply_button")
                            .replace('#', filterResult.result_count)}
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    reset() {
        this.setState({
            show: 0,
            tab: 'general',
            filter: JSON.parse(JSON.stringify(DEFAULT_FILTER)),
            new_filter: JSON.parse(JSON.stringify(DEFAULT_FILTER)),
            equipment_filter: '',
            characteristic_filter: ''
        });
    }

    show(filterData) {
        let filter = JSON.parse(JSON.stringify(DEFAULT_FILTER));
        if (filterData !== undefined) {
            for (const key of Object.keys(filterData)) {
                filter[key] = filterData[key];
            }
        }
        this.setState({
            show: 1,
            filter: filter,
            new_filter: JSON.parse(JSON.stringify(filter))
        });
    }

    activateTab(tab) {
        this.setState({tab: tab});
    }

    getRadiusDropdownItems() {
        let items = [];
        for (const radiusValue of RADIUS_VALUES) {
            if (radiusValue === 'none') {
                items.push({
                    value: 'none',
                    label: "general>not_specified"
                })
            }
            else {
                items.push({
                    value: parseInt(radiusValue),
                    label: radiusValue + "km"
                })
            }
        }
        return items;
    }

    applyFilterData() {
        this.props.onApplyFilter(this.state.new_filter);
        this.reset();
    }

    setEquipmentFilter(e) {
        this.setState({equipment_filter: e.target.value.toLowerCase()});
    }

    setCharacteristicFilter(e) {
        this.setState({characteristic_filter: e.target.value.toLowerCase()});
    }

}

SearchFilterModal.propTypes = {
    onTestFilter: PropTypes.func.isRequired,
    onApplyFilter: PropTypes.func.isRequired
}
SearchFilterModal.defaultProps = {}
export default SearchFilterModal;