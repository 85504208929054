import React from "react";
import FilterSelectorBar from "../../../../components/FilterSelectorBar";
import {UNPAID_RENTS_CACHE} from "../../../../utils/CacheHandler.ts";
import {getUnpaidRents} from "../../../../utils/RESTInterface";
import {
    getDayFromBegin, getFormattedDate, getLanguageEntry, getTimeUnitInMS, priceToString,
    PRODUCTION_START
} from "../../../../utils/Helper";
import _uniqueId from "lodash/uniqueId";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MessageModal from "../../../../components/modals/MessageModal";
import PropTypes from "prop-types";

class UnpaidRents extends React.Component {

    filterItems = [
        {
            value: -1,
            label: "components>interval_filter>all_unpaid"
        },
        {
            value: 30,
            label: "components>interval_filter>last_month"
        },
        {
            value: 90,
            label: "components>interval_filter>last_quarter"
        },
        {
            value: 180,
            label: "components>interval_filter>last_half_year"
        },
        {
            value: 365,
            label: "components>interval_filter>last_year"
        },
    ]

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            bookings: [],
            interval: -1
        }
        this.message_modal = React.createRef();
    }

    componentDidMount() {
        if (UNPAID_RENTS_CACHE.isValid()) {
            this.setState({
                bookings: UNPAID_RENTS_CACHE.getCache() ? UNPAID_RENTS_CACHE.getCache() : []
            });
        }
        else {
            this.setState({loading: true});
            let that = this;
            getUnpaidRents(() => {
                that.setState({
                    loading: false,
                    bookings: UNPAID_RENTS_CACHE.getCache() ? UNPAID_RENTS_CACHE.getCache() : []
                });
            });
        }
    }

    render() {
        let boxes = this.createUnpaidRentBoxList();
        return(
            <div id="unpaid-rents-tool">
                {
                    this.state.loading &&
                    <div className="roomjack-container loading">
                        <img src="https://roomjack.blob.core.windows.net/roomjack/animations/loading_animation.gif" alt="Roomjack Loading"/>
                    </div>
                }
                {
                    !this.state.loading &&
                    <FilterSelectorBar items={this.filterItems} defaultValue={-1}
                                       onChange={(v) => { this.setState({interval: v}) }}/>
                }
                {
                    !this.state.loading && boxes.length === 0 &&
                    <div className="roomjack-container no-data-container">
                        <img src="https://roomjack.blob.core.windows.net/roomjack/content-images/roomjack_faultier_nachrichten_2.png"
                             alt="No payouts"/>
                        <div className="description-container">
                            {getLanguageEntry("jackboard>finances>submenus>unpaid_rents>placeholder" +
                                (this.state.bookings.length === 0 ? "" : "_interval"))}
                        </div>
                    </div>
                }
                {
                    !this.state.loading && boxes.length > 0 &&
                    <div className="roomjack-container">
                        {boxes.map((box, i) => box)}
                    </div>
                }
                <MessageModal userData={this.props.userData} ref={this.message_modal}
                              onUpdateUserData={this.props.onUpdateUserData}/>
            </div>
        )
    }

    createUnpaidRentBoxList() {
        let bookings = this.filterBookings();
        let result = [];
        for (const booking of bookings) {
            let unpaidCharges = booking.charge_data.charges
                .filter(c => !c.paid && c.due_dates[0] < Date.now());
            for (const charge of unpaidCharges) {
                result.push(this.createUnpaidRentBox(charge, booking));
            }
        }
        return result
    }

    createUnpaidRentBox(charge, booking) {
        let langScope = "jackboard>finances>submenus>unpaid_rents>";
        let guestName = booking.booker.first_name + ' ' + booking.booker.last_name;
        let due = new Date(charge.due_dates[0]);
        return <div className="transaction-box" key={_uniqueId(charge.id)}>
            <div className="transaction-header">
                {
                    getLanguageEntry(langScope + "container_header")
                        .replace("#", guestName)
                }
            </div>
            <table>
                <tbody>
                <tr>
                    <td>{getLanguageEntry(langScope + "due")}</td>
                    <td>{getFormattedDate(due, true, false, false, true)}</td>
                </tr>
                <tr>
                    <td>{getLanguageEntry("general>rental_fee")}</td>
                    <td>{priceToString(charge.amount)}</td>
                </tr>
                <tr>
                    <td>room</td>
                    <td>{booking.title}</td>
                </tr>
                <tr>
                    <td>{getLanguageEntry("general>booking_code")}</td>
                    <td>{booking.confirmation_code}</td>
                </tr>
                <tr>
                    <td>{getLanguageEntry("general>guest")}</td>
                    <td>
                        {guestName}
                        <button className="send-msg" onClick={() => {
                            this.message_modal.current.show(booking.booker) }}>
                            <FontAwesomeIcon icon={["fas", "envelope"]} />
                        </button>
                    </td>
                </tr>
                {
                    booking.booker.phone &&
                    <tr>
                        <td>{getLanguageEntry("general>phone")}</td>
                        <td>{booking.booker.phone}</td>
                    </tr>
                }
                </tbody>
            </table>
        </div>
    }

    filterBookings() {
        if (this.state.loading) {
            return [];
        }
        let start = PRODUCTION_START.getTime();
        let end = getDayFromBegin().getTime();
        if (this.state.interval !== -1) {
            start = end - getTimeUnitInMS(this.state.interval);
        }
        return this.state.bookings.filter(b => {
            for (const charge of b.charge_data.charges) {
                if (!charge.paid && charge.due_dates && charge.due_dates[0] >= start && charge.due_dates[0] < end) {
                    return true;
                }
            }
            return false;
        });
    }

}
UnpaidRents.propTypes = {
    userData: PropTypes.object.isRequired,
    onUpdateUserData: PropTypes.func.isRequired
}
export default UnpaidRents;