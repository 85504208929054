export enum RuntimeMode {
    development = "development",
    production = "production"
}

export enum AccountType {
    private = "private",
    b2b = "b2b"
}

export enum Mode {
    renter = 'renter',
    landlord = 'landlord'
}
export enum Page {
    main = 'main',
    profile = "profile",
    desktop = "desktop",
    search = "search",
    expose = "expose",
    booking_checkout = "booking_checkout",
    user = "user",
    content = "content",
    not_found = "not_found"
}
export enum PolicyType {
    privacy = "privacy_policy",
    terms = "terms_and_conditions",
    revocation = "revocation_policy"
}
export enum NotificationType {
    message = "message",
    booking = "booking",
    inquiry = "inquiry",
    pending_review = "pending_review",
    review = "review",
}
export enum HousingCounterType {
    clicks = "clicks",
    sharing = "sharings"
}
export enum ReportReason {
    misc = "miscellaneous",
    contact = "contact",
    bug_report = "bug_report",
    help = "help",
    advert_report = "advert_report",
    user_report = "user_report",
    email_change = "email_change"
}
export enum BookingType {
    direct = "direct",
    inquiry = "inquiry"
}
export enum ReviewType {
    advert = "advert",
    user = "user"
}
export enum BookingLengthType {
    short_term = "short_term",
    long_term = "long_term"
}
export enum RoomJackReviewCriteria {
    general_rating = "general_rating",
    payment_method_rating = "payment_method_rating",
    usability_rating = "usability_rating",
    ux_rating = "ux_rating",
    support_rating = "support_rating"
}
export enum UserReviewCriteria {
    general_rating = "general_rating",
    communication_rating = "communication_rating",
    reliability_rating = "reliability_rating",
    house_rules_followed_rating = "house_rules_followed_rating"
}
export enum AdvertReviewCriteria {
    location_rating = "location_rating",
    equipment_rating = "equipment_rating",
    cleanliness_rating = "cleanliness_rating",
    price_performance_rating = "price_performance_rating",
    info_accuracy_rating = "info_accuracy_rating",
    check_in_rating = "check_in_rating",
    wifi_rating = "wifi_rating"
}
export enum SupportedChannelManager {
    zimmersoftware = "zimmersoftware"
}
export enum BoosterType {
    basic_package = "basic_package",
    color_accent = "color_accent",
    search_preference = "search_preference",
    gold = "gold",
    gold_plus = "gold_plus"
}
export enum AdvertBooster {
    color_accent = "color_accent",
    search_preference_boosters = "search_preference_boosters",
    gold_boosters = "gold_boosters",
    gold_plus_boosters = "gold_plus_boosters"
}
export enum BookingOverviewType {
    active = "active",
    upcoming = "upcoming",
    past = "past",
    cancelled = "cancelled"
}
export enum TransactionStatusType {
    pending = "pending",
    failed = "failed",
    succeeded = "succeeded",
    processing = "processing",
    cancelled = "cancelled",
    paid = "paid"
}
export enum PaymentTarget {
    platform = "platform",
    connect = "connect"
}
export enum PaymentType {
    card = "card",
    sepa_debit = "sepa_debit",
    paypal = "paypal",
    klarna = "klarna"
}

export enum WidgetType {
    empty = "empty",
    message = "message",
    inquiry = "inquiry",
    todo_list = "todo_list",
    occupancy_rate = "occupancy_rate",
    customer_satisfaction = "customer_satisfaction",
    income = "income",
    click_booking = "click_booking",
    search = "search",
    booker_info = "booker_info",
    booking_info = "booking_info",
    create_advert = "create_advert",
    personalize_dashboard = "personalize_dashboard",
    tips_tricks = "tips_tricks",
    faq = "faq"
}

export enum UnitType {
    currency = "currency",
    percent = "percent",
    people = "people",
    nights = "nights",
    count = "count",
    clock = "clock"
}
/***********************************************************************************************************************
                                                     ADVERT TYPES
 **********************************************************************************************************************/
export enum AdvertEditorTab {
    location = "location",
    advert_type = "advert_type",
    equipment = "equipment",
    images = "images",
    description = "description",
    pricing = "pricing",
    booking_settings = "booking_settings",
}

export enum AdvertType {
    house = "house",
    apartment = "apartment",
    room = "room",
    flat = "flat",
    penthouse = "penthouse",
    loft = "loft",
    souterrain = "souterrain",
    garconniere = "garconniere",
    duplex = "duplex"
}

export enum RoomType {
    kitchen = 'kitchen',
    bathroom = 'bathroom',
    toilet = 'toilet',
    bedroom = 'bedroom',
    living_room = 'living_room',
    childrens_room = 'childrens_room',
    working_room = 'working_room',
    corridor = 'corridor',
    other = 'other'
}

export enum AdvertAreaStatus {
    completed = "completed",
    warning = "warning",
    invalid = "invalid"
}
export enum HouseRuleType {
    no_smoking = "no_smoking",
    no_instruments = "no_instruments",
    no_pets = "no_pets",
    night_rest_period = "night_rest_period",
    custom = "custom"
}
export enum AccessType {
    personal_handover = "personal_handover",
    key_storage = "key_storage",
    remote_open = "remote_open",
    electric_door_lock = "electric_door_lock",
    miscellaneous = "miscellaneous"
}

export enum TaxType {
    local_tax = "local_tax",
    value_added_tax = "value_added_tax"
}

export enum DragListDirection {
    vertical = "vertical",
    horizontal = "horizontal"
}