import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import ImageSlider from "./ImageSlider";
import {
    buildHousingID, getLanguageEntry, collectHousingListAttributes, convertDateToStandardDateString,
    determineAdvertNameLabel, convertMilliseconds, getNightsBetweenDates, calculateRealUnitRent, calculateInvoice,
    priceToString, determineHousingRatingLabel,
    globalLanguage, START, END, getCountLabel, getReviewsOfHousing, getFormattedDate
} from "../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "../../css/components/housing-preview.css";
import {languageData} from "../../resources/language";
import {addFavoriteHousing, deleteFavoriteHousing, incrementHousingCounter} from "../utils/RESTInterface";
import {HousingCounterType} from "../utils/Types.ts";

export const PREVIEW_TYPES = {
    search: "search-result",
    optional: "optional-result",
    overview: "housing-overview"
}

class HousingPreview extends React.Component {

    constructor(props) {
        super(props);
        this.housingID = this.props.type === PREVIEW_TYPES.optional ?
            this.props.advert.id : buildHousingID(this.props.advert);
        this._rooms = {};
        if (this.props.advert.rooms !== undefined) {
            for (const room of this.props.advert.rooms) {
                if (this._rooms[room.type] !== undefined) {
                    this._rooms[room.type] += 1;
                } else {
                    this._rooms[room.type] = 1;
                }
            }
        }
    }

    getAdvertTitleLabel() {
        if (this.props.advert.title && this.props.advert.title.length > 0) {
            return this.props.advert.title;
        }
        return getLanguageEntry("general>without_title");
    }

    render() {
        let classList = [this.props.type];
        if (this.props.type === PREVIEW_TYPES.search) {
            if (this.props.className) {
                classList.push(this.props.className);
            }
            return (
                <Link className={classList.join(" ")} to={this.getLink()} rel="noopener nofollow"
                      onMouseOver={(e) => { this.props.onMouseOver?.(e, this.props.advert) }}
                      onMouseLeave={(e) => { this.props.onMouseLeave?.(e, this.props.advert) }}
                      onClick={(e) => { incrementHousingCounter(this.housingID, HousingCounterType.clicks) }}>
                    <div className="main-information-container">
                        <div className="left-panel">
                            <ImageSlider images={this.determineImages()}
                                         onViewImage={(index, images) => { this.props.onViewImage?.(index, images) }}/>
                        </div>
                        <div className="middle-panel">
                            <div className="housing-type-label">{this.determineHousingType()}</div>
                            <div className="housing-title-label">{this.getAdvertTitleLabel()}</div>
                            <div className="accent-divider"></div>
                            {this.buildDescriptionContainer()}
                            <div className="housing-preview-property-container space">
                                <FontAwesomeIcon icon={["fal", "bed-alt"]} fixedWidth={true} />
                                <span className="label">{getLanguageEntry("search_page>filters>living_space")}</span>
                                <span className="data">{this.determineSurfaceSize()}</span>
                            </div>
                            <div className="housing-preview-property-container rating">
                                <FontAwesomeIcon icon={["fal", "star"]} fixedWidth={true} />
                                <span className="label">{getLanguageEntry("general>rating")}</span>
                                <span className="data">{determineHousingRatingLabel(this.props.advert, this.props.housing)}</span>
                            </div>
                        </div>
                    </div>
                    <div className="right-panel">
                        <button className="favorite-marker" onClick={(e) => { this.handleFavorit(e) }}>
                            <FontAwesomeIcon className="outline" icon={["fal", "heart"]} />
                            <FontAwesomeIcon className={this.getFavoriteIconClass()} icon={["fas", "heart"]}/>
                        </button>
                        {this.determinePrice()}
                    </div>
                </Link>
            )
        }
        if (this.props.type === PREVIEW_TYPES.overview) {
            let lastEditDate;
            if (this.props.displayLastEdit && (this.props.advert.last_edit || this.props.advert.first_publishing)) {
                lastEditDate = getFormattedDate(new Date(this.props.advert.last_edit ?? this.props.advert.first_publishing),
                    false, false, false, true, false, false)
            }
            let reviews = getReviewsOfHousing(this.props.advert, this.props.advert);
            if (this.props.className) {
                classList.push(this.props.className);
            }
            if (this.props.linkPath) {
                return (
                    <Link to={this.getLink()} rel="noopener nofollow" className={classList.join(" ")}>
                        <div className="advert-main-info">
                            <ImageSlider images={this.determineImages()}/>
                            <div className="advert-preview-information">
                                <div className="housing-type-label">
                                    {this.determineHousingType()}
                                </div>
                                <div className="housing-title-label">
                                    {this.getAdvertTitleLabel()}
                                </div>
                                <div className="housing-rooms-label">
                                    {this.determineRoomLabel()}
                                </div>
                                <div className="review-container">
                                    <FontAwesomeIcon icon={["fas", "star"]} />
                                    <span className="rating-value">
                                    {determineHousingRatingLabel(this.props.advert, this.props.housing)}
                                </span>
                                    {
                                        reviews && reviews.length > 0 &&
                                        <span className="rating-count">
                                        ({getCountLabel(reviews.length, "rating")})
                                    </span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="advert-additional-info">
                            {
                                this.props.advert.reg_no !== undefined &&
                                <div className="advert-registration-no">
                                    {getLanguageEntry("advert_attributes>registration_number_short") + ": "}
                                    <strong>{this.props.advert.reg_no}</strong>
                                </div>
                            }
                            {
                                lastEditDate &&
                                <div className="last-edit-label description-container">
                                    {
                                        getLanguageEntry("jackboard>adverts>advert_inspector>" +
                                            ((this.props.advert.published) ? "published_on" : "last_edited_on"))
                                            .replace("#", lastEditDate)
                                    }
                                </div>
                            }
                        </div>
                    </Link>
                )
            }
            else {
                return (
                    <div className={classList.join(" ")}>
                        <div className="advert-main-info">
                            <ImageSlider images={this.determineImages()}/>
                            <div className="advert-preview-information">
                                <div className="housing-type-label">
                                    {this.determineHousingType()}
                                </div>
                                <div className="housing-title-label">
                                    {this.getAdvertTitleLabel()}
                                </div>
                                <div className="housing-rooms-label">
                                    {this.determineRoomLabel()}
                                </div>
                                <div className="review-container">
                                    <FontAwesomeIcon icon={["fas", "star"]} />
                                    <span className="rating-value">
                                    {determineHousingRatingLabel(this.props.advert, this.props.housing)}
                                </span>
                                    {
                                        reviews && reviews.length > 0 &&
                                        <span className="rating-count">
                                        ({getCountLabel(reviews.length, "rating")})
                                    </span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="advert-additional-info">
                            {
                                this.props.advert.reg_no !== undefined &&
                                <div className="advert-registration-no">
                                    {getLanguageEntry("advert_attributes>registration_number_short") + ": "}
                                    <strong>{this.props.advert.reg_no}</strong>
                                </div>
                            }
                            {
                                lastEditDate &&
                                <div className="last-edit-label description-container">
                                    {
                                        getLanguageEntry("jackboard>adverts>advert_inspector>" +
                                            ((this.props.advert.published) ? "published_on" : "last_edited_on"))
                                            .replace("#", lastEditDate)
                                    }
                                </div>
                            }
                        </div>
                    </div>
                )
            }
        }
        else {
            if (this.props.className) {
                classList.push(this.props.className);
            }
            return (
                <Link to={this.getLink()} rel="noopener nofollow" className={classList.join(" ")}>
                    <ImageSlider images={this.determineImages()}/>
                    <button className="favorite-marker" onClick={(e) => { this.handleFavorit(e) }}>
                        <FontAwesomeIcon className="outline" icon={["fal", "heart"]} />
                        <FontAwesomeIcon className={this.getFavoriteIconClass()} icon={["fas", "heart"]}/>
                    </button>
                    <div className="rating">
                        <FontAwesomeIcon icon={["fas", "star"]} />
                        <span>{getLanguageEntry("general>rating")}</span>
                        <span>{determineHousingRatingLabel(this.props.advert, this.props.housing)}</span>
                    </div>
                    <div className="advert-type">{this.determineHousingType()}</div>
                    <div className="housing-title">{this.getAdvertTitleLabel()}</div>
                </Link>
            )
        }
    }

    getLink() {
        let link = '/' + this.props.linkPath + '/' + this.housingID;
        if (this.props.linkPath === "expose") {
            let startSet = false;
            if (this.props.span !== undefined) {
                if (this.props.span.start) {
                    link += "?" + START + "=" + convertDateToStandardDateString(this.props.span.start);
                    startSet = true;
                }
                if (this.props.span.end) {
                    link += (startSet ? "&" : "?") + END + "=" + convertDateToStandardDateString(this.props.span.end);
                }
            }
        }
        return link;
    }

    determineImages() {
        return collectHousingListAttributes(this.props.advert, this.props.housing).images;
    }

    determineHousingType() {
        let housingType;
        if (this.props.housing === undefined || this.props.advert.id === this.props.housing.id) {
            housingType = determineAdvertNameLabel(this.props.advert);
        }
        else {
            housingType = this.props.housing.id.startsWith('room') ?
                getLanguageEntry("advert_attributes>advert_types>room") :
                getLanguageEntry("advert_attributes>advert_types>" + (this.props.housing.type ?? this.props.housing.advert_type));
        }
        return housingType
    }
    
    buildDescriptionContainer() {
        let description = this.props.advert.description;
        let more = description.length > 70;
        if (description.length > 70) {
            description = description.substring(0, 63);
            if (description.lastIndexOf(' ') !== -1 && description.lastIndexOf(' ') > 60) {
                description = description.substring(0, description.lastIndexOf(' ') + 1);
            }
        }
        return <div className="housing-description-container">
            <span>{description}</span>
            {
                more &&
                <span><b>...{getLanguageEntry("general>more")}</b></span>
            }
        </div>
    }

    determineSurfaceSize() {
        let surfaceSize = "-";
        let advert = this.props.advert;
        let target = this.props.housing;
        if (target !== undefined) {
            if (target.surface_size !== undefined) {
                surfaceSize = '' + target.surface_size;
            }
        }
        else if (advert.surface_size !== undefined) {
            surfaceSize = '' + advert.surface_size;
        }
        return surfaceSize + ' m²'
    }

    handleFavorit(e) {
        e.stopPropagation();
        e.preventDefault();
        if (this.props.userData === undefined || this.props.userData === null) {
            this.props.callLogin();
        }
        else {
            let that = this;
            if (this.props.userData.favorite_housings === undefined ||
                !this.props.userData.favorite_housings.includes(this.housingID)) {
                addFavoriteHousing(this.housingID, (response) => {
                    if (response.errorCode !== undefined) {
                        console.error(response.errorCode + ": " + response.message);
                    }
                    else {
                        let user = JSON.parse(JSON.stringify(this.props.userData));
                        if (user.favorite_housings === undefined) {
                            user.favorite_housings = [this.housingID];
                            that.props.onUpdateUserData?.(user);
                        }
                        else {
                            if (user.favorite_housings.filter(id => id === response.housing_id)[0] === undefined) {
                                user.favorite_housings.push(response.housing_id);
                                that.props.onUpdateUserData?.(user);
                            }
                        }
                    }
                });
            }
            else {
                deleteFavoriteHousing(this.housingID, (response) => {
                    if (response.errorCode !== undefined) {
                        console.error(response.errorCode + ": " + response.message);
                    }
                    else {
                        let user = JSON.parse(JSON.stringify(this.props.userData));
                        if (user.favorite_housings !== undefined) {
                            user.favorite_housings = user.favorite_housings.filter(id => id !== response.housing_id);
                            that.props.onUpdateUserData?.(user);
                        }
                    }
                });
            }
        }
    }

    getFavoriteIconClass() {
        let className = "inner";
        if (this.props.userData !== undefined && this.props.userData !== null &&
            this.props.userData.favorite_housings !== undefined &&
            this.props.userData.favorite_housings.includes(this.housingID)) {
            className += " selected";
        }
        return className;
    }

    determinePrice() {
        if (this.props.span === undefined) {
            return '';
        }
        let bookingOffsetDays = convertMilliseconds(this.props.span.start.getTime() - Date.now(), 'day', 'floor');
        let nightCount = getNightsBetweenDates(this.props.span.end, this.props.span.start);
        let pricing = this.props.housing !== undefined ?
            (nightCount >= 30 ? this.props.housing.long_term_pricing : this.props.housing.pricing) : null;
        let pricingList = null;
        let priceLabel;
        let dataLabel;
        let sumLabel;
        if (pricing === null) {
            pricingList = [];
            if (nightCount >= 30 && this.props.advert.long_term_pricing !== undefined) {
                pricingList.push(this.props.advert.long_term_pricing);
            }
            else if (nightCount < 30 && this.props.advert.pricing !== undefined) {
                pricingList.push(this.props.advert.pricing);
            }
            if (this.props.advert.rooms !== undefined) {
                for (const room of this.props.advert.rooms) {
                    if (nightCount >= 30 && room.long_term_pricing !== undefined) {
                        pricingList.push(room.long_term_pricing);
                    }
                    else if (nightCount < 30 && room.pricing !== undefined) {
                        pricingList.push(room.pricing);
                    }
                }
            }
            priceLabel = pricingList[0].long_term ?
                getLanguageEntry("search_page>price_per_month") :
                getLanguageEntry("search_page>price_per_day");
            if (this.props.span !== undefined) {
                let priceArray = pricingList.map(p =>
                    calculateRealUnitRent(this.props.span.start, this.props.span.end, bookingOffsetDays, p));
                let minPrice = Math.min(...priceArray);
                let maxPrice = Math.max(...priceArray);
                dataLabel = priceToString(minPrice, true, 0) + (minPrice !== maxPrice ?
                    ' - ' + priceToString(maxPrice, true, 0) : '');
            }
        }
        else if (this.props.span !== undefined) {
            let invoice = calculateInvoice(this.props.span.start, this.props.span.end, bookingOffsetDays, pricing);
            priceLabel = pricing.long_term ?
                getLanguageEntry("search_page>price_per_month") :
                getLanguageEntry("search_page>price_per_day");
            dataLabel = priceToString(invoice.rent_per_unit, true, 0);
            sumLabel = <span>{getLanguageEntry("search_page>sum") + ": "}
                <b>{priceToString(invoice.total_with_discount, false, 0)}</b>
            </span>
        }
        return <div className="price-container">
            {
                priceLabel &&
                <div className="label">{priceLabel}</div>
            }
            {
                dataLabel &&
                <div className="data">{dataLabel}</div>
            }
            {
                sumLabel &&
                <div className="label">{sumLabel}</div>
            }
        </div>;
    }

    determineRoomLabel() {
        let roomText = '';
        let displayedRoomTypes = [];
        if (Object.keys(this._rooms).length > 0) {
            let roomType = Object.keys(this._rooms)[0];
            displayedRoomTypes.push(roomType);
            roomText += this._rooms[roomType] + ' ' +
                (languageData[globalLanguage].advert_attributes.room_types[roomType] !== undefined ?
                getLanguageEntry("advert_attributes>room_types>" + roomType) : roomType);
        }
        if (Object.keys(this._rooms).length > 1) {
            let roomType = Object.keys(this._rooms)[1];
            displayedRoomTypes.push(roomType);
            roomText += ' • ' + this._rooms[roomType] + ' ' +
                (languageData[globalLanguage].advert_attributes.room_types[roomType] !== undefined ?
                    getLanguageEntry("advert_attributes>room_types>" + roomType) : roomType);
        }
        if (Object.keys(this._rooms).length > 2) {
            let sum = 0;
            for (const roomType of Object.keys(this._rooms)) {
                if (!displayedRoomTypes.includes(roomType)) {
                    sum += this._rooms[roomType];
                }
            }
            roomText += ' • ' + sum + ' ' + getLanguageEntry("general>more_room" + (sum === 1 ? "" : "s"));
        }
        return roomText;
    }

}

HousingPreview.propTypes = {
    className: PropTypes.string,
    linkPath: PropTypes.string,
    advert: PropTypes.object.isRequired,
    housing: PropTypes.object,
    span: PropTypes.exact({
        start: PropTypes.instanceOf(Date).isRequired,
        end: PropTypes.instanceOf(Date).isRequired
    }),
    type: PropTypes.oneOf(Object.values(PREVIEW_TYPES)).isRequired,
    onUpdateUserData: PropTypes.func,
    callLogin: PropTypes.func,
    userData: PropTypes.object,
    onMouseOver: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onViewImage: PropTypes.func,
    displayLastEdit: PropTypes.bool
}
export default HousingPreview;