import ICacheObject from "./objects/ICacheObject";

class Cache<T extends ICacheObject> {

    private cache: Map<string, T>;
    private readonly lifetime:number;

    constructor(lifetime=-1) {
        this.cache = new Map<string, T>();
        this.lifetime = lifetime;
    }

    public getCacheObjectData(id:string) {
        if (this.cache.has(id)) {
            if (this.cache.get(id).isValid(this.lifetime)) {
                return this.cache.get(id).getData();
            }
            this.cache.delete(id);
        }
        return null;
    }

    public getCacheObject(id:string) {
        if (this.cache.has(id)) {
            if (this.cache.get(id).isValid(this.lifetime)) {
                return this.cache.get(id);
            }
            this.cache.delete(id);
        }
        return null;
    }

    public getCacheObjects(ids:Array<string>) {
        let result = [];
        for (const id of ids) {
            let cacheObject = this.getCacheObjectData(id);
            if (cacheObject) {
                result.push(cacheObject);
            }
        }
        return result;
    }

    public addCacheObject(id:string, data:T) {
        this.cache.set(id, data);
    }

    public addCacheObjects(dataList:Array<T>) {
        for (const data of dataList) {
            this.addCacheObject(data.getData().id, data);
        }
    }

    public getCachedDataList() {
        return Array.from(this.cache.values()).map(c => c.getData());
    }

    public removeCachedObject(id:string) {
        this.cache.delete(id);
    }

    public reset() {
        this.cache = new Map<string, T>();
    }
}
export default Cache;