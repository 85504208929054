import React from 'react';

import {ROOMJACK_IS_GREEN} from "../../../resources/contents/ContentPageContent";
import {getLanguageEntry} from "../../utils/Helper";

class GreenContentPage extends React.Component {

    render() {
        return(
            <div id="content-roomjack-is-green" className="content-page">
                <div className="parallax"
                      style={{backgroundImage: "url('https://roomjack.blob.core.windows.net/roomjack/content-images/landscape.jpg')"}}>
                    <span className="parallax-text">{getLanguageEntry(ROOMJACK_IS_GREEN.title)}</span>
                    <span role="img" aria-label="Nachhaltiger ökologischer Mann"> </span>
                </div>
                <div className="container">
                    <h1>{getLanguageEntry(ROOMJACK_IS_GREEN.header)}</h1>
                    <div className="content-description">
                        <div className="roomjack-feature">
                            <div className="feature-image-container">
                                <img src={ROOMJACK_IS_GREEN.features[0].url} alt={ROOMJACK_IS_GREEN.features[0].alt} />
                            </div>
                            <div className="feature-content-container">
                                <div className="feature-text-content">{getLanguageEntry(ROOMJACK_IS_GREEN.features[0].text)}</div>
                            </div>
                        </div>
                        <div className="roomjack-feature inverted">
                            <div className="feature-image-container">
                                <img src={ROOMJACK_IS_GREEN.features[1].url} alt={ROOMJACK_IS_GREEN.features[1].alt}/>
                            </div>
                            <div className="feature-content-container">
                                <div className="feature-text-content">{getLanguageEntry(ROOMJACK_IS_GREEN.features[1].text)}</div>
                            </div>
                        </div>
                        <p>{getLanguageEntry(ROOMJACK_IS_GREEN.paragraphs[0])}</p>
                    </div>
                </div>
            </div>
        )
    }

}
export default GreenContentPage;