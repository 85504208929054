import React from 'react';
import {createPhoneCountryPrefixDropdown, getLanguageEntry} from "../../utils/Helper";
import PropTypes from "prop-types";
import '../../../css/components/input/phone-input-field.css';

class PhoneInputField extends React.Component {

    constructor(props) {
        super(props);
        this.prefix_selector = React.createRef();
        this.field = React.createRef();
    }

    render() {
        let initialValueSplit = this.props.defaultValue ? this.props.defaultValue.split(" ") : [];
        let prefix = null;
        let number = "";
        if (initialValueSplit.length > 0) {
            if (initialValueSplit.length > 1) {
                prefix = initialValueSplit[0];
                number = initialValueSplit.length > 2 ? initialValueSplit.slice(1).join("") : initialValueSplit[1];
            }
            else {
                number = initialValueSplit[0];
            }
        }
        return(
            <div className="horizontal-form-group">
                {createPhoneCountryPrefixDropdown("prefix", this.prefix_selector, null, prefix, this.props.required)}
                <input ref={this.field} name={this.props.name} required={this.props.required}
                       placeholder={this.props.placeholder !== undefined ? getLanguageEntry(this.props.placeholder) : ""}
                       onChange={() => { this.props.onChange?.() }}
                       onKeyUp={(e) => { this.handleKeyUp(e) }}
                       onInput={(e) => { this.handleInput(e); }}
                       disabled={this.props.disabled} defaultValue={number} />
            </div>
        )
    }

    get value() {
        if (this.field.current.value.length === 0 || !this.prefix_selector.current.value) {
            return '';
        }
        return this.prefix_selector.current.value + ' ' + this.field.current.value;
    }

    handleKeyUp(e) {
        e.preventDefault();
        if (this.field.current === null) {
            return;
        }
        if (e.key === 'Enter') {
            this.props.onEnter?.();
        }
        if (((e.key === "c" || e.key === "v") && !e.ctrlKey) &&
            (e.key !== "Backspace" && e.key !== 'Delete' && this.field.current.value.length >= 15) &&
            !Array.from(Array(10).keys()).includes(parseInt(e.key))) {
            e.preventDefault();
        }
    }

    handleInput(e) {
        e.preventDefault();
        if (this.field.current !== null) {
            this.field.current.value = this.field.current.value.replace(/\D/g, '');
        }
    }

}
PhoneInputField.propTypes = {
    defaultValue: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    disabled: PropTypes.bool
}
PhoneInputField.defaultProps = {
    defaultValue: "",
    required: false,
    disabled: false
}
export default PhoneInputField;
