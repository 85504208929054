import React from 'react';

import {getLanguageEntry } from "../../utils/Helper";
import ReportModal from "../../components/modals/ReportModal";
import {ReportReason} from "../../utils/Types.ts";

class LegalNoticeContentPage extends React.Component {
    constructor(props) {
        super(props);
        this.report_modal = React.createRef();
    }

    render() {
        return (
            <div id="content-legal-notice" className="content-page">
                <div className="container">
                    <h1 className="content-title">{getLanguageEntry("content_pages>legalnotice>title")}</h1>
                    <div>
                        <div className="content-page-block">
                            <h2>Impressumspflichten nach dem UGB</h2>
                            <p><strong>Firma: </strong>RoomJack GmbH<br/>
                                <strong>Rechtsform: </strong>Gesellschaft mit beschränkter Haftung<br/>
                                <strong>Sitz: </strong>politische Gemeinde Attnang-Puchheim<br/>
                                <strong>Firmenbuchnummer: </strong>FN567106f<br/>
                                <strong>Firmenbuchgericht: </strong>Landesgericht Wels<br/>
                            </p>
                        </div>
                        <div className="content-page-block">
                            <h2>Impressumspflichten nach dem ECG</h2>
                            <p>
                                <strong>Anschrift: </strong>RoomJack GmbH<br/>
                                <strong>Kontakt: </strong><a href="mailto:office@roomjack.at">office@roomjack.at</a> und
                                über unser <button className='link-button' onClick={()=>{this.callReportModal()}}>Kontaktformular</button><br/>
                                <strong>Mitgliedschaft: </strong>Wirtschaftskammer Oberösterreich - Fachverband Unternehmensberatung, Buchhaltung und IT<br/>
                                <strong>UID-Nr.: </strong>ATU77520005<br/>
                                <strong>Anwendbare Rechtsvorschrift: </strong>GewO aufrufbar auf <a href="https://www.ris.bka.gv.at/" target="_blank" rel="noreferrer nofollow">https://www.ris.bka.gv.at/</a><br/>
                                <strong>Aufsichtsbehörde gem. ECG: </strong>BH Vöcklabruck<br/>
                            </p>
                        </div>
                        <div className="content-page-block">
                            <h2>Offenlegungspflicht nach dem MedienG</h2>
                            <p>
                                <strong>Medieninhaber: </strong>RoomJack GmbH<br/>
                                <strong>Firmensitz: </strong>Attnang-Puchheim<br/>
                                <strong>Unternehmensgegenstand: </strong>IT-Dienstleistungen<br/>
                                <strong>Beteiligungen:<br/></strong>direkte Beteiligung: Michael Schiestl, Philipp Schiestl, CS Management GmbH (GF Christoph Schiestl)<br/>
                                <strong>Geschäftsführer: </strong>Michael Schiestl, Philipp Schiestl, Christoph Schiestl<br/>
                                <strong>Blattlinie: </strong>Betrieb einer Online-Plattform auf der User Wohnraum anbieten, bewerben anmieten bzw. buchen können.<br/>
                            </p>
                        </div>
                        <div className="content-page-block">
                            <h2>Link WKO</h2>
                            <p>
                                <a href="https://www.wkoecg.at/Ecg.aspx?FirmaID=905d535b-692c-4488-b827-1a46d775db58"
                                   target="_blank" rel="noreferrer nofollow">https://www.wkoecg.at/Ecg.aspx?FirmaID=905d535b-692c-4488-b827-1a46d775db58</a>
                            </p>
                        </div>
                    </div>
                </div>
                <ReportModal ref={this.report_modal} userData={this.props.userData}/>
            </div>
        )
    }
    callReportModal() {
        this.report_modal.current.show(null, ReportReason.contact);
    }
}
export default LegalNoticeContentPage;
