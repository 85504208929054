import React from 'react';
import {Form, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SecretInputField from "../input/SecretInputField";
import {login, reactivateAccount} from "../../utils/RESTInterface";
import md5 from "md5";
import {convertMilliseconds, EMAIL, PASSWORD, getLanguageEntry} from "../../utils/Helper";
import PropTypes from "prop-types";
import '../../../css/components/modals/login.css'
import FetchButton from "../buttons/FetchButton";

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            login_result: null,
            show: false,
            reactivation_code_invalid: true,
            reactivating: false,
            loading: false,
            loading_text: ""
        }
        this.login_form = React.createRef();
        this.reactivation_input = React.createRef();
    }

    render() {
        return(
            <Modal id={this.props.id} className={this.props.className} show={this.state.show}
                   onHide={() => { this.hide() }} dialogClassName="max-content" centered>
                <Modal.Header>
                    <h4>{getLanguageEntry("general>sign_in")}</h4>
                    <button onClick={() => { this.hide() }}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <form onSubmit={(e) => { this.triggerLogin(e); }} ref={this.login_form}>
                            <div className="login-input mb-3">
                                <label className="description-container v-form-label" htmlFor="login-mail">
                                    {getLanguageEntry("general>email")}
                                </label>
                                <input type={EMAIL} name={EMAIL} onChange={() => { this.resetLoginError() }}
                                       tabIndex={1}
                                       placeholder={getLanguageEntry("general>email_placeholder")}
                                       readOnly={this.state.reactivating} id="login-mail" required />
                            </div>
                            <div className="login-input mb-5">
                                <label className="description-container v-form-label" htmlFor="login-password">
                                    {getLanguageEntry("general>password")}
                                </label>
                                <SecretInputField placeholder={getLanguageEntry("general>password_placeholder")}
                                                  onChange={() => { this.resetLoginError() }} name={PASSWORD} tabIndex={2}
                                                  readOnly={this.state.reactivating} onEnterPressed={() => {
                                                      document.getElementById("login-button").click();
                                                  }}
                                                  id="login-password" required />
                            </div>
                            {
                                this.state.login_result !== null && !this.state.reactivating &&
                                <div id="login-message" className="description-container mb-3">{this.getLoginErrorMessage()}</div>
                            }
                            {
                                this.state.reactivating &&
                                <div className="reactivation-container mb-3">
                                    <div id="reactivation-description" className="description-container">
                                        {getLanguageEntry("processes>login>account_paused")}
                                    </div>
                                    <Form.Control type="text" id="reactivation-input" ref={this.reactivation_input}
                                           placeholder={getLanguageEntry("processes>login>reactivation_code")}
                                           onChange={() => this.proofReactivationCode() } />
                                </div>
                            }
                            <FetchButton id="login-button" className="accent-icon-button" type="submit" tabIndex={3}
                                         loading={this.state.loading} loadingText={this.state.loading_text}>
                                <FontAwesomeIcon icon={["fal", "sign-in-alt"]} />
                                <span>{getLanguageEntry("general>sign_in")}</span>
                            </FetchButton>
                        </form>
                        <button id="forgot-password" className="link-button" tabIndex={4}
                                onClick={() => this.toRecovery()}>
                            {getLanguageEntry("processes>login>forgot_password")}
                        </button>
                        <button id="to-registration" onClick={() => this.toRegistration()} tabIndex={5}>
                            <FontAwesomeIcon icon={["fal", "user-circle"]}/>
                            <span>{getLanguageEntry("processes>login>no_account")}</span>
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    reset() {
        this.setState({
            login_result: null,
            show: false,
            reactivation_code_valid: false,
            reactivating: false,
            loading: false,
            loading_text: ""
        });
    }

    resetLoginError() {
        this.setState({ login_result: null });
    }

    show() {
        this.setState({ show: 1 });
    }

    hide() {
        this.setState({ show: 0 }, () => { this.reset() });
    }

    triggerLogin(e) {
        e.preventDefault();
        let loginData = new FormData(this.login_form.current);
        let passwordMD5 = md5(loginData.get(PASSWORD));
        let that = this;
        this.setState({
            loading: true,
            loading_text: "processes>login>login_running"
        });
        login(loginData.get(EMAIL).toLowerCase(), passwordMD5, (response) => {
            let loginState = {
                loading: false,
                login_result: response,
                reactivating: response.confirmation_code !== undefined,
                loading_text: ""
            }
            that.setState(loginState, () => {
                that.handleLoginResult();
            });
        });
    }

    handleLoginResult() {
        if (this.state.login_result.errorCode === undefined &&
            this.state.login_result.user !== undefined) {
            this.props.onLoginSuccess(this.state.login_result);
            this.hide();
        }
    }

    getLoginErrorMessage() {
        if (this.state.login_result === null) {
            return '';
        }
        if (this.state.login_result.errorCode !== undefined) {
            let errorCode = this.state.login_result.errorCode;
            let errorMessage = getLanguageEntry('error_codes>' + errorCode);
            if (errorCode === 'ip_ban_err') {
                let banMinutes = convertMilliseconds(this.state.login_result.banTime, 'minute', 'ceil');
                errorMessage = errorMessage.replace('#', '' + banMinutes);
            }
            return errorMessage;
        }
        return '';
    }

    proofReactivationCode() {
        if (this.reactivation_input.current !== null) {
            let md5Code = md5(this.reactivation_input.current.value);
            let invalid = md5Code !== this.state.login_result.confirmation_code;
            if (invalid !== this.state.reactivation_code_invalid) {
                this.setState({ reactivation_code_invalid: invalid });
            }
            if (!invalid) {
                let that = this;
                let loginData = new FormData(this.login_form.current);
                let passwordMD5 = md5(loginData.get(PASSWORD));
                this.setState({
                    loading: true,
                    loading_text: "processes>login>reactivating"
                })
                reactivateAccount(loginData.get(EMAIL).toLowerCase(), passwordMD5, md5Code, (response) => {
                    let state = { loading: false };
                    if (response.errorCode !== undefined) {
                        console.log(response.errorCode + ": " + response.message);
                    }
                    else {
                        state.login_result = response;
                        state.reactivating = response.confirmation_code !== undefined;
                        state.loading_text = ""
                    }
                    that.setState(state, () => {
                        that.handleLoginResult();
                    });
                })
            }
        }
    }

    toRecovery() {
        this.hide();
        this.props.toRecovery();
    }

    toRegistration() {
        this.hide();
        this.props.toRegistration();
    }

}
Login.propTypes = {
    id: PropTypes.string.isRequired,
    onLoginSuccess: PropTypes.func.isRequired,
    toRecovery: PropTypes.func.isRequired,
    toRegistration: PropTypes.func.isRequired
}
export default Login;