import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _uniqueId from 'lodash/uniqueId';
import "../../../css/components/input/dropdown.css"
import PropTypes from "prop-types";
import {getLanguageEntry, truncateText} from "../../utils/Helper";

class MultiDropdown extends React.Component {

    constructor(props) {
        super(props);
        let itemSelectionMap = {};
        if (props.items) {
            for (const item of props.items) {
                if (props.initialValue) {
                    itemSelectionMap[item.value] = props.initialValue.includes(item.value);
                }
                else {
                    itemSelectionMap[item.value] = false;
                }
            }
        }
        this.state = {
            selected_items: itemSelectionMap,
            show: false,
            disabled: this.props.disabled,
        }
        this.dropdown = React.createRef();
        this.dropdown_toggle = React.createRef();
        this.all_selector = React.createRef();
        this.dropdownID =  _uniqueId("dr_id-");
    }

    render() {
        let selectedItems = this.value;
        return(
            <div className="custom-dropdown multi-select" ref={this.dropdown} tabIndex="0" onBlur={(e) => { this.handleFocus(e) }}>
                <button className={(this.state.disabled ? "disabled " : "") + "dropdown-toggle"}
                        id={this.props.id} onClick={(e) => { this.toggle(e) }} ref={this.dropdown_toggle}>
                    {
                        this.props.icon !== undefined &&
                        <FontAwesomeIcon className="decoration" icon={this.props.icon} />
                    }
                    <span className={this.getLabelClass()}>{this.getDropdownLabel()}</span>
                    <FontAwesomeIcon className="arrow" icon={this.state.show ? ["fal", "chevron-up"] : ["fal", "chevron-down"]}/>
                </button>
                <input type="hidden" name={this.props.name} value={this.value === null ? '' : this.value}/>
                {
                    this.state.show && this.props.items !== undefined && <div className="dropdown-menu">
                        <div className="dropdown-item" ref={this.all_selector} onClick={(e) => { this.selectAll(e) }}>
                            <input type="checkbox" value="all" className="dropdown-item"/>
                            <label className={selectedItems.length > 0 ? 'checked' : ''}>
                                <div className="multi-dropdown-checkbox">
                                    {
                                        <FontAwesomeIcon icon={selectedItems.length < this.props.items.length ? ["fas", "circle" ] : ["fal", "check"]}/>
                                    }
                                </div>
                                <span>{getLanguageEntry("general>select_all")}</span>
                            </label>
                        </div>
                        {this.props.items.map((item, index) => {
                            let id = this.dropdownID + "-" + index;
                            return <div className="dropdown-item" key={id}
                                        onClick={(e) => { this.selectItem(e, item.value) }}>
                                <input type="checkbox" value={item.value} className="dropdown-item" id={id}
                                       name={this.props.name}/>
                                <label className={this.state.selected_items[item.value] ? 'checked' : ''} htmlFor={id}>
                                    <div className="multi-dropdown-checkbox">
                                        <FontAwesomeIcon icon={["fal", "check"]}/>
                                    </div>
                                    <span>
                                        {truncateText(getLanguageEntry(item.label), 50)}
                                    </span>
                                </label>
                            </div>
                        })}
                    </div>
                }
            </div>
        )
    }

    onShow() {
        if (this.props.disabled) {
            return;
        }
        this.setState({ show: true });
    }

    onHide() {
        if (this.props.disabled) {
            return;
        }
        this.setState({ show: false });
    }

    get value() {
        return Object.keys(this.state.selected_items).filter(i => this.state.selected_items[i]);
    }

    toggle(e) {
        e.preventDefault();
        if (this.props.disabled) {
            return;
        }
        if (this.state.show) {
            this.onHide();
        }
        else {
            this.onShow();
        }
    }

    getLabelClass() {
        return this.value.length === 0 ? 'placeholder' : ''
    }

    getDropdownLabel() {
        let selectedItems = this.value;
        if (selectedItems.length === 0) {
            return getLanguageEntry(this.props.placeholder);
        }
        if (selectedItems.length === 1) {
            return truncateText(getLanguageEntry(this.props.items.filter(i =>
                i.value === selectedItems[0])[0].label), 50);
        }
        return selectedItems.length + ' ' + getLanguageEntry("general>selected");
    }

    handleFocus(e) {
        if (!this.dropdown.current.contains(e.relatedTarget)) {
            this.onHide();
        }
    }

    selectAll(e) {
        let that = this;
        e.preventDefault();
        if (this.value.length === this.props.items.length) {
            let items = {};
            for (const item of Object.keys(this.state.selected_items)) {
                items[item] = false;
            }
            this.setState({selected_items: items}, () => {
                that.props.onChange?.(e, []);
            });
        }
        else {
            let items = {};
            for (const item of Object.keys(this.state.selected_items)) {
                items[item] = true;
            }
            this.setState({selected_items: items}, () => {
                that.props.onChange?.(e, Object.keys(items));
            });
        }
    }

    selectItem(e, value) {
        let that = this;
        e.preventDefault();
        let items = {...this.state.selected_items};
        items[value] = !items[value];
        this.setState({ selected_items: items }, () => {
            that.props.onChange?.(e, this.value);
        });
        /*if (this.state.selected_items.includes(value)) {
            this.setState({ selected_items: this.state.selected_items.filter(i => i !== value) }, () => {
                that.props.onChange?.(e, this.state.selected_items);
            });
        }
        else {
            let array = [];
            if (this.state.selected_items !== null && this.state.selected_items !== undefined) {
                array = array.concat(this.state.selected_items)
            }
            array.push(value);
            this.setState({ selected_items: array }, () => {
                that.props.onChange?.(e, this.state.selected_items);
            });
        }*/
        this.dropdown_toggle.current.classList.remove("invalid");
    }

    setInvalid() {
        this.dropdown_toggle.current.classList.add("invalid");
    }

    set disabled(disabled) {
        this.setState({ disabled: disabled });
        this.dropdown_toggle.current.disabled = disabled;
        if (disabled) {
            this.dropdown_toggle.current.classList.add("disabled");
        }
        else {
            this.dropdown_toggle.current.classList.remove("disabled");
        }
    }

}
MultiDropdown.propTypes = {
    id: PropTypes.string,
    initialValue: PropTypes.arrayOf(PropTypes.any),
    placeholder: PropTypes.string,
    icon: PropTypes.arrayOf(PropTypes.string),
    items: PropTypes.arrayOf(PropTypes.exact({
        value: PropTypes.any,
        label: PropTypes.string
    })).isRequired,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func
}
MultiDropdown.defaultProps = {
    placeholder: 'general>default_dropdown_placeholder',
    disabled: false
}
export default MultiDropdown;