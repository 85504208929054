import ICacheObject from "./ICacheObject";

class CacheObject implements ICacheObject{

    readonly data:object;
    readonly timestamp:number;

    constructor(data) {
        this.data = data;
        this.timestamp = Date.now();
    }

    public getData() {
        return this.data;
    }

    public isValid(lifetime) {
        if (lifetime < 0) {
            return true;
        }
        return Date.now() < this.timestamp + lifetime;
    }
}

export default CacheObject;