import React from "react";
import PropTypes from "prop-types";
import {getLanguageEntry} from "../../../utils/Helper";
import CustomToggle from "../../../components/input/CustomToggle";
import _uniqueId from "lodash/uniqueId";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {updateUser} from "../../../utils/RESTInterface";
import FetchButton from "../../../components/buttons/FetchButton";
import {Mode} from "../../../utils/Types.ts"

class Notifications extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            message: "",
            loading: false
        }
    }

    getActualNotificationValue(mode, notification) {
        if (mode === "account") {
            if (this.props.userData === undefined || this.props.userData.account_notifications === undefined ||
            this.props.userData.account_notifications[notification] === undefined) {
                return true;
            }
            return this.props.userData.account_notifications[notification];
        }
        if (mode === "renter") {
            if (this.props.userData === undefined || this.props.userData.guest_mode_notifications === undefined ||
                this.props.userData.guest_mode_notifications[notification] === undefined) {
                return true;
            }
            return this.props.userData.guest_mode_notifications[notification];
        }
        if (mode === "landlord") {
            if (this.props.userData === undefined || this.props.userData.landlord_mode_notifications === undefined ||
                this.props.userData.landlord_mode_notifications[notification] === undefined) {
                return true;
            }
            return this.props.userData.landlord_mode_notifications[notification];
        }
    }

    render() {
        this.account_notifications = {
            new_message: this.getActualNotificationValue("account", "new_message"),
            report_received: this.getActualNotificationValue("account", "report_received")
        };
        this.guest_mode_notifications = {
            guest_cancelled_booking: this.getActualNotificationValue("renter", "guest_cancelled_booking"),
            inquiry: this.getActualNotificationValue("renter", "inquiry"),
            inquiry_declined: this.getActualNotificationValue("renter", "inquiry_declined"),
            inquiry_accepted: this.getActualNotificationValue("renter", "inquiry_accepted"),
            booking_invoice: this.getActualNotificationValue("renter", "booking_invoice"),
            review_stay: this.getActualNotificationValue("renter", "review_stay")
        };
        this.landlord_mode_notifications = {
            guest_cancelled_booking: this.getActualNotificationValue("landlord", "guest_cancelled_booking"),
            booking: this.getActualNotificationValue("landlord", "booking"),
            inquiry: this.getActualNotificationValue("landlord", "inquiry"),
            booster_invoice: this.getActualNotificationValue("landlord", "booster_invoice"),
            review_guest: this.getActualNotificationValue("landlord", "review_guest"),
            advert_published: this.getActualNotificationValue("landlord", "advert_published")
        };
        let modeNotifications = this.props.mode === Mode.renter ? this.guest_mode_notifications : this.landlord_mode_notifications;
        return (
            <div id="notifications">
                <div id="notification-container" className="roomjack-container">
                    <div className="roomjack-container-header">
                        {getLanguageEntry("profile_menu>notifications>account>header")}
                    </div>
                    <div className="description-container mb-2">
                        {getLanguageEntry("profile_menu>notifications>description")}
                    </div>
                    <table className="notification-table mb-4">
                        <tbody>
                            {
                                Object.entries(this.account_notifications).map(([k, v], i) => {
                                    let langPath = "profile_menu>notifications>account>notifications>" + k;
                                    return <tr key={_uniqueId(k)}>
                                        <td>
                                            <span className="description-container">... {getLanguageEntry(langPath)}</span>
                                        </td>
                                        <td>
                                            <CustomToggle leftValue={false} rightValue={true} value={v}
                                                          onChange={(state) => { this.account_notifications[k] = state; }}/>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                    <div className="roomjack-container-header">
                        {getLanguageEntry("profile_menu>notifications>modes>" + this.props.mode + ">header")}
                    </div>
                    <div className="description-container mb-2">
                        {getLanguageEntry("profile_menu>notifications>description")}
                    </div>
                    <table className="notification-table mb-2">
                        <tbody>
                            {
                                Object.entries(modeNotifications).map(([k, v], i) => {
                                    let langPath = "profile_menu>notifications>modes>" + this.props.mode + ">notifications>" + k;
                                    return <tr key={_uniqueId(k)}>
                                        <td>
                                            <span className="description-container">... {getLanguageEntry(langPath)}</span>
                                        </td>
                                        <td>
                                            <CustomToggle  leftValue={false} rightValue={true} defaultValue={v}
                                                           onChange={(state) => { modeNotifications[k] = state; }}/>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                    <div className="description-container mt-3 mb-3">{getLanguageEntry(this.state.message)}</div>
                    <div className="container-control">
                        <FetchButton className="outline-button accent" onClick={() => {this.updateNotifications()}}
                                     loadingText={"general>saving"} loading={this.state.loading}
                                     style={{marginLeft: "auto"}}>
                            <FontAwesomeIcon icon={["fal", "save"]} />
                            <span>{getLanguageEntry("general>save")}</span>
                        </FetchButton>
                    </div>
                </div>
            </div>
        )
    }

    updateNotifications() {
        let that = this;
        let update = {
            account_notifications: this.account_notifications,
            guest_mode_notifications: this.guest_mode_notifications,
            landlord_mode_notifications: this.landlord_mode_notifications,
        }
        this.setState({loading: true});
        updateUser(this.props.userData.id, update, function(response) {
            let state = {loading: false}
            if (response.errorCode !== undefined) {
                console.error(response.errorCode + ": " + response.message);
                this.setState(state);
            }
            else {
                state.message = "general>changes_successful_stored";
                that.setState(state);
                setTimeout(() => {
                    that.setState({message: ""});
                }, 3000);
                that.props.onUpdateUserSession?.(response);
            }
        });
    }

}

Notifications.propTypes = {
    onUpdateUserSession: PropTypes.func.isRequired,
    userData: PropTypes.object,
    mode: PropTypes.string.isRequired
}
export default Notifications;