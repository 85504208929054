import React from 'react';
import {Modal} from "react-bootstrap";
import {
    getLanguageEntry, MAX_MESSAGE_LENGTH
} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TextArea from "../input/TextArea";
import "../../../css/components/modals/message-modal.css";
import PropTypes from "prop-types";
import {sendMessage} from "../../utils/RESTInterface";
import FetchButton from "../buttons/FetchButton";

class MessageModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            receiver: null,
            response: null,
            sending: false
        }
        this.message_form = React.createRef();
    }

    render() {
        let errorObject = this.getSendingError();
        return (
            <Modal id="messaging-modal" show={this.state.show}
                   onHide={() => { this.hide() }} dialogClassName="max-content" centered>
                <Modal.Header>
                    <h4>
                        {getLanguageEntry("processes>messaging>your_message_to")
                            .replace('@', this.state.receiver !== null ? this.state.receiver.first_name : '')}
                    </h4>
                    <button onClick={() => { this.hide() }}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>

                <Modal.Body>
                    {
                        errorObject &&
                        <div className="message-sending-error">
                            <img className="error-image" src={errorObject.image} alt="message-sending-error" />
                            {errorObject.content}
                        </div>
                    }
                    {
                        !errorObject && this.state.response === null &&
                        <div className="messaging-modal-body">
                            <form ref={this.message_form} onSubmit={(e) => { this.sendMessage(e) }}>
                                <TextArea maxLength={MAX_MESSAGE_LENGTH} name="message" required={true} className="mb-4"/>
                                <FetchButton className="send-message accent-icon-button" type="submit"
                                             loading={this.state.sending} loadingText={"processes>report>sending"}>
                                    <FontAwesomeIcon icon={["fal", "envelope"]} />
                                    <span>{getLanguageEntry("general>send_message")}</span>
                                </FetchButton>
                            </form>
                        </div>
                    }
                    {
                        !errorObject && this.state.response !== null &&
                        <div className="process-modal-result-container">
                            <div className="process-result-image">
                                <div className="result-icon-container">
                                    <FontAwesomeIcon icon={["fal", "paper-plane"]}/>
                                </div>
                            </div>
                            <div className="process-result-message-box">
                                {getLanguageEntry("processes>messaging>message_successful_sent")
                                    .replaceAll('@', this.state.receiver !== null ? this.state.receiver.first_name : '')}
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        )
    }

    show(receiver) {
        if (this.props.userData) {
            this.setState({
                show: true,
                receiver: receiver
            });
        }
    }

    hide() {
        this.setState({
            show: false,
            receiver: null,
            response: null,
            sending: false,
        });
    }

    getSendingError() {
        if (this.state.receiver === null) {
            return null;
        }
        let errorObject = null;
        let alertText; let image;
        if (this.state.receiver.delete !== undefined &&
            ((this.state.receiver.delete.active !== undefined && this.state.receiver.delete.active) ||
                (this.state.receiver.delete instanceof Boolean && this.state.receiver.delete))) {
            alertText = getLanguageEntry("components>message_modal>alerts>user_deleted")
                .replace("#username#", this.state.receiver.first_name);
            image = "https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear_honey.png";
        }
        else if (this.state.receiver.blocking !== undefined &&
            ((this.state.receiver.blocking.active !== undefined && this.state.receiver.blocking.active) ||
                (this.state.receiver.blocking instanceof Boolean && this.state.receiver.blocking))) {
            alertText = getLanguageEntry("components>message_modal>alerts>user_blocked")
                .replace("#username#", this.state.receiver.first_name);
            image = "https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear_honey.png";
        }
        else if (this.state.receiver.pause !== undefined &&
            ((this.state.receiver.pause.active !== undefined && this.state.receiver.pause.active) ||
                (this.state.receiver.pause instanceof Boolean && this.state.receiver.pause))) {
            alertText = getLanguageEntry("components>message_modal>alerts>user_paused")
                .replace("#username#", this.state.receiver.first_name);
            image = "https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear_honey.png";
        }
        else if (this.props.userData.id === this.state.receiver.id) {
            alertText = getLanguageEntry("processes>messaging>alerts>message_to_yourself");
            image = "https://roomjack.blob.core.windows.net/roomjack/content-images/roomjack_faultier_nachrichten_5.png";
        }
        if (alertText) {
            errorObject = {
                content: <div className="modal-headline">{alertText}</div>,
                image: image
            }
        }
        return errorObject;
    }

    sendMessage(e) {
        e.preventDefault();
        let messageForm = new FormData(this.message_form.current);
        let that = this;
        this.setState({sending: true});
        sendMessage(this.props.userData, this.state.receiver, messageForm.get("message"),
            null, null, null, function(response) {
            if (response.conversation) {
                let user = JSON.parse(JSON.stringify(that.props.userData));
                if (!user.conversations) {
                    user.conversations = [];
                }
                user.conversations.push(response.conversations.id);
                that.props.onUpdateUserData?.(user, () => {
                    that.setState({ response: response, sending: false });
                });
            }
        });

    }


}
MessageModal.propTypes = {
    userData: PropTypes.any,
    onUpdateUserData: PropTypes.func.isRequired
}
MessageModal.defaultProps = {

}
export default MessageModal;