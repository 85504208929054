import React from "react";
import PropTypes from "prop-types";
import {getLanguageEntry, globalLanguage} from "../utils/Helper";
import {Accordion} from "react-bootstrap";
import "../../css/pages/help-page.css";
import parse from 'html-react-parser';

class HelpPage extends React.Component {

    constructor(props) {
        super(props);
        let params = new URLSearchParams(window.location.search);
        let initialCategory = params.get("category");
        this.state = {
            category: initialCategory ? initialCategory : this.props.categories ? this.props.categories[0].id : null,
            initial_topic: params.get("topic"),
            keyword: null
        }
    }

    render() {
        let regex = this.state.keyword ? new RegExp(this.state.keyword, "i") : null;
        let filteredData = this.props.data.filter(d =>
            (!this.state.category || !d.category || d.category === this.state.category) &&
            (!regex || regex.test(d.header[globalLanguage]) || regex.test(d.content[globalLanguage])));
        return(
            <div id={this.props.id} className="help-page container">
                <div className="accordion-site">
                    <div className="left-panel">
                        <h1>{getLanguageEntry(this.props.title)}</h1>
                        <div className="accordion-site-image">
                            <img src={this.props.image.url} alt={this.props.image.alt} />
                        </div>
                        {
                            !regex && this.props.categories &&
                            <div className="category-selector">
                                {
                                    this.props.categories.map((category, i) => {
                                        return <div className="form-group" key={category.id}>
                                            <input type="radio" id={category.id} name="category" readOnly={true}
                                                   checked={this.state.category === category.id} onClick={() => {
                                                    if (this.state.category !== category.id) {
                                                        this.setState({category: category.id});
                                                    }
                                                   }}/>
                                            <label htmlFor={category.id}>
                                                {getLanguageEntry(category.title)}
                                            </label>
                                        </div>
                                    })
                                }
                            </div>
                        }
                    </div>
                    <div className="right-panel">
                        {
                            this.props.useSearch &&
                            <input type="text" className="keyword-search"
                                   onKeyUp={(e) => { this.searchEntries(e) }}
                                   placeholder={getLanguageEntry("help_pages>search_keyword")}/>
                        }
                        <Accordion defaultActiveKey={this.state.initial_topic}>
                            {
                                filteredData.map((item, i) => {
                                    return <Accordion.Item eventKey={item.id} key={item.id}>
                                        <Accordion.Header as={"div"}>
                                            {parse(item.header[globalLanguage])}
                                        </Accordion.Header>
                                        <Accordion.Body className="description-container">
                                            {parse(item.content[globalLanguage])}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                })
                            }
                        </Accordion>
                    </div>
                </div>
            </div>
        );
    }

    searchEntries(e) {
        this.setState({keyword: e.target.value.length > 0 ? e.target.value : null });
    }

}

HelpPage.propTypes = {
    id: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.exact({
        header: PropTypes.object.isRequired,
        content: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        category: PropTypes.string,
    })).isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.exact({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired
    }).isRequired,
    categories: PropTypes.arrayOf(PropTypes.exact({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
    })),
    useSearch: PropTypes.bool
}
HelpPage.defaultProps = {
    useSearch: true
}
export default HelpPage;