import {DEFAULT_MAP_LOCATION} from "../../utils/Helper";
import {GoogleMap} from "@react-google-maps/api";
import PropTypes from "prop-types";
import { useRef, useState} from "react";
import _uniqueId from "lodash/uniqueId";
import AdvertMarker from "./AdvertMarker";

function GoogleMaps(props) {
    const [zoom, setZoom] = useState(props.zoom);
    const map = useRef();
    const lastCenter = useRef(null);

    function getCenter() {
        if (props.focusedAdvert.index < 0 && props.adverts !== undefined && props.adverts.length > 0) {
            for (const advert of props.adverts) {
                if (advert.address !== undefined && advert.address.location !== undefined) {
                    lastCenter.current = advert.address.location;
                    return lastCenter.current;
                }
            }
        }
        if (props.focusedAdvert.index > 0 && props.adverts !== undefined &&
            props.adverts.length > props.focusedAdvert.index && props.adverts[props.focusedAdvert.index].address !== undefined &&
            props.adverts[props.focusedAdvert.index].address.location !== undefined) {
            lastCenter.current = props.adverts[props.focusedAdvert.index].address.location;
            return lastCenter.current;
        }
        return lastCenter.current === null ? DEFAULT_MAP_LOCATION : lastCenter.current;
    }

    function getMarkerClass(index) {
        if (props.focusedAdvert.index === index) {
            return props.focusedAdvert.focus ? "focus" : "";
        }
        else {
            return props.focusedAdvert.focus ? "fade-out" : "";
        }
    }

    return <GoogleMap zoom={props.zoom} center={getCenter()} mapContainerClassName={props.className}
                      options={{ disableDefaultUI: true }} ref={map} onZoomChanged={() => {
                          if (map.current !== null && map.current !== undefined) {
                              setZoom(map.current.state.map.zoom);
                          }
                      }}>
        {
            props.adverts.map((advert, i) => {
                if (advert.address !== undefined && advert.address.location !== undefined) {
                    return <AdvertMarker advert={advert} content={"Test"} zoom={zoom}
                                         className={getMarkerClass(i)}
                                         key={_uniqueId("map-marker-" + i)}/>
                }
                return '';
            })
        }
    </GoogleMap>

}
GoogleMaps.propTypes = {
    className: PropTypes.string.isRequired,
    adverts: PropTypes.arrayOf(PropTypes.object),
    focusedAdvert: PropTypes.exact({
        index: PropTypes.number.isRequired,
        focus: PropTypes.bool.isRequired
    }),
    zoom: PropTypes.number
}
GoogleMaps.defaultProps = {
    adverts: [],
    focusedAdvert: {
        index: -1,
        focus: false
    },
    zoom: 8
}
export default GoogleMaps;