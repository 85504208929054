import React from "react";
import PropTypes from "prop-types";
import {PaymentTarget, PaymentType} from "../../utils/Types.ts";
import Dropdown from "./Dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    getLanguageEntry,
    getPaymentGatewayLogo,
    PAYMENT_GATEWAY_LOGO_MAP,
    SUPPORTED_PAYMENT_GATEWAYS
} from "../../utils/Helper";
import {
    createCheckoutSession,
    createSetupIntent,
    listPaymentMethods
} from "../../utils/RESTInterface";
import {Elements, ElementsConsumer, PaymentElement} from "@stripe/react-stripe-js";
import {getConnectStripe, STRIPE} from "../../utils/StripeHelper.ts";
import FetchButton from "../buttons/FetchButton";

class PaymentForm extends React.Component {

    handleSubmit() {
        return this.props.stripe.confirmSetup({
            elements: this.props.elements,
            redirect: "if_required"
        });
    };

    render() {
        return (
            <PaymentElement />
        );
    }
}


class PaymentHandler extends React.Component {

    basic_payment_methods = [
        { value: PaymentType.card, label: "components>payment_handler>payment_types>card" },
        { value: PaymentType.sepa_debit, label: "components>payment_handler>payment_types>sepa_debit" },
    ]

    constructor(props) {
        super(props);
        this.state = {
            loading_pm: false,
            creating_intent: false,
            creating_session: null,
            payment_methods: this.basic_payment_methods,
            initial_selected: null,
            show_pm_input: false,
            client_secret: null,
            stripe: null,
            payment_method: null,
            checkout_url: null
        }
        this.pm_form = React.createRef();
        this.trigger_button = React.createRef();
    }

    componentDidMount() {
        if (this.props.waitForPMs) {
            this.setState({loading_pm: true});
        }
        else if (this.props.requestPMs) {
            this.setState({loading_pm: true});
            this.requestPaymentMethods();
        }
    }

    render() {
        return (
            <div className="payment-handler">
                <div className="description-container">
                    <FontAwesomeIcon icon={["fal", "info-circle"]} />
                    <span>{getLanguageEntry("components>payment_handler>description")}</span>
                </div>
                <div className="stripe-link">
                    <a href="https://stripe.com/de/privacy" rel="noreferrer nofollow" target="_blank">
                        {getLanguageEntry("components>payment_handler>stripe_privacy")}
                    </a>
                </div>
                {
                    !this.state.loading_pm && !this.state.creating_intent && !this.state.creating_session &&
                    this.state.checkout_url && getPaymentGatewayLogo(this.state.payment_method)
                }
                {
                    !this.state.loading_pm && !this.state.creating_intent && !this.state.creating_session &&
                    this.state.checkout_url &&
                    <button className="cancel-session simple-icon-button" onClick={() => {
                        this.setState({
                            payment_method: null,
                            checkout_url: null
                        });
                        this.props.onPaymentGatewaySelected?.();
                    }}>
                        <FontAwesomeIcon icon={["fal", "times"]}/>
                    </button>
                }
                {
                    !this.state.loading_pm && !this.state.creating_intent &&
                    !this.state.checkout_url && this.props.paymentGateways &&
                    this.props.paymentGateways.map((pg, _) => {
                        if (!this.state.creating_session || this.state.creating_session === pg) {
                            return <FetchButton className="session-payment-button" key={pg}
                                                loading={this.state.creating_session === pg}
                                                loadingText={getLanguageEntry("components>payment_handler>creating_session")
                                                    .replace("#", getLanguageEntry("components>payment_handler>payment_types>" + pg))}
                                                onClick={() => {this.triggerPaymentGatewaySetup(pg)}}>
                                <img src={PAYMENT_GATEWAY_LOGO_MAP[pg]} alt={pg + "-logo"} />
                            </FetchButton>
                        }
                        return null;
                    })
                }
                {
                    !this.state.loading_pm && (!this.state.client_secret || this.state.payment_method !== "paypal") &&
                    !this.state.creating_intent && !this.state.creating_session  && !this.state.checkout_url &&
                    this.props.paymentGateways && this.props.paymentGateways.length > 0 &&
                    <div className="description-container">
                        {getLanguageEntry("general>or")}...
                    </div>
                }
                {
                    !this.state.loading_pm && this.state.payment_methods && this.state.payment_methods.length &&
                    !this.state.creating_intent && !this.state.creating_session  && !this.state.checkout_url &&
                    <form onSubmit={(e) => {this.execute(e)}}>
                        <Dropdown items={this.state.payment_methods} name="payment_method"
                                  style={{justifySelf: "center"}} disabled={!!this.state.creating_session}
                                  onChange={(e, pm) => { this.triggerSetupIntentCreation(pm); }}
                                  initialValue={this.state.payment_method} required={true}
                                  comparator={(item1, item2) => item1 && item2 &&
                                          ((item1.id && item1.id === item2.id) || (item1 === item2))}/>
                        <button ref={this.trigger_button} style={{display: "none"}}></button>
                    </form>
                }
                {
                    (this.state.loading_pm || this.state.creating_intent) &&
                    <div className="loading" style={{fontSize: "13px"}}>
                        <FontAwesomeIcon icon={["fad", "spinner-third"]} spin={true}/>
                        <span style={{color: "gray"}}>
                                {getLanguageEntry(this.state.loading_pm ?
                                    "components>payment_handler>loading_payment_methods" :
                                    "components>payment_handler>creating_intent")}
                            </span>
                    </div>
                }
                {
                    !this.state.loading_pm && !this.state.creating_intent && this.state.show_pm_input &&
                    this.state.client_secret && this.state.stripe &&
                    <div style={{display: "grid", gap: "15px", padding: "5px 10px",
                        borderRadius: "16px", border: "1px solid lightgray"}}>
                        <div className="description-header">
                            {getLanguageEntry("components>payment_handler>add_new_payment_method")}
                        </div>
                        <Elements stripe={this.state.stripe}
                                  options={{clientSecret: this.state.client_secret}}>
                            <ElementsConsumer>
                                {(props) => <PaymentForm {...props} ref={this.pm_form} />}
                            </ElementsConsumer>
                        </Elements>
                    </div>
                }
            </div>
        )
    }

    requestPaymentMethods() {
        let that = this;
        listPaymentMethods((response) => {
            if (response.errorCode) {
                console.warn(response.errorCode + ": " + response.message);
            }
            that.createDropdownItems(response.data);
        })
    }

    triggerSetupIntentCreation(paymentMethod, metadata) {
        this.setState({ creating_intent: true });
        let that = this;
        createSetupIntent(paymentMethod, metadata, paymentMethod === PaymentType.sepa_debit ?
            this.props.accountOwnerID : null, (response) => {
            let state = { creating_intent: false }
            if (!response.client_secret) {
                console.warn(response);
                state.stripe = null;
                state.payment_method = null;
            }
            else {
                state.show_pm_input = !paymentMethod.id;
                state.client_secret = response.client_secret;
                state.stripe = response.stripe_account ? getConnectStripe(response.stripe_account) : STRIPE
                state.payment_method = paymentMethod;
                that.props.onPaymentGatewaySelected?.(paymentMethod);
            }
            that.setState(state);
        })
    }

    triggerPaymentGatewaySetup(paymentMethod) {
        if (this.props.accountOwnerID) {
            this.triggerCheckoutSessionCreation(paymentMethod)
        }
        else if (this.props.getCheckoutData && this.props.getCheckoutData()) {
            this.setState({creating_session: paymentMethod});
            let that = this;
            let baseURL = window.location.href;
            let successURL = baseURL + "?" + paymentMethod + "={CHECKOUT_SESSION_ID}";
            if (this.props.getCheckoutSuccessParams) {
                successURL += "&" + this.props.getCheckoutSuccessParams();
            }
            let data = this.props.getCheckoutData();
            createCheckoutSession(paymentMethod, data.amount, data.description, data.metadata, null,
                successURL, baseURL, (response) => {
                    console.log(response);
                let state = {creating_session: null};
                if (!response.url) {
                    console.warn(response);
                    state.stripe = null;
                    state.payment_method = null;
                }
                else {
                    state.checkout_url = response.url;
                    state.payment_method = paymentMethod;
                    that.props.onPaymentGatewaySelected?.(paymentMethod);
                }
                that.setState(state);
            });
        }
    }

    triggerCheckoutSessionCreation(paymentMethod) {
        this.setState({creating_session: paymentMethod});
        let baseURL = window.location.href;
        let successURL = baseURL + "?" + paymentMethod + "={CHECKOUT_SESSION_ID}";
        if (this.props.getCheckoutSuccessParams) {
            successURL += "&" + this.props.getCheckoutSuccessParams();
        }
        let that = this;
        createCheckoutSession(paymentMethod, this.props.accountOwnerID ?? null,
            successURL, baseURL, (response) => {
            that.setState({creating_session: null});
            if (response.url) {
                window.location.href = response.url;
            }
        });
    }

    execute(e) {
        e.preventDefault();
        this.props.onConfirmationStarted();
        if (!this.state.stripe || !this.state.client_secret ||
            (!this.pm_form.current && !this.state.payment_method)) {
            return;
        }
        if (this.pm_form.current) {
            this.pm_form.current.handleSubmit()
                .then(this.props.onPaymentConfirmed)
                .catch(error => {
                    console.log(error);
                });
        }
        else if (this.state.payment_method && this.state.payment_method.type) {
            if (this.state.payment_method.type === PaymentType.card) {
                this.state.stripe.confirmCardSetup(this.state.client_secret,
                    { payment_method: this.state.payment_method.id })
                    .then(this.props.onPaymentConfirmed)
                    .catch(error => {
                        console.log(error);
                    });
            }
            if (this.state.payment_method.type === PaymentType.sepa_debit) {
                this.state.stripe.confirmSepaDebitSetup(this.state.client_secret,
                    { payment_method: this.state.payment_method.id })
                    .then(this.props.onPaymentConfirmed)
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    }

    triggerSubmit() {
        if (SUPPORTED_PAYMENT_GATEWAYS.includes(this.state.payment_method) && this.state.checkout_url) {
            window.location.href = this.state.checkout_url;
        }
        else if (this.trigger_button.current) {
            this.trigger_button.current.click();
        }
    }

    createDropdownItems(paymentMethods, initialSelected) {
        let options = [];
        let year = new Date().getFullYear();
        let month = new Date().getMonth();
        if (paymentMethods) {
            for (const paymentType of Object.keys(paymentMethods)) {
                for (const paymentMethod of paymentMethods[paymentType]) {
                    if (paymentType === PaymentType.card) {
                        if (paymentMethod.exp_year > year ||
                            (paymentMethod.exp_year === year && paymentMethod.exp_month > month)) {
                            options.push({
                                value: paymentMethod,
                                label: paymentMethod.brand.toUpperCase() + "  **** " + paymentMethod.last4
                            });
                        }
                    }
                    if (paymentType === PaymentType.sepa_debit && this.props.target !== PaymentTarget.connect) {
                        options.push({
                            value: paymentMethod,
                            label: getLanguageEntry("components>payment_handler>debit_charge") + "  **** " + paymentMethod.last4
                        });
                    }
                }
            }
        }
        options.push(...this.basic_payment_methods);
        this.setState({
            loading_pm: false,
            payment_methods: options,
            initial_selected: initialSelected,
            payment_method: initialSelected
        });
    }

    hasChanged() {
        return this.state.client_secret && this.state.payment_method &&
            (!this.state.initial_selected || this.state.initial_selected.id !== this.state.payment_method.id);
    }

}
PaymentHandler.propTypes = {
    requestPMs: PropTypes.bool.isRequired,
    waitForPMs: PropTypes.bool.isRequired,
    target: PropTypes.oneOf(Object.values(PaymentTarget)).isRequired,
    accountOwnerID: PropTypes.string,
    paymentGateways: PropTypes.arrayOf(Object.values(PaymentType)),
    onPaymentConfirmed: PropTypes.func.isRequired,
    onConfirmationStarted: PropTypes.func.isRequired,
    getCheckoutSuccessParams: PropTypes.func,
    getCheckoutData: PropTypes.func,
    onPaymentGatewaySelected: PropTypes.func
}
PaymentHandler.defaultProps = {
    requestPMs: true,
    waitForPMs: false
}
export default PaymentHandler;