import React from "react";
import PropTypes from "prop-types";
import {getDayFromBegin, getLanguageEntry, getTimeUnitInMS, PRODUCTION_START} from "../../../../utils/Helper";
import { Doughnut } from "react-chartjs-2";
import {determineOccupancyChartData} from "../../../../utils/StatisticHelper";

class OccupancyRate extends React.Component {

    render() {
        let start = PRODUCTION_START.getTime();
        let end = getDayFromBegin().getTime();
        if (this.props.interval !== -1) {
            start = end - getTimeUnitInMS(this.props.interval);
        }
        let chartData = determineOccupancyChartData(start, end, this.props.adverts, this.props.bookings);
        return(
            <div id="occupancy-rate-overview" className="statistic-container">
                <div className="statistic-title">
                    {getLanguageEntry("jackboard>stats>submenus>occupancy_rate>title")}
                </div>
                <div className="content-container">
                    <div className="roomjack-container">
                        <Doughnut data={chartData.data} options={chartData.options}
                                  width={250} height={250}/>
                        <div className="doughnut-center-label">
                            <div>{chartData.percent}%</div>
                            <div>{getLanguageEntry("jackboard>stats>submenus>occupancy_rate>occupied_label")}</div>
                        </div>
                    </div>
                </div>
            </div>)
    }

}

OccupancyRate.propTypes = {
    adverts: PropTypes.array,
    bookings: PropTypes.array,
    interval: PropTypes.number
}
export default OccupancyRate;