import React from "react";
import PropTypes from "prop-types";
import {
    calculateFee, calculateIncludedTax,
    calculateInvoiceFromObject,
    calculateLandlordWin,
    calculateTax,
    getCountLabel,
    getLanguageEntry,
    isFeeObjectIncomplete,
    isRunningCostIncomplete,
    priceToString
} from "../../utils/Helper";
import _uniqueId from "lodash/uniqueId";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    PREDEFINED_FEE_TYPES,
    PREDEFINED_LONG_TERM_FEE_TYPES,
    PREDEFINED_RUNNING_COSTS
} from "../../../resources/housings";

class BookingInvoiceTable extends React.Component {

    render() {
        if (!this.props.booking) {
            return <div></div>;
        }
        let booking = this.props.booking;
        let proTool = booking.invoice_data.pro_tool;
        let pricingObject = this.props.booking.invoice_data.pricing;
        let invoiceObject = calculateInvoiceFromObject(booking);
        let discount = 0;
        if (this.props.show_discount) {
            for (const charge of this.props.booking.charge_data.charges) {
                if (charge.discount) {
                    discount += charge.discount;
                }
            }
        }
        this.sum = 0;
        let win;
        if (this.props.show_win) {
            win = calculateLandlordWin(invoiceObject.day_count, invoiceObject.total_with_discount, proTool);
        }

        return(
            <div className="booking-overview-table ">
                <table className="cost-table">
                    <tbody>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>{this.props.detailed ? getLanguageEntry("components>invoice_table>headers>net") : ''}</th>
                        <th>{this.props.detailed ? getLanguageEntry("components>invoice_table>headers>tax") : ''}</th>
                        <th>{getLanguageEntry("components>invoice_table>headers>gross")}</th>
                    </tr>
                    {
                        invoiceObject.day_count < 30 &&
                        this.createDailyFeeRow(pricingObject, invoiceObject)
                    }
                    {
                        invoiceObject.day_count > 29 &&
                        this.createMonthlyFeeRow(pricingObject, invoiceObject)
                    }
                    {
                        (!!invoiceObject.discounts && invoiceObject.discounts > 0) &&
                        this.createDiscountRow(pricingObject, invoiceObject)
                    }
                    {
                        invoiceObject.day_count > 29 &&
                        this.createCostRows(pricingObject).map((row, _) => row)
                    }
                    {
                        this.createFeeRows(pricingObject, invoiceObject).map((row, _) => row)
                    }
                    {
                        this.props.show_discount && discount > 0 &&
                        <tr>
                            <td>{getLanguageEntry("processes>booking>roomjack_discount_text")}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{priceToString(discount)}</td>
                        </tr>
                    }
                    <tr className="sum">
                        <td>{getLanguageEntry("jackboard>adverts>advert_creator>pricing>" +
                            (pricingObject.long_term ? "monthly_" : "") + "sum")}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{priceToString(pricingObject.long_term ? invoiceObject.rent_per_unit : invoiceObject.total_with_discount)}</td>
                    </tr>
                    {
                        this.props.show_win && win &&
                        <tr className="win">
                            <td>{getLanguageEntry("jackboard>adverts>advert_creator>pricing>win")}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                                {priceToString(win)}
                                <a href="/faq-help?category=general&topic=roomjack_fees" target="_blank"
                                   rel="noreferrer nofollow"
                                   title={getLanguageEntry("jackboard>adverts>advert_creator>pricing>stripe_fee_info")}>
                                    <FontAwesomeIcon icon={["fas", "fa-info-square"]} />
                                </a>
                            </td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
        )
    }

    createDailyFeeRow(pricingObject, invoiceObject) {
        let dailyFeeTax = 0;
        let dailyFeeTaxPrice = 0;
        let dailyFeeTaxPriceIncl = 0;
        if (pricingObject.tax) {
            dailyFeeTax = pricingObject.tax.percent;
            dailyFeeTaxPrice = calculateIncludedTax(pricingObject.tax, invoiceObject.base_fee);
            dailyFeeTaxPriceIncl = pricingObject.tax.included ?
                calculateIncludedTax(pricingObject.tax, pricingObject.rent) : 0;
        }
        this.sum += invoiceObject.base_fee;
        return <tr>
            <td>
                {getLanguageEntry("components>invoice_table>column_names>daily_rental_fee")}
            </td>
            <td>
                {
                    `${getCountLabel(invoiceObject.day_count, 'night')} ${getLanguageEntry("components>invoice_table>at")} ${priceToString(pricingObject.rent - dailyFeeTaxPriceIncl)}`
                }
            </td>
            <td>
                {
                    this.props.detailed && priceToString(invoiceObject.base_fee - dailyFeeTaxPrice)
                }
            </td>
            <td>
                {
                    this.props.detailed ?
                        dailyFeeTax + '% (' + priceToString(dailyFeeTaxPrice) + ')' : ''
                }
            </td>
            <td>{priceToString(invoiceObject.base_fee)}</td>
        </tr>
    }

    createMonthlyFeeRow(pricingObject, invoiceObject) {
        let monthlyFeeTax = 0;
        let monthlyFeeTaxPrice = 0;
        if (pricingObject.tax) {
            monthlyFeeTax = pricingObject.tax.percent;
            monthlyFeeTaxPrice = calculateIncludedTax(pricingObject.tax, pricingObject.rent);
        }
        this.sum += invoiceObject.rent;
        return <tr>
            <td>
                {getLanguageEntry("components>invoice_table>column_names>monthly_rental_fee")}
            </td>
            <td></td>
            <td>
                {
                    this.props.detailed && priceToString(pricingObject.rent - monthlyFeeTaxPrice)
                }
            </td>
            <td>
                {
                    this.props.detailed ?
                        monthlyFeeTax + '% (' + priceToString(monthlyFeeTaxPrice) + ')' : ''
                }
            </td>
            <td>{priceToString(invoiceObject.rent)}</td>
        </tr>
    }

    createDiscountRow(pricingObject, invoiceObject) {
        this.sum -= invoiceObject.discounts;
        return <tr>
            <td>{getLanguageEntry("components>invoice_table>column_names>granted_discount")}</td>
            <td></td>
            <td></td>
            <td></td>
            <td>-{priceToString(invoiceObject.discounts)}</td>
        </tr>
    }

    createFeeRows(pricingObject, invoiceObject) {
        let rows = [];
        if (pricingObject.additional_fees) {
            for (const fee of pricingObject.additional_fees) {
                if (isFeeObjectIncomplete(fee)) {
                    continue;
                }
                let feeName = fee.name;
                if (PREDEFINED_FEE_TYPES[fee.name] || PREDEFINED_LONG_TERM_FEE_TYPES[fee.name]) {
                    feeName = getLanguageEntry("jackboard>adverts>advert_creator>pricing>additional_fees>types>" + fee.name);
                }
                let feeTax = 0;
                let feeTaxPrice = 0;
                if (fee.tax) {
                    feeTax = fee.tax.percent;
                    if (fee.tax.included) {
                        feeTaxPrice = calculateIncludedTax(fee.tax, fee.price);
                    }
                    else {
                        feeTaxPrice = calculateTax(fee.tax, fee.price);
                    }
                }

                let feePrice = calculateFee(fee);
                if (invoiceObject.day_count > 30) {
                    feePrice = Math.round(feePrice / invoiceObject.day_count) * 30;
                    feeTaxPrice = Math.round(feeTaxPrice / invoiceObject.day_count) * 30;
                }
                this.sum += feePrice;
                rows.push(
                    <tr key={_uniqueId(fee.name)}>
                        <td>{feeName}</td>
                        <td></td>
                        <td>{this.props.detailed && priceToString(feePrice - feeTaxPrice)}</td>
                        <td>
                            {
                                (this.props.detailed) &&
                                feeTax + '% (' + priceToString(feeTaxPrice) + ')'
                            }
                        </td>
                        <td>{priceToString(feePrice)}</td>
                    </tr>
                );
            }
        }
        return rows;
    }

    createCostRows(pricingObject) {
        let rows = [];
        if (pricingObject.running_costs !== undefined) {
            for (const runningCost of pricingObject.running_costs) {
                if (isRunningCostIncomplete(runningCost)) {
                    continue;
                }
                let runningCostName = runningCost.name;
                if (PREDEFINED_RUNNING_COSTS[runningCost.name]) {
                    runningCostName = getLanguageEntry("jackboard>adverts>advert_creator>pricing>running_costs>types>" + runningCost.name);
                }
                let costTax = 0;
                let costTaxPrice = 0;
                if (runningCost.tax) {
                    costTax = runningCost.tax.percent;
                    if (runningCost.tax.included) {
                        costTaxPrice = calculateIncludedTax(runningCost.tax, runningCost.price);
                    }
                    else {
                        costTaxPrice = calculateTax(runningCost.tax, runningCost.price);
                    }
                }

                let runningCostPrice = calculateFee(runningCost);
                this.sum += runningCostPrice;
                rows.push(
                    <tr key={_uniqueId(runningCost.name)}>
                        <td>{runningCostName}</td>
                        <td></td>
                        <td>{this.props.detailed && priceToString(runningCostPrice - costTaxPrice)}</td>
                        <td>
                            {
                                (this.props.detailed) &&
                                costTax + '% (' + priceToString(costTaxPrice) + ')'
                            }
                        </td>
                        <td>{priceToString(runningCostPrice)}</td>
                    </tr>
                )
            }
        }
        return rows;
    }
}

BookingInvoiceTable.propTypes = {
    className: PropTypes.string,
    booking: PropTypes.object,
    detailed: PropTypes.bool,
    show_win: PropTypes.bool,
    show_discount: PropTypes.bool.isRequired
}
BookingInvoiceTable.defaultProps = {
    detailed: false
}
export default BookingInvoiceTable;