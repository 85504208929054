import React from 'react';
import * as PropType from "prop-types";
import {Link} from "react-router-dom";

class Avatar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            style: this.getBasicStyle()
        }
        if (props.data !== undefined) {
            this.loadImage();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.data === undefined && this.props.data !== undefined) ||
            (prevProps.data !== undefined && this.props.data !== undefined &&
             JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data))) {
            this.loadImage();
        }
        if (prevProps.data !== undefined && this.props.data === undefined) {
            this.setState({style: this.getBasicStyle()})
        }
    }

    render() {
        return(
            this.props.href ?
                <Link to={this.props.href}>
                    <div className="avatar-container" style={this.state.style}>
                        {this.props.children}
                    </div>
                </Link> :
                <div className="avatar-container" style={this.state.style}>
                    { this.props.children}
                </div>
        )
    }

    getBasicStyle() {
        return {
            width: this.props.size + "px",
            height: this.props.size + "px",
            borderRadius: (this.props.size / 2) + "px",
            backgroundImage: "url(https://roomjack.blob.core.windows.net/roomjack/content-images/genderless_avatar.png)",
            backgroundPositionX: "0",
            backgroundPositionY: "0",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            cursor: this.props.href ? "pointer" : "initial",
            border: "1px solid lightgray"
        }
    }
    
    loadImage() {
        let that = this;
        let image = new Image();
        image.src = this.props.data.image;
        image.onload = function() {
            let style = JSON.parse(JSON.stringify(that.state.style));
            style.backgroundImage = "url(" + that.props.data.image + ")";
            style.backgroundPositionX = (that.props.size * that.props.data.x) + "px";
            style.backgroundPositionY = (that.props.size * that.props.data.y) + "px";
            style.backgroundSize = (that.props.data.zoom * 100) + "%";
            that.setState({ style: style })
        }
    }

}
Avatar.propTypes = {
    size: PropType.number.isRequired,
    data: PropType.exact({
        image: PropType.string.isRequired,
        x: PropType.number.isRequired,
        y: PropType.number.isRequired,
        zoom: PropType.number.isRequired
    }),
    href: PropType.string
}
export default Avatar;