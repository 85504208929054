import React from 'react';
import {Col, Form, Modal, Row} from "react-bootstrap";
import {
    EMAIL, FIRST_NAME, LAST_NAME, MAX_MESSAGE_LENGTH, PHONE, PRESET_REPORT_REASONS, REASON, SELECTABLE_REPORT_REASONS,
    getLanguageEntry
} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TextArea from "../input/TextArea";
import Dropdown from "../input/Dropdown";
import "../../../css/components/modals/report-modal.css";
import PropTypes from "prop-types";
import PhoneInputField from "../input/PhoneInputField";
import {sendReport} from "../../utils/RESTInterface";
import FetchButton from "../buttons/FetchButton";

class ReportModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            reason: null,
            target_id: null,
            response: null,
            sending: false
        }
        this.report_form = React.createRef();
    }

    render() {
        return (
            <Modal className="report-modal" show={this.state.show}
                   onHide={() => { this.hide() }} dialogClassName="max-content" centered>
                <Modal.Header>
                    <h4>{this.getTitle()}</h4>
                    <button onClick={() => { this.hide() }}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>

                <Modal.Body>
                    {
                        this.state.response === null &&
                        <div className="report-modal-body">
                            <form ref={this.report_form} onSubmit={(e) => { this.sendReport(e) }}>
                                {
                                    (this.state.reason === null || !PRESET_REPORT_REASONS.includes(this.state.reason)) &&
                                    <Form.Group as={Col} controlId={REASON} className="mb-2">
                                        <Form.Label className="description-container v-form-label" column>
                                            {getLanguageEntry("processes>report>reason")}*
                                        </Form.Label>
                                        <Dropdown className="report-reason-selector" name={REASON} required={true}
                                                  items={this.getReportReasonItems()} initialValue={this.state.reason}
                                                  onChange={(e, reason) => {
                                                      e.preventDefault();
                                                      this.setState({reason: reason})
                                                  }}/>
                                    </Form.Group>
                                }
                                {
                                    (this.props.userData === undefined || this.props.userData === null) &&
                                    <div className="public-session-fieldset">
                                        <Form.Group className="report-name-block mb-2" as={Row}>
                                            <Form.Group as={Col} controlId={FIRST_NAME}>
                                                <Form.Label className="description-container v-form-label" column>
                                                    {getLanguageEntry("general>first_name")}*
                                                </Form.Label>
                                                <Form.Control type="text" name={FIRST_NAME} required />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId={LAST_NAME}>
                                                <Form.Label className="description-container v-form-label" column>
                                                    {getLanguageEntry("general>last_name")}*
                                                </Form.Label>
                                                <Form.Control type="text" name={LAST_NAME} required />
                                            </Form.Group>
                                        </Form.Group>
                                        <Form.Group className="mb-2" as={Col} controlId={EMAIL}>
                                            <Form.Label className="description-container v-form-label" column>
                                                {getLanguageEntry("general>email")}*
                                            </Form.Label>
                                            <Form.Control type={EMAIL} name={EMAIL}
                                                          placeholder={getLanguageEntry("general>email_placeholder")}
                                                          required />
                                        </Form.Group>
                                        <Form.Group className="mb-2" as={Col}>
                                            <Form.Label className="description-container v-form-label" column>
                                                {getLanguageEntry("general>phone")}
                                            </Form.Label>
                                            <PhoneInputField name={PHONE}/>
                                        </Form.Group>
                                    </div>
                                }
                                <TextArea label="processes>report>report_statement_label" maxLength={MAX_MESSAGE_LENGTH}
                                          name="statement" required={true} className="mb-4"/>
                                <FetchButton className="send-report accent-icon-button" type="submit"
                                             loading={this.state.sending} loadingText={"processes>report>sending"}>
                                    <FontAwesomeIcon icon={["fas", "bullhorn"]} />
                                    <span>{getLanguageEntry("general>post")}</span>
                                </FetchButton>
                            </form>
                        </div>
                    }
                    {
                        this.state.response !== null &&
                        <div className="process-modal-result-container">
                            <div className="process-result-image">
                                <img src={this.state.response.success ?
                                    "https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/hardfacts/thumbs_up.jpg" :
                                    "https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear.png"}
                                     alt={this.state.response.success ? "Report success" : "Report failed"} />
                            </div>
                            <div className="process-result-message-box">
                                <div className="header">
                                    {getLanguageEntry("processes>report>" +
                                        (this.state.response.success ? "report_successful_sent" : "send_report_failed") + "_header")}
                                </div>
                                <div className="description-container">
                                    {getLanguageEntry("processes>report>" +
                                        (this.state.response.success ? "report_successful_sent" : "send_report_failed") + "_description")}
                                </div>
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        )
    }

    getTitle() {
        if (PRESET_REPORT_REASONS.includes(this.state.reason)) {
            return getLanguageEntry("processes>report>report_reasons>" + this.state.reason);
        }
        return getLanguageEntry("processes>report>contact_form_header");
    }

    show(targetID, reason) {
        this.setState({
            show: true,
            reason: reason,
            target_id: targetID
        });
    }

    hide() {
        this.setState({
            show: false,
            reason: null,
            target_id: null,
            response: null,
            sending: false
        });
    }

    getReportReasonItems() {
        return SELECTABLE_REPORT_REASONS.map((r, i) => {
            return {
                value: r,
                label: "processes>report>report_reasons>" + r
            }
        });
    }

    sendReport(e) {
        e.preventDefault();
        let reportForm = new FormData(this.report_form.current);
        let body = { reason: this.state.reason };
        body.statement = reportForm.get("statement");
        if (reportForm.get(FIRST_NAME)) {
            body.first_name = reportForm.get(FIRST_NAME);
            body.last_name = reportForm.get(LAST_NAME);
            body.email = reportForm.get(EMAIL);
            if (reportForm.get(PHONE)) {
                body.phone = '+' + reportForm.get(PHONE);
            }
        }
        let that = this;
        this.setState({sending: true})
        sendReport(this.state.target_id, body, function(response) {
            that.setState({ response: response, sending: false });
        });

    }


}
ReportModal.propTypes = {
    userData: PropTypes.object
}
ReportModal.defaultProps = {

}
export default ReportModal;