import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {
    compareDates, getDayCountBetweenDates,
    getDayFromBegin,
    getFormattedDate,
    getLanguageEntry,
    getMonthBegin, getNextDay, getNightsBetweenDates,
    isLeapYear, LeapYearDays, NormalYearDays
} from "../utils/Helper";
import "../../css/components/calendar.css";
import PropTypes from "prop-types";

function CalendarButton(props) {

    return (
        <div className={"calendar-button" + (props.date === null ? " empty" : "") +
            (props.selected ? " selected" : "") + " " + (props.className)}
            onClick={() => {props.onClick?.()}} tabIndex={0}>
            { props.icon !== undefined && <FontAwesomeIcon icon={props.icon} /> }
            <span>
                {
                    props.date === null ?
                        getLanguageEntry(props.placeholder) :
                        getFormattedDate(props.date, true, false, false, true, false, false)
                }
            </span>
            <button onClick={(e) => {
                props.cancel();
                e.target.parentNode.parentNode.parentNode.parentNode.focus();
            }}>
                <FontAwesomeIcon icon={["fal", "times"]} />
            </button>
        </div>
    )
}
CalendarButton.propTypes = {
    date: PropTypes.instanceOf(Date),
    icon: PropTypes.arrayOf(PropTypes.string),
    onClick: PropTypes.func
}

class Calendar extends React.Component {

    firstActiveMonth = getMonthBegin(getDayFromBegin());

    constructor(props) {
        super(props);
        this.state = {
            selectedStartDate: null,
            selectedEndDate: null,
            startSelected: props.initialSelected === "start",
            endSelected: props.initialSelected === "end",
            actualActiveMonth: getMonthBegin(getDayFromBegin()),
            show: false,
            monthsPerSite: window.innerWidth >= 750 ? props.monthsPerSite : 1
        }
        this.calendar = React.createRef();
    }

    render() {
        let classList = ["calendar"];
        if (this.props.hideOnBlur) {
            classList.push("hide");
        }
        if (this.props.classList && this.props.classList.length > 0) {
            classList = classList.concat(this.props.classList);
        }
        return (
            <div className={classList.join(" ")}
                 ref={this.calendar}
                 tabIndex={0} onBlur={(e) => { this.handleFocus(e)}}>
                <div className={"calendar-button-container" + (this.props.labels ? " labeled" : "")}>
                    {
                        this.props.labels &&
                        <div className="start time-label">{getLanguageEntry(this.props.labels.start)}</div>
                    }
                    {
                        this.props.labels &&
                        <div className="gap" />
                    }
                    {
                        this.props.labels &&
                        <div className="end time-label">{getLanguageEntry(this.props.labels.end)}</div>
                    }
                    <CalendarButton className="start" icon={this.props.icon} placeholder="general>from"
                                    date={this.state.selectedStartDate} onClick={() => { this.onStartClick() }}
                                    selected={this.state.startSelected} cancel={() => { this.onStartCancel() }}/>
                    <div className="calendar-dash"><FontAwesomeIcon icon={["fal", "minus"]} /></div>
                    <CalendarButton className="end" placeholder="general>to" date={this.state.selectedEndDate}
                                    onClick={() => { this.onEndClick() }} selected={this.state.endSelected}
                                    cancel={() => { this.onEndCancel() }}/>
                </div>
                {
                    (!this.props.hideOnBlur || this.state.show) &&
                    <div className={"calendar-container " + this.props.hAlign}>
                        <div className={"calendar-main-container" + (this.state.monthsPerSite === 1 ? " single" : "")}>
                            <div className="calendar-table-container">
                                <div className="month-header">
                                    <div className={"site-arrow bw c-flex" + (!this.props.allowPast && this.isAtStart() ? " disabled" : "")}
                                         onClick={() => {
                                             if (this.props.allowPast || !this.isAtStart()) {
                                                 this.goToPreviousMonth();
                                             }
                                         }}>
                                        <FontAwesomeIcon icon={["fal", "chevron-left"]}/>
                                    </div>
                                    <div className="month-display">{this.getHeaderText(0)}</div>
                                    {
                                        this.state.monthsPerSite === 1 &&
                                        <div className={"site-arrow fw c-flex"} onClick={() => { this.goToNextMonth() }}>
                                            <FontAwesomeIcon icon={["fal", "chevron-right"]}/>
                                        </div>
                                    }
                                </div>
                                {this.generateCalendarTable(0)}
                            </div>
                            {
                                this.state.monthsPerSite === 2 &&
                                <div className="calendar-table-container">
                                    <div className="month-header">
                                        <div className="month-display">{this.getHeaderText(1)}</div>
                                        <div className={"site-arrow fw c-flex"} onClick={() => { this.goToNextMonth() }}>
                                            <FontAwesomeIcon icon={["fal", "chevron-right"]}/>
                                        </div>
                                    </div>
                                    {this.generateCalendarTable(1)}
                                </div>
                            }
                        </div>
                        {
                            this.props.useFooter &&
                            <div className="calendar-footer">{this.getFooterText()}</div>
                        }
                        {this.props.children}
                    </div>
                }
            </div>
        )
    }

    getSelectedStart() { return this.state.selectedStartDate; }

    getSelectedEnd() { return this.state.selectedEndDate; }

    setDates(start, end, startSelected=false, endSelected=false, jumpTo) {
        let actualActiveMonth = this.state.actualActiveMonth;
        if (start && jumpTo === "start") {
            actualActiveMonth = getMonthBegin(start);
        }
        if (end && jumpTo === "end") {
            actualActiveMonth = getMonthBegin(end);
        }
        this.setState({
            selectedStartDate: start,
            selectedEndDate: end,
            startSelected: startSelected,
            endSelected: endSelected,
            actualActiveMonth: actualActiveMonth
        })
    }

    generateCalendarTable(index) {
        let that = this;
        let startDate = new Date(this.state.actualActiveMonth.getFullYear(),
            this.state.actualActiveMonth.getMonth() + index, 1);
        let dayArray = isLeapYear(startDate.getFullYear()) ? LeapYearDays : NormalYearDays;
        let dayCount = dayArray[startDate.getMonth()] + Math.abs(1 - (startDate.getDay() === 0 ? 7 : startDate.getDay()));
        let rows = Math.ceil(dayCount / 7);
        let monthStartReached = startDate.getDay() === 1;
        let monthEndReached = false;
        return <table>
            <tbody>
            <tr className="row header">
                {Array.from(Array(7)).map((_, i) => {
                    return <th className="cell" key={"header-cell-" + i}>
                        {getLanguageEntry("general>day_names_short>" + ((i + 1) % 7))}
                    </th>
                })}
            </tr>
            {
                Array.from(Array(rows)).map((_, i) => {
                    return <tr className="row" key={"month-row-" + i}>
                        {
                            Array.from(Array(7)).map((_, d) => {
                                let thisDate = new Date(startDate);
                                if (!monthStartReached) {
                                    let day = startDate.getDay() === 0 ? 6 : startDate.getDay() - 1;
                                    monthStartReached = day === d;
                                }
                                let cellContent = "";
                                let classList = ["cell"];
                                let event = null;
                                if (monthStartReached) {
                                    if (!monthEndReached) {
                                        monthEndReached = startDate.getDate() === dayArray[startDate.getMonth()];
                                        cellContent = '' + startDate.getDate();
                                        if (this.props.markDays) {
                                            let dayOfWeek = startDate.getDay();
                                            if (dayOfWeek === 0) {
                                                dayOfWeek = 7;
                                            }
                                            if (this.props.markDays.includes('' + dayOfWeek)) {
                                                classList.push("day-of-week-marker");
                                            }
                                        }
                                        if (!this.props.allowPast && compareDates(startDate, new Date()) < 0) {
                                            classList.push('disabled');
                                        }
                                        else {
                                            event = () => {
                                                let nextStart = that.calculateStartDate(thisDate);
                                                let nextEnd = that.calculateEndDate(thisDate);
                                                let startSelected = nextStart === null || (that.state.startSelected && nextEnd !== null);
                                                let endSelected = nextEnd === null || (that.state.endSelected && nextStart !== null)
                                                that.setDates(nextStart, nextEnd, startSelected, endSelected);
                                                that.props.onChange?.({start: nextStart, end: nextEnd});
                                            }
                                        }
                                        if (that.state.selectedStartDate !== null &&
                                            compareDates(startDate, that.state.selectedStartDate) === 0) {
                                            classList.push('selected');
                                            classList.push('start');
                                        }
                                        if (that.state.selectedEndDate !== null &&
                                            compareDates(startDate, that.state.selectedEndDate) === 0) {
                                            classList.push('selected');
                                            classList.push('end');
                                        }
                                        if (that.state.selectedStartDate !== null && that.state.selectedEndDate !== null &&
                                            compareDates(startDate, that.state.selectedStartDate) > 0 &&
                                            compareDates(startDate, that.state.selectedEndDate) < 0) {
                                            classList.push('between-selected');
                                        }
                                        if (startDate.getDate() < dayArray[startDate.getMonth()]) {
                                            startDate = getNextDay(startDate);
                                        }
                                    }
                                }
                                return <td className={classList.join(" ")} key={"month-cell-" + d} onClick={event}>{cellContent !== "" ? <div>{cellContent}</div> : ""}</td>
                            })
                        }
                    </tr>
                })
            }
            </tbody>
        </table>
    }

    getHeaderText(index) {
        let date = index === 0 ? this.state.actualActiveMonth :
            new Date(this.state.actualActiveMonth.getFullYear(), this.state.actualActiveMonth.getMonth() + 1, 1);
        return getFormattedDate(date, false, false, false, false);
    }

    getFooterText() {
        if (this.props.countDays) {
            if (this.state.selectedStartDate !== null) {
                let formattedStart = getFormattedDate(this.state.selectedStartDate, true, false, true, true, true);
                let formattedEnd = null;
                let dayCount = 0;
                if (this.selectedEndDate !== null) {
                    formattedEnd = getFormattedDate(this.state.selectedEndDate, true, false, true, true, true);
                    dayCount = getDayCountBetweenDates(this.state.selectedEndDate, this.state.selectedStartDate);
                }
                let dayLabel = dayCount > 1 ? getLanguageEntry("general>days") : getLanguageEntry("general>day");
                let result = document.createElement('div');
                if (this.props.monthsPerSite > 1) {
                    result.appendChild(Object.assign(
                        document.createElement('div'),
                        {
                            innerHTML: formattedStart + ' - ' + (formattedEnd !== null ? formattedEnd : '') +
                                (dayCount > 0 ? " (" + dayCount + " " + dayLabel + ")" : "")
                        }));
                }
                else {
                    result.appendChild(Object.assign(
                        document.createElement('div'),
                        {
                            innerHTML: getLanguageEntry("general>from") + ": " + formattedStart
                        }));
                    result.appendChild(Object.assign(
                        document.createElement('div'),
                        {
                            innerHTML: getLanguageEntry("general>to") + ": " + formattedEnd
                        }));
                    result.appendChild(Object.assign(
                        document.createElement('div'),
                        {
                            innerHTML: getLanguageEntry("general>days") + ': ' + dayCount
                        }
                    ))
                }
                return result;
            }
            else if (this.selectedEndDate !== null) {
                return getFormattedDate(this.selectedEndDate, true, false, true, true, true);
            }
        }
        else {
            if (this.state.selectedStartDate !== null && this.state.selectedEndDate !== null) {
                let formattedStart = getFormattedDate(this.state.selectedStartDate, true, false, true, true, true);
                let formattedEnd = getFormattedDate(this.state.selectedEndDate, true, false, true, true, true);
                let nightCount = getNightsBetweenDates(this.state.selectedEndDate, this.state.selectedStartDate);
                let nightLabel = nightCount > 1 ? getLanguageEntry("general>nights") : getLanguageEntry("general>night");
                if (this.props.monthsPerSite > 1) {
                    return <div>{formattedStart + ' - ' + (formattedEnd ?? '') + ' (' + nightCount + ' ' + nightLabel + ')'}</div>
                }
                return <div>
                    <div>{getLanguageEntry("general>from") + ": " + formattedStart}</div>
                    <div>{getLanguageEntry("general>to") + ": " + formattedEnd}</div>
                    <div>{getLanguageEntry("general>nights") + ": " + nightCount}</div>
                </div>;
            }
        }
    }

    goToNextMonth() {
        this.setState({
            actualActiveMonth: new Date(
                this.state.actualActiveMonth.getFullYear(),
                this.state.actualActiveMonth.getMonth() + 1,
                1)
        });
    }

    goToPreviousMonth() {
        this.setState({
            actualActiveMonth: new Date(
                this.state.actualActiveMonth.getFullYear(),
                this.state.actualActiveMonth.getMonth() - 1,
                1)
        });
    }

    componentDidMount() {
        window.addEventListener("resize", () => { this.handleCalendarResize(); });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => { this.handleCalendarResize(); });
    }

    handleCalendarResize() {
        if (window.innerWidth < 750 && this.state.monthsPerSite > 1) {
            this.setState({monthsPerSite: 1});
        }
        if (window.innerWidth >= 750 && this.state.monthsPerSite < 2) {
            this.setState({monthsPerSite: 2});
        }
    }

    onStartClick() {
        let actualActiveMonth = this.state.selectedStartDate !== null ?
            getMonthBegin(this.state.selectedStartDate) : this.firstActiveMonth;
        this.setState({
            startSelected: true,
            endSelected: false,
            show: true,
            actualActiveMonth: actualActiveMonth
        }, () => {
            this.calendar.current.focus();
        });
    }

    onEndClick() {
        let actualActiveMonth = this.state.selectedEndDate !== null ?
            getMonthBegin(this.state.selectedEndDate) : this.firstActiveMonth;
        this.setState({
            startSelected: false,
            endSelected: true,
            show: true,
            actualActiveMonth: actualActiveMonth
        }, () => {
            this.calendar.current.focus();
        });
    }

    onStartCancel() {
        this.setState({
            show: true,
            selectedStartDate: null,
            startSelected: true,
            endSelected: false
        });
        this.props.onChange?.({start: null, end: this.state.selectedEndDate});
    }

    onEndCancel() {
        this.setState({
            show: true,
            selectedEndDate: null,
            startSelected: false,
            endSelected: true
        });
        this.props.onChange?.({start: this.state.selectedStartDate, end: null});
    }

    calculateStartDate(nextDate) {
        if (this.state.startSelected) {
            return nextDate;
        }
        else {
            if (this.state.selected_charge !== null && nextDate !== null) {
                let difference = compareDates(nextDate, this.state.selectedStartDate);
                let allowedDifference = this.props.countDays ? 0 : 1;
                if (difference < 0) {
                    return nextDate;
                }
                else if (difference < allowedDifference) {
                    return null;
                }
            }
            return this.state.selectedStartDate;
        }
    }

    calculateEndDate(nextDate) {
        let allowedDifference = this.props.countDays ? 0 : 1;
        if (this.state.startSelected) {
            if (this.state.selectedEndDate !== null && nextDate !== null &&
                compareDates(this.state.selectedEndDate, nextDate) < allowedDifference) {
                return null;
            }
            return this.state.selectedEndDate;
        }
        else {
            if (this.state.selectedStartDate !== null && nextDate !== null &&
                compareDates(this.state.selectedStartDate, nextDate) > 0) {
                return null;
            }
            return nextDate;
        }
    }

    onHide() {
        this.setState({
            show: false,
            startSelected: false,
            endSelected: false
        });
    }

    isAtStart() {
        return compareDates(this.state.actualActiveMonth, this.firstActiveMonth) === 0;
    }

    handleFocus(e) {
        if (!this.calendar.current.contains(e.relatedTarget)) {
            this.onHide();
        }
        else {
            this.calendar.current.focus();
        }
    }

}
Calendar.propTypes = {
    countDays: PropTypes.bool,
    monthsPerSite: PropTypes.oneOf([1, 2]),
    onChange: PropTypes.func,
    hAlign: PropTypes.oneOf([
        "left", "center", "right"
    ]),
    icon: PropTypes.arrayOf(PropTypes.string),
    labels: PropTypes.exact({
        start: PropTypes.string,
        end: PropTypes.string
    }),
    hideOnBlur: PropTypes.bool,
    useFooter: PropTypes.bool,
    markDays: PropTypes.string,
    classList: PropTypes.arrayOf(PropTypes.string),
    initialSelected: PropTypes.oneOf(["start", "end"]),
    allowPast: PropTypes.bool,
}
Calendar.defaultProps = {
    countDays: false,
    monthsPerSite: 2,
    hAlign: "center",
    hideOnBlur: true,
    useFooter: true,
    allowPast: false,
}
export default Calendar;