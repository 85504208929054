import React from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getLanguageEntry} from "../../utils/Helper";

const LANG_PATH = "jackboard>todos>";

class ToDoList extends React.Component {

    render() {
        let checkIns = this.props.data.check_ins;
        let checkOuts = this.props.data.check_outs;
        return <div className="page header">
            <div id="todo-title">
                {getLanguageEntry(LANG_PATH + "printable>header")}
            </div>
            <div className="todo-body">
                <div id="jack-in-container" className="data-table-container">
                    <div className="roomjack-headline">
                        {getLanguageEntry(LANG_PATH + "widget_labels>check_ins")}
                    </div>
                    <table className="todo-table check-in">
                        <tbody>
                            <tr className="header">
                                <th>
                                    {getLanguageEntry(LANG_PATH + "table_headers>booker_name")}
                                </th>
                                <th>
                                    {getLanguageEntry(LANG_PATH + "table_headers>housing_id")}
                                </th>
                                <th>
                                    {getLanguageEntry(LANG_PATH + "table_headers>date")}
                                </th>
                                <th>
                                    {getLanguageEntry(LANG_PATH + "table_headers>jack_in_time")}
                                </th>
                                <th>
                                    {getLanguageEntry(LANG_PATH + "table_headers>key_handover")}
                                </th>
                                <th>
                                    {getLanguageEntry(LANG_PATH + "table_headers>process_status")}
                                </th>
                            </tr>
                            {
                                checkIns.length > 0 &&
                                checkIns.map((checkIn, _) => {
                                    return <tr>
                                        <td>{checkIn.booker}</td>
                                        <td>{checkIn.housing_id}</td>
                                        <td>{checkIn.date}</td>
                                        <td>{checkIn.check_in_time}</td>
                                        <td>{checkIn.access}</td>
                                        <td>
                                            {
                                                checkIn.checked &&
                                                <FontAwesomeIcon icon={["fal", "check"]} />
                                            }
                                        </td>
                                    </tr>
                                })
                            }
                            {
                                checkIns.length === 0 &&
                                <tr>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div id="jack-out-container" className="data-table-container">
                    <div className="roomjack-headline">
                        {getLanguageEntry(LANG_PATH + "widget_labels>check_outs")}
                    </div>
                    <table className="todo-table check-out">
                        <tbody>
                            <tr className="header">
                                <th>
                                    {getLanguageEntry(LANG_PATH + "table_headers>booker_name")}
                                </th>
                                <th>
                                    {getLanguageEntry(LANG_PATH + "table_headers>housing_id")}
                                </th>
                                <th>
                                    {getLanguageEntry(LANG_PATH + "table_headers>date")}
                                </th>
                                <th>
                                    {getLanguageEntry(LANG_PATH + "table_headers>jack_out_time")}
                                </th>
                                <th>
                                    {getLanguageEntry(LANG_PATH + "table_headers>key_back")}
                                </th>
                                <th>
                                    {getLanguageEntry(LANG_PATH + "table_headers>cleaning")}
                                </th>
                            </tr>
                            {
                                checkOuts.length > 0 &&
                                checkOuts.map((checkOut, _) => {
                                    return <tr>
                                        <td>{checkOut.booker}</td>
                                        <td>{checkOut.housing_id}</td>
                                        <td>{checkOut.date}</td>
                                        <td>{checkOut.check_out_time}</td>
                                        <td>
                                            {
                                                checkOut.checked &&
                                                <FontAwesomeIcon icon={["fal", "check"]} />
                                            }
                                        </td>
                                        <td>
                                            {
                                                checkOut.cleaning_checked &&
                                                <FontAwesomeIcon icon={["fal", "check"]} />
                                            }
                                        </td>
                                    </tr>
                                })
                            }
                            {
                                checkOuts.length === 0 &&
                                <tr>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }

}
ToDoList.propTypes = {
    data: PropTypes.any.isRequired
}
export default ToDoList;