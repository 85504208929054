import {getTimeUnitInMS, TIME_UNIT_VALUE_MAP} from "../Helper.js";

class InvoiceCache {

    private invoices:Map<string, Array<object>>;
    private timestamp:number;
    private readonly lifetime = getTimeUnitInMS(1, TIME_UNIT_VALUE_MAP.HOUR);

    constructor() {
        this.invoices = new Map<string, Array<object>>();
    }

    public getInvoices(interval) {
        if (Date.now() >= this.timestamp + this.lifetime) {
            return null;
        }
        return this.invoices.get(interval);
    }

    public setCache(interval:string, data:Array<object>) {
        this.timestamp = Date.now();
        console.log(Date.now());
        this.invoices.set(interval, data);
    }

    public isValid() {
        return Date.now() < this.timestamp + this.lifetime
    }

    public reset() {
        this.timestamp = 0;
        this.invoices.clear();
    }

}
export default InvoiceCache;