import React from "react";
import PropTypes from "prop-types";
import {AdvertEditorTab, AdvertType} from "../../../../../utils/Types.ts";
import AdvertEditorBlock from "../../../../../components/advert/AdvertEditorBlock";
import AdvertHierarchy from "../../../../../components/advert/AdvertHierarchy";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    getCountLabel,
    getLanguageEntry,
    MAX_BOOKING_LENGTH,
    MIN_BOOKING_LENGTH,
    priceToString
} from "../../../../../utils/Helper";
import {Link} from "react-router-dom";
import PricingModal from "../../../../../components/modals/PricingModal";
import CancellationConditionEditor from "../../../../../components/advert/CancellationConditionEditor";
import _uniqueId from "lodash/uniqueId";
import {rj_landlord_theme} from "../../../../../utils/MaterialUIColorTheme";
import {Slider, ThemeProvider} from "@mui/material";
import CustomToggle from "../../../../../components/input/CustomToggle";
import {RENTABLE_ROOM_TYPES} from "../../../../../../resources/housings";

const LANG_PATH = "jackboard>adverts>advert_creator>";

class PricingTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            edited_advert: JSON.parse(JSON.stringify(props.advert)),
            cancellation_creator: false,
        }
        this.pricing_modal = React.createRef();
    }

    extractData() {
        return this.state.edited_advert;
    }

    canHousingHavePricing(housing) {
        return ((this.state.edited_advert.rooms_bookable &&
                housing.id && housing.id.startsWith("room") &&
                    RENTABLE_ROOM_TYPES.includes(housing.type)) ||
            (!this.state.edited_advert.rooms_bookable &&
                (!housing.id || !housing.id.startsWith("room"))));
    }

    render() {
        let notBookable = this.props.requirements.bookable;
        let missingPricing = Object.keys(this.props.requirements)
            .filter(r => r !== "main" && r !== "bookable" && r !== "cancellation_conditions").length > 0;
        return <div className={"editor-tab" + (this.props.disabled ? " disabled" : "")}>
            {
                this.state.edited_advert &&
                <AdvertEditorBlock title={LANG_PATH + "booking_length>title"} description={LANG_PATH + "booking_length>box_description"}
                                   info={LANG_PATH + "booking_length>infobox_description"}>
                    <ThemeProvider theme={rj_landlord_theme}>
                        <Slider min={MIN_BOOKING_LENGTH} max={MAX_BOOKING_LENGTH} step={1} id="booking-length-slider"
                                getAriaLabel={() => { getLanguageEntry("general>nights") } }
                                value={[this.state.edited_advert.min_booking_length,
                                    this.state.edited_advert.max_booking_length]}
                                name="price" valueLabelDisplay='on'
                                onChange={(e, value) => {
                                    const advert = JSON.parse(JSON.stringify(this.state.edited_advert));
                                    advert.min_booking_length = Math.min(...value);
                                    advert.max_booking_length = Math.max(...value);
                                    this.setState({edited_advert: advert});
                                }}
                                valueLabelFormat={(x) => { return getCountLabel(x, "night") }}/>
                    </ThemeProvider>
                </AdvertEditorBlock>
            }
            {
                this.state.edited_advert.min_booking_length < 30 &&
                <AdvertEditorBlock title={LANG_PATH + "cancellation_conditions>title"}
                                   info={LANG_PATH + "cancellation_conditions>infobox_description"}
                                   description={LANG_PATH + "cancellation_conditions>description"}
                                   invalid={this.props.requirements.cancellation_conditions}>
                    {
                        this.state.edited_advert && this.state.edited_advert.cancellation_conditions &&
                        this.state.edited_advert.cancellation_conditions.length > 0 &&
                        <div style={{display: "grid", gap: "5px"}}>
                            {
                                this.state.edited_advert.cancellation_conditions.map((c, i) => {
                                    return <CancellationConditionEditor data={c} key={_uniqueId("cCond-" + i)}
                                        onSave={(cond) => { this.saveCancellationCondition(i, cond); }}
                                        onDelete={() => { this.deleteCancellationCondition(i); }} />
                                })
                            }
                        </div>
                    }
                    {
                        !this.state.cancellation_creator && (!this.state.edited_advert.cancellation_conditions ||
                        this.state.edited_advert.cancellation_conditions.length < 3) &&
                        <button className="outline-button accent" style={{width: "max-content"}}
                                onClick={() => {this.setState({cancellation_creator: true})}}>
                            <FontAwesomeIcon icon={["fal", "plus"]}/>
                            <span>{getLanguageEntry(LANG_PATH + "cancellation_conditions>add_cancellation_condition")}</span>
                        </button>
                    }
                    {
                        this.state.cancellation_creator && (!this.state.edited_advert.cancellation_conditions ||
                            this.state.edited_advert.cancellation_conditions.length < 3) &&
                        <CancellationConditionEditor onSave={(cond) => {this.saveCancellationCondition(null, cond);}}
                                                     onDelete={() => {this.setState({cancellation_creator: false})}} />
                    }
                </AdvertEditorBlock>
            }
            {
                this.state.edited_advert &&
                <AdvertEditorBlock title={LANG_PATH + "pricing>title"}
                                   info={LANG_PATH + "pricing>infobox_description"}
                                   invalid={notBookable || missingPricing}
                                   invalid_tooltip={LANG_PATH + "pricing>pricing_missing"}>
                    {
                        this.state.edited_advert.type !== AdvertType.room &&
                        <>
                            <div className="description-container">
                                {getLanguageEntry(`${LANG_PATH}pricing>rent_target_description`)}
                            </div>
                            <div className="horizontal-form-group">
                                <div className="roomjack-headline">
                                    {getLanguageEntry(`${LANG_PATH}pricing>rent_advert`)}
                                </div>
                                <CustomToggle leftValue={false} rightValue={true}
                                              defaultValue={this.state.edited_advert.rooms_bookable}
                                              onChange={(state) => {
                                                  const advert = {...this.state.edited_advert};
                                                  advert.rooms_bookable = state;
                                                  advert.bookable = !state;
                                                  this.setState({edited_advert: advert})
                                              }}/>
                                <div className="roomjack-headline">
                                    {getLanguageEntry(`${LANG_PATH}pricing>rent_rooms`)}
                                </div>
                            </div>
                        </>
                    }
                    {
                        <div className="description-container">
                            {getLanguageEntry(`${LANG_PATH}pricing>box_description${this.state.edited_advert.rooms_bookable ? "_rooms" : ""}`)}
                        </div>
                    }
                    {
                        this.state.edited_advert.advert_type &&
                        <AdvertHierarchy advert={this.state.edited_advert} b2b={this.props.userData.b2b}
                                         editable={(housing) => {
                                             return this.canHousingHavePricing(housing);
                                         }}
                                         onEdit={(housing) => {this.callPricingModal(housing)}}
                                         createContent={(housing) => {return this.createRoomBoxContent(housing)}}
                                         requirements={this.props.requirements} showBookable={true}/>
                    }
                    {
                        !this.state.edited_advert.advert_type &&
                        <div className="description-container">
                            <FontAwesomeIcon icon={["fal", "exclamation-circle"]}/>
                            <span>{getLanguageEntry(LANG_PATH + "advert_type_missing_part_1")}</span>
                            <Link to="/desktop/adverts/editor/advert-type">
                                {getLanguageEntry(LANG_PATH + "tab_headers>advert_type")}
                            </Link>
                            <span>{getLanguageEntry(LANG_PATH + "advert_type_missing_part_2")}</span>
                        </div>
                    }
                </AdvertEditorBlock>
            }
            <PricingModal ref={this.pricing_modal} />
        </div>
    }

    createRoomBoxContent(housing) {
        if (!this.canHousingHavePricing(housing)) {
            return null;
        }
        return <table>
            <tbody>
            {
                (this.state.edited_advert.min_booking_length < 30 ||
                 this.state.edited_advert.max_booking_length < 30) &&
                <tr>
                    <td><FontAwesomeIcon icon={["fal", "calendar-day"]} fixedWidth={true} /></td>
                    <td>{housing.pricing && housing.pricing.rent ?
                        priceToString(housing.pricing.rent) : '-'}</td>
                </tr>
            }
            {
                (this.state.edited_advert.min_booking_length > 29 ||
                 this.state.edited_advert.max_booking_length > 29) &&
                <tr>
                    <td><FontAwesomeIcon icon={["fal", "calendar-days"]} fixedWidth={true} /></td>
                    <td>{housing.long_term_pricing && housing.long_term_pricing.rent ?
                        priceToString(housing.long_term_pricing.rent) : '-'}</td>
                </tr>
            }
            </tbody>
        </table>;
    }

    callPricingModal(housing) {
        if (this.pricing_modal.current) {
            let titleAddition = housing.id && housing.id.startsWith("room") ?
                (housing.name ?? getLanguageEntry("advert_attributes>room_types>" + housing.type)) :
                getLanguageEntry("advert_attributes>advert_types>" +
                    (housing.type ?? housing.advert_type));
            let proTool = this.props.userData.b2b || this.state.edited_advert.pro_tool;
            this.pricing_modal.current.show(this.state.edited_advert, housing, (updatedHousing) => {
                if (updatedHousing.id && updatedHousing.id.startsWith("room")) {
                    let advert = JSON.parse(JSON.stringify(this.state.edited_advert));
                    let index = advert.rooms.findIndex(r => r.id === updatedHousing.id);
                    advert.rooms.splice(index, 1, updatedHousing);
                    this.setState({edited_advert: advert}, () => {this.actualizeRequirements()});
                }
                else {
                    this.setState({edited_advert: updatedHousing}, () => {this.actualizeRequirements()});
                }
            }, getLanguageEntry(LANG_PATH + "pricing>title") + " - " + titleAddition, proTool);
        }
    }

    actualizeRequirements() {
        if (this.props.checkRequirements) {
            this.props.updateRequirements?.(this.extractData());
        }
    }

    getTabTpe() {
        return AdvertEditorTab.pricing;
    }

    saveCancellationCondition(index, condition) {
        let advert = {...this.state.edited_advert};
        if (index && advert.cancellation_conditions) {
            advert.cancellation_conditions.splice(index, 1, condition);
            this.setState({edited_advert: advert});
        }
        if (!index) {
            if (!advert.cancellation_conditions) {
                advert.cancellation_conditions = [];
            }
            if (advert.cancellation_conditions.length < 3) {
                advert.cancellation_conditions.push(condition);
                this.setState({
                    edited_advert: advert,
                    cancellation_creator: false
                });
            }
        }
    }

    deleteCancellationCondition(index) {
        let advert = {...this.state.edited_advert};
        if (advert.cancellation_conditions) {
            advert.cancellation_conditions.splice(index, 1);
            this.setState({edited_advert: advert});
        }
    }

}

PricingTab.propTypes = {
    advert: PropTypes.any.isRequired,
    userData: PropTypes.any.isRequired,
    updateRequirements: PropTypes.func.isRequired,
    checkRequirements: PropTypes.bool.isRequired,
    requirements: PropTypes.any.isRequired
}
export default PricingTab;