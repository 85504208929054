import React, {useEffect, useRef, useState} from "react";
import {
    createMultiAdvertDropdown,
    getFormattedDate, getLanguageEntry, priceToString
} from "../../../../utils/Helper";
import {BOOKING_INVOICES_CACHE} from "../../../../utils/CacheHandler.ts";
import {getBookingInvoices} from "../../../../utils/RESTInterface";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Mode} from "../../../../utils/Types.ts";
import PrintOverviewModal from "../../../../components/modals/PrintOverviewModal";
import * as PropType from "prop-types";
import RoomJackFeeInvoice from "../../../../components/printables/RoomJackFeeInvoice";
import BookingReceipt from "../../../../components/printables/BookingReceipt";
import BookingCSVExporter from "../../../../components/modals/BookingCSVExporter";
    import Dropdown from "../../../../components/input/Dropdown";

const Intervals = [];
let date = new Date(2023, 6, 1);
while (date.getTime() < Date.now()) {
    const end = new Date(date.getTime());
    end.setMonth(date.getMonth() + 1);
    Intervals.push({
        value: {
            start: date.getTime(),
            end: Math.min(Date.now(), end.getTime())
        },
        label: `${date.getFullYear()} ${getLanguageEntry("general>month_names")[date.getMonth()]}`
    });
    date = end;
}

function BookingInvoices({userData}) {
    const [loading, setLoading] = useState(false);
    const [interval, setInterval] = useState(Intervals[Intervals.length - 1].value);
    const [showBookingExport, setShowBookingExport] = useState(false);
    const [bookings, setBookings] = useState([]);
    const [selectedAdverts, setSelectedAdverts] = useState([]);
    const [printDocument, setPrintDocument] = useState(null);
    const charges = useRef([]);
    const adverts = useRef([]);

    const createTableBody = function() {
        charges.current = [];
        for (const booking of bookings) {
            if (selectedAdverts.includes(booking.advert_id)) {
                let filteredCharges = booking.charge_data.charges.filter(c =>
                    c.payout_date && c.payout_date >= interval.start && c.payout_date < interval.end);
                filteredCharges.forEach(c => {
                    c.booking = booking;
                    c.title = booking.room_name ?
                        `${booking.room_name}, ${booking.booker.last_name}` : booking.title;
                    //c.start = getFormattedDate(new Date(booking.start), false, false, false ,true, false, false);
                    //c.end = getFormattedDate(new Date(booking.end), false, false, false ,true, false, false);
                    c.payoutDate = getFormattedDate(new Date(c.payout_date), false, false, false, true, false, false);
                    c.code = booking.confirmation_code;
                    c.name = `${booking.booker.last_name}, ${booking.booker.first_name}`;
                })
                charges.current.push(...filteredCharges);
            }
        }
        charges.current.sort((a, b) => a.payout_date - b.payout_date);
        return <tbody>
        {
            charges.current.map((c, _) =>
                <tr key={c.id}>
                    <td>{c.title}</td>
                    {/*<td>{c.start}</td>
                    <td>{c.end}</td>*/}
                    <td>{c.code}</td>
                    <td>{c.name}</td>
                    <td>{priceToString(c.amount)}</td>
                    <td>{priceToString(c.application_fee)}</td>
                    <td>{priceToString(c.stripe_fee)}</td>
                    <td>{c.payoutDate}</td>
                    <td>
                        {
                            <button className="link-button" onClick={() => {printFeeInvoice(c.booking, c)}}>
                                <FontAwesomeIcon icon={["fal", "file-invoice"]}/>
                            </button>
                        }
                    </td>
                    <td>
                        {
                            <button className="link-button" onClick={() => {printBookingReceipt(c.booking, c)}}>
                                <FontAwesomeIcon icon={["fal", "file-magnifying-glass"]}/>
                            </button>
                        }
                    </td>
                </tr>
            )
        }
        </tbody>
    }

    const printFeeInvoice = function(booking, charge) {
        setPrintDocument({
            document: <RoomJackFeeInvoice booking={booking} charge={charge} userData={userData} />,
            filename: `${booking.confirmation_code}-${getLanguageEntry("jackboard>finances>submenus>all_invoices>invoice")}.pdf`
        });
    }

    const printBookingReceipt = function(booking, charge) {
        setPrintDocument({
            document: <BookingReceipt booking={booking} charge={charge} userData={userData} />,
            filename: `${booking.confirmation_code}-${getLanguageEntry("jackboard>finances>submenus>all_invoices>overview")}.pdf`
        });
    }

    useEffect(function() {
        const intervalString = `${interval.start}-${interval.end}`;
        if (BOOKING_INVOICES_CACHE.isValid() && BOOKING_INVOICES_CACHE.getInvoices(intervalString)) {
            setBookings(BOOKING_INVOICES_CACHE.getInvoices(intervalString));
        }
        else {
            setLoading(true);
            getBookingInvoices(interval, () => {
                setLoading(false);
                setBookings(BOOKING_INVOICES_CACHE.getInvoices(intervalString));
            })
        }
    }, [interval, setLoading]);

    useEffect(function() {
        adverts.current = [];
        bookings.forEach(b => {
            if (adverts.current.filter(a => a.id === b.advert_id).length === 0) {
                adverts.current.push({
                    id: b.advert_id,
                    title: b.title
                })
            }
        });
        setSelectedAdverts(adverts.current.map(a => a.id));
    }, [bookings]);

    return <div id="invoices-tool">
        {
            <div className="second-filter-row">
                <Dropdown
                    onChange={(_, value) => {
                        setInterval(value);
                    }}
                    name="interval-selector"
                    initialValue={interval}
                    items={Intervals}
                    comparator={(a, b) => a.start === b.start}
                    disabled={loading} />
                {
                    !loading && bookings.length > 0 &&
                    <>
                        {
                            adverts.current.length > 0 &&
                            createMultiAdvertDropdown(adverts.current, "adverts", null,
                                (e, v) => { setSelectedAdverts(v); }, selectedAdverts)
                        }
                        <button className="outline-button accent" onClick={() => {
                            setShowBookingExport(true);
                        }}>
                            <FontAwesomeIcon icon={["fal", "file-csv"]} />
                            <span>{getLanguageEntry("jackboard>finances>submenus>all_invoices>csv_export")}</span>
                        </button>
                    </>
                }
            </div>
        }
        {
            loading &&
            <div className="roomjack-container loading">
                <img src="https://roomjack.blob.core.windows.net/roomjack/animations/loading_animation.gif" alt="Roomjack Loading"/>
                <div className="roomjack-headline">
                    {getLanguageEntry("jackboard>finances>submenus>all_invoices>requesting")}
                </div>
            </div>
        }
        {
            !loading && bookings.length === 0 &&
            <div className="roomjack-container no-data-container">
                <img src="https://roomjack.blob.core.windows.net/roomjack/content-images/verantwortungsvoll_vermieten/bild2.jpg"
                     alt="No invoices" style={{borderRadius: "100px"}}/>
                <div className="description-container">
                    {getLanguageEntry("jackboard>finances>submenus>all_invoices>no_filter_match_placeholder")}
                </div>
            </div>
        }
        {
            !loading && bookings.length > 0 &&
            <div id="invoice-container" className="roomjack-container">
                <table id="invoice-table">
                    <thead>
                    <tr>
                        <th className="roomjack-headline">room</th>
                        {/*<th className="roomjack-headline">
                            {getLanguageEntry("general>booking_start")}
                        </th>
                        <th className="roomjack-headline">
                            {getLanguageEntry("general>booking_end")}
                        </th>*/}
                        <th className="roomjack-headline">
                            {getLanguageEntry("general>booking_code")}
                        </th>
                        <th className="roomjack-headline">
                            {getLanguageEntry("general>guest")}
                        </th>
                        <th className="roomjack-headline">
                            {getLanguageEntry("jackboard>finances>submenus>all_invoices>booking_sum")}
                        </th>
                        <th className="roomjack-headline">
                            {getLanguageEntry("jackboard>finances>submenus>all_invoices>application_fee")}
                        </th>
                        <th className="roomjack-headline">
                            {getLanguageEntry("jackboard>finances>submenus>all_invoices>stripe_fee")}
                        </th>
                        <th className="roomjack-headline">
                            {getLanguageEntry("jackboard>finances>submenus>all_invoices>payout_date")}
                        </th>
                        <th className="roomjack-headline">
                            {getLanguageEntry("jackboard>finances>submenus>all_invoices>invoice")}
                        </th>
                        <th className="roomjack-headline">
                            {getLanguageEntry("jackboard>finances>submenus>all_invoices>booking_receipt")}
                        </th>
                    </tr>
                    </thead>
                    {createTableBody()}
                </table>
            </div>
        }
        {
            printDocument &&
            <PrintOverviewModal
                mode={Mode.landlord}
                document={printDocument.document}
                onClose={() => {
                    setPrintDocument(null);
                }}
                filename={printDocument.filename}/>
        }
        {
            showBookingExport && bookings.length > 0 && Intervals.length > 0 &&
            <BookingCSVExporter data={charges.current} onClose={() => {
                setShowBookingExport(false);
            }} filename={Intervals.filter(i => i.value === interval)[0].label} />
        }
    </div>
}
BookingInvoices.propTypes = {
    userData: PropType.any.isRequired
}
export default BookingInvoices;