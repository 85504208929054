import PropTypes from "prop-types";
import {getLanguageEntry, priceToString} from "../../utils/Helper";
import React from "react";
import BookingDetailTable from "../booking/BookingDetailTable";
import BookingInvoiceTable from "../booking/BookingInvoiceTable";
import InvoiceLogo from "./InvoiceLogo";

const LANG_PATH = "jackboard>finances>submenus>all_invoices>";

class BookingReceipt extends React.Component {

    render() {
        const {booking, charge, userData} = this.props;
        return <div className="page roomjack-invoice">
            <InvoiceLogo />
            <div className="invoice-body">
                <div className="roomjack-headline accent">
                    {getLanguageEntry("components>booking_detail_modal>title")}
                </div>
                <BookingDetailTable booking={booking} userData={userData} />
                <div className="roomjack-headline accent mt-4">
                    {getLanguageEntry("components>booking_detail_table>" +
                        (booking.invoice_data.pricing.long_term ? "rent_in_detail" : "booking_costs_in_detail"))}
                </div>
                <BookingInvoiceTable booking={booking} detailed={true} show_discount={true}/>
                <div className="roomjack-headline accent mt-4">
                    {getLanguageEntry(LANG_PATH + "fees_detailed")}
                </div>
                <div className="booking-overview-table">
                    <table>
                        <tbody>
                            <tr>
                                <td>{getLanguageEntry("jackboard>finances>submenus>all_invoices>booking_sum_extended")}</td>
                                <td>{priceToString(charge.amount)}</td>
                            </tr>
                            <tr>
                                <td>{getLanguageEntry("jackboard>finances>submenus>all_invoices>application_fee")} - roomjack</td>
                                <td>{priceToString(charge.application_fee)}</td>
                            </tr>
                            <tr>
                                <td>{getLanguageEntry("jackboard>finances>submenus>all_invoices>stripe_fee")} - Stripe</td>
                                <td>{priceToString(charge.stripe_fee)}</td>
                            </tr>
                            <tr className="sum">
                                <td>{getLanguageEntry("jackboard>finances>submenus>all_invoices>payout")}</td>
                                <td>{priceToString(charge.amount - charge.application_fee - charge.stripe_fee)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/*<InvoiceFooter />*/}
        </div>;
    }

}
BookingReceipt.propTypes = {
    booking: PropTypes.any.isRequired,
    charge: PropTypes.any.isRequired,
    userData: PropTypes.any.isRequired
}
export default BookingReceipt;