import React from "react";
import PropTypes from "prop-types";
import {
    calculateInvoiceFromObject, collectHousingListAttributes, determineAdvertNameLabel,
    findTargetHousing, getCountLabel, getFormattedAddress, getFormattedDate,
    getLanguageEntry, getTimeUnitInMS, priceToString
} from "../../../utils/Helper";
import {BookingType, Mode, ReportReason} from "../../../utils/Types.ts";
import Loading from "../../../components/Loading";
import {CONVERSATION_CACHE} from "../../../utils/CacheHandler.ts";
import {
    deleteConversation,
    getMessages,
    pinConversation, sendMessage,
    setConversationRead,
    syncConversations
} from "../../../utils/RESTInterface";
import ConversationPreview from "../../../components/ConversationPreview";
import ReportModal from "../../../components/modals/ReportModal";
import ConversationSurface from "../../../components/ConversationSurface";
import InquiryEditModal from "../../../components/modals/InquiryEditModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getRatingInformationOfUser} from "../../../utils/StatisticHelper";
import ImageSlider from "../../../components/ImageSlider";
import _uniqueId from "lodash/uniqueId";
import ProToolModal from "../../../components/modals/ProToolModal";
import {Link} from "react-router-dom";

const CHAT_SYNC_INTERVAL = 30000; // 30 seconds
const LANG_PATH = "jackboard>messages>";

class Chat extends React.Component {

    constructor(props) {
        super(props);
        let params = new URLSearchParams(window.location.search);
        let conversation = params.get("conversation") ?? null;
        let message = params.get("message") ?? null;
        this.state = {
            loading: false,
            pinning: false,
            deleting: false,
            sending: false,
            loading_more: false,
            sync: false,
            conversations: [],
            selected_conversation: null,
            selected_message: null,
            initial_conversation: conversation,
            initial_message: message,
            input_rows: 1,
            show_overview: window.innerWidth > 1450
        }
        this.report_modal = React.createRef();
        this.inquiry_edit_modal = React.createRef();
        this.pro_tool_modal = React.createRef();
        this.chat_sync = null;
    }

    componentDidMount() {
        this.updateConversations(true);
        window.addEventListener("resize", () => {this.onResize();});
        if (!this.chat_sync) {
            let that = this;
            this.chat_sync = setInterval(() => {
                if (!this.state.loading_more && !this.state.sync) {
                    that.setState({sync: true});
                    let actualConversationMap = null;
                    if (CONVERSATION_CACHE.getCache()) {
                        actualConversationMap = {};
                        for (const conversation of CONVERSATION_CACHE.getCache()) {
                            actualConversationMap[conversation.id] = conversation.messages && conversation.messages.length > 0 ?
                                conversation.messages[0].id : "none";
                        }
                    }
                    syncConversations(actualConversationMap, this.props.userData.id, () => {
                            let conversations = CONVERSATION_CACHE.getCache();
                            // check if there are new conversations
                            let actualConversations = [];
                            if (this.props.userData.conversations) {
                                actualConversations = [...this.props.userData.conversations];
                            }
                            for (const conversation of conversations) {
                                if (!actualConversations.includes(conversation.id)) {
                                    actualConversations.push(conversation.id);
                                }
                            }
                            if (actualConversations.length !== conversations.length) {
                                let user = JSON.parse(JSON.stringify(this.props.userData));
                                user.conversations = actualConversations;
                                that.props.onUpdateUserData?.(user, () => {
                                    that.setState({
                                        conversations: conversations,
                                        sync: false
                                    });
                                });
                            }
                            else {
                                that.setState({
                                    conversations: conversations,
                                    sync: false
                                });
                            }
                        });
                }
            }, CHAT_SYNC_INTERVAL);
        }
    }

    componentWillUnmount() {
        if (this.chat_sync) {
            clearInterval(this.chat_sync);
        }
    }

    render() {
        let blockControls = this.state.loading || this.state.pinning || this.state.deleting ||
            this.state.sending || this.state.loading_more || this.state.sync;
        let disableSurface = this.state.loading || this.state.pinning || this.state.deleting ||
            this.state.sending || this.state.loading_more;
        let surfaceClass = [];
        if (this.state.selected_conversation) {
            surfaceClass.push("shadowy");
        }
        if (!this.state.selected_message) {
            surfaceClass.push("expanded");
        }
        let metadata; let housing; let bookingType; let ratingObject;
        let user; let invoiceObject; let housingID;
        if (this.state.selected_message) {
            bookingType = this.state.selected_message.inquiry ? BookingType.inquiry : null
            if (this.state.selected_message.inquiry &&
                this.state.selected_message.inquiry.data) {
                metadata = this.state.selected_message.inquiry;
            }
            if (metadata) {
                housingID = metadata.data.advert_id;
                if (metadata.data.room_id) {
                    housingID += "SP" + metadata.data.room_id;
                }
                housing = findTargetHousing(metadata.data.advert, housingID);
                let mode = this.props.userData.id === metadata.data.booker ? Mode.landlord : Mode.renter;
                user = mode === Mode.renter ?
                    metadata.data.booker : metadata.data.owner;
                ratingObject = getRatingInformationOfUser(user.reviews ? Object.values(user.reviews) : [], -1, -1, mode);
                invoiceObject = calculateInvoiceFromObject(metadata.data);
            }
        }
        let chatContainerClassList = [];
        if (!this.state.selected_conversation) {
            chatContainerClassList.push("no-chat");
        }
        if (window.innerWidth < 1451) {
            chatContainerClassList.push("small-screen");
        }
        return (
            <div id="my-chat">
                {
                    this.state.loading &&
                    <Loading imageWidth="250px" />
                }
                {
                    !this.state.loading &&
                    <h1 className="desktop-tool-title">
                        {getLanguageEntry("jackboard>messages>title")}
                        {
                            this.state.sync &&
                            <FontAwesomeIcon className="chat-sync-icon" icon={["fal", "rotate"]} spin={true} />
                        }
                    </h1>
                }
                {
                    !this.state.loading && this.state.conversations.length === 0 &&
                    <div className="roomjack-container no-data-container">
                        <img src="https://roomjack.blob.core.windows.net/roomjack/content-images/roomjack_faultier_nachrichten_1.png"
                             alt="No conversations" style={{objectFit: "contain"}}/>
                        <div className="description-container">
                            {getLanguageEntry("jackboard>messages>placeholder>no_conversations")}
                        </div>
                    </div>
                }
                {
                    !this.state.loading && this.state.conversations.length > 0 &&
                    <div id="chat-container" className={chatContainerClassList.join(" ")}>
                        <button id="close-conversation" className={this.state.selected_conversation ? "show" : ""}
                                onClick={() => {this.setState({selected_conversation: null})}}>
                            <FontAwesomeIcon icon={["fal", "arrow-left"]} />
                        </button>
                        <div id="conversation-previews" className={this.state.selected_conversation ? "hide" : ""}>
                            {
                                this.state.conversations.map((c, _) => {
                                    return <ConversationPreview key={c.id} data={c}
                                                                userData={this.props.userData}
                                                                onReport={(userID) => { this.callReportModal(userID, ReportReason.user_report) }}
                                                                onDeleteConversation={(c) => {this.deleteConversation(c)}}
                                                                onPinConversation={(c) => {this.pinConversation(c)}}
                                                                onSelectConversation={(c) => {this.selectConversation(c)}}
                                                                loading={this.state.pinning || this.state.deleting}
                                                                blocked={blockControls}
                                                                pinned={!!(c.pinned && c.pinned.includes(this.props.userData.id))}
                                                                selected={!!(this.state.selected_conversation &&
                                                                    this.state.selected_conversation.id === c.id)}/>
                                })
                            }
                        </div>
                        <div id="conversation-surface-container" className={surfaceClass.join(" ")}>
                            {
                                this.state.selected_conversation &&
                                <ConversationSurface userData={this.props.userData}
                                                     conversation={this.state.selected_conversation}
                                                     onSendMessage={(c, m, cb) => {this.sendMessage(c, m, cb)}}
                                                     onActivateProTool={(a) => {this.updateProTool(a)}}
                                                     onTopReached={(c) => {this.loadOlderMessages(c)}}
                                                     loading={this.state.loading_more} sync={this.state.sync}
                                                     disableSurface={disableSurface} mode={this.props.mode}
                                                     onEditInquiry={(inquiry) => {this.callInquiryEditModal(inquiry)}}
                                                     onContactSupport={(target, reason) => {this.callReportModal(null, reason)}}
                                                     onShowBookingOverview={(message) => {this.setState({selected_message: message})}}
                                                     selectedMessage={this.state.selected_message ? this.state.selected_message.id : null}
                                                     updateUserData={this.props.onUpdateUserData}/>
                            }
                        </div>
                        {
                            metadata && housing && this.state.show_overview &&
                            <div id="booking-overview" >
                                <button id="close-overview" onClick={() => {this.setState({show_overview: false})}}>
                                    <FontAwesomeIcon icon={["fal", "times"]}/>
                                </button>
                                <div className="roomjack-container">
                                    <ImageSlider images={collectHousingListAttributes(metadata.data.advert, housing).images}/>
                                    <div className="description-container">
                                        {metadata.data.room_id ?
                                            getLanguageEntry("advert_attributes>advert_types>room") :
                                            determineAdvertNameLabel(metadata.data)}
                                    </div>
                                    <div className="roomjack-headline advert-title">
                                        {metadata.data.title}
                                    </div>
                                    <div className="description-container">
                                        {getFormattedAddress(metadata.data.address, true, false)}
                                    </div>
                                </div>
                                <div className="booking-checkout-box roomjack-container">
                                    <div>
                                        <div className="roomjack-headline">Jack-In</div>
                                        <div className="description-container">
                                            {getFormattedDate(new Date(metadata.data.start), true, false, true, true, true, true)}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="roomjack-headline">Jack-Out</div>
                                        <div className="description-container">
                                            {getFormattedDate(new Date(metadata.data.end), true, false, true, true, true, true)}
                                        </div>
                                    </div>
                                    {
                                        bookingType === BookingType.inquiry &&
                                        !metadata.accepted &&
                                        metadata.data.owner === this.props.userData.id &&
                                        this.getInquiryControlButton(metadata.data)
                                    }
                                </div>
                                <div className="roomjack-container booking-details">
                                    <div className="roomjack-headline">
                                        {getLanguageEntry(LANG_PATH + "inquiry_advert_overview>about_" +
                                            (this.props.userData.id === metadata.data.booker ? "landlord" : "guest"))}
                                    </div>
                                    <div className="description-container">
                                        {this.props.userData.id === metadata.data.booker ?
                                            metadata.data.owner.first_name + " " + metadata.data.owner.last_name :
                                            metadata.data.booker.first_name + " " + metadata.data.booker.last_name}
                                    </div>
                                    <div className="review-container">
                                        <FontAwesomeIcon icon={["fas", "star"]} />
                                        <span className="rating-value">
                                            {ratingObject.rating_value === 0 ? "-" : ratingObject.rating_value}
                                        </span>
                                        {
                                            ratingObject.rating_count > 0 &&
                                            <span className="rating-count">
                                                {getCountLabel(ratingObject.rating_count, "rating")}
                                            </span>
                                        }
                                    </div>
                                    {
                                        metadata.data.confirmation_code &&
                                        <div className="roomjack-headline">
                                            {getLanguageEntry(LANG_PATH + "inquiry_advert_overview>booking_code")}
                                        </div>
                                    }
                                    {
                                        metadata.data.confirmation_code &&
                                        <div className="description-container">
                                            {metadata.data.confirmation_code}
                                        </div>
                                    }
                                    {
                                        invoiceObject &&
                                        <div className="roomjack-headline">
                                            {getLanguageEntry( "general>price")}
                                        </div>
                                    }
                                    {
                                        invoiceObject &&
                                        <div className="description-container">
                                            {priceToString(invoiceObject.total_with_discount)}
                                        </div>
                                    }
                                    {
                                        this.props.userData.id === metadata.data.booker && housingID &&
                                        <Link className="accent-icon-button" to={"/expose/" + housingID}>
                                            <FontAwesomeIcon icon={["fal", "house"]} />
                                            <span>{getLanguageEntry(LANG_PATH + "buttons>view_room")}</span>
                                        </Link>
                                    }
                                    {
                                        metadata.data.accepted &&
                                        <Link className="accent-icon-button" to={this.getBookingLink(metadata)}>
                                            <span>{getLanguageEntry(LANG_PATH + "buttons>to_booking")}</span>
                                        </Link>
                                    }
                                </div>
                            </div>
                        }
                        {
                            metadata && housing && !this.state.show_overview &&
                            this.state.selected_conversation && this.state.selected_message &&
                            <button id="show-overview" onClick={() => {this.setState({show_overview: true})}}>
                                <FontAwesomeIcon icon={["fal", "chevron-left"]} />
                                <span>{getLanguageEntry(LANG_PATH + "buttons>show_booking_info")}</span>
                            </button>
                        }
                    </div>
                }
                <ReportModal userData={this.props.userData} ref={this.report_modal} />
                <InquiryEditModal ref={this.inquiry_edit_modal} userData={this.props.userData}
                                  onUpdateConversation={() => {this.updateConversations();}}
                                  lastMsgID={this.state.selected_conversation ? this.state.selected_conversation.messages[0].id : null}
                                  onContactSupport={() => {this.callReportModal(null, ReportReason.bug_report)}}/>
                <ProToolModal ref={this.pro_tool_modal} onAdvertChanged={(advert) => {
                    this.props.onActivateProTool(advert);
                }} onContactSupport={() => { this.props.onContactSupport(null, ReportReason.bug_report) }}/>
            </div>
        )
    }

    callReportModal(target, reason) {
        if (this.report_modal.current) {
            this.report_modal.current.show(target, reason);
        }
    }

    updateConversations(selectFirst=false) {
        let cache = CONVERSATION_CACHE.getCache();
        let selectedConversation = this.state.selected_conversation ??
            (selectFirst && cache && cache.length > 0 ? cache[0] : null);
        let selectedMessage = selectFirst && selectedConversation ?
            this.autoSelectMessage(selectedConversation) : null;
        if (cache) {
            if (this.state.initial_conversation) {
                selectedConversation = this.findInitialConversation(cache);
            }
            if (selectFirst && selectedConversation) {
                window.history.replaceState(null, "", "/desktop/messages?conversation=" + selectedConversation.id);
            }
            this.setState({
                conversations: cache,
                initial_conversation: selectedConversation ? selectedConversation.id : null,
                initial_message: null,
                selected_conversation: selectedConversation,
                selected_message: selectedMessage
            }, () => {
                if (selectFirst) {
                    if (selectedConversation.unread_msg_count) {
                        let messageID = null;
                        for (const msg of selectedConversation.messages) {
                            if (msg.sender_id !== this.props.userData.id && !msg.read) {
                                messageID = msg.id;
                                break;
                            }
                        }
                        let that = this;
                        setConversationRead(selectedConversation.id, messageID, this.props.userData.id, () => {
                            that.updateConversations();
                            that.updateUserNotifications(selectedConversation.id);
                        });
                    }
                }
            });
        }
        else {
            if (this.props.userData.conversations) {
                this.setState({loading: true});
                let that = this;
                syncConversations(null, this.props.userData.id, () => {
                    let conversations = CONVERSATION_CACHE.getCache() ?? [];
                    selectedConversation = that.findInitialConversation(conversations) ??
                        (selectFirst && conversations && conversations.length > 0 ? conversations[0] : null);
                    if (selectFirst && selectedConversation) {
                        window.history.replaceState(null, "", "/desktop/messages?conversation=" + selectedConversation.id);
                    }
                    that.setState({
                        conversations: conversations,
                        loading: false,
                        initial_conversation: selectedConversation ? selectedConversation.id : null,
                        initial_message: null,
                        selected_conversation: selectedConversation,
                        selected_message: selectedMessage
                    }, () => {
                        if (selectFirst) {
                            if (selectedConversation.unread_msg_count) {
                                let messageID = null;
                                for (const msg of selectedConversation.messages) {
                                    if (msg.sender_id !== this.props.userData.id && !msg.read) {
                                        messageID = msg.id;
                                        break;
                                    }
                                }
                                let that = this;
                                setConversationRead(selectedConversation.id, messageID, this.props.userData.id, () => {
                                    that.updateConversations();
                                    that.updateUserNotifications(selectedConversation.id);
                                });
                            }
                        }
                    });
                });
            }
            else {
                this.setState({
                    conversations: [],
                    initial_conversation: null,
                    initial_message: null,
                    selected_conversation: null
                });
            }
        }
    }

    findInitialConversation(conversations) {
        let selectedConversation = conversations
            .filter(c => c.id === this.state.initial_conversation)[0];
        return selectedConversation ?? null;
    }

    sendMessage(conversation, message, chatSurfaceCallback) {
        if (conversation && (!conversation.user.delete || !conversation.user.delete.active) &&
            (!conversation.user.blocking || !conversation.user.blocking.active) &&
            (!conversation.user.pause || !conversation.user.pause.active)) {
            this.setState({sending: true});
            let that = this;
            let lastMsgID = conversation.messages && conversation.messages.length > 0 ? conversation.messages[0].id : null;
            sendMessage(this.props.userData, conversation.user, message.message, conversation.id, message.image, lastMsgID, (response) => {
                that.setState({sending: false}, () => {
                    that.updateConversations();
                    chatSurfaceCallback?.();
                });
            });
        }

    }

    deleteConversation(conversationID) {
        this.setState({deleting: true});
        let that = this;
        deleteConversation(conversationID, this.props.userData.id, (response) => {
            if (response.success) {
                let user = JSON.parse(JSON.stringify(this.props.userData));
                let update = false;
                if (user.conversations) {
                    let cCount = user.conversations.length;
                    user.conversations = user.conversations.filter(c => c !== this.props.data.id);
                    update = user.conversations.length !== cCount;
                }
                if (update) {
                    that.props.onUpdateUserData?.(user)
                }
            }
            that.setState({deleting: false});
        });
    }

    selectConversation(c) {
        // find the last actual message inquiry or booking which can be selected
        let selectedMessage = this.autoSelectMessage(c);
        window.history.replaceState(null, "", "/desktop/messages?conversation=" + c.id);
        this.setState({
            selected_conversation: c,
            selected_message: selectedMessage,
            show_overview: window.innerWidth > 1450
        });
        if (c.unread_msg_count) {
            let messageID = null;
            for (const msg of c.messages) {
                if (msg.sender_id !== this.props.userData.id && !msg.read) {
                    messageID = msg.id;
                    break;
                }
            }
            let that = this;
            setConversationRead(c.id, messageID, this.props.userData.id, () => {
                that.updateConversations();
                that.updateUserNotifications(c.id);
            });
        }
    }

    autoSelectMessage(conversation) {
        let selectedMessage = null;
        if (conversation.messages) {
            selectedMessage = conversation.messages.filter(m => (m.inquiry && m.inquiry.data))[0];
            if (!selectedMessage) {
                selectedMessage = null;
            }
        }
        return selectedMessage;
    }

    updateUserNotifications(conversationID) {
        let user = JSON.parse(JSON.stringify(this.props.userData));
        if (user.notification_data && user.notification_data.conversations) {
            let conversation =
                user.notification_data.conversations.filter(i => i.id === conversationID)[0];
            if (conversation && conversation.messages) {
                conversation.messages.forEach(m => m.read = true);
                this.props.onUpdateUserData?.(user);
            }
        }
    }

    pinConversation(conversationID) {
        this.setState({pinning: true});
        let that = this;
        pinConversation(conversationID, this.props.userData.id, () => {
            that.setState({pinning: false});
        });
    }

    loadOlderMessages(c) {
        if (c && c.exists_more) {
            this.setState({ loading_more: true });
            let that = this;
            let lastMsgID = c.messages[c.messages.length - 1].id;
            getMessages(c.id, lastMsgID, -1, this.props.userData.id, () => {
               that.setState({loading_more: false}, () => {
                   that.updateConversations();
               });
               
            });
        }
    }

    updateProTool(advert) {
        let cache = CONVERSATION_CACHE.getCache();
        if (cache) {
            // iterate through all conversations
            for (const conversation of cache) {
                // filter all messages with inquiry
                if (conversation.messages) {
                    let inquiryMessages = conversation.messages.filter(m =>
                        m.inquiry && m.inquiry.data && m.inquiry.data.advert
                        && m.inquiry.data.advert.id === advert.id);
                    inquiryMessages.forEach(m => m.inquiry.data.advert.pro_tool = advert.pro_tool);
                }
            }
            CONVERSATION_CACHE.setCache(cache);
        }
        this.updateConversations()
    }

    callInquiryEditModal(inquiry) {
        if (this.inquiry_edit_modal.current) {
            this.inquiry_edit_modal.current.show(inquiry);
        }
    }

    onResize() {
        if (window.innerWidth < 1451 && this.state.show_overview) {
            this.setState({show_overview: false});
        }
        if (window.innerWidth > 1450 && !this.state.show_overview) {
            this.setState({show_overview: true});
        }
    }

    getInquiryControlButton(inquiryData) {
        if (!this.props.userData.b2b && !inquiryData.advert.pro_tool &&
            (inquiryData.end - inquiryData.start) >= getTimeUnitInMS(180)) {
            return <button className="accent-icon-button" key={_uniqueId("activate-pro-" + inquiryData.advert.id)}
                           title={getLanguageEntry(LANG_PATH + "inquiry_advert_overview>pro_advantage")}
                           onClick={() => {this.callProToolModal(inquiryData.advert)}}>
                {getLanguageEntry(LANG_PATH + "inquiry_advert_overview>activate_pro")}
            </button>;
        }
        else {
            return <button className="accent-icon-button" key={_uniqueId("edit-inquiry-" + inquiryData.id)}
                           onClick={() => {
                               this.callInquiryEditModal(inquiryData)
                           }}>
                {getLanguageEntry(LANG_PATH + "inquiry_advert_overview>edit_inquiry")}
            </button>
        }
    }

    callProToolModal(advert) {
        if (this.pro_tool_modal.current) {
            this.pro_tool_modal.current.show(advert);
        }
    }

    getBookingLink(data) {
        if (data.cancelled) {
            return "/desktop/bookings/cancelled?id=" + data.id
        }
        if (data.end < Date.now()) {
            return "/desktop/bookings/past?id=" + data.id
        }
        if (data.start <= Date.now()) {
            return "/desktop/bookings/active?id=" + data.id
        }
        return "/desktop/bookings/upcoming?id=" + data.id
    }

}

Chat.propTypes = {
    mode: PropTypes.oneOf(Object.values(Mode)).isRequired,
    userData: PropTypes.any.isRequired,
    onUpdateUserData: PropTypes.func.isRequired
}
Chat.defaultProps = {}
export default Chat;