import React from "react";
import PropTypes from "prop-types";
import {getLanguageEntry, isValidPassword} from "../../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Form, Col} from "react-bootstrap";
import SecretInputField from "../../../components/input/SecretInputField";
import md5 from "md5";
import {updateUser} from "../../../utils/RESTInterface";
import FetchButton from "../../../components/buttons/FetchButton";

class PasswordSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            message: "",
            loading: false
        }
        this.old_password = React.createRef();
        this.new_password = React.createRef();
        this.confirm_password = React.createRef();
        this.password_form = React.createRef();
    }

    render() {
        return (
            <div id="change-password">
                <div id="password-settings-container" className="roomjack-container">
                    <form ref={this.password_form} onSubmit={(e) => { this.updatePassword(e) }}>
                        <Form.Group className="mb-2" as={Col} controlId="old_password">
                            <Form.Label className="description-container v-form-label" column>
                                {getLanguageEntry("general>actual_password")}
                            </Form.Label>
                            <SecretInputField name="old_password" ref={this.old_password}
                                              onChange={() => {this.resetMessage()}} required />
                        </Form.Group>
                        <Form.Group  className="mb-2" as={Col} controlId="new_password">
                            <Form.Label className="description-container v-form-label" column>
                                {getLanguageEntry("general>new_password")}
                            </Form.Label>
                            <SecretInputField name="new_password" ref={this.new_password}
                                              onChange={() => {this.resetMessage()}}
                                              tooltip={"general>password_info"} required/>
                        </Form.Group>
                        <Form.Group  className="mb-4" as={Col} controlId="repeat_password">
                            <Form.Label className="description-container v-form-label" column>
                                {getLanguageEntry("general>confirm_new_password")}
                            </Form.Label>
                            <SecretInputField name="confirm_password" ref={this.confirm_password}
                                              onChange={() => {this.resetMessage()}} required/>
                        </Form.Group>
                        <div className="description-container mt-2 mb-3">{getLanguageEntry(this.state.message)}</div>
                        <div className="container-control">
                            <FetchButton type="submit" className="outline-button accent" loading={this.state.loading}
                                         loadingText="general>saving" style={{marginLeft: "auto"}}>
                                <FontAwesomeIcon icon={["fal", "save"]}/>
                                <span>{getLanguageEntry("general>save")}</span>
                            </FetchButton>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    resetMessage() {
        if (this.state.message !== "") {
            this.setState({message: ""});
        }
    }

    updatePassword(e) {
        e.preventDefault();
        let passwordData = new FormData(this.password_form.current);
        let oldPW = passwordData.get("old_password");
        let newPW = passwordData.get("new_password");
        let confirmPW = passwordData.get("confirm_password");
        if (oldPW === newPW) {
            let message = "profile_menu>password_settings>new_password_same";
            if (this.state.message !== message) {
                this.setState({message: message})
            }
            return;
        }
        if (!isValidPassword(newPW)) {
            let message = "general>password_error";
            if (this.state.message !== message) {
                this.setState({message: message})
            }
            return;
        }
        if (newPW !== confirmPW) {
            let message = "general>confirm_password_error";
            if (this.state.message !== message) {
                this.setState({message: message})
            }
            return;
        }
        let that = this;
        let update = {
            actual_password: md5(oldPW),
            password: md5(newPW)
        }
        this.setState({loading: true});
        updateUser(this.props.userData.id, update, function(response) {
            let state = { loading: false }
            if (response.errorCode !== undefined) {
                console.error(response.errorCode + ": " + response.message);
                state.message = "error_codes>" + response.errorCode;
                that.setState(state);
            }
            else {
                state.message = "general>changes_successful_stored"
                that.setState(state);
                that.old_password.current.value = "";
                that.new_password.current.value = "";
                that.confirm_password.current.value = "";
                setTimeout(() => {
                    that.setState({message: ""});
                }, 3000);
                that.props.onUpdateUserSession?.(response);
            }
        });
    }

}
PasswordSettings.propTypes = {
    onUpdateUserSession: PropTypes.func.isRequired,
    userData: PropTypes.object,
    mode: PropTypes.string.isRequired
}
export default PasswordSettings;