import React from "react";
import PropTypes from "prop-types";
import {getLanguageEntry} from "../../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Navigate, Routes} from "react-router";
import {Link, Route} from "react-router-dom";
import AdvertEditor from "../tools/adverts/AdvertEditor";
import AdvertInspector from "../tools/adverts/AdvertInspector";

class Adverts extends React.Component {

    render() {
        return (
            <div id="advert-tool">
                <h1 className="desktop-tool-title">
                    {getLanguageEntry("jackboard>adverts>name")}
                </h1>
                <Routes>
                    <Route exact path={"/"} element={<div className="desktop-submenu-grid">
                        <Link to="/desktop/adverts/editor/general" className="desktop-submenu-tile">
                            <div className="tile-icon-container">
                                <FontAwesomeIcon icon={["fal", "plus"]}/>
                            </div>
                            <div className="tile-text-container">
                                {
                                    getLanguageEntry("jackboard>adverts>submenus>create_new_advert")
                                }
                            </div>
                        </Link>
                        <Link to="/desktop/adverts/overview" className="desktop-submenu-tile">
                            <div className="tile-icon-container">
                                <FontAwesomeIcon icon={["fal", "home-alt"]}/>
                            </div>
                            <div className="tile-text-container">
                                {
                                    getLanguageEntry("jackboard>adverts>submenus>my_adverts")
                                }
                            </div>
                        </Link>
                    </div>} />
                    <Route exact path="/editor/*" element={<AdvertEditor userData={this.props.userData}
                                                                         navHook={this.props.navHook}
                                                                         onUpdateUserData={this.props.onUpdateUserData} />} />
                    <Route exact path="/overview" element={<AdvertInspector userData={this.props.userData}
                                                                            onUpdateUserData={this.props.onUpdateUserData} />} />
                    <Route exact path={"/*"} element={<Navigate to="/desktop/adverts" />} />
                </Routes>
            </div>
        )
    }

}

Adverts.propTypes = {
    userData: PropTypes.object,
    onUpdateUserData: PropTypes.func.isRequired,
    navHook: PropTypes.func.isRequired
}
Adverts.defaultProps = {}
export default Adverts;