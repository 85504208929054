export const languageData = {
    de: {
        general: {
            error: "Fehler",
            whoops: "Oooops",
            warning: "Warnung",
            attention: "Achtung",
            yes: "Ja",
            no: "Nein",
            or: "oder",
            and: "und",
            preferred_menu_mode: "Bevorzugter Modus",
            modes: {
                renter: "room finden",
                landlord: "room vermieten",
            },
            date: "Datum",
            sum: "Betrag",
            month_names: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
            month_names_short: ["Jan.", "Feb.", "März", "Apr.", "Mai", "Juni", "Juli", "Aug.", "Sep.", "Okt.", "Nov.", "Dez."],
            day_names: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
            day_names_short: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
            year: "Jahr",
            years: "Jahre",
            month: "Monat",
            months: "Monate",
            week: "Woche",
            weeks: "Wochen",
            day: "Tag",
            days: "Tage",
            day_s: "Tag(e)",
            hour_s: "Stunde(n)",
            booking_start: "Buchungsbeginn",
            booking_end: "Buchungsende",
            booking_code: "Buchungscode",
            booking_date: "Buchungsdatum",
            night: "Nacht",
            nights: "Nächte",
            landlord: "Vermieter:in",
            booker: "Buchende:r",
            guest: "Mieter:in",
            more_room: "weiteres Zimmer",
            more_rooms: "weitere Zimmer",
            image: "Bild",
            images: "Bilder",
            hours: "Std.",
            minutes: "Min.",
            seconds: "Sek.",
            close: "Schliessen",
            abort: "Abbrechen",
            delete: "Löschen",
            proof: "Prüfen",
            submit: "Einsenden",
            back: "Zurück",
            done: "Fertig",
            next: "Weiter",
            from: "Von",
            to: "Bis",
            as_of: "Ab",
            here: "hier",
            more: "mehr",
            rating: "Bewertung",
            ratings: "Bewertungen",
            chars: "Zeichen",
            rental_fee: "Miete",
            default_dropdown_placeholder: "Wähle...",
            select_all: "Alle auswählen",
            selected: "ausgewählt",
            not_specified: "Keine Angabe",
            sign_in: "Anmelden",
            email: "E-Mail Adresse",
            email_placeholder: "max.mustermann@beispiel.de",
            username: "Benutzername",
            password: "Passwort",
            password_placeholder: "Dein Passwort...",
            contact_support: "Kontaktiere unseren Support.",
            contact_support_for_link: "Kontaktiere unseren Support ",
            please_contact_support: "Bitte kontaktiere unseren ",
            support: "Support",
            verification_code: "Verifizierungscode",
            verification_code_placeholder: "6-stelliger Bestätigungscode",
            request_code: "Code anfordern",
            requesting_code: "Code wird angefordert",
            request_security_code: "Sicherheitscode anfordern",
            security_code: "Sicherheitscode",
            security_code_placeholder: "6-stelliger Sicherheitscode",
            new_password: "Neues Passwort",
            actual_password: "Aktuelles Passwort",
            confirm_password: "Passwort wiederholen",
            confirm_new_password: "Neues Passwort wiederholen",
            password_info: "8 oder mehr Zeichen mit einer Mischung aus Groß- & Kleinbuchstaben, Ziffern und Symbolen verwenden",
            change_password: "Passwort ändern",
            password_error: "Das Passwort mindestens 8 Zeichen lang sein und aus Groß- & Kleinbuchstaben, sowie Ziffern bestehen.",
            confirm_password_error: "Die Passwörter stimmen nicht überein.",
            avatar: "Profilbild",
            name: "Name",
            first_name: "Vorname",
            last_name: "Nachname",
            address: "Adresse",
            street: "Straße",
            street_no: "Nr.",
            address_addition: "Addresszusatz",
            line_1: "Adresszeile 1",
            line_2: "Adresszeile 2",
            postal_code: "PLZ",
            location: "Ort",
            city: "Stadt",
            country: "Land",
            country_prefix: "Ländervorwahl",
            phone: "Telefonnummer",
            yesterday: "Gestern",
            edit: "Bearbeiten",
            save: "Speichern",
            birthday: "Geburtsdatum",
            company: "Name der Firma",
            company_reg_no: "Firmenbuchnummer",
            languages: "Sprachen",
            tax_id: "Steuernummer",
            tax_country: "Steuernummer Land",
            vat_id: "UID",
            operating_countries: "Länder mit Betriebstätten",
            about_me: "Über mich",
            about_us: "Über uns",
            changes_successful_stored: "Änderungen erfolgreich gespeichert.",
            loading: "Lade...",
            loading_data: "Lade Daten...",
            saving: "wird gespeichert",
            deleting: "wird gelöscht",
            price: "Preis",
            send_message: "Nachricht senden",
            post: "Abschicken",
            complete: "Vollständig",
            incomplete: "Unvollständig",
            third_party_software: "Drittanbieter",
            receipt: "Quittung",
            print: "Drucken",
            downloading: "Wird heruntergeladen",
            less: "abzüglich",
            less_short: "abzgl.",
            now: "Jetzt",
            start_now: "Jetzt loslegen",
            calling_code: "Ländervorwahl",
            included: "inklusive",
            included_short: "inkl.",
            not_included: "zuzüglich",
            not_included_short: "zzgl.",
            without_title: "Ohne Titel"
        },
        processes: {
            login: {
                login_running: "Anmeldung läuft...",
                reactivating: "Reaktiviere Account",
                forgot_password: "Passwort vergessen?",
                no_account: "Mit Email registrieren",
                account_paused: "Schön, dass du wieder da bist. Wir haben dir zum Reaktivieren deines Accounts eine Email mit einem Bestätigungscode zukommen lassen. Um deinen Account vollständig zu reaktivieren, trage diesen bitte hier unterhalb ein:",
                reactivation_code: "6-stelliger Code zur Reaktivierung des Accounts",
            },
            recovery: {
                title: "Logindaten vergessen",
                back_to_login: "Zurück zum Loginbereich",
                proof_code: "Prüfe Code",
                forgot_email_info: "Hast du vergessen, mit welcher E-Mail Adresse du dich bei roomjack registriert hast?",
                recovery_info: "Gib bitte die E-Mail Adresse an, mit der du dich bei roomjack registriert hast und klicke auf CODE ANFORDERN. Wir senden dir eine E-Mail mit einem Bestätigungscode. Nach Eingabe des Codes kannst du dein Passwort neu einstellen.",
                wrong_recovery_code: "Der eingegebene Code zum Zurücksetzen des Passworts ist falsch.",
                reset_password: "Passwort zurücksetzen"
            },
            registration: {
                change_account_type: "Account-Typ ändern",
                account_type_description: "Wie möchtest du roomjack nutzen? Du hast bei uns sowohl mit einem privaten als auch gewerblichen Account die Möglichkeit auf roomjack rooms zu mieten und zu vermieten. Bei einem gewerblichen Account musst du - um vermieten zu können - aus rechtlichen Gründen zusätzliche Angaben zu deiner Firma machen.",
                private_label: "Privat",
                b2b_label: "Gewerblich",
                account_type_warning: "Bitte beachte, dass der Typ deines Accounts nach einer abgeschlossenen Registrierung nicht mehr geändert werden kann.",
                step_0_header: "Registrieren",
                step_0_button_label: "Zum nächsten Schritt",
                step_0_proof_email: "Email wird geprüft",
                step_1_header: "Email-Adresse verifizieren",
                step_1_welcome_text: "Hallo #!",
                step_1_description: "Wir haben dir eine Email mit dem Verifizierungscode zugeschickt. Prüfe bitte dein Postfach und füge den Code hier ein:",
                step_1_no_email_received: "Keine Email erhalten?",
                step_1_resend_email: "Verifizierungscode wird erneut gesendet",
                step_1_button_label: "Zum nächsten Schritt",
                step_1_proof_code: "Überprüfe Code",
                step_2_header: "Registrierung abschließen",
                step_2_mode_description: "Was möchtest du bei roomjack hauptsächlich tun?",
                step_2_button_label: "Account erstellen",
                privacy_policy_label: "Es gilt die #link#.",
                privacy_policy: "Datenschutzerklärung",
                terms_and_conditions_label: "Es gelten die #link# und die Übersicht der Zahlungsmittel.",
                terms_and_conditions: "AGBs/Nutzungsbedingungen der Room Jack GmbH",
                revocation_policy_label: "Ich habe die #link# zu Kenntnis genommen.",
                revocation_policy: "Widerrufsbelehrung",
                verification_code_invalid: "Verifizierungscode falsch!",
                registration_success: "Registrierung erfolgreich!",
                registration_fail: "Bei der Erstellung deines Accounts ist leider etwas schiefgelaufen :(",
                welcome_to_roomjack_header: "Willkommen bei roomjack {name}!",
                welcome_to_roomjack_description: "Damit du roomjack im vollen Umfang nutzen kannst ist es notwendig dein Profil zu vervollständigen. Du kannst das sofort im nächsten Schritt machen oder auch zu einem späteren Zeitpunkt in deinen Benutzereinstellungen.",
                start_using_roomjack: "Jetzt gleich Profil vervollständigen!",
                resending: "Code wird erneut gesendet...",
                creating_account: "Account wird erstellt...",
                your_address: "Deine Anschrift"
            },
            report: {
                contact_form_header: "Kontaktformular",
                report_successful_sent_header: "Vielen Dank für deine Nachricht!",
                send_report_failed_header: "Hoppla :(",
                report_successful_sent_description: "Unser Support Team wird sich umgehend darum kümmern.",
                send_report_failed_description: "Beim Senden deines Berichts ist leider etwas schiefgelaufen, bitte verusche es später noch einmal.",
                reason: "Grund",
                report_statement_label: "Was können wir für dich tun?",
                report_reasons: {
                    contact: "Kontaktaufnahme",
                    bug_report: "Ein Problem melden",
                    help: "Ich brauche Hilfe",
                    advert_report: "Ein Inserat melden",
                    user_report: "Einen Benutzer melden",
                    miscellaneous: "Sonstiger Grund"},
                sending: "Wird gesendet..."
            },
            messaging: {
                your_message_to: "Deine Nachricht an @",
                alerts: {
                    user_deleted: "Es tut uns Leid, aber du kannst #username# keine Nachricht mehr senden, weil sie*er den Account gelöscht hat.",
                    user_paused: "Es tut uns Leid, aber du kannst #username# aktuell keine Nachrichten senden, weil sie*er den Account pausiert hat.",
                    user_blocked: "Es tut uns Leid, aber du kannst #username# keine Nachrichten mehr senden, weil ihr*sein Account gesperrt wurde.",
                    message_to_yourself: "Netter Versuch :) Du kannst keine Nachrichten an dich selbst schicken."
                },
                message_successful_sent: "Deine Nachricht wurde erfolgreich an @ gesendet.",
            },
            booking: {
                booking_period: "Zeitraum",
                gold_grip_header: "Goldgriff!",
                gold_grip_text: "Der room von # ist normalerweise ausgebucht!",
                check_data_title: "Jack deine Daten",
                overview_listing_title: "Jack die Einzelheiten",
                charges_title: "Zahlungsplan",
                charge_date: "Abbuchungsdatum",
                sum: "Abbuchungssumme",
                charges_description: "Für Buchungen, die länger als 29 Tage dauern, erfolgt die Abbuchung der Buchungsgebühr bzw. Miete nicht in einer Einmalzahlung, sondern monatlich im 30-tägigen Intervall.",
                cancellation_condition_title: "Stornobedingungen",
                cancellation_long_term: "Diese Buchung dauert länger als 29 Tage und fällt somit in die Kategorie Langzeit-Miete. Ein Langzeit-Mietverhältnis kann nur mit beidseitiger Einigung aufgelöst werden. Wenn du diese Buchung stornieren möchtest, kontaktiere bitte deine:n Vermieter:in.",
                important_information: "Wichtige Informationen",
                house_rules_title: "Jack die Hausregeln",
                free_cancellable: "Für dieses Inserat sind keine Stornierungsbedingungen festgelegt.",
                free_cancellable_short: "Stornofrei",
                charged_cancellation: "Kein kostenloses Storno (siehe Stornobedingungen)",
                cancellation_condition_charged: "<strong>€</strong> Stornogebühr (#%).",
                cancellation_condition_free: "Kostenloses Storno bis <strong>#</strong>.",
                cancellation_condition_charged_from: "<strong>€</strong> Stornogebühr ab <strong>@</strong> (#%).",
                cancellation_condition_payment_fees: "Bitte beachte, dass unabhängig von den Stornierungsbedingungen dieses Inserats, Kosten für den rückabgewickelten Zahlungsverkehr anfallen. Mehr dazu unter ",
                discount_text: "Rabatt auf den Mietpreis",
                roomjack_discount_text: "roomjack-Rabatt",
                roomjack_discount_one_time: "einmalig",
                roomjack_discount_multi_time: "auf x Abbuchungen aufgeteilt",
                long_term_info: "Die Gesamtkosten sind nicht zu Beginn vollständig fällig, sondern werden in monatlichen Mieten von der angegebenen Zahlungsmethode eingezogen.",
                book_with_charge: "Kostenpflichtig buchen",
                send_inquiry: "Verbindlich anfragen",
                contact_owner: "Kontakt aufnehmen",
                arrangement: "nach Absprache",
                security_notice: "Um dich und deine Zahlung zu schützen, solltest du stets die Zahlungsmethoden auf unserer Plattform nutzen und ausschließlich über das Chat-Tool kommunizieren!",
                booking_process_invalid: "",
                total_fees: "Gesamtkosten",
                price_per_month_short: "mtl.",
                address_info: "Die genaue Adresse bekommst du nach erfolgreicher Buchung in deinen Buchungsdetails!",
                booking_success: "Buchung erfolgreich!",
                inquiry_success: "Anfrage erfolgreich!",
                to_my_booking: "Hier geht's zu den Buchungen",
                to_chat: "Hier geht's zur Anfrage",
                booking_failed: "Buchung fehlgeschlagen",
                inquiry_failed: "Anfrage fehlgeschlagen",
                booking_in_process: "Buchung wird ausgeführt...",
                inquiry_in_process: "Anfrage wird verschickt...",
                discount_code: "Rabattcode",
                redeem: "Einlösen",
                remove: "Entfernen",
                checking_discount_code: "Wird geprüft",
                roomjack_terms: "Ich bestätige die Kenntnisnahme der <a href='/agb.html' target='_blank' rel='noreferrer nofollow'>AGB</a> und <a href='/privacy-policy.html' target='_blank' rel='noreferrer nofollow'>Datenschutzhinweise</a> von roomjack.",
                landlord_terms: "Ich bestätige die Kenntnisnahme der # des Unterkunftsanbieters.",
                terms_of_service: "allg. Mietbedingungen",
                revocation: "Widerrufsbelehrung",
                privacy_policy: "Datenschutzhinweise",
                withdrawal_revocation: "Ich verlange ausdrücklich, dass vor Ende der Widerrufsfrist mit der Ausführung der beauftragten Dienstleistung begonnen wird.",
                booking_notice: "Sie buchen diese Unterkunft direkt bei Vermieter:in {name}. Indem Sie die Buchung verbindlich abschließen, stimmen Sie den vorstehenden Bedingungen, insbesondere den Allgemeinen Mietbedingungen der Vermieterin/des Vermieters zu.",
                inquiry_notice: "Sie fragen diese Unterkunft direkt bei Vermieter:in {name} an. Indem Sie die Anfrage verbindlich abschließen, stimmen Sie den vorstehenden Bedingungen, insbesondere den Allgemeinen Mietbedingungen der Vermieterin/des Vermieters zu."
            },
            booking_cancellation: {
                title: "Buchung stornieren",
                cancellation_reason: "Grund der Stornierung",
                reason_comment: "Anmerkung zur Stornierung",
                warning: "Du bist gerade dabei die Buchung vom <b>@></b> bis <b><@</b> für \"#advertTitle#\" zu stornieren.",
                reason_label: "<br><br>Warum willst du deine Buchung stornieren?",
                warning_landlord: "Du bist gerade dabei die Buchung vom <b>@></b> bis <b><@</b> für dein Inserat \"#advertTitle#\" vom Gast #guestName# zu stornieren. Bitte beachte, dass in diesem Fall die <u>volle</u> Buchungssumme erstattet wird, falls sie bereits von der:dem Mieter:in bezahlt wurde.<br><br>Warum willst du deine Buchung stornieren?",
                costs_incurring: "Bitte beachte, dass durch die von die:der Vermieter:in festgelegte Stornierungsbedingungen <b>€ (% der Gesamt-Buchungssumme) zzgl. Zahlungsgebühren</b> anfallen.",
                no_refund: "Bitte beachte, dass durch die von die:der Vermieter:in festgelegte Stornierungsbedingungen <b>keine Rückerstattung</b> erfolgt.",
                refund_all: "Bitte beachte, dass für diese Buchung keine Stornierungskosten fällig sind. Dir werden <b>100% der Buchungssumme abzüglich Zahlungsgebühren</b> rückerstattet.",
                cancelling: "Storniere Buchung...",
                failed_renter: "Leider ist bei der Stornierung deiner Buchung etwas schiefgegangen.",
                failed_landlord: "Leider ist bei der Stornierung dieser Buchung ist etwas schiefgegangen.",
                success_title_renter: "Deine Buchung wurde erfolgreich storniert!",
                success_title_landlord: "Die Buchung wurde erfolgreich storniert!",
                success_description_renter: "Die bereits von dir bezahlten Gebühren werden dir, abzüglich der Bearbeitungsgebühren, gemäß den Stornierungsbedingungen des Vermieters bzw. des Inserates zurückerstattet.",
                success_description_landlord: "Eventuell bereits bezahlte Buchungsgebühren werden der:dem Mieter:in zu 100% erstattet."
            },
            payment_method_change: {
                title: "Zahlungsmethode ändern",
                loading: "Daten für aktuelle Zahlungsmethode werden abgefragt.",
                description: "Hier hast du die Möglichkeit deine Zahlungsmethode zu ändern, sofern die Buchungssumme nicht bereits vollständig bezahlt wurde. Um Komplikationen zu vermieden, achte bitte immer darauf, dass der ausgewählte Zahlungsmittel zum Zeitpunkt der Abbuchung über genügend Deckung verfügt. Um zu sehen, wann ein Buchungsgebühr / die Miete fällig wird, öffne unter Jackboard/Buchungen die entsprechende Buchung und rufe die Transaktionsdetails ab.",
                process_failed: "Hoppla! Da ha leider etwas nicht geklappt.",
                process_succeeded: "Du hast erfolgreich die Zahlungsmethode für diese Buchung geändert.",
                process_running: "Zahlungsmethod wird geändert..."
            },
            advert_deletion: {
                title: "Inserat löschen",
                delete_code: "Löschcode",
                delete_code_placeholder: "6-stelliger Löschcode",
                deleting_advert: "Inserat wird gelöscht",
                success: "Dein Inserat wurde erfolgreich gelöscht.",
                description: "Das Löschen eines Inserats ist endgültig. Aus Sicherheitsgründen ist die Löschung nur nach Eingabe eines Löschcodes möglich, den du per E-Mail zugesandt bekommst. Der Code kann nur einmal verwendet werden und ist ausschließlich direkt nach der Anforderung gültig. Um fortzufahren, klicke bitte unten auf \"Code anfordern\" und gib anschließend den erhaltenen Code in das erscheinende Feld ein.",
                failure: "Leider kann dein Inserat nicht gelöscht werden.",
                advert_not_found: "Dein Inserat konnte in der Datenbank nicht mehr gefunden werden. Möglicherweise wurde es bereits gelöscht und wurde jetzt auch aus deiner Inseratsliste entfernt.",
                advert_not_deletable: "Bei der serverseitigen Überprüfung wurde festgestellt, dass für dein Inserat noch mindestens eine offene Buchung existiert. Solange aktive oder zukünftige Buchungen für das Inserat vorhanden sind, kann es aus Sicherheitsgründen nicht gelöscht werden.<br>Du kannst dein Inserat vorerst aber schon deaktivieren, damit es nicht mehr gefunden und gebucht werden kann. Sobald die aktive Buchungen abgelaufen sind, kannst du es löschen.",
                user_has_no_housing: "Wir konnten das zu löschende Inserat in deiner Liste in der Datenbank nicht finden. Wir werden diesen Fall umgehend überprüfen.",
                delete_advert_irrevocable: "Inserat unwiderruflich löschen",
            },
            advert_publication: {
                deactivate_title: "Inserat deaktivieren",
                deactivate_description: "Wenn du dein Inserat deaktivierst, kann es nicht mehr von anderen gefunden oder gebucht werden. Möchtest du trotzdem fortfahren?",
                deactivate_advert: "Ja, Inserat deaktvieren",
                publish_title: "Inserat veröffentlichen",
                publish_description: "Wenn du das Inserat veröffentlichst, wird es und all seine Teilinserate für die Suche freigegeben und dadurch buchbar sein. Bist du dir sicher, dass das Inserat veröffentlicht werden soll?",
                publish_advert: "Ja, Inserat veröffentlichen",
                edit_advert: "Inserat bearbeiten",
                to_my_account: "Zu meinem Account",
                to_my_profile: "Zu meinem Profil",
                complete_advert: "Jetzt Inserat vervollständigen",
                publishing_failed_requirements: "Leider konnte das Inserat nicht veröffentlicht werden, da noch nicht alle notwendigen Informationen ausgefüllt sind. Bitte ergänze alle fehlenden Informationen zuerst.",
                publishing_failed_requirements_info: "Unser Tipp: Du kannst im Inserierungstool mit Hilfe des \"Auf Vollständigkeit prüfen\" alle fehlenden Informationen ermitteln und ausgeben lassen. Somit kannst du gezielt und effizient die Ergänzungen durchführen.",
                publishing_failed_rp_acceptance: "Leider konnte das Inserat nicht veröffentlicht werden, da dein Widerrufsrecht noch aktiv ist. Wenn du dein Inserat sofort veröffentlichen möchtest, hast du die Möglichkeit auf dein Widerrufsrecht zu verzichten. Gehe bitte dazu in <b>Benutzerprofil verwalten > Account</b> und setze dort den Kontrollkästchen unter \"Dienstleistung vor Ablauf der Widerrufsfrist für Konsumenten\".",
                publishing_failed_missing_user_data_header: "Leider konnte das Inserat nicht veröffentlicht werden, da uns über dich noch folgende Informationen fehlen:",
                publishing_failed_missing_user_data_footer: "Du kannst die fehlenden Informationen unter Benutzerprofil verwalten > Profilübersicht eintragen.",
                publishing_failed_verification: "Leider konnte das Inserat nicht veröffentlicht werden, da dein Account noch nicht vollständig verifiziert wurde. Dieser Schritt ist zwingend notwendig, damit wir in der Lage sind dich bei Buchungen auszubezahlen.",
                verify_now: "Jetzt Account verifizieren",
                to_profile_menu: "Zum Profilmenü",
                publication_success: "Dein Inserat wurde erfolgreich veröffentlicht!",
                deactivation_success: "Dein Inserat wurde erfolgreich deaktiviert!",
                publication_failure: "Dein Inserat konnte leider nicht veröffentlicht werden!"
            },
            advert_duplication: {
                title: "Inserat duplizieren",
                duplicating_advert: "Inserat wird dupliziert",
                description: "Du hast die Möglichkeit dein Inserat eins zu eins zu duplizieren. Das ist vorallem dann sehr nützlich, wenn du mehrere ähnliche oder sogar gleiche Inserate besitzt. Du kannst hier gleich einen neuen Titel für das duplizierte Inserat angeben, um sie leichter außeinander halten zu können. Alles andere kann nach der Duplizierung wie gewohnt bearbeitet werden.",
                duplicate_title_label: "Titel des neuen Inserats",
                success: "Dein Inserat wurde erfolgreich dupliziert.",
                failure: "Leider konnten wir dein Inserat nicht duplizieren.",
            },
            pro_tool_modal: {
                title: "roomjack Pro Status ändern",
                toggle_label: "roomjack Pro für Inserat aktiv",
                success: "Änderung des Pro-Tool-Status erfolgreich",
                failure: "Änderung des Pro-Tool-Status fehlgeschlagen"
            },
            booster_modal: {
                title: "Inserat hervorheben",
                booster_types: {
                    basic_package: {
                        title: "Basic package",
                        description: "Standardmäßig schalten wir dein Inserat als Basic Modell ohne Extra-Kosten.",
                    },
                    color_accent: {
                        title: "Color package",
                        description: "Mit unserem neuen Color Package hast du die Möglichkeit, deine Anzeigen farbig hervorzuheben. So sticht dein Inserat sofort ins Auge. Einmal gekauft, bleibt dieser Booster für dieses Inserat immer aktiviert!",
                    },
                    search_preference: {
                        title: "Top package",
                        description: "Vorne mit dabei! Mit diesem Package reihen wir dein Inserat vor.",
                    },
                    gold: {
                        title: "Gold package",
                        description: "Mit dem Gold Package heben wir dein Inserat nicht nur farbig hervor, sondern reihen es außerdem in den Suchergebnissen unter die ersten zwei Anzeigen.",
                    },
                    gold_plus: {
                        title: "Premium package",
                        description: "Maximale Aufmerksamkeit. Mit dem Premium Package wird dein Inserat farbig hervorgehoben, erscheint unter den ersten zwei Suchergebnissen und auf der Startseite unter den Top-Inseraten.",
                    }
                },
                cost_label: "Dieser Booster kostet einmalig <strong>#</strong> und ist nach erfolgreichem Kauf für das gewählte Inserat freigeschaltet.",
                multi_cost_label: "für <strong>#</strong> Tage für <strong>€</strong>",
                purchased: "Gekauft",
                free: "Gratis",
                price_from: "ab ",
                booster_activated: "Für dieses Inserat bereits aktiv.",
                boosted: "Für dieses Inserat aktiv bis ",
                active: "Aktiviert",
                header_success: "Vielen Dank für deinen Einkauf!",
                header_failure: "Leider ist beim Boosterkauf etwas schiefgelaufen.",
                description_success: "Der Booster wird in den nächsten Minuten für das Inserat aktiviert!",
                buy_now: "Jetzt kostenpflichtig kaufen",
                buying: "Boosterkauf wird ausgeführt"
            },
            inquiry_edit_modal: {
                details_of_inquiry: "Details zu der Anfrage",
                accept_inquiry: "Anfrage akzeptieren",
                accepting_inquiry: "Anfrage wird akzeptiert",
                decline_inquiry: "Anfrage ablehnen",
                declining_inquiry: "Anfrage wird abgelehnt",
                accept_success: "Gratuliere, die Buchung konnte erfolgreich abgeschlossen werden!",
                decline_success: "Die Anfrage wurde erfolgreich abgelehnt!",
                process_failed: "Hoppla, da ist leider etwas schiefgelaufen.",
            },
            reviews: {
                buttons: {
                    send_review: "Bewertung absenden",
                    cancel_review: "Nicht jetzt!",
                    transmit_review: "Übermittle Bewertung",
                    show_more: "mehr anzeigen",
                    show_less: "weniger anzeigen"
                },
                roomjack_review: {
                    header: "Wie gefällt dir roomjack?",
                    description_1: "Die Zufriedenheit unserer User hat für uns stets oberste Priorität. Damit wir unseren Service ständig weiter verbessern können, würden wir uns über Feedback von dir freuen! Bitte nimm dir einen Moment Zeit und sag uns was du von roomjack hältst.",
                    good_review_prefix: "Schön, dass es dir hier gefällt!",
                    bad_review_prefix: "Das ist schade!",
                    description_2: "Was können wir besser machen?",
                    payment_possibilities: "Zahlungsmöglichkeiten",
                    usability: "Benutzerfreundlichkeit",
                    user_experience: "Benutzererlebnis",
                    support: "Support",
                    everything_fine: "Alles bestens!",
                    review_text_label: "Beschreibe deine Erfahrung mit unserer Seite",
                    thanking: "Vielen Dank für deine Zeit! Damit hast du uns sehr viel geholfen roomjack in der Zukunft noch besser zu machen."
                },
                landlord_review: {
                    header: "Bewerte deine:n Vermieter:in",
                    description: "Wie zufrieden warst du mit @ als Vermieter?",
                },
                renter_review: {
                    header: "Bewerte deine:n Mieter:in",
                    description: "Wie zufrieden warst du mit @ als Mieter:in?",
                },
                advert_review: {
                    header: "Bewerte den room",
                    description: "Wie hat es dir im room von @ gefallen?"
                },
                fair_review: "Wir bitten dich, bei der Bewertung fair und sachlich zu bleiben.",
                thanking: "Vielen Dank für dein Feedback!",
                failure: "Hoppla! Da hat leider etwas nicht geklappt :( Bitte versuche es später erneut."
            },
        },
        main_banner: {
            notification_center: {
                title: "Benachrichtigungen",
                placeholder: "Du hast keine Benachrichtigungen",
                old_placeholder: "Du hast keine älteren Benachrichtigungen",
                new_placeholder: "Du hast keine neuen Benachrichtigungen",
                types: {
                    message: "hat dir eine Nachricht geschickt.",
                    new_booking: "hat deinen room gebucht.",
                    cancelled_booking: "hat eine Buchung storniert.",
                    cancelled_booking_by_landlord: "hat deine Buchung storniert",
                    booking_invitation: "hat deine Buchungsanfrage akzeptiert.",
                    inquiry: "hat dir eine Buchungsanfrage geschickt.",
                    user_review: "hat dir eine Bewertung abgegeben.",
                    advert_review: "hat dein Inserat bewertet.",
                    pending_landlord_review: "Bitte bewerte deine(n) Vermieter:in, #.",
                    pending_booker_review: "Bitte bewerte deine:n Mieter:in, #.",
                    pending_advert_review: "Bitte bewerte den room \"#\"."
                },
                new_label: "Neu",
                old_label: "Ältere",
            },
            main_menu: {
                dashboard: "Mein Jack-Board",
                profile: "Benutzerprofil verwalten",
                mode: "Modus",
                logout: "Logout"
            },
            public_mode_toggle: {
                landlord: "Room mieten",
                renter: "Room vermieten",
            },
            mode_toggle: {
                landlord: "vermieten",
                renter: "wohnen",
            },
            login: "Login",
            welcome: "Hallo"
        },
        landing_pages: {
            renter: {
                carousel_prefix: "Jack dir den perfekten room für ",
                slogans: {
                    studies: "dein Studium",
                    business_trip: "deinen Business-Trip",
                    work_week: "deine Arbeitswoche",
                    job: "deinen Job",
                    module_week: "deine Modulwoche",
                    theory_block: "deinen Theorieblock",
                    internship: "dein Praktikum",
                    project_day: "deine Projekttage",
                    homeoffice: "dein Homeoffice"
                },
                search_bar: {
                    location_placeholder: "Wo soll's hingehen?",
                    flexible_search: "Ich bin flexibel",
                    search: "Suchen"
                },
                hard_facts_title: "Wohnraum neu gedacht - Jetzt room mieten",
                hard_facts_description: "roomjack ist deine Plattform, um den perfekten Wohnraum für deine Bedürfnisse zu finden. Apartments, Zimmer, Hotel-Zimmer und WG-Zimmer für deinen Business-Trip, deine Präsenzphasen, oder dein Studium und das für genau den Zeitraum für den du ihn brauchst - von Kurzzeitmiete bis Langzeitaufenthalte, einzelne Tage, wochenweise, monatsweise, wohnen auf Zeit oder dauerhaft. Ganz nach deinen Wünschen!",
                hard_facts: {
                    find: {
                        title: "room finden",
                        content : "Finde in unserer Vielzahl an Angeboten den room der zu dir passt! Vom einfachen WG-Zimmer bis zum voll-ausgestatteten Serviced-Apartment. Vom kuscheligen Einzelapartment bis zur WG mit Freunden. Rooms so vielfältig wie das Leben und für den Zeitraum, für den du sie brauchst!",
                        image_alt: "Unterkunft für Prüfungstage"
                    },
                    booking: {
                        title: "room jacken",
                        content : "Buche deinen room in wenigen Minuten und profitiere von unserem sicheren Buchungs-prozess. Sämtliche Zahlungen sind versichert und werden über lizensierte Zahlungspartner abgewickelt. Übernachtungsgebühren oder Mieten werden frühestens ausbezahlt, nachdem du erfolgreich ein-jacken konntest!",
                    image_alt: "Daumen nach oben für Wohnen nach deinen Bedürfnissen"
                    },
                    save_money: {
                        title: "Geld sparen",
                        content : "Du bezahlst nur für die Nächte, für die du deine Unterkunft tatsächlich brauchst und vermeidest hohe Fixkosten durch dauerhaft angemietete Unterkünfte. Gleichzeitig hilfst du mit Wohnraum effizienter zu nutzen, wodurch andernorts Wohnraumbedarf eingespart werden kann, wodurch der Umwelt geholfen wird.",
                        image_alt: "Studenten nach der Buchung"
                    }
                },
                recommendations: {
                    title: "Rooms in deiner Umgebung",
                },
                special_search: {
                    title: "Deine Wohnraum Bedürfnisse sind uns wichtig",
                    description: "Finde deinen perfekten room für dich! Egal was du benötigst: ob Barrierefrei, Platz für Kreativität, Entspannung, Shopping und vieles mehr – bei uns wirst du bestimmt fündig.",
                    citation: "„Die Welt ist ein Buch. Wer nie reist, sieht nur eine Seite davon“",
                    cat: {
                        barrier_free: {
                            name: "room ohne Barrieren",
                            image_alt: "Apartments barrierefrei mieten"
                        },
                        garden: {
                            name: "room mit Garten",
                            image_alt: "Wohnung mieten mit Garten oder Balkon"
                        },
                        silent_place: {
                            name: "room in ruhigen Lagen",
                            image_alt: "Kurzzeitmiete zum Entspannen"
                        },
                        city_centre: {
                            name: "room im Stadtzentrum",
                            image_alt: "Kurzzeitmiete im Stadtzentrum"
                        },
                        relaxing: {
                            name: "room zum relaxen",
                            image_alt: "Zimmer mieten zum Relaxen"
                        },
                        streaming: {
                            name: "room zum streamen",
                            image_alt: "WG-Zimmer mieten zum Streamen"
                        },
                        cool: {
                            name: "room für kuhle Köpfe",
                            image_alt: "Apartment mieten mit Klimaanlage"
                        },
                        environment: {
                            name: "room für Umweltbewusste",
                            image_alt: "Nachhaltige Wohnung mieten"
                        },
                        shopping: {
                            name: "room für Shoppingverrückte",
                            image_alt: "Zimmer mieten fürs shoppen"
                        },
                    }
                },
                become_landlord: {
                    room: "room",
                    rent: "vermieten",
                    description: "Egal ob Gelegenheits-Vermieter, oder Profi - bei uns sind alle willkommen!",
                    button_text: "Mehr erfahren",
                    image_alt: "Vermieter bei der Schlüsselübergabe der vermieteten Wohnung"
                },
                news: {
                    // add here renter specific news content later
                }
            },
            landlord: {
                you_are: "Du bist",
                b2b_header: "Geschäftskunde?",
                b2b_button: "Als Geschäftskunde",
                private_header: "Privatkunde?",
                private_button: "Als Privatkunde",
                register: "registrieren",
                create_advert_label: "room inserieren",
                slogans: {
                    rent: {
                        header: "Sorglos<br />vermieten.<br />Mit roomjack.",
                        image_alt: "Kaffee trinken und sorgenlos Wohnung vermieten"
                    },
                    time: {
                        header: "Zeit für<br />wichtige Dinge.<br />Mit roomjack",
                        image_alt: "Zeit für wichtige Dinge"
                    },
                    overview: {
                        header: "Alles im<br />Überblick.<br />Mit roomjack.",
                        image_alt: "Alles im Überblick"
                    },
                    responsible: {
                        header: "Verantwortungsvoll<br />vermieten.<br />Mit roomjack.",
                        image_alt: "Verantwortungsvoll vermieten"
                    }
                },
                features: {
                    title: "Jetzt als roomjack-Host durchstarten!",
                    items: [
                        {
                            header: "Kundengruppen maßgeschneidert für dich.",
                            description: "Bei roomjack verstehen wir, dass jede Unterkunft ihre einzigartigen Mieter verdient. Deshalb ermöglichen wir es dir, deine Unterkunft an passende Kunden zu vermieten und die ideale Übereinstimmung zu erzielen. Unsere Plattform wurde speziell entwickelt, um den Bedürfnissen von qualitätsbewussten Mietern gerecht zu werden, sei es für Studenten, Geschäftsreisen oder kurzfristige Aufenthalte. Bei uns findest du die perfekte Zielgruppe für deine Unterkunft.",
                            image_alt: "Was dazu verdienen"
                        },
                        {
                            header: "Superjacker & Super Host‘s.",
                            description: "Du hast die Möglichkeit, bei deinen ersten Buchungen ausschließlich Mieter zuzulassen, die bereits mehrere positive Bewertungen erhalten haben. Wir möchten unseren RoomJack-Hosts, die positive Bewertungen erhalten, etwas zurückgeben. Denn je mehr positive Bewertungen du als Host und für deine Unterkünfte sammelst, desto besser wird sich dies auf dein Ranking in den Suchergebnissen auswirken.",
                            image_alt: "Auslastung oder Reichweite erhöhen"
                        },
                        {
                            header: "Wir sind gerne für dich da.",
                            description: "Wir unterstützen dich gerne als RoomJack-Host von Anfang an. Unser geschultes Personal steht dir zur Verfügung, um dir bei Fragen und Anliegen zu helfen. Egal, ob du Fragen zu deinem Account hast oder Informationen zu unseren Funktionen benötigst, wir stehen jederzeit gerne für dich zur Verfügung.",
                            image_alt: "Vermieten leicht gemacht"
                        }
                    ]
                },
                functions: {
                    title: "Unsere Tools zum vermieten",
                    items: [
                        {
                            title: "Jack-Board",
                            content: "Bei roomjack hast du die Möglichkeit deine eigene Übersicht für die für dich wichtigsten Themen zu erstellen. Wähle aus zahlreichen Widgets eine für dich angepasste Übersicht und du siehst bei jedem Login alles was du brauchst auf einen Blick!"
                        },
                        {
                            title: "Selektierbare Kundensegmente",
                            content: "Bei roomjack kannst du deinen room auch nur bestimmten Zielgruppen anbieten. Dies hilft dir einen homogenen Kundenkreis anzusprechen und ist speziell für WG-Zimmer vorteilhaft."
                        },
                        {
                            title: "Sicherer Zahlungsverkehr",
                            content: "Nutze unsere integrierte Zahlungsabwicklung, um deinen Mietern alle gängigen Zahlungsmethoden zur Verfügung zu stellen und für einen sicheren Geldfluss zu sorgen."
                        }
                    ]
                },
                become_b2b: {
                    headline: "room für Business",
                    description: "Vielseitige Tools, nahtlos integrierbar. So flexibel wie Ihre Anforderungen!",
                    button: "Mehr erfahren!",
                    image_alt: "Menschen geben sich High-Five"
                },
                testimonials: [
                    {
                        text: "An Tagen, an denen ich nicht selbst an der Uni bin, biete ich meine Wohnung bei roomjack an und kann mir damit etwas dazu verdienen!",
                        image_alt: "Student im Klassenzimmer"
                    },
                    {
                        text: "Mit dem Jack-Board behalte ich alle wichtigen Informationen über meine Buchungen im Blick. Es bleibt alles wie es ist und ich finde mich immer zurecht.",
                        image_alt: "Ältere Dame lacht"
                    },
                    {
                        text: "Ich spare mir nun viel wiederkehrende Arbeit. Rechnungen lasse ich jetzt von roomjack erstellen.",
                        image_alt: "Frau mit Notebook"
                    },
                    {
                        text: "Mir war es wichtig den Ansprüchen meiner Gäste zu entsprechen. Deshalb empfehle ich meinen room für bestimmte Zielgruppen besonders.",
                        image_alt: "Frau trinkt Kaffee in der Küche"
                    },
                    {
                        text: "Meine Wohnung wird von roomjack vermarktet. Ich brauch mich selber nicht mehr um Werbung zu kümmern!",
                        image_alt: "Mann entspannt sich auf der Sofae"
                    }
                ],
                become_landlord: {
                    headline: "Worauf wartest du noch?",
                    register_now: "Jetzt host werden",

                },
                news: {
                    // add here landlord specific news content later
                }
            },
            shared_content: {
                news: {
                    title: "Newsroom",
                    linz: {
                        title: "roomjack startet in Linz!",
                        description: "Zugegeben - ein etwas angestaubter Slogan. Aber für roomjack ist er brandaktuell, denn roomjack..."
                    },
                    heidenheim: {
                        title: "roomjack startet in Heidenheim",
                        description: "Finde deine ideale Unterkunft und entdecke die Studierenden-Hotspots der Stadt..."
                    },
                    horb: {
                        title: "roomjack startet in Horb am Neckar",
                        description: "Finde deine ideale Unterkunft und entdecke die Studierenden-Hotspots der Stadt..."
                    }
                },
                roomjack_is_green: {
                    header_1: "roomjack - Wohnen",
                    header_2: "nachhaltig gedacht.",
                    description: "Klicke hier um mehr über unsere Ziele und Mission zu erfahren!",
                    button_text: "Jetzt mehr erfahren!",
                    image_alt: "Nachhaltiger ökologischer Mann"
                },
            },
            read_more: "mehr lesen"
        },
        search_page: {
            housings_in: "rooms in #",
            search_result_count_label: "# Ergebnis gefunden",
            search_result_count_label_plural: "# Ergebnisse gefunden",
            search_result_count_label_more: "Über # Ergebnisse gefunden",
            sort_by: "Sortieren",
            sorting: {
                rating: "Beliebteste",
                price_ascending: "Preis aufsteigend",
                price_descending: "Preis absteigend",
                distance: "Umkreis",
                newest_first: "Neueste zuerst",
                oldest_first: "Älteste zuerst"
            },
            adjust_search: "Suchen",
            actualize_search_tooltip: "Bitte wähle einen anderen Zeitraum",
            filtering: "Filtern",
            filters: {
                general_filter: "Allgemeines",
                equipment_filter: "Austattung",
                characteristic_filter: "Merkmale",
                refine_search_criteria: "Suchkriterien verfeinern",
                reset_all_filters: "Alles zurücksetzen",
                direct: "Direkte Buchung",
                inquiry: "Buchung per Anfrage",
                apply_button: "# Ergebnisse anzeigen",
                housing_type: "Art des rooms",
                booking_type: "Buchungsart",
                living_space: "Wohnfläche",
                radius: "Umkreis",
                without_cancellation_conditions: "Nur Inserate ohne Stornobedingungen"
            },
            search_running: "Einen Augenblick bitte! Wir jacken die besten rooms für dich!",
            no_results_header: "Wir haben leider keine passenden rooms gefunden.",
            no_results_filter: "Wir haben leider keine passenden rooms für deine Suchkriterien gefunden. Versuche es erneut mit anderen Suchkriterien.",
            optional_adverts: "Wir haben diese rooms gefunden, die dir eventuell gefallen könnten:",
            no_results_search: "In dem von dir gewählten Zeitraum sind alle rooms besetzt, bitte probiere ein anderes Start- und/oder Enddatum.",
            price_per_day: "Tagesmiete",
            price_per_month: "Monatsmiete",
            sum: "Gesamtmiete",
            show_map: "Karte",
            searching: "Sucht..."
        },
        expose: {
            labels: {
                target_group_text: "Dieser room ist perfekt für ",
                contact_owner_label: "@ kennenlernen",
                share_with_friends_label: "Room mit deinen Freunden teilen",
                please_select_time_interval: "Bitte Zeitraum wählen",
                housing_attributes_label: "Besondere Merkmale des room",
                housing_equipment_label: "Das bietet der room",
                no_equipment_placeholder: "Für diesen room wurde keine Ausstattung angegeben. Bei Interesse bitte Vermieter:in kontaktieren, um mehr darüber zu erfahren.",
                no_house_rules_placeholder: "Für diesen room sind uns keine Hausregeln bekannt. Bei Interesse bitte Vermieter:in kontaktieren, um mehr darüber zu erfahren.",
                share_housing: "Room teilen",
                copy_link: "...oder Link kopieren",
                speaks: "spricht",
                more_langauges: "weitere Sprachen"
            },
            buttons: {
                inspect_blueprint: "Jetzt Grundriss ansehen",
                share_now: "Jetzt teilen",
                report_advert: "Melden",
                favorize: "Merken",
                favorized: "Gemerkt",
                share: "Teilen",
                equipment_toggle_show: "Alle # Ausstattungen ansehen",
                equipment_toggle_hide: "Weniger Ausstattung anzeigen",
                house_rules_toggle_show: "Alle # Hausregeln ansehen",
                house_rules_toggle_hide: "Weniger Hausregeln anzeigen",
                show_more: "Mehr anzeigen",
                show_less: "Weniger anzeigen",
                book_now: "Jetzt buchen!",
                to_booking: "Zur Buchung",
                inquiry_now: "Jetzt anfragen!",
                check_availability: "Verfügbarkeit prüfen",
                check_availability_loading: "Prüfe Verfügbarkeit..."
            },
            alerts: {
                copy_to_clipboard_success: "Der Text wurde erfolgreich in die Zwischenablage kopiert!",
                copy_to_clipboard_failure: "Der Text konnte leider nicht in die Zwischenablage kopiert werden, bitte per Selektion manuell kopieren.",
                cannot_book_own: "Du kannst dein eigenes Inserat nicht buchen!"
            },
            housing_available: "Der Room ist im gewünschten Zeitraum verfügbar!",
            share_housing_mail_text: "Hey,%0D%0A%0D%0ASchau dir diesen room an den ich gerade bei roomjack gefunden habe:%0D%0A%0D%0A@%0D%0A%0D%0AViele Grüße,%0D%0A#",
            share_housing_mail_text_anonymous: "Hey,%0D%0A%0D%0ASchau dir diesen room an den ich gerade bei roomjack gefunden habe:%0D%0A%0D%0A@%0D%0A%0D%0AViele Grüße",
        },
        footer: {
            landlord_column: {
                title: "Vermieter",
                become_landlord: "So wirst Du zur VermieterIn",
                rent_responsible: "Verantwortungsvoll vermieten",
                become_b2b: "B2B Kunden",
            },
            community_column: {
                title: "Community",
                tips_tricks: "Tipps & Tricks",
                partner: "Partner von roomjack"
            },
            support_column: {
                title: "Support & Kontakt",
                faq_help: "FAQs und Hilfe",
                report_bug: "Ein Problem melden",
                contact: "Kontakt",
            },
            legal_links: {
                terms_of_service: "Nutzungsbedingungen",
                privacy_policy: "Datenschutz",
                legal_notice: "Impressum",
            },
            social_media: {
                follow_us: "Folge uns"
            }
        },
        content_pages: {
            roomjack_is_green: {
                title: "roomjack - wohnen nachhaltig gedacht",
                header: "Unser Beitrag zu einer nachhaltigeren Zukunft",
                features: [
                    {
                        text: "Wusstest du, dass die Gebäude, in denen wir in der EU leben, wohnen und arbeiten für 40% des Energieverbrauchs und 36% der Treibhausgasemissionen verantwortlich sind? Gleichzeitig drängen immer mehr Menschen in Städte, weshalb immer noch mehr Wohnraum in Städten gebaut und errichtet wird. Im Städtebau nennt sich das Ausnutzen von freien innerstädtischen Flächen, um das Verhältnis zwischen Wohnraum und verbauter Fläche zu erhöhen, Nachverdichtung. Wir von roomjack möchten mit unserer Plattform einen anderen Weg gehen und Städte ohne zusätzlichen Beton, sondern über den Faktor Zeit \"nachverdichten\".",
                        image_alt: "Frau gießt Blumen"
                    },
                    {
                        text: "Verschiedene Entwicklungen in der jüngeren Vergangenheit haben dazu geführt, dass ein dauerhafter Wohnsitz am Arbeitsort für viele Menschen nicht mehr notwendig ist. Auch bei Ausbildungen oder dem Studium gewinnen vorübergehende Präsenzphasen gegenüber dem dauerhaften Aufenthalt immer mehr an Bedeutung. Gleichzeitig gibt es, wenn auch oft nur kurzzeitig, leerstehende Wohnungen, Apartments oder Hotelzimmer, die wir nutzen können, um diese wachsenden kurzzeitigen Wohnbedürfnisse zu befriedigen und gleichzeitig die Nachfrage nach immer mehr Wohnraum einzudämmen.",
                        image_alt: "Frau auf Schaukel im Grünen"
                    }
                ],
                paragraphs: [
                    "Deshalb möchten wir auch dich ermutigen roomjack.at zu nutzen, um durch das Erhöhen der Auslastung von vorhandenem Wohnraum eine Form von nachhaltigem Wohnen zu fördern. Wir bei roomjack sind der Meinung, dass Menschen mit derartigen Wohnbedürfnissen und Wohnraumangeboten zusammenfinden und so gemeinsam einen Teil zum nachhaltigeren Umgang mit Ressourcen beitragen sollten.",
                ],
            },
            linz: {
                scroll_box_text: "startet in Linz!",
                title: "In Linz beginnt's!",
                description: "Zugegeben - ein etwas angestaubter Slogan. Aber für roomjack ist er brandaktuell, denn roomjack öffnet seine Pforten und startet den Betrieb in unserer Landeshauptstadt – Linz! Erste wagemutige Hotelbetreiber und gewerbliche Vermieter konnte roomjack in den vergangenen Wochen für den Beta-Start der Plattform begeistern und so können wir euch ab sofort zeigen wo man in Linz bezahlbare Wohnungen und Zimmer findet! Wir möchten uns an der Stelle bei allen ‚first-mover‘ für ihr Vertrauen bedanken!",
                hotspot_header: "Angesagte Hotspots und Aktivitäten in Linz",
                hotspots: "Damit ihr neben euren Jobs, dem Studium, der Ausbildung oder den Projekttagen auch ein bisschen Abwechslung bekommt, haben wir euch hier, mit freundlicher Unterstützung des Tourismusverbandes Linz, ein paar angesagte ‚places to be‘ und Freizeit-Tipps zusammengestellt.",
                hotspot_list: [
                    {
                        header: "Two Rabbits - Cafe - Aperitivo",
                        content: "Frühstück all-day-long oder den Feierabend richtig zelebrieren? Beides davon gibt’s im neu eröffneten Two-Rabbits in der Linzer Hofgasse. 40 verschiedene Aperitivos erwarten euch und sorgen dafür, dass jeder Feierabend einzigartig wird! Geöffnet ist das Two-Rabbits von Dienstag bis Samstag jeweils von 07:30 bis 19:00 Uhr. #link#"
                    },
                    {
                        header: "Mural Harbor Gallery",
                        content: "Für Urban Art Begeisterte ein absolutes Highlight: Die Mural Harbor Gallery im Linzer Hafen. Auf einer Fläche von 135 Hektar befinden sich mehr als 300, teils überdimensionale Murals und Graffitis von nationalen und internationalen Künstlern. Die Muarl Harbor Gallery ist damit eines der weltweit größten Projekte für Urban Art. #link#"
                    },
                    {
                        header: "Ars Electronica",
                        content: "Science and Future ist dein Ding? Dann verpass bei deinem Trip nach Linz nicht das Ars Electronica! Im ‚Museum der Zukunft‘ befinden sich Ausstellungen zu den Themen Kunst, Technologie und Gesellschaft. Entdecke in interaktiven Stationen wie künstliche Intelligenz unser Leben verändert, oder lass dich von anderen spannenden Zukunftsfeldern begeistern. #link#"
                    },
                    {
                        header: "Straßenmusiker Walter Klinger",
                        content: "Ein echtes Linzer Unikat: Walter Klinger! Als ‚Elton John von Linz‘ begeistert der Straßenmusiker mit seinem Keyboard seit Jahren die Passanten in der Linzer Landstraße. Wer Zeit hat, sollte sich das nicht entgehen lassen. Er spielt meistens am Samstag Nachmittag."
                    },
                    {
                        header: "Geheimtipp!",
                        content: "Du reist nachhaltig und kommst mit Bus oder Bahn nach Linz? Dann Tausche dein Ticket in der Tourist Information (Hauptplatz 1, 4020 Linz) gegen einen 10€ Erlebnisgutschein in Linz!"
                    }
                ],
                closing_block: "Für weitere Sightseeing-Tipps und Stadteindrücke besuche #link# oder #link#",
                to_page: "Hier geht’s zur Website!"
            },
            heidenheim: {
                scroll_box_text: "startet in Heidenheim!",
                title: "Finde deine ideale Unterkunft und entdecke die Studierenden-Hotspots der Stadt",
                paragraphs: [
                    "Heidenheim an der Brenz - Roomjack ist endlich da und wir möchten dich als Studierende/r der Dualen Hochschule Baden-Württemberg (<a href='https://www.heidenheim.dhbw.de/startseite' rel='noreferrer nofollow' target='_blank'>DHBW</a>) herzlich in Heidenheim willkommen heißen! Ab sofort kannst du von den umfangreichen Funktionen und Vorteilen von Roomjack profitieren und ganz einfach deine ideale Unterkunft in Heidenheim finden.",
                    "Mit Roomjack steht dir eine spezialisierte Plattform zur Verfügung, die darauf ausgerichtet ist, passende Unterkünfte für Studierende und junge Berufstätige wie dich zu finden. Ob Zimmer oder Apartments - wir haben eine Vielzahl von Optionen, damit du dich in Heidenheim wohlfühlen kannst. Und das Beste daran? Die Buchung ist ganz einfach und unkompliziert!",
                    "Neben der DHBW gibt es in Heidenheim auch jede Menge Studierenden-Hotspots und sehenswerte Orte, die du erkunden kannst. Hier sind einige Empfehlungen:"
                ],
                hotspot_list: [
                    {
                        header: "Schloss Hellenstein",
                        content: "Tauche ein in die Geschichte von Heidenheim und besuche das beeindruckende #link#. Von hier aus hast du einen atemberaubenden Blick auf die Stadt und kannst im Archäopark Vogelherd sogar in die prähistorische Zeit eintauchen."
                    },
                    {
                        header: "Brenzpark",
                        content: "Genieße eine Auszeit im wunderschönen #link#. Hier kannst du entlang des Flusses spazieren gehen, die vielfältige Pflanzenwelt erkunden oder dich einfach auf den großzügigen Grünflächen mit Freunden treffen."
                    },
                    {
                        header: "Voith-Arena",
                        content: "Als Fußballfan solltest du dir ein Spiel des #link# 1846 in der #link# nicht entgehen lassen. Die mitreißende Atmosphäre und die leidenschaftlichen Fans sorgen für echte Gänsehautmomente."
                    },
                    {
                        header: "Baseball",
                        content: "Die \"#link#\" sind der stolze Baseballverein in Heidenheim. Mit leidenschaftlichem Spiel und zahlreichen Erfolgen haben sie sich in der deutschen Baseballszene einen Namen gemacht. Spieler aller Altersgruppen trainieren hier und das Team hat bereits nationale Meisterschaften gewonnen. Die Heideköpfe fördern die Baseballkultur und schaffen eine starke Gemeinschaft zwischen Spielern und Fans."
                    },
                    {
                        header: "Opernfestspiele",
                        content: "Die #link# in Heidenheim sind ein jährliches Highlight mit hochkarätigen Opernaufführungen in der imposanten Schlossruine Hellenstein. Das abwechslungsreiche Programm lockt Opernliebhaber aus aller Welt an. Ein unvergessliches Erlebnis für Kulturbegeisterte."
                    },
                    {
                        header: "Baseball",
                        content: "Die \"#link#\" sind der stolze Baseballverein in Heidenheim. Mit leidenschaftlichem Spiel und zahlreichen Erfolgen haben sie sich in der deutschen Baseballszene einen Namen gemacht. Spieler aller Altersgruppen trainieren hier und das Team hat bereits nationale Meisterschaften gewonnen. Die Heideköpfe fördern die Baseballkultur und schaffen eine starke Gemeinschaft zwischen Spielern und Fans."
                    }
                ],
                closing_paragraphs: [
                    "Heidenheim hat nicht nur die DHBW zu bieten, sondern auch eine lebendige Atmosphäre und vielfältige Freizeitmöglichkeiten, die es zu <a href='https://www.tourismus-heidenheim.de/startseite' rel='noreferrer nofollow' target='_blank'>entdecken</a> gilt. Mit roomjack hast du die Möglichkeit, eine perfekte Unterkunft zu finden und die Stadt in vollen Zügen zu genießen.",
                    "Registriere dich noch heute auf Roomjack und entdecke die unzähligen Möglichkeiten, die Heidenheim an der Brenz für dich bereithält. Finde deine Traumunterkunft und erkunde die Studierenden-Hotspots sowie die faszinierenden Sehenswürdigkeiten der Stadt."
                ],
                closing_block: "roomjack - Deine Plattform für kurzzeitige Unterkünfte in Heidenheim!"
            },
            horb: {
                scroll_box_text: "startet in Horb am Neckar!",
                title: "Finde deine ideale Unterkunft und entdecke die Studierenden-Hotspots der Stadt",
                paragraphs: [
                    "Horb am Neckar - Roomjack ist jetzt auch in Horb am Neckar verfügbar und wir freuen uns, dich als Studierende/r der Dualen Hochschule Baden-Württemberg (<a href='https://www.heidenheim.dhbw.de/startseite' rel='noreferrer nofollow' target='_blank'>DHBW</a>) in unserer charmanten Stadt willkommen zu heißen! Ab sofort kannst du von den umfangreichen Funktionen und Vorteilen von Roomjack profitieren und problemlos deine ideale Unterkunft in Horb am Neckar finden.",
                    "Roomjack ist spezialisiert auf die Vermittlung passender Unterkünfte für Studierende und junge Berufstätige wie dich. Wir haben bereits eine große Auswahl an Zimmern in Horb am Neckar, um sicherzustellen, dass du dich hier wie zuhause fühlst. Unsere Buchungsmöglichkeiten sind schnell, einfach und bieten dir die Flexibilität, die du benötigst, um dich auf das Wesentliche zu konzentrieren - dein Studium und das Erkunden der Stadt.",
                    "Neben der DHBW gibt es in Horb am Neckar auch viele Studierenden-Hotspots und sehenswerte Orte, die du entdecken kannst. Hier sind einige Empfehlungen:"
                ],
                hotspot_list: [
                    {
                        header: "Horber Schloss",
                        content: "Lasse dich von der beeindruckenden Architektur und der Geschichte des Horber Schlosses verzaubern. Von hier aus hast du einen wunderbaren Blick über die Stadt und ihre Umgebung."
                    },
                    {
                        header: "Neckarpromenade",
                        content: "Genieße einen entspannten Spaziergang entlang der malerischen Neckarpromenade und lasse die Natur auf dich wirken. Hier findest du Ruhe und Erholung vom Studienalltag."
                    },
                    {
                        header: "Altstadt von Horb",
                        content: "Tauche ein in das charmante Ambiente der historischen Altstadt von Horb am Neckar. Schlendere durch die verwinkelten Gassen, entdecke gemütliche Cafés und stöbere in den lokalen Geschäften."
                    },
                    {
                        header: "SchieferErlebnis",
                        content: "Besuche das SchieferErlebnis, ein faszinierendes Museum und Erlebniszentrum, das sich der Geschichte und Nutzung des Schiefers widmet. Hier kannst du mehr über dieses einzigartige Naturmaterial erfahren."
                    }
                ],
                closing_paragraphs: [
                    "Horb am Neckar bietet nicht nur eine herausragende Bildungseinrichtung wie die DHBW, sondern auch eine lebendige Stadt mit einer Vielzahl von Aktivitäten und kulturellen Angeboten. Mit Roomjack hast du die Möglichkeit, die perfekte Unterkunft zu finden und gleichzeitig die Studierenden-Hotspots und Sehenswürdigkeiten der Stadt zu erkunden.",
                    "Registriere dich noch heute auf Roomjack und entdecke die unzähligen Möglichkeiten, die Horb am Neckar für dich bereithält. Finde deine Traumunterkunft und genieße das pulsierende Studierendenleben in einer Stadt, die viel zu bieten hat."
                ],
                closing_block: "roomjack - Deine Plattform für kurzzeitige Unterkünfte in Horb am Neckar!"
            },
            rent_responsible : {
                title: "Verantwortungsvoll vermieten",
                header_1: "Starte jetzt mit der Erstellung deines Inserats bei roomjack",
                header_2: "Wir wünschen viel Erfolg!",
                content: [
                    "Je nach Situation kann Vermieten eine sehr komplexe, rechtliche Angelegenheit darstellen, weshalb wir dich sensibilisieren möchten, dich mit dem Thema vollumfänglich auseinander zu setzen bevor du einen room bei roomjack anbietest! Die Regeln hierzu sind vielseitig und können von vielen unterschiedlichen Dingen abhängig sein, während die rechtlichen Konsequenzen durchaus weitreichend sein können!",
                    "Zunächst ist zu klären unter welchen Rahmenbedingungen du deinen room anbietest. Bist du selbst Mieter und möchtest ein Zimmer untervermieten? Gehört dir eine Wohnung, die du gerne anbieten möchtest? Welche Aufenthaltsdauern willst du ermöglichen?",
                    "Anschließend solltest du dich mit dem Thema Gewerbeberechtigung auseinandersetzen. Unter Umständen kann eine solche für dich rechtlich notwendig sein! Hilfe bieten dir hier die Gewerbebehörden, aber auch die Wirtschaftskammer.",
                    "Kläre auch, ob dein room für die von dir geplante Vermietungsart geeignet ist. Im Speziellen bei Kurzzeitvermietung können rechtliche Konstellationen mit Miteigentümern eine Rolle spielen, aber auch baurechtliche und raumordnungsrechtliche Themen.",
                    "In Österreich gilt das Meldegesetz, wodurch du unter Umständen dazu verpflichtet sein könntest deine Gäste anzumelden und ein Gästeverzeichnis zu führen. Überlege dir, wie du dies umsetzen könntest.",
                    "Bespreche dein Vorhaben auch mit einem Steuerberater. Deine Einkünfte können möglicherweise auch der Steuerpflicht unterliegen. Außerdem könnte deine Stadt Ortstaxen für Übernachtungen erheben.",
                    "Bedenke auch das Thema Vertragsgestaltung. Je nach Situation wird es sinnvoll sein, schriftliche Übereinkünfte mit deinem Mieter zu schließen, einen Mietvertrag zu schließen und sich auch auf eine Kaution zu einigen."
                ]
            },
            b2b : {
                title: "roomjack für gewerbliche Kunden",
                content: ["Hotels, Pensionen, Boardinghouses und gewerbliche Apartment-Anbieter aufgepasst! Du willst deine Reichweite vergrößern und die Auslastung erhöhen? Dann bist du bei roomjack genau richtig! Egal, ob du einzelne Apartments anbietest oder Teil einer großen Hotelkette bist. Mit unseren Tools und APIs bieten wir Lösungen für alle Dimensionen des Vermietens und sparen dir Zeit, damit du dich um deine Gäste kümmern kannst.",
                    "Verwalte deine rooms direkt über unser professionelles Gantt-Chart, erstelle Rechnungen automatisch, verfolge deine Leistungen und optimiere dein Business mit den Daten aus deinen Statistiken. Diese und noch viele weitere benutzerfreundliche und innovative Tools warten auf dich und werden ständig mehr. Du arbeitest mit einem Channel-Manager? Kein Problem! Die Liste der roomjack-Partner und Schnittstellen wird ständig länger!",
                    "Wieviel kostet roomjack für B2B-Kunden? - Für B2B-Kunden schlägt roomjack nur 1% auf die übliche roomjack-fee auf. Wird dein room gebucht, wird ein Teil der Gesamtkosten einbehalten. roomjack kostet dich also auch als B2B-Kunde nur dann etwas, wenn du auch durch roomjack verdienst! 100% Chance, 0% Risiko - Genial, oder?",
                    "Verpasse nicht die Gelegenheit, dein Business auszubauen und erstelle jetzt deinen Account bei roomjack - der Alternative aus Österreich!"
                    ],
                register_as_b2b_button: "Jetzt Geschäftskonto erstellen"
            },
            privacy : {
                title: "Datenschutzerklärung"
            },
            agb : {
                title: "Nutzungsbedingungen/Allgemeine Geschäftsbedingungen der Plattform roomjack.at"
            },
            legalnotice : {
                title: "Impressum"
            },
            partners: {
                title: "Unsere Partner",
                design: "Design und Marketing",
                development: "Development"
            }
        },
        profile_menu: {
            profile_overview: {
                button: "Profilübersicht",
                save_avatar: "Avatar speichern",
                update_avatar: "Profilbild aktualisieren",
                change_email_description: "Zum Schutz deines Accounts ist die Änderung der Email-Adresse nur über unserem Support möglich.",
                avatar_file_size_error: "Diese Datei kann nicht hochgeladen werden.<br><br>Bitte achte darauf, dass sie von einer der folgenden Formaten:<br>JPG/JPEG, PNG und BMP<br>ist und ihre Größe nicht 2MB überschreitet.",
                avatar_file_size_error_title: "Fehler beim Upload der Datei",
            },
            account_settings: {
                button: "Account",
                revocation_header: "Widerrufsfrist",
                skip_revocation_title: "Dienstleistung vor Ablauf der Widerrufsfrist für Konsumenten",
                skip_revocation_time: "Ich verlange ausdrücklich, dass mit der Ausführung der Dienstleistungen vor Ablauf der 14-tägigen Widerrufsfrist begonnen wird. Mein Widerrufsrecht erlischt mit der vollständigen Erfüllung der Dienstleistungen. Wurden die Dienstleistungen nicht vollständig erfüllt und wird der Vertrag von mir widerrufen, wird mir ein im Vergleich zum vertraglich vereinbarten Gesamtpreis verhältnismäßiger Betrag in Rechnung gestellt.",
                revocation_description: "Bei Konsumenten erfolgt die Ausführungen der Dienstleistungen grundsätzlich erst nach Ablauf der 14-tägigen Widerrufsfrist. Bis zum Ablauf der 14-tägigen Widerrufsfrist haben Konsumenten das Recht auf Widerruf.",
                revocation_expiry: "Deine Widerrufsfrist läuft in # ab.",
                read_revocation: "Genaue Details findest du in der #link#.",
                revocation_period_running: "Du hast unsere Widerrufsbelehrung gelesen und akzeptiert. Nun hast du noch # um das Widerrufsrecht zu nutzen. Nach Ablauf dieser Zeit erlischt dein Widerrufsrecht und ab dann wirst du in der Lage sein Inserate zu veröffentlichen.",
                revocation_checkbox_label: "Ich habe die # gelesen und stimme ihr zu.",
                accept_revocation_button: "Absenden",
                stripe_account_header: "Stripe Account",
                stripe_description: "roomjack arbeitet für sichere Zahlungsabwicklungen und für den sicheren Umgang mit persönlichen Daten mit #link# zusammen. Alle persönliche Informationen, die für einen verifizierten Account bei roomjack relevant sind, werden aus diesem Grund in einer abgesicherten Sitzung abgefragt.",
                stripe_account_completion_step: "Schritt 1: Stripe-Account vollständig ausfüllen.",
                stripe_account_completion: "Klicke dazu bitte unten auf den Button.",
                stripe_account_verification_step: "Schritt 2: Stripe-Account verifizieren.",
                stripe_account_verification: "Nun musst du dich im letzten Schritt noch verifizieren, klicke dazu bitte unten auf den Button.",
                stripe_account_change_step: "Dein Stripe-Account ist vollständig ausgefüllt und verifiziert. Du kannst eine Sitzung bei Stripe starten, falls du Informationen aktualisieren möchtest. Klicke dazu bitte unten auf den Button.",
                stripe_session_warning: "Bitte beachte: Du wirst für die Sitzung auf die Seite von Stripe und nach Beendung der Sitzung zurück auf unsere Seite umgeleitet.",
                start_session: "Stripe Sitzung starten",
                starting_stripe_session: "Account-Status wird abgefragt...",
                account_status_change_header: "RoomJack Account pausieren/löschen",
                pause_account_button: "Pausieren",
                delete_account_button: "Löschen",
                delete_account_header: "Account löschen",
                pause_account_header: "Account pausieren"
            },
            password_settings: {
                button: "Passwort",
                new_password_same: "Das neue Passwort stimmt mit dem alten überein. Bitte neues Passwort wählen.",
            },
            third_party_software: {
                button: "Drittsoftware",
                third_party_software_header: "Drittsoftware-Anbindung",
                channel_manager_title: "Channel-Manager",
                channel_manager_description: "!!!TODO: Text optimieren!!! Hier hast du die Möglichkeit, deine Inserate mit einem Channel-Manager zu verbinden und somit sie mit anderen Plattformen synchron zu halten. Wir arbeiten kontinuierlich daran unsere kompatibilität weiter auszubauen. Ist dein Channel-Manager nicht auf der Liste? Dann teile uns bitte #link-button# mit, welche Software du benutzt, damit wir nach Möglichkeit und Nutzungshäufigkeit auch diese einbinden können.",
                third_party_software_description: "Hier hast du Möglichkeit deinen Account mit Software von Drittanbieter (z.B. Hotelmanagement-Software) zu verbinden, damit deine Inserate mit anderen Buchungsplattformen synchronisiert, deine Tagesmiete aktualisiert werden können und vieles mehr. Du benötigst hierfür lediglich den öffentlichen RoomJack-API-Schlüssel des Software-Entwicklers.",
                supported_software: {
                    zimmersoftware: {
                        title: "ZimmerSoftware Anbindung",
                        description: "Um ein ZimmerSoftware-Account mit einem deinen Inseraten zu verbinden, klicke bitte auf Inserat verbinden und folge die Einweisungen in dem sich öffnenden Popup. Um bestehende Verbindungen zu bearbeiten oder löschen, klicke bitte in der Liste unten den Entsprechenden Icon an.",
                        no_adverts_description: "Du hast noch keine veröffentlichten Inserate bei uns. Um Inserate bei roomjack mit ZimmerSoftware verbinden zu können, müssen sie bei roomjack veröffentlicht sein.",
                        connect_account: "ZimmerSoftware-Account verbinden",
                        setup_account: "ZimmerSoftware Setup-Assistent",
                        overview_table: {
                            account: "ZimmerSoftware-Account",
                            advert: "Inserat bei roomjack",
                            connection_status: "Verbindungsstatus",
                            action: "Aktionen"
                        },
                        setup_assistant: {
                            connect_account_header: "Before es losgehen kann:",
                            connect_account_description: "TODO: Beschreibungstext für die notwendigen Schritte bei ZimmerSoftware, bevor die Einrichtung losgehen kann.",
                            advert_to_connect: "Inserat",
                            zimmersoftware_username: "Dein ZimmerSoftware Benutzername",
                            zimmersoftware_password: "Dein ZimmerSoftware Channel-Manager Passwort für roomjack",
                            connecting_account: "Account wird verbunden",
                            checking_account_status: "Account Status wird getestet",
                            updating_account: "Account wird geupdated",
                            room_category: "RoomCategory bei Zimmersoftware"
                        },
                        error_codes: {
                            user_not_found: "Es existiert kein Benutzer mit dem angegebenen Namen bei ZimmerSoftware.",
                            wrong_pw_err: "Das angegebene Channel-Manager Passwort ist falsch.",
                            account_connected_err: "Dieses Zimmersoftware-Account ist bereits verbunden."
                        },
                        alert: {
                            delete_warning: "TODO: Text überarbeiten. Durch die Löschung der Verbindung zwischen ZimmerSoftware-Account und deinem Inserat werden alle aktuellen Blockierungen, die durch ZimmerSoftware bei roomjack eingetragen wurden ebenfalls gelöscht. Darüber hinaus wird in Zukunft auch keine Synchronisierung mehr stattfinden, was zu überlappende Buchungen führen kann. Bist du dir sicher, dass du die Verbindung löschen möchtest?"
                        },
                        delete_account: "Ja, Account entkoppeln",
                        deleting_account: "Account wird entkoppelt...",
                    }
                },
                add_third_party_software: "Neue Verbindung herstellen",
                table: [ "Name", "Öffentlicher Schlüssel", "Löschen"],
                modal: {
                    header: "Account mit Drittsoftware verbinden",
                    description: "Bitte füge den öffentlichen Schlüssel (beginnt \"rj-ak-live_\") des Drittsoftware-Herstellers unten in das vorgesehene Feld ein und bestätige, dass du diese Software dazu berechtigst, dein Account zu verwalten.",
                    grant_access_text: "Hiermit bevollmächtige ich den Besitzer des oben eingetragenen Schlüssels dazu, mein Account über ein Drittsoftware verwalten zu lassen. Mir ist bewusst, dass RoomJack GmbH keine Verantwortung für mögliche Fehler übernimmt, die auf diese Art und Weise auftreten könnten.",
                    button: "Mit Drittsoftware verbinden"
                },
                delete_header: "Warnung",
                delete_warning: "Achtung! Wenn du diese Berechtigung zurückziehst, wird die Software, zu der dieser Schlüssel gehört, nicht mehr in der Lage deinen Account zu verwalten. Bist du dir sicher?",
                revoke_permission: "Ja, Berechtigung zurückziehen!"
            },
            notifications: {
                button: "Benachrichtigungen",
                description: "Ich möchte eine Benachrichtigung per Email erhalten, wenn ...",
                account: {
                    header: "Allgemeine Benachrichtigungen",
                    notifications: {
                        new_message: "ich eine neue Chat-Nachricht erhalte.",
                        report_received: "ich eine Supportanfrage abgeschickt habe.",
                        newsletter: "Newsletter"
                    }
                },
                modes: {
                    renter: {
                        header: "Benachrichtigungen - Mieten",
                        notifications: {
                            guest_cancelled_booking: "ich eine Buchung storniere.",
                            booking: "meine Buchung erfolgreich war.",
                            inquiry: "meine Buchungsanfrage erfolgreich zugestellt wurde.",
                            inquiry_declined: "meine Buchungsanfrage abgelehnt wurde.",
                            inquiry_accepted: "meine Buchungsanfrage akzeptiert wurde.",
                            booking_invoice: "mir meine Vermieter*in eine Rechnung per Chat zuschickt.",
                            review_stay: "ich nach einer Aufenthalt eine Bewertung dazu abgeben kann."
                        }
                    },
                    landlord: {
                        header: "Benachrichtigungen - Vermieten",
                        notifications: {
                            guest_cancelled_booking: "ein Mieter:in eine Buchung storniert.",
                            booking: "mein Inserat gebucht wird.",
                            inquiry: "mir jemand eine Buchungsanfrage schickt.",
                            booster_invoice: "ich einen Booster für mein Inserat kaufe.",
                            review_guest: "ich eine Bewertung für meine:n Mieter:in abgeben kann.",
                            advert_published: "ich ein Inserat veröffentlicht habe."
                        }
                    }
                },
            },
            account_verification: {
                header: "Account verifizieren",
                alert_description: "Du hast deinen Account bei uns noch nicht verifizert. Damit du Inserate bei uns publizieren und über uns vermieten kannst, musst du deinen Account vollständig verifizieren. Nur so können wir dich bei einer Buchung ausbezahlen. Bitte gehe dafür in <b>Benutzerprofil verwalten > Verifizierung</b>, oder klicke auf den Button unten, um direkt dorthin zu gelangen.",
                verify_now: "Jetzt Account verifizieren"
            },
            change_account_status: {
                delete_button: "Account löschen",
                pause_button: "Account pausieren",
                delete_description_header: "Schade, dass du deinen Account löschen möchtest!",
                pause_description_header: "Schade, dass du deinen Account pausieren möchtest!",
                delete_description_content: "Achtung! Um deinen Account löschen zu können, darfst du keine aktiven bzw. bevorstehenden Buchungen haben. Du musst deinen Account nicht sofort löschen. Du kannst diesen auch <button class='link-button'>pausieren</button>.<br><br>Dein Account inkl. aller Einstellungen wird vollständig gelöscht. Bitte beachte dass wir aufgrund rechtlicher Anforderungen alle Buchungsdaten für 10 Jahre aufbewahren müssen. Bist du sicher, dass du fortfahren willst?",
                delete_description_content_adverts: "Achtung! Um deinen Account löschen zu können, darfst du keine aktiven bzw. bevorstehenden Buchungen haben. Du musst deinen Account nicht sofort löschen. Du kannst diesen auch <button class='link-button'>pausieren</button>.<br><br>Alle deine veröffentlichten & für später gespeicherten Inserate werden gelöscht und können nicht mehr gefunden werden! Dein Account inkl. aller Einstellungen wird vollständig gelöscht. Bereits getätigte Käufe wie beispielsweise Booster für Inserate werden unwirksam und können nicht rückerstattet werden. Bitte beachte, dass wir aufgrund rechtlicher Anforderungen alle Buchungsdaten für 10 Jahre aufbewahren müssen. Bist du sicher, dass du fortfahren willst?",
                pause_description_content: "Du brauchst eine Pause vom roomjack? Dafür musst du deinen Account nicht gleich löschen, du kannst ihn auch pausieren.<br>Achtung! Um deinen Account pausieren zu können, darfst du keine aktiven bzw. bevorstehenden Buchungen haben.<br>Die von dir getätigten Einstellungen an deinem roomjack Account bleiben selbstverständlich unberührt. Bist du sicher, dass du fortfahren willst?",
                pause_description_content_adverts: "Du brauchst eine Pause vom roomjack? Dafür musst du deinen Account nicht gleich löschen, du kannst ihn auch pausieren.<br>Achtung! Um deinen Account pausieren zu können, darfst du keine aktiven bzw. bevorstehenden Buchungen haben.<br>Die von dir getätigten Einstellungen an deinem roomjack Account bleiben selbstverständlich unberührt.<br><br>Alle deine veröffentlichten Inserate werden deaktiviert und können nicht mehr gefunden werden. Nach deiner Rückkehr musst du die Inserate lediglich erneut veröffentlichen. Die von dir getätigten Einstellungen an deinem roomjack Account bleiben selbstverständlich unberührt. Bereits getätigte Käufe wie beispielsweise Booster für Inserate werden nicht pausiert und laufen bis zum vereinbarten Zeitpunkt weiter. Bist du sicher, dass du fortfahren willst?",
                delete_reasons: {
                    no_use: "ich will meinen room nicht mehr online anbieten oder über roomjack rooms buchen",
                    dissatisfied: "ich bin mit roomjack nicht zufrieden",
                    terms_of_service: "ich kann die roomjack Nutzungsbedingungen nicht einhalten",
                    security_concerns: "ich habe Sicherheitsbedenken",
                    misc_reason: "anderer Grund"
                },
                pause_reasons: {
                    timeout: "ich brauche ein Auszeit von roomjack",
                    renovation: "langandauernde Sanierungs- Renovierungsarbeiten",
                    pause_reason_3: "private Gründe",
                    misc_reason: "anderer Grund"
                },
                misc_reason_description: "Bitte beschreibe den Grund in paar Worten:",
                operation_error: "Die von dir gewünschte Aktion ist leider nicht möglich, da du noch mindestens eine offene Buchung hast.<br><br>Für weitere Informationen zur Löschung des Accounts besuche unsere <button class='link-button'>FAQ's & Hilfe</button>.",
                deleting_account: "Account wird gelöscht",
                pausing_account: "Account wird pausiert",
            },
            to_jackboard: "Zum Jack-Board",
            to_jackboard_tooltip: "Wusstest du es schon? Du kannst dein Jack-Board auch jederzeit über dem Hauptmenü öffnen. Um das Menü zu öffnen, klicke oben rechts auf dem Profil-Icon neben den Benachrichtigungs-Center."
        },
        jackboard: {
            dashboard: {
                title: "Mein Dashboard",
                name: "Dashboard",
                toolbar_buttons: {
                    edit_dashboard: "Widgets bearbeiten",
                    change_layout: "Layout ändern",
                    placeholders: {
                        no_widget_set: "Kein Widget ausgewählt."
                    }
                },
                widgets: {
                    message: {
                        name: "Nachrichten",
                        header: "Neue Nachrichten",
                        body_placeholder: "Du hast noch keine Unterhaltungen.",
                        footer: {
                            check_all_messages: "JACK alle Nachrichten"
                        }
                    },
                    inquiry: {
                        name: "Anfragen",
                        header: "Buchungsanfragen",
                        body_placeholder: "Du hast aktuell keine offene Buchungsanfragen.",
                        body: {
                            open_inquiry: "<span>Du hast</span><span class='highlight'>eine offene Buchungsanfrage</span><span>von #.</span>",
                        }
                    },
                    todo_list: {
                        name: "Aufgaben",
                        header: "Meine TODOs heute",
                        footer: {
                            check_all_todos: "JACK alle Aufgaben"
                        }
                    },
                    occupancy_rate: {
                        name: "Belegungsrate",
                        header: "Belegungsrate meiner Inserate",
                        footer: {
                            check_statistics: "JACK deine Statistiken",
                            interval_buttons: [
                                "Heute", "Nächste 7 Tage", "Nächste 30 Tage"
                            ]
                        },
                        occupied_label: "Belegt"
                    },
                    customer_satisfaction: {
                        name: "Zufriedenheit",
                        header: "Zufriedenheit meiner Kunden",
                        adverts_satisfaction: "Durchschnittliche Bewertung meiner Inserate",
                        landlord_satisfaction: "Meine durchschnittliche Bewertung",
                        footer: {
                            check_reviews: "JACK deine Bewertungen"
                        }
                    },
                    income: {
                        name: "Umsatz",
                        header: "Meine Umsätze",
                        footer: {
                            check_statistics: "JACK deine Statistiken"
                        }
                    },
                    click_booking: {
                        name: "Aufrufe-Buchungen",
                        header: "Aufrufe / Buchungen meiner Inserate",
                        footer: {
                            check_statistics: "JACK deine Statistiken"
                        }
                    },
                    search: {
                        name: "Suche",
                        header: "Room suchen",
                        footer: {
                            search: "Los!"
                        }
                    },
                    booker_info: {
                        name: "Buchungsinfo",
                        header: "Infos über aktuelle Mieter:innen",
                        no_data_placeholder: "Du hast aktuell keine Mieter:innen.",
                        your_guest: "Dein:e Mieter:in",
                        contact_button_label: "@ eine Nachricht schreiben",
                        footer: {
                            booking_details: "Zu den Buchungsdetails"
                        }
                    },
                    booking_info: {
                        name: "Buchungsinfo",
                        header: "Infos zur aktuellen Buchung",
                        no_data_placeholder: "Du hast gerade keine laufende Buchung.",
                        housing_access: "Zutritt",
                        your_landlord: "Dein:e Vermieter:in",
                        contact: "Kontakt",
                        booking_span: "Zeitraum",
                        navigate: "Jetzt Navigation starten",
                        footer: {
                            booking_details: "Zu den Buchungsdetails"
                        },
                        wifi_ssid: "WLAN SSID",
                        wifi_code: "WLAN Passwort",
                        code_in_chat: "Code wird per Nachricht übermittelt."
                    },
                    create_advert: {
                        header: "Du hast noch keine Inserate bei roomjack.",
                        description: "Erstelle jetzt dein erstes Inserat!",
                        footer: {
                            create_now: "Jetzt erstellen"
                        }
                    },
                    personalize_dashboard: {
                        body: "Personalisiere dein Jack-Board hier"
                    },
                    tips_tricks: {
                        name: "Tipps & Tricks",
                        header: "Tipps & Tricks"
                    },
                    faq: {
                        name: "FAQs und Hilfe",
                        header: "FAQs und Hilfe"
                    }
                },
                widget_selector_modal: {
                    header: "Widget auswählen",
                    description: "Bitte wähle hier den Widget aus, den du für den gewählten Slot einsetzen möchtest. Die eingefärbten Widgets verwendest du bereits in anderen Slots."
                },
                add_widget_label: "Widget hinzufügen",
                widget_edit_mode: "Du befindest dich im Bearbeitungsmodus. Um Inhalte anzuzeigen speichere bitte deine Einstellung."
            },
            adverts: {
                name: "Inserate",
                submenus: {
                    create_new_advert: "Neues Inserat erstellen",
                    my_adverts: "Meine Inserate",
                },
                advert_creator: {
                    screen_to_small_header: "Es tut uns Leid, aber dein Bildschirmauflösung ist fürs Inserieren zu klein.",
                    screen_to_small_description: "Damit wir ein angenehmes Benutzererlebnis beim Erstellen von Inseraten ermöglichen können, muss dein Browserfenster für diesen Prozess größer sein. Wir bitten um Verständnis.",
                    characteristics_description: "1-5 Merkmale",
                    upload_images: "Bilder hochladen",
                    image_limit_label: "max. # Bilder",
                    main_advert: "Hauptinserat",
                    registration_number: "Reg. No.",
                    alerts: {
                        save_and_publish_successful: "Glückwunsch! Dein Inserat wurde erfolgreich gespeichert und veröffentlicht.",
                        save_successful: "Glückwunsch! Dein Inserat wurde erfolgreich gespeichert.",
                        publish_title: "Inserat Veröffentlichung",
                        upload_in_progress: "Dein Inserat wird gerade gerade hochgeladen. Dieser Prozess kann je nach Anzahl und Größe der Bilder, die du hochladen möchtest und abhängig von deiner Internet-Upload-Geschwindigkeit etwas länger dauern. Bitte schließe nicht dieses Fenster, bis der Prozess beendet wurde.",
                        save_in_progress: "Dein Inserat wird gerade in der Datenbank gespeichert, dies kann noch einen Augenblick dauern. Bitte schließe nicht dieses Fenster, bis der Prozess beendet wurde.",
                        save_title: "Inserat gespeichert",
                        unsaved_changes_title: "Inserierung abbrechen?",
                        unsaved_changes_description: "Du hast Änderungen im Inserierungsprozess, die bisher noch nicht gespeichert wurden. Wenn du jetzt den Inserierungsprozess verlässt, gehen all diese Daten verloren. Möchtest du den Prozess wirklich abbrechen?",
                        save_successful_requirements: "Dein Inserat wurde erfolgreich gespeichert, konnte aber leider noch nicht veröffentlicht werden. Für eine Veröffentlichung müssen noch folgenden Voraussetzungen erfüllt werden:",
                        verification_missing: "Du musst deinen roomjack-Account verifizieren",
                        skip_withdrawal_period: "Ablauf der 14-tägigen Widerrufsfrist oder Verzicht auf Widerrufsrecht",
                        advert_requirements: "Dein Inserat muss vollständig ausgefüllt sein (rot markierte Felder)",
                        data_check_title: "Ergebniss der Datenüberprüfung",
                        data_check_success: "Glückwunsch! Dein Inserat ist vollständig ausgefüllt und kann publiziert werden.",
                        data_check_failure: "Leider fehlen uns noch Daten von deinem Inserat. Bitte prüfe alle Bereiche, die mit einem roten Ausrufezeichen gekennzeichnet sind. Dein Inserat kann erst publiziert werden, sobald alle fehlenden Daten eingetragen wurden. Du kannst jederzeit eine Zwischenspeicherung machen und das Inserieren später wieder weitermachen.",
                        data_check_missing_images: "Du hast für dein Inserat keine Bilder hochgeladen.",
                        data_check_missing_location: "Dein Inserat wird aktuell nicht auf der Karte verortet.",
                        add_image: "Jetzt Bilder hochladen",
                        add_location: "Jetzt Standort angeben"
                    },
                    requirements: {
                        header: "Folgende Daten fehlen noch, damit dein Room vollständig ist und veröffentlicht werden kann:<br><br>",
                        advert_type: "Du hast noch keinen Roomtyp für dein Inserat festgelegt.",
                        address: "Bitte gib die vollständige Adresse deines Inserats an. Folgende Informationen fehlen uns noch dazu:<br>",
                        title: "Du hast für dein Inserat noch kein Titel angegeben.",
                        description: "Du hast für dein Inserat noch keine Beschreibung angegeben.",
                        booking_offset: "Du hast noch nicht bestimmt, wie spontan du Buchungen annehmen kannst.",
                        booking_type: "Du hast noch nicht ausgewählt, ob dein Inserat per Anfrage oder direkt über roomjack gebucht werden kann.",
                        cancellation_conditions: "Du hast eine oder mehrere Stornobedingungen festgelegt, diese aber nicht vollständig ausgefüllt. Bitte immer sowohl die Anzahl der Tage, ab der die Bedingung gilt, als auch die Prozentzahl des Gebührs angeben.",
                        access: "Bitte wähle zumindest eine Zugangsmöglichkeit.",
                        additional_fees: "Du hast eine oder mehrere Zusatzgebühren festgelegt, diese aber nicht vollständig ausgefüllt. Bitte immer den Preis festlegen und bei von dir festgelegten Gebühren auch eine Bezeichnung angeben.",
                        pricing: "# ist als buchbar eingestellt. Bitte vergiss nicht den Mietpreis anzugeben. Falls du Frühbucherrabatt vergeben möchtest, denke daran auch diesen vollständig auszufüllen: die Rabatthöhe (%) und die Anzahl Wochen vor Buchungsbeginn, bis der Rabatt vergeben werden kann.",
                        type_main: "Du möchtest ein WG-Zimmer vermieten, bitte gebe hierfür einen Typ an.",
                        type_sub: "Bitte lege für jedes buchbaren Zimmer den Typ des Zimmers fest.",
                        main_advert: "Das Hauptinserat",
                        bookable: "Bitte stelle dein Inserat so ein, dass entweder es selbst und/oder untergeordnete Räumlichkeiten darin buchbar sind.",
                        equipment: "Bitte stelle dein Inserat so ein, dass für mindestens eine der Räumlichkeiten ein Ausstattungsobjekt ausgewählt ist."
                    },
                    tab_headers: {
                        general: "Allgemein",
                        location: "Standort",
                        advert_type: "Objektart",
                        equipment: "Ausstattung & Merkmale",
                        images: "Bilder",
                        description: "Beschreibung",
                        pricing: "Preis",
                        booking_settings: "Buchungseinstellungen"
                    },
                    buttons: {
                        need_help: "Benötigst du Unterstützung beim Inserieren? Dann besuche unsere <a href='/faq-help?category=landlord' target='_blank' rel='noreferrer nofollow'>FAQ's und Hilfe</a> oder <a href='/tips-tricks?category=landlord' target='_blank' rel='noreferrer nofollow'>Tipps und Tricks</a>.",
                        check_for_completeness: "Auf Vollständigkeit prüfen",
                        save_and_publish: "Speichern und veröffentlichen",
                        abort_advert_process: "Abbrechen und verwerfen",
                        to_overview: "Zu meiner Inseraten",
                        add_room: "Room hinzufügen",
                        fulfill_requirements: "Fehlende Angaben ergänzen"
                    },
                    room_type: {
                        infobox_description_1: "<div>Wenn du Wohnung auswählst, kannst du dein Inserat später in weitere einzelne Zimmer untergliedern und diese als eigenständige Einheiten separat anbieten.</div><br><p style=\"color:var(--tertiary-accent-color)\"><i>Bitte beachte, dass diese Auswahl später nicht mehr verändert werden kann!</i></p>",
                        infobox_description_2: "<div>Du kannst dein Inserat in weitere einzelne Zimmer untergliedern und diese als eigenständige Einheiten separat anbieten.</div>",
                        advert_room_type: "Der Roomtyp deines Inserats ist",
                        box_description: "Welche Art von Unterkunft möchtest du anbieten?"
                    },
                    room_hierarchy: {
                        title: "Raumaufteilung",
                        infobox_description: "Durch Klick auf die entsprechenden Schaltflächen kannst du deine rooms umbenennen und ihnen Attribute wie Wohnfläche oder die Anzahl der Schlafmöglichkeiten hinzufügen. Wenn du die Reihenfolge deiner Zimmer änderst, werden diese gemäß deiner Anordnung im Gantt-Diagramm aufgelistet.",
                        box_description: "In welche rooms ist deine Unterkunft aufgeteilt? Gibt es rooms, die du einzeln anbieten möchtest?",
                    },
                    address: {
                        box_description: "Wie lautet die Adresse des rooms, die du anbieten möchtest?",
                        infobox_description: "<div>Die genaue Adresse deines rooms wird anderen Usern nur bei bestätigten Buchungen angezeigt. Davor wird lediglich ein ungefährer Ort deines rooms angezeigt.</div><br><p><i>Bitte beachte, dass wir unseren Service aktuell nur in Österreich und Deutschland anbieten.</i></p>",
                        google_location_description: "Damit dein room auf der Karte angezeigt werden kann, musst du in dem Eingabefeld \"Straße und Nr.\" beginnen die Straße und Hausnummer der Adresse einzutippen. Sobald unter den Vorschlägen von Google die richtige Adresse erscheint, musst du diese per Mausklick auswählen. Die restlichen Felder werden danach automatisch befüllt und die Koordinaten zum Anzeigen auf der Karte gespeichert. Wenn du das nicht möchtest, tippe die Adresse manuell ein, ohne einen Vorschlag auszuwählen. Manuelle Änderungen an den Eingabefeldern \"PLZ\" oder \"Stadt/Ort\" löschen ebenfalls die gespeicherten Koordinaten.",
                        google_location_stored: "Dein room wird auf der Karte verortet.",
                        google_location_missing: "Dein room wird aktuell nicht auf der Karte verortet.",
                    },
                    title: {
                        infobox_description: "<div>Beschreibe deinen Room in kurzen Worten. So wird dein Room später den Suchenden angezeigt.</div>",
                        box_description: "Welchen Namen soll dein Inserat bekommen?",
                    },
                    description: {
                        infobox_description: "Erzähle etwas über deinen Room! Was macht ihn besonders? Gibt es besondere Sehenswürdigkeiten in der Nähe? Gibt es besondere Sehenswürdigkeiten in deiner Umgebung? Wie ist die Nachbarschaft?",
                        infobox_description_room: "Erzähle etwas über deinen Room! Was macht ihn besonders? Gibt es besondere Sehenswürdigkeiten in der Nähe? Gibt es besondere Sehenswürdigkeiten in deiner Umgebung? Wie ist die Nachbarschaft? Wer sind die anderen WG-Mitglieder?",
                        box_description: "Erzähle etwas über deinen room! Was erwartet deine Gäste?",
                        ai_label: "Nutze JacKI und spare Zeit – generiere im Handumdrehen einen ansprechenden Titel und eine überzeugende Beschreibung für dein Inserat.",
                        description_generator_title: "Titel und Beschreibung mit JacKI",
                        description_generator_description: "Lass dir von JacKI unser KI-Assistenten dabei helfen passende Titel und Beschreibung für deine Unterkunft zu formulieren. Du kannst nach Wunsch im Textfeld unten weitere Details angeben.",
                        description_generator_hint: "Unser Tipp: Je detaillierter du bei den Angaben - besonders im Reiter Merkmale und Objektart bist - ein umso besseres Bild kann sich JacKI über deine Unterkunft machen und entsprechend Beschreibung und Titel generieren.",
                        your_description: "Beschreibe deine Unterkunft detaillierter",
                        ai_generated_title: "JacKI's Vorschlag -Titel",
                        ai_generated_description: "JacKI's Vorschlag - Beschreibung",
                        accept_suggestion: "Vorschlag übernehmen"
                    },
                    characteristics: {
                        title: "Merkmale des Inserats",
                        infobox_description: "<div>Füge deinem Inserat hier Merkmale hinzu, die für deinen gesamten room gültig sind. Sollte es Besonderheiten geben, die wir in unserem Katalog nicht berücksichtigt haben, kannst du diese mit dem Freitextfeld hinzufügen.</div>",
                        box_description: "Merkmale betreffen die gesamte Unterkunft.",
                        add_characteristic: "Merkmal hinzufügen",
                        edit_characteristic: "Merkmale bearbeiten",
                        more_characteristic: "... und ein weiteres Merkmal",
                        more_characteristics: "... und # weitere Merkmale",
                        modal_description: "Wähle hier Merkmale aus, die für deinen gesamten room gültig sind. Nutze das Freitext-Feld, wenn dir etwas in unserem Katalog fehlt.",
                        categories: {
                            all: "Alle Merkmale",
                            building_technology: "Gebäudetechnik",
                            highlights: "Highlights",
                            jack_in_security: "Jack-In & Sicherheit",
                            location_poi: "Lage und POIs",
                            mobility: "Mobilität",
                            streaming_service: "Streaming Dienste"
                        },
                        custom_placeholder: "Sonstiges Merkmal (max. {x}} Zeichen)"
                    },
                    house_rules: {
                        infobox_description: "<div>Bestimme in den Hausregeln, welches Verhalten du von deinen Gästen erwartest. Es gibt einige vordefinierte Hausregeln, wie zum Beispiel <i>Rauchen verboten</i>. Selbstverständlich kannst du aber auch ganz individuelle Hausregeln aufstellen, wie zb. <i>Bitte keine Straßenschuhe im Wohnzimmer verwenden</i>.</div>",
                        predefined_rules: "Vordefinierte Hausregeln",
                        custom_rules: "Individuelle Hausregel",
                        add_house_rule: "Vordefinierte Hausregel hinzufügen",
                        create_house_rule: "Individuelle Hausregel schreiben",
                        edit_house_rules: "Hausregeln bearbeiten",
                        box_description: "Hier kannst du Hausregeln für dein Inserat definieren.",
                        custom_house_rule_placeholder: "Max. 30 Zeichen"
                    },
                    access: {
                        title: "Hinweise beim Jack-in",
                        infobox_description: "<div>Erkläre wie der Jack-in bei dir abläuft. Beschreibe auch in Textform wie man in deinen Room kommt. Um zu verhindern, dass es zu Missverständnissen kommt, erkläre deinen Jack-in relativ genau zb.: der Schlüssel zu deinem room befindet sich in einem Schlüsseltresor. Dieser ist schwarz, beschrieben mit Wohnung 2, und befindet sich rechts neben der grünen Haustüre. Diese Informationen werden nur bestätigten Gästen 24h vor dem Jack-in übermittelt und sind ansonsten nicht öffentlich einsehbar!</div>",
                        access_label: "Wie kommen deine Gäste in deinen Room?",
                        access_types: {
                            personal_handover: "Persönliche Schlüsselübergabe",
                            key_storage: "Schlüssel liegt im Schlüsseltresor",
                            remote_open: "Fernöffnung",
                            electric_door_lock: "Elektronisches Türschloss",
                            miscellaneous: "Sonstiges"
                        },
                        wifi_code: "WLAN-Passwort",
                        wifi_ssid: "WLAN-SSID",
                        key_storage_code: "Code für Schlüsseltresor",
                        misc_access_placeholder: "Beschreibe hier, wie die Gäste in deinen room kommen..."
                    },
                    pro_tool: {
                        title: "roomjack Pro",
                        pro_tool_label: "roomjack Pro für dieses Inserat aktivieren?",
                        pro_tool_b2b_info: "roomjack Pro-Tools sind für deine Inserate automatisch aktiviert, da du B2B-User bist!",
                        description: "Wenn du für dieses Inserat die roomjack Pro aktivierst, kannst du komplette Rechnungen von roomjack für deine Gäste erstellen lassen. Außerdem wirst du bei Support-Anfragen bevorzugt behandelt. Klicke <a href='/faq-help?category=general&topic=roomjack_fees' target='_blank' rel='noreferrer nofollow'>hier</a>, wenn du mehr zu unseren Geschäftsmodellen erfahren möchtest.",
                        auto_reminder: "Automatisches Mahnwesen",
                        auto_reminder_interval: "Wann soll die Mahnung bei einem fehlgeschlagenen Zahlungseinzug verschickt werden?",
                        payment_deadline_interval: "Nächste Zahlungsfrist nach Mahnung",
                        immediately: "sofort",
                        reminder_free_text: "Freier Text in der Mahnung",
                        reminder_preview: "Mahnungsbrief Vorschau"
                    },
                    booking_settings: {
                        title: "Buchbarkeit",
                        infobox_description: "<div>Um eine höhere Auslastung zu erzielen, kannst du deinen Gästen anbieten, dass sie deinen Room direkt buchen können. Wenn du direkt Buchungen nicht erlaubst, bekommst du Buchungsanfragen von Suchenden und musst diese bestätigen, bevor eine Buchung zustande kommt.<br><br>Du kannst auswählen, wie lange im Voraus deine Gäste buchen müssen, damit du den Room vorbereiten kannst. </div>",
                        booking_offset_label: "Wie spontan kannst du Buchungen annehmen?",
                        booking_type_label: "Möchtest du Direktbuchungen erlauben?",
                        jack_in_time_label: "Jack-In Uhrzeit",
                        jack_out_time_label: "Jack-Out Uhrzeit",
                        whole_day_check_in_tooltip: "Du hast in dem Reiter Ausstattungen & Merkmale einen 24-Stunde Jack-In ausgewählt. Um individuelle Jack-In Zeiten festzulegen musst du dieses Merkmal wieder entfernen."
                    },
                    miscellaneous_advert_info: {
                        title: "Sonstige Angaben",
                        reg_no_info: "Manche Städte verlangen, dass Wohnungen für Kurzzeitaufenthalte registriert und mit einer Registrierungsnummer veröffentlicht werden müssen. Solltest du verpflichtet sein eine Registrierungsnummer anzugeben, kannst du sie hier eintragen. Sie wird in der Detailseite deines Inserates angezeigt.",
                        registration_number_label: "Registrierungsnummer des Inserats (optional)",
                        land_reg_no_label: "Grundbuchnummer (optional)",
                        land_reg_no_info: "TODO"
                    },
                    legal_links: {
                        title: "Mietbedingungnen, Widerruf, Datenschutz",
                        legal_links_info: "Du kannst deine eigenen Allgemeinen Mietbedingungen, eine Widerrufsbelehrung sowie eine Datenschutzerklärung hinzufügen. Bei einer Buchung werden diese zusätzlich an deine Kunden übermittelt. Diese AGB und Widerrufsbelehrung bilden die Grundlage für den Vertragsabschluss einer Buchung. (Optional)",
                        terms_of_service: "Deine Allgemeine Mietbedingungen",
                        revocation: "Deine Widerrufsbelehrung",
                        privacy_policy: "Datenschutzerklärung",
                        add_terms: "Allg. Mietbedingungen hinzufügen",
                        add_revocation: "Widerrufsbelehrung hinzufügen",
                        add_privacy_policy: "Datenschutzerklärung hinzufügen"
                    },
                    cancellation_conditions: {
                        title: "Stornierungsbedingungen",
                        infobox_description: "<div>Hier kannst du bis zu 3 Stornierungsbedingungen definieren. Bedenke, dass standardmäßig keine Stornierungsbedingungen definiert sind.</div>",
                        description: "Hier kannst du Stornierungsbedingungen für Buchungen erstellen, dessen Dauer unter 30 Tage liegt. Werden keine Stornierungsbedingungen definiert, gilt für deinen room kostenfreie Stornierung! Buchungen mit einem Dauer von über 29 Tage können grundsätzlich nicht storniert werden. roomjack übernimmt keine Haftung und Gewähr für die (rechtliche) Zulässigkeit und Wirksamkeit der Stornierungsbedingungen.",
                        add_cancellation_condition: "Stornobedingung hinzufügen",
                        day_of_arrival: "Anreisetag",
                        before_arrival: "vor Anreise",
                        at_time: "um",
                        before_start_of_rental: "Uhr fallen",
                        cancellation_costs: "Stornokosten an.",
                    },
                    spatial_properties: {
                        title: "Räumliche Eigenschaften",
                        infobox_description: "<div>Hier kannst du für jede Räumlichkeit die Größe in m² angeben. Für Rooms die vermietbar sind, kannst du darüber hinaus auch angeben, wie viele Personen darin wohnen dürfen. Die Grundeinstellung für jeden vermietbaren Room ist eine Person.</div>",
                        surface_size_label: "Fläche in m²",
                        surface_size: "Fläche",
                        contingent: "Zimmerkontingent",
                        room_name: "Interne Zimmerbezeichnung",
                        room_type: "Zimmertyp",
                        unit_name_description: "Tipp von RoomJack: Wenn du noch keine internen Bezeichnungen für deine Zimmer hast, empfehlen wir dir, zur besseren Übersicht jedem Inserat ein Kürzel zuzuweisen. Zum Beispiel: Zimmer 3 im Stockwerk 2 in Musterstraße 15 könntest du als \"MUS203\" bezeichnen. Wenn du solche Kürzel für deine Zimmer verwendest, werden sie im Gantt-Diagramm und in den Buchungen anstelle des Inserattitels angezeigt, falls sie vermietbar sind.",
                        micro_location: {
                            title: "Lage im Objekt",
                            infobox_description: "Beschreibe wo sich der Room genau befindet, damit sie von deinen Gästen leichter gefunden werden kann.",
                            floor_number_label: "Stockwerk",
                            door_number_label: "Türnummer"
                        },
                    },
                    equipment: {
                        title: "Ausstattung der einzelnen Räume",
                        infobox_description: "<div>Klicke auf die Kacheln deiner rooms und füge deine Wohnungsausstattung den jeweiligen rooms hinzu. So weiß dein:e Mieter:in in welchen Raum deine Ausstattung zu finden ist. Wenn dir etwas fehlt, kannst du die Freitext-Funktion nutzen.</div>",
                        box_description: "Ausstattungen betreffen ein spezielles Zimmer in deiner Unterkunft.",
                        all_equipment: "Alle Ausstattungen",
                        equipment_search_placeholder: "Suche nach Ausdruck...",
                        modal_description: "Wähle hier die Ausstattungen aus, die sich in den jeweiligen rooms befinden. Je genauer du deinen room beschreibst, desto weniger Missverständnisse wird es geben. Nutze das Freitext-Feld, wenn dir etwas in unserem Katalog fehlt.",
                        custom_placeholder: "Weitere Ausstattung (max. {x}} Zeichen)"
                    },
                    images: {
                        title: "Bilder",
                        infobox_description: "<strong>Zeige uns wie dein Room aussieht!</strong><br><br>Klicke auf die Kacheln deiner rooms und füge den jeweiligen rooms Fotos hinzu. Nutze dabei möglichst qualitative Fotos und stelle deinen room so dar, wie ihn dein:e Mieter:in vorfinden wird!",
                        box_description: "Füge deiner gesamten Unterkunft, oder den einzelnen rooms Bilder hinzu!",
                        warning_tooltip: "Bilder sagen mehr als 1000 Worte! Füge deinem Inserat Fotos hinzu, damit sich deine Gäste vorstellen können, wie dein room aussieht. Für viele Suchende, sind Bilder eines der wichtigsten Entscheidungskriterien!",
                    },
                    booking_length: {
                        title: "Mietdauer",
                        box_description: "TODO: Auch infotext, falls gebraucht. Wie lange sollen die Aufenthalte deiner Gäste sein?",
                        infobox_description: "TODO",
                        min_booking_length_label: "Mindestbuchungsdauer (Nächte)?",
                        min_booking_placeholder: "z.B. 7 Nächte",
                        max_booking_length_label: "Maximalbuchungsdauer (Nächte)?",
                        max_booking_placeholder: "z.B. 7 Nächte",
                    },
                    pricing: {
                        title: "Preiseinstellung",
                        infobox_description: "Klicke auf die Kacheln deiner buchbaren rooms und konfiguriere deine Preiseinstellungen! Du kannst hier auch Rabatte und Zusatzgebühren für besondere Services einstellen. Nutze den Beispielrechner, um zu kontrollieren, ob roomjack deine Angaben auch richtig umsetzt!",
                        rent_target_description: "Möchtest du deinen gesamten room als Ganzes oder als WG vermieten die einzelne Zimmer separat vermieten?",
                        rent_advert: "Ganz vermieten",
                        rent_rooms: "Als WG vermieten",
                        long_term_tab: "Langzeit-Vermietung",
                        short_term_tab: "Kurzzeit-Vermietung",
                        base_fee: "Mietpreis",
                        daily_fee: "Tagesmiete",
                        monthly_fee: "Monatliche Miete",
                        discounts: "Rabatte",
                        weekly_discount: "Wochenrabatt",
                        monthly_discount: "Monatsrabatt",
                        deposit: "Kaution",
                        deposit_info: "Bitte beachte, dass die Kautionsverwaltung via roomjack derzeit noch nicht möglich ist. Mit diesem Feld kannst du deine Gäste darüber informieren, dass du eine Kaution erheben möchtest.",
                        early_booker_discount: "Frühbucherbonus",
                        early_discount_week_label: "Ab wann?",
                        early_discount_week_label_info: "Hier kannst du festlegen wie viele Wochen im Voraus bei dir gebucht werden muss, um einen Frühbucherbonus zu erhalten. Dieses Feld ist optional und muss nicht befüllt werden.",
                        early_discount_week_placeholder: "Anzahl der Wochen vor Buchungsbeginn",
                        early_discount_percent_label: "Rabatt",
                        early_discount_missing_field_alert: "Wenn du einen Frühbucherbonus einstellen möchtest, stelle bitte sowohl die Wochenanzahl als auch den Rabatt ein.",
                        actualize_example: "Beispiel aktualisieren",
                        examples: "Beispielrechnungen",
                        example_data_needed: "Um Beispiele berechnen zu können, musst du mindestens den Mietpreis angeben.",
                        example_for_day: "Beispiel für eine Buchungsdauer von 1 Tag",
                        example_for_week: "Beispiel für eine Buchungsdauer von 1 Woche",
                        example_for_month: "Beispiel für eine Buchungsdauer von 1 Monat",
                        minus: "abzüglich",
                        discount: "Rabatt",
                        produces_total: "ergibt insgesamt",
                        sum: "Gesamtbetrag",
                        monthly_sum: "Monatsbetrag",
                        win: "Deine Einkünfte",
                        regulations: "Besondere Regelungen",
                        small_business: "Kleinunternehmerregelung",
                        small_business_info: "Wenn du diese Funktion anhakst, wird auf die von dir an deine Gäste ausgestellten Rechnungen ein Vermerk zur Kleinunternehmerregelung angeführt.",
                        reverse_charge: "Reverse Charge",
                        reverse_charge_info: "Wenn du diese Funktion anhakst, wird auf die von dir an deine Gäste ausgestellten Rechnungen ein Vermerk zur Reverse Charge angeführt.",
                        stripe_fee_info: "Für die Bereitstellung des Services und Support wird eine Gebühr einbehalten. Klicke um mehr zu erfahren.",
                        pro_tool_tax_info: "Aktiviere roomjack-pro, um deiner Berechnung Steuern hinzuzufügen und automatisiert Rechnungen durch roomjack erstellen zu lassen.",
                        box_description: "Definiere die Preiseinstellungen für deinen room.",
                        box_description_rooms: "Definiere die Preiseinstellungen für deine einzelnen Zimmer.",
                        pricing_missing: "Bitte stelle für jedes buchbare Objekt einen Preis ein und achte auf Vollständigkeit bei zusätzlichen Gebühren.",
                        additional_fees: {
                            header: "Zusätzliche Gebühren",
                            description: "Du hast hier die Möglichkeit, verschiedene Gebühren festzulegen, darunter Service- und Reinigungsgebühren. Wie und für was du diese Gebühren ansetzt, liegt ganz bei dir.",
                            price_placeholder: "Wieviel?",
                            name_placeholder: "Bezeichnung",
                            add_custom_fee: "Gebühr hinzufügen",
                            types: {
                                cleaning_fee: "Reinigunsgebühr",
                                service_fee: "Servicegebühr",
                                end_cleaning: "Endreinigung"
                            },
                            custom_fee_name: "Gebühr",
                            custom_cost_name: "Kosten"
                        },
                        running_costs: {
                            header: "Neben- und Betriebskosten",
                            fee_description: "Du hast hier die Möglichkeit, verschiedene Gebühren festzulegen. Gebühren werden einmalig für die Buchung angerechnet. Wie und für was du diese Gebühren ansetzt, liegt ganz bei dir.",
                            cost_description: "Du hast hier die Möglichkeit, verschiedene Neben- und Betriebskosten festzulegen, darunter Wasser-, Heiz- und Stromkosten. Neben- und Betriebskosten werden monatlich pro Abbuchung angerechnet.",
                            add_running_cost: "Betriebskosten hinzufügen",
                            name_placeholder: "Bezeichnung",
                            price_placeholder: "Wieviel?",
                            types: {
                                heating_costs: "Heizkosten",
                                water_costs: "Wasser",
                                electricity_costs: "Strom"
                            }
                        }
                    },
                    room_adder_modal: {
                        title: "Zimmer hinzufügen",
                        description: "Wähle die Zimmer für dein Inserat aus, in dem du auf die entsprechende Kacheln klickst. Es ist möglich mehrere Zimmertypen auf einmal aber auch mehrere Zimmer des gleichen Typs mehrfach auszuwählen, in dem du einfach nochmal auf den Kachel klickst. Möchtest du die Anzahl verringern, klicke bitte mit der rechten Maustaste auf den entsprechenden Kachel. Du kannst insgesamt maximal # Zimmer hinzufügen.",
                        counter: "{x} von {max} Zimmer ausgewählt"
                    },
                    advert_type_missing: "Um diesen Bereich bearbeiten zu können, musst du zuerst oben den Typ deines Inserats auswählen.",
                    advert_type_missing_part_1: "Um diesen Bereich bearbeiten zu können, musst du zuerst unter ",
                    advert_type_missing_part_2: " die Art deines Inserats auswählen.",
                    sort_rooms: "Zimmerreihenfolge ändern",
                    sort_room_description: "Klicke die Zimmer in der von dir gewünschten Reihenfolge nacheinander an und anschließend auf \"Reihenfolge speichern\".",
                    sort_room_info: "Wenn du die Reihenfolge deiner Zimmer änderst, werden diese gemäß deiner Anordnung im Gantt-Diagramm aufgelistet.",
                    sort_rooms_done: "Reihenfolge speichern"
                },
                advert_inspector: {
                    published_adverts: "Veröffentlicht",
                    unpublished_adverts: "Für später gespeichert",
                    no_published_adverts: "Du hast noch keine veröffentlichten Inserate.",
                    no_unpublished_adverts: "Du hast noch keine gespeicherten Inserate.",
                    no_adverts: "Du hast noch keine Inserate bei roomjack.",
                    no_title: "Ohne Titel",
                    boost: "Hervorheben",
                    deactivate: "Deaktivieren",
                    publish: "Veröffentlichen",
                    deactivate_pro: "Pro deaktivieren",
                    activate_pro: "Pro aktivieren",
                    duplicate: "Duplizieren",
                    more_room: "weiteres Zimmer",
                    more_rooms: "weitere Zimmer",
                    published_on: "Veröffentlicht am #",
                    last_edited_on: "Zuletzt bearbeitet am #",
                },
                errors: {
                    missing_address: "Wir benötigen deine Adresse, um Rechnungen an dich ausstellen zu können.",
                    missing_phone: "Wir benötigen deine Telefonnummer, um bei eventuellen Problemen rund um Buchungen mit dir in Kontakt treten zu können.",
                    missing_tax_id: "Wir benötigen aus rechtlichen Gründen deine Steuernummer.",
                    missing_tax_country: "Wir benötigen aus rechtlichen Gründen den Steuernummer vergebenden Staat.",
                    missing_vat_id: "Wir benötigen deine Umsatzsteuer-ID, um Rechnungen an dich ausstellen zu können.",
                    missing_company_reg_no: "Wir benötigen aus rechtlichen Gründen die Firmenbuchnummer deiner Firma.",
                    missing_operating_countries: "Wir benötigen aus rechtlichen Gründen die Angabe in welchen Ländern du Betriebsstätten unterhältst.",
                    missing_birthday: "Wir benötigen aus rechtlichen Gründen dein Geburtsdatum.",
                }
            },
            bookings: {
                name: "Buchungen",
                title: "Meine Buchungen",
                submenus: {
                    gantt: "Gantt-Diagramm",
                    upcoming_bookings: "Bevorstehende Buchungen",
                    active_bookings: "Aktive Buchungen",
                    past_bookings: "Vergangene Buchungen",
                    cancelled_bookings: "Stornierte Buchungen",
                },
                labels: {
                    no_adverts_label: "Du hast keine veröffentlichte Inserate.",
                    to_advert_creator: "Jetzt Inserat erstellen",
                    to_search: "Jetzt room suchen",
                    payment_processing_label: "Zahlung wird noch verarbeitet"
                },
                placeholders: {
                    past: "Keine Buchungen in der Vergangenheit.",
                    active: "Keine laufende Buchungen.",
                    upcoming: "Keine bevorstehende Buchungen.",
                    cancelled: "Keine stornierte Buchungen.",
                    no_bookings_filter: "Es wurden keine Buchungen gefunden, die den aktuellen Filtern entsprechen."
                },
                cancelled_by: "Storniert von",
                blocked_by_user: "Von dir blockiert.",
                blocked_external: "Blockiert durch",
                edit_block_info: "Für bearbeitung klicken.",
                bookings_at_roomjack: "Buchungen über roomjack",
                external_bookings: "Externe Buchungen",
                blocks_at_roomjack: "Buchungsstopps",
                detail_view: "Detailansicht",
                landlord_invoice: "Vermieter-Abrechnung",
                roomjack_fee: "roomjack-Gebühr",
                stripe_fee: "Zahlungsabwicklungsgebühr",
                payout_amount: "Auszahlungsbetrag",
                print_landlord_invoice: "Rechnung für Vermieter:in",
                print_guest_invoice: "Rechnung für Mieter:in",
                print_invoice: "Rechnung ausdrucken",
                change_payment_method: "Zahlungsmethode ändern",
                housing_not_available_title: "Unterkunft nicht verfügbar",
                housing_not_available: "Die Unterkunft kann leider nicht angezeigt werden, da entweder sie oder der Besitzers Account von roomjack gesperrt wurde.",
                controlled_by_third_party: "Dieser room wird durch Channel-Manager verwaltet.",
                control_override: "Dein room wird von einem Channel-Manager gesteuert.<br>Kein manueller Eingriff möglich.",
            },
            current_price: {
                name: "Tagesmiete",
                title: "Tagesmiete",
                description: "Klicke hier auf die einzelnen Tage im Kalender um unterschiedliche Mietpreise für einzelne Tage festzulegen oder Vorlagen für wiederkehrende Perioden festzulegen.",
            },
            rental_agreements: {
                name: "Mietverträge",
                title: "Meine Mietverträge",
                submenus: {
                    upload_agreements: "Mietverträge hochladen",
                    my_templates: "Meine Vertragsvorlagen",
                    completed_agreements: "Abgeschlossene Mietverträge"
                }
            },
            todos: {
                name: "Aufgaben",
                widget_labels: {
                    check_ins: "Jack-Ins",
                    check_outs: "Jack-Outs",
                    key_handovers: "Übergaben",
                    cleanings: "Reinigungen"
                },
                title: "Meine Aufgaben",
                placeholder: "Es ist nichts zu tun.",
                placeholder_interval: "Du hast im gewählten Zeitraum keine unerledigte Aufgaben.",
                filters: {
                    today: "Heute",
                    tomorrow: "Morgen",
                    this_week: "Diese Woche",
                    next_four_weeks: "Kommenden 4 Wochen"
                },
                print_todo_list: "ToDo-Liste ausdrucken",
                table_headers: {
                    booker_name: "Name",
                    housing_id: "Room",
                    date: "Datum",
                    jack_in_time: "Jack-In",
                    jack_out_time: "Jack-Out",
                    key_handover: "Schlüsselübergabe",
                    key_back: "Schlüsselrücknahme",
                    cleaning: "Reinigung",
                    process_status: "Status"
                },
                table_placeholders: {
                    jack_in_placeholder: "Keine Jack-Ins im gewählten Zeitraum",
                    jack_out_placeholder: "Keine Jack-Outs im gewählten Zeitraum",
                    completed: "Erledigt"
                },
                modal_content: {
                    modal_header: "Bau dir deine Reinigungs-Jackliste zusammen",
                    modal_body_description: "Wähle aus den folgenden Bereichen aus:",
                    tile_texts: {
                        information_before_cleaning: "Infos vor der Reinigung",
                        general_hygienic_measures: "Allgemeine Hygienemaßnahmen"
                    },
                    button_text: "Reinigungs-Jackliste erstellen"
                },
                printable: {
                    header: "Deine Aufgaben im Überblick"
                },
                alert_modal_content: {
                    todo_check: "Bist du sicher, dass du diese Aufgabe als erledigt markieren möchtest?",
                    todo_uncheck: "Bist du sicher, dass du diese Aufgabe wieder als nicht erledigt markieren möchtest?"
                }
            },
            messages: {
                name: "Nachrichten",
                title: "Meine Nachrichten",
                message_body: {
                    housing: "Room"
                },
                message_footers: {
                    incoming_booking_invitation: "hat dir eine Buchungseinladung gesendet.",
                    outgoing_booking_invitation: "hast eine Buchungseinladung gesendet.",
                    booking_completed: "Die Buchung wurde erfolgreich abgeschlossen.",
                    booking_cancelled: "Die Buchung wurde storniert",
                    booking_rejected: "Die Buchungseinladung wurde abgelehnt",
                    booking_expired: "Die Reservierung des rooms ist abgelaufen.",
                    waiting_for_complete: "Die Buchung steht aus.",
                    remaining_until_expiration: "Der room bleibt reserviert für ",
                    incoming_inquiry: "hat dir eine Buchungsanfrage gesendet.",
                    outgoing_inquiry: "hast eine Buchungsanfrage gesendet.",
                    inquiry_accepted: "Die Anfrage wurde akzeptiert.",
                    inquiry_declined: "Die Anfrage wurde abgelehnt.",
                    inquiry_expired: "Die Anfrage ist abgelaufen.",
                    waiting_for_inquiry_edit: "Die Bearbeitung steht aus.",
                    message_contains_invoice: "Diese Nachricht enthält eine Rechnung.",
                    download_invoice: "Rechnung herunterladen",
                    open_invoice: "Rechnung öffnen"
                },
                inquiry_advert_overview: {
                    edit_inquiry: "Anfrage bearbeiten",
                    edit_invitation: "Einladung beantworten",
                    decline_booking: "Buchung ablehnen",
                    about_landlord: "Über deine:n Vermieter:in",
                    about_guest: "Über deine:n Mieter:in",
                    booking_code: "Buchungscode",
                    pro_advantage: "Mit roomjack Pro hast du die Möglichkeit diese Anfrage komplett über unser Platform abzuwickeln.",
                    activate_pro: "Jetzt Pro aktivieren",
                },
                placeholder: {
                    type_message: "Nachricht eingeben",
                    no_conversations: "Du hast noch keine Unterhaltungen.",
                    select_conversation: "Wähle links eine Unterhaltung aus um mit dem Verfassen einer Nachricht beginnen zu können.",
                },
                buttons: {
                    send_image: "Bild senden",
                    pin_conversation: "Unterhaltung anpinnen",
                    unpin_conversation: "Unterhaltung lösen",
                    delete_conversation: "Unterhaltung löschen",
                    report_user: "Benutzer melden",
                    show_booking_info: "Buchungsdetails anzeigen",
                    view_room: "zum room",
                    to_booking: "Zur Buchung"
                },
                labels: {
                    you: "Du",
                    today: "Heute",
                    user_deleted: "Dieser Account wurde gelöscht. Du kannst keine Nachrichten mehr schicken.",
                    user_blocked: "Dieser Account wurde blockiert. Du kannst keine Nachrichten mehr schicken.",
                    user_paused: "Dieser Account wurde pausiert. Du kannst aktuell keine Nachrichten schicken.",
                },
                conversations: "Unterhaltungen",
                emoji_browser: {
                    search: "Suche...",
                    search_results: "Suchergebnisse",
                    recently_used: "Zuletzt verwendet",
                    people: "Emojis & Personen",
                    nature: "Natur",
                    food: "Essen & Trinken",
                    activities: "Aktivitäten",
                    travel: "Reisen & Orte",
                    objects: "Objekte",
                    symbols: "Zeichen",
                    flags: "Spaß mit Flaggen"
                },
                deleted_user: "Gelöschter User",
                attachments: {
                    image_file: "Bild-Datei",
                    booking_request: "Buchungsanfrage",
                    booking_invitation: "Buchungseinladung",
                    invoice_data: "Rechnung"
                },
                file_size_error: "Die Dateigröße darf nicht 5MB überschreiten.",
            },
            finances: {
                name: "Finanzen",
                title: "Meine Finanzen",
                submenus: {
                    payout_history: {
                        title: "Vergangene Auszahlungen",
                        error_alert_title: "Abfrage vergangenen Auszahlungen",
                        error_alert_description: "Hoppla! Bei der Abfrage der Auszahlungsverlauf ist leider etwas schiefgelaufen.<br>Bitte kontaktiere unseren #.",
                        placeholder: "Du hast noch keine vergangene Auszahlungen.",
                        placeholder_interval: "Im gewählten Zeitraum haben keine Auszahlungen auf dein Konto stattgefunden."
                    },
                    payout_settings: {
                        title: "Auszahlungen",
                        error_alert_title: "Auszahlungenseinstellungen",
                        error_alert_description: "Hoppla! Bei der Abfrage der Auszahlungseinstellungen ist leider etwas schiefgelaufen.<br>Bitte kontaktiere unseren #.",
                        intervals: {
                            daily: "Tag",
                            weekly: "Woche",
                            monthly: "Monat"
                        },
                        payout_every: "Auszahlung jede(n)",
                        payout_description: "Hier kannst du einstellen in welchen Intervallen deine Einkünfte von deinem Stripe-Konto an dein angegebenes Referenzkonto ausbezahlt werden.",
                        payout_on: "am",
                        weekly_anchor: {
                            monday: "Montag",
                            tuesday: "Dienstag",
                            wednesday: "Mittwoch",
                            thursday: "Donnerstag",
                            friday: "Freitag"
                        },
                        save_settings: "Auszahlungsplan speichern",
                        save_successful: "Auszahlungsplan erfolgreich gespeichert.",
                        payout_info: "Hier kannst du einstellen in welchen Intervallen deine Einkünfte von deinem Stripe-Konto an dein angegebenes Referenzkonto ausbezahlt werden."
                    },
                    all_invoices: {
                        title: "Alle Rechnungen",
                        invoice_types: {
                            booster: "Boosterkäufe",
                            application_fee: "Buchungsgebühren"
                        },
                        error_alert_title: "Alle Rechnungen",
                        error_alert_description: "Hoppla! Bei der Abfrage deiner Rechnungen ist leider etwas schiefgelaufen.<br>Bitte kontaktiere unseren #.",
                        no_filter_match_placeholder: "Für den ausgewählten Zeitraum und/oder Inserate gibt es noch keine Rechnungen.",
                        no_data_placeholder: "Du hast noch keine Rechnungen bei roomjack.",
                        booster_purchase: "Boosterkauf",
                        application_fee_for_booking: "Gebühren für Buchung",
                        purchase_date: "Kaufdatum",
                        purchase_amount: "Kaufbetrag",
                        booster_duration: "Boosterdauer",
                        unlimited: "Unbegrenzt",
                        booster_expiry_date: "Ablaufdatum",
                        invoice: "Rechnung",
                        booking_receipt: "Beleg",
                        booking_sum: "Buchungssumme",
                        application_fee: "Plattformgebühr",
                        stripe_fee: "Gebühr für Zahlungsdienst",
                        payout_date: "Ausbezahlt am",
                        payout: "Ausgezahlte Summe",
                        guest_invoice: "Mietrechnung",
                        fees_detailed: "Gebühren im Detail",
                        booking_sum_extended: "Buchungssumme - von Mieter:in bezahlt",
                        csv_export: "CSV-Export",
                        requesting: "Daten werden abgefragt. Dieser Prozess kann einige Minuten dauern.",
                        overview: "Übersicht",
                    },
                    unpaid_rents: {
                        title: "Offene Mieten",
                        placeholder: "Es sind keine Mieten ausständig, gönn dir eine Auszeit!",
                        placeholder_interval: "Es gibt keine offene Mieten im gewählten Zeitraum.",
                        error_alert_title: "Abfrage offene Mieten",
                        error_alert_description: "Hoppla! Bei der Abfrage der offenen Mieten ist leider etwas schiefgelaufen.<br>Bitte kontaktiere unseren #.",
                        container_header: "Unbezahlte Miete - #",
                        due: "Fällig am"
                    },
                    transaction_states: {
                        paid: "Auszahlung erfolgreich",
                        pending: "Auszahlung steht bevor",
                        canceled: "Auszahlung abgebrochen",
                        failed: "Auszahlung fehlgeschlagen"
                    },
                    payout_created_on: "Auszahlung erstellt",
                    payout_arrived_on: "Auszahlung erfolgt",
                    payout_failure_msg: "Fehlermeldung",
                    bank_name: "Name der Bank",
                    account_owner_name: "Name des Kontoinhabers",
                    last4: "Bankkonto",
                    payout_sum: "Auszahlungssumme"
                }
            },
            stats: {
                name: "Statistiken",
                title: "Meine Statistiken",
                submenus: {
                    general_statistics: {
                        title: "Allgemeine Statistik",
                        completed_bookings_header: "Abgeschlossene Buchungen",
                        income_header: "Umsätze gesamt",
                        customer_satisfaction_header: "Kundenzufriedenheit"
                    },
                    occupancy_rate: {
                        title: "Belegungsrate",
                        occupied_label: "Belegt"
                    },
                    cancellation_rate: {
                        title: "Stornorate",
                        cancelled: "storniert"
                    },
                    click_booking_ratio: {
                        title: "Aufrufe / Buchungen",
                        click_header: "Aufrufe"
                    },
                    income: {
                        title: "Gesamt-einnahmen",
                        legend_entries: {
                            base_fee: "Mieteinnahmen",
                            misc_fee: "Sonstige Gebühren",
                            misc_costs: "Sonstige Betriebskosten"
                        },
                        details: {
                            title: "Detaillierte Einnahmen",
                            row_titles: {
                                base_fee: "Tagesmiete",
                                service_fee: "Servicegebühren",
                                cleaning_fee: "Reinigungsgebühren",
                                misc_fee: "Sonstige Gebühren",
                                taxes: "Steuern",
                                sum: "Gesamteinnahmen",
                                rents: "Monatsmiete",
                                heating_costs: "Heizkosten",
                                water_costs: "Wasser",
                                electricity_costs: "Strom",
                                end_cleaning: "Endreinigung",
                                misc_costs: "Sonstige Betriebskosten",
                                cancellation_fee: "Stornogebühren"
                            }
                        },
                        no_short_term_bookings: "Du hast noch keine Kennzahlen aus kurzfristiger Vermietung bei roomjack",
                        no_short_term_data: "Du hast im ausgewählten Zeitraum keine Einnahmen<br>aus Kurzzeit-Vermietungen bei roomjack",
                        no_long_term_bookings: "Du hast noch keine Kennzahlen aus langfristiger Vermietung bei roomjack",
                        no_long_term_data: "Du hast im ausgewählten Zeitraum keine Einnahmen<br>aus Langzzeit-Vermietungen bei roomjack",
                        booking_length: "Buchungsdauer"
                    },
                    length_of_stay: {
                        title: "Aufenthalts-dauer"
                    }
                },
                diagram_labels: {
                    occupancy_percent: "Belegung deiner Inserate in %",
                    no_of_clicks: "Anzahl der Aufrufe"
                },
                booking_length_dropdown: {
                    all: "Alle Buchungen",
                    long: "Langzeit-Vermietung",
                    short: "Kurzzeit-Vermietung"
                },
                overview_labels: {
                    average_occupancy: "Durchschnittliche Belegung",
                    average_stay_length: "Durchschnittliche Aufenthaltsdauer",
                    max_stay_length: "Längste Aufenthaltsdauer",
                    min_stay_length: "Kleinste Aufenthaltsdauer",
                    rental_fee_incomes: "Einkünfte aus Mietgebühren",
                    service_fee_incomes: "Einkünfte aus Servicegebühren",
                    extra_service_fee_incomes: "Einkünfte aus Zusatzdienstleistungen",
                    cleaning_fee_incomes: "Einkünfte aus Reinigungsgebühren",
                    parking_fee_incomes: "Einkünfte aus Parkgebühren",
                    average_daily_clicks: "Aufrufe täglich (Durchschnitt)",
                    max_clicks_a_day: "Max. Aufrufe an einem Tag",
                }

            },
            reviews: {
                name: "Bewertungen",
                user_reviews: "Meine Bewertungen",
                advert_reviews: "Bewertungen meiner Inserate",
                no_advert_reviews: "Du hast noch keine Inseratsbewertungen.",
                no_user_reviews: "Du hast noch keine Bewertungen.",
                report_review: "Melden"
            },
            favorites: {
                name: "Merkliste",
                title: "Meine Merkliste",
                no_favorites: "Du hast keine rooms auf deiner Merkliste.",
            },
        },
        user_profile: {
            title: "#'s page",
            subscribed_since: "Bei roomjack seit #",
            personal_data_labels: {
                company: "Firmenname",
                company_reg_no: "Firmenbuchnummer",
                birthday: "Geburtsdatum",
                living: "Wohnort",
                speaking: "Sprachen",
                rating: "Bewertungen",
                responding: "Schnelligkeit",
                about_me: "Über mich",
                about_us: "Über uns",
                responding_one_hour: "antwortet in der Regel innerhalb einer Stunde",
                responding_two_hours: "antwortet in der Regel innerhalb zwei Stunden",
                responding_three_hours: "antwortet in der Regel innerhalb drei Stunden",
                responding_twelve_hours: "antwortet in der Regel innerhalb einem halben Tag",
                responding_one_day: "antwortet in der Regel innerhalb einem Tag",
                responding_days: "antworten dauern in der Regel länger als einen Tag",
                tax_country: "Steuernummer Land",
                operating_countries: "Länder mit Betriebstätten"
            },
            advert_header: "#'s Inserate",
            rating_header: "#'s Bewertung",
            review_detail_header: "Bewertungsdetails",
            send_message_header: "# eine Nachricht senden",
            superjacker_header: "Superjacker"
        },
        advert_attributes: {
            type_of_housing: "Art des rooms",
            room_type: "Roomtyp",
            accessibility: "Zugänglichkeit",
            title: "Titel",
            description: "Beschreibung",
            house_rules: {
                name: "Hausregeln",
                list: {
                    no_smoking: "Rauchen verboten",
                    no_instruments: "Musizieren nicht erlaubt",
                    no_pets: "Haustiere verboten",
                    night_rest_period: "Nachtruhe"
                }
            },
            characteristics: {
                contactless_jack_in: "Kontaktloser Jack-In",
                central_location: "Zentrale Lage",
                good_public_transport: "Gute Anbindung an das Öffentliche Verkehrsnetz",
                good_shopping: "Gute Shoppinglage",
                green_electricity: "Ökostrom",
                green_heating: "Umweltschonendes Heizungsystem",
                highspeed_internet: "Highspeed Internet",
                internet: "Internet",
                near_to_university: "Nähe zur Uni",
                quiet_location: "Ruhige Lage",
                restaurants: "Restaurants in der Nähe",
                bars: "Bars in der Nähe",
                whole_day_check_in: "0-24h Jack-In",
                suitable_for_allergy_sufferers: "Allergiker geeignet",
                alarm_system: "Alarmanlage",
                amazon_prime: "Amazon Prime",
                elevator: "Aufzug",
                balcony: "Balkon",
                barrier_free: "Barrierefrei",
                dazn: "DAZN",
                disney: "Disney+",
                charging_station: "E-Ladesäule",
                bike_parking: "Fahrradstellplatz",
                fire_extinguisher: "Feuerlöscher",
                fitness_devices: "Fitnessgeräte",
                garden: "Garten",
                garden_cottage: "Gartenhaus",
                free_parking: "Gratis Parkmöglichkeit",
                blinds: "Jalousien",
                air_conditioning: "Klimaanlage",
                parking: "Kostenpflichtige Parkmöglichkeit",
                netflix: "Netflix",
                party_basement: "Partykeller",
                pool: "Pool",
                raffstore: "Raffstore",
                smoke_detector: "Rauchmelder",
                sauna: "Sauna",
                sky_x: "Sky X",
                terrace: "Terasse",
                room_ventilation: "Wohnraumlüftung",
                climate_friendly_construction: "Klimaschonende Bauweise",
                restaurant: "Restaurant vor Ort",
                bar: "Bar vor Ort",
                breakfast: "Frühstücksbüffet",
                washroom: "Waschraum"
            },
            advert_types: {
                apartment: "Apartment",
                flat: "Wohnung",
                room: "WG-Zimmer",
                house: "Haus",
                penthouse: "Penthouse",
                loft: "Loft",
                souterrain: "Souterrain",
                garconniere: "Garconniere",
                duplex: "Maisonette"
            },
            room_types: {
                kitchen: "Küche",
                bathroom: "Badezimmer",
                toilet: "Toilette",
                bedroom: "Schlafzimmer",
                living_room: "Wohnzimmer",
                childrens_room: "Kinderzimmer",
                working_room: "Arbeitszimmer",
                storeroom: "Abstellraum",
                closet: "Schrankraum",
                corridor: "Flur",
                other: "Sonstiges Zimmer"
            },
            equipment: {
                oven: "Backofen", cutlery: "Besteck", bluetooth_speaker: "Bluetooth-Lautsprecher",
                ironing_board: "Bügelbrett", iron: "Bügeleisen", steamer: "Dampfgarer",
                tv: "Fernseher", fly_screen: "Fliegengitter", freezer: "Gefrierschrank",
                tableware: "Geschirr", stove_top: "Herdplatte", induction_hob: "Induktionskochfeld",
                coffee_machine: "Kaffeemaschine", fridge: "Kühlschrank", microwave: "Mikrowelle",
                pin_board: "Pinnwand", shelf: "Regal", bench: "Sitzbank", sink: "Waschbecken",
                dishwasher: "Spülmaschine", vacuum_cleaner: "Staubsauger", chairs: "Stühle",
                toaster: "Toaster", pots_and_pans: "Töpfe und Pfannen", dryer: "Wäschetrockner",
                water_heater: "Wasserkocher", whiteboard: "Whiteboard", first_aid_kit: "Erste-Hilfe-Paket",
                lockable_door: "Abschließbare Tür", bathtub: "Badewanne", shower: "Dusche",
                hairdryer: "Föhn", clothes_rack: "Kleiderständer", chest_of_drawers: "Kommode",
                mirror: "Spiegel", mirror_cabinet: "Spiegelschrank", toilet: "Toilette ",
                curtains: "Vorhänge", clothes_drying_rack: "Wäscheständer", beamer: "Beamer",
                bed_90: "Bett 90x200", bed_140: "Bett 140x200", office_chair: "Bürostuhl",
                couch: "Couch", armchair: "Couchsessel", couch_table: "Couchtisch",
                double_bed: "Doppelbett", multifunction_printer: "Multifunktionsdrucker", bunk_bed: "Etagenbett",
                wardrobe: "Kleiderschrank", clothes_rail: "Kleiderstange", childrens_travel_bed: "Reisebett für Kinder",
                roll_container: "Rollcontainer", couch_bed: "Schlafcouch", writing_desk: "Schreibtisch",
                desk_lamp: "Schreibtischlampe", floor_lamp: "Stehlampe", tv_connection: "TV-Anschluss",
                dining_table: "Esstisch", fireplace: "Kamin", crib_bed: "Gitterbett",
                baby_changing_unit: "Wickelkommode", kitchen_sink: "Spülbecken", washing_machine: "Waschmaschine",
                ventilation: "Lüftung", trash_can: "Mülleimer", basic_cook_utils: "Koch-Grundausstattung",
                bbq: "Grill vorhanden", basic_equipment: "Grundausstattung (Handtuch, Bettwäsche, Seife)",
                cleaning_utensils: "Reinigungsutensilien", coat_stand: "Garderobe", closet: "Schrank",
                nightstand: "Nachtkästchen", carpet: "Teppich", fan: "Lüfter", safe: "Safe"},
            registration_number_short: "Reg. No."
        },
        error_codes: {
            server_connection_failed: "Es konnte keine Verbidung zum Server hergestellt werden. Bitte später erneut versuchen.",
            ip_ban_err: "Zu viele fehlgeschlagene Loginversuche. Aus Sicherheitsgründen wird diese IP-Adresse für # Minuten blockiert.",
            wrong_pw_err: "Falsches Passwort.",
            email_unknown_err: "Es existiert kein Account mit dieser Email-Adresse.",
            old_password_wrong_err: "Dein altes Passwort ist falsch.",
            recovery_err_0: "E-Mail Adresse muss angegeben werden!",
            recovery_err_1: "E-Mail Adresse unbekannt!",
            recovery_err_2: "Bestätigungscode falsch!",
            account_deleted: "Du hast dein Account gelöscht. Eine Anmeldung ist nicht mehr möglich. Bei weiteren Fragen kontaktiere bitte unseren <button class='link-button' onclick='callReportModal()'>Support</button>.",
            account_paused: "Schön, dass du wieder da bist. Wir haben dir zum Reaktivieren deines Accounts eine Email mit einem Bestätigungscode zukommen lassen. Um deinen Account vollständig zu reaktivieren, trage diesen bitte hier unterhalb ein:",
            account_blocked: "Dein Account wurde gesperrt, eine Anmeldung ist aktuell nicht möglich. Bitte kontaktiere unseren <button class='link-button' onclick='callReportModal()'>Support</button>.",
            session_expired_err: "Session abgelaufen. Bitte lade die Seite neu.",
            permission_err: "Für diese Operation musst du eingeloggt sein.",
            email_check_err_0: "Bitte gebe eine gültige und funktionierende Email-Adresse an und achte darauf, dass dein Postfach Emails empfangen kann.",
            email_check_err_1: "Mit dieser Email-Adresse existiert bereits ein Konto bei roomjack.",
            ad_availability_err_0: "Fehlendes room-ID. Bitte #link# kontaktieren.",
            ad_availability_err_1: "Bitte wähle je einen Termin für Buchungsstart und -ende.",
            ad_availability_err_2: "Ungültiges Wohnungs-ID. Bitte #link# kontaktieren.",
            ad_availability_err_3: "Ungültiges Zimmer-ID. Bitte #link# kontaktieren.",
            ad_availability_err_4: "Ungültiges Buchungsintervall: Buchungslänge muss mind. 1 Tag betragen und Buchungsstart vor -ende liegen.",
            ad_availability_err_5: "Der room konnte in der Datenbank nicht gefunden werden. Bitte #link# kontaktieren.",
            ad_availability_err_6: "Dieser room kann im Augenblick nicht gebucht werden.",
            ad_availability_err_7: "Dieser room kann frühestens ab # gebucht werden.",
            ad_availability_err_8: "Der room beinhaltet nicht mehr die gesuchte Wohnung, möglicherweise wurde sie inzwischen deaktiviert oder gelöscht. Bitte #link# kontaktieren, damit wir uns das Problem näher anschauen können.",
            ad_availability_err_9: "Der room beinhaltet nicht mehr die Wohnung, oder das darin gesuchte Zimmer. Möglicherweise wurde das Zielobjekt inzwischen deaktiviert oder gelöscht. Bitte #link# kontaktieren, damit wir uns das Problem näher anschauen können.",
            ad_availability_err_10: "Der room beinhaltet nicht mehr das gesuchte Zimmer, möglicherweise wurde es inzwischen deaktiviert oder gelöscht. Bitte #link# kontaktieren, damit wir uns das Problem näher anschauen können.",
            ad_availability_err_11: "Der room ist im angefragten Zeitraum nicht verfügbar.",
            ad_availability_err_13: "Der room kann für x-y Nächte gebucht werden.",
            ad_availability_err_14: "Der room kann nur für volle Monate gebucht werden.",
            ad_availability_err_15: "Ungültiger Zeitraum.",
            ad_availability_err_16: "Der room kann für folgenden Zeiträume gebucht werden:",
            check_out_room_not_available_err: "Hoppla! Der room steht leider in dem von dir gewählten Zeitraum nicht mehr zur Verfügung.",
            advert_not_available: "Der room kann im gewählten Zeitraum nicht gebucht werden.",
            booking_err_0: "Der Mindestbuchungsdauer für diesen room beträgt 180 Tage.",
            booking_err_1: "Du kannst nicht deinen eigen room buchen.",
            payment_intent_err_1: "Bei der Erstellung des Bezahlvorgangs ist leider etwas schiefgelaufen. Bitte versuche es erneut oder kontaktiere unseren #link#.",
            booking_invoice_err_0: "Fehlendes Buchungs-ID. Bitte #link# kontaktieren.",
            booking_invoice_err_1: "Bitte eine Rechnungsnummer angeben.",
            booking_invoice_err_2: "Die Buchung wurde in der Datenbank nicht gefunden.",
            booking_invoice_err_3: "Die:der Mieter:in wurde in der Datenbank nicht gefunden.",
            user_not_found: "Der gesuchte Benutzer wurde nicht gefunden.",
            account_deleted_err: "Der gesuchte Benutzer hat sein Account gelöscht.",
            account_blocked_err: "Der gesuchte Benutzer wurde blockiert.",
            account_paused_err: "Der gesuchte Benutzer hat sein Account pausiert.",
            vat_id_not_deletable_err: "Du bist als gewerbliche Kunde bei uns registriert (B2B). Aus diesem Grund ist das Löschen deiner UID nicht möglich solange du über veröffentlichte Inserate verfügst.",
            vat_id_invalid_err: "Die von dir angegebene Umsatzsteuer-ID ist ungültig. Wir unterstützen aktuell nur Unternehmen aus Deutschland (Präfix: DE) und Österreich (Präfix: AT). Bitte prüfe nochmal deine Eingaben.",
            tax_id_not_deletable_err: "Solange du über veröffentlichte Inserate verfügst, ist das Löschen deiner Steuer-ID-Nummer aus rechtlichen Gründen nicht möglich.",
            tax_id_invalid_err: "Die von dir angegebene Steuer-ID-Nummer ist ungültig.",
            tax_country_not_deletable_err: "Solange du über veröffentlichte Inserate verfügst, ist das Löschen dieser Eingabefeld nicht möglich.",
            tax_country_invalid_err: "Bitte gebe einen gültigen Ländercode an.",
            company_reg_no_not_deletable_err: "Solange du über veröffentlichte Inserate verfügst, ist das Löschen der Firmenbuchnummer nicht möglich.",
            company_reg_no_invalid_err: "Bitte gebe eine gültige Firmenbuchnummer an.",
            birthday_id_not_deletable_err: "Solange du über veröffentlichte Inserate verfügst, ist das Löschen deines Geburtsdatums nicht möglich.",
            operating_countries_not_deletable_err: "Solange du über veröffentlichte Inserate verfügst, musst du mindestens ein Land auswählen, wo du Betriebstätte hast.",
            operating_countries_invalid_err: "Bitte gebe gültige Ländercodes an.",
            key_not_found_err: "Leider konnte kein API-Schlüssel mit dem von dir angegebenen öffentlichen Schlüssel gefunden werden. Bitte überprüfe deine Angaben.",
            public_key_error: "Bitte gebe einen gültigen öffentlichen Schlüssel der Form \"rj-ak-live_**********\" an.",
            acct_status_change_err_2: "Die von dir gewünschte Aktion ist leider nicht möglich, da du noch mindestens eine offene Buchung hast. Für weitere Informationen zur Löschung des Accounts besuche unsere #link#.",
            discount_errors: {
                discount_not_found: "Rabattcode unbekannt.",
                invalid_discount: "Dieser Rabattcode ist abgelaufen.",
                permission_err: "Dieser Rabattcode kann nicht mit deinem Account eingelöst werden."
            }
        },
        components: {
            image_uploader: {
                avatar_selector_info: "Profilbild per Drag&Drop einfügen oder Click um aus den eigenen Dateien auszuwählen.",
            },
            image_upload_modal: {
                label_no_images: "Keine Bilder",
                dropzone_placeholder: "Foto hinzufügen",
                dropzone_info: "Hier klicken oder per Drag&Drop ein Bild hinzufügen.",
                modal_file_count_info: "max. # Bilder. Erlaubte Dateiformate JPG, PNG, BMP. Max. Dateigröße 5MB",
                file_size_error: "Pro Bild sind maximal 5MB erlaubt.",
                sort_images: "Reihenfolge ändern",
                sort_images_description: "Klicke die Bilder in der gewünschten Reihenfolge an.",
                actual_image_count: "{x} von {y} Bilder ausgewählt",
                cover_image: "Titelbild"
            },
            reviews: {
                buttons: {
                    send_review: "Bewertung absenden",
                    cancel_review: "Nicht jetzt!",
                    transmit_review: "Übermittle Bewertung",
                    show_more: "mehr anzeigen",
                    show_less: "weniger anzeigen"
                },
                roomjack_review: {
                    header: "Wie gefällt dir roomjack?",
                    description_1: "Die Zufriedenheit unserer User hat für uns stets oberste Priorität. Damit wir unseren Service ständig weiter verbessern können, würden wir uns über Feedback von dir freuen! Bitte nimm dir einen Moment Zeit und sag uns was du von roomjack hältst.",
                    good_review_prefix: "Schön, dass es dir hier gefällt!",
                    bad_review_prefix: "Das ist schade!",
                    description_2: "Was können wir besser machen?",
                    rating: {
                        general_rating: "Gesamteindruck",
                        payment_method_rating: "Zahlungsmöglichkeiten",
                        usability_rating: "Benutzerfreundlichkeit",
                        ux_rating: "Benutzererlebnis",
                        support_rating: "Support",
                    },
                    everything_fine: "Alles bestens!",
                    review_text_label: "Beschreibe deine Erfahrung mit unserer Seite",
                    thanking: "Vielen Dank für deine Zeit! Damit hast du uns sehr viel geholfen roomjack in der Zukunft noch besser zu machen."
                },
                user_review: {
                    header_landlord: "Bewerte deinen Mieter:in",
                    header_booker: "Bewerte deinen Vermieter:in",
                    description_landlord: "Wie zufrieden warst du mit @ als Gast?",
                    description_booker: "Wie zufrieden warst du mit @ als Vermieter:in?",
                    ratings: {
                        general_rating: "Gesamteindruck",
                        communication_rating: "Kommunikation",
                        house_rules_followed_rating: "Hausregeln eingehalten",
                        reliability_rating: "Verlässlichkeit"
                    },
                    no_review_available: "# hat noch keine Bewertungen erhalten."
                },
                advert_review: {
                    header: "Bewerte den room",
                    description: "Wie hat es dir im room von @ gefallen?",
                    ratings: {
                        location_rating: "Lage",
                        equipment_rating: "Ausstattung",
                        cleanliness_rating: "Sauberkeit & Ordnung",
                        price_performance_rating: "Mietpreis-Leistung",
                        info_accuracy_rating: "Angaben-Genauigkeit",
                        check_in_rating: "Jack-In/-Out",
                        wifi_rating: "WIFI"
                    },
                    no_review_available: "Dieser room wurde noch nicht bewertet."
                },
                fair_review: "Wir bitten dich, bei der Bewertung fair und sachlich zu bleiben.",
                thanking: "Vielen Dank für dein Feedback!",
                failure: "Hoppla! Da hat leider etwas nicht geklappt :( Bitte versuche es später erneut."
            },
            message_modal: {
                alerts: {
                    user_deleted: "Es tut uns Leid, aber du kannst #username# keine Nachricht mehr senden, weil sie*er den Account gelöscht hat.",
                    user_paused: "Es tut uns Leid, aber du kannst #username# aktuell keine Nachrichten senden, weil sie*er den Account pausiert hat.",
                    user_blocked: "Es tut uns Leid, aber du kannst #username# keine Nachrichten mehr senden, weil ihr*sein Account gesperrt wurde.",
                }
            },
            interval_filter: {
                last_month: "Letzter Monat",
                last_quarter: "Letztes Quartal",
                last_half_year: "Letztes Halbjahr",
                last_year: "Letztes Jahr",
                future_month: "Nächster Monat",
                future_quarter: "Nächstes Quartal",
                future_half_year: "Nächstes Halbjahr",
                future_year: "Nächstes Jahr",
                all_bookings: "Alle Buchungen",
                all_payouts: "Alle Auszahlungen",
                all_invoices: "Alle Rechnungen",
                all_unpaid: "Alle unbezahlte Mieten",
                all_reviews: "Alle Bewertungen",
                all: "Von Beginn",
            },
            invoice_table: {
                headers: {
                    net: "Netto",
                    gross: "Brutto",
                    tax: "zzgl. Steuer"
                },
                column_names: {
                    daily_rental_fee: "Tagesmiete",
                    monthly_rental_fee: "Monatsmiete",
                    granted_discount: "Rabatt"
                },
                at: "à"
            },
            booking_detail_table: {
                rent_in_detail: "Miete im Detail",
                booking_costs_in_detail: "Buchungsgebühr im Detail",
                cancellation_date: "Stornierungsdatum",
                transaction_history: "Transaktionsverlauf",
                refund_to_me: "An mich zurückerstattet",
                refund_to_guest: "An Mieter:in zurückerstattet",
                total_refund: "Erstattungssumme",
                nth_charge: "#. Abbuchung",
                transaction_table_header: {
                    transaction_type: "Art des Vorgangs",
                    transaction_due: "Datum",
                    transaction_status: "Status",
                    transaction_sum: "Summe"
                },
                transaction_types: {
                    debit: "Abbuchung",
                    cancellation: "Stornierung",
                    refund: "Rückerstattung",
                    payout: "Auszahlung"
                },
                transaction_states: {
                    pending: "Ausstehend",
                    processing: "In Bearbeitung",
                    succeeded: "Erfolgreich",
                    failed: "Fehlgeschlagen",
                    cancelled: "Storniert"
                },
                calculated_on_payout: "wird bei Auszahlung berechnet",
                expected_payout: "ab ",
                load_transaction_details: "Transaktionsdetails abfragen",
                loading_transaction_details: "Lade Transaktionsdetails..."
            },
            booking_detail_modal: {
                title: "Buchungsdetails"
            },
            guest_invoice_creator: {
                title: "Rechnung für Mieter:in erstellen",
                guest_invoice_description: "Die Rechnung für Gäste ist ein Service von roomjack für Vermieter:in und berechnet sich anhand der von dir eingegebenen Daten und Steuern. Die Verantwortung für die Erstellung einer korrekten Rechnung liegt weiterhin bei dir, denn das Rechtsverhältnis zwischen Vermieter:in und Mieter:in bleibt durch diesen Service unberührt. roomjack haftet nicht für fehlerhafte Gäste-Rechnungen. Solltest du Hilfe bei der korrekten Berechnung der Steuern benötigen <a href='/faq-help?category=landlord&topic=calculate_taxes' rel='noreferrer nofollow' target='_blank'>klicke hier</a>.",
                invoice_no_description: "Rechnungsnummer:",
                your_message: "Deine Nachricht an die:den Mieter:in",
                send_guest_invoice: "Rechnung senden",
                sending_invoice: "Rechnung wird gesendet",
                invoice_successful_sent: "Die Rechnung wurde erfolgreich an die:den Mieter:in versandt.",
                invoice_preview: "Rechnungsvorschau"
            },
            booking_free_period_modal: {
                title: "Buchungen blockieren",
                description: "Hier hast du die Gelegenheit für deinen room, einen Zeitraum zu definieren, in dem er nicht gebucht werden kann. Bitte beachte, dass in dem Zeitraum eventuell bereits existierende Buchungen weiterhin bestehen bleiben.",
                new_marker: "Neue Markierung",
                color_label: "Farbe",
                description_label: "Bezeichnung",
                description_placeholder: "Max. 20 Zeichen",
                same_name_error: "Du hast bereits eine Vorlage mit der gleichen Beschreibung",
                no_dates_error: "Bitte Start- und Enddatum festlegen."
            },
            current_price_modal: {
                title: "Tagesmiete verwalten",
                simple_description: "Hier kannst du einen Tagesmiete für einen individuellen Zeitraum definieren. Achtung: Diese Tagesmiete überschreibt die von dir im Inserat festgelegte Tagesmiete für den von dir selektierten Zeitraum! Alle nicht selektierten Tage bleiben unberührt!",
                template_description: "Hier kannst du individuelle Vorlagen für Tagesmiete erstellen und diese bestimmten Zeiträumen zuordnen. Achtung: Diese Tagesmiete überschreibt die von dir im Inserat festgelegte Tagesmiete für den von dir selektierten Zeitraum! Alle nicht selektierten Tage bleiben unberührt!",
                new_template: "Neue Vorlage",
                description_label: "Bezeichnung",
                description_placeholder: "Max. 20 Zeichen",
                price_label: "Preis",
                week_days: ["montags", "dienstags", "mittwochs", "donnerstags", "freitags", "samstags", "sonntags"],
                no_repeat: "Einmalig",
                workdays: "Arbeitstage",
                weekend: "Wochenende",
                simple_current_price: "einzelnen Tag anpassen",
                template_current_price: "Vorlage festlegen",
                same_name_error: "Du hast bereits eine Vorlage mit der gleichen Beschreibung",
                invalid_price_error: "Bitte einen gültigen Preis festlegen.",
                no_dates_error: "Bitte Startdatum festlegen.",
                no_template_selected_error: "Bitte eine Vorlage auswählen."
            },
            calendar_sync_modal: {
                title: "Kalendersynchronisierung",
                description: "Hier hast du die Möglichkeit diese Unterkunft mit anderen Plattformen via iCal zu synchronisieren, um die Wahrscheinlichkeit von Doppelbuchungen zu minimieren." +
                    "<ol><li>Erstelle einen neuen Eintrag, in dem du auf \"Neuer Kalender-Sync-Eintrag\" klickst. Gebe dem Service einen Namen bestehend aus max. 20 Zeichen. Füge anschließend den iCal-Link vom anderen Plattform ins URL-Feld ein. Bitte beachte, dass nur Links, die mit https:// beginnen akzeptiert werden und sowohl der Name als auch die URL nur einmalig in deiner Liste vorkommen kann.</li>" +
                    "<li>Kopiere den Synchronisierungslink deiner Unterkunft bei roomjack, und füge ihn entsprechend der Anweisungen des anderen Plattforms ein.</li></ol>",
                sync_link_label: "Synchronisierungslink",
                add_button_text: "Neuer Kalender-Sync-Eintrag",
                errors: {
                    url_in_use: "Der von dir eingegebene URL wird für dieses Inserat bereits verwendet.",
                    name_in_use: "Der von dir eingegebene Name wird für diesen room bereits verwendet.",
                    invalid_url: "Nur geschützte URL mit \"https://\" am Anfang werden akzeptiert.",
                    invalid_input: "Bitte einen gültigen Namen und URL angeben (siehe Details oben).",
                    invalid_feed: "Der angegebene URL ist kein gültiger Kalender-Synchronisierungslink."
                }
            },
            payment_handler: {
                header: "Zahle mit...",
                loading_payment_methods: "Lade Zahlungsmethoden...",
                creating_intent: "Erstelle Vorgang...",
                creating_session: "Erstelle Session für #",
                debit_charge: "Lastschrift",
                payment_types: {
                    card: "Kreditkarte",
                    sepa_debit: "SEPA-Lastschriftverfahren",
                    paypal: "PayPal",
                    klarna: "Klarna"
                },
                bank_account_owner: "Kontoinhaber",
                add_new_payment_method: "Neue Zahlungsmethode hinzufügen",
                verifying_payment_method: "Prüfe Zahlungsmittel...",
                description: "Für sichere Zahlungsprozesse arbeitet roomjack mit dem Online-Bezahldienst Stripe zusammen. Bitte beachte, dass die hier angegebene Zahlungsdaten direkt von Stripe abgefragt und verwaltet werden. Von roomjack werden keinerlei Daten über Zahlungsmittel abgespeichert.",
                stripe_privacy: "Stripe Datenschutzhinweise",
            },
            invoicing: {
                invoice: "Rechnung",
                number_short: "Nr.",
                position_short: "Pos.",
                invoice_term: "Bezeichnung",
                time_interval: "Zeitraum",
                service_date: "Leistungsdatum",
                share: "Anteil",
                count: "Anzahl",
                discount: "Rabatt",
                net: "Netto",
                value_added_tax: "Mehrwertsteuer",
                value_added_tax_short: "MwSt.",
                gross: "Brutto",
                sum: "Gesamt",
                price_sum: "Gesamtsumme",
                invoice_number_short: "Rechnungsnr.",
                invoice_date: "Rechnungsdatum",
                customer_id: "Kunden-ID",
                customer_vat_id: "Kunden UID",
                tax_id: "Steuer-Nr.",
                vat_id: "UID-Nr.",
                advert_id: "Inserats-ID",
                registration_number_short: "Registrierungsnr.",
                booster_invoice_term: "roomjack - Booster<br>[boosterType] für<br>[advertTitle]<br>Inserats-ID: [advertID]",
                roomjack_fee_invoice_term: "roomjack – fee<br>Servicenutzung roomjack",
                guest_invoice_term: "Nächtigungsgebühr für<br>[advertTitle]<br>Inserats-ID: [advertID]<br>Registrierungsnr.: [regNo]",
                guest_invoice_cancellation_term: "Stornogebühr für<br>[advertTitle]<br>Inserats-ID: [advertID]<br>Registrierungsnr.: [regNo]",
                sum_positions: "Summe Positionen",
                plus_value_added_tax: "zzgl. [taxPercent]% Umsatzsteuer",
                invoice_amount: "Rechnungsbetrag",
                roomjack_fee_information: "Deine roomjack – fee wurde bereits bei der Auszahlung von Stripe berücksichtigt!<br>Du musst sie nicht gesondert bezahlen!",
                thanking: "Vielen Dank, dass du roomjack genutzt hast! <3<br><br>Dein Team von roomjack",
                miscellaneous_taxes: "zzgl. weitere Steuern",
                small_business_regulation: "Umsatzsteuerfrei aufgrund der Kleinunternehmerregelung.",
                reverse_charge_regulation: "Die Umsatzsteuerschuld geht auf den Leistungsempfänger über (Reverse Charge System)."
            },
            legal_doc_modal: {
                input_label: "Verweislink",
                terms_of_service_title: "Verweis auf allg. Mietbedingungen",
                terms_of_service_placeholder: "z.B. https://deine-seite.org/agb.html",
                revocation_title: "Verweis auf Widerrufsbelehrung",
                revocation_placeholder: "z.B. https://deine-seite.org/widerrufsbelehrung.html",
                privacy_policy_title: "Verweis auf Datenschutzerklärung",
                privacy_policy_placeholder: "z.B. https://deine-seite.org/datenschutz.html",
                url_without_ssh: "Der Link muss mit https:// beginnen.",
                legal_link_description: "Du hast die Möglichkeit den Verweis entweder zu verlinken oder als PDF-Dokument hochzuladen. Wenn du deinen Verweis als Link einfügen möchtest, muss dieser aus Sicherheitsgründen mit \"https://\" beginnen. Bitte achte auf eine korrekte Verlinkung. roomjack übernimmt keinerlei Verantwortung für falsche Verweise und dadurch eventuell entstehende rechtliche Streitigkeiten.",
                file_selector_header: "Drag&Drop oder Click zum Auswählen.",
                file_selector_info: "Ausschließlich PDF-Dateien mit einer maximale Größe von 10 MB sind erlaubt."
            },
            file_uploader: {
                too_big_alert: "Die ausgewählte Datei ist zu groß.",
                too_big_alert_multi: "Mindestens eins der ausgewählten Dateien war zu groß.",
                wrong_format_alert: "Die ausgewählte Dateiformat ist falsch.",
                wrong_format_alert_multi: "Mindestens eins der ausgewählten Dateien hatte den falschen Format.",
                too_many: "Maximale Anzahl der Dateien wurde überschritten.",
            },
            csv_export: {
                title: "Einkommen aus Buchungen als CSV",
                description: "Wähle aus welche Spalten deine csv-Datei beinhalten soll. Die Reihenfolge der Spalten kannst du per Drag&Drop umordnen. Um die Datei zu generieren und automatisch herunterzuladen, klicke bitte auf \"Datei herunterladen\".",
                download: "Datei Herunterladen",
                columns: {
                    name: "Name Mieter:in",
                    code: "Buchungscode",
                    start: "Start",
                    end: "Ende",
                    sum: "Betrag",
                    stripe_fee: "Stripe-Gebuehr",
                    application_fee: "RoomJack-Gebuehr",
                    net: "Netto",
                    currency: "Waehrung",
                    payout_date: "Datum der Auszahlung"
                }
            },
            ai_assistant: {
                ask_jacki: "Frag JacKI!",
            }
        },
        four_o_four: {
            title: "Ooooops!",
            button: "Zur Startseite",
            user_login: "Dafür musst du eingeloggt sein.",
            page_not_found: "Die Seite wurde nicht gefunden",
            booking_process_invalid: "Sitzung abgelaufen.",
            unauthorized: "Unauthorisierter Zugriff.",
            contact: "Kontakt aufnehmen"
        },
        help_pages: {
            faq_help: { title: "FAQ's und Hilfe" },
            tips_tricks: {
                title: "Tipps und Tricks",
                categories: {
                    landlord: "Rund ums Vermieten",
                    renter: "Rund ums Mieten",
                }
            },
            general: "Allgemein",
            search_keyword: "Nach Stichwort suchen..."
        },
        languages: {
            de: "Deutsch",
            en: "Englisch",
            es: "Spanisch",
            fr: "Französisch",
            it: "Italienisch",
            nl: "Niederländisch",
            pt: "Portugiesisch",
            ru: "Russisch",
            sq: "Albanisch",
            cs: "Tschechisch",
            da: "Dänisch",
            fi: "Finnisch",
            el: "Griechisch",
            hu: "Ungarisch",
            is: "Isländisch",
            ga: "Irisch",
            no: "Norwegisch",
            pl: "Polnisch",
            ro: "Rumänisch",
            sv: "Schwedisch",
            sk: "Slowakisch",
            zh: "Chinesisch",
            hi: "Hindi",
            ar: "Arabisch",
            bn: "Bengalisch",
            ja: "Japanisch",
            ko: "Koreanisch"
        },
        alerts: {
            update_available: {
                description: "Es ist eine neue Version von roomjack verfügbar!",
                button: "Jetzt upgraden!"
            },
            browser_support: {
                description: "Für das optimale Benutzererlebnis empfehlen wir die Verwendung des neuesten Mozilla Firefox, Google Chrome oder Microsoft Edge Browsers, da  möglicherweise nicht alle Funktionen von roomjack in deinem aktuellen  Browser unterstützt werden.",
                button: "OK, verstanden"
            }
        }
    },
    en: {

    }
}