import React from "react";
import FilterSelectorBar from "../../../../components/FilterSelectorBar";
import {PAYOUT_CACHE} from "../../../../utils/CacheHandler.ts";
import {getPayouts} from "../../../../utils/RESTInterface";
import {
    generateCreditCardLabel,
    getDayFromBegin,
    getFormattedDate,
    getLanguageEntry,
    getTimeUnitInMS, priceToString,
    PRODUCTION_START
} from "../../../../utils/Helper";
import _uniqueId from "lodash/uniqueId";
import PropTypes from "prop-types";
import parse from "html-react-parser";

class PayoutHistory extends React.Component {

    filterItems = [
        {
            value: -1,
            label: "components>interval_filter>all_payouts"
        },
        {
            value: 30,
            label: "components>interval_filter>last_month"
        },
        {
            value: 90,
            label: "components>interval_filter>last_quarter"
        },
        {
            value: 180,
            label: "components>interval_filter>last_half_year"
        },
        {
            value: 365,
            label: "components>interval_filter>last_year"
        },
    ]

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            interval: -1,
            payouts: []
        }
    }

    componentDidMount() {
        if (PAYOUT_CACHE.isValid()) {
            this.setState({
                payouts: PAYOUT_CACHE.getPayouts() ? PAYOUT_CACHE.getPayouts() : []
            });
        }
        else {
            this.setState({loading: true});
            let that = this;
            getPayouts(() => {
                that.setState({
                    loading: false,
                    payouts: PAYOUT_CACHE.getPayouts() ? PAYOUT_CACHE.getPayouts() : []
                });
            });
        }
    }

    render() {
        let payouts = this.filterPayouts();
        return(
            <div id="payout-history-tool">
                {
                    this.state.loading &&
                    <div className="roomjack-container loading">
                        <img src="https://roomjack.blob.core.windows.net/roomjack/animations/loading_animation.gif" alt="Roomjack Loading"/>
                    </div>
                }
                {
                    !this.state.loading &&
                    <FilterSelectorBar items={this.filterItems} defaultValue={-1}
                                       onChange={(v) => { this.setState({interval: v}) }}/>
                }
                {
                    !this.state.loading && payouts.length === 0 &&
                    <div className="roomjack-container no-data-container">
                        <img src="https://roomjack.blob.core.windows.net/roomjack/content-images/verantwortungsvoll_vermieten/bild2.jpg"
                             alt="No payouts" style={{borderRadius: "100px"}}/>
                        <div className="description-container">
                            {getLanguageEntry("jackboard>finances>submenus>payout_history>placeholder" +
                                (this.state.payouts.length === 0 ? "" : "_interval"))}
                        </div>
                    </div>
                }
                {
                    !this.state.loading && payouts.length > 0 &&
                    <div className="roomjack-container">
                        {
                            payouts.map((p, i) => {
                                let created = new Date(p.created * 1000);
                                let arrival = new Date(p.arrival_date * 1000);
                                let bankAccount = PAYOUT_CACHE.getAccountData(p.destination);
                                let accountHolder = !bankAccount ? null : (bankAccount.account_holder_name ?
                                    bankAccount.account_holder_name : this.props.userData.first_name + " " + this.props.userData.last_name)
                                return <div className="transaction-box" key={_uniqueId(p.destination + "-" + i)}>
                                    <div className="transaction-header">
                                        {p.description ? p.description :
                                            getLanguageEntry("components>booking_detail_table>transaction_types>payout")}
                                    </div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {getLanguageEntry("components>booking_detail_table>transaction_table_header>transaction_status")}
                                                </td>
                                                <td>
                                                    {getLanguageEntry("jackboard>finances>submenus>transaction_states>" + [p.status])}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {getLanguageEntry("jackboard>finances>submenus>payout_created_on")}</td>
                                                <td>
                                                    {getFormattedDate(created, true, true, false, true)}
                                                </td>
                                            </tr>
                                            {
                                                p.status === 'paid' &&
                                                <tr>
                                                    <td>
                                                        {getLanguageEntry("jackboard>finances>submenus>payout_arrived_on")}
                                                    </td>
                                                    <td>
                                                        {getFormattedDate(arrival, true, true, false, true)}
                                                    </td>
                                                </tr>
                                            }
                                            {
                                                p.status === 'failed' && p.failure_message &&
                                                <tr>
                                                    <td>
                                                        {getLanguageEntry("jackboard>finances>submenus>payout_failure_msg")}
                                                    </td>
                                                    <td>
                                                        {p.failure_message}
                                                    </td>
                                                </tr>
                                            }
                                            {
                                                bankAccount &&
                                                <tr>
                                                    <td>
                                                        {getLanguageEntry("jackboard>finances>submenus>bank_name")}
                                                    </td>
                                                    <td>{bankAccount.bank_name}</td>
                                                </tr>
                                            }
                                            {
                                                accountHolder &&
                                                <tr>
                                                    <td>
                                                        {getLanguageEntry("jackboard>finances>submenus>account_owner_name")}
                                                    </td>
                                                    <td>{accountHolder}</td>
                                                </tr>
                                            }
                                            {
                                                bankAccount &&
                                                <tr>
                                                    <td>
                                                        {getLanguageEntry("jackboard>finances>submenus>last4")}
                                                    </td>
                                                    <td>{parse(generateCreditCardLabel(bankAccount.last4))}</td>
                                                </tr>
                                            }{
                                                bankAccount &&
                                                <tr>
                                                    <td>
                                                        {getLanguageEntry("jackboard>finances>submenus>payout_sum")}
                                                    </td>
                                                    <td>{priceToString(p.amount)}</td>
                                                </tr>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            })
                        }
                    </div>
                }
            </div>
        )
    }

    filterPayouts() {
        if (this.state.loading) {
            return [];
        }
        let start = PRODUCTION_START.getTime();
        let end = getDayFromBegin().getTime();
        if (this.state.interval !== -1) {
            start = end - getTimeUnitInMS(this.state.interval);
        }
        return this.state.payouts.filter(p => p.created * 1000 >= start && p.created * 1000 <= end);
    }

}
PayoutHistory.propTypes = {
    userData: PropTypes.object.isRequired
}
export default PayoutHistory;