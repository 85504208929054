import React from "react";
import PropTypes from "prop-types";
import {
    determineAdvertNameLabel, getFormattedAddress, getFormattedDate,
    getLanguageEntry, getNightsBetweenDates
} from "../../utils/Helper";
import {ADVERT_CACHE} from "../../utils/CacheHandler.ts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

class BookingDetailTable extends React.Component {

    render() {
        if (!this.props.booking) {
            return <div></div>
        }
        let startDate = new Date(this.props.booking.start);
        let endDate = new Date(this.props.booking.end);
        let dayCount = getNightsBetweenDates(endDate, startDate);
        let bookingTSDate = new Date(this.props.booking.booking_ts);
        let cancellationTSDate = null;
        if (this.props.booking.cancellation_ts !== undefined) {
            cancellationTSDate = new Date(this.props.booking.cancellation_ts);
        }
        let owner = this.props.booking.owner;
        let booker = this.props.booking.booker;
        let targetUser = this.props.userData.id === owner ? booker : owner;

        let advertType = null;
        let housingID = this.props.booking.advert_id;
        if (this.props.booking.room_id) {
            housingID += "SP" + this.props.booking.room_id
        }
        if (this.props.userData.id === owner) {
            let advert = ADVERT_CACHE.getCacheObjectData(this.props.booking.advert_id);
            if (advert && advert.rooms && this.props.booking.room_id !== undefined) {
                advertType = advert.rooms.filter(r => r.id === this.props.booking.room_id)[0].name;
            }
        }
        if (advertType === null) {
            advertType = this.props.booking.room_id ?
                getLanguageEntry("advert_attributes>advert_types>room") :
                determineAdvertNameLabel(this.props.booking);
        }
        let bookerID = booker.id ?? booker;
        let cancelledByBooker; let reasonDefined; let reasonMap; let reason;
        if (this.props.booking.cancelled) {
            cancelledByBooker = this.props.booking.cancelled_by === bookerID;
            reasonDefined = this.props.booking.cancellation_reason !== null &&
                            this.props.booking.cancellation_reason !== undefined;
            reasonMap = getLanguageEntry("dynamic_page_content>cancellation_reasons>" +
                (cancelledByBooker ? "renter" : "landlord"));
            reason = !reasonDefined || !this.props.booking.cancellation_reason.reason ||
                     !reasonMap[this.props.booking.cancellation_reason.reason] ?
                reasonMap.miscellaneous : reasonMap[this.props.booking.cancellation_reason.reason];
        }
        return(
            <div className="booking-overview-table">
                <table>
                    <tbody>
                        <tr>
                            <td>{getLanguageEntry("advert_attributes>type_of_housing")}</td>
                            <td>
                                {advertType}
                                {
                                    this.props.userData.id === booker &&
                                    <Link className="link-button view-housing" to={"/expose/" + housingID}>
                                        <FontAwesomeIcon icon={["fal", "arrow-up-right-from-square"]}/>
                                    </Link>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>{getLanguageEntry("general>booking_date")}</td>
                            <td>{getFormattedDate(bookingTSDate, true, true)}</td>
                        </tr>
                        <tr>
                            <td>{getLanguageEntry("general>booking_start")}</td>
                            <td>{getFormattedDate(startDate, true, true)}</td>
                        </tr>
                        <tr>
                            <td>{getLanguageEntry("general>booking_end")}</td>
                            <td>{getFormattedDate(endDate, true, true)}</td>
                        </tr>
                        <tr>
                            <td>{getLanguageEntry("general>nights")}</td>
                            <td>{dayCount}</td>
                        </tr>
                        {
                            this.props.booking.confirmation_code &&
                            <tr>
                                <td>{getLanguageEntry("general>booking_code")}</td>
                                <td>{this.props.booking.confirmation_code}</td>
                            </tr>
                        }
                        <tr>
                            <td>{getLanguageEntry("general>address")}</td>
                            <td>{getFormattedAddress(this.props.booking.address)}</td>
                        </tr>
                        <tr>
                            <td>{getLanguageEntry("general>" + (this.props.userData.id === booker ? "landlord" : "guest"))}</td>
                            <td>
                                {targetUser.first_name + ' ' + targetUser.last_name}
                                {
                                    this.props.onSendMessage &&
                                    <button className="send-msg" onClick={() => {
                                        this.props.onSendMessage(targetUser)
                                    }}>
                                        <FontAwesomeIcon icon={["fal", "envelope"]}/>
                                    </button>
                                }
                            </td>
                        </tr>
                        {
                            this.props.booking.terms_of_service &&
                            <tr>
                                <td>{getLanguageEntry("processes>booking>terms_of_service")}</td>
                                <td>
                                    <a href={this.props.booking.terms_of_service} rel="noreferrer nofollow">
                                        <FontAwesomeIcon icon={["fal", "up-right-from-square"]} />
                                    </a>
                                </td>
                            </tr>
                        }
                        {
                            this.props.booking.revocation &&
                            <tr>
                                <td>{getLanguageEntry("processes>booking>revocation")}</td>
                                <td>
                                    <a href={this.props.booking.revocation} rel="noreferrer nofollow">
                                        <FontAwesomeIcon icon={["fal", "up-right-from-square"]} />
                                    </a>
                                </td>
                            </tr>
                        }
                        {
                            this.props.booking.privacy_policy &&
                            <tr>
                                <td>{getLanguageEntry("processes>booking>privacy_policy")}</td>
                                <td>
                                    <a href={this.props.booking.privacy_policy} rel="noreferrer nofollow">
                                        <FontAwesomeIcon icon={["fal", "up-right-from-square"]} />
                                    </a>
                                </td>
                            </tr>
                        }
                        {
                            cancellationTSDate &&
                            <tr>
                                <td>{getLanguageEntry("components>booking_detail_table>cancellation_date")}</td>
                                <td>{getFormattedDate(cancellationTSDate, true, true, true, true, true)}</td>
                            </tr>
                        }
                        {
                            this.props.booking.cancelled &&
                            <tr>
                                <td>{getLanguageEntry("jackboard>bookings>cancelled_by")}</td>
                                <td>{getLanguageEntry("general>" + (cancelledByBooker ? "guest" : "landlord"))}</td>
                            </tr>
                        }
                        {
                            this.props.booking.cancelled &&
                            <tr>
                                <td>{getLanguageEntry("processes>booking_cancellation>cancellation_reason")}</td>
                                <td>{reason}</td>
                            </tr>
                        }
                        {
                            reasonDefined && this.props.booking.cancellation_reason.comment &&
                            <tr>
                                <td>{getLanguageEntry("processes>booking_cancellation>reason_comment")}</td>
                                <td>{this.props.booking.cancellation_reason.comment}</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        )
    }

}

BookingDetailTable.propTypes = {
    booking: PropTypes.object,
    userData: PropTypes.object.isRequired,
    onSendMessage: PropTypes.func
}
export default BookingDetailTable;