import {HEIDENHEIM, HORB, LINZ} from "../../../resources/contents/ContentPageContent";
import {buildTextLinkContainer, createFeatureContainer, getLanguageEntry} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import parse from "html-react-parser";

export function LinzContentPage() {

    return <div id="content-linz" className="content-page">
        <div className="parallax" style={{backgroundImage: "url('" + LINZ.parallax_background + "')"}}>
                    <span className="parallax-text">
                        <span>roomjack</span><sup className="copyright">&#169;</sup>
                        <span> {getLanguageEntry("content_pages>linz>scroll_box_text")}</span>
                    </span>
            <span role="img" aria-label={LINZ.parallax_alt}> </span>
            <span className="image-copyright-label"><FontAwesomeIcon icon={["fal", "copyright"]} fixedWidth={true}/> Copyright: {LINZ.parallax_copyright}</span>
        </div>
        <div className="container">
            <h1 className="content-title">{getLanguageEntry("content_pages>linz>title")}</h1>
            <div className="content-description">{getLanguageEntry("content_pages>linz>description")}</div>
            <div className="content-page-block">
                <h2>{getLanguageEntry("content_pages>linz>hotspot_header")}</h2>
                <p>{getLanguageEntry("content_pages>linz>hotspots")}</p>
            </div>
            {
                LINZ.features.map((item, i)=>  {
                    return createFeatureContainer(i, item.url, item.alt, item.copyright, "content_pages>linz>hotspot_list>", item.link)
                })
            }
            {buildTextLinkContainer("content-description", "content_pages>linz>closing_block", LINZ.closing_block)}
        </div>
    </div>

}

export function HeidenheimContentPage() {

    return <div id="content-heidenheim" className="content-page">
        <div className="parallax" style={{backgroundImage: "url('" + HEIDENHEIM.parallax_background + "')"}}>
                    <span className="parallax-text">
                        <span>roomjack</span><sup className="copyright">&#169;</sup>
                        <span> {getLanguageEntry("content_pages>heidenheim>scroll_box_text")}</span>
                    </span>
            <span role="img" aria-label={HEIDENHEIM.parallax_alt}> </span>
        </div>
        <div className="container">
            <h1 className="content-title">{getLanguageEntry("content_pages>heidenheim>title")}</h1>
            {
                HEIDENHEIM.paragraphs.map((item, i) => {
                    return <div className="content-description" key={"heidenheim-paragraph-" + i}>
                        {parse(getLanguageEntry(item))}
                    </div>
                })
            }
            {
                HEIDENHEIM.features.map((item, i)=>{
                    return createFeatureContainer(i, item.url, item.alt, item.copyright, "content_pages>heidenheim>hotspot_list>", item.link)
                })
            }
            {
                HEIDENHEIM.closing_paragraphs.map((item, i) => {
                    return <div className="content-description" key={"heidenheim-closing-paragraph-" + i}>
                        {parse(getLanguageEntry(item))}
                    </div>
                })
            }
            <div className="content-description">
                {getLanguageEntry("content_pages>heidenheim>closing_block")}
            </div>
        </div>
    </div>

}

export function HorbContentPage() {

    return <div id="content-horb" className="content-page">
        <div className="parallax" style={{backgroundImage: "url('" + HORB.parallax_background + "')"}}>
                    <span className="parallax-text">
                        <span>roomjack</span><sup className="copyright">&#169;</sup>
                        <span> {getLanguageEntry("content_pages>horb>scroll_box_text")}</span>
                    </span>
            <span role="img" aria-label={HORB.parallax_alt}> </span>
        </div>
        <div className="container">
            <h1 className="content-title">{getLanguageEntry("content_pages>horb>title")}</h1>
            {
                HORB.paragraphs.map((item, i) => {
                    return <div className="content-description" key={"horb-paragraph-" + i}>
                        {parse(getLanguageEntry(item))}
                    </div>
                })
            }
            {
                HORB.features.map((item, i)=>{
                    return createFeatureContainer(i, item.url, item.alt, item.copyright, "content_pages>horb>hotspot_list>", item.link)
                })
            }
            {
                HORB.closing_paragraphs.map((item, i) => {
                    return <div className="content-description" key={"horb-closing-paragraph-" + i}>
                        {getLanguageEntry(item)}
                    </div>
                })
            }
            <div className="content-description">
                {getLanguageEntry("content_pages>horb>closing_block")}
            </div>
        </div>
    </div>

}