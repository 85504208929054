import React from "react";
import PropTypes from "prop-types";
import _uniqueId from "lodash/uniqueId";
import {Form, Col} from "react-bootstrap";
import {getLanguageEntry} from "../../utils/Helper";

class TextArea extends React.Component {

    constructor(props) {
        super(props);
        this.textarea = React.createRef();
        this.state = {
            input_length: this.props.initialValue ? this.props.initialValue.length : 0
        }
    }

    render() {
        return (
            <Form.Group as={Col} controlId={_uniqueId("custom-textarea@" + Date.now())}
                        className={this.props.className}>
                {
                    this.props.label &&
                    <Form.Label className="description-container">{getLanguageEntry(this.props.label)}</Form.Label>
                }
                <textarea name={this.props.name} id={this.props.id} cols={this.props.cols} rows={this.props.rows}
                          maxLength={this.props.maxLength} defaultValue={this.props.initialValue ? this.props.initialValue : ""}
                          ref={this.textarea} required={this.props.required} className={this.props.invalid ? "invalid" : ""}
                          readOnly={this.props.readOnly} onKeyUp={(e) => {
                              this.actualizeInputLength();
                              this.props.onChange?.(e.target.value);
                          }} placeholder={this.props.placeholder ? getLanguageEntry(this.props.placeholder) : ""}/>
                {
                    this.props.maxLength &&
                    <div className="description-container">
                        {this.state.input_length + " " + getLanguageEntry("general>chars") +
                            ' (max. ' + this.props.maxLength + ' ' + getLanguageEntry("general>chars")   + ')'}
                    </div>
                }
            </Form.Group>
        )
    }

    get value() {
        return this.textarea.current.value;
    }

    set value(value) {
        this.textarea.current.value = value;
    }

    actualizeInputLength() {
        this.setState({input_length: this.textarea.current.value.length});
    }

}

TextArea.propTypes = {
    maxLength: PropTypes.number,
    label: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    cols: PropTypes.number,
    rows: PropTypes.number,
    initialValue: PropTypes.string,
    required: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    invalid: PropTypes.bool,
    readOnly: PropTypes.bool,
}
TextArea.defaultProps = {
    cols: 10,
    rows: 5,
    className: '',
    readOnly: false
}
export default TextArea;