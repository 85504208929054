import PropTypes from "prop-types";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper";
import "swiper/css";
import "../../css/components/carousel-grid.css";
import {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function CarouselGrid(props) {
    const recalculateBreakpoints = () => {
        return Object.fromEntries(Object.entries(props.breakpoints).filter(([_, value]) => {
            return value.slidesPerView <= props.items.length
        }));
    }

    const [breakpoints, setBreakpoints] = useState(recalculateBreakpoints);
    const swiperRef = useRef();


    useEffect(() => {
        function handleResize() {
            setBreakpoints(recalculateBreakpoints);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    })

    const showArrows = () => {
        let itemsPerSlide = 1;
        for (let i=0; i<Object.keys(breakpoints).length; i++) {
            if (window.innerWidth < Object.keys(breakpoints)[i]) {
                break;
            }
            itemsPerSlide = Object.values(breakpoints)[i].slidesPerView;
        }
        return props.items && props.items.length > itemsPerSlide;
    }

    return <div className="carousel-grid">
        {
            props.controls && showArrows() &&
            <button onClick={() => swiperRef.current?.slidePrev()} className="prev-slide">
                {props.prevButton}
            </button>
        }
        <Swiper breakpoints={breakpoints} loop={true} spaceBetween={props.spaceBetween}
                onBeforeInit={(swiper) => {swiperRef.current = swiper}} autoplay={{delay: props.interval}}
                modules={[Autoplay]} >
            {
                props.items.map((item, i) => {
                    return <SwiperSlide key={props.id + "-" + i}>{item}</SwiperSlide>
                })
            }
        </Swiper>
        {
            props.controls && showArrows() &&
            <button onClick={() => swiperRef.current?.slideNext()} className="next-slide">
                {props.nextButton}
            </button>
        }
    </div>

}
CarouselGrid.propTypes = {
    interval: PropTypes.number,
    indicators: PropTypes.bool,
    controls: PropTypes.bool,
    items: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
    breakpoints: PropTypes.object,
    prevButton: PropTypes.object,
    nextButton: PropTypes.object,
    spaceBetween: PropTypes.number
}
CarouselGrid.defaultProps = {
    interval: 4000,
    indicators: true,
    controls: true,
    breakpoints: {
        400: {
            slidesPerView: 1,
            slidesPerGroup: 1
        },
        500: {
            slidesPerView: 2,
            slidesPerGroup: 2
        },
        750: {
            slidesPerView: 3,
            slidesPerGroup: 3
        },
        950: {
            slidesPerView: 4,
            slidesPerGroup: 4
        }
    },
    prevButton: <FontAwesomeIcon icon={["fal", "chevron-left"]} />,
    nextButton: <FontAwesomeIcon icon={["fal", "chevron-right"]} />,
    spaceBetween: 10
}