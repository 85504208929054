import React from 'react';
import {
    ABOUT_ME, BIRTHDAY, CITY, COMPANY, COMPANY_REG_NO, COUNTRY, LINE_1, TAX_COUNTRY, MAX_CHARS_ABOUT_ME,
    OPERATING_COUNTRIES, POSTAL_CODE, PREFERRED_MENU_MODE, ADDRESS, PHONE, TAX_ID, VAT_ID, AVATAR_SIZE_LIMIT,
    activateBreakLines, createCountryDropdown, createModeDropdown, createMultiCountryDropdown,
    dateStringToObject, getFormattedAddress, getFormattedDate,
    getLanguageEntry, getMousePositionOnElement, getLanguageNameList, createMultiLanguageDropdown
} from "../../../utils/Helper";
import NavigatorButton from "../../../components/buttons/NavigatorButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PhoneInputField from "../../../components/input/PhoneInputField";
import DatePicker from "../../../components/input/DatePicker";
import {Col, Form, Modal} from "react-bootstrap";
import Autocomplete from "../../../components/input/Autocomplete";
import PropTypes from "prop-types";
import {updateUser} from "../../../utils/RESTInterface";
import AvatarUploader from "../../../components/input/AvatarUploader";
import CustomSlider from "../../../components/input/CustomSlider"
import TextArea from "../../../components/input/TextArea";
import {Mode} from "../../../utils/Types.ts";
import FetchButton from "../../../components/buttons/FetchButton";

const EditButton = (props) => {
    const icon = props.state === 0 ? ["fas", "pencil-alt"] : props.state === 1 ?
        ["fas", "save"] : ["fad", "spinner-third"];
    const spin = props.state > 1;

    return <button {...props} disabled={props.state === 2}>
        <FontAwesomeIcon icon={icon} spin={spin} />
        {
            <span>{getLanguageEntry("general>" + (props.state > 0 ? "save" : "edit"))}</span>
        }
    </button>
}

class ProfileOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            edit_birthday: 0,
            edit_preferred_menu_mode: 0,
            edit_address: 0,
            edit_email: 0,
            edit_phone: 0,
            edit_languages: 0,
            edit_about_me: 0,
            edit_tax_id: 0,
            edit_vat_id: 0,
            edit_tax_country: 0,
            edit_company: 0,
            edit_company_reg_no: 0,
            edit_operating_countries: 0,
            show_alert: false,
            alert_content: '',
            show_avatar_editor: false,
            max_slider_value: 21,
            x: 0,
            y: 0,
            radius: 0,
            saving_avatar: false,
            deleting_avatar: false
        }
        this.birthday = React.createRef();
        this.company = React.createRef();
        this.company_reg_no = React.createRef();
        this.mode_selector = React.createRef();
        this.address = React.createRef();
        this.line_1 = React.createRef();
        this.postal_code = React.createRef();
        this.city = React.createRef();
        this.country = React.createRef();
        this.phone = React.createRef();
        this.languageSelector = React.createRef();
        this.tax_id = React.createRef();
        this.tax_country = React.createRef();
        this.vat_id = React.createRef();
        this.operating_countries = React.createRef();
        this.about_me = React.createRef();
        this.overlay = React.createRef();

        // avatar editor
        this.x = 0;
        this.y = 0;
        this.lastDragPosition = null;
        this.drag = false;
    }

    render() {
        return (
            <div>
                <div id="profile-card" className="roomjack-container">
                    <div id="avatar-selector-container">
                        <AvatarUploader size={200} header="components>image_uploader>avatar_selector_info"
                                        onEdit={() => { this.showAvatarEditor(); }}
                                        onAddImage={(imageData, callback) => {
                                            this.setState({saving_avatar: true});
                                            this.uploadAvatar(imageData, () => {
                                                callback?.();
                                                this.setState({saving_avatar: false});
                                            })
                                        }}
                                        maxFileSize={AVATAR_SIZE_LIMIT} imageData={this.props.userData.avatar}/>
                    </div>
                    <table>
                        <tbody>
                        <tr className="name-container">
                            <td className="label">{getLanguageEntry("general>name")}</td>
                            <td className="data">
                                <span>{this.props.userData.first_name + " " + this.props.userData.last_name}</span>
                                {
                                    this.props.userData.verified &&
                                    <FontAwesomeIcon icon={["fas", "check-circle"]} />
                                }
                            </td>
                            <td className="control">
                                <NavigatorButton to="/profile/account" >
                                    <FontAwesomeIcon icon={["fas", "pencil-alt"]} />
                                    <span>{getLanguageEntry("general>edit")}</span>
                                </NavigatorButton>
                            </td>
                        </tr>
                        {
                            !this.props.userData.b2b &&
                            <tr className="birthday-container">
                                <td className="label">{getLanguageEntry("general>birthday")}</td>
                                <td className="data">
                                    {
                                        this.state.edit_birthday === 0 &&
                                        (this.props.userData.birthday === undefined ?
                                            "-" : getFormattedDate(new Date(this.props.userData.birthday), true, false, false))
                                    }
                                    {
                                        this.state.edit_birthday > 0 &&
                                        <DatePicker ref={this.birthday}
                                            initialValue={dateStringToObject(this.props.userData.birthday)}/>
                                    }
                                </td>
                                <td className="control">
                                    <EditButton state={this.state.edit_birthday}
                                                onClick={() => { this.changeEditStatus(BIRTHDAY, this.state.edit_birthday) } } />
                                </td>
                            </tr>
                        }
                        {
                            this.props.mode === Mode.landlord && this.props.userData.b2b &&
                            <tr className="company-container">
                                <td className="label">{getLanguageEntry("user_profile>personal_data_labels>company")}</td>
                                <td className="data">
                                    {
                                        this.state.edit_company === 0 &&
                                        (this.props.userData.company === undefined ?
                                            "-" : this.props.userData.company)
                                    }
                                    {
                                        this.state.edit_company > 0 &&
                                        <form onSubmit={(e) => {
                                            e.preventDefault();
                                            this.changeEditStatus(COMPANY, this.state.edit_company);
                                        }} id="profile-edit-company">
                                            <input type="text" ref={this.company} required={true}
                                                   defaultValue={this.props.userData.company === undefined ? "" : this.props.userData.company}/>
                                        </form>
                                    }
                                </td>
                                <td className="control">
                                    {
                                        this.state.edit_company > 0 &&
                                        <EditButton state={this.state.edit_company} form="profile-edit-company" type="submit" />
                                    }
                                    {
                                        this.state.edit_company === 0 &&
                                        <EditButton state={this.state.edit_company}
                                                    onClick={() => {
                                                        if (!this.state.edit_company) {
                                                            this.changeEditStatus(COMPANY, this.state.edit_company)
                                                        }}} />
                                    }
                                </td>
                            </tr>
                        }
                        {
                            this.props.mode === Mode.landlord && this.props.userData.b2b &&
                            <tr className="company-reg-no-container">
                                <td className="label">{getLanguageEntry("general>company_reg_no")}</td>
                                <td className="data">
                                    {
                                        this.state.edit_company_reg_no === 0 &&
                                        (this.props.userData.company_reg_no === undefined ?
                                            "-" : this.props.userData.company_reg_no)
                                    }
                                    {
                                        this.state.edit_company_reg_no > 0 &&
                                        <input type="text" ref={this.company_reg_no}
                                               defaultValue={this.props.userData.company_reg_no === undefined ? "-" : this.props.userData.company_reg_no}/>
                                    }
                                </td>
                                <td className="control">
                                    <EditButton state={this.state.edit_company_reg_no}
                                                onClick={() => { this.changeEditStatus(COMPANY_REG_NO, this.state.edit_company_reg_no) } } />
                                </td>
                            </tr>
                        }
                        <tr className="mode-container">
                            <td className="label">{getLanguageEntry("general>preferred_menu_mode")}</td>
                            <td className="data">
                                {
                                    this.state.edit_preferred_menu_mode === 0 &&
                                    getLanguageEntry("general>modes>" + this.props.userData.preferred_menu_mode)
                                }
                                {
                                    this.state.edit_preferred_menu_mode > 0 &&
                                    createModeDropdown(PREFERRED_MENU_MODE, this.mode_selector, this.props.userData.preferred_menu_mode)
                                }
                            </td>
                            <td className="control">
                                <EditButton state={this.state.edit_preferred_menu_mode}
                                            onClick={() => { this.changeEditStatus(PREFERRED_MENU_MODE, this.state.edit_preferred_menu_mode) } } />
                            </td>
                        </tr>
                        <tr className="address-container">
                            <td className="label">{getLanguageEntry("general>address")}</td>
                            <td className="data">
                                {
                                    this.state.edit_address === 0 &&
                                    (this.props.userData.address === undefined ?
                                        "-" : getFormattedAddress(this.props.userData.address))
                                }
                                {
                                    this.state.edit_address > 0 &&
                                    <Form.Group as={Col}>
                                        <form ref={this.address} name={ADDRESS}>
                                            <Form.Group className="mb-2" as={Col}>
                                                <Form.Label className="description-container v-form-label" column>
                                                    {getLanguageEntry("general>street") + ", " +
                                                        getLanguageEntry("general>street_no")}
                                                </Form.Label>
                                                <Form.Group>
                                                    <Autocomplete name={LINE_1}
                                                                  initialValue={this.props.userData.address &&
                                                                      this.props.userData.address.line_1 ?
                                                                      this.props.userData.address.line_1 : ''}
                                                                  onPlaceSelected={(address_line, place) => { this.onPlaceSelected(address_line, place); }} />
                                                </Form.Group>
                                            </Form.Group>
                                            <Form.Group as={Col} >
                                                <Form.Label className="description-container v-form-label" column>
                                                    {getLanguageEntry("general>postal_code")}
                                                </Form.Label>
                                                <Form.Control type="text" name={POSTAL_CODE} ref={this.postal_code}
                                                              defaultValue={(this.props.userData.address !== undefined &&
                                                                  this.props.userData.address.postal_code !== undefined) ?
                                                                  this.props.userData.address.postal_code : ''}/>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label className="description-container v-form-label" column>
                                                    {getLanguageEntry("general>city")}
                                                </Form.Label>
                                                <Form.Control type="text" name={CITY} ref={this.city}
                                                              defaultValue={(this.props.userData.address !== undefined &&
                                                                  this.props.userData.address.city !== undefined) ?
                                                                  this.props.userData.address.city : ''}/>
                                            </Form.Group>
                                            <Form.Group className="mb-2" as={Col}>
                                                <Form.Label className="description-container v-form-label" column>
                                                    {getLanguageEntry("general>country")}
                                                </Form.Label>
                                                {createCountryDropdown(COUNTRY, this.country, null, false, this.props.userData.address !== undefined ? this.props.userData.address.country : undefined)}
                                            </Form.Group>
                                        </form>
                                    </Form.Group>
                                }
                            </td>
                            <td className="control">
                                <EditButton state={this.state.edit_address}
                                            onClick={() => { this.changeEditStatus(ADDRESS, this.state.edit_address) } } />
                            </td>
                        </tr>
                        <tr className="email-container">
                            <td className="label">{getLanguageEntry("general>email")}</td>
                            <td className="data">
                                {
                                    this.state.edit_email === 0 && this.props.userData.email
                                }
                                {
                                    this.state.edit_email > 0 &&
                                    <div>
                                        <span>{getLanguageEntry("profile_menu>profile_overview>change_email_description")}</span>
                                        <button className="link-button">{getLanguageEntry("general>contact_support")}</button>
                                    </div>
                                }
                            </td>
                            <td className="control">
                                <EditButton state={this.state.edit_email}
                                            onClick={() => { this.changeEditStatus("email", this.state.edit_email) } } />
                            </td>
                        </tr>
                        <tr className="phone-container">
                            <td className="label">{getLanguageEntry("general>phone")}</td>
                            <td className="data">
                                {
                                    this.state.edit_phone === 0 &&
                                    (this.props.userData.phone === undefined ?
                                        '-' : this.props.userData.phone)
                                }
                                {
                                    this.state.edit_phone > 0 &&
                                    <PhoneInputField name={PHONE} ref={this.phone}
                                                     defaultValue={this.props.userData.phone ?? ""} />
                                }
                            </td>
                            <td className="control">
                                <EditButton state={this.state.edit_phone}
                                            onClick={() => { this.changeEditStatus(PHONE, this.state.edit_phone) } } />
                            </td>
                        </tr>
                        <tr className="language-container">
                            <td className="label">{getLanguageEntry("general>languages")}</td>
                            <td className="data">
                                {
                                    this.state.edit_languages === 0 &&
                                    (!this.props.userData.languages || this.props.userData.languages.length === 0 ? "-" :
                                        getLanguageNameList(this.props.userData.languages).join(", "))
                                }
                                {
                                    this.state.edit_languages > 0 &&
                                    createMultiLanguageDropdown("languages", this.languageSelector, null, this.props.userData.languages)
                                }
                            </td>
                            <td className="control">
                                <EditButton state={this.state.edit_languages}
                                            onClick={() => { this.changeEditStatus("languages", this.state.edit_languages) } } />
                            </td>
                        </tr>
                        {
                            this.props.mode === Mode.landlord &&
                            <tr className="tax-id-container">
                                <td className="label">{getLanguageEntry("general>tax_id")}</td>
                                <td className="data">
                                    {
                                        this.state.edit_tax_id === 0 &&
                                        (this.props.userData.tax_id === undefined ?
                                            "-" : this.props.userData.tax_id)
                                    }
                                    {
                                        this.state.edit_tax_id > 0 &&
                                        <input type="text" ref={this.tax_id}
                                               defaultValue={this.props.userData.tax_id === undefined ? "" : this.props.userData.tax_id}/>
                                    }
                                </td>
                                <td className="control">
                                    <EditButton state={this.state.edit_tax_id}
                                                onClick={() => { this.changeEditStatus(TAX_ID, this.state.edit_tax_id) } } />
                                </td>
                            </tr>
                        }
                        {
                            this.props.mode === Mode.landlord && this.props.userData.b2b &&
                            <tr className="tax-country-container">
                                <td className="label">{getLanguageEntry("general>tax_country")}</td>
                                <td className="data">
                                    {
                                        this.state.edit_tax_country === 0 &&
                                        (this.props.userData.tax_country === undefined ?
                                            "-" : this.props.userData.tax_country)
                                    }
                                    {
                                        this.state.edit_tax_country > 0 &&
                                        createCountryDropdown(TAX_COUNTRY, this.tax_country, null, true, this.props.userData.tax_country)
                                    }
                                </td>
                                <td className="control">
                                    <EditButton state={this.state.edit_tax_country}
                                                onClick={() => { this.changeEditStatus("tax_country", this.state.edit_tax_country) } } />
                                </td>
                            </tr>
                        }
                        {
                            this.props.mode === Mode.landlord && this.props.userData.b2b &&
                            <tr className="vat-id-container">
                                <td className="label">{getLanguageEntry("general>vat_id")}</td>
                                <td className="data">
                                    {
                                        this.state.edit_vat_id === 0 &&
                                        (this.props.userData.vat_id === undefined ?
                                            "-" : this.props.userData.vat_id)
                                    }
                                    {
                                        this.state.edit_vat_id > 0 &&
                                        <input type="text" ref={this.vat_id}
                                               defaultValue={this.props.userData.vat_id === undefined ? "" : this.props.userData.vat_id}/>
                                    }
                                </td>
                                <td className="control">
                                    <EditButton state={this.state.edit_vat_id}
                                                onClick={() => { this.changeEditStatus(VAT_ID, this.state.edit_vat_id) } } />
                                </td>
                            </tr>
                        }
                        {
                            this.props.mode === Mode.landlord && this.props.userData.b2b &&
                            <tr className="operating-countries-container">
                                <td className="label">{getLanguageEntry("general>operating_countries")}</td>
                                <td className="data">
                                    {
                                        this.state.edit_operating_countries === 0 &&
                                        (this.props.userData.operating_countries === undefined ?
                                            "-" : this.props.userData.operating_countries.join(", "))
                                    }
                                    {
                                        this.state.edit_operating_countries > 0 &&
                                        createMultiCountryDropdown(OPERATING_COUNTRIES, this.operating_countries, null, this.props.userData.operating_countries)
                                    }
                                </td>
                                <td className="control">
                                    <EditButton state={this.state.edit_operating_countries}
                                                onClick={() => { this.changeEditStatus(OPERATING_COUNTRIES, this.state.edit_operating_countries) } } />
                                </td>
                            </tr>
                        }
                        <tr className="about-me-container">
                            <td className="label">{getLanguageEntry("general>about_" + (this.props.userData.b2b ? "us" : "me"))}</td>
                            <td className="data">
                                {
                                    this.state.edit_about_me === 0 &&
                                    (this.props.userData.about_me === undefined ?
                                        "-" : activateBreakLines(this.props.userData.about_me))
                                }
                                {
                                    this.state.edit_about_me > 0 &&
                                    <TextArea name={ABOUT_ME} ref={this.about_me} rows={5} cols={15}
                                              maxLength={MAX_CHARS_ABOUT_ME}
                                              initialValue={this.props.userData.about_me === undefined ? "" : this.props.userData.about_me.replaceAll("<br>", "\n")}/>
                                }
                            </td>
                            <td className="control">
                                <EditButton state={this.state.edit_about_me}
                                            onClick={() => { this.changeEditStatus(ABOUT_ME, this.state.edit_about_me) } } />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <Modal id="avatar-editor" show={this.state.show_avatar_editor} dialogClassName="max-content"
                       backdrop={true} size="lg" centered>
                    <Modal.Header>
                        <h4>{getLanguageEntry("profile_menu>profile_overview>update_avatar")}</h4>
                        <button onClick={() => {this.hideAvatarEditor()}}
                                disabled={this.state.deleting_avatar || this.state.saving_avatar}>
                            <FontAwesomeIcon icon={["fal", "close"]}/>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="avatar-box">
                            <img src={this.props.userData.avatar !== undefined ? this.props.userData.avatar.image : ""} alt="avatar" />
                            <div className="overlay" ref={this.overlay} 
                                 onMouseDown={(e) => { this.startCutoutDrag(e) }}
                                 onMouseUp={() => { this.stopCutoutDrag() }}
                                 onMouseLeave={() => { this.stopCutoutDrag() }}
                                 onMouseMove={(e) => { this.moveCutout(e) }}></div>
                        </div>
                        <div className="zoom-box">
                            <button onClick={() => { this.changeRadius(this.state.radius - 5, true)} }>
                                <FontAwesomeIcon icon={["fal", "minus"]}/>
                            </button>
                            <CustomSlider min={20} max={this.state.max_slider_value} value={this.state.radius}
                                          onChange={(r) => { this.changeRadius(r) }} />
                            <button onClick={() => { this.changeRadius(this.state.radius + 5, true)} }>
                                <FontAwesomeIcon icon={["fal", "plus"]}/>
                            </button>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <FetchButton className="outline-button black" loading={this.state.deleting_avatar}
                                     loadingText={"general>deleting"} disabled={this.state.saving_avatar}
                                     onClick={() => { this.deleteAvatar() }}>
                            <FontAwesomeIcon icon={["fal", "trash-alt"]} />
                            <span>{getLanguageEntry("general>delete")}</span>
                        </FetchButton>
                        <button className="outline-button black" onClick={() => {this.hideAvatarEditor()}}
                                disabled={this.state.deleting_avatar || this.state.saving_avatar}>
                            <FontAwesomeIcon icon={["fal", "times-circle"]} />
                            <span>{getLanguageEntry("general>abort")}</span>
                        </button>
                        <FetchButton id="save-avatar-button" className="outline-button filled accent"
                                     loading={this.state.saving_avatar} loadingText={"general>saving"}
                                     disabled={this.state.deleting_avatar} onClick={() => { this.saveAvatar() }}>
                            <FontAwesomeIcon icon={["fal", "save"]} />
                            <span>{getLanguageEntry("general>save")}</span>
                        </FetchButton>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.show_alert}  dialogClassName="max-content" centered>
                    <Modal.Header>
                        <h4>{getLanguageEntry("general>error")}</h4>
                        <button onClick={() => {this.setState({show_alert: false})}}>
                            <FontAwesomeIcon icon={["fal", "close"]}/>
                        </button>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="description-container">{getLanguageEntry(this.state.alert_content)}</div>
                    </Modal.Body>

                    <Modal.Footer>
                        <button className="outline-button accent" onClick={() => {this.setState({show_alert: false})}}>OK</button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

    changeEditStatus(attribute, edit) {
        let that = this;
        let state = {};
        if (edit === 0) {
            state["edit_" + attribute] = (edit + 1) % 2;
            this.setState(state);
            return;
        }
        if (edit === 1) {
            let update = {};
            switch (attribute) {
                case BIRTHDAY:
                    if (this.birthday.current !== null) {
                        let bDay = this.birthday.current.value;
                        if (bDay === null && this.props.userData.birthday !== undefined) {
                            update.birthday = "null";
                        }
                        else if (bDay !== this.props.userData.birthday) {
                            update.birthday = bDay;
                        }
                    }
                    break;
                case COMPANY:
                    if (this.company.current !== null) {
                        let company = this.company.current.value;
                        if (company.length === 0 && this.props.userData.company !== undefined) {
                            update.company = "null";
                        }
                        else if (company !== this.props.userData.company) {
                            update.company = company;
                        }
                    }
                    break;
                case COMPANY_REG_NO:
                    if (this.company_reg_no.current !== null) {
                        let companyRegNo = this.company_reg_no.current.value;
                        if (companyRegNo.length === 0 && this.props.userData.company_reg_no !== undefined) {
                            update.company_reg_no = "null";
                        }
                        else if (companyRegNo !== this.props.userData.company_reg_no) {
                            update.company_reg_no = companyRegNo;
                        }
                    }
                    break;
                case PREFERRED_MENU_MODE:
                    if (this.mode_selector.current !== null) {
                        let mode = this.mode_selector.current.value;
                        if (mode !== this.props.userData.preferred_menu_mode) {
                            update.preferred_menu_mode = mode;
                        }
                    }
                    break;
                case ADDRESS:
                    if (this.address.current !== null) {
                        let address = {};
                        let addressData = new FormData(this.address.current);
                        // update attributes if deleted now and given in the actual address data
                        // or if not the same as in the actual address data
                        if (addressData.get(LINE_1).length > 0) {
                            address.line_1 = addressData.get(LINE_1);
                        }
                        if (addressData.get(POSTAL_CODE).length > 0) {
                            address.postal_code = addressData.get(POSTAL_CODE);
                        }
                        if (addressData.get(CITY).length > 0) {
                            address.city = addressData.get(CITY);
                        }
                        if (addressData.get(COUNTRY).length > 0) {
                            address.country = addressData.get(COUNTRY);
                        }
                        update.address = Object.keys(address).length === 0 ? 'null' : address;
                    }
                    break;
                case PHONE:
                    if (this.phone.current !== null) {
                        let phone = this.phone.current.value;
                        if (phone.length === 0 && this.props.userData.phone !== undefined) {
                            update.phone = "null";
                        }
                        else if (phone !== this.props.userData.phone) {
                            update.phone = phone;
                        }
                    }
                    break;
                case "languages":
                    if (this.languageSelector.current !== null) {
                        let languages = this.languageSelector.current.value;
                        if (languages.length === 0 && this.props.userData.languages) {
                            update.languages = "null";
                        }
                        else if (languages.length > 0) {
                            if (!this.props.userData.languages) {
                                update.languages = languages;
                            }
                            else {
                                if (languages.sort().join(',') !== this.props.userData.languages.sort().join(',')) {
                                    update.languages = languages;
                                }
                            }
                        }
                    }
                    break;
                case TAX_ID:
                    if (this.tax_id.current !== null) {
                        let taxID = this.tax_id.current.value;
                        if (taxID.length === 0 && this.props.userData.tax_id !== undefined) {
                            update.tax_id = "null";
                        }
                        else if (taxID !== this.props.userData.tax_id) {
                            update.tax_id = taxID;
                        }
                    }
                    break;
                case TAX_COUNTRY:
                    if (this.tax_country.current !== null) {
                        let taxCountry = this.tax_country.current.value;
                        if ((taxCountry === null || taxCountry === 'none') &&
                            this.props.userData.tax_country !== undefined) {
                            update.tax_country = "null";
                        }
                        else if (taxCountry !== this.props.userData.tax_country) {
                            update.tax_country = taxCountry;
                        }
                    }
                    break;
                case VAT_ID:
                    if (this.vat_id.current !== null) {
                        let vatID = this.vat_id.current.value;
                        if (vatID.length === 0 && this.props.userData.vat_id !== undefined) {
                            update.vat_id = "null";
                        }
                        else if (vatID !== this.props.userData.vat_id) {
                            update.vat_id = vatID;
                        }
                    }
                    break;
                case OPERATING_COUNTRIES:
                    if (this.operating_countries.current !== null) {
                        let countries = this.operating_countries.current.value;
                        if (countries.length === 0 && this.props.userData.operating_countries !== undefined) {
                            update.operating_countries = "null";
                        }
                        else if (countries.length > 0) {
                            if (this.props.userData.operating_countries === undefined) {
                                update.operating_countries = countries;
                            }
                            else {
                                if (countries.sort().join(',') !== this.props.userData.operating_countries.sort().join(',')) {
                                    update.operating_countries = countries;
                                }
                            }
                        }
                    }
                    break;
                case ABOUT_ME:
                    if (this.about_me.current !== null) {
                        let aboutMe = this.about_me.current.value.replaceAll("\n", "<br>");
                        if (aboutMe.length === 0 && this.props.userData.about_me !== undefined) {
                            update.about_me = "null";
                        }
                        else if (aboutMe !== this.props.userData.about_me) {
                            update.about_me = aboutMe;
                        }
                    }
                    break;
                default:
                    break;
            }
            if (Object.keys(update).length > 0) {
                state["edit_" + attribute] = 2;
                this.setState(state);
                updateUser(this.props.userData.id, update, function(response) {
                    state["edit_" + attribute] = 0;
                    if (response.errorCode !== undefined) {
                        state.show_alert = true;
                        state.alert_content = "error_codes>" + response.errorCode;
                        that.setState(state);
                    }
                    else {
                        that.setState(state);
                        that.props.onUpdateUserSession?.(response);
                    }
                });
            }
            else {
                // no updates, just do nothing
                state["edit_" + attribute] = 0;
                this.setState(state);
            }
        }
    }

    onPlaceSelected(address_line, place) {
        let address = place.address_components;
        //region postal code
        let postalCodeElem = address.filter(ac => ac.types[0] === 'postal_code')[0];
        if (postalCodeElem !== undefined) {
            this.postal_code.current.value = postalCodeElem.long_name;
        }
        else {
            this.postal_code.current.value = '';
        }
        //endregion
        //region city
        let locationElem = address.filter(ac => ac.types[0] === 'locality')[0];
        if (locationElem !== undefined) {
            this.city.current.value = locationElem.long_name;
        }
        else {
            this.city.current.value = '';
        }
        //endregion
        //region country
        let countryElem = address.filter(ac => ac.types[0] === 'country')[0];
        if (countryElem !== undefined) {
            this.country.current.value = countryElem.short_name;
        }
        else {
            this.country.current.value = null;
        }
        //endregion
    }

    uploadAvatar(avatarData, callback=null) {
        let that = this;
        let update = { avatar: avatarData };
        updateUser(this.props.userData.id, update, function(response) {
            if (response.errorCode !== undefined) {
                console.error(response.errorCode + ": " + response.message);
                that.setState({
                    saving_avatar: false,
                    deleting_avatar: false
                });
            }
            else {
                that.props.onUpdateUserSession?.(response, () => {
                    if (callback) {
                        callback();
                    }
                    else {
                        that.setState({
                            saving_avatar: false,
                            deleting_avatar: false
                        });
                    }
                });
            }
        })
    }

    showAvatarEditor() {
        let that = this;
        this.setState({ show_avatar_editor: true }, () => {
            let avatar = that.props.userData.avatar;
            if (this.overlay.current !== null && avatar !== undefined) {
                that.setState({
                    radius: that.overlay.current.offsetWidth / (avatar.zoom * 2),
                    max_slider_value: Math.min(that.overlay.current.offsetWidth, that.overlay.current.offsetHeight) / 2
                }, () => {
                    that.x = that.state.radius - avatar.x * that.overlay.current.offsetWidth / avatar.zoom;
                    that.y = that.state.radius - avatar.y * that.overlay.current.offsetWidth / avatar.zoom;
                    that.adjustAvatarEditorOverlay(that.x, that.y);
                });
            }
        });
    }

    hideAvatarEditor() {
        this.setState({ show_avatar_editor: false });
    }

    changeRadius(radius) {
        radius = Math.min(this.state.max_slider_value, Math.max(20, radius));
        this.setState({radius: radius}, () => {
            this.adjustAvatarEditorOverlay(this.x, this.y);
        })
    }

    adjustAvatarEditorOverlay(x, y) {
        if (this.overlay.current === null) {
            return;
        }
        this.x = Math.max(this.state.radius, Math.min(this.overlay.current.offsetWidth - this.state.radius, x));
        this.y = Math.max(this.state.radius, Math.min(this.overlay.current.offsetHeight - this.state.radius, y));
        let css = 'radial-gradient(circle ' + this.state.radius + 'px at ' + this.x + 'px ' + this.y +
            'px, transparent 100%, white 100.01%)';
        this.overlay.current.style.setProperty('background', css);
    }

    startCutoutDrag(e) {
        this.lastDragPosition = getMousePositionOnElement(e, this.overlay.current);
        this.drag = true;
    }

    moveCutout(e) {
        if (this.drag) {
            let newDragPosition = getMousePositionOnElement(e, this.overlay.current);
            let xOffset = newDragPosition.x - this.lastDragPosition.x;
            let yOffset = newDragPosition.y - this.lastDragPosition.y;
            this.lastDragPosition = newDragPosition;
            this.adjustAvatarEditorOverlay(this.x + xOffset, this.y + yOffset);
        }
    }

    stopCutoutDrag() {
        this.lastDragPosition = null;
        this.drag = false;
    }

    saveAvatar() {
        let zoom = this.overlay.current.offsetWidth / (this.state.radius * 2);
        let avatarData = {
            image: this.props.userData.avatar.image,
            x: (this.state.radius - this.x) * zoom / this.overlay.current.offsetWidth,
            y: (this.state.radius - this.y) * zoom / this.overlay.current.offsetWidth,
            zoom: zoom
        }
        this.setState({saving_avatar: true});
        this.uploadAvatar(avatarData, () => {
            this.setState({
                show_avatar_editor: false,
                saving_avatar: false
            });
        });
    }

    deleteAvatar() {
        this.setState({deleting_avatar: true});
        this.uploadAvatar('null', () => {
            this.setState({
                show_avatar_editor: false,
                saving_avatar: false,
                deleting_avatar: false
            });
        });
    }

}
ProfileOverview.propTypes = {
    onUpdateUserSession: PropTypes.func.isRequired,
    userData: PropTypes.object,
    mode: PropTypes.string.isRequired
}
export default ProfileOverview;