import React from "react";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import {getLanguageEntry, megaByteToByte} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _uniqueId from "lodash/uniqueId";
import {Mode} from "../../utils/Types.ts";
import "../../../css/components/modals/image-upload-modal.css";
import ImageUploader from "../input/ImageUploader";

const LANG_PATH = "components>image_upload_modal>"

class ImageUploadModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            images: [],
            on_save: null,
            sort_mode: false,
            title: null,
            selected_image: -1,
            sort_map: [],
            image_count: 5
        }
    }

    render() {
        return <Modal className={"image-upload-modal " + this.props.mode}
                      show={this.state.show} onHide={() => {this.hide()}}
                      dialogClassName="max-content" size="xl" centered>
            <Modal.Header>
                {
                    <div>
                        <h4>{this.state.title}</h4>
                        <div className="description-container">
                            {getLanguageEntry(LANG_PATH + "modal_file_count_info")
                                .replace("#", "" + this.state.image_count)}
                        </div>
                    </div>
                }
                <button onClick={() => { this.hide() }}>
                    <FontAwesomeIcon icon={["fal", "close"]}/>
                </button>
            </Modal.Header>
            <Modal.Body>
                <ImageUploader maxFileSize={megaByteToByte(5)} disabled={this.state.sort_mode}
                               dropFileCount={this.state.image_count}
                               onAddImage={(images) => {
                                   this.setState({
                                   images: images,
                                   selected_image: images.length - 1
                               })}}
                               images={this.state.images} showImage={this.state.selected_image}/>
                {/*<div id="image-modal-error-field"></div>*/}
                {
                    this.state.images.length > 0 &&
                    <div className="image-preview-row">
                        {
                            this.state.images.map((img, i) => {
                                return <div className="imagePreviewContainer" key={_uniqueId("image-preview-" + i)}>
                                    <div className="imageTitle">
                                        {i === 0 && getLanguageEntry(`${LANG_PATH}cover_image`)}
                                    </div>
                                    <div className={"image-preview" + (this.state.sort_mode ? " sort" : "") + (i === 0 ? " title" : "")}
                                         onClick={() => {this.onSort(i)}}>
                                        <img src={img} alt={"image-" + i}/>
                                        {
                                            !this.state.sort_mode && this.state.selected_image !== i &&
                                            <button className="simple-icon-button show"
                                                    onClick={() => {this.setState({selected_image: i})}}>
                                                <FontAwesomeIcon icon={["fal", "magnifying-glass"]}/>
                                            </button>
                                        }
                                        {
                                            !this.state.sort_mode &&
                                            <button className="simple-icon-button delete" onClick={() => {
                                                let images = this.state.images;
                                                images.splice(i, 1);
                                                this.setState({
                                                    images: images,
                                                    selected_image: Math.min(i, images.length - 1)
                                                })}}>
                                                <FontAwesomeIcon icon={["fal", "trash"]}/>
                                            </button>
                                        }
                                        {
                                            this.state.sort_mode && this.state.sort_map[i] > -1 &&
                                            <div className="sort-label">{this.state.sort_map[i] + 1}</div>
                                        }
                                    </div>
                                </div>
                            })
                        }
                        {
                            this.state.images.length < this.state.image_count && !this.state.sort_mode &&
                            this.state.selected_image !== -1 &&
                            <button className={"image-preview adder"} onClick={() => {
                                if (this.state.selected_image !== -1) {
                                    this.setState({selected_image: -1});
                                }
                            }}>
                                <FontAwesomeIcon icon={["fal", "plus"]} />
                            </button>
                        }
                    </div>
                }
            </Modal.Body>
            {
                this.state.sort_mode &&
                <Modal.Footer>
                    <div className="description-container">
                        {getLanguageEntry("components>image_upload_modal>sort_images_description")}
                    </div>
                    <button className="outline-button accent" onClick={() => {this.saveSorting()}}>
                        <FontAwesomeIcon icon={["fal", "check"]}/>
                        {getLanguageEntry("general>done")}
                    </button>
                </Modal.Footer>

            }
            {
                !this.state.sort_mode &&
                <Modal.Footer>
                    <div className="roomjack-headline">
                        {getLanguageEntry(LANG_PATH + "actual_image_count")
                            .replace("{x}", this.state.images.length)
                            .replace("{y}", this.state.image_count)
                        }
                    </div>
                    {
                        this.state.images.length > 0 &&
                        <button className="outline-button black"
                                onClick={() => {this.activateSortMode();}}>
                            <FontAwesomeIcon icon={["fal", "arrow-down-1-9"]}/>
                            {getLanguageEntry(LANG_PATH + "sort_images")}
                        </button>
                    }
                    <button className="outline-button accent" onClick={() => {
                        this.state.on_save?.(this.state.images);
                        this.hide();
                    }}>
                        <FontAwesomeIcon icon={["fal", "check"]}/>
                        {getLanguageEntry("general>done")}
                    </button>
                </Modal.Footer>
            }
        </Modal>
    }

    show(images, onSave, title, imageCount=5) {
        this.setState({
            show: true,
            images: images ?? [],
            on_save: onSave,
            sort_mode: false,
            title: title ?? getLanguageEntry("general>images"),
            selected_image: images && images.length > 0 ? images.length - 1 : -1,
            sort_map: [],
            image_count: imageCount
        });
    }

    hide() {
        this.setState({
            show: false,
            images: [],
            on_save: null,
            sort_mode: false,
            title: null,
            selected_image: -1,
            sort_map: []
        });
    }

    activateSortMode() {
        if (!this.state.images || this.state.images.length === 0) {
            return;
        }
        let sortMap = this.state.images.map(() => -1);
        this.setState({
            sort_mode: true,
            sort_map: sortMap,
            selected_image: this.state.images.length - 1
        })
    }

    saveSorting() {
        if (!this.state.images || this.state.images.length === 0 ||
            !this.state.sort_mode) {
            return;
        }
        let sortMap = this.state.sort_map;
        let max = Math.max(...sortMap);
        // handle not assigned slots
        for (let i=0; i<sortMap.length; i++) {
            if (sortMap[i] < 0) {
                sortMap[i] = ++max;
            }
        }
        let sortedImages = this.state.images.slice(0);
        for (let i=0; i<sortMap.length; i++) {
            sortedImages[sortMap[i]] = this.state.images[i];
        }
        this.setState({
            sort_map: [],
            sort_mode: false,
            images: sortedImages,
            selected_image: sortedImages.length - 1
        })
    }

    onSort(buttonIndex) {
        if (this.state.sort_mode) {
            let sortMap = this.state.sort_map;
            let actualValue = sortMap[buttonIndex];
            if (actualValue === -1) {
                sortMap[buttonIndex] = Math.max(...sortMap) + 1;
            }
            else {
                for (let i=0; i<sortMap.length; i++) {
                    if (sortMap[i] === actualValue) {
                        sortMap[i] = -1;
                    }
                    else if (sortMap[i] > actualValue) {
                        sortMap[i] -= 1;
                    }
                }
            }
            this.setState({sort_map: sortMap});
        }
    }

}

ImageUploadModal.propTypes = {
    mode: PropTypes.oneOf(Object.values(Mode))
}
ImageUploadModal.defaultProps = {
    mode: Mode.landlord
}
export default ImageUploadModal;