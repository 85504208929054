import PropTypes from "prop-types";
import {OverlayView} from "@react-google-maps/api";
import "../../../css/components/google/advert-marker.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {collectHousingListAttributes} from "../../utils/Helper";

function AdvertMarker(props) {

    function calculatePerimeterSize() {
        let size = 60 * Math.pow(2, (props.zoom - 14));
        return { width: size + 'px', height: size + 'px'}
    }

    if (props.advert.address === undefined || props.advert.address.location === undefined) {
        return <></>
    }
    else {
        if (!props.advert.address.location.distorted || props.zoom < 14) {
            let image = collectHousingListAttributes(props.advert).images[0];
            return <OverlayView position={props.advert.address.location}
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                <div className={"custom-map-marker " + (props.className !== undefined ? props.className : "")}>
                    <FontAwesomeIcon icon={["fas", "house"]}/>
                    {
                        image!== undefined &&
                        <img src={image} alt={props.advert.title}/>
                    }
                </div>
            </OverlayView>
        }
        else {
            return <OverlayView position={props.advert.address.location}
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                <div className={"custom-map-marker-perimeter " + (props.className !== undefined ? props.className : "")}
                     style={calculatePerimeterSize()}></div>
            </OverlayView>
        }
    }

}
AdvertMarker.propTypes = {
    advert: PropTypes.object.isRequired,
    content: PropTypes.string.isRequired,
    zoom: PropTypes.number.isRequired,
    className: PropTypes.string
}
export default AdvertMarker;