import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _uniqueId from 'lodash/uniqueId';
import "../../../css/components/input/dropdown.css"
import PropTypes from "prop-types";
import {getLanguageEntry, truncateText} from "../../utils/Helper";

class Dropdown extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected_item: this.props.initialValue ?? null,
            show: false,
            last_search: 0,
            search_input: ''
        }
        this.dropdown = React.createRef();
        this.dropdown_toggle = React.createRef();
        this.dropdown_menu = React.createRef();
        this.dropdownID =  _uniqueId("dr_id-");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.initialValue !== this.props.initialValue) {
            this.setState({selected_item: this.props.initialValue ?? null})
        }
    }

    render() {
        let labelData = this.getLabelData();
        return(
            <div className={"custom-dropdown" + (this.props.invalid ? " invalid" : "")}
                 ref={this.dropdown} tabIndex="0" style={this.props.style}
                 onKeyUp={(e) => {this.handleSearch(e)}}
                 onBlur={(e) => { this.handleFocus(e) }}>
                <button className={(this.props.disabled ? "disabled " : "") + "dropdown-toggle"}
                        id={this.props.id} onClick={(e) => { this.toggle(e) }}
                        ref={this.dropdown_toggle} disabled={this.props.disabled}>
                    {
                        this.props.icon !== undefined &&
                        <FontAwesomeIcon className="decoration" icon={this.props.icon} />
                    }
                    {this.getColor()}
                    <span className={labelData.className}>{labelData.selected_label ?? labelData.label}</span>
                    <FontAwesomeIcon className="arrow" icon={this.state.show ? ["fal", "chevron-up"] : ["fal", "chevron-down"]}/>
                </button>
                <input className="dropdown-input" value={this.value !== null && this.value !== undefined ? this.value : ''} name={this.props.name}
                       required={this.props.required} onChange={() => {}} />
                {
                    this.state.show && this.props.items !== undefined && <div className="dropdown-menu"
                                                                              ref={this.dropdown_menu}>
                        {this.props.items.map((item, index) => {
                            let id = this.dropdownID + "-" + index;
                            return <div className="dropdown-item" key={id} onClick={(e) => { this.selectItem(e, item.value) }}>
                                <input type="radio" value={item.value !== null && item.value !== undefined ? item.value : ''}
                                       className="dropdown-item" id={id}
                                       name={this.props.name}/>
                                <label className={this.isSelected(item.value) ? 'checked' : ''} htmlFor={id}>
                                    <div className={"icon-box" + (item.color ? " color" : "")}
                                         style={{backgroundColor: item.color ?? "transparent"}}>
                                        <FontAwesomeIcon icon={["fas", "check"]} />
                                    </div>
                                    <span>
                                        {
                                            typeof item.label === "function" ? item.label() :
                                            truncateText(getLanguageEntry(item.label), 50)
                                        }
                                    </span>
                                </label>
                            </div>
                        })}
                    </div>
                }
            </div>
        )
    }

    onShow() {
        if (this.props.disabled) {
            return;
        }
        this.setState({ show: true });
    }

    onHide() {
        if (this.props.disabled) {
            return;
        }
        this.setState({ show: false });
    }

    get value() {
        return this.state.selected_item;
    }

    set value(value) {
        if (value === null) {
            this.setState({selected_item: null});
            return;
        }
        let item = this.props.items.filter(i => i.value.toLowerCase() === value.toLowerCase())[0];
        if (item) {
            this.setState({ selected_item: item.value });
        }
    }

    toggle(e) {
        e.preventDefault();
        if (this.props.disabled) {
            return;
        }
        if (this.state.show) {
            this.onHide();
        }
        else {
            this.onShow();
        }
    }

    getLabelData() {
        let selectedItem = this.state.selected_item === null || this.state.selected_item === undefined ?
            null : this.props.items.filter(i => this.isSelected(i.value))[0];
        return {
            className: selectedItem === null || selectedItem === undefined  ? 'placeholder' : '',
            label: selectedItem === null || selectedItem === undefined  ? getLanguageEntry(this.props.placeholder) :
                typeof selectedItem.label === "function" ?
                    selectedItem.label() : truncateText(getLanguageEntry(selectedItem.label), 50),
            selected_label: selectedItem === null || selectedItem === undefined || !selectedItem.selected_label ? null :
                (typeof selectedItem.selected_label === "function" ?
                    selectedItem.selected_label() : truncateText(getLanguageEntry(selectedItem.selected_label), 50))
        };
    }

    getColor() {
        let selectedItem = !this.state.selected_item ?
            null : this.props.items.filter(i => i.value === this.state.selected_item)[0];
        return selectedItem && selectedItem.color ?
            <div className="color-box" style={{backgroundColor: selectedItem.color}}></div> : null;
    }

    handleFocus(e) {
        if (!this.dropdown.current.contains(e.relatedTarget)) {
            this.onHide();
        }
    }

    selectItem(e, value) {
        let that = this;
        e.preventDefault();
        if (this.state.selected_item !== value) {
            this.setState({ selected_item: value }, () => {
                that.props.onChange?.(e, value);
            });
        }
        else if (this.props.allowDeselect) {
            this.setState({ selected_item: null }, () => {
                that.props.onChange?.(e, null);
            });
        }
        this.onHide();
        this.dropdown_toggle.current.classList.remove("invalid");
    }

    setInvalid() {
        this.dropdown_toggle.current.classList.add("invalid");
    }

    isSelected(item) {
        if (this.props.comparator) {
            return this.props.comparator(this.state.selected_item, item)
        }
        return item === this.state.selected_item;
    }

    handleSearch(e) {
        if (!this.state.show) return;
        let searchInput = this.state.search_input;
        if (Date.now() > this.state.last_search + 1000) {
            searchInput = '';
        }
        searchInput += e.key;
        this.setState({
            search_input: searchInput,
            last_search: Date.now()
        });
        if (searchInput.length > 1) {
            // trigger the actual search
            let children = Array.prototype.slice.call(this.dropdown_menu.current.children);
            let item = children.filter(i => i.textContent.toLowerCase().includes(searchInput))[0];
            if (item) {
                this.dropdown_menu.current.scrollTo({
                    top: item.offsetTop,
                    behavior: 'smooth',
                    block: 'start',
                })
            }
        }
    }

}
Dropdown.propTypes = {
    id: PropTypes.string,
    initialValue: PropTypes.any,
    placeholder: PropTypes.string,
    icon: PropTypes.arrayOf(PropTypes.string),
    items: PropTypes.arrayOf(PropTypes.exact({
        value: PropTypes.any,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        selected_label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        color: PropTypes.string
    })).isRequired,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    comparator: PropTypes.func,
    style: PropTypes.object,
    invalid: PropTypes.bool,
    searchable: PropTypes.bool,
    allowDeselect: PropTypes.bool
}
Dropdown.defaultProps = {
    placeholder: 'general>default_dropdown_placeholder',
    disabled: false,
    required: false,
    searchable: true
}
export default Dropdown;