import React from "react";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import {getLanguageEntry} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FetchButton from "../buttons/FetchButton";
import parse from "html-react-parser";
import {uniqueId} from "lodash";
import {setPublicationStatus} from "../../utils/RESTInterface";
import NavigatorButton from "../buttons/NavigatorButton";

class AdvertPublicationModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            loading: false,
            response: null
        }
    }

    render() {
        return (<Modal className={"advert-publication-modal landlord"} show={this.state.show} size="lg" backdrop="static"
                       keyboard={false} onHide={() => { this.hide() }} dialogClassName="max-content" centered>
            <Modal.Header>
                <h4>{getLanguageEntry("processes>advert_publication>" +
                    (this.state.advert && this.state.advert.published ? "deactivate" : "publish") + "_title")}</h4>
                <button onClick={() => { this.hide() }} disabled={this.state.loading}>
                    <FontAwesomeIcon icon={["fal", "close"]}/>
                </button>
            </Modal.Header>

            <Modal.Body style={{display: "grid", gap: "20px" }}>
                {
                    this.state.advert && !this.state.response &&
                    <div className="description-container">
                        {
                            getLanguageEntry("processes>advert_publication>" +
                                (this.state.advert.published ? "deactivate" : "publish") + "_description")
                        }
                    </div>
                }
                {
                    this.state.advert && this.state.response &&
                    <div className="process-modal-result-container">
                        <div className="process-result-image">
                            <img src={this.state.response.errorCode ?
                                "https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear.png" :
                                "https://roomjack.blob.core.windows.net/roomjack/content-images/High-Five.gif"}
                                 alt={"Advert publication " + (this.state.response.errorCode ? "failure" : "success")} />
                        </div>
                        <div className="process-result-message-box">
                            <div className="header">
                                {this.getResultHeader()}
                            </div>
                            {
                                this.state.response.errorCode && this.getPublicationError()
                            }
                        </div>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                {
                    this.state.advert && !this.state.response &&
                    <FetchButton loading={this.state.loading} className="outline-button accent"
                                 loadingText="general>loading" onClick={() => {
                                    this.triggerPublicationChange();
                                 }}>
                        {
                            getLanguageEntry("processes>advert_publication>" +
                                (this.state.advert.published ? "deactivate_" : "publish_") + "advert")
                        }
                    </FetchButton>
                }
                {
                    this.state.advert && this.state.response && this.state.response.errorCode &&
                    this.getFooterButton()
                }
            </Modal.Footer>
        </Modal>)
    }

    show(advert) {
        this.setState({
            show: true,
            loading: false,
            response: null,
            advert: advert
        })
    }

    hide() {
        this.setState({
            show: false
        })
    }

    triggerPublicationChange() {
        if (this.state.advert) {
            this.setState({loading: true});
            let that = this;
            setPublicationStatus(this.state.advert.id, !this.state.advert.published, (response) => {
                that.setState({
                    loading: false,
                    response: response
                });
                if (response.success) {
                    that.props.onAdvertChanged?.();
                }
            })
        }
    }

    getResultHeader() {
        let path = "processes>advert_publication>";
        if (this.state.response.errorCode) {
            path += "publication_failure";
        }
        else {
            path += (this.state.response.data.published) ? "publication_success" : "deactivation_success";
        }
        return getLanguageEntry(path);
    }

    getPublicationError() {
        if (this.state.response.errorCode) {
            let response = this.state.response;
            let verified = response.verified === undefined || response.verified;
            if (response.requirements !== undefined) {
                return <div style={{textAlign: "justify"}}>
                    <div className="description-container">
                        {getLanguageEntry("processes>advert_publication>publishing_failed_requirements")}
                    </div>
                    <div className="description-container">
                        <i>{getLanguageEntry("processes>advert_publication>publishing_failed_requirements_info")}</i>
                    </div>
                </div>;
            }
            else if (!verified) {
                return <div className="description-container" style={{textAlign: "justify"}}>
                    {getLanguageEntry("processes>advert_publication>publishing_failed_verification")}
                </div>;
            }
            else if (response.withdrawal_active) {
                return <div className="description-container" style={{textAlign: "justify"}}>
                    {parse(getLanguageEntry("processes>advert_publication>publishing_failed_rp_acceptance"))}
                </div>;
            }
            else if (!response.address || !response.phone || !response.tax_id || !response.vat_id ||
                !response.tax_country || !response.company_reg_no || !response.operating_countries || !response.birthday) {
                let items = []; let path = "jackboard>adverts>errors>missing_";
                if (response.address !== undefined) {
                    items.push(<li key={uniqueId("missing_address")}>{getLanguageEntry(path + "address")}</li>);
                }
                if (response.phone !== undefined) {
                    items.push(<li key={uniqueId("missing_phone")}>{getLanguageEntry(path + "phone")}</li>);
                }
                if (response.tax_id !== undefined) {
                    items.push(<li key={uniqueId("missing_tax_id")}>{getLanguageEntry(path + "tax_id")}</li>);
                }
                if (response.vat_id !== undefined) {
                    items.push(<li key={uniqueId("missing_vat_id")}>{getLanguageEntry(path + "vat_id")}</li>);
                }
                if (response.tax_country !== undefined) {
                    items.push(<li key={uniqueId("missing_tax_country")}>{getLanguageEntry(path + "tax_country")}</li>);
                }
                if (response.company_reg_no !== undefined) {
                    items.push(<li key={uniqueId("missing_c_reg_no")}>{getLanguageEntry(path + "company_reg_no")}</li>);
                }
                if (response.operating_countries !== undefined) {
                    items.push(<li key={uniqueId("missing_op_country")}>{getLanguageEntry(path + "operating_countries")}</li>);
                }
                if (response.birthday !== undefined) {
                    items.push(<li key={uniqueId("missing_birthday")}>{getLanguageEntry(path + "birthday")}</li>);
                }
                return <div className="description-container" style={{display: "grid", gap: "10px", textAlign: "justify"}}>
                    {getLanguageEntry("processes>advert_publication>publishing_failed_missing_user_data_header")}
                    <ul className="bulleted">
                        {items.map((item, _) => item)}
                    </ul>
                    {getLanguageEntry("processes>advert_publication>publishing_failed_missing_user_data_footer")}
                </div>;
            }
            return null;
        }
    }

    getFooterButton() {
        let response = this.state.response;
        if (response.requirements) {
            return <NavigatorButton to={"/desktop/adverts/editor/general?id=" + this.state.advert.id}
                                    className="outline-button accent">
                {getLanguageEntry("processes>advert_publication>complete_advert")}
            </NavigatorButton>
        }
        else if (response.verified !== undefined && !response.verified) {
            return <NavigatorButton to="/profile/account" className="outline-button accent">
                {getLanguageEntry("processes>advert_publication>verify_now")}
            </NavigatorButton>
        }
        else if (response.withdrawal_active) {
            return <NavigatorButton to="/profile/account" className="outline-button accent">
                {getLanguageEntry("processes>advert_publication>to_my_account")}
            </NavigatorButton>
        }
        else if (!response.address|| !response.phone || !response.tax_id || !response.vat_id ||
            !response.tax_country || !response.company_reg_no || !response.operating_countries || !response.birthday) {
            return <NavigatorButton to="/profile/overview" className="outline-button accent">
                {getLanguageEntry("processes>advert_publication>to_my_profile")}
            </NavigatorButton>
        }
        return null;
    }
}

AdvertPublicationModal.propTypes = {
    onContactSupport: PropTypes.func.isRequired,
    onAdvertChanged: PropTypes.func.isRequired
}
export default AdvertPublicationModal;