import React from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "../../css/pages/404.css";
import {getLanguageEntry} from "../utils/Helper";

class PageNotFound extends React.Component {

    render() {
        return (
            <div id="four-o-four">
                <div id="four-o-four-image-container">
                    <div id="plug-left">
                        <img src="https://roomjack.blob.core.windows.net/roomjack/email-images/kabel_links.png" alt="Kabel getrennt links"/>
                    </div>
                    <div id="four-o-four-image">
                        <img src="https://roomjack.blob.core.windows.net/roomjack/content-images/bild_404.png" alt="404"/>
                    </div>
                    <div id="plug-right">
                        <img src="https://roomjack.blob.core.windows.net/roomjack/email-images/kabel_rechts.png" alt="Kabel getrennt rechts"/>
                    </div>
                </div>
                <div id="four-o-four-text-container">
                    <div id="four-o-four-message">
                        <div>{getLanguageEntry("four_o_four>title")} </div>
                        {
                            <div>{getLanguageEntry(this.props.reason)}</div>
                        }
                    </div>
                    <div className="horizontal-form-group">
                        <a id="four-o-four-home-button" className="outline-button" href="/">
                            <FontAwesomeIcon icon={["fal", "home"]} />
                            <span>{getLanguageEntry("four_o_four>button")}</span>
                        </a>
                        <button className="outline-button filled accent" onClick={() => {this.props.onContact()}}>
                            <FontAwesomeIcon icon={["fal", "feather"]} />
                            <span>{getLanguageEntry("four_o_four>contact")}</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }

}

PageNotFound.propTypes = {
    reason: PropTypes.oneOf([
        "four_o_four>page_not_found",
        "four_o_four>user_login",
        "four_o_four>booking_process_invalid",
        "four_o_four>unauthorized"
    ]),
    onContact: PropTypes.func.isRequired
}
PageNotFound.defaultProps = {
    reason: "four_o_four>page_not_found"
}
export default PageNotFound;