import {getTimeUnitInMS, TIME_UNIT_VALUE_MAP} from "../Helper.js";

class PayoutCache {

    private payouts:Array<object>;
    private account_data:Map<string, object>;
    private timestamp:number;
    private readonly lifetime = getTimeUnitInMS(30, TIME_UNIT_VALUE_MAP.MINUTE);

    constructor() {
        this.payouts = null;
        this.account_data = null;
    }

    public getPayouts() {
        if (this.payouts === null || Date.now() >= this.timestamp + this.lifetime) {
            return null;
        }
        return this.payouts;
    }

    public getAccountDataMap() {
        if (this.account_data === null || Date.now() >= this.timestamp + this.lifetime) {
            return null;
        }
        return this.account_data;
    }

    public getAccountData(destination) {
        if (this.account_data === null || Date.now() >= this.timestamp + this.lifetime ||
            !this.account_data.get(destination)) {
            return null;
        }
        return this.account_data.get(destination);
    }

    public setCache(cache) {
        if (cache) {
            this.payouts = cache.payouts ? cache.payouts : null;
            this.account_data = cache.account_data ? new Map(Object.entries(cache.account_data)) : null;
        }
        else {
            this.payouts = null;
            this.account_data = null;
        }
        this.timestamp = Date.now();
    }

    public isValid() {
        return Date.now() < this.timestamp + this.lifetime
    }

    public reset() {
        this.timestamp = 0;
        this.payouts = null;
        this.account_data = null;
    }

}
export default PayoutCache;