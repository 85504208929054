import {loadStripe} from "@stripe/stripe-js";
// @ts-ignore
import {PAYMENT_INTENT_CACHE} from "./CacheHandler.ts";
// @ts-ignore
import CacheObject from "./cache/objects/CacheObject.ts";
import {STRIPE_PUBLIC_KEY} from "../../resources/version-dependent-variables";

export let STRIPE;

export function initStripe() {
    loadStripe(STRIPE_PUBLIC_KEY)
        .then(result => {
            STRIPE = result;
        }).catch(error => {
        console.log(error)
    });
}

export function getConnectStripe(connectAccountID) {
    return loadStripe(STRIPE_PUBLIC_KEY, { stripeAccount: connectAccountID })
}

export function getPaymentIntent(clientSecret, callback) {
    STRIPE.retrievePaymentIntent(clientSecret)
        .then(result => {
            if (result.paymentIntent) {
                PAYMENT_INTENT_CACHE.addCacheObject(result.paymentIntent.id, new CacheObject(result.paymentIntent));
                callback?.(result.paymentIntent);
            }
        })
        .catch(error => {
            console.log(error);
        });
}