import React from "react";
import PropTypes from "prop-types";
import {AdvertEditorTab} from "../../../../../utils/Types.ts";
import AdvertEditorBlock from "../../../../../components/advert/AdvertEditorBlock";
import AdvertHierarchy from "../../../../../components/advert/AdvertHierarchy";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ImageUploadModal from "../../../../../components/modals/ImageUploadModal";
import { getLanguageEntry} from "../../../../../utils/Helper";
import {Link} from "react-router-dom";

const LANG_PATH = "jackboard>adverts>advert_creator>";
const ADVERT_IMAGE_LIMIT = 10;
const ROOM_IMAGE_LIMIT = 5;

class ImagesTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            edited_advert: JSON.parse(JSON.stringify(props.advert))
        }
        this.image_modal = React.createRef();
    }

    extractData() {
        let newAdvert = {...this.state.edited_advert};
        if (this.props.advert.images && this.props.advert.images.length > 0 &&
            (!newAdvert.images || newAdvert.images.length === 0)) {
            newAdvert.images = "null";
        }
        return newAdvert;
    }

    render() {
        return <div id="advert-images" className={"editor-tab" + (this.props.disabled ? " disabled" : "")}>
            {
                this.state.edited_advert &&
                <AdvertEditorBlock title={LANG_PATH + "images>title"} description={LANG_PATH + "images>box_description"}
                                   info={LANG_PATH + "images>infobox_description"} warning={this.props.requirements.images}
                                   warning_tooltip={LANG_PATH + "images>warning_tooltip"}>
                    {
                        this.state.edited_advert.advert_type &&
                        <AdvertHierarchy advert={this.state.edited_advert} b2b={this.props.userData.b2b}
                                         editable={() => true} onEdit={(housing) => {this.callImageModal(housing)}}
                                         createContent={(housing) => {return this.createRoomBoxContent(housing)}} />
                    }
                    {
                        !this.state.edited_advert.advert_type &&
                        <div className="description-container">
                            <FontAwesomeIcon icon={["fal", "exclamation-circle"]}/>
                            <span>{getLanguageEntry(LANG_PATH + "advert_type_missing_part_1")}</span>
                            <Link to="/desktop/adverts/editor/advert-type">
                                {getLanguageEntry(LANG_PATH + "tab_headers>advert_type")}
                            </Link>
                            <span>{getLanguageEntry(LANG_PATH + "advert_type_missing_part_2")}</span>
                        </div>
                    }
                </AdvertEditorBlock>
            }
            <ImageUploadModal ref={this.image_modal}/>
        </div>
    }

    callImageModal(housing) {
        if (this.image_modal.current) {
            let actualImages = housing.images ? housing.images.slice(0) : [];
            let titleAddition = housing.id && housing.id.startsWith("room") ?
                (housing.name ?? getLanguageEntry("advert_attributes>room_types>" + housing.type)) :
                getLanguageEntry("advert_attributes>advert_types>" +
                (housing.type ?? housing.advert_type));
            let imageCount = housing.id && housing.id.startsWith("room") ? ROOM_IMAGE_LIMIT : ADVERT_IMAGE_LIMIT;
            this.image_modal.current.show(actualImages, (result) => {
                if (result.length > 0) {
                    housing.images = result;
                }
                else {
                    delete housing.images;
                }
                let advert;
                if (housing.id && housing.id.startsWith("room")) {
                    advert = JSON.parse(JSON.stringify(this.state.edited_advert));
                    let roomIndex = advert.rooms.findIndex(r => r.id === housing.id);
                    if (roomIndex !== -1) {
                        advert.rooms.splice(roomIndex, 1, housing);
                    }
                }
                else {
                    advert = housing;
                }
                this.setState({
                    edited_advert: advert
                }, () => {this.actualizeRequirements()})
            }, getLanguageEntry("general>images") + " - " + titleAddition, imageCount);
        }
    }

    createRoomBoxContent(housing) {
        return <table>
            <tbody>
                <tr>
                    <td><FontAwesomeIcon icon={["fal", "image"]} fixedWidth={true} /></td>
                    <td>{housing.images && housing.images.length > 0 ? housing.images.length : "-"}</td>
                </tr>
            </tbody>
        </table>;
    }

    actualizeRequirements() {
        if (this.props.checkRequirements) {
            this.props.updateRequirements?.(this.extractData());
        }
    }

    getTabTpe() {
        return AdvertEditorTab.images;
    }

}

ImagesTab.propTypes = {
    advert: PropTypes.any.isRequired,
    userData: PropTypes.any.isRequired,
    updateRequirements: PropTypes.func.isRequired,
    checkRequirements: PropTypes.bool.isRequired,
    requirements: PropTypes.any.isRequired
}
export default ImagesTab;