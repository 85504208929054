import React, {useRef, useState} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getLanguageEntry, readImageFile, SUPPORTED_IMAGE_TYPES} from "../../utils/Helper";
import * as PropType from "prop-types";
import Avatar from "../Avatar";
import "../../../css/components/input/image-uploader.css"

function AvatarUploader(props) {

    const [dropValid, setDropValid] = useState("");
    const [loading, setLoading] = useState(false);
    const input = useRef(null);

    const openInput = () => {
        if (!loading && input.current !== null) {
            input.current.click();
        }
    }

    const onDragOver = (e) => {
        e.preventDefault();
        if (loading || e.dataTransfer.items.length === 0) return;
        let valid = SUPPORTED_IMAGE_TYPES.includes(e.dataTransfer.items[0].type);
        setDropValid(valid ? "valid" : "invalid");
    }

    const abortDrag = () => {
        setDropValid("");
    }

    const receiveDrop = (e) => {
        e.preventDefault();
        if (loading) {
            return;
        }
        let wrongFormat = false;
        let fileSizeTooBig = false;
        let imageFile = e.dataTransfer === undefined ? e.target.files[0] : e.dataTransfer.files[0];
        if (SUPPORTED_IMAGE_TYPES.includes(imageFile.type)) {
            if (imageFile.size > props.maxFileSize) {
                fileSizeTooBig = true;
            }
        }
        else {
            wrongFormat = true;
        }
        if (wrongFormat || fileSizeTooBig) {
            props.onDropError?.(wrongFormat, fileSizeTooBig);
        }
        setLoading(true);
        readImageFile(imageFile, (result) => {
            let img = new Image();
            img.src = result;
            img.onload = function() {
                let imageData = { image: result };
                imageData.zoom = 1;
                imageData.x = 0;
                imageData.y = 0;
                if (this.width > this.height) {
                    let ratio = this.width / this.height;
                    imageData.zoom *= ratio;
                }
                props.onAddImage?.(imageData, () => {
                    setLoading(false);
                    setDropValid("");
                });
            }
        });
    }

    let dropzoneIcon;
    if (loading) {
        dropzoneIcon = <FontAwesomeIcon icon={["fad", "loader"]} spin={true}/>
    }
    else {
        switch (dropValid) {
            case "valid":
                dropzoneIcon = <FontAwesomeIcon icon={["fat", "check-circle"]}/>;
                break;
            case "invalid":
                dropzoneIcon = <FontAwesomeIcon icon={["fat", "ban"]}/>;
                break;
            default:
                dropzoneIcon = <FontAwesomeIcon icon={["fat", "user"]}/>;
                break;
        }
    }

    return (
        <div className={"image-uploader" + (props.className !== undefined ? " " + props.className : "")}>
            <div className={"drop-zone " + dropValid}
                 onDragOver={(e) => { onDragOver(e) } }
                 onDragEnd={() => { abortDrag() }} onDragLeave={() => { abortDrag() }}
                 onDrop={(e) => { receiveDrop(e) }}>
                {
                    props.imageData === undefined &&
                    <div className={"drop-zone__placeholder c-flex" + (loading ? " disabled" : "")}
                         onClick={() => { openInput() }}>
                        {dropzoneIcon}
                        {
                            props.header !== undefined && !loading &&
                            <span className="image-selector-header-label">
                                {getLanguageEntry(props.header)}
                            </span>
                        }
                    </div>
                }
                {
                    props.imageData !== undefined &&
                    <Avatar data={props.imageData} size={180} />
                }
                {
                    props.imageData === undefined &&
                    <input type="file" className="drop-zone__input" onChange={(e) => { receiveDrop(e) }}
                           accept={SUPPORTED_IMAGE_TYPES.join(", ")} ref={input} disabled={loading}/>
                }
                {
                    props.imageData !== undefined &&
                    <button className="image-uploader-edit-button" onClick={() => { props.onEdit?.() }}
                            disabled={loading}>
                        <FontAwesomeIcon icon={["fal", "pencil"]} />
                    </button>
                }
            </div>
        </div>
    )

}
AvatarUploader.propTypes = {
    size: PropType.number.isRequired,
    header: PropTypes.string,
    info: PropTypes.string,
    maxFileSize: PropTypes.number.isRequired,
    onDropError: PropTypes.func,
    onAddImage: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    imageData: PropTypes.exact({
        image: PropTypes.string.isRequired,
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        zoom: PropTypes.number.isRequired
    })
}
export default AvatarUploader;