import React from "react";
import PropTypes from "prop-types";
import "../../css/pages/user-profile.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    activateBreakLines,
    getFormattedAddress,
    getLanguageEntry,
    getLanguageNameList,
    getRatingText,
    isAdvertAvailable,
    USER_REVIEW_CRITERIA_MAP
} from "../utils/Helper";
import {getAdvertsOfUser, getUser} from "../utils/RESTInterface";
import Avatar from "../components/Avatar";
import {getRatingInformationOfUser} from "../utils/StatisticHelper";
import MessageModal from "../components/modals/MessageModal";
import {ADVERT_CACHE, USER_CACHE} from "../utils/CacheHandler.ts";
import HousingPreview, {PREVIEW_TYPES} from "../components/HousingPreview";
import {ReviewAverageContainer, ReviewDetailContainer} from "../components/ReviewDisplays";
import {Mode} from "../utils/Types.ts";

class UserProfile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            adverts: [],
            loading: false,
            mode: Mode.renter
        }
        this.message_modal = React.createRef();
    }

    componentDidMount() {
        let that = this;
        let userID = window.location.pathname.replace("/user/", "");
        if (userID.includes("?")) {
            userID = userID.substring(0, userID.indexOf("?"));
        }
        let params = new URLSearchParams(window.location.search);
        let mode = Mode.renter;
        if (params.has("mode") && params.get("mode") === Mode.landlord) {
            mode = Mode.landlord;
        }
        let cachedUser = USER_CACHE.getCacheObjectData(userID);
        if (cachedUser) {
            let excludeAdverts = [];
            let adverts = [];
            if (cachedUser.adverts) {
                for (const advertID of cachedUser.adverts) {
                    let advert = ADVERT_CACHE.getCacheObjectData(advertID);
                    if (advert) {
                        excludeAdverts.push(advertID);
                        if (isAdvertAvailable(advert)) {
                            adverts.push(advert);
                        }
                    }
                }
                if (excludeAdverts.length === cachedUser.adverts.length) {
                    this.setState({
                        user: cachedUser,
                        adverts: adverts,
                        mode: mode
                    });
                }
                else {
                    this.setState({loading: true});
                    getAdvertsOfUser(userID, excludeAdverts.length > 0 ? excludeAdverts : null, (response) => {
                        if (response.errorCode !== undefined) {
                            console.error(response.errorCode + ": " + response.message);
                        }
                        else if (response.data !== undefined) {
                            for (const advert of response.data) {
                                if (isAdvertAvailable(advert)) {
                                    adverts.push(advert);
                                }
                            }
                        }
                        that.setState({
                            loading: false,
                            user: cachedUser,
                            adverts: adverts,
                            mode: mode
                        });
                    });
                }
            }
            else {
                this.setState({
                    user: cachedUser,
                    adverts: adverts,
                    mode: mode
                });
            }
        }
        else {
            let that = this;
            this.setState({loading: true});
            getUser(userID, function(response) {
                if (response.errorCode !== undefined) {
                    console.error(response.errorCode + ": " + response.message);
                    that.props.navHook('/404');
                }
                else if (response.data !== undefined) {
                    let user = response.data;
                    let excludeAdverts = [];
                    let adverts = [];
                    if (user.adverts) {
                        for (const advertID of user.adverts) {
                            let advert = ADVERT_CACHE.getCacheObjectData(advertID);
                            if (advert) {
                                excludeAdverts.push(advertID);
                                if (isAdvertAvailable(advert)) {
                                    adverts.push(advert);
                                }
                            }
                        }
                        if (excludeAdverts.length === user.adverts.length) {
                            this.setState({
                                loading: false,
                                user: user,
                                adverts: adverts,
                                mode: mode
                            });
                        }
                        else {
                            getAdvertsOfUser(userID, excludeAdverts.length > 0 ? excludeAdverts : null, (response) => {
                                if (response.errorCode !== undefined) {
                                    console.error(response.errorCode + ": " + response.message);
                                }
                                else if (response.data !== undefined) {
                                    for (const advert of response.data) {
                                        if (isAdvertAvailable(advert)) {
                                            adverts.push(advert);
                                        }
                                    }
                                }
                                that.setState({
                                    loading: false,
                                    user: user,
                                    adverts: adverts,
                                    mode: mode
                                });
                            });
                        }
                    }
                    else {
                        this.setState({
                            loading: false,
                            user: user,
                            adverts: adverts,
                            mode: mode
                        });
                    }
                }
            });
        }
    }

    render() {
        let name = this.state.user === null ? "" :
            (this.state.user.b2b && this.state.user.company ? this.state.user.company : this.state.user.first_name);
        let ratingObject = null; let criteria = null;
        if (this.state.user && this.state.user.reviews) {
            ratingObject = getRatingInformationOfUser(Object.values(this.state.user.reviews), -1, -1, this.state.mode)
            criteria = Object.values(USER_REVIEW_CRITERIA_MAP.general)
                .concat(Object.values(USER_REVIEW_CRITERIA_MAP[this.state.mode]));
        }
        return (
            <div className="user-profile">
                {
                    this.state.loading &&
                    <div id="user-profile-loading" className="loading">
                        <img src="https://roomjack.blob.core.windows.net/roomjack/animations/loading_animation.gif" alt="Roomjack Loading"/>
                    </div>
                }
                {
                    !this.state.loading && this.state.user &&
                    <img src="https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/carousel/header_studium.jpg"
                         alt="Background" />
                }
                {
                    !this.state.loading && this.state.user &&
                    <div className="container">
                        <div id="profile-overview-header">
                            <h1>
                                {getLanguageEntry("user_profile>title").replace("#", name)}
                            </h1>
                        </div>
                        <div id="profile-overview-body">
                            <div id="profile-data-card" className="roomjack-container">
                                <div className="avatar-data">
                                    <Avatar size={180} data={this.state.user.avatar} />
                                    <div className="name-container">
                                        <span>{name}</span>
                                        {
                                            this.state.user.verified &&
                                            <FontAwesomeIcon icon={["fas", "check-circle"]} />
                                        }
                                    </div>
                                    <div className="subscription-time">
                                        {
                                            getLanguageEntry("user_profile>subscribed_since")
                                                .replace("#", new Date(this.state.user.reg_ts).getFullYear())
                                        }
                                    </div>
                                </div>
                                <div className="personal-data">
                                    {
                                        this.state.user.address &&
                                        <div className="personal-data-row">
                                            <div className="personal-data-label">
                                                <FontAwesomeIcon icon={["fal", "location-dot"]} fixedWidth={true} />
                                                <span>{getLanguageEntry("user_profile>personal_data_labels>living")}</span>
                                            </div>
                                            <div className="personal-data-container">
                                                {getFormattedAddress(this.state.user.address, false, false, true, false, true)}
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.user.languages &&
                                        <div id="speaking" className="personal-data-row">
                                            <div className="personal-data-label">
                                                <FontAwesomeIcon icon={["fal", "comment-lines"]} fixedWidth={true} />
                                                <span>{getLanguageEntry("user_profile>personal_data_labels>speaking")}</span>
                                            </div>
                                            <div className="personal-data-container">
                                                {this.getLanguages()}
                                            </div>
                                        </div>
                                    }
                                    {
                                        ratingObject &&
                                        <div id="rating" className="personal-data-row">
                                            <div className="personal-data-label">
                                                <FontAwesomeIcon icon={["fal", "star"]} fixedWidth={true} />
                                                <span>{getLanguageEntry("user_profile>personal_data_labels>rating")}</span>
                                            </div>
                                            <div className="personal-data-container">
                                                {getRatingText(ratingObject.rating_count)}
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.user.reaction_time !== undefined && this.state.user.reaction_time > 0 &&
                                        <div id="responding" className="personal-data-row">
                                            <div className="personal-data-label">
                                                <FontAwesomeIcon icon={["fal", "envelope-circle-check"]} fixedWidth={true} />
                                                <span>{getLanguageEntry("user_profile>personal_data_labels>responding")}</span>
                                            </div>
                                            <div className="personal-data-container">
                                                {this.getReactionTime()}
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.user.about_me &&
                                        <div id="about" className="personal-data-row">
                                            <div className="personal-data-label">
                                                <FontAwesomeIcon icon={["fal", "user"]} fixedWidth={true} />
                                                <span>{getLanguageEntry("user_profile>personal_data_labels>" +
                                                    (this.state.user.b2b ? "about_us" : "about_me"))}</span>
                                            </div>
                                            <div className="personal-data-container">
                                                {activateBreakLines(this.state.user.about_me)}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div id="profile-data-grid">
                                <div id="profile-data-left-col">
                                    {
                                        ratingObject &&
                                        <div id="profile-rating" className="roomjack-container">
                                            <div className="header">
                                                {getLanguageEntry("user_profile>rating_header").replace("#", name)}
                                            </div>
                                            <ReviewAverageContainer  ratingObject={ratingObject}/>
                                        </div>
                                    }
                                    {
                                        criteria &&
                                        <div id="profile-review-details" className="roomjack-container">
                                            <div className="header">
                                                {getLanguageEntry("user_profile>review_detail_header")}
                                            </div>
                                            <ReviewDetailContainer langPath={"components>reviews>user_review>ratings>"}
                                                                   criteria={criteria} ratingObject={ratingObject}/>
                                        </div>
                                    }
                                    {
                                        (!this.state.user || !this.props.userData ||
                                        this.props.userData.id !== this.state.user.id) &&
                                        <div id="profile-send-message" className="roomjack-container" onClick={() => { this.callMessageModal() }}>
                                            <div className="header">
                                                {getLanguageEntry("user_profile>send_message_header").replace("#", name)}
                                            </div>
                                            <img src="https://roomjack.blob.core.windows.net/roomjack/content-images/write_message.png"
                                                 alt="Write message" />
                                        </div>
                                    }
                                </div>
                                <div id="profile-data-right-col">
                                    {
                                        this.state.adverts.length > 0 &&
                                        <div id="profile-advert-container" className="roomjack-container">
                                            <div className="header">
                                                {getLanguageEntry("user_profile>advert_header").replace("#", name)}
                                            </div>
                                            <div id="profile-advert-scroller">
                                                {
                                                    this.state.adverts.map((a, i) => {
                                                        return <HousingPreview key={a.id} advert={a} linkPath={"expose"}
                                                                               type={PREVIEW_TYPES.overview}/>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                    {/*<div id="profile-superjacker" class="roomjack-container">
                                    <h6><i class="fal fa-award"></i>&nbsp;Superjacker</h6>
                                    <div>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod</div>
                                    <img src="https://roomjack.blob.core.windows.net/roomjack/content-images/superjacker.png" alt="">
                                </div>*/}
                                </div>
                            </div>
                        </div>
                        <MessageModal ref={this.message_modal} userData={this.props.userData}
                                      onUpdateUserData={this.props.onUpdateUserData}/>
                        {/*<div id="profile-review-overview-modal" className="modal fade" tabIndex="-1" role="dialog">
                        <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4></h4>
                                    <button type="button" className="close-button c-flex" data-dismiss="modal">
                                        <i className="fal fa-times"></i>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <ReviewViewer />
                                </div>
                            </div>
                        </div>
                    </div>*/}
                    </div>
                }
            </div>
        )
    }

    getLanguages() {
        let langList = getLanguageNameList(this.state.user.languages);
        let langCount = langList.length;
        if (langCount === 1) {
            return langList[0];
        }
        return langList.slice(0, langCount - 1).join(", ") + " & " + langList[langCount - 1];
    }

    getReactionTime() {
        let t = this.state.user.reaction_time;
        let textPath = getLanguageEntry("user_profile>personal_data_labels");
        return t <= 1 ? textPath.responding_one_hour : t <= 2 ? textPath.responding_two_hours :
            t <= 3 ? textPath.responding_three_hours : t <= 12 ? textPath.responding_twelve_hours :
                t <= 24 ? textPath.responding_one_day : textPath.responding_days;
    }

    callMessageModal() {
        if (this.props.userData) {
            this.message_modal.current.show(this.state.user);
        }
        else {
            this.props.callLogin();
        }
    }

}

UserProfile.propTypes = {
    mode: PropTypes.oneOf(Object.values(Mode)).isRequired,
    userData: PropTypes.object,
    callLogin: PropTypes.func.isRequired,
    onUpdateUserData: PropTypes.func.isRequired
}
UserProfile.defaultProps = {
    mode: Mode.landlord
}
export default UserProfile;