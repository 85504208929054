import React from "react";
import {createMultiAdvertDropdown, getLanguageEntry} from "../../../utils/Helper";
import Loading from "../../../components/Loading";
import {STATISTIC_CACHE} from "../../../utils/CacheHandler.ts";
import {getStatisticsData} from "../../../utils/RESTInterface";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _uniqueId from "lodash/uniqueId";
import {Navigate, Route, Routes} from "react-router";
import FilterSelectorBar from "../../../components/FilterSelectorBar";
import GeneralStatistics from "../tools/stats/GeneralStatistics";
import LengthOfStay from "../tools/stats/LengthOfStay";
import Income from "../tools/stats/Income";
import ClickBookingRatio from "../tools/stats/ClickBookingRatio";
import CancellationRate from "../tools/stats/CancellationRate";
import OccupancyRate from "../tools/stats/OccupancyRate";
import {BookingLengthType} from "../../../utils/Types.ts";
import Dropdown from "../../../components/input/Dropdown";

export const STAT_SCREEN_BREAKPOINTS = [1200, 1100, 950, 750, 500];

class Statistics extends React.Component {

    filterItems = [
        {
            value: -1,
            label: "components>interval_filter>all"
        },
        {
            value: 30,
            label: "components>interval_filter>last_month"
        },
        {
            value: 90,
            label: "components>interval_filter>last_quarter"
        },
        {
            value: 180,
            label: "components>interval_filter>last_half_year"
        },
        {
            value: 365,
            label: "components>interval_filter>last_year"
        },
    ]

    menus = [
        {
            mainPath: "/desktop/statistics/",
            subPath: "/desktop/statistics/overview",
            lang: "jackboard>stats>submenus>general_statistics>title",
            icon: <FontAwesomeIcon icon={["fal", "chart-column"]} />
        },
        {
            mainPath: "/desktop/statistics",
            subPath: "/desktop/statistics/occupancy-rate",
            lang: "jackboard>stats>submenus>occupancy_rate>title",
            icon: <FontAwesomeIcon icon={["fal", "bed-alt"]} />
        },
        {
            mainPath: "/desktop/statistics",
            subPath: "/desktop/statistics/cancellation-rate",
            lang: "jackboard>stats>submenus>cancellation_rate>title",
            icon: <FontAwesomeIcon icon={["fal", "times-circle"]} />
        },
        {
            mainPath: "/desktop/statistics",
            subPath: "/desktop/statistics/click-booking",
            lang: "jackboard>stats>submenus>click_booking_ratio>title",
            icon: <div className="icon" style={{backgroundImage: "url('https://roomjack.blob.core.windows.net/roomjack/icons/clicks_bookings.svg')"}}></div>
        },
        {
            mainPath: "/desktop/statistics",
            subPath: "/desktop/statistics/income",
            lang: "jackboard>stats>submenus>income>title",
            icon: <div className="icon" style={{backgroundImage: "url('https://roomjack.blob.core.windows.net/roomjack/icons/income.svg')"}}></div>
        },
        {
            mainPath: "/desktop/statistics",
            subPath: "/desktop/statistics/length-of-stay",
            lang: "jackboard>stats>submenus>length_of_stay>title",
            icon: <div className="icon" style={{backgroundImage: "url('https://roomjack.blob.core.windows.net/roomjack/icons/length_of_stay.svg')"}}></div>
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            adverts: [],
            bookings: [],
            selected_adverts: [],
            interval: -1,
            booking_type: "all",
            breakpoint: this.calculateBreakpoint()
        }
        this.advert_selector = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("resize", () => { this.handleStatisticResize(); });
        if (STATISTIC_CACHE.isValid()) {
            this.setState({
                adverts: STATISTIC_CACHE.getAdverts() ? STATISTIC_CACHE.getAdverts() : [],
                bookings: STATISTIC_CACHE.getBookings() ? STATISTIC_CACHE.getBookings() : [],
                selected_adverts: STATISTIC_CACHE.getAdverts() ? STATISTIC_CACHE.getAdverts().map((a, _) => a.id) : []
            })
        }
        else {
            this.setState({loading: true});
            let that = this;
            getStatisticsData(() => {
                that.setState({
                    adverts: STATISTIC_CACHE.getAdverts() ? STATISTIC_CACHE.getAdverts() : [],
                    bookings: STATISTIC_CACHE.getBookings() ? STATISTIC_CACHE.getBookings() : [],
                    selected_adverts: STATISTIC_CACHE.getAdverts() ? STATISTIC_CACHE.getAdverts().map((a, _) => a.id) : [],
                    loading: false
                })
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => { this.handleStatisticResize(); });
    }

    render() {
        let url = window.location.pathname;
        let filteredAdverts = this.state.adverts ?
            this.state.adverts.filter(a => this.state.selected_adverts.includes(a.id)) : [];
        return (
            <div id="my-statistics">
                {
                    this.state.loading &&
                    <Loading imageWidth="250px"/>
                }
                {
                    !this.state.loading &&
                    <h1 className="desktop-tool-title">
                        {getLanguageEntry("jackboard>stats>title")}
                    </h1>
                }
                {
                    !this.state.loading &&
                    <div className="desktop-submenu-grid">
                        {
                            this.menus.map((item, i) => {
                                return <Link to={item.subPath} key={_uniqueId(item.subPath)}
                                             className={"desktop-submenu-tile selectable" + (url.includes(item.subPath) ? " selected" : "")}>
                                    <div className="tile-icon-container">{item.icon}</div>
                                    <div className="tile-text-container">{getLanguageEntry(item.lang)}</div>
                                </Link>
                            })
                        }
                    </div>
                }
                {
                    !this.state.loading &&
                    <FilterSelectorBar items={this.filterItems} defaultValue={-1}
                                       onChange={(v) => { this.setState({interval: v}) }}/>
                }
                {
                    !this.state.loading && this.state.adverts.length > 0 &&
                    <div className="second-filter-row">
                        {
                            url.includes("income") &&
                            <div>
                                <div className="description-container">
                                    {getLanguageEntry("jackboard>stats>submenus>income>booking_length")}
                                </div>
                                <Dropdown
                                    name="booking-length"
                                    onChange={(e, val) => {
                                        this.setState({booking_type: val})
                                    }}
                                    initialValue={this.state.booking_type} items={[
                                        { value: "all", label: "jackboard>stats>booking_length_dropdown>all" },
                                        { value: BookingLengthType.long_term, label: "jackboard>stats>booking_length_dropdown>long" },
                                        { value: BookingLengthType.short_term, label: "jackboard>stats>booking_length_dropdown>short" }
                                    ]} />
                            </div>
                        }
                        {
                            <div>
                                <div className="description-container">
                                    {getLanguageEntry("jackboard>adverts>name")}
                                </div>
                                {createMultiAdvertDropdown(this.state.adverts, "advert_selector", this.advert_selector,
                                        (e, v) => { this.setState({selected_adverts: v}) }, this.state.adverts.map(a => a.id))}
                            </div>
                        }
                    </div>
                }
                {
                    !this.state.loading &&
                    <Routes>
                        <Route exact path={"/overview"} element={<GeneralStatistics adverts={filteredAdverts}
                                                                                    bookings={this.state.bookings}
                                                                                    interval={this.state.interval}/>}/>
                        <Route exact path={"/occupancy-rate"} element={<OccupancyRate adverts={filteredAdverts}
                                                                                      bookings={this.state.bookings}
                                                                                      interval={this.state.interval}/>}/>
                        <Route exact path={"/cancellation-rate"} element={<CancellationRate adverts={filteredAdverts}
                                                                                            bookings={this.state.bookings}
                                                                                            interval={this.state.interval}/>}/>
                        <Route exact path={"/click-booking"} element={<ClickBookingRatio adverts={filteredAdverts}
                                                                                         bookings={this.state.bookings}
                                                                                         interval={this.state.interval}/>}/>
                        <Route exact path={"/income"} element={<Income adverts={filteredAdverts}
                                                                       bookings={this.state.bookings}
                                                                       interval={this.state.interval}
                                                                       bookingType={this.state.booking_type}
                                                                       breakpoint={this.state.breakpoint}/>}/>
                        <Route exact path={"/length-of-stay"} element={<LengthOfStay adverts={filteredAdverts}
                                                                                     bookings={this.state.bookings}
                                                                                     interval={this.state.interval}
                                                                                     breakpoint={this.state.breakpoint}/>}/>
                        <Route exact path={"/*"} element={<Navigate to="/desktop/statistics/overview" />}/>
                    </Routes>
                }
            </div>
        )
    }

    handleStatisticResize() {
        let bp = this.calculateBreakpoint();
        if (bp !== this.state.breakpoint) {
            this.setState({breakpoint: bp});
        }
    }

    calculateBreakpoint() {
        for (let i=0; i<STAT_SCREEN_BREAKPOINTS.length; i++) {
            if (window.innerWidth >= STAT_SCREEN_BREAKPOINTS[i]) {
                return STAT_SCREEN_BREAKPOINTS[i];
            }
        }
        return STAT_SCREEN_BREAKPOINTS[STAT_SCREEN_BREAKPOINTS.length - 1];
    }

}

Statistics.propTypes = {}
Statistics.defaultProps = {}
export default Statistics;