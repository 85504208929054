import React from "react";
import {Col, Form, Modal} from "react-bootstrap";
import {extractErrorCode, getLanguageEntry, MAX_MESSAGE_LENGTH} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TextArea from "../input/TextArea";
import parse from "html-react-parser";
import FetchButton from "../buttons/FetchButton";
import {sendGuestInvoice} from "../../utils/RESTInterface";
import * as PropType from "prop-types";
import GuestInvoice from "../printables/GuestInvoice";

class GuestInvoiceCreator extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            booking: null,
            sending: false,
            error_message: null,
            response: null
        }
        this.form = React.createRef();
    }

    render() {
        return(
            <Modal className={"guest-invoice-creator-modal landlord"} show={this.state.show} size="lg"
                   onHide={() => { this.hide() }} dialogClassName="max-content" centered>
                <Modal.Header>
                    <h4>{getLanguageEntry("components>guest_invoice_creator>title")}</h4>
                    <button onClick={() => { this.hide() }} disabled={this.state.loading}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>

                <Modal.Body>
                    {
                        this.state.booking && (!this.state.response || this.state.error_message) &&
                        <form onSubmit={(e) => {this.sendInvoice(e)}}
                              style={{display: "grid", gap: "25px"}} ref={this.form}>
                            <div className="description-container">
                                {parse(getLanguageEntry("components>guest_invoice_creator>guest_invoice_description"))}
                            </div>
                            <Form.Group as={Col} controlId="invoice_no">
                                <Form.Label className="description-container v-form-label" column>
                                    {getLanguageEntry("components>guest_invoice_creator>invoice_no_description")}
                                </Form.Label>
                                <Form.Control type="text" name="invoice_no" required={true}
                                              defaultValue={this.state.booking.landlord_invoice_no ?? "" } />
                            </Form.Group>
                            <TextArea label="components>guest_invoice_creator>your_message" name="message"
                                      maxLength={MAX_MESSAGE_LENGTH} />
                            {
                                this.state.error_message &&
                                <div id="invoice-result">{this.state.error_message}</div>
                            }
                            <div style={{display: "flex", gap: "10px", justifyContent: "flex-end"}}>
                                <button className="outline-button accent" onClick={(e) => {this.showPreview(e)}} type="button">
                                    <FontAwesomeIcon icon={["fal", "file-magnifying-glass"]}/>
                                    <span>{getLanguageEntry("components>guest_invoice_creator>invoice_preview")}</span>
                                </button>
                                <FetchButton loading={this.state.sending} type="submit" className="outline-button accent"
                                             loadingText="components>guest_invoice_creator>sending_invoice">
                                    <FontAwesomeIcon icon={["fal", "envelope"]}/>
                                    <span>{getLanguageEntry("components>guest_invoice_creator>send_guest_invoice")}</span>
                                </FetchButton>
                            </div>
                        </form>
                    }
                    {
                        this.state.response && !this.state.error_message &&
                        <div className="process-modal-result-container">
                            <div className="process-result-image">
                                <div className="result-icon-container">
                                    <FontAwesomeIcon icon={["fal", "file-check"]}/>
                                </div>
                            </div>
                            <div className="process-result-message-box">
                                {getLanguageEntry("components>guest_invoice_creator>invoice_successful_sent")}
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        )
    }

    show(booking, charge) {
        this.setState({
            show: true,
            booking: booking,
            charge: charge,
            error_message: null,
            response: null
        });
    }

    hide() {
        this.setState({
            show: false,
            error_message: null,
            response: null
        })
    }

    sendInvoice(e) {
        e.preventDefault();
        let formData = new FormData(this.form.current);
        this.setState({
            sending: true,
            error_message: null
        });
        let message = null;
        if (formData.get("message") && formData.get("message").length > 0) {
            message = formData.get("message");
        }
        let invoiceNo = formData.get("invoice_no");
        let that = this;
        sendGuestInvoice(this.state.booking.id, this.state.charge.id, invoiceNo, message, function(response) {
            let state = { sending: false, response: response };
            let errorCode = extractErrorCode(response);
            if (errorCode) {
                state.error_message = parse(getLanguageEntry("error_codes>" + errorCode));
                that.setState(state);
            }
            else if (response.conversation_id && (!this.props.userData.conversations ||
                !this.props.userData.conversations.includes(response.conversation_id))) {
                let user = JSON.parse(JSON.stringify(this.props.userData));
                if (!user.conversations) {
                    user.conversations = [];
                }
                user.conversations.push(response.conversation_id);
                this.props.onUpdateUserData?.(user, () => {
                    that.setState(state);
                });
            }
            else {
                that.setState(state);
            }
        });
    }

    showPreview(e) {
        e.preventDefault();
        let formData = new FormData(this.form.current);
        let booking = {...this.state.booking};
        booking.landlord_invoice_no = formData.get("invoice_no");
        if (booking.landlord_invoice_no.length === 0) {
            booking.landlord_invoice_no = "-";
        }
        let document = <GuestInvoice booking={booking} charge={this.state.charge}
                                         userData={this.props.userData} />
        this.props.onPreviewInvoice?.(document)
    }

}
GuestInvoiceCreator.propTypes = {
    userData: PropType.any.isRequired,
    onUpdateUserData: PropType.func.isRequired,
    onPreviewInvoice: PropType.func.isRequired
}
export default GuestInvoiceCreator;