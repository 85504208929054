import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Modal} from "react-bootstrap";
import {getLanguageEntry} from "../../utils/Helper";
import FetchButton from "../buttons/FetchButton";
import "../../../css/components/modals/alert-modal.css";

class AlertModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            onAccept: null,
            acceptIcon: null,
            acceptText: null,
            cancelIcon: null,
            cancelText: null,
            title: '',
            content: null,
            image: null,
            imageClass: '',
            loading: false,
            loadingText: ''
        };
    }

    render() {
        return (
            <Modal className={"alert-modal " + this.props.mode} show={this.state.show}
                   onHide={() => { this.hide() }} dialogClassName="max-content" centered>
                <Modal.Header>
                    <h4>{getLanguageEntry(this.state.title)}</h4>
                    <button onClick={() => { this.hide() }} disabled={this.state.loading}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>

                <Modal.Body>
                    {
                        this.state.image &&
                        <div className={"alert-modal-image " + this.state.imageClass}>
                            <img src={this.state.image} alt="alert-illustration"/>
                        </div>
                    }
                    {
                        this.state.content
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        this.state.onAccept &&
                        <FetchButton className="outline-button accent" onClick={() => { this.state.onAccept?.(); }}
                                     loading={this.state.loading} loadingText={this.state.loadingText}>
                            {
                                this.state.acceptIcon &&
                                <FontAwesomeIcon icon={this.state.acceptIcon}/>
                            }
                            {
                                <span>{getLanguageEntry(this.state.acceptText)}</span>
                            }
                        </FetchButton>
                    }
                    {
                        this.state.cancelText &&
                        <button className="outline-button black" onClick={() => {this.hide()}} disabled={this.state.loading}>
                            {
                                this.state.cancelIcon &&
                                <FontAwesomeIcon icon={this.state.cancelIcon}/>
                            }
                            {
                                <span>{getLanguageEntry(this.state.cancelText)}</span>
                            }
                        </button>
                    }
                </Modal.Footer>
            </Modal>
        )
    }

    show(title, content, onAccept, acceptIcon, acceptText, cancelIcon, cancelText,
         image, imageClass) {
        this.setState({
            show: true,
            title: title,
            content: content,
            onAccept: onAccept,
            acceptIcon: acceptIcon,
            acceptText: acceptText,
            cancelIcon: cancelIcon,
            cancelText: cancelText,
            image: image,
            imageClass: imageClass
        })
    }

    hide() {
        this.setState({
            show: false,
            loading: false,
            loadingText: null
        })
    }

    setLoading(loading, loadingText) {
        this.setState({
            loading: loading,
            loadingText: loadingText
        })
    }

}

AlertModal.propTypes = {}
AlertModal.defaultProps = {}
export default AlertModal;