import React from "react";
import PropTypes from "prop-types";
import _uniqueId from "lodash/uniqueId";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "../../../css/components/input/rating-input.css";

class RatingInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: "" + props.initialValue ?? ''
        }
    }

    render() {
        let actualValue = parseInt(this.state.value);
        return (
            <div className="rating-input">
                <input value={this.state.value} name={this.props.name}
                       required={true} type="text" onChange={() => {}}/>
                {
                    Array.from(Array(5)).map((_, i) => {
                        let number = i + 1;
                        let iconClass = isNaN(actualValue) || number > actualValue ? "fal" : "fas";
                        return <button onClick={() => {
                            this.setState({value: '' + number})}}
                                key={_uniqueId(this.props.name + "-" + i)}>
                            <FontAwesomeIcon icon={[iconClass, "star"]}/>
                        </button>
                    })
                }
            </div>
        )
    }

}
RatingInput.propTypes = {
    name: PropTypes.string.isRequired,
    initialValue: PropTypes.number
}
export default RatingInput;