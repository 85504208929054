import React from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    compareDates,
    getDayFromBegin,
    getFormattedDate,
    getLanguageEntry, megaByteToByte, readImageFile,
    SUPPORTED_IMAGE_TYPES
} from "../utils/Helper";
import MessageContainer from "./MessageContainer";
import _uniqueId from "lodash/uniqueId";
import AlertModal from "./modals/AlertModal";
import {Mode} from "../utils/Types.ts";
import PrintOverviewModal from "./modals/PrintOverviewModal";
import GuestInvoice from "./printables/GuestInvoice";

const LANG_PATH = "jackboard>messages>";
const MAX_FILE_SIZE_BYTES = megaByteToByte(5); // 5M

class ConversationSurface extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            input_rows: 1,
            show_emojis: false,
            input_focus: false,
            document: null
        }
        this.message_surface = React.createRef();
        this.length_test = React.createRef();
        this.message_input_form = React.createRef();
        this.message_input = React.createRef();
        this.send_button = React.createRef();
        this.image_browser = React.createRef();
        this.alert_modal = React.createRef();
        this.emoji_container = React.createRef();
        this.emoji_activator = React.createRef();
        this.print_overview = React.createRef();
    }

    componentDidMount() {
        this.scrollToBottom();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.input_focus && this.message_input.current) {
            this.message_input.current.focus();
        }
        if (prevProps.conversation.id !== this.props.conversation.id) {
            this.scrollToBottom();
        }
    }

    render() {
        let disableSending = !this.isConversationActive();
        let disableSurface = this.props.disableSurface;
        return (
            <div id="conversation-surface">
                <div id="message-surface" ref={this.message_surface}
                     onScroll={() => {this.handleScroll()}}>
                    {
                        this.props.loading &&
                        <div className="loading">
                            <FontAwesomeIcon icon={["fad", "spinner-third"]} spin={true} />
                        </div>
                    }
                    {this.createConversationItemList().map((item, _) => item)}
                </div>
                <div id="control-bar" onBlur={(e) => {this.handleFocusLoss(e)}}>
                    <button className="simple-icon-button" disabled={disableSending || disableSurface}
                            onClick={() => {this.image_browser.current.click()}}>
                        <FontAwesomeIcon icon={["fal", "image"]}/>
                        <input type="file" accept={SUPPORTED_IMAGE_TYPES.join(", ")} form="message-input"
                               ref={this.image_browser} style={{display: "none"}} name="image"
                               onClick={(e) => {e.stopPropagation()}}
                               onChange={(e) => {this.prepareFileForSending(e)}}/>
                    </button>
                    {/*<button className="simple-icon-button" ref={this.emoji_activator} onClick={() => {
                        this.setState({show_emojis: !this.state.show_emojis})}}
                            disabled={disableSending || disableSurface}>
                        <FontAwesomeIcon icon={["fal", "smile"]}/>
                    </button>*/}
                    {/*<div className={"emoji-container" + (this.state.show_emojis ? " show" : "")}
                         ref={this.emoji_container} tabIndex={0}>
                        <EmojiPicker lazyLoadEmojis={true} onEmojiClick={(emoji) => {
                            this.message_input.current.value += ":#@" + emoji.unified + "@#:";
                            this.handleKeyUp();
                        }} />
                    </div>*/}
                    <form ref={this.message_input_form} id="message-input"
                          onSubmit={(e) => {
                              if (!disableSending && !disableSurface) {
                                  this.sendMessage(e)
                              }}}>
                                    <textarea name="message" required={true} rows={this.state.input_rows}
                                              onChange={(e) => {this.handleChange(e)}}
                                              onKeyDown={(e) => {this.handleKeyDown(e)}}
                                              disabled={disableSending} ref={this.message_input}
                                              onFocus={(e) => {this.setState({input_focus: true})}}
                                              onBlur={(e) => {this.setState({input_focus: false})}}/>
                        <button className="simple-icon-button" type="submit" ref={this.send_button}
                                disabled={disableSending || disableSurface}>
                            <FontAwesomeIcon icon={["fal", "paper-plane"]}/>
                        </button>
                    </form>
                    <span style={{position: "absolute", opacity: 0, fontSize: "var(--small-text)", pointerEvents: "none"}}
                          ref={this.length_test}></span>
                </div>
                <div className={"overlay" + (disableSurface ? " loading" : "")}>
                    {
                        disableSurface && <FontAwesomeIcon icon={["fas", "spinner-third"]} spin={true}/>
                    }
                </div>
                <AlertModal ref={this.alert_modal}/>
                {
                    this.state.document &&
                    <PrintOverviewModal
                        mode={this.props.mode}
                        onClose={() => {
                            this.setState({
                                document: null,
                            })
                        }}
                        document={this.state.document.document}
                        filename={this.state.document.filename}/>
                }
            </div>
        )
    }

    scrollToBottom() {
        if (this.message_surface.current) {
            this.message_surface.current.scrollTo(0, this.message_surface.current.scrollHeight);
        }
    }

    createConversationItemList() {
        let items = []
        if (this.props.conversation.messages) {
            let lastDate = null;
            for (let i=this.props.conversation.messages.length - 1; i>-1; i--) {
                let message = this.props.conversation.messages[i];
                let timestamp = message.timestamp;
                let actualDate = getDayFromBegin(new Date(timestamp));
                if (!lastDate || compareDates(actualDate, lastDate) === 1) {
                    lastDate = actualDate;
                    let labelText = compareDates(lastDate, getDayFromBegin()) === 0 ?
                        getLanguageEntry("jackboard>messages>labels>today") :
                        getFormattedDate(lastDate, true, false, true, true, true);
                    items.push(<div className="chat-day info-label" key={_uniqueId()}>{labelText}</div>);
                }
                items.push(<MessageContainer conversationID={this.props.conversation.id}
                                             message={message} userData={this.props.userData} key={message.id}
                                             partner={this.props.conversation.user.first_name}
                                             isSelected={this.props.selectedMessage === message.id}
                                             onSelectMessage={(m) => { this.props.onShowBookingOverview(m)}}
                                             onEditInquiry={this.props.onEditInquiry}
                                             onPrintInvoice={(booking, charge) => {this.showInvoice(booking, charge)}} />)
            }
            let status = this.getUserStatusMessage();
            if (status) {
                items.push(status)
            }
        }
        return items;
    }

    isConversationActive() {
        let user = this.props.conversation.user ?? null;
        if (user) {
            return !((user.delete && (user.delete === true || user.delete.active)) ||
                (user.blocking && (user.blocking === true || user.blocking.active)) ||
                (user.pause && (user.pause === true || user.pause.active)));

        }
        return false;
    }

    getUserStatusMessage() {
        let user = this.props.conversation.user ?? null;
        if (user) {
            let status;
            if (user.delete && (user.delete === true || user.delete.active)) {
                status = "deleted"
            }
            else if (user.blocking && (user.blocking === true || user.blocking.active)) {
                status = "blocked";
            }
            else if (user.pause && (user.pause === true || user.pause.active)) {
                status = "paused";
            }
            if (status) {
                return <div className="description-container" key={_uniqueId("status-" + this.props.conversation.id)}>
                    {getLanguageEntry("jackboard>messages>labels>user_" + status)}
                </div>;
            }
        }
    }

    handleKeyDown(e) {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            this.send_button.current.click();
        }
    }

    handleChange(e) {
        e.preventDefault();
        let textSplit = this.message_input.current.value.split("\n");
        let widthExceeding = 0;
        let availableWidth = this.message_input.current.offsetWidth - 16;
        for (const line of textSplit) {
            this.length_test.current.textContent = line;
            if (this.length_test.current.offsetWidth > availableWidth) {
                widthExceeding += 1;
            }
        }
        let rows = Math.min(5, widthExceeding + textSplit.length);
        if (rows !== this.state.input_rows) {
            this.setState({input_rows: rows})
        }
    }

    sendMessage(e) {
        e.preventDefault();
        if (this.message_input_form.current) {
            let form = new FormData(this.message_input_form.current);
            this.props.onSendMessage?.(this.props.conversation, {message: form.get("message")}, () => {
                this.scrollToBottom();
                this.message_input_form.current.value = "";
                e.target.reset();
                this.setState({input_rows: 1});
            });
        }
    }

    prepareFileForSending(e) {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].size > MAX_FILE_SIZE_BYTES) {
                if (this.alert_modal.current) {
                    this.alert_modal.current.show("general>error",
                        <div className="description-container">
                            {getLanguageEntry(LANG_PATH + "file_size_error")}
                        </div>, null, null, null, null, "OK");
                }
            }
            else {
                readImageFile(e.target.files[0], (result) => {
                    this.sendImage(result);
                })
            }
        }
    }

    sendImage(imageData) {
        this.props.onSendMessage?.(this.props.conversation, {image: imageData}, () => {
            this.scrollToBottom();
        });
    }

    handleScroll() {
        if (this.message_surface.current.scrollTop === 0 && this.props.conversation.exists_more &&
            !this.props.sync && !this.props.disableSurface) {
            this.props.onTopReached?.(this.props.conversation);
        }
    }

    handleFocusLoss(e) {
        if (this.state.show_emojis && !this.emoji_container.current.contains(e.relatedTarget) &&
            !this.emoji_activator.current.contains(e.relatedTarget)) {
            this.setState({show_emojis: false})
        }
    }

    showInvoice(booking, charge) {
        this.setState({
            document: {
                document: <GuestInvoice booking={booking} charge={charge} userData={this.props.userData} />,
                filename: `${booking.confirmation_code}.pdf`
            }
        });
    }

}

ConversationSurface.propTypes = {
    mode: PropTypes.oneOf(Object.values(Mode)),
    userData: PropTypes.any.isRequired,
    conversation: PropTypes.any.isRequired,
    onSendMessage: PropTypes.func.isRequired,
    onTopReached: PropTypes.func.isRequired,
    sync: PropTypes.bool.isRequired,
    disableSurface: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    onContactSupport: PropTypes.func.isRequired,
    onActivateProTool: PropTypes.func.isRequired,
    updateUserData: PropTypes.func.isRequired,
    onShowBookingOverview: PropTypes.func.isRequired,
    onEditInquiry: PropTypes.func.isRequired,
    selectedMessage: PropTypes.string
}
export default ConversationSurface;