import React from "react";
import {Tooltip} from "@mui/material";
import parse from "html-react-parser";
import {getLanguageEntry} from "../../utils/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

class AdvertEditorBlock extends React.Component {

    render() {
        let classList = ["advert-editor-block", "roomjack-container"];
        if (this.props.invalid) {
            classList.push("invalid");
        }
        else if (this.props.warning) {
            classList.push("warning");
        }
        if (this.props.className) {
            classList.push(this.props.className);
        }
        return(
            <div className={classList.join(" ")} id={this.props.id}>
                {
                    this.props.info &&
                    <Tooltip title={parse(getLanguageEntry(this.props.info))} placement="right-start" arrow>
                        <FontAwesomeIcon icon={["fas", "info-circle"]}/>
                    </Tooltip>
                }
                {
                    this.props.title &&
                    <div className="roomjack-headline">
                        {getLanguageEntry(this.props.title)}
                    </div>
                }
                {
                    this.props.description &&
                    <div className="description-container">
                        {getLanguageEntry(this.props.description)}
                    </div>
                }
                {this.props.children}
                {
                    this.props.invalid && this.props.invalid_tooltip &&
                    <Tooltip title={parse(getLanguageEntry(this.props.invalid_tooltip))} placement="right-end" arrow>
                        <FontAwesomeIcon icon={["fas", "exclamation-triangle"]}/>
                    </Tooltip>
                }
                {
                    !this.props.invalid && this.props.warning && this.props.warning_tooltip &&
                    <Tooltip title={parse(getLanguageEntry(this.props.warning_tooltip))} placement="right-end" arrow>
                        <FontAwesomeIcon icon={["fas", "exclamation-circle"]}/>
                    </Tooltip>
                }
            </div>
        )
    }

}
AdvertEditorBlock.propTypes = {
    info: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string,
    invalid: PropTypes.bool,
    warning: PropTypes.bool,
    invalid_tooltip: PropTypes.string,
    warning_tooltip: PropTypes.string,
    className: PropTypes.string
}
AdvertEditorBlock.defaultProps = {
    invalid: false
}
export default AdvertEditorBlock;