import React from "react";
import AdvertEditorBlock from "../../../../../components/advert/AdvertEditorBlock";
import PropTypes from "prop-types";
import {
    CHARACTERISTIC_ICON_MAP,
    CHARACTERISTICS_CATEGORY_MAP,
    ROOM_EQUIPMENT_MAP
} from "../../../../../../resources/housings";
import HousingProperty from "../../../../../components/advert/HousingProperty";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    getLanguageEntry,
    MAX_CUSTOM_CHARACTERISTIC_LENGTH,
    MAX_CUSTOM_EQUIPMENT_LENGTH
} from "../../../../../utils/Helper";
import {AdvertEditorTab} from "../../../../../utils/Types.ts";
import AdvertHierarchy from "../../../../../components/advert/AdvertHierarchy";
import ItemSelectorModal from "../../../../../components/modals/ItemSelectorModal";
import {Link} from "react-router-dom";

const LANG_PATH = "jackboard>adverts>advert_creator>";

class EquipmentTab extends React.Component {

    constructor(props) {
        super(props);
        let editedAdvert = JSON.parse(JSON.stringify(props.advert));
        if (!editedAdvert.characteristics) {
            editedAdvert.characteristics = [];
        }
        this.state = {
            edited_advert: editedAdvert
        }
        this.characteristic_modal = React.createRef();
        this.equipment_modal = React.createRef();
    }

    extractData() {
        return this.state.edited_advert;
    }

    render() {
        return(
            <div className={"editor-tab" + (this.props.disabled ? " disabled" : "")}>
                {
                    this.state.edited_advert &&
                    <AdvertEditorBlock title={LANG_PATH + "characteristics>title"}
                                       description={LANG_PATH + "characteristics>box_description"}
                                       info={LANG_PATH + "characteristics>infobox_description"}
                                       invalid={this.props.requirements.characteristics}>
                        <div style={{display: "grid", gap: "5px"}}>
                            {
                                this.state.edited_advert.characteristics.slice(0, 5).map((c, _) => {
                                    return <HousingProperty key={c} value={c}
                                                            name={"advert_attributes>characteristics>" + c}
                                                            onDelete={() => {this.removeCharacteristic(c)}}
                                                            icon={CHARACTERISTIC_ICON_MAP[c] ?? ["fal", "circle-info"]} />
                                })
                            }
                            {
                                this.state.edited_advert.characteristics.length > 5 &&
                                <span style={{fontFamily: "urbane-rounded, sans-serif", fontSize: "var(--small-text)"}}>
                                    {getLanguageEntry(LANG_PATH + "characteristics>" +
                                        (this.state.edited_advert.characteristics.length - 5 > 1 ?
                                            "more_characteristics" : "more_characteristic"))
                                        .replace("#", this.state.edited_advert.characteristics.length - 5)}
                                </span>
                            }
                        </div>
                        <button className="outline-button accent" style={{width: "max-content"}}
                                onClick={() => {this.callCharacteristicsModal()}}>
                            <FontAwesomeIcon icon={["fal", "pencil"]} />
                            <span>{getLanguageEntry(LANG_PATH + "characteristics>edit_characteristic")}</span>
                        </button>
                    </AdvertEditorBlock>
                }
                {
                    this.state.edited_advert &&
                    <AdvertEditorBlock title={LANG_PATH + "equipment>title"}
                                       info={LANG_PATH + "equipment>infobox_description"}
                                       description={LANG_PATH + "equipment>box_description"}
                                       invalid={this.props.requirements.equipment}
                                       invalid_tooltip={LANG_PATH + "requirements>equipment"} >
                        {
                            this.state.edited_advert.advert_type &&
                            <AdvertHierarchy advert={this.state.edited_advert} b2b={this.props.userData.b2b}
                                             editable={() => true} onEdit={(housing) => {this.callEquipmentModal(housing)}}
                                             createContent={(housing) => {return this.createRoomBoxContent(housing)}}/>
                        }
                        {
                            !this.state.edited_advert.advert_type &&
                            <div className="description-container">
                                <FontAwesomeIcon icon={["fal", "exclamation-circle"]}/>
                                <span>{getLanguageEntry(LANG_PATH + "advert_type_missing_part_1")}</span>
                                <Link to="/desktop/adverts/editor/advert-type">
                                    {getLanguageEntry(LANG_PATH + "tab_headers>advert_type")}
                                </Link>
                                <span>{getLanguageEntry(LANG_PATH + "advert_type_missing_part_2")}</span>
                            </div>
                        }
                    </AdvertEditorBlock>
                }
                <ItemSelectorModal ref={this.characteristic_modal} className="characteristics-modal"
                                   title={LANG_PATH + "characteristics>title"}
                                   description={LANG_PATH + "characteristics>modal_description"}
                                   categories={this.getCharacteristicCategories()}
                                   itemMap={CHARACTERISTICS_CATEGORY_MAP}
                                   customItemLength={MAX_CUSTOM_CHARACTERISTIC_LENGTH}
                                   customItemPlaceholder={"jackboard>adverts>advert_creator>characteristics>custom_placeholder"}
                                   itemLangPath={"advert_attributes>characteristics>"}
                                   searchPlaceholder={LANG_PATH + "equipment>equipment_search_placeholder"}/>
                <ItemSelectorModal ref={this.equipment_modal} className="equipment-modal"
                                   title={LANG_PATH + "equipment>title"}
                                   description={LANG_PATH + "equipment>modal_description"}
                                   categories={this.getEquipmentCategories()}
                                   itemMap={ROOM_EQUIPMENT_MAP}
                                   customItemLength={MAX_CUSTOM_EQUIPMENT_LENGTH}
                                   customItemPlaceholder={"jackboard>adverts>advert_creator>equipment>custom_placeholder"}
                                   itemLangPath={"advert_attributes>equipment>"}
                                   searchPlaceholder={LANG_PATH + "equipment>equipment_search_placeholder"}/>
            </div>
        )
    }

    removeCharacteristic(characteristic) {
        let count = this.state.edited_advert.characteristics.length;
        if (count > 0) {
            let advert = {...this.state.edited_advert};
            advert.characteristics = advert.characteristics.filter(c => c !== characteristic);
            if (advert.characteristics.length !== count) {
                this.setState({edited_advert: advert}, () => {
                    this.actualizeRequirements()
                });
            }
        }
    }

    callCharacteristicsModal() {
        if (this.characteristic_modal.current) {
            let actualSelection = this.state.edited_advert.characteristics ?
                this.state.edited_advert.characteristics.slice(0) : []
            this.characteristic_modal.current.show(actualSelection, "all", (result) => {
                let advert = {...this.state.edited_advert};
                advert.characteristics = result;
                this.setState({
                    edited_advert: advert
                }, () => {this.actualizeRequirements()})
            });
        }
    }

    callEquipmentModal(housing) {
        if (this.equipment_modal.current) {
            let actualSelection = housing.equipment ? housing.equipment.slice(0) : [];
            let category = (housing.id && housing.id.startsWith("room") && !housing.name) ?
                (housing.type ?? "other") : "all";
            this.equipment_modal.current.show(actualSelection, category, (result) => {
                let advert = JSON.parse(JSON.stringify(this.state.edited_advert));
                if (housing.id && housing.id.startsWith("room")) {
                    if (result.length > 0) {
                        housing.equipment = result;
                    }
                    else {
                        delete housing.equipment;
                    }
                    let roomIndex = advert.rooms.findIndex(r => r.id === housing.id);
                    if (roomIndex !== -1) {
                        advert.rooms.splice(roomIndex, 1, housing);
                    }
                }
                else {
                    advert.equipment = result;
                }
                this.setState({
                    edited_advert: advert
                }, () => {this.actualizeRequirements()})
            })
        }
    }

    getCharacteristicCategories() {
        let path = LANG_PATH + "characteristics>categories>";
        let items = [];
        for (const category of Object.keys(CHARACTERISTICS_CATEGORY_MAP)) {
            items.push({ value: category, label: path + category })
        }
        items.push({ value: "all", label: path + "all" });
        return items;
    }

    getEquipmentCategories() {
        let path = "advert_attributes>room_types>";
        let items = [];
        for (const category of Object.keys(ROOM_EQUIPMENT_MAP)) {
            items.push({ value: category, label: path + category })
        }
        items.push({ value: "all", label: LANG_PATH + "equipment>all_equipment" });
        return items;
    }

    createRoomBoxContent(housing) {
        return <table>
            <tbody>
                <tr>
                    <td><FontAwesomeIcon icon={["fal", "couch"]} fixedWidth={true} /></td>
                    <td>{housing.equipment && housing.equipment.length > 0 ? housing.equipment.length : "-"}</td>
                </tr>
            </tbody>
        </table>;
    }

    actualizeRequirements() {
        if (this.props.checkRequirements) {
            this.props.updateRequirements?.(this.extractData());
        }
    }

    getTabTpe() {
        return AdvertEditorTab.equipment;
    }

}
EquipmentTab.propTypes = {
    advert: PropTypes.any.isRequired,
    userData: PropTypes.any.isRequired,
    updateRequirements: PropTypes.func.isRequired,
    checkRequirements: PropTypes.bool.isRequired,
    requirements: PropTypes.any.isRequired
}
export default EquipmentTab;