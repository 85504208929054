import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AZURE_STORAGE_PATH, fileListToBase64, getFileNameFromBase64, getLanguageEntry} from "../../utils/Helper";
import "../../../css/components/input/file-uploader.css"
import PropTypes from "prop-types";

class FileUploader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            files: props.files ?? [],
            drop_valid: "",
            loading: false
        }
        this.input = React.createRef();
    }

    render() {
        let dropzoneIcon;
        if (this.state.loading) {
            dropzoneIcon = <FontAwesomeIcon icon={["fad", "loader"]} spin={true}/>
        }
        else {
            switch (this.state.drop_valid) {
                case "valid":
                    dropzoneIcon = <FontAwesomeIcon icon={["fat", "check-circle"]}/>;
                    break;
                case "invalid":
                    dropzoneIcon = <FontAwesomeIcon icon={["fat", "ban"]}/>;
                    break;
                default:
                    dropzoneIcon = <FontAwesomeIcon icon={["fat", "file-pdf"]}/>;
                    break;
            }
        }
        return (
            <div className="file-uploader">
                <div className={"drop-zone " + this.state.drop_valid} onClick={() => { this.openInput() }}
                     onDragStart={(e) => { e.dataTransfer.effectAllowed = "all" }}
                     onDragOver={(e) => { this.onDragOver(e) } }
                     onDragEnd={() => { this.abortDrag() }} onDragLeave={() => { this.abortDrag() }}
                     onDrop={(e) => { this.receiveDrop(e) }}>
                    {
                        this.state.files.length === 0 &&
                        <div className={"drop-zone__placeholder c-flex" + (this.state.loading ? " disabled" : "")}>
                            {dropzoneIcon}
                            {
                                this.props.fileSelectorHeader &&
                                <span className="file-selector-header-label">
                                {getLanguageEntry(this.props.fileSelectorHeader)}
                            </span>
                            }
                            {
                                this.props.fileSelectorInfo &&
                                <span className="description-container">
                                {getLanguageEntry(this.props.fileSelectorInfo)}
                            </span>
                            }
                        </div>
                    }
                    {
                        this.state.files.length > 0 &&
                        <div className="drop-zone__file c-flex">
                            {
                                this.state.files.map((f, i) => {
                                    return <div className="horizontal-form-group" key={"file-" + i}>
                                        <div className="description-container">
                                            {this.getFileName(f)}
                                        </div>
                                        <button className="simple-icon-button" onClick={() => {
                                            let copy = [...this.state.files];
                                            copy.splice(i, 1);
                                            this.setState({files: copy}, () => {
                                                this.props.onFileSelected?.(this.state.files);
                                            });
                                        }}>
                                            <FontAwesomeIcon icon={["fal", "times"]} />
                                        </button>
                                    </div>
                                })
                            }
                        </div>
                    }
                    <input type="file" className="drop-zone__input" multiple={this.props.dropFileCount > 1}
                           onChange={(e) => {this.receiveDrop(e)}}
                           accept={this.props.supportedFileTypes.join(", ")} ref={this.input}
                           disabled={this.state.loading}/>
                </div>
            </div>
        )
    }

    getFileName(file) {
        if (typeof file === "string") {
            if (file.startsWith("data:")) {
                return getFileNameFromBase64(file);
            }
            if (file.startsWith(AZURE_STORAGE_PATH)) {
                let link = file.replaceAll("%2F", "/");
                link = link.substring(link.lastIndexOf("/") + 1);
                if (link.length > 43) {
                    link = link.substring(0, 20) + "..." + link.substring(link.length - 20);
                }
                return link;
            }
        }
        return file
    }

    openInput() {
        if (!this.state.loading && this.state.files.length < this.props.dropFileCount &&
            this.input.current !== null) {
            this.input.current.click();
        }
    }

    onDragOver(e) {
        e.preventDefault();
        if (this.state.loading || e.dataTransfer.items.length === 0) return;
        let valid = false;
        for (let i=0; i<Math.min((this.props.dropFileCount - this.state.files.length), e.dataTransfer.items.length); i++) {
            valid = valid ||
                (this.props.supportedFileTypes.includes(e.dataTransfer.items[i].type));
        }
        this.setState({ drop_valid: (valid ? "valid" : "invalid") });
    }

    abortDrag() {
        this.setState({ drop_valid: "" });
    }

    receiveDrop(e) {
        e.preventDefault();
        if (this.state.loading) {
            return;
        }
        let error;
        let actualFiles = this.state.files.slice(0);
        let newFiles = [];
        let receivedData = !e.dataTransfer ? e.target.files : e.dataTransfer.files;
        for (let i=0; i<Math.min((this.props.dropFileCount - this.state.files.length), receivedData.length); i++) {
            if (this.props.supportedFileTypes.includes(receivedData[i].type)) {
                if (receivedData[i].size <= this.props.maxFileSize) {
                    newFiles.push(receivedData[i]);
                }
                else {
                    error = "components>file_uploader>too_big_alert" + (this.props.dropFileCount === 1 ? "" : "_multi");
                }
            }
            else {
                error = "components>file_uploader>wrong_format_alert" + (this.props.dropFileCount === 1 ? "" : "_multi");
            }
        }
        if (this.props.dropFileCount > 1 &&
            receivedData.length > (this.props.dropFileCount - actualFiles.length)) {
            error = "components>file_uploader>too_many";
        }
        if (error) {
            alert(getLanguageEntry(error))
        }
        this.setState({
            loading: true
        });
        fileListToBase64(newFiles)
            .then(fileList => {
                this.setState({
                    files: actualFiles.concat(fileList),
                    loading: false
                }, () => {
                    this.props.onFileSelected?.(this.state.files);
                });
            });
    }

    getFiles() {
        return this.state.files;
    }

}
FileUploader.propTypes = {
    files: PropTypes.arrayOf(PropTypes.string).isRequired,
    supportedFileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    dropFileCount: PropTypes.number.isRequired,
    maxFileSize: PropTypes.number.isRequired,
    fileSelectorHeader: PropTypes.string,
    fileSelectorInfo: PropTypes.string,
    onFileSelected: PropTypes.func
}
FileUploader.defaultProps = {
    dropFileCount: 1
}
export default FileUploader;