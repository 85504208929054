import React from 'react';
import {InputGroup, Overlay, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import {getLanguageEntry} from "../../utils/Helper";

class SecretInputField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            reveal: false,
            show_tooltip: false
        }
        this.secret = React.createRef();
        this.field = React.createRef();
    }

    render() {
        return(
            <InputGroup className="secret-input-field" onFocus={(e) => { this.handleFocusIn(e) }}
                        onBlur={(e) => { this.handleFocusOut(e) }} ref={this.secret}>
                <input type={this.state.reveal ? "text" : "password"} tabIndex={this.props.tabIndex}
                       placeholder={this.props.placeholder !== undefined ? getLanguageEntry(this.props.placeholder) : ""}
                       required={this.props.required} ref={this.field} name={this.props.name}
                       onBlur={(e) => { this.blurSecret(e); }}
                       onKeyUp={(e) => { this.checkSecret(e) }}
                       onFocus={() => { this.setState({ show_tooltip: true }) }}
                       onChange={() => { this.props.onChange?.() }} disabled={this.props.disabled}
                       defaultValue={this.props.initialValue}/>
                <InputGroup.Text>
                    <button onClick={(e) => { this.togglePassword(e) }}
                            type="button" tabIndex={-1}>
                        <FontAwesomeIcon icon={["fal", (this.state.reveal ? "eye-slash" : "eye")]} fixedWidth={true}/>
                    </button>
                </InputGroup.Text>
                {
                    this.props.tooltip !== undefined &&
                    <Overlay target={this.field.current} show={this.state.show_tooltip}>
                        <Tooltip>{getLanguageEntry(this.props.tooltip)}</Tooltip>
                    </Overlay>
                }
            </InputGroup>
        )
    }

    showPassword = () => { this.setState({reveal: true}); }

    hidePassword = () => { this.setState({reveal: false}); }

    togglePassword = (e) => {
        e.preventDefault();
        if (this.state.reveal) {
            this.hidePassword();
        }
        else {
            this.showPassword();
        }
    }

    handleFocusOut = (e) => {
        if (!this.secret.current.contains(e.relatedTarget)) {
            this.hidePassword();
        }
    }

    handleFocusIn = (e) => {
        if (!this.secret.current.contains(e.relatedTarget)) {
            this.field.current.classList.remove('invalid');
            this.field.current.classList.remove('valid');
        }
    }

    get value() {
        return this.field.current.value
    }

    set value(value) {
        this.field.current.value = value;
    }

    checkSecret(e) {
        this.field.current.classList.remove('invalid');
        this.field.current.classList.remove('valid');
        if (this.props.isValid !== undefined) {
            if (!this.props.isValid(this.field.current.value)) {
                this.field.current.classList.add('invalid');
            }
            else {
                this.field.current.classList.add('valid');
            }
        }
        if (e !== undefined && e.code === "Enter") {
            this.props.onEnterPressed?.();
        }
    }

    blurSecret(e) {
        this.setState({ show_tooltip: false });
        this.checkSecret(e);
    }

}
SecretInputField.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    isValid: PropTypes.func,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
    initialValue: PropTypes.string,
    onEnterPressed: PropTypes.func,
    tabIndex: PropTypes.number
}
SecretInputField.defaultProps = {
    required: false,
    disabled: false
}
export default SecretInputField;