import React from "react";
import PropTypes from "prop-types";
import Loading from "../../../components/Loading";
import {getLanguageEntry} from "../../../utils/Helper";
import HousingPreview, {PREVIEW_TYPES} from "../../../components/HousingPreview";
import {FAVORITE_CACHE} from "../../../utils/CacheHandler.ts";
import {getFavoriteHousings} from "../../../utils/RESTInterface";

class Favorites extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        if (this.props.userData.favorite_housings && !FAVORITE_CACHE.getCache()) {
            let that = this;
            this.setState({loading: true});
            getFavoriteHousings((response) => {
                that.setState({loading: false});
            })
        }
    }

    render() {
        let housings = FAVORITE_CACHE.getCache() ? FAVORITE_CACHE.getCache() : [];
        return(
            <div id="favorite-housings">
                {
                    this.state.loading &&
                    <Loading imageWidth="15vw"/>
                }
                {
                    !this.state.loading &&
                    <h1 className="desktop-tool-title">
                        {getLanguageEntry("jackboard>favorites>title")}
                    </h1>
                }
                {
                    !this.state.loading && housings.length > 0 &&
                    <div className="roomjack-container">
                        {
                            housings.map((a, i) => {
                                return <HousingPreview advert={a} key={a.id} type={PREVIEW_TYPES.search}
                                                       {...this.props}/>
                            })
                        }
                    </div>
                }
                {
                    !this.state.loading && housings.length === 0 &&
                    <div className="roomjack-container no-data-container">
                        <img src="https://roomjack.blob.core.windows.net/roomjack/content-images/verantwortungsvoll_vermieten/bild1.jpg"
                             alt="No favorites" style={{borderRadius: "100px"}}/>
                        <div className="description-container">
                            {getLanguageEntry("jackboard>favorites>no_favorites")}
                        </div>
                    </div>
                }
            </div>
        )
    }

}

Favorites.propTypes = {
    userData: PropTypes.object.isRequired,
    onUpdateUserData: PropTypes.func.isRequired
}
Favorites.defaultProps = {}
export default Favorites;