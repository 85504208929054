import React from "react";
import PropTypes from "prop-types";
import {getDayFromBegin, getLanguageEntry, getTimeUnitInMS, priceToString} from "../../../../utils/Helper";
import {
    getCompletedBookingsOfAdvertsInPeriod,
    getIncome,
    getRatingInformationOfAdverts
} from "../../../../utils/StatisticHelper";
import {ReviewAverageContainer} from "../../../../components/ReviewDisplays";

class GeneralStatistics extends React.Component {

    render() {
        let statisticData = this.determineGeneralStatisticData();
        return(
            <div id="general-statistics-overview" className="statistic-container">
                <div className="statistic-title">
                    {getLanguageEntry("jackboard>stats>submenus>general_statistics>title")}
                </div>
                <div className="content-container">
                    <div id="general-completed-bookings" className="statistic-display">
                        <div className="statistic-display-headline">
                            {getLanguageEntry("jackboard>stats>submenus>general_statistics>completed_bookings_header")}
                        </div>
                        <div className="statistic-display-value">
                            {statisticData.bookings}
                        </div>
                    </div>
                    <div id="general-income" className="statistic-display">
                        <div className="statistic-display-headline">
                            {getLanguageEntry("jackboard>stats>submenus>general_statistics>income_header")}
                        </div>
                        <div className="statistic-display-value">
                            {priceToString(statisticData.income, true, 2)}
                        </div>
                    </div>
                    <div className="statistic-display">
                        <div className="statistic-display-headline">
                            {getLanguageEntry("jackboard>stats>submenus>general_statistics>customer_satisfaction_header")}
                        </div>
                        <ReviewAverageContainer ratingObject={statisticData.rating} />
                    </div>
                </div>
            </div>
        )
    }

    determineGeneralStatisticData() {
        let advertIDs = this.props.adverts.map((a, i) => { return a.id });
        let bookingCount;
        let income;
        let start; let end;
        if (this.props.interval && this.props.interval !== -1) {
            end = Date.now();
            start = getDayFromBegin(new Date(end - getTimeUnitInMS(this.props.interval))).getTime();
        }
        let ratingResult = getRatingInformationOfAdverts(this.props.adverts, start, end, false);
        bookingCount = getCompletedBookingsOfAdvertsInPeriod(this.props.bookings, advertIDs, start, end).length;
        income = getIncome(this.props.bookings, advertIDs, start, end);

        return {
            bookings: bookingCount,
            income: income,
            rating: ratingResult
        };
    }

}
GeneralStatistics.propTypes = {
    adverts: PropTypes.array,
    bookings: PropTypes.array,
    interval: PropTypes.number
}
export default GeneralStatistics;