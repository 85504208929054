import React from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    createMultiAdvertDropdown, getDayFromBegin,
    getLanguageEntry, getNextDay, getTimestampLabel, getTimeUnitInMS, isAdvertAvailable,
    PRODUCTION_START, USER_REVIEW_CRITERIA_MAP
} from "../../../utils/Helper";
import FilterSelectorBar from "../../../components/FilterSelectorBar";
import {ADVERT_CACHE, USER_CACHE} from "../../../utils/CacheHandler.ts";
import {getAdvertsOfUser, requestReviewAuthors} from "../../../utils/RESTInterface";
import {
    filterValidReviews,
    getRatingInformationOfAdverts,
    getRatingInformationOfUser
} from "../../../utils/StatisticHelper";
import {
    ReviewAverageContainer,
    ReviewDetailContainer,
    ReviewDistributionContainer
} from "../../../components/ReviewDisplays";
import Avatar from "../../../components/Avatar";
import _uniqueId from "lodash/uniqueId";
import ReportModal from "../../../components/modals/ReportModal";
import {AdvertReviewCriteria, Mode, ReportReason} from "../../../utils/Types.ts";

class Reviews extends React.Component {

    filterItems = [
        {
            value: -1,
            label: "components>interval_filter>all_reviews"
        },
        {
            value: 30,
            label: "components>interval_filter>last_month"
        },
        {
            value: 90,
            label: "components>interval_filter>last_quarter"
        },
        {
            value: 180,
            label: "components>interval_filter>last_half_year"
        },
        {
            value: 365,
            label: "components>interval_filter>last_year"
        },
    ]

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            show_reviews: '',
            adverts: [],
            authors: {},
            review_interval: -1,
            selected_adverts: this.props.userData.adverts
        }
        this.advert_selector = React.createRef();
        this.report_modal = React.createRef();
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let type = "user";
        if (params.get("type") && params.get("type") === "advert" &&
            this.props.mode === Mode.landlord) {
            type = "advert";
        }
        if (type === "user") {
            this.showUserReviews();
        }
        else {
            this.showAdvertReviews();
        }
    }

    render() {
        let reviewType = this.state.show_reviews === 'advert' && this.props.mode === Mode.landlord ?
            'advert' : 'user';
        let reviews = []; let ratingObject;
        let start = PRODUCTION_START.getTime();
        let end = getNextDay(getDayFromBegin()).getTime();
        if (this.state.review_interval !== -1) {
            start = end - getTimeUnitInMS(this.state.review_interval);
        }
        if (reviewType === 'advert') {
            let filteredAdverts = this.state.adverts.filter(a => this.state.selected_adverts.includes(a.id));
            for (const advert of this.state.adverts) {
                if (advert.reviews) {
                    reviews = reviews.concat(filterValidReviews(advert.reviews, this.state.authors));
                }
            }
            ratingObject = getRatingInformationOfAdverts(filteredAdverts, start, end);
        }
        else {
            reviews = filterValidReviews(this.props.userData.reviews, this.state.authors);
            ratingObject = getRatingInformationOfUser(reviews, start, end, this.props.mode);
        }
        let criteria = reviewType === 'advert' ? Object.values(AdvertReviewCriteria) :
            Object.values(USER_REVIEW_CRITERIA_MAP.general).concat(Object.values(USER_REVIEW_CRITERIA_MAP[this.props.mode]));
        return (
            <div id="my-reviews">
                <h1 className="desktop-tool-title">
                    {getLanguageEntry("jackboard>reviews>name")}
                </h1>
                {
                    this.props.mode === Mode.landlord &&
                    <div>
                        <div className="desktop-submenu-grid">
                            <div id="user-reviews" onClick={() => {this.showUserReviews()}}
                                 className={"desktop-submenu-tile selectable" +
                                     (reviewType === "user" ? " selected" : "")}>
                                <div className="tile-icon-container">
                                    <FontAwesomeIcon icon={["fal", "user"]}/>
                                </div>
                                <div className="tile-text-container">
                                    {getLanguageEntry("jackboard>reviews>user_reviews")}
                                </div>
                            </div>
                            <div id="advert-reviews" onClick={() => {this.showAdvertReviews()}}
                                 className={"desktop-submenu-tile selectable" +
                                (reviewType === "advert" ? " selected" : "")}>
                                <div className="tile-icon-container">
                                    <FontAwesomeIcon icon={["fal", "house"]}/>
                                </div>
                                <div className="tile-text-container">
                                    {getLanguageEntry("jackboard>reviews>advert_reviews")}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    !this.state.loading && reviews.length > 0 &&
                    <FilterSelectorBar items={this.filterItems} defaultValue={-1}
                                       onChange={(v) => {
                                                this.setState({review_interval: v});
                                            }}/>
                }
                {
                    !this.state.loading && reviews.length > 0 && reviewType === "advert" &&
                    createMultiAdvertDropdown(this.state.adverts, "advert_selector", this.advert_selector,
                        (e, v) => { this.setState({selected_adverts: v}) }, this.state.adverts.map(a => a.id))
                }
                {
                    !this.state.loading && reviews.length === 0 &&
                    <div>
                        <div className="roomjack-container no-data-container">
                            <img src="https://roomjack.blob.core.windows.net/roomjack/content-images/verantwortungsvoll_vermieten/bild5.jpg"
                                 alt="No reviews" style={{borderRadius: "100px"}}/>
                            <div className="description-container">
                                {getLanguageEntry("jackboard>reviews>no_" + reviewType + "_reviews")}
                            </div>
                        </div>
                    </div>
                }
                {
                    !this.state.loading && reviews.length > 0 &&
                    <div className="review-main-container">
                        <div className="roomjack-grid-container">
                            <div className="roomjack-container">
                                <ReviewAverageContainer ratingObject={ratingObject}/>
                            </div>
                            <div className="roomjack-container">
                                <ReviewDistributionContainer ratingObject={ratingObject}/>
                            </div>
                            <div className="roomjack-container">
                                <ReviewDetailContainer  criteria={criteria} ratingObject={ratingObject}
                                                        langPath={"components>reviews>" + reviewType + "_review>ratings>"}/>
                            </div>
                        </div>
                        {
                            ratingObject.reviews.length > 0 &&
                            <div id="review-scroller" className="roomjack-container">
                                {
                                    ratingObject.reviews.map((review, i) => {
                                        return <div className="review" key={_uniqueId("review_" + review.author_id)}>
                                            <div className="review-header">
                                                <Avatar size={30} data={this.state.authors[review.author_id].avatar}/>
                                                <div className="review-author-name">{this.state.authors[review.author_id].first_name}</div>
                                                <div className="review-timestamp">{getTimestampLabel(review.timestamp)}</div>
                                            </div>
                                            <div className="review-body">
                                                <div className="rating-type-container">
                                                    {
                                                        reviewType === 'user' &&
                                                        Object.keys(review.evaluation_criteria).map((k, i) => {
                                                            return <div className="rating" key={_uniqueId(review.author_id + "_" + k + "_" + i)}>
                                                                <div className="rating-name">
                                                                    {getLanguageEntry("components>reviews>user_review>ratings>" + k)}
                                                                </div>
                                                                <div className="rating-value">
                                                                    {
                                                                        Array.from(Array(5)).map((_, i) => {
                                                                            let iconType = i < review.evaluation_criteria[k] ?
                                                                                "fas" : "fal"
                                                                            return <FontAwesomeIcon key={_uniqueId(review.author_id + "-star#" + i)}
                                                                                                    icon={[iconType, "star"]}/>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                    {
                                                        reviewType === 'advert' &&
                                                        Object.keys(review).filter(r => r.endsWith('_rating')).map((k, i) => {
                                                            return <div className="rating" key={_uniqueId(review.author_id + "_" + k + "_" + i)}>
                                                                <div className="rating-name">
                                                                    {getLanguageEntry("components>reviews>advert_review>ratings>" + k)}
                                                                </div>
                                                                <div className="rating-value">
                                                                    {
                                                                        Array.from(Array(5)).map((_, i) => {
                                                                            let iconType = i < review[k] ?
                                                                                "fas" : "fal"
                                                                            return <FontAwesomeIcon key={_uniqueId(review.author_id + "-star#" + i)}
                                                                                                    icon={[iconType, "star"]}/>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                                <div className="review-description-container">
                                                    {
                                                        review.description !== undefined ? review.description : ''
                                                    }
                                                </div>
                                                <button className="report-author" onClick={() => {this.reportUser(review.author_id)}}>
                                                    <FontAwesomeIcon icon={["fal", "bullhorn"]}/>
                                                    <span>{getLanguageEntry("jackboard>reviews>report_review")}</span>
                                                </button>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        }
                    </div>
                }
                {
                    this.state.loading &&
                    <div className="roomjack-container loading">
                        <img src="https://roomjack.blob.core.windows.net/roomjack/animations/loading_animation.gif" alt="Roomjack Loading"/>
                    </div>
                }
                <ReportModal ref={this.report_modal} userData={this.props.userData} />
            </div>
        )
    }

    showUserReviews() {
        if (this.props.userData.reviews) {
            let authors = this.state.authors;
            let authorIDs = Object.keys(this.props.userData.reviews)
                .filter(a => {
                    let cachedUser = USER_CACHE.getCacheObjectData(a);
                    if (cachedUser) {
                        authors[a] = cachedUser;
                        return false;
                    }
                    return true;
                });
            if (authorIDs.length > 0) {
                let that = this;
                this.setState({
                    show_reviews: 'user',
                    loading: true
                });
                requestReviewAuthors(authorIDs, function(response) {
                    if (response.users !== undefined) {
                        for (const user of response.users) {
                            authors[user.id] = user;
                        }
                    }
                    that.setState({
                        authors: authors,
                        loading: false
                    });
                });
            }
            else {
                this.setState({
                    authors: authors,
                    show_reviews: 'user',
                    loading: false
                });
            }
        }
        else {
            this.setState({
                loading: false,
                show_reviews: 'user'
            });
        }
    }

    showAdvertReviews() {
        if (this.props.userData.adverts) {
            let adverts = [];
            let excludeAdverts = [];
            for (const advertID of this.props.userData.adverts) {
                let advert = ADVERT_CACHE.getCacheObjectData(advertID);
                if (advert) {
                    excludeAdverts.push(advertID);
                    if (isAdvertAvailable(advert)) {
                        adverts.push(advert);
                    }
                }
            }
            if (excludeAdverts.length === this.props.userData.adverts.length) {
                this.requestAdvertReviewAuthorData(adverts);
            }
            else {
                let that = this;
                this.setState({loading: true});
                getAdvertsOfUser(this.props.userData.id, excludeAdverts.length > 0 ? excludeAdverts : null, (response) => {
                    if (response.errorCode !== undefined) {
                        console.error(response.errorCode + ": " + response.message);
                    }
                    else if (response.data !== undefined) {
                        for (const advert of response.data) {
                            if (isAdvertAvailable(advert)) {
                                adverts.push(advert);
                            }
                        }
                    }
                    that.requestAdvertReviewAuthorData(adverts);
                });
            }
        }
        else {
            this.setState({
                loading: false,
                show_reviews: 'advert'
            });
        }
    }

    requestAdvertReviewAuthorData(adverts) {
        let authors = this.state.authors;
        let authorIDs = [];
        for (const advert of adverts) {
            if (isAdvertAvailable(advert) && advert.reviews) {
                authorIDs = authorIDs.concat(Object.keys(advert.reviews)
                    .filter(a => {
                        let cachedUser = USER_CACHE.getCacheObjectData(a);
                        if (cachedUser) {
                            authors[a] = cachedUser;
                            return false;
                        }
                        return true;
                    }));
            }
        }
        if (authorIDs.length > 0) {
            let that = this;
            this.setState({
                show_reviews: 'advert',
                adverts: adverts,
                loading: true
            });
            requestReviewAuthors(authorIDs, function(response) {
                if (response.users !== undefined) {
                    for (const user of response.users) {
                        authors[user.id] = user;
                    }
                }
                that.setState({
                    authors: authors,
                    loading: false
                });
            });
        }
        else {
            this.setState({
                authors: authors,
                adverts: adverts,
                show_reviews: 'advert',
                loading: false
            });
        }
    }

    reportUser(userID) {
        this.report_modal.current.show(userID, ReportReason.user_report);
    }

}

Reviews.propTypes = {
    mode: PropTypes.oneOf(Object.values(Mode)),
    userData: PropTypes.object.isRequired
}
Reviews.defaultProps = {}
export default Reviews;