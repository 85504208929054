import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getLanguageEntry} from "../../../utils/Helper";
import {Link} from "react-router-dom";
import _uniqueId from "lodash/uniqueId";
import {Navigate, Route, Routes} from "react-router";
import PayoutHistory from "../tools/finances/PayoutHistory";
import UnpaidRents from "../tools/finances/UnpaidRents";
import BookingInvoices from "../tools/finances/BookingInvoices";
import PropTypes from "prop-types";

class Finances extends React.Component {

    menus = [
        {
            mainPath: "/desktop/finances",
            subPath: "/desktop/finances/payout-history",
            lang: "jackboard>finances>submenus>payout_history>title",
            icon: <FontAwesomeIcon icon={["fal", "history"]} />
        },
        {
            mainPath: "/desktop/finances",
            subPath: "/desktop/finances/booking-invoices",
            lang: "jackboard>finances>submenus>all_invoices>title",
            icon: <FontAwesomeIcon icon={["fal", "file-invoice"]} />
        },
        {
            mainPath: "/desktop/finances",
            subPath: "/desktop/finances/unpaid-rents",
            lang: "jackboard>finances>submenus>unpaid_rents>title",
            icon: <FontAwesomeIcon icon={["fal", "history"]} flip="horizontal" />
        }
    ];

    render() {
        let url = window.location.pathname;
        return (
            <div id="my-finances">
                <h1 className="desktop-tool-title">
                    {getLanguageEntry("jackboard>finances>title")}
                </h1>
                <div className="desktop-submenu-grid">
                    {
                        this.menus.map((item, i) => {
                            return <Link to={item.subPath} key={_uniqueId(item.subPath)}
                                         className={"desktop-submenu-tile selectable" + (url.includes(item.subPath) ? " selected" : "")}>
                                <div className="tile-icon-container">{item.icon}</div>
                                <div className="tile-text-container">{getLanguageEntry(item.lang)}</div>
                            </Link>
                        })
                    }
                </div>
                <Routes>
                    <Route exact path={"/overview"} element={<div />} />
                    <Route exact path={"/payout-history"} element={<PayoutHistory userData={this.props.userData} />} />
                    <Route exact path={"/booking-invoices"} element={<BookingInvoices userData={this.props.userData} />} />
                    <Route exact path={"/unpaid-rents"} element={<UnpaidRents userData={this.props.userData} onUpdateUserData={this.props.onUpdateUserData} />} />
                    <Route exact path={"/*"} element={<Navigate to="/desktop/finances/overview" />} />
                </Routes>
            </div>
        )
    }

}

Finances.propTypes = {
    userData: PropTypes.object.isRequired,
    onUpdateUserData: PropTypes.func.isRequired
}
export default Finances;