import React from "react";
import PropTypes from "prop-types";
import {buildTextLinkContainer, getLanguageEntry, millisecondsToTime, WITHDRAWAL_PERIOD} from "../../../utils/Helper";
import {Form} from "react-bootstrap";
import FetchButton from "../../../components/buttons/FetchButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getAccountLink, updateUser} from "../../../utils/RESTInterface";
import AccountStatus from "../../../components/modals/AccountStatus";

class AccountSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loading_revocation: false,
            stripe_account_status: null,
            stripe_account_completed: false
        }
        this.revocation_form = React.createRef();
        this.account_status = React.createRef();
    }

    componentDidMount() {
        let that = this;
        getAccountLink(function(response) {
            let state = {stripe_account_status: response};
            if (!response.verified && response.requirements !== undefined) {
                let requirements = response.requirements;
                let accountCompleted = true;
                for (let requirementKey of Object.keys(requirements)) {
                    if (!requirementKey.includes('due')) {
                        continue;
                    }
                    let requirementList = requirements[requirementKey];
                    if (Array.isArray(requirementList)) {
                        for (const requirement of requirementList) {
                            if (typeof requirement === 'string' && !requirement.includes('verification')) {
                                accountCompleted = false;
                                break;
                            }
                        }
                        if (!accountCompleted) {
                            break;
                        }
                    }
                    else if (typeof requirementList === 'string' &&
                        !requirementList.includes('verification')) {
                        accountCompleted = false;
                        break;
                    }
                }
                state.stripe_account_completed = accountCompleted;
            }
            that.setState(state);
        })
    }

    render() {
        return (
            <div id="account">
                {
                    this.props.userData.reg_ts + WITHDRAWAL_PERIOD > Date.now() &&
                    this.props.userData.skip_withdrawal_period === undefined &&
                    !this.props.userData.b2b &&
                    <div className="roomjack-container">
                        <div className="roomjack-container-header">
                            {getLanguageEntry("profile_menu>account_settings>revocation_header")}
                        </div>
                        <div id="revocation-policy-container">
                            {this.getRevocationDescription()}
                            <form ref={this.revocation_form} onSubmit={(e) => { this.skipRevocation(e) }}>
                                <Form.Group className="revocation-checkbox mb-3">
                                    <input type="checkbox" id="skip-withdrawal-profile" name="skip_withdrawal" required/>
                                    <Form.Label htmlFor="skip-withdrawal-profile">
                                        {getLanguageEntry("profile_menu>account_settings>skip_revocation_time")}
                                    </Form.Label>
                                </Form.Group>
                                <FetchButton className="outline-button accent" type="submit"
                                             loading={this.state.loading_revocation} loadingText="general>saving">
                                    <FontAwesomeIcon icon={["fal", "save"]} />
                                    <span>{getLanguageEntry("profile_menu>account_settings>accept_revocation_button")}</span>
                                </FetchButton>
                            </form>
                        </div>
                    </div>
                }
                <div className="roomjack-container">
                    <div className="roomjack-container-header">
                        {getLanguageEntry("profile_menu>account_settings>stripe_account_header")}
                    </div>
                    <div id="account-settings-description">
                        {buildTextLinkContainer("description-container mb-3", "profile_menu>account_settings>stripe_description", [{
                            text_path: "Stripe",
                            url: "https://stripe.com/",
                            rel: "noreferrer nofollow",
                            target: "_blank"
                        }])}
                        {
                            this.state.stripe_account_status !== null &&
                            <div id="stripe-account-status">
                                {
                                    !this.state.stripe_account_status.verified &&
                                    <div>
                                        <div className={this.state.stripe_account_completed ? "description-container" : ""}>
                                            <FontAwesomeIcon icon={["fas", "address-card"]} fixedWidth/>
                                            <span>{" " + getLanguageEntry("profile_menu>account_settings>stripe_account_completion_step") + " "}</span>
                                            {
                                                this.state.stripe_account_completed &&
                                                <FontAwesomeIcon style={{color: "forestgreen"}} icon={["fas", "check-circle"]} fixedWidth/>
                                            }
                                            {
                                                !this.state.stripe_account_completed &&
                                                <span>{getLanguageEntry("profile_menu>account_settings>stripe_account_completion")}</span>
                                            }
                                        </div>
                                        <div className={!this.state.stripe_account_completed ? "description-container" : ""}>
                                            <FontAwesomeIcon icon={["fas", "fingerprint"]} fixedWidth/>
                                            <span>{" " + getLanguageEntry("profile_menu>account_settings>stripe_account_verification_step")}</span>
                                            {
                                                this.state.stripe_account_completed &&
                                                <span>{" " + getLanguageEntry("profile_menu>account_settings>stripe_account_verification")}</span>
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.stripe_account_status.verified &&
                                    <div className="mb-1">
                                        <FontAwesomeIcon style={{color: "forestgreen"}} icon={["fas", "check-circle"]} fixedWidth/>
                                        <span>{" " + getLanguageEntry("profile_menu>account_settings>stripe_account_change_step")}</span>
                                    </div>
                                }
                                <div className="description-container mt-3 mb-4">
                                    <span><i>{getLanguageEntry("profile_menu>account_settings>stripe_session_warning")}</i></span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="container-control">
                        {
                            this.state.stripe_account_status === null &&
                            <FetchButton className="outline-button accent"
                                         loading={true}
                                         loadingText="profile_menu>account_settings>starting_stripe_session">
                            </FetchButton>
                        }
                        {
                            this.state.stripe_account_status !== null &&
                            <a id="stripe-session-link" className="outline-button accent"
                               href={this.state.stripe_account_status.account_link} rel="noreferrer nofollow">
                                {getLanguageEntry("profile_menu>account_settings>start_session")}
                            </a>
                        }
                    </div>
                </div>
                <div className="roomjack-container">
                    <div className="roomjack-container-header">
                        {getLanguageEntry("profile_menu>account_settings>account_status_change_header")}
                    </div>
                    <div className="desktop-submenu-grid">
                        <button className="desktop-submenu-tile" onClick={() => { this.account_status.current.show("pause") }}>
                            <div className="tile-icon-container">
                                <FontAwesomeIcon icon={["fal", "pause-circle"]}/>
                            </div>
                            <div className="tile-text-container">
                                {getLanguageEntry("profile_menu>account_settings>pause_account_button")}
                            </div>
                        </button>
                        <button className="desktop-submenu-tile" onClick={() => { this.account_status.current.show("delete") }}>
                            <div className="tile-icon-container">
                                <FontAwesomeIcon icon={["fal", "user-times"]}/>
                            </div>
                            <div className="tile-text-container">
                                {getLanguageEntry("profile_menu>account_settings>delete_account_button")}
                            </div>
                        </button>
                    </div>
                </div>
                <AccountStatus id="account-status-change-modal" ref={this.account_status} {...this.props}/>
            </div>
        )
    }

    skipRevocation(e) {
        e.preventDefault();
        let that = this;
        let update = { skip_withdrawal_period: Date.now() };
        this.setState({loading_revocation: true});
        updateUser(this.props.userData.id, update, function(response) {
            that.setState({ loading_revocation: false });
            if (response.errorCode !== undefined) {
                console.error(response.errorCode + ": " + response.message);
            }
            else {
                that.props.onUpdateUserSession?.(response);
            }
        });
    }

    getRevocationDescription() {
        let withdrawalRemaining = (this.props.userData.reg_ts + WITHDRAWAL_PERIOD) - Date.now();
        let withdrawalRemainingText = millisecondsToTime(withdrawalRemaining, true, false, false, false, true);
        return <div id="revocation-policy-description">
            <strong>{getLanguageEntry("profile_menu>account_settings>skip_revocation_title")}</strong>
            <div className="mb-2">
                {getLanguageEntry("profile_menu>account_settings>revocation_description")}
            </div>
            <div className="mb-2">
                {getLanguageEntry("profile_menu>account_settings>revocation_expiry").replace('#', withdrawalRemainingText)}
            </div>
            {buildTextLinkContainer("mb-3", "profile_menu>account_settings>read_revocation",
                [{text_path: "processes>registration>revocation_policy",
                url: "/widerrufsbelehrung.html",
                rel: "noreferrer nofollow",
                target: "_blank"}])}
        </div>
    }

}

AccountSettings.propTypes = {
    onStatusChangeSuccess: PropTypes.func.isRequired,
    onUpdateUserSession: PropTypes.func.isRequired,
    userData: PropTypes.object,
    mode: PropTypes.string.isRequired
}
export default AccountSettings;