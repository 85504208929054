function InvoiceLogo({className}) {

    const getClassName = function() {
        const classList = ["logo"];
        if (className) {
            classList.push(className);
        }
        return classList.join(" ");
    }

    return <img
        className={getClassName()}
        src={`${process.env.PUBLIC_URL}/roomjack_color.svg`}
        alt="Logo"/>
}
export default InvoiceLogo;