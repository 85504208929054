import React from 'react';
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import '../../css/components/notification-center.css'
import {
    getLanguageEntry,
    getTimestampLabel,
    unpackNotificationData
} from "../utils/Helper";
import Avatar from "./Avatar";
import {Mode, NotificationType} from "../utils/Types.ts";
import {
    syncNotifications,
    updateAdvertReview,
    updateBooking,
    updateUserReview
} from "../utils/RESTInterface";
import NavigatorButton from "./buttons/NavigatorButton";
import {collectHousingListAttributes} from "../utils/Helper";

const NOTIFICATION_SYNC_INTERVAL = 60000; // 1 minute

class NotificationCenter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            sync: false
        };
        this.notification_sync = null;
        this.notification_center = React.createRef();
        this.menu = React.createRef();
    }

    extractNotificationData() {
        let data = this.props.userData.notification_data;
        let newNotifications = [];
        let oldNotifications = [];
        if (data) {
            if (data.conversations !== undefined) {
                for (const conversation of data.conversations) {
                    if (conversation.messages[0].read) {
                        oldNotifications.push(conversation);
                    }
                    else {
                        newNotifications.push(conversation);
                    }
                }
            }
            if (data.bookings !== undefined) {
                for (const bookings of data.bookings) {
                    if (bookings.read) {
                        oldNotifications.push(bookings);
                    }
                    else {
                        newNotifications.push(bookings);
                    }
                }
            }
            if (data.inquiries !== undefined) {
                for (const inquiry of data.inquiries) {
                    if (inquiry.read) {
                        oldNotifications.push(inquiry);
                    }
                    else {
                        newNotifications.push(inquiry);
                    }
                }
            }
            if (data.pending_reviews !== undefined) {
                for (const review of data.pending_reviews) {
                    let old = true;
                    if (this.props.userData.id === review.booker) {
                        if (review.landlord_review === undefined || !review.landlord_review) {
                            old = review.landlord_review_read !== undefined && review.landlord_review_read;
                        }
                        else if (review.housing_review === undefined || !review.housing_review) {
                            old = review.housing_review_read !== undefined && review.housing_review_read;
                        }
                    }
                    else {
                        old = review.booker_review_read !== undefined && review.booker_review_read;
                    }
                    if (old) {
                        oldNotifications.push(review);
                    }
                    else {
                        newNotifications.push(review);
                    }
                }
            }
            if (data.user_reviews !== undefined) {
                for (const review of data.user_reviews) {
                    if (review.read) {
                        oldNotifications.push(review);
                    }
                    else {
                        newNotifications.push(review);
                    }
                }
            }
            if (data.advert_reviews !== undefined) {
                for (const review of data.advert_reviews) {
                    if (review.read) {
                        oldNotifications.push(review);
                    }
                    else {
                        newNotifications.push(review);
                    }
                }
            }
            oldNotifications.sort((a, b) => b.notification_ts - a.notification_ts);
            newNotifications.sort((a, b) => b.notification_ts - a.notification_ts);
        }
        return {
            old_notifications: oldNotifications,
            new_notifications: newNotifications
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.setState(this.extractNotificationData());
        }
    }

    componentDidMount() {
        if (!this.notification_sync) {
            let that = this;
            this.notification_sync = setInterval(() => {
                that.setState({sync: true});
                syncNotifications((response) => {
                    let user = JSON.parse(JSON.stringify(that.props.userData));
                    let actualNotificationData = user.notification_data ? JSON.stringify(user.notification_data) : "";
                    if (response.notifications) {
                        unpackNotificationData(response.notifications);
                        user.notification_data = response.notifications;
                    }
                    if (JSON.stringify(response.notifications) !== actualNotificationData) {
                        that.props.onUpdateUserData?.(user, () => {
                            that.setState({sync: false})
                        });
                    }
                    else {
                        that.setState({sync: false})
                    }
                })
            }, NOTIFICATION_SYNC_INTERVAL);
        }
    }

    componentWillUnmount() {
        if (this.notification_sync) {
            clearInterval(this.notification_sync);
        }
    }

    render() {
        let notifications = this.extractNotificationData();
        let newNotifications = this.getModeRelevantNotifications(notifications.new_notifications);
        let oldNotifications = this.getModeRelevantNotifications(notifications.old_notifications);
        return(
            <div className="notification-bell" tabIndex="0" ref={this.notification_center}
                 onClick={() => { this.toggle() }}>
                {
                    !this.state.sync &&
                    <FontAwesomeIcon icon={["fal", "bell"]} />
                }
                {
                    this.state.sync &&
                    <FontAwesomeIcon icon={["fal", "rotate"]} spin={true} />
                }
                <div className="notifications" tabIndex="0">
                    {
                        newNotifications.length > 0 &&
                        <div className="notification-marker">
                            {
                                newNotifications.length > 3 ?
                                "3+" : "" + newNotifications.length
                            }
                        </div>
                    }
                    {
                        this.state.show &&
                        <div className="notification-center main-banner-content-container" ref={this.menu}
                             tabIndex="0" onBlur={(e) => { this.handleFocus(e) }}>
                            <div className="notification-header">
                                <div className="notification-label">{getLanguageEntry("main_banner>notification_center>title")}</div>
                            </div>
                            {
                                oldNotifications.length === 0 && newNotifications.length === 0 &&
                                <div className="no-notifications">
                                    <img src="https://roomjack.blob.core.windows.net/roomjack/content-images/megaphone.png" alt="Megaphone"/>
                                    <div className="description-container">
                                        {getLanguageEntry("main_banner>notification_center>placeholder")}
                                    </div>
                                </div>
                            }
                            {
                                (oldNotifications.length > 0 || newNotifications.length > 0) &&
                                <div className="notification-main-container">
                                    <div className="new-notifications">
                                        <div className="notification-type-label">
                                            {getLanguageEntry("main_banner>notification_center>new_label")}
                                        </div>
                                        <div className="notifications-container">
                                            {
                                                newNotifications.length === 0 &&
                                                <div className="description-container">
                                                    {getLanguageEntry("main_banner>notification_center>new_placeholder")}
                                                </div>
                                            }
                                            {
                                                newNotifications.map((n, i) => {
                                                    return this.createNotification(n, i, 'new');
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="old-notifications">
                                        <div className="notification-type-label">
                                            {getLanguageEntry("main_banner>notification_center>old_label")}
                                        </div>
                                        <div className="notifications-container">
                                            {
                                                oldNotifications.length === 0 &&
                                                <div className="description-container">
                                                    {getLanguageEntry("main_banner>notification_center>old_placeholder")}
                                                </div>
                                            }
                                            {
                                                oldNotifications.map((n, i) => {
                                                    return this.createNotification(n, i, 'old');
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }

    toggle() {
        if (this.state.show) {
            this.hide();
        }
        else {
            this.show();
        }
    }

    show() {
        this.setState({ show: true }, () => {
            if (this.menu.current !== null) {
                this.menu.current.focus();
            }
        });
    }

    hide() {
        this.setState({ show: false });
    }

    handleFocus(e) {
        if (!this.notification_center.current.contains(e.relatedTarget)) {
            this.hide();
        }
    }

    getModeRelevantNotifications(notificationList) {
        if (notificationList.length === 0) {
            return [];
        }
        let result = [];
        for (const notification of notificationList) {
            if (// count message always
                notification.notification_type === NotificationType.message ||
                // count booking, inquiry and pending review if the user is the booker and in renter mode
                // or if the user is the landlord and in landlord mode
                ((notification.notification_type === NotificationType.booking ||
                  notification.notification_type === NotificationType.inquiry ||
                  notification.notification_type === NotificationType.pending_review) &&
                 ((notification.booker === this.props.userData.id && this.props.mode === Mode.renter) ||
                  (notification.booker !== this.props.userData.id && this.props.mode === Mode.landlord))) ||
                // count review if the advert_id is set and landlord mode or not and renter mode
                (notification.notification_type === NotificationType.review &&
                (this.props.mode === Mode.landlord || notification.advert_id === undefined))) {
                result.push(notification);
            }
        }
        return result;
    }

    createNotification(notification, index, type) {
        let userData;
        let typeText = '';
        let typeIcon;
        let headerText = '';
        let image;
        let url; let onClick;
        switch (notification.notification_type) {
            case 'message':
                userData = notification.user1 instanceof Object ?
                    notification.user1 : notification.user2;
                typeText = getLanguageEntry("main_banner>notification_center>types>message");
                typeIcon = ["fas" ,"comment-dots"];
                headerText = userData.first_name;
                url = "/desktop/messages?conversation=" + notification.id;
                if (notification.messages.filter(m => !m.read).length > 0) {
                    onClick = () => {
                        let user = JSON.parse(JSON.stringify(this.props.userData));
                        if (user.notification_data && user.notification_data.conversations) {
                            let conversation =
                                user.notification_data.conversations.filter(i => i.id === notification.id)[0];
                            if (conversation && conversation.messages) {
                                conversation.messages.forEach(m => m.read = true);
                                this.props.onUpdateUserData?.(user);
                            }
                        }
                    }
                }
                break;
            case 'booking':
            case 'inquiry':
            case 'pending_review':
                userData = notification.owner instanceof Object ?
                    notification.owner : notification.booker;
                if (notification.notification_type === NotificationType.booking) {
                    if (notification.cancellation_ts &&
                        notification.cancelled_by !== this.props.userData.id) {
                        typeIcon = ["fas", "calendar-xmark"];
                        let text = notification.cancelled_by === notification.booker ?
                            "cancelled_booking" : "cancelled_booking_by_landlord"
                        typeText = getLanguageEntry("main_banner>notification_center>types>" + text);
                        url = "/desktop/bookings/cancelled?id=" + notification.id;
                        onClick = () => {
                            if (!notification.read) {
                                updateBooking(notification.id, {read: true});
                                let user = JSON.parse(JSON.stringify(this.props.userData));
                                if (user.notification_data && user.notification_data.bookings) {
                                    let booking =
                                        user.notification_data.bookings.filter(i => i.id === notification.id)[0];
                                    if (booking) {
                                        booking.read = true;
                                        this.props.onUpdateUserData?.(user);
                                    }
                                }
                            }
                        }
                    }
                    else if (notification.booking_type === 'inquiry' && notification.booker === this.props.userData.id) {
                        typeIcon = ["fas", "calendar-check"];
                        typeText = getLanguageEntry("main_banner>notification_center>types>booking_invitation");
                        url = "/desktop/messages?conversation=" + notification.conversation_id;
                        onClick = () => {
                            if (!notification.read) {
                                updateBooking(notification.id, {read: true});
                                let user = JSON.parse(JSON.stringify(this.props.userData));
                                if (user.notification_data && user.notification_data.bookings) {
                                    let booking =
                                        user.notification_data.bookings.filter(i => i.id === notification.id)[0];
                                    if (booking) {
                                        booking.read = true;
                                        this.props.onUpdateUserData?.(user);
                                    }
                                }
                            }
                        }
                    }
                    else {
                        typeIcon = ["fas", "calendar-days"];
                        typeText = getLanguageEntry("main_banner>notification_center>types>new_booking");
                        url = "/desktop/bookings/upcoming?id=" + notification.id;
                        onClick = () => {
                            if (!notification.read) {
                                updateBooking(notification.id, {read: true});
                                let user = JSON.parse(JSON.stringify(this.props.userData));
                                if (user.notification_data && user.notification_data.bookings) {
                                    let booking =
                                        user.notification_data.bookings.filter(i => i.id === notification.id)[0];
                                    if (booking) {
                                        booking.read = true;
                                        this.props.onUpdateUserData?.(user);
                                    }
                                }
                            }
                        }
                    }
                    headerText = userData.first_name;
                }
                else if (notification.notification_type === NotificationType.inquiry) {
                    typeIcon = ["fas", "calendar-circle-plus"];
                    typeText = getLanguageEntry("main_banner>notification_center>types>inquiry");
                    headerText = userData.first_name;
                    url = "/desktop/messages?conversation=" + notification.conversation_id;
                    console.log(notification);
                    onClick = () => {
                        if (!notification.read) {
                            updateBooking(notification.id, {read: true});
                            let user = JSON.parse(JSON.stringify(this.props.userData));
                            if (user.notification_data && user.notification_data.inquiries) {
                                let inquiry =
                                    user.notification_data.inquiries.filter(i => i.id === notification.id)[0];
                                if (inquiry) {
                                    inquiry.read = true;
                                    this.props.onUpdateUserData?.(user);
                                }
                            }
                        }
                    }
                }
                else {
                    typeIcon = ["fas", "fa-star"];
                    if (notification.booker === this.props.userData.id) {
                        if (!notification.housing_review_read) {
                            image = collectHousingListAttributes(notification.advert).images[0];
                            if (!image) {
                                image = "https://roomjack.blob.core.windows.net/roomjack/content-images/placeholder-image.png";
                            }
                            typeText = getLanguageEntry("main_banner>notification_center>types>pending_advert_review")
                                .replace('#', notification.title);
                            onClick = () => {
                                this.props.onCreateReview?.(notification);
                                if (!notification.housing_review_read) {
                                    updateBooking(notification.id, {housing_review_read: true});
                                    let user = JSON.parse(JSON.stringify(this.props.userData));
                                    if (user.notification_data && user.notification_data.pending_reviews) {
                                        let review =
                                            user.notification_data.pending_reviews.filter(i => i.id === notification.id)[0];
                                        if (review) {
                                            review.housing_review_read = true;
                                            this.props.onUpdateUserData?.(user);
                                        }
                                    }
                                }
                            }
                        }
                        else {
                            typeText =
                                getLanguageEntry("main_banner>notification_center>types>pending_landlord_review")
                                .replace('#', userData.first_name);
                            onClick = () => {
                                this.props.onCreateReview?.(notification);
                                if (!notification.landlord_review_read) {
                                    updateBooking(notification.id, {landlord_review_read: true});
                                    let user = JSON.parse(JSON.stringify(this.props.userData));
                                    if (user.notification_data && user.notification_data.pending_reviews) {
                                        let review =
                                            user.notification_data.pending_reviews.filter(i => i.id === notification.id)[0];
                                        if (review) {
                                            review.landlord_review_read = true;
                                            this.props.onUpdateUserData?.(user);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    else {
                        typeText = getLanguageEntry("main_banner>notification_center>types>pending_booker_review")
                            .replace('#', userData.first_name);
                        onClick = () => {
                            this.props.onCreateReview?.(notification);
                            if (!notification.booker_review_read) {
                                updateBooking(notification.id, {booker_review_read: true});
                                let user = JSON.parse(JSON.stringify(this.props.userData));
                                if (user.notification_data && user.notification_data.pending_reviews) {
                                    let review =
                                        user.notification_data.pending_reviews.filter(i => i.id === notification.id)[0];
                                    if (review) {
                                        review.booker_review_read = true;
                                        this.props.onUpdateUserData?.(user);
                                    }
                                }
                            }
                        }
                    }
                }
                break;
            default:
                userData = notification.author;
                headerText = userData.first_name;
                typeIcon = ["fas", "fa-star"];
                let text = notification.advert_id !== undefined ? "advert_review" : "user_review";
                typeText = getLanguageEntry("main_banner>notification_center>types>" + text);
                url = "/desktop/reviews?type=";
                if (notification.advert_id) {
                    url += "advert";
                    onClick = () => {
                        if (!notification.read) {
                            updateAdvertReview(notification.advert_id, notification.author_id, true);
                            let user = JSON.parse(JSON.stringify(this.props.userData));
                            if (user.notification_data && user.notification_data.advert_reviews) {
                                let review =
                                    user.notification_data.advert_reviews.filter(i => i.id === notification.id)[0];
                                if (review) {
                                    review.read = true;
                                    this.props.onUpdateUserData?.(user);
                                }
                            }
                        }
                    }
                }
                else {
                    url += "user";
                    onClick = () => {
                        if (!notification.read) {
                            updateUserReview(notification.author_id, true);
                            let user = JSON.parse(JSON.stringify(this.props.userData));
                            if (user.notification_data && user.notification_data.user_reviews) {
                                let review =
                                    user.notification_data.user_reviews.filter(i => i.id === notification.id)[0];
                                if (review) {
                                    review.read = true;
                                    this.props.onUpdateUserData?.(user);
                                }
                            }
                        }
                    }
                }
                break;
        }

        if (url) {
            return <NavigatorButton to={url} className="notification-item" onBeforeClick={onClick}
                                    key={type + "-notification-" + index}>
                <Avatar size={40} data={userData.avatar} />
                <div className="notification-content">
                    <div className="notification-header">
                        <div className="notification-title">{headerText}</div>
                        <div className="notification-timestamp">{getTimestampLabel(notification.notification_ts)}</div>
                    </div>
                    <div className="notification-body">{typeText}</div>
                </div>
                <div className="notification-type-icon">
                    <FontAwesomeIcon icon={typeIcon}/>
                </div>
            </NavigatorButton>
        }
        else {
            return <button className="notification-item" key={type + "-notification-" + index}
                           onClick={onClick}>
                <Avatar size={40} data={userData.avatar} />
                <div className="notification-content">
                    <div className="notification-header">
                        <div className="notification-title">{headerText}</div>
                        <div className="notification-timestamp">{getTimestampLabel(notification.notification_ts)}</div>
                    </div>
                    <div className="notification-body">{typeText}</div>
                </div>
                <div className="notification-type-icon">
                    <FontAwesomeIcon icon={typeIcon}/>
                </div>
            </button>
        }
    }

}
NotificationCenter.propTypes = {
    userData: PropTypes.any.isRequired,
    mode: PropTypes.oneOf(Object.values(Mode)).isRequired,
    onUpdateUserData: PropTypes.func.isRequired,
    onCreateReview: PropTypes.func.isRequired
}
export default NotificationCenter;