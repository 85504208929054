import React from 'react';
import Dropdown from "./Dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getLanguageEntry, isLeapYear, LeapYearDays, NormalYearDays} from "../../utils/Helper";
import PropTypes from "prop-types";
import "../../../css/components/input/date-picker.css";

class DatePicker extends React.Component {

    constructor(props) {
        super(props);
        this.year = React.createRef();
        this.month = React.createRef();
        this.day = React.createRef();
        this.state = {
            year: !this.props.initialValue ? -1 : this.props.initialValue.year,
            month: !this.props.initialValue ? -1 : this.props.initialValue.month,
            day: !this.props.initialValue  ? -1 : this.props.initialValue.day
        }
    }

    render() {
        return (
            <div className="date-picker">
                <Dropdown ref={this.day} name="day" items={this.getDays()} onChange={(e) => { this.select(e, this.day) }}
                          initialValue={!!this.props.initialValue ? this.props.initialValue.day : undefined}/>
                <Dropdown ref={this.month} name="month" items={this.getMonths()} onChange={(e) => { this.select(e, this.month) }}
                          initialValue={!!this.props.initialValue ? this.props.initialValue.month : undefined}/>
                <Dropdown ref={this.year} name="year" items={this.getYears()} onChange={(e) => { this.select(e, this.year) }}
                          initialValue={!!this.props.initialValue ? this.props.initialValue.year : undefined} />
                {
                    (this.state.year !== -1 || this.state.month !== -1 || this.state.day !== -1) &&
                    <button onClick={() => { this.reset() }}>
                        <FontAwesomeIcon icon={["fal", "trash-alt"]}/>
                    </button>
                }
            </div>
        );
    }
    
    reset() {
        this.year.current.value = null;
        this.month.current.value = null;
        this.day.current.value = null;
        this.setState({
            year: -1,
            month: -1,
            day: -1,
            validate: false
        })
    }
    
    getYears() {
        let items = [];
        let actualYear = new Date().getFullYear();
        for (let i=0; i<100; i++) {
            let year = actualYear - i;
            items.push({ value: year, label: "" + year })
        }
        return items;
    }

    getMonths() {
        let items = [];
        for (let i=1; i<13; i++) {
            items.push({ value: i, label: getLanguageEntry("general>month_names>" + (i-1)) });
        }
        return items;
    }

    getDays() {
        let dayCount = this.state.year === -1 || this.state.month === -1 ? 31 :
            isLeapYear(this.state.year) ?
                LeapYearDays[this.state.month - 1] : NormalYearDays[this.state.month - 1];
        let items = [];
        for (let i=1; i<=dayCount; i++) {
            items.push({ value: i, label: (i < 10 ? "0" : "") + i })
        }
        return items;
    }

    select(e, ref) {
        e.preventDefault();
        if (ref.current !== null) {
            if (ref === this.year) {
                if (this.state.year !== ref.current.value) {
                    this.setState({year: ref.current.value});
                }
            }
            if (ref === this.month) {
                if (this.state.month !== ref.current.value) {
                    this.setState({month: ref.current.value});
                }
            }
            if (ref === this.day) {
                if (this.state.day !== ref.current.value) {
                    this.setState({day: ref.current.value});
                }
            }
        }
    }

    get value() {
        let year = this.year.current.value;
        let month = this.month.current.value;
        let day = this.day.current.value;
        if (year === null || month === null || day === null) {
            return null;
        }
        return year + "-" + ((month < 10 ? "0" : "") + month) + "-" + ((day < 10 ? "0" : "") + day);
    }

    getDate() {
        let year = this.year.current.value;
        let month = this.month.current.value;
        let day = this.day.current.value;
        if (year === null || month === null || day === null) {
            return null;
        }
        return new Date(year, month, day)
    }

    validate() {
        if (this.year.current.value === null) {
            this.year.current.setInvalid();
        }
        if (this.month.current.value === null) {
            this.month.current.setInvalid();
        }
        if (this.day.current.value === null) {
            this.day.current.setInvalid();
        }
    }

}
DatePicker.propTypes = {
    initialValue: PropTypes.exact({
        year: PropTypes.number.isRequired,
        day: PropTypes.number.isRequired,
        month: PropTypes.number.isRequired,
    })
}
export default DatePicker;