import React from 'react';
import {Col, Form, Modal, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import md5 from "md5";
import {
    getLanguageEntry, isValidPassword, buildTextLinkContainer, createCountryDropdown, createModeDropdown,
    EMAIL, PASSWORD, SUPPORT_MAIL_ADDRESS, FIRST_NAME, LAST_NAME, PREFERRED_MENU_MODE,
    COUNTRY, CITY, POSTAL_CODE, LINE_1, PHONE, COMPANY, MAX_COMPANY_NAME_LENGTH
} from "../../utils/Helper";
import PropTypes from "prop-types";
import '../../../css/components/modals/registration.css'
import SecretInputField from "../input/SecretInputField";
import PhoneInputField from "../input/PhoneInputField";
import {checkEmailValidity, createAccount} from "../../utils/RESTInterface";
import NavigatorButton from "../buttons/NavigatorButton";
import Autocomplete from "../input/Autocomplete";
import FetchButton from "../buttons/FetchButton";
import {AccountType, PolicyType} from "../../utils/Types.ts";

class Registration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            server_response: null,
            show: false,
            step: 0,
            registration_data: {},
            can_resend: true,
            verification_error_message: '',
            password_error_message: null,
            loading: false,
            loading_text: "",
            resending: false,
            mode: "",
            account_type: null
        }
        this.step_0_form = React.createRef();
        this.verification_code = React.createRef();
        this.step_2_form = React.createRef();
        this.postal_code = React.createRef();
        this.city = React.createRef();
        this.country = React.createRef();
    }
    
    render() {
        return (
            <Modal id={this.props.id} className={this.props.className} show={this.state.show} backdrop="static"
                   keyboard={false} onHide={() => { this.hide() }} dialogClassName="max-content" centered>
                <Modal.Header>
                    <h4>{this.getTitle()}</h4>
                    <button onClick={() => { this.hide() }}>
                        <FontAwesomeIcon icon={["fal", "close"]}/>
                    </button>
                </Modal.Header>

                <Modal.Body>
                    {
                        !this.state.account_type &&
                        <div className="account-type-selection">
                            <div className="description-container">
                                {getLanguageEntry("processes>registration>account_type_description")}
                            </div>
                            <div className="desktop-submenu-grid">
                                <button className="desktop-submenu-tile" onClick={() => {this.setState({account_type: AccountType.private})}}>
                                    <div className="tile-icon-container">
                                        <FontAwesomeIcon icon={["fal", "user"]}/>
                                    </div>
                                    <div className="tile-text-container">
                                        {getLanguageEntry("processes>registration>private_label")}
                                    </div>
                                </button>
                                <button className="desktop-submenu-tile landlord" onClick={() => {this.setState({account_type: AccountType.b2b})}} >
                                    <div className="tile-icon-container">
                                        <FontAwesomeIcon icon={["fal", "user-tie"]}/>
                                    </div>
                                    <div className="tile-text-container">
                                        {getLanguageEntry("processes>registration>b2b_label")}
                                    </div>
                                </button>
                            </div>
                            <div className="description-container">
                                <FontAwesomeIcon icon={["fal", "exclamation-circle"]}/>
                                {getLanguageEntry("processes>registration>account_type_warning")}
                            </div>
                        </div>
                    }
                    {
                        this.state.step === 0 && this.state.account_type &&
                        <div>
                            <form onSubmit={(e) => { this.startEmailValidation(e) }}
                                  ref={this.step_0_form}>
                                {
                                    this.state.account_type === AccountType.b2b &&
                                    <div className="mb-3" style={{display: "grid"}}>
                                        <label className="description-container v-form-label mb-2" htmlFor={COMPANY}>
                                            {getLanguageEntry("general>company")}*
                                        </label>
                                        <input type="text" id={COMPANY} required={true} name={COMPANY}
                                               maxLength={MAX_COMPANY_NAME_LENGTH}/>
                                    </div>
                                }
                                <div className="horizontal-form-group mb-3">
                                    <div style={{display: "grid", flex: "1"}}>
                                        <label className="description-container v-form-label mb-2" htmlFor={FIRST_NAME}>
                                            {getLanguageEntry("general>first_name")}*
                                        </label>
                                        <input type="text" id={FIRST_NAME} required={true} name={FIRST_NAME}/>
                                    </div>
                                    <div style={{display: "grid", flex: "1"}}>
                                        <label className="description-container v-form-label mb-2" htmlFor={LAST_NAME}>
                                            {getLanguageEntry("general>last_name")}*
                                        </label>
                                        <input type="text" id={LAST_NAME} required={true} name={LAST_NAME}/>
                                    </div>
                                </div>
                                <div className="mb-3" style={{display: "grid"}}>
                                    <label className="description-container v-form-label mb-2" htmlFor={EMAIL}>
                                        {getLanguageEntry("general>email")}*
                                    </label>
                                    <input type={EMAIL} id={EMAIL} required={true} name={EMAIL}
                                           placeholder={getLanguageEntry("general>email_placeholder")}/>
                                </div>
                                <div id="email-validation-msg-box" className="description-container mb-3">
                                    {this.getRegistrationErrorMessage()}
                                </div>
                                <div className="description-container mb-5">
                                    {this.getLegalLinkContent(PolicyType.privacy)}
                                </div>
                                <div className="horizontal-form-group verification-step">
                                    <button className="outline-button gray" onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({account_type: null});
                                    }}>
                                        <FontAwesomeIcon icon={["fas", "sync"]}/>
                                        {getLanguageEntry("processes>registration>change_account_type")}
                                    </button>
                                    <FetchButton className="outline-button accent margin" type="submit"
                                                 loading={this.state.loading} loadingText={this.state.loading_text}>
                                        <FontAwesomeIcon icon={["fas", "arrow-right"]}/>
                                        <span>{getLanguageEntry("processes>registration>step_0_button_label")}</span>
                                    </FetchButton>
                                </div>
                            </form>
                        </div>
                    }
                    {
                        this.state.step === 1 && this.state.account_type &&
                        <div id="registration-welcome">
                            <div id="registration-welcome-image" className="mb-4"></div>
                            <div id="registration-welcome-text" className="mb-5">
                                {getLanguageEntry("processes>registration>step_1_welcome_text").replace('#', this.state.registration_data.first_name)}
                            </div>
                            <div id="registration-step-1-description" className="description-container mb-3">
                                {getLanguageEntry("processes>registration>step_1_description")}</div>
                            <div id="verification-code-group" className="mb-3">
                                <Form.Control placeholder={getLanguageEntry("general>verification_code")}
                                              ref={this.verification_code}
                                              onChange={(e) => { this.activateDataStep(e) }} />
                                {
                                    this.state.verification_error_message !== '' &&
                                    <div className="description-container mt-2">{this.state.verification_error_message}</div>
                                }
                            </div>
                            {
                                this.state.can_resend &&
                                <div id="verification-help" className="mb-4">
                                    <FetchButton className="link-button" loading={this.state.resending}
                                                 loadingText={"processes>registration>resending"}
                                                 onClick={(e) => { this.resendVerificationCode(e) }}>
                                        {getLanguageEntry("processes>registration>step_1_no_email_received")}
                                    </FetchButton>
                                </div>
                            }
                        </div>
                    }
                    {
                        this.state.step === 2 && this.state.account_type &&
                        <div>
                            <form ref={this.step_2_form} onSubmit={(e) => { this.createAccount(e); }}>
                                <Form.Group className="mb-2" as={Col}>
                                    <Form.Label className="description-container v-form-label" column>
                                        {getLanguageEntry("processes>registration>step_2_mode_description")}
                                    </Form.Label>
                                    {createModeDropdown(PREFERRED_MENU_MODE, null, null,
                                        (e, value) => { this.onModeChanged(e, value); }, true)}
                                </Form.Group>
                                <Form.Group className="mb-2" as={Col}>
                                    <Form.Label className="description-container v-form-label" column>
                                        {getLanguageEntry("processes>registration>your_address") + " - " +
                                            getLanguageEntry("general>street") + ", " +
                                            getLanguageEntry("general>street_no")}
                                    </Form.Label>
                                    <Form.Group>
                                        <Autocomplete id="registration-autocomplete" name={LINE_1}
                                                      onChange={(e) => { this.resetAddress(e) } }
                                                      onPlaceSelected={(address_line, place) => { this.onPlaceSelected(address_line, place); }} />
                                    </Form.Group>
                                </Form.Group>
                                <Form.Group className="mb-2" as={Row}>
                                    <Form.Group id="registration-address-postal-code-group" as={Col} >
                                        <Form.Label className="description-container v-form-label" column>
                                            {getLanguageEntry("general>postal_code")}
                                        </Form.Label>
                                        <Form.Control type="text" name={POSTAL_CODE} ref={this.postal_code}
                                                      onChange={(e) => { this.resetAddress(e) } }/>
                                    </Form.Group>
                                    <Form.Group id="registration-address-city-group" as={Col}>
                                        <Form.Label className="description-container v-form-label" column>
                                            {getLanguageEntry("general>city")}
                                        </Form.Label>
                                        <Form.Control type="text" name={CITY} ref={this.city}
                                                      onChange={(e) => { this.resetAddress(e) } }/>
                                    </Form.Group>
                                </Form.Group>
                                <Form.Group className="mb-2" as={Col}>
                                    <Form.Label className="description-container v-form-label" column>
                                        {getLanguageEntry("general>country")}
                                    </Form.Label>
                                    {createCountryDropdown(COUNTRY, this.country, (e) => { this.resetAddress(e) })}
                                </Form.Group>
                                <Form.Group id="registration-phone" className="mb-2" as={Col}>
                                    <Form.Label className="description-container v-form-label" column>
                                        {getLanguageEntry("general>phone")}
                                    </Form.Label>
                                    <PhoneInputField name={PHONE}/>
                                </Form.Group>
                                <Form.Group className="mb-2" as={Col} controlId={PASSWORD}>
                                    <Form.Label className="description-container v-form-label" column>
                                        {getLanguageEntry("general>password")}*
                                    </Form.Label>
                                    <SecretInputField name={PASSWORD} isValid={isValidPassword}
                                                      onChange={() => { this.resetPasswordError() }}
                                                      tooltip={"general>password_info"} required />
                                </Form.Group>
                                <Form.Group className="mb-2" as={Col} controlId="confirm_password">
                                    <Form.Label className="description-container v-form-label" column>
                                        {getLanguageEntry("general>confirm_password")}*
                                    </Form.Label>
                                    <SecretInputField name="confirm_password"
                                                      onChange={() => { this.resetPasswordError() }}
                                                      isValid={(p) => { return this.isConfirmPasswortValid(p) }} required />
                                </Form.Group>
                                <Form.Group className="description-container" as={Col}>
                                    {this.getLegalLinkContent(PolicyType.terms)}
                                    {this.getLegalLinkContent(PolicyType.privacy)}
                                    {this.getLegalLinkContent(PolicyType.revocation)}
                                </Form.Group>
                                {
                                    this.state.password_error_message !== null &&
                                    <div className="description-container mt-3">
                                        {getLanguageEntry(this.state.password_error_message)}
                                    </div>
                                }
                                <FetchButton type="submit" className="outline-button accent margin mt-5"
                                             loading={this.state.loading} loadingText={this.state.loading_text}>
                                    <FontAwesomeIcon icon={["fal", "user-plus"]}/>
                                    <span>
                                        {getLanguageEntry("processes>registration>step_2_button_label")}
                                    </span>
                                </FetchButton>
                            </form>
                        </div>
                    }
                    {
                        this.state.step === 3 && this.state.account_type &&
                        <div id="registration-result">{this.getRegistrationResult()}</div>
                    }
                </Modal.Body>
            </Modal>
        )
    }

    getTitle() {
        if (this.state.step < 3) {
            return getLanguageEntry("processes>registration>step_" + this.state.step + "_header");
        }
        return "";
    }

    reset() {
        this.setState({
            server_response: null,
            show: false,
            step: 0,
            registration_data: {},
            can_resend: true,
            verification_error_message: '',
            password_error_message: null,
            loading: false,
            loading_text: "",
            resending: false,
            mode: "",
            account_mode: null
        });
    }

    show(accountType=null) {
        this.setState({
            show: true,
            step: 0,
            account_type: accountType
        });
    }

    hide() {
        this.setState({ show: false }, () => { this.reset() });
    }

    getRegistrationErrorMessage() {
        if (this.state.server_response === null) {
            return '';
        }
        if (this.state.server_response.errorCode !== undefined) {
            let errorCode = this.state.server_response.errorCode;
            return getLanguageEntry('error_codes>' + errorCode);
        }
        return '';
    }

    isVerificationCodeRight() {
        return md5(this.verification_code.current.value) === this.state.server_response.verification_code;
    }

    getLegalLinkContent(policyType) {
        if (policyType === PolicyType.privacy) {
            return buildTextLinkContainer("description-container",
                "processes>registration>privacy_policy_label",
                [{
                    text_path: "processes>registration>privacy_policy",
                    url: "/privacy-policy.html",
                    rel: "noreferrer nofollow",
                    target: "_blank"
                }]);
        }
        if (policyType === PolicyType.revocation) {
            return buildTextLinkContainer("description-container",
                "processes>registration>revocation_policy_label",
                [{
                    text_path: "processes>registration>revocation_policy",
                    url: "/widerrufsbelehrung.html",
                    rel: "noreferrer nofollow",
                    target: "_blank"
                }]);
        }
        if (policyType === PolicyType.terms) {
            return buildTextLinkContainer("description-container",
                "processes>registration>terms_and_conditions_label",
                [{
                    text_path: "processes>registration>terms_and_conditions",
                    url: "/agb.html",
                    rel: "noreferrer nofollow",
                    target: "_blank"
                }]);
        }
        return '';
    }

    isConfirmPasswortValid(password) {
        let data = new FormData(this.step_2_form.current);
        return isValidPassword(password) && data.get(PASSWORD) === password;
    }

    resetPasswordError() {
        this.setState({ password_error_message: null });
    }

    onPlaceSelected(address_line, place) {
        let address = place.address_components;
        let regData = this.state.registration_data;
        regData.address = {
            line_1: address_line
        };
        //region postal code
        let postalCodeElem = address.filter(ac => ac.types[0] === 'postal_code')[0];
        if (postalCodeElem !== undefined) {
            this.postal_code.current.value = postalCodeElem.long_name;
            regData.address.postal_code = postalCodeElem.long_name;
        }
        //endregion
        //region city
        let locationElem = address.filter(ac => ac.types[0] === 'locality')[0];
        if (locationElem !== undefined) {
            this.city.current.value = locationElem.long_name;
            regData.address.city = locationElem.long_name;
        }
        //endregion
        //region country
        let countryElem = address.filter(ac => ac.types[0] === 'country')[0];
        if (countryElem !== undefined) {
            this.country.current.value = countryElem.short_name;
            regData.address.country = countryElem.short_name.toLowerCase();
        }
        //endregion
    }

    resetAddress(e) {
        e.preventDefault();
        let regData = this.state.registration_data;
        if (regData !== null && regData.address !== undefined) {
            delete regData.address;
            this.setState({registration_data: regData});
        }
    }

    //region STEP 1
    // The first registration step: first-, last name and email input
    // verification trial of the email address
    startEmailValidation(e) {
        e.preventDefault();
        let that = this;
        let data = new FormData(this.step_0_form.current);
        let b2b = this.state.account_type === AccountType.b2b;
        let regData = {
            email: data.get(EMAIL).toLowerCase(),
            first_name: data.get(FIRST_NAME),
            last_name: data.get(LAST_NAME),
            b2b: b2b
        };
        if (b2b) {
            regData.company = data.get(COMPANY);
        }
        this.setState({
            loading: true,
            loading_text: "processes>registration>step_0_proof_email"
        })
        checkEmailValidity(regData.email, regData.first_name + ' ' + regData.last_name, function (response) {
            let state = {
                server_response: response,
                registration_data: regData,
                loading: false,
                loading_text: ""
            };
            if (!response.errorCode && response.verification_code) {
                state.step = 1;
            }
            that.setState(state);
        });
    }
    //endregion

    //region STEP 2
    // The second registration step: proofing the confirmation code
    resendVerificationCode(e) {
        e.preventDefault();
        if (this.state.step === 1 && this.state.can_resend &&
            this.state.registration_data !== null) {
            let that = this;
            this.setState({
                resending: true
            })
            checkEmailValidity(this.state.registration_data.email,
                this.state.registration_data.first_name + ' ' + this.state.registration_data.last_name,
                function (response) {
                    that.setState({
                        server_response: response,
                        can_resend: false,
                        resending: false
                    });
                    setTimeout(function () {
                        that.setState({ can_resend: true });
                    }, 60000);
                });
        }
    }

    activateDataStep(e) {
        e.preventDefault();
        if (this.verification_code.current !== null && this.verification_code.current.value.length !== 6) {
            this.setState({ verification_error_message: '' });
        }
        else if (this.isVerificationCodeRight()) {
            this.setState({ verification_error_message: '', step: 2 });
        }
        else {
            this.setState({ verification_error_message: getLanguageEntry("processes>registration>verification_code_invalid") });
        }
    }
    //endregion

    //region STEP 3
    onModeChanged(e, mode) {
        e.preventDefault();
        this.setState({ mode: mode });
    }

    createAccount(e) {
        e.preventDefault();
        let data = new FormData(this.step_2_form.current);
        if (!isValidPassword(data.get(PASSWORD))) {
            this.setState({ password_error_message: "general>password_error" });
        }
        else if (data.get(PASSWORD) !== data.get("confirm_password")) {
            this.setState({ password_error_message: "general>confirm_password_error" });
        }
        else {
            this.setState({
                password_error_message: null,
                loading: true,
                loading_text: "processes>registration>creating_account"
            });
            let that = this;
            let regData = this.state.registration_data;
            regData.password = md5(data.get(PASSWORD));
            regData.preferred_menu_mode = data.get(PREFERRED_MENU_MODE);
            if (regData.address === undefined) {
                let address = {};
                if (data.get(LINE_1) !== null && data.get(LINE_1).length > 0) {
                    address.line_1 = data.get(LINE_1);
                }
                if (data.get(POSTAL_CODE) !== null && data.get(POSTAL_CODE).length > 0) {
                    address.postal_code = data.get(POSTAL_CODE);
                }
                if (data.get(CITY) !== null && data.get(CITY).length > 0) {
                    address.city = data.get(CITY);
                }
                if (data.get(COUNTRY) !== null && data.get(COUNTRY).length > 0) {
                    address.country = data.get(COUNTRY);
                }
                if (Object.keys(address).length > 0) {
                    regData.address = address;
                }
            }
            if (data.get(PHONE) !== null && data.get(PHONE).length > 0) {
                regData.phone = '+' + data.get(PHONE);
            }

            createAccount(regData, function(response) {
                that.setState({
                    step: 3,
                    server_response: response,
                    loading: false,
                    loading_text: ""
                }, () => {
                    that.props.onRegister?.(response);
                })
            });
        }
    }
    //endregion

    //region RESULT
    getRegistrationResult() {
        if (this.state.step === 3) {
            if (this.state.server_response.errorCode !== undefined) {
                return <div>
                    <div className="process-result-image failure">
                        <img src="https://roomjack.blob.core.windows.net/roomjack/email-images/sad_bear_honey.png" alt="Sad bear"/>
                    </div>
                    <div className="description-container">
                        <span>{getLanguageEntry("processes>registration>registration_fail")}</span>
                        <a href={"mailto:" + SUPPORT_MAIL_ADDRESS}>
                            {getLanguageEntry("general>contact_support")}
                        </a>
                    </div>
                </div>
            }
            else {
                return <div>
                    <div className="process-result-image">
                        <img src="https://roomjack.blob.core.windows.net/roomjack/animations/highfive.gif" alt="High five!"/>
                    </div>
                    <div className="process-result-message-box mb-5">
                        <div className="header">
                            {getLanguageEntry("processes>registration>welcome_to_roomjack_header")
                                .replace('{name}', this.state.registration_data.first_name)}
                        </div>
                        <div className="description-container">{getLanguageEntry("processes>registration>welcome_to_roomjack_description")}</div>
                    </div>
                    <NavigatorButton to="/profile/overview" className="accent-icon-button"
                                     onAfterClick={() => {this.hide()}}>
                        <FontAwesomeIcon icon={["fal", "user-check"]} />
                        <span>
                            {getLanguageEntry("processes>registration>start_using_roomjack")}
                        </span>
                    </NavigatorButton>
                </div>
            }
        }
    }
    //endregion

}
Registration.propTypes = {
    onRegister: PropTypes.func
};
export default Registration;