import React from "react";
import PropTypes from "prop-types";
import SubpageSideMenu from "../../components/SubpageSideMenu";
import {Mode} from "../../utils/Types.ts";
import {Navigate, Routes} from "react-router";
import {Route} from "react-router-dom";
import Favorites from "./submenus/Favorites";
import Statistics from "./submenus/Statistics";
import Reviews from "./submenus/Reviews";
import Finances from "./submenus/Finances";
import Chat from "./submenus/Chat";
import ToDos from "./submenus/ToDos";
import CurrentPrice from "./submenus/CurrentPrice";
import Bookings from "./submenus/Bookings";
import Adverts from "./submenus/Adverts";
import Dashboard from "./submenus/Dashboard";
import ProtectedRoutes from "../../utils/ProtectedRoutes";
import "../../../css/pages/jackboard/jackboard.css";
import "../../../css/components/booking/booking-components.css";
import ReviewModal from "../../components/modals/ReviewModal";
import {
    getTimeUnitInMS,
    RJ_REVIEW_MODAL_TRIGGERED,
    setRoomJackReviewModalTriggered,
    TIME_UNIT_VALUE_MAP
} from "../../utils/Helper";
import {SITE_INIT} from "../../../index";

class JackBoard extends React.Component {

    constructor(props) {
        super(props);
        this.menu_items = [
            {
                mainPath: "/desktop/dashboard",
                subPath: "/desktop/dashboard",
                lang: "jackboard>dashboard>name",
                icon: ["fas", "th-large"]
            },
            {
                mainPath: "/desktop/adverts",
                subPath: "/desktop/adverts",
                lang: "jackboard>adverts>name",
                icon: ["fas", "house"],
                shouldRender: () => { return this.props.mode === Mode.landlord }
            },
            {
                mainPath: "/desktop/bookings",
                subPath: "/desktop/bookings/overview",
                lang: "jackboard>bookings>name",
                icon: ["fas", "calendar-alt"]
            },
            {
                mainPath: "/desktop/current-price",
                subPath: "/desktop/current-price",
                lang: "jackboard>current_price>name",
                icon: ["fas", "chart-line"],
                shouldRender: () => { return this.props.mode === Mode.landlord }
            },
            {
                mainPath: "/desktop/todos",
                subPath: "/desktop/todos",
                lang: "jackboard>todos>name",
                icon: ["fas", "tasks"],
                shouldRender: () => { return this.props.mode === Mode.landlord }
            },
            {
                mainPath: "/desktop/messages",
                subPath: "/desktop/messages",
                lang: "jackboard>messages>name",
                icon: ["fas", "comment-dots"]
            },
            {
                mainPath: "/desktop/finances",
                subPath: "/desktop/finances/overview",
                lang: "jackboard>finances>name",
                icon: ["fas", "calculator-alt"],
                shouldRender: () => { return this.props.mode === Mode.landlord }
            },
            {
                mainPath: "/desktop/statistics",
                subPath: "/desktop/statistics/overview",
                lang: "jackboard>stats>name",
                icon: ["fas", "chart-pie"],
                shouldRender: () => { return this.props.mode === Mode.landlord }
            },
            {
                mainPath: "/desktop/reviews",
                subPath: "/desktop/reviews",
                lang: "jackboard>reviews>name",
                icon: ["fas", "star"]
            },
            {
                mainPath: "/desktop/favorites",
                subPath: "/desktop/favorites",
                lang: "jackboard>favorites>name",
                icon: ["fas", "heart"],
                shouldRender: () => { return this.props.mode === Mode.renter }
            }
        ];
        this.review_modal = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let url = window.location.pathname;
        let ignoreOnURLs = ["/desktop/adverts/editor"];
        if (ignoreOnURLs.filter(i => url.includes(i)).length === 0 &&
            this.props.userData && SITE_INIT + getTimeUnitInMS(30, TIME_UNIT_VALUE_MAP.SECOND) <= Date.now() &&
            (this.props.userData.bookings || this.props.userData.adverts || this.props.userData.conversations) &&
            !RJ_REVIEW_MODAL_TRIGGERED) {
            this.callReviewModal();
            setRoomJackReviewModalTriggered(true);
        }
    }

    render() {
        return(
            <div id="my-desktop" className="subpage-container">
                <SubpageSideMenu items={this.menu_items} mode={this.props.mode} />
                <div id="jack-board">
                    <Routes>
                        <Route exact path={"/dashboard"} element={<Dashboard mode={this.props.mode}
                                                                             userData={this.props.userData}
                                                                             navHook={this.props.navHook}
                                                                             onUpdateUserData={this.props.onUpdateUserData} />}/>
                        <Route exact path={"/bookings/*"} element={<Bookings mode={this.props.mode}
                                                                             userData={this.props.userData}
                                                                             onUpdateUserSession={this.props.onUpdateUserSession}
                                                                             onUpdateUserData={this.props.onUpdateUserData} />}/>
                        <Route exact path={"/messages"} element={<Chat mode={this.props.mode}
                                                                       userData={this.props.userData}
                                                                       onUpdateUserData={this.props.onUpdateUserData} />}/>
                        <Route exact path={"/reviews"} element={<Reviews mode={this.props.mode}
                                                                         userData={this.props.userData}  />}/>
                        <Route element={<ProtectedRoutes redirection="/desktop/dashboard"
                                                         authObject={{loading: false, isAuthorized: this.props.mode === Mode.renter}} />}>}>
                            <Route exact path={"/favorites"} element={<Favorites userData={this.props.userData}
                                                                                 onUpdateUserData={this.props.onUpdateUserData} />}/>
                        </Route>
                        <Route element={<ProtectedRoutes redirection="/desktop/dashboard"
                                                         authObject={{loading: false, isAuthorized: this.props.mode === Mode.landlord}} />}>}>
                            <Route exact path={"/adverts/*"} element={<Adverts userData={this.props.userData}
                                                                               onUpdateUserData={this.props.onUpdateUserData}
                                                                               navHook={this.props.navHook} />}/>
                            <Route exact path={"/current-price"} element={<CurrentPrice userData={this.props.userData} />}/>
                            <Route exact path={"/todos"} element={<ToDos mode={this.props.mode} />}/>
                            <Route exact path={"/finances/*"} element={<Finances userData={this.props.userData}
                                                                                 onUpdateUserData={this.props.onUpdateUserData} />}/>
                            <Route exact path={"/statistics/*"} element={<Statistics  />}/>
                        </Route>
                        <Route exact path={"/*"} element={<Navigate to="/desktop/dashboard" />}/>
                    </Routes>
                </div>
                <ReviewModal ref={this.review_modal} userData={this.props.userData} mode={this.props.mode}
                             onUpdateUserData={this.props.onUpdateUserData}/>
            </div>
        )
    }

    callReviewModal() {
        if (this.review_modal.current) {
            this.review_modal.current.show();
        }
    }

}

JackBoard.propTypes = {
    onUpdateUserSession: PropTypes.func.isRequired,
    onUpdateUserData: PropTypes.func.isRequired,
    userData: PropTypes.object,
    mode: PropTypes.oneOf(Object.values(Mode)).isRequired,
    navHook: PropTypes.func.isRequired
}
JackBoard.defaultProps = {
    mode: Mode.renter
}
export default JackBoard;